// import DOMPurify from 'dompurify'
// import JSDOM from 'jsdom'

// let DOMPurify
// if (!window) {
//   const { window } = new JSDOM('')
//   DOMPurify = createDOMPurify(window)
// } else {
//   DOMPurify = createDOMPurify
// }

const sanitizeHTML = fields => {
  const value =
    fields && fields.text && fields.text.editable ? fields.text.editable : fields.text.value
  // return DOMPurify.sanitize(value, { FORBID_TAGS: ['style'] })
  return value
}

export default sanitizeHTML
