// @flow
import React from 'react'
import AltsInflationProofingComponent from '../../../organisms/Alteration/AltsInflationProofing'
import { isBrowser } from '../../../../utils/browserUtils'

type AltsInflationProofingProps = {
  fields: Object,
  rendering: Object,
}

const AltsInflationProofing = ({ fields, rendering }: AltsInflationProofingProps) =>
  isBrowser() && <AltsInflationProofingComponent fields={fields} rendering={rendering} />

export default AltsInflationProofing
