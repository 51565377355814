import { colours, space, fontSize, fontWeights } from '../../../../../styles'

const styles = {
  accordion: {
    marginBottom: space(2),
    background: colours.white,
    padding: `${space(4, true)} ${space(7.5, true)}`,
    boxShadow: '0px 3px 5px 0px #0000000D',
  },
  label: {
    fontSize: fontSize.md,
    fontWeight: fontWeights.bold,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  status(checked) {
    let dynamicAttr = {
      backgroundColor: colours.lightestGrey,
    }
    if (checked) {
      dynamicAttr = {
        backgroundColor: colours.lightGreen,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& svg': {
          color: colours.white,
        },
      }
    }
    return {
      width: space(3),
      display: 'inline-block',
      height: space(3),
      borderRadius: '100%',
      marginRight: space(3),
      ...dynamicAttr,
    }
  },
  accordionIcon: {
    '& svg': {
      color: colours.black,
    },
  },
  children(disableScrollContent) {
    let dynamicAttr = {
      maxHeight: space(60),
    }
    if (disableScrollContent) {
      dynamicAttr = { maxHeight: 'auto' }
    }
    return {
      color: colours.black,
      margin: `${space(4, true)} 0 0`,
      padding: `${space(5, true)} 0`,
      borderColor: colours.lightGrey,
      borderStyle: 'solid',
      borderWidth: '1px 0 0 0',
      overflowY: 'auto',

      '& h3': {
        fontSize: fontSize.sm,
        fontWeight: fontWeights.bold,
        marginBottom: space(3),
      },
      '& p': {
        marginBottom: space(3),
      },
      '& ul': {
        marginBottom: space(3),
      },
      ...dynamicAttr,
    }
  },
  continueSection: {
    borderTop: `1px solid ${colours.lightGrey}`,
    paddingTop: space(4),
  },
  spinner: {
    minHeight: 0,
  },
}

export default styles
