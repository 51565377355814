import { colours, fontSize, space, fontWeights } from '../../../styles'
import ureAppStyles from '../UreTool/ureApp.style'

const styles = {
  topBackground: {
    backgroundColor: colours.darkGreen,
    color: colours.white,
    minHeight: space(40),
    display: 'flex',
    h1: {
      color: colours.white,
      width: '100%',
    },
  },
  pageWrapHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    width: space(180),
    alignItems: 'center',
  },
  pageContent: {
    marginTop: space(-6),
  },
  listWrapper: {
    display: 'flex',
  },
  wrapper: {
    marginTop: space(-7),
  },
  label: checked => ({
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    color: checked ? colours.mediumGreen : colours.white,
  }),
  list: checked => ({
    display: 'inline-block',
    listStyleType: 'none',
    padding: `${space(2, true)} ${space(3, true)}`,
    background: checked ? colours.white : colours.mediumGreen,
    cursor: 'pointer',
  }),
  ureSection: {
    padding: `${space(3, true)} 0 ${space(15, true)}`,
    ...ureAppStyles,
  },
  status: (statusComplete, activeTab) => ({
    width: space(3),
    backgroundColor: activeTab ? colours.lightGreen : colours.lightestGrey,
    height: space(3),
    borderRadius: '100%',
    marginRight: space(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '>svg': {
      color: statusComplete && activeTab ? colours.white : colours.lightGreen,
    },
  }),
}
export default styles
