import { space, colours, fontWeights, fontSize } from '../../../../../styles'
import { responsiveTileStyle } from '../../styles'

const styles = {
  relatedArticleWrap: {
    margin: '0 auto',
  },
  relatedArticleHeading: {
    fontSize: fontSize.md,
    lineHeight: space(3, true),
    fontWeight: fontWeights.semiBold,
    color: colours.mediumGrey,
  },
  articleCardWrapper: {
    display: 'grid',
    gridGap: space(2),
    marginTop: space(8),
    ...responsiveTileStyle,
  },
}

export default styles
