// @flow
import React from 'react'
import styled from '@emotion/styled'

// styles
import styles from './modalCard.styles'

type CardButtonProps = {
  // Card Icon
  buttonIcon?: Node | null,
  // handler function
  handleClickEvent: Function,
  // title text
  buttonTitleText: string,
  // Description Text
  buttonDescriptionText: string,
  description: string,
  userIcon: string,
  phoneIcon: string,
  descriptionText: string,
  buttonText: string,
}

const ButtonElement = styled('button')(styles.buttonElement)
const ButtonTitle = styled('strong')(styles.buttonTitle)
const ButtonDescription = styled('span')(styles.buttonDescription)
const Icon = styled('div')(styles.icon)
const ButtonIcon = styled('div')(styles.buttonIcon)
const ButtonText = styled('div')(styles.buttonText)
const CardButton = (props: CardButtonProps) => {
  const {
    buttonIcon,
    handleClickEvent,
    buttonTitleText,
    buttonDescriptionText,
    description,
    userIcon,
    phoneIcon,
    descriptionText,
    buttonText,
  } = props
  return (
    <ButtonElement type="button" onClick={handleClickEvent}>
      {buttonIcon && <Icon>{buttonIcon}</Icon>}
      <ButtonTitle>{buttonTitleText}</ButtonTitle>
      <ButtonIcon>
        {userIcon}
        <ButtonDescription>{buttonDescriptionText}</ButtonDescription>
      </ButtonIcon>

      <ButtonText>{description}</ButtonText>
      {buttonIcon && (
        <ButtonIcon>
          {phoneIcon}
          <ButtonDescription>{descriptionText}</ButtonDescription>
          <ButtonText>{buttonText}</ButtonText>
        </ButtonIcon>
      )}
    </ButtonElement>
  )
}

CardButton.defaultProps = {
  buttonIcon: null,
}

export default CardButton
