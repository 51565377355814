import { colours, fontSize, fontWeights, space } from '../../../../../../styles'

const styles = {
  wrap: {
    position: 'relative',
    padding: `${space(2, true)} ${space(3, true)} ${space(2, true)} ${space(4, true)}`,
    borderBottom: `1px solid ${colours.darkestGrey} `,
    '&:before': {
      content: '""',
      width: space(1),
      height: '100%',
      position: 'absolute',
      background: colours.lightGreen,
      left: 0,
      top: 0,
    },
  },

  settings: {
    marginLeft: space(-1),
  },

  infoIcon: {
    color: colours.white,
    margin: space(1, true),
    width: space(2),
    height: space(2),
  },

  switch: {
    color: colours.white,
    fontSize: fontSize.lg,
    margin: `0 ${space(1, true)}`,
    fontWeight: fontWeights.semiBold,
  },
  label: {
    color: colours.white,
    fontWeight: fontWeights.semiBold,
    textAlign: 'right',
    fontSize: fontSize.xxs,
  },
  button: {
    marginTop: space(5),
    display: 'block',
  },
  inputElement: {
    maxWidth: space(32),
  },

  error: {
    color: colours.red,
  },
  tooltipContent: {
    width: space(35, true),
    textAlign: 'left',
  },
  labelText: {
    fontSize: fontSize.xxs,
    fontWeight: fontWeights.semiBold,
    lineHeight: 1,
    color: colours.white,
  },
  flex: {
    display: 'flex',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
}

export default styles
