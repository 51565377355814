// @flow
import get from 'lodash/get'

// components.
import RequestAQuote from '../../CreateQuote/components/RequestManualQuote/RequestAQuote'
import SubmittedRequest from '../../CreateQuote/components/RequestManualQuote/SubmittedRequest'

// actions
import { closeSidebar } from '../../../../actions/creators/sidebar'

// utils
import history from '../../../../utils/browserHistory'

// constants
import { DASHBOARD_ROUTE } from '../../../../constants/routes'

const SIDEBAR_SCHEMA = (fields = {}) => [
  {
    sitecoreFieldHeadingKey: 'Header',
    component: RequestAQuote,
    visible: false,
    callbackKey: 'goToRequestManualQuote',
    props: {
      goToRequestManualQuote: next => next(),
      secondaryHeader: true,
      isAltsQuote: true,
    },
  },
  {
    sitecoreFieldHeadingKey: 'Header',
    component: SubmittedRequest,
    visible: false,
    props: {
      secondaryHeader: true,
      forcedClose: true,
      isAltsQuote: true,
    },
    nextText: get(fields, 'BackToDashboard.value'),
    handleNext: (next, dispatch) => {
      dispatch(closeSidebar())
      history.push(DASHBOARD_ROUTE)
    },
  },
]

export default SIDEBAR_SCHEMA
