import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

// components
import PersonalDetails from './PersonalDetails'
import ContactDetails from './ContactDetails'
import ContactDetailsForm from './ContactDetailsForm'
// @ts-expect-error non-TS-code
import WithLoader from '../../molecules/WithLoader'

// types
import { propTypes } from '../../../types/components/PersonalAndContactDetails'
import { Relationship } from '../../../types/ClientPolicies'

// constants
import { BANCS_CUSTOMER_NUMBER_IOOF, BANCS_CUSTOMER_NUMBER_NULIS } from '../../../constants/bancs'

// components
import InternalSuperOwnerDetails from './InternalSuperOwnerDetails'

// styles
import styles from './personalAndContactDetails.styles'
import { Store } from '../../../types/store'

// utils
import { createEvent } from '../../../utils/telemetry'

const Wrapper = styled('div')(styles.wrapper)

const PersonalAndContactDetails = (props: propTypes) => {
  const [isContactDetailsFormVisible, toggleContactDetailsForm] = useState(false)
  useEffect(() => {
    if (isContactDetailsFormVisible) {
      const event = createEvent({
        GA: {
          category: 'Contact details',
          action: 'Edit',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Edit Contact details',
          },
        },
      })
      event.end()
    }
  }, [isContactDetailsFormVisible])
  const { isLoading } = useSelector((state: Store) => state.policies)
  const { data } = props
  const bancsCustomerNo = data?.bancsCustomerNo ?? ''
  const isInternalSuperOwner =
    bancsCustomerNo === BANCS_CUSTOMER_NUMBER_NULIS ||
    bancsCustomerNo === BANCS_CUSTOMER_NUMBER_IOOF
  const childrenProps = {
    ...props,
    data: isInternalSuperOwner ? (data?.lifeInsured as Relationship) : data,
  }
  return (
    <Wrapper>
      <WithLoader isLoading={isLoading} loaderProps={{ type: 'tab' }}>
        {data && (
          <>
            {isInternalSuperOwner && <InternalSuperOwnerDetails {...props} />}
            <PersonalDetails {...childrenProps} isInternalSuperOwner={isInternalSuperOwner} />
            {isContactDetailsFormVisible ? (
              <ContactDetailsForm
                {...childrenProps}
                closeForm={() => toggleContactDetailsForm(false)}
              />
            ) : (
              <ContactDetails {...childrenProps} openForm={() => toggleContactDetailsForm(true)} />
            )}
          </>
        )}
      </WithLoader>
    </Wrapper>
  )
}

export default PersonalAndContactDetails
