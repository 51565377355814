import { space, mediaQueries, setColumns } from '../../../../../styles'

const styles = {
  offset: {
    marginTop: -space(7),
  },
  base: {
    paddingBottom: space(8),
    paddingTop: space(3),
    [mediaQueries.md]: {
      ...setColumns(1),
      paddingTop: space(7),
    },
  },
  body: {
    maxWidth: '450px',
    [mediaQueries.lg]: {
      ...setColumns(1.25),
    },
  },
}

export default styles
