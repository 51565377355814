// @flow
import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button, Modal } from '@mlcl-digital/mlcl-design'
import get from 'lodash/get'

// actions
import { actionCreators } from '../../../actions'

// selectors
import { getAlterations } from '../../../selectors/common.selectors'

// styles
import styles from './premiumIncreaseModal.styles'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import { formatCurrency } from '../../../utils/quoteUtils'

// styled components
const ModalFooter = styled('div')(styles.modalFooter)
const ModalDescription = styled('p')(styles.modalDescription)

type PremiumIncreaseModalPropTypes = {
  fields: Object,
}

const PremiumIncreaseModal = ({ fields }: PremiumIncreaseModalPropTypes) => {
  const dispatch = useDispatch()
  const alterationsState = useSelector(getAlterations)
  const { isIncreasedPremiumModal, increasedPremium } = alterationsState

  const handleIncreasedPremiumModalClose = useCallback(() => {
    dispatch(actionCreators.showAltsPremiumIncreasedModal(false))
  }, [])

  return (
    <Modal
      isOpen={isIncreasedPremiumModal}
      title={fields.ModalTitle}
      onClose={handleIncreasedPremiumModalClose}
      footer={
        <ModalFooter>
          <Button variant="secondary" size="small" onClick={handleIncreasedPremiumModalClose}>
            {renderTextField(fields.ModalCTA)}
          </Button>
        </ModalFooter>
      }
    >
      <ModalDescription>
        {renderTextField(
          get(fields, 'ModalDesc.value', '').replace('##', formatCurrency(increasedPremium)),
          true
        )}
      </ModalDescription>
    </Modal>
  )
}

export default PremiumIncreaseModal
