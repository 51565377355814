// @flow
import React from 'react'

// components.
import InactiveAdviser from '../../../organisms/InactiveAdviser'

type InactiveAdviserProps = {
  // Sitecore fields
  fields: Object,
}

const InactiveAdviserComponent = ({ fields }: InactiveAdviserProps) => (
  <InactiveAdviser fields={fields} />
)

export default InactiveAdviserComponent
