import { EXPERIENCE_API } from '../types/experienceApi'
import { FUND_LOOKUP, FUND_LOOKUP_RESET } from '../types/abnLookup'
import { EXPERIENCE_API_VERSION_1, EXPERIENCE_API_BASE } from '../../constants/apis'

export const fundSearch = data => ({
  type: EXPERIENCE_API,
  name: FUND_LOOKUP,
  verb: 'POST',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/funds/search`,
  data,
  callback: (err, dataReceived) => dataReceived,
})

export const fundSearchReset = () => ({
  type: FUND_LOOKUP_RESET,
})
