// @flow
import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { dispatch } from 'rxjs/internal/observable/pairs'

// styles.
import styles from './panel.styles'

type PanelProps = {
  // Schema object of details for panel
  schema: {
    title?: string,
    component: Node,
    // Sets the Panel to dark theme
    isDark?: boolean,
  },
  // The id of the panel we are making
  id: number,
  // The id of the panel being diplayed
  currentPanelId: number,
  // Function which can be used for sidebar progression if needed.
  callback: Function,
  // foot render function
  renderFoot: Function,
  // To render all child labels from sitecore content editor
  fields: {},
  // A set of props passed in from state optionaly from openSidebar dispatches.
  panelProps: {},
  // Checks if Sidebar has footer
  hasFooter: boolean,
  // Checks id the sidebar has secondary button
  hasSecondaryAction: boolean,
  // active sub pannel number if any else undefined
  activeSubPanel: number,
}

const El = styled('div')(
  ({ currentPanelId, active, hasFooter, hasSecondaryAction, isDark, styleOverrides }) =>
    styles.base(currentPanelId, active, hasFooter, hasSecondaryAction, isDark, styleOverrides)
)

// Makes a panel for the side bar from a given schema object
const Panel = ({
  id,
  currentPanelId,
  schema: orgSchema,
  callback,
  fields,
  hasFooter,
  hasSecondaryAction,
  panelProps,
  activeSubPanel,
  renderFoot,
}: PanelProps) => {
  let schema = orgSchema
  if (Array.isArray(schema)) {
    schema = orgSchema[typeof activeSubPanel === 'undefined' ? 0 : activeSubPanel]
  }
  const Component = schema.component
  const { props, callbackKey, callBackWithValue } = schema
  const alwaysReset = get(props, 'alwaysReset', false)
  const callbackProp = callbackKey
    ? {
        [callbackKey]: () => {
          props[callbackKey](callback, dispatch)
        },
      }
    : null
  const callBackWithValueProps = callBackWithValue
    ? {
        [callbackKey]: subPanel => () => {
          props[callbackKey](callback, dispatch, subPanel)
        },
      }
    : null

  const componentProps = {
    ...props,
    ...panelProps,
    ...callbackProp,
    ...callBackWithValueProps,
    renderFoot,
    fields,
  }
  return (
    <El
      hasFooter={hasFooter}
      hasSecondaryAction={hasSecondaryAction}
      key={
        alwaysReset
          ? `panel-id-${id}${panelProps ? panelProps.formId : ''}${currentPanelId}`
          : `panel-id-${id}`
      } // needs to re render with form values
      active={currentPanelId === id}
      currentPanelId={currentPanelId}
      isDark={schema.isDark}
      styleOverrides={schema.props?.panelStyleOverrides}
    >
      <Component {...componentProps} />
    </El>
  )
}

export default Panel
