import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, fontWeights, colours, fontSize } = Variables

const styles = {
  wrap: () => ({
    background: colours.tertiaryFive,
    padding: `${space(1, true)} ${space(1.5, true)}`,
    '&:nth-of-type(even)': {
      background: colours.tertiarySix,
    },
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${colours.tertiaryThree}`,
    },
  }),
  line: () => ({
    [mediaQueries.md]: {
      display: 'flex',
      alignItems: 'center',
      color: colours.tertiaryOne,
      '& > button': {
        marginLeft: 'auto',
      },
      '& > div:nth-of-type(2)': {
        marginRight: space(5),
      },
    },
  }),
  lineBoxHidden: {
    width: '100%',
    display: 'none',
    [mediaQueries.md]: {
      justifySelf: 'stretch',
      display: 'block',
    },
  },
  activeBox: {
    marginTop: space(2),
    fontSize: fontSize.xs,
    justifySelf: 'stretch',
    height: space(3),
    paddingLeft: space(3),
    width: '70%',
    paddingRight: space(3),
    paddingTop: 2,
    paddingBottom: 2,
    display: 'inline',
  },
  title: {
    whiteSpace: 'nowrap',
    minWidth: '10%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: fontWeights.semiBold,
    [mediaQueries.md]: {
      overflow: 'visible',
      width: 'auto',
    },
  },
  dateText: {
    whiteSpace: 'nowrap',
    minWidth: '10%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [mediaQueries.md]: {
      overflow: 'visible',
      width: 'auto',
    },
  },
  button: {
    height: 'auto',
    textAlign: 'center',
    fontSize: fontSize.xs,
    background: 'none',
    padding: 0,
  },
  descriptionWrap: () => ({
    width: '80%',
    color: colours.tertiaryOne,
    marginTop: space(3.25),
  }),
  dateBtnContainer: {
    padding: `${space(1, true)} 0`,
    [mediaQueries.md]: {
      padding: 0,
    },
  },
}
export default styles
