import { mediaQueries, colours, fontSize, space, fontWeights, shadows } from '../../../../../styles'

const styles = {
  wrapper: {
    flex: 1,
    backgroundColor: colours.white,
    paddingTop: space(2, true),
    boxShadow: shadows.mid,
    marginBottom: space(4, true),

    [mediaQueries.lg]: {
      flexBasis: 'calc(33% - 12px)',
      marginBottom: 0,
    },
    position: 'relative',
  },
  header: {
    padding: space(4, true),
  },
  title: {
    fontSize: fontSize.md,
    lineHeight: space(4.5, true),
    color: colours.black,
    opacity: 0.7,
    fontWeight: fontWeights.semiBold,
    margin: 0,
    verticalAlign: 'middle',
    paddingLeft: space(1, true),
  },
  iconContainer: {
    width: space(10, true),
    height: space(10, true),
    borderRadius: '50%',
    display: 'flex',
    color: colours.mediumGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'inherit',
  },
  footer: {
    textAlign: 'center',
    fontWeight: fontWeights.bold,
    background: colours.lightestGreen,
    color: colours.mediumGreen,
    height: space(7, true),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  footerText: {
    padding: `${space(2, true)} 0 0`,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.sm,
    lineHeight: space(3, true),
  },
  wrapText: {
    padding: `0 ${space(4, true)} ${space(14.5, true)}`,
    color: colours.black,
    opacity: 0.7,
  },
}

export default styles
