// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, colours, fontSize } = Variables
const styles = {
  pageWrap: {
    background: colours.tertiarySix,
    paddingTop: space(3),
    paddingBottom: space(18),
    [mediaQueries.lg]: {
      paddingTop: space(7),
      paddingBottom: space(15),
    },
  },
  heading: ({ mb }) => ({
    marginBottom: (mb && space(mb)) || space(0.5),
  }),
  description: {
    marginBottom: space(5),
  },
  tabs: {
    marginBottom: space(4),
  },
  buttonsWrapper: ({ isBottomWrapper }: Object) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: isBottomWrapper ? space(5) : space(2),
    [mediaQueries.lg]: {
      marginBottom: isBottomWrapper ? space(10) : space(2),
    },
  }),
  cardContainer: {
    marginBottom: space(3),
    [mediaQueries.lg]: {
      marginBottom: space(4),
    },
  },
  mcdDiscountInfo: {
    padding: `${space(2, true)} ${space(3, true)}`,
    backgroundColor: colours.functionalInfoLight,
    '& > div > div': {
      display: 'flex',
      alignItems: 'flex-start',
      color: colours.functionalInfoDark,
      '& > svg': {
        fontSize: fontSize.md,
      },
      '& > span': {
        fontSize: fontSize.xs,
      },
    },
  },
  modalDescription: {
    color: colours.black,
    marginBottom: space(4, true),
  },
  selectAllPoliciesBtn: {
    fontSize: fontSize.md,
    paddingLeft: space(1),
    paddingRight: space(1),
    letterSpacing: 'normal',
  },
  backCTAButton: {
    margin: '14px 0',
  },
}

export default styles
