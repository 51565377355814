// @flow
import { mediaQueries, colours } from '../../../styles'

const styles = {
  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  backgroundDetail: (theme: { bg: string }) => ({
    position: 'absolute',
    bottom: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
    background: colours.white,
    [mediaQueries.md]: {
      background: [
        theme.bg,
        `linear-gradient(transparent
        60%, ${theme.bg} 40%)`,
      ],
    },
  }),
}
export default styles
