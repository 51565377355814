// @flow
import React from 'react'

// components.
import ReportToolRoot from '../../../organisms/ReportTool'

type ReportingProps = {
  // Sitecore fields
  fields: Object<Object>,
}

const Reporting = ({ fields }: ReportingProps) => <ReportToolRoot fields={fields} />

export default Reporting
