import { Variables } from '@mlcl-digital/mlcl-design'

import { space } from '../../../../../../styles'

const { colours } = Variables

export default {
  cardWrapper: ({ isSelected }) => ({
    ...(isSelected && { boxShadow: `0px 0px 0px 2px ${colours.functionalAccept}` }),
    display: 'flex',
    flexDirection: 'column',
  }),
  btnCTA: {
    justifyContent: 'center',
    display: 'inline-flex',
    alignItems: 'center',

    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'coloumn',
    justifyContent: 'center',
    alignItems: 'center',

    padding: `${space(3, true)} ${space(4, true)}`,
    border: `1px solid ${colours.tertiaryThree}`,
    borderTop: 'unset',
  },
}
