// routes
export const DECREASE_COVER_LANDING_PAGE_ROUTE = '/DecreaseCover'

// component names
export const DECREASE_COVER_QUOTE_COMPONENT_NAME = 'AltsDecreaseQuote'
export const DECREASE_COVER_CONFIRMATION_PAGE = 'AltsConfirmation'
export const DECREASE_COVER_DECLARATION_PAGE = 'AltsDeclaration'

export const ALTERATION_TYPES = {
  REJECT_CPI: 'rejectCPI',
  SMOKER_STATUS: 'smokerStatus',
  RE_INSTATEMENT: 'reinstatement',
  DECREASE_RISK: 'decreaseRisk',
  INCREASE_RISK: 'increaseRisk',
}

export const ALTERATION_STATUS = {
  REJECT_CPI: 'rejectCPI',
  DECREASED: 'decreaseRisk',
  REMOVED: 'removed',
  REDUCED: 'reduced',
  INCREASED: 'increased',
  ADDED: 'added',
  UNCHANGED: 'unchanged',
}

export const ALT_TYPE_DECREASE_COVER = 'Decrease'
export const ALTERATION = 'Alteration'
export const DECREASE_COVER_GA_TAG = 'Decrease cover'
export const DECREASE_COVER_GA_TAG_QUOTE_SCREEN = 'Decrease cover quote screen'

export const REQUESTED_BY = {
  AGNT: 'AGNT',
  OWR: 'OWR',
}

export const PORTFOLIO_ANIVERSARY_PERIOD = {
  PRE: 'Pre',
  WITHIN: 'Within',
  OUTSIDE: 'Outside',
  MULTIPLE: 'Multiple',
}

export const REJECTCPI_WINDOW_IN_DAYS = 105

export const LIST_BULLET = String.fromCharCode(46)

export const NEW_PREMIUM_TAB = 'newPremium'

export const EXISTING_PREMIUM_TAB = 'existingPremium'

export const DECREASE_SUM_INSURED = 'decreaseSumInsured'
export const CMMON_EXCLUSION_DESC = 'Exclusion'

export const REMOVE_BENEFIT = 'removed'
export const BENEFIT_ALTS_TYPE_REMOVE = 'removeBenefit'
export const UNCHANGED = 'unchanged'
export const INCREASED = 'increased'
export const DECREASED = 'decreased'

export const DEFAULT_AMOUNT = '$0.00'

export const DURATION_MAPPING = {
  MON: 'Months',
  DAY: 'Days',
}

export const SYSTEM_GENERATED_REASON = {
  NONE: 'none',
  INCREASED: 'increasePremium',
  DECRASED: 'decreasePremium',
}

export const SAVE_QUOTE_ACTION_TYPE = {
  CONTINUE_CLICK: 'continueClick',
  SAVE_CLICK: 'saveClick',
  SAVE_AND_EXIT_CLICK: 'saveAndExitClick',
  DOWNLOAD_QUOTE_CLICK: 'downloadQuoteClick',
}

export const TIMELINE_COMPONENENT = {
  [DECREASE_COVER_CONFIRMATION_PAGE]: 'confirmation',
  [DECREASE_COVER_DECLARATION_PAGE]: 'declaration',
  [DECREASE_COVER_QUOTE_COMPONENT_NAME]: 'AltsDecreaseQuote',
}

export const VALIDATION_OUTCOME_FAILURE = 'failure'

// Inflation Proofing component names
export const INFLATION_PROOFING_QUOTE_PAGE = 'inflation-proofing-quote'
export const INFLATION_PROOFING_CONTACT_DETAILS_PAGE = 'alts-contact-details'
export const INFLATION_PROOFING_DECLARATION_PAGE = 'AltsDeclaration'
