import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { colours, space } = Variables as VariablesType

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(3),
  },
  sectionHeading: {
    marginBottom: space(5),
  },
  selectAllPoliciesBtn: {
    marginLeft: space(3),
  },
  buttonContainer: {
    marginTop: space(4),
    button: {
      marginRight: space(2.5),
    },
  },
  cardContainer: {
    width: '66%',
    '> div': {
      marginBottom: space(5),
    },
  },
}

export default styles
