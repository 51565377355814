// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { pathOr } from 'lodash/fp'
import isEmpty from 'lodash/isEmpty'

// redux.
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createEvent } from '../../../../../../utils/telemetry'

// styles.
import styles from './collectionInfo.styles'
import Heading from '../../../../../atoms/Heading'

// components.
import { IconEdit16 } from '../../../../../atoms/Icons'
import Modal from '../../../../../molecules/Modal'
import QuoteCollectionName from '../QuoteCollectionName'
import { actionCreators } from '../../../../../../actions'
import Button from '../../../../../atoms/Button'
import { CalculateQuoteErrorModal } from '../../../../../molecules/CalculateQuoteErrorModal'

import history from '../../../../../../utils/browserHistory'
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { getCalQuoteErrorMessage } from '../../../../../../utils/extendedQuoteUtils'
import { DEFAULT_QUOTE_COLLECTION_NAME } from '../../../../../../constants/forms'

const SubHeading = styled(Heading)(styles.subHeading)
const ConfirmBtn = styled(Button)(styles.confirmBtn)

type CollectionInfoProps = {
  // Title of quote
  quoteCollectionName: string,
  // sitecore field data
  fields: {
    [string]: string,
  },
  actions: {
    // Save the quote
    saveQuote: Function,
    // Update isUpdated
    isUpdatedQuote: Function,
    // duplicate quote template action
    duplicateQuoteTemplate: Function,
    setSaveQuoteInitiator: Function,
    initiateSaveQuote: Function,
    showSaveQuoteModal: Function,
    openSidebar: Function,
  },
  // show/hide save quote modal
  showSaveQuoteModal: boolean,
  // show/hide save quote error modal
  isSaveQuoteErrorModal: boolean,
  // error received from save quote api
  saveQuoteError: Object,
  // identifier for save quote initiator
  saveQuoteInitiator: string,
  // show/hide calculate quote error modal
  isCalQuoteErrorModal: boolean,
  // error received from calculate quote api
  calculateQuoteError: Object,
  exitToDashboard: boolean,
  // sitecore master data object
  masterData: Object,
  lifeAssured: Object,
}

const CollectionRoot = styled('div')(styles.collectionInfoRoot)
const CollectionLabel = styled('span')(styles.collectionLabel)
const CollectionName = styled(Heading)(styles.collectionName)
const QuoteEditIcon = styled(IconEdit16)(styles.quoteEditIcon)
const Wrap = styled('div')(styles.wrap)

class CollectionInfo extends Component<CollectionInfoProps, CollectionInfoState> {
  constructor(props) {
    super(props)

    this.updateQuoteCollectionName = this.updateQuoteCollectionName.bind(this)
    this.handleSaveQuoteModalClose = this.handleSaveQuoteModalClose.bind(this)
    this.handleCalQuoteModalClose = this.handleCalQuoteModalClose.bind(this)

    const tagEvent = createEvent({
      GA: {
        category: 'Quote collection',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Quote collection - View',
        },
      },
    })
    tagEvent.end()
  }

  componentDidMount() {
    const {
      actions: { resetQuoteErrorModal },
    } = this.props
    // reset save and create quote error to show/hide modal
    resetQuoteErrorModal()
  }

  // opens the edit collection name modal
  onChangeEditMode = () => {
    const {
      actions: { showSaveQuoteModal },
    } = this.props
    showSaveQuoteModal(true)
  }

  // updates the store with quote collection name
  updateQuoteCollectionName = data => {
    const {
      actions: { initiateSaveQuote, showSaveQuoteModal, openSidebar },
      saveQuoteInitiator,
    } = this.props
    const quoteCollectionName = `${data.firstName} ${data.lastName}`
    initiateSaveQuote(
      err => {
        if (!err) {
          switch (saveQuoteInitiator) {
            case 'continueToApplication': {
              history.replace('/quote-summary')
              break
            }
            case 'redirectToDashboard': {
              history.replace('/dashboard')
              break
            }
            case 'projections': {
              openSidebar('projections')
              break
            }
            case 'visualiser': {
              openSidebar('visualiser')
              break
            }
            default: {
              break
            }
          }
        }
      },
      saveQuoteInitiator ? `${saveQuoteInitiator}Modal` : '',
      quoteCollectionName,
      data.firstName,
      data.lastName
    )
    showSaveQuoteModal(false)
  }

  // closes the edit collection name modal
  closeModal() {
    const {
      actions: { showSaveQuoteModal, setSaveQuoteInitiator },
    } = this.props
    showSaveQuoteModal(false)
    setSaveQuoteInitiator('')
  }

  // handles save quote modal close
  handleSaveQuoteModalClose() {
    const {
      actions: { showSaveQuoteErrorModal },
    } = this.props
    showSaveQuoteErrorModal(false)
  }

  // handles calculate quote modal close
  handleCalQuoteModalClose() {
    const {
      actions: { showCalQuoteErrorModal },
    } = this.props
    showCalQuoteErrorModal(false)
  }

  render() {
    const {
      fields,
      quoteCollectionName,
      showSaveQuoteModal,
      isSaveQuoteErrorModal,
      isCalQuoteErrorModal,
      saveQuoteError,
      exitToDashboard,
      calculateQuoteError,
      masterData,
      lifeAssured,
    } = this.props

    const { quoteEntityName, saveQuoteErrorModalHeading, saveQuoteErrorModalConfirm } = fields
    const { saveQuoteErrorModalNoBenefitInPolicy } = reduceAuthorableFields(fields)
    const errorMessage =
      pathOr('', 'dataReceived.status.description', saveQuoteError) ||
      saveQuoteErrorModalNoBenefitInPolicy
    const calQuoteError = pathOr({}, 'dataReceived.status', calculateQuoteError)
    let formattedError = []

    if (!isEmpty(calQuoteError)) {
      formattedError = getCalQuoteErrorMessage(calQuoteError, masterData)
    }
    const { firstName, lastName } = lifeAssured
    let quoteNameToRender = quoteCollectionName
    if (quoteCollectionName === DEFAULT_QUOTE_COLLECTION_NAME && firstName) {
      quoteNameToRender = `${firstName} ${lastName}`
    }
    return (
      <CollectionRoot>
        <CollectionLabel>
          <Text field={quoteEntityName} />
        </CollectionLabel>
        <Wrap>
          <CollectionName size="2" data-locator="quoteCollection_name">
            {quoteNameToRender}
          </CollectionName>
          <QuoteEditIcon
            onClick={this.onChangeEditMode}
            data-locator="quoteCollection_name_editIcon"
          />
        </Wrap>
        <Modal
          title={
            exitToDashboard
              ? fields.dashboardConfirmQuoteModalHeading.value
              : fields.quoteCollectionNameTitle.value
          }
          onClose={() => this.closeModal()}
          isOpen={showSaveQuoteModal}
        >
          <QuoteCollectionName
            fields={fields}
            exitToDashboard={exitToDashboard}
            onSubmit={this.updateQuoteCollectionName}
          />
        </Modal>
        <CalculateQuoteErrorModal
          formattedError={formattedError}
          fields={fields}
          isOpen={isCalQuoteErrorModal}
          handleModalClose={this.handleCalQuoteModalClose}
          modalCTA={saveQuoteErrorModalConfirm}
        />
        <Modal
          isOpen={isSaveQuoteErrorModal}
          title={<Text field={saveQuoteErrorModalHeading} />}
          onClose={this.handleSaveQuoteModalClose}
        >
          <SubHeading size="6">{errorMessage}</SubHeading>
          <ConfirmBtn type="secondary" onClick={this.handleSaveQuoteModalClose}>
            <Text field={saveQuoteErrorModalConfirm} />
          </ConfirmBtn>
        </Modal>
      </CollectionRoot>
    )
  }
}

const mapStateToProps = ({ createQuote, masterList, client, lifeAssured }) => ({
  showSaveQuoteModal: createQuote.showSaveQuoteModal,
  isSaveQuoteErrorModal: createQuote.isSaveQuoteErrorModal,
  saveQuoteError: createQuote.saveQuoteError,
  exitToDashboard: createQuote.exitToDashboard,
  saveQuoteInitiator: createQuote.saveQuoteInitiator,
  isCalQuoteErrorModal: createQuote.isCalQuoteErrorModal,
  calculateQuoteError: createQuote.calculateQuoteError,
  masterData: masterList.data,
  client,
  lifeAssured,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionInfo)
