// @flow
import React from 'react'

// components.
import TaskDashboardComponent from '../../../organisms/TaskDashboard'

type TaskDashboardProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const TaskDashboard = ({ fields }: TaskDashboardProps) => <TaskDashboardComponent fields={fields} />

export default TaskDashboard
