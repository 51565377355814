// @flow
import React from 'react'
import { isBrowser } from '../../../../utils/browserUtils'

// components.
import AdvisorClientDetails from '../../../organisms/AdvisorClientDetails'

type ClientDetailsProps = {
  // Sitecore fields.
  fields: Object<Object>,
  // sitecore rendering
  rendering: Object,
}

const ClientDetails = ({ fields, rendering }: ClientDetailsProps) =>
  isBrowser() && <AdvisorClientDetails fields={fields} rendering={rendering} />

export default ClientDetails
