import { space, colours, QUOTE_LEFT_SIDEBAR_WIDTH } from '../../../../../styles'

const styles = {
  quoteSummaryRoot: {
    background: colours.offWhite,
    flex: 1,
    width: '100%',
    padding: space(3, true),
    paddingLeft: space(5, true),
    paddingTop: space(5, true),
    paddingRight: space(13, true),
    marginLeft: QUOTE_LEFT_SIDEBAR_WIDTH, // this should be equal to the width of quote left sidebar
    minHeight: '790px',
  },
}

export default styles
