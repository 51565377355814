import React, { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import queryString from 'query-string'
import TabBar from '@mlcl-digital/mlcl-design/lib/base/TabBar'
import { withRouter, RouteComponentProps } from 'react-router-dom'

// constants
import { POLICY_IDENTIFIER_POLICY_ID } from '../../../../../constants/policies'

// selectors
import { getPoliciesData } from '../../../../../selectors/clientPolicies'

// components
import Policy from '../Policy'

// types
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'

// utils
import { createEvent } from '../../../../../utils/telemetry'

type policyTabsProps = {
  location: {
    hash: string
  }
  fields: fieldsType
} & RouteComponentProps

const PolicyTabs = ({ location, fields }: policyTabsProps) => {
  const policies = useSelector(getPoliciesData)
  const { policyId } = queryString.parse(location?.search) as {
    policyId: string
  }
  const selectPolicyIndex = policies.findIndex(policy => policy.bancsPolicyNo === policyId)
  const [currentTab, setCurrentTab] = useState(selectPolicyIndex > -1 ? selectPolicyIndex : 0)
  const onTabChange = useCallback((tab: number) => {
    setCurrentTab(tab)
    const event = createEvent({
      GA: {
        category: 'View additional policy',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'View additional policy',
        },
      },
    })
    event.end()
  }, [])

  const schema = useMemo(
    () =>
      policies.map(policyData => {
        const { policy } = policyData
        const { identifiers = [] } = policy || {}
        const policyID = get(
          identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID),
          'value',
          ''
        )
        return {
          title: policyID,
          id: policyID,
          component: Policy,
          isVisible: true,
          props: {
            policyData,
            policies,
            fields,
            onPolicyTabChange: onTabChange,
          },
        }
      }),
    [policies]
  )

  return policies.length === 1 ? (
    <Policy
      fields={fields}
      policies={policies}
      policyData={policies[0]}
      onPolicyTabChange={onTabChange}
    />
  ) : (
    <TabBar
      onTabChange={onTabChange}
      currentTab={currentTab}
      // @ts-expect-error facing schema type issue for type defined in components library
      schema={schema}
      variant="navigation-level2"
      isMobileAllOptions
      mobileRowMaxTabLimit={3}
      disableHash
      location={location}
    />
  )
}

export default withRouter(PolicyTabs)
