// @flow
import React from 'react'
import ApplicationSummary from '../../../organisms/ApplicationSummary'

type ApplicationProps = {
  fields: Object<Object>,
}

const Application = ({ fields }: ApplicationProps) => <ApplicationSummary fields={fields} />

export default Application
