// @flow
import React from 'react'
import styled from '@emotion/styled'
import type { Node as ReactNode } from 'react'
import {
  Heading,
  Chip,
  Accordion as BaseAccordion,
  Tooltip,
  Icons,
} from '@mlcl-digital/mlcl-design'

import { formatLifeAssuredArray } from '../../../../../utils/taskDashboardUtils'

// styles
import styles from './accordion.styles'

// data locators
import {
  LOCATOR_ACCORDION_CLOSE_BUTTON,
  LOCATOR_ACCORDION_OPEN_BUTTON,
} from '../../TaskList.locators'

const { IconChevronDown32, IconChevronUp32 } = Icons

const Label = styled('div')(styles.label)
const AccordionIcon = styled('span')(styles.accordionIcon)

type AccordionProps = {
  children: ReactNode,
  isOpen: boolean,
  labelKey: string,
  disableToggleIcon: boolean,
  disableScrollContent: boolean,
  isUpdated?: boolean,
  noOfTask?: string,
  toggleListener: Function,
  lifeAssured: Array,
  applicationId: String,
}

const renderLabel = (lifeAssured, applicationID, tasks) => (
  <Label>
    <Chip variant="info">{tasks}</Chip>
    <Heading variant="h3" size="xsmall">
      {lifeAssured.length > 1 ? (
        <>
          Application: {applicationID}
          &nbsp;&nbsp;&nbsp;
          <Chip variant="info" data-for={`${applicationID}-tooltip`} data-tip>
            {lifeAssured.length}
          </Chip>
          <Tooltip id={`${applicationID}-tooltip`}>
            {lifeAssured.map(LA => (
              <>
                {LA} <br />
              </>
            ))}
          </Tooltip>
        </>
      ) : (
        lifeAssured[0]
      )}
    </Heading>
  </Label>
)

const renderIcon = type => {
  let Icon = <IconChevronUp32 data-locator={LOCATOR_ACCORDION_CLOSE_BUTTON} />
  if (type === 'open') {
    Icon = <IconChevronDown32 data-locator={LOCATOR_ACCORDION_OPEN_BUTTON} />
  }
  return <AccordionIcon>{Icon}</AccordionIcon>
}

class Accordion extends React.PureComponent<AccordionProps> {
  constructor(props) {
    super(props)
    this.Children = styled('div')(styles.children(props.disableScrollContent))
  }

  render() {
    const {
      children,
      isOpen,
      labelKey,
      disableToggleIcon,
      isUpdated,
      toggleListener,
      noOfTask,
      lifeAssured = '',
      applicationId,
    } = this.props
    const { Children } = this
    const lifeAssureds = formatLifeAssuredArray(lifeAssured)
    return (
      <BaseAccordion
        label={renderLabel(lifeAssureds, applicationId, noOfTask)}
        openIcon={disableToggleIcon ? null : renderIcon('open')}
        closeIcon={disableToggleIcon ? null : renderIcon('close')}
        isOpen={isOpen}
        labelKey={labelKey}
        isUpdated={isUpdated}
        toggleListener={toggleListener}
      >
        <Children>{children}</Children>
      </BaseAccordion>
    )
  }
}

Accordion.defaultProps = {
  isUpdated: false,
  noOfTask: '',
}

export default Accordion
