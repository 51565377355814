// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Hero, Loader, PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// atoms
import { IconTag32 } from '../../../../atoms/Icons'

// components
import ArticleCard from '../ArticleCard'
import LoadMore from '../LoadMore'

// utils.
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import history from '../../../../../utils/browserHistory'
import { getRole } from '../../../../../utils/middlewareUtils'

// styles.
import styles from './articleTagPage.styles'

// constants
import {
  ARTICLE_LISTING_PAGE_URL,
  ARTICLE_PAGE_INDEX,
  ARTICLE_NO_OF_RECORDS,
  NO_MORE_ARTICLES_AVAILABLE,
} from '../../../../../constants/knowledgeBase'

type ArticleTagPageProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // An object containing action creator functions.
  actions: {
    resetNavigationType: Function,
    resetFooterType: Function,
    setActiveCategoryType: Function,
    getAllArticles: Function,
    setActiveArticleId: Function,
    setArticleLoadMore: Function,
    setFetchArticlesLoading: Function,
  },
  // knowledge base data
  knowledgeBase: Array<{
    articels: Object,
    categories: Object,
    totalPage: Number,
    page: Number,
  }>,
  // active category type
  activeCategoryType: String,
  // active article id
  activeArticleId: String,
  // active article tag
  activeTag: String,
}

type ArticleTagPageState = {
  // pageIndex number
  pageIndex: number,
  // reocrds to show
  noOfRecords: number,
  // selected category type
  categoryType: string,
  // selected tag type
  activeArticleTag: string,
  // article id
  articleId: number,
  // list of tag related articles
  articleList: Array,
  // total no of pages
  totalNumberOfRecords: number,
  // are there more articles to load
  hasNoMoreArticles: boolean,
}

const Wrap = styled(PageWrap)(styles.wrap, styles.tableRow)
const ArticleCardWrapper = styled('div')(styles.articleCardWrapper)
const Loading = styled(Loader)(styles.loaderWrap)
const TagArticleNotAvailable = styled('h3')(styles.tagArticleNotAvailable)

export class ArticleTagPage extends Component<ArticleTagPageProps, ArticleTagPageState> {
  constructor(props) {
    super(props)
    this.state = {
      articleList: [],
      pageIndex: ARTICLE_PAGE_INDEX,
      noOfRecords: ARTICLE_NO_OF_RECORDS,
      articleId: props.activeArticleId,
      categoryType: props.activeCategoryType,
      activeArticleTag: props.activeTag,
      totalNumberOfRecords: NO_MORE_ARTICLES_AVAILABLE,
      hasNoMoreArticles: false,
    }
  }

  componentDidMount() {
    this.handleGetArticleList()
  }

  handleGetArticleList = isLoadMore => {
    const {
      actions: { getAllArticles, setActiveCategoryType, setFetchArticlesLoading },
      knowledgeBase: { activeTag },
    } = this.props
    const { pageIndex, noOfRecords, articleList, categoryType, activeArticleTag } = this.state
    !isLoadMore && setFetchArticlesLoading()
    const articleListingParams = {
      portal: getRole(),
      categoryType: '',
      pageIndex,
      noOfRecords,
      articleId: '',
      searchText: '',
      tag: activeArticleTag || activeTag,
    }
    setActiveCategoryType(categoryType)
    getAllArticles(articleListingParams, (err, data) => {
      if (data) {
        this.setState({
          articleList:
            pageIndex === ARTICLE_PAGE_INDEX
              ? get(data, 'data.articles', [])
              : [...articleList, ...get(data, 'data.articles', [])],
          totalNumberOfRecords: get(data, 'data.totalNumberOfRecords', NO_MORE_ARTICLES_AVAILABLE),
          hasNoMoreArticles: get(data, 'data.page', '') === get(data, 'data.totalPage', ''),
        })
      }
    })
  }

  handleSetActiveArticle = (articleId: Number) => {
    const {
      actions: { setActiveArticleId, setActiveCategoryType },
    } = this.props
    const { categoryType } = this.state
    setActiveArticleId(articleId)
    setActiveCategoryType(categoryType)
  }

  handleLoadMore = () => {
    const { pageIndex, articleId, categoryType } = this.state
    const {
      actions: { setArticleLoadMore, setActiveArticleId, setActiveCategoryType },
    } = this.props
    setArticleLoadMore()
    setActiveArticleId(articleId)
    setActiveCategoryType(categoryType)
    this.setState(
      {
        pageIndex: pageIndex + 1,
      },
      () => {
        this.handleGetArticleList(true)
      }
    )
  }

  renderArticleTagPage() {
    const {
      fields,
      knowledgeBase,
      knowledgeBase: { isFetchingArticles, isLoadingMoreArticles, activeTag },
    } = this.props
    const {
      articleTagPageLoadMoreText,
      articleTagPageLinkText,
      tagArticleNotAvailableMessage,
      articleTagPageBackText,
    } = reduceAuthorableFields(fields)
    const { articleList, totalNumberOfRecords, activeArticleTag, hasNoMoreArticles } = this.state
    const hasMoreArticle = get(knowledgeBase, 'articles', [])

    const navBar = {
      label: articleTagPageBackText,
      onClick: () => history.push(`/${ARTICLE_LISTING_PAGE_URL}`),
      isVisible: true,
    }
    const heroArticleTagFields = {
      heading: { value: activeArticleTag || activeTag },
      content: get(fields, 'content.value', ''),
    }
    return (
      <React.Fragment>
        <Hero fields={heroArticleTagFields} icon={<IconTag32 />} navBar={navBar} />
        <Wrap>
          {isFetchingArticles ? (
            <Loading />
          ) : (
            <ArticleCardWrapper>
              {articleList.map(article => (
                <ArticleCard
                  key={article.id}
                  title={article.title}
                  shortDescription={article.shortDescription}
                  link={article.url}
                  footer={articleTagPageLinkText}
                  articleId={article.id}
                  categoryType={article.categoryType}
                  handleSetActiveArticle={this.handleSetActiveArticle}
                />
              ))}
            </ArticleCardWrapper>
          )}
          {!hasNoMoreArticles || totalNumberOfRecords !== NO_MORE_ARTICLES_AVAILABLE ? (
            <div>
              {isLoadingMoreArticles ? (
                <Loading />
              ) : (
                <LoadMore
                  label={articleTagPageLoadMoreText}
                  onClick={this.handleLoadMore}
                  hasMoreArticle={
                    isFetchingArticles || hasMoreArticle.length === NO_MORE_ARTICLES_AVAILABLE
                  }
                />
              )}
            </div>
          ) : null}
          {hasNoMoreArticles || totalNumberOfRecords === NO_MORE_ARTICLES_AVAILABLE ? (
            <TagArticleNotAvailable>{tagArticleNotAvailableMessage}</TagArticleNotAvailable>
          ) : null}
        </Wrap>
      </React.Fragment>
    )
  }

  render() {
    return this.renderArticleTagPage()
  }
}

const mapStateToProps = ({
  knowledgeBase: {
    knowledgeBase,
    knowledgeBase: { activeArticleId, activeCategoryType, activeTag },
  },
}) => ({
  knowledgeBase,
  activeArticleId,
  activeCategoryType,
  activeTag,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTagPage)
