// @flow
import React from 'react'
// Organism
import AltsGenerateQuoteTryAgainModalOrgs from '../../../organisms/AltsGenerateQuoteTryAgainModal'

type AltsGenerateQuoteTryAgainModalProps = {
  fields: Object,
}

const AltsGenerateQuoteTryAgainModal = ({ fields }: AltsGenerateQuoteTryAgainModalProps) => (
  <AltsGenerateQuoteTryAgainModalOrgs fields={fields} />
)

export default AltsGenerateQuoteTryAgainModal
