// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createEvent } from '../../../../../../utils/telemetry'
import { actionCreators } from '../../../../../../actions'

// components.
import SidebarNavItem from '../../../../../molecules/SidebarNavItem'

// constants
import { DECREASE_COVER_LANDING_PAGE } from '../../../../../../constants/routes'

// schema.
import manualLoadingSchema, {
  FORM_ID as MANUAL_LOADINGS_FORM_ID,
} from '../../PreAssessment/Loadings/loadings.schema'

// utils
import { resetBenefitList } from '../../../../../../utils/preAssessmentBenefitUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { isFeatureEnabledForAP } from '../../../../../../utils/commonUtils'
import history from '../../../../../../utils/browserHistory'

// styles.
import styles from '../requestManualQuote.styles'
import { ALTERATION_TYPES } from '../../../../../../constants/alterations'

type SelectQuoteTypeProps = {
  // sitecore manage fields
  fields: Object<Object>,
  // list of actions
  actions: Object<Function>,
  goToRequestAQuote: Function,
  // advisor entity
  details: Object,
  // bancsCustomerNo
  bancsCustomerNo: string,
  // Feature control switch for increase cover option
  isIncreaseCover: boolean,
  // Feature control switch for decrease cover option
  isDecreaseCover: boolean,
  // Feature control switch for create new quote option
  isCreateNewQuote: boolean,
  // Feature control switch for request quote option
  isRequestQuote: boolean,
  // Alterations error modal data
  altsErrorModal: Object,
  // Alterations state from store
  alterations: Object,
}

type SelectQuoteTypeState = {
  modalID: string,
}

const SelectionHeading = styled('div')(styles.resultsHeading)
export class SelectQuoteType extends PureComponent<SelectQuoteTypeProps, SelectQuoteTypeState> {
  requestAQuote = () => {
    const { goToRequestAQuote } = this.props
    goToRequestAQuote()
    const tagEvent = createEvent({
      GA: {
        category: 'Change Clients Cover',
        action: 'Request a quote',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Change Clients Cover - Request a quote',
        },
      },
    })
    tagEvent.end()
  }

  createNewQuote = () => {
    const {
      actions: {
        formInit,
        removeEnquiryId,
        resetAllSavedState,
        updateLoading,
        saveLoadings,
        resetConfirmSmokerTerms,
        setPanelProps,
        openSidebar,
      },
      details,
      bancsCustomerNo,
    } = this.props

    const adviserNumber = get(details, 'customerDetails.customerNo', undefined)

    openSidebar('createQuote', 1, { isFromClientListingPage: true })
    // reset capature client form
    removeEnquiryId(true)
    resetAllSavedState(adviserNumber, bancsCustomerNo)
    updateLoading([])
    resetBenefitList()
    // reset manual loading underwriter form data
    saveLoadings(null)
    formInit(MANUAL_LOADINGS_FORM_ID, manualLoadingSchema()({}), {})
    resetConfirmSmokerTerms()
    // Set props on sidebar
    setPanelProps({
      setPreAssessmentVisibility: true,
    })

    const tagEvent = createEvent({
      GA: {
        category: 'Change Clients Cover',
        action: 'Request a quote',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Change Clients Cover - Create a quote',
        },
      },
    })
    tagEvent.end()
  }

  isDecreaseCoverNotEligible = () => {
    const {
      alterations: { rules },
    } = this.props
    return get(rules, 'businessData.assesment.decrease_EligibleForAdviser', 'N') === 'N'
  }

  handleDecreaseCover = () => {
    const {
      actions: { closeSidebar, showModal, setModalDescription, setAlterationTypeSelectedByUser },
      altsErrorModal,
    } = this.props
    if (this.isDecreaseCoverNotEligible()) {
      if (altsErrorModal.description !== 'decreaseCover') {
        setModalDescription({
          modalType: 'altsErrorModal',
          description: 'decreaseCover',
        })
      }
      showModal('altsErrorModal')
    } else {
      closeSidebar()
      setAlterationTypeSelectedByUser(ALTERATION_TYPES.DECREASE_RISK)
      history.push(DECREASE_COVER_LANDING_PAGE)
      const tagEvent = createEvent({
        GA: {
          category: 'Change Clients Cover',
          action: 'Start a Decrease',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Change Clients Cover - Start a Decrease',
          },
        },
      })
      tagEvent.end()
    }
  }

  render() {
    const { fields, isIncreaseCover, isDecreaseCover, isCreateNewQuote, isRequestQuote } =
      this.props
    const { SelectQuoteType: selectQuote } = fields
    const {
      CreateQuoteTitle,
      CreateQuoteDesc,
      ReqQuoteTitle,
      ReqQuoteDesc,
      IncreaseQuoteTitle,
      IncreaseQuoteDesc,
      DecreaseQuoteTitle,
      DecreaseQuoteDesc,
      ChangeCoverErrMessage,
    } = reduceAuthorableFields(fields)
    return (
      <Fragment>
        <SelectionHeading>{renderTextField(selectQuote)}</SelectionHeading>
        {isIncreaseCover && (
          <SidebarNavItem
            title={IncreaseQuoteTitle}
            description={IncreaseQuoteDesc}
            clickHandler={() => {}}
          />
        )}
        {isDecreaseCover && (
          <SidebarNavItem
            title={DecreaseQuoteTitle}
            description={DecreaseQuoteDesc}
            clickHandler={this.handleDecreaseCover}
            status={this.isDecreaseCoverNotEligible() && ChangeCoverErrMessage}
          />
        )}
        {isCreateNewQuote && (
          <SidebarNavItem
            title={CreateQuoteTitle}
            description={CreateQuoteDesc}
            clickHandler={this.createNewQuote}
          />
        )}
        {isRequestQuote && (
          <SidebarNavItem
            title={ReqQuoteTitle}
            description={ReqQuoteDesc}
            clickHandler={this.requestAQuote}
          />
        )}
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  advisor: { details, betaTestingFeatures: { featuresEnabled } = {} },
  okta: { bancsCustomerNo },
  masterList,
  modal,
  alterations,
}) => ({
  details,
  bancsCustomerNo,
  masterData: masterList.data,
  isIncreaseCover: isFeatureEnabledForAP(masterList.data.featureControlSwitch, 'apIncreaseCover'),
  isDecreaseCover:
    isFeatureEnabledForAP(masterList.data.featureControlSwitch, 'apDecreaseCover') ||
    featuresEnabled.includes('apDecreaseCover'),
  isCreateNewQuote: isFeatureEnabledForAP(masterList.data.featureControlSwitch, 'apCreateNewQuote'),
  isRequestQuote: isFeatureEnabledForAP(masterList.data.featureControlSwitch, 'apRequestQuote'),
  altsErrorModal: get(modal, 'altsErrorModal', {}),
  alterations,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectQuoteType)
