// @flow
import React, { PureComponent, Fragment } from 'react'
import get from 'lodash/get'
import moment from 'moment'
import { Icons } from '@mlcl-digital/mlcl-design'
import {
  Card,
  CardTable,
  CardCell,
  CardGrid,
  CardGridItem,
} from '../../../../molecules/CardChildren'
// component

// utils
import {
  getFullAddressWithState,
  getEmail,
  getPhoneNumberWithCode,
} from '../../../../../utils/contactUtils'
import { CLIENT_SIDE_DATE_FORMAT } from '../../../../../utils/quoteUtils'

const { IconMail24, IconGift24, IconHouse24, IconPhone24 } = Icons

type ClientDetailsComponentProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // clientDetails object
  clientDetails: Object,
}

export class ClientDetailsComponent extends PureComponent<ClientDetailsComponentProps> {
  renderClientDetails = () => {
    const {
      fields: {
        adviserPortalClientDetailsContactDetailsClientDetailsPhoneHeading,
        adviserPortalClientDetailsContactDetailsClientDetailsEmailHeading,
        adviserPortalClientDetailsContactDetailsClientDetailsAddressHeading,
        adviserPortalClientDetailsContactDetailsClientDetailsDateOfBirthHeading,
      },
      clientDetails,
    } = this.props
    const phone = getPhoneNumberWithCode(
      get(clientDetails, 'relatedParty.contactMethods.phones', []),
      'TEL'
    )

    const address = getFullAddressWithState(
      get(clientDetails, 'relatedParty.contactMethods.addresses', [])
    )
    const email = getEmail(get(clientDetails, 'relatedParty.contactMethods.emails', []))
    const dateOfBirth = get(clientDetails, 'relatedParty.dateOfBirth', '-')
    return (
      <Card large>
        <CardGrid>
          <CardGridItem>
            <CardTable>
              <CardCell>
                <IconPhone24 />
                {adviserPortalClientDetailsContactDetailsClientDetailsPhoneHeading}
              </CardCell>
              <CardCell>{phone || '-'}</CardCell>
            </CardTable>
            <CardTable>
              <CardCell>
                <IconMail24 />
                {adviserPortalClientDetailsContactDetailsClientDetailsEmailHeading}
              </CardCell>
              <CardCell>{email || '-'}</CardCell>
            </CardTable>
          </CardGridItem>
          <CardGridItem>
            <CardTable>
              <CardCell>
                <IconGift24 />
                {adviserPortalClientDetailsContactDetailsClientDetailsDateOfBirthHeading}
              </CardCell>
              <CardCell>
                {dateOfBirth !== '' ? moment(dateOfBirth).format(CLIENT_SIDE_DATE_FORMAT) : '-'}
              </CardCell>
            </CardTable>
            <CardTable>
              <CardCell>
                <IconHouse24 />
                {adviserPortalClientDetailsContactDetailsClientDetailsAddressHeading}
              </CardCell>
              <CardCell>{address || '-'}</CardCell>
            </CardTable>
          </CardGridItem>
        </CardGrid>
      </Card>
    )
  }

  render() {
    return <Fragment>{this.renderClientDetails()}</Fragment>
  }
}

export default ClientDetailsComponent
