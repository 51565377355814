// @flow
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { pathOr } from 'lodash/fp'
import get from 'lodash/get'
import moment from 'moment'
import Tabs from '@mlcl-digital/mlcl-design/lib/base/Tabs'
import Tab from '@mlcl-digital/mlcl-design/lib/base/Tab'
import {
  Chip,
  Button,
  ErrorState,
  Hero,
  PageWrap,
  TabWrap,
  Heading,
  Variables,
} from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// molecules
import Modal from '../../molecules/Modal'
import SearchBar from '../../molecules/SearchBar'

// utils.
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { getBancsCustomerNumber } from '../../../utils/cookieUtils'
import history from '../../../utils/browserHistory'
import { insertRecordInArrayAtPosition } from '../../../utils/arrayUtils'
import { sortByKey, sortDate } from '../../../utils/sortingUtils'
import { getAlterationTypeLabel } from '../../../utils/applicationUtils'

// styles.
import styles from './quoteListing.styles'
import QuotesTable from './components/QuotesTable'

// constants
import {
  DEFAULT_PAGE_LIMIT,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_INDEX,
  MANUAL,
  DIGITAL,
} from '../../../constants/forms'
import { NEW_BUSINESS, QUOTE_COLLECTION_NAME, UPDATED_DATE } from '../../../constants/quotes'
import { APPLICATION_TYPES } from '../../../constants/application'
import { ALTERATION_TYPES } from '../../../constants/alterations'
import { DECREASE_ALTS_QUOTE_ROUTE } from '../../../constants/routes'
import { cssEnums } from '../../../styles'

const { fontSize } = Variables

const Card = styled('div')(styles.card)
const SubHeading = styled(Heading)(styles.subHeading)
const SearchBoxContainer = styled('div')(styles.searchBoxContainer)
const DeleteButton = styled(Button)(styles.button)
const ConfirmCoverRemovalBtn = styled(Button)(styles.confirmCoverRemovalBtn)
const Wrap = styled(PageWrap)(styles.wrap, styles.tableRow)
const StyledChip = styled(Chip)(styles.roundPill)
// const CategorySelect = styled(Select)(styles.selectStyle)

const defaultSearchParams = {
  sortBy: UPDATED_DATE,
  orderBy: 'desc',
  offset: DEFAULT_PAGE_NUMBER,
  limit: DEFAULT_PAGE_LIMIT,
  isApplication: false,
}

// TODO: make sitecore authorable
const CSV_HEADERS = [
  { label: 'Quote ID', key: 'quoteId' },
  { label: 'Quote name', key: 'quoteName' },
  { label: 'Number of quotes', key: 'numberOfQuotes' },
  { label: 'Date last updated', key: UPDATED_DATE },
]

const CSV_HEADERS_EXISTING_BUSINESS = [
  ...CSV_HEADERS,
  { label: 'Quote Type', key: 'alterationType' },
]
// Removed Number of Quotes header for CSV
CSV_HEADERS_EXISTING_BUSINESS.splice(2, 1)
const EMPTY = ''

type AdvisorQuoteListingProps = {
  // Sitecore authorable fields.
  fields: {
    quoteListingsSearchHeading: string,
    quoteListingsSearchPlaceholder: string,
    dateLastUpdatedHeader: string,
    quoteIdHeader: string,
    quoteNameHeader: string,
    totalQuotesHeader: string,
    quoteTypeHeader: string,
    removeQuoteModalButton: string,
    removeQuoteModalContent: string,
    removeQuoteModalTitle: string,
    quoteNameHeaderTooltip: string,
    dateLastUpdatedHeaderTooltip: string,
  },
  // An object containing action creator functions.
  actions: {
    initEmptyRows: Function,
    getQuoteSearch: Function,
    getAllQuoteRecords: Function,
    getAllProducts: Function,
    removeQuote: Function,
    resetNavigationType: Function,
    resetFooterType: Function,
    resetAllSavedState: Function,
  },
  productData: Array<Object>,
  search: {
    results: Array<{
      quoteCollectionId: string,
      quoteCollectionName: string,
      state: string,
      updatedDate: string,
      isNonStandard: boolean,
      action: string,
      noOfQuotes: number,
      applicationType: string,
      createdDate: string,
      manualQuoted: boolean,
      lastUpdatedDate?: string,
      alterationType?: string,
    }>,
    totalRecords: number,
    isLoading: boolean,
  },
  advisor: Object,
  alterationTypes: Object,
  location: {
    hash: string,
  },
}

type AdvisorQuoteListingState = {
  searchBarInput: string,
  isModalOpen: boolean,
  quoteIdForDel: string,
  totalRecords: number,
  allRecords: Array<Object>,
  activeTab: string,
  pageSize: number,
  pageIndex: number,
  isDownloadAllQuoteLoading: boolean,
  isNewBusiness: boolean,
}

// Extract the fields needed for table and transform based on selected tab
export const filterAndFormatResults = (
  data: Array<Object>,
  isNewBusiness: boolean,
  alterationTypes: Array<Object>
) =>
  data
    ? data
        .filter(
          ({ applicationType, alterationType }) =>
            !(
              applicationType === APPLICATION_TYPES.DigiAlt &&
              [
                ALTERATION_TYPES.REJECT_CPI,
                ALTERATION_TYPES.SMOKER_STATUS,
                ALTERATION_TYPES.RE_INSTATEMENT,
              ].includes(alterationType)
            )
        )
        .map(
          ({
            quoteCollectionId,
            quoteCollectionName,
            manualQuoted,
            updatedDate,
            noOfQuotes,
            alterationType = '',
          }) => ({
            quoteId: quoteCollectionId,
            quoteType: manualQuoted ? MANUAL : DIGITAL,
            quoteName: quoteCollectionName,
            numberOfQuotes: noOfQuotes || null,
            // Mapping done to updatedDate only
            updatedDate: moment(updatedDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
            ...(!isNewBusiness && {
              alterationType: getAlterationTypeLabel(alterationType, alterationTypes),
              alterationTypeId: alterationType,
            }),
          })
        )
    : []

const csvLinkRef = React.createRef()

export class AdvisorQuoteListing extends Component<
  AdvisorQuoteListingProps,
  AdvisorQuoteListingState
> {
  constructor(props) {
    super(props)
    const {
      fields,
      location: { hash },
    } = props
    // Tab categories
    const { newBusinessTab, existingBusinessTab } = reduceAuthorableFields(fields)
    this.tabs = {
      newBusinessTab,
      existingBusinessTab,
    }
    const tabId = hash ? hash.substring(1) : 'newBusinessTab'

    // TODO: Replace with proper search state.
    this.state = {
      searchBarInput: '',
      isModalOpen: false,
      quoteIdForDel: '',
      totalRecords: 0,
      allRecords: [],
      activeTab: this.tabs[tabId],
      isNewBusiness: tabId === 'newBusinessTab',
      pageIndex: DEFAULT_PAGE_INDEX,
      // @FIXME: state is being used incorrect
      // eslint-disable-next-line react/no-unused-state
      sortBy: [{ id: UPDATED_DATE, desc: false }],
    }
  }

  componentWillMount() {
    const {
      actions: {
        getAllProducts,
        resetNavigationType,
        resetFooterType,
        initEmptyRows,
        resetAllSavedState,
      },
      advisor,
      productData,
    } = this.props
    const bancsAdviserCustomerNo = getBancsCustomerNumber()
    const advisorDetails = get(advisor, 'advisorDetails', [])
    const adviserNumber = get(advisorDetails[0], 'customerDetails.customerNo', '')
    resetNavigationType()
    resetFooterType()
    if (!get(productData, 'length')) {
      getAllProducts()
    }
    initEmptyRows()
    resetAllSavedState(adviserNumber, bancsAdviserCustomerNo)
  }

  componentDidMount() {
    const { isNewBusiness } = this.state
    this.fetchQuotes(isNewBusiness)
    // Handler function to fetch all quotes
    const tagEvent = createEvent({
      GA: {
        category: 'Quotes from dashboard',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Quotes from dashboard - View',
        },
      },
    })
    tagEvent.end()
  }

  fetchQuotes = (isNewBusiness: boolean = true) => {
    const { quoteCollectionName } = this.state
    const {
      actions: { getQuoteSearch },
    } = this.props
    const requestParam = {
      ...defaultSearchParams,
      quoteCollectionName,
      isNewBusiness,
    }

    getQuoteSearch(requestParam, (err, quotes) => {
      if (!err) {
        this.setState({ totalRecords: quotes.totalRecords })
      }
    })
  }

  /** Handler to construct table cell metadata for new business */
  constructColumnsForNewBusinessObject = (
    dateLastUpdatedHeader: string,
    quoteIdHeader: string,
    quoteNameHeader: string,
    totalQuotesHeader: string,
    quoteNameHeaderTooltip: string,
    dateLastUpdatedHeaderTooltip: string,
    removeQuoteModalButton: string
  ) => [
    {
      Header: dateLastUpdatedHeader,
      id: UPDATED_DATE,
      width: cssEnums.AUTO,
      accessor: 'updatedDate',
      className: 'left',
      headerClassName: 'left',
      sortType: sortDate(UPDATED_DATE, null, 'DD-MM-YYYY'),
      tooltip: dateLastUpdatedHeaderTooltip,
    },
    {
      Header: quoteIdHeader,
      id: 'quoteId',
      width: cssEnums.AUTO,
      accessor: 'quoteId',
      Cell: ({ value: quoteId }) => quoteId || '',
      disableSortBy: true,
    },
    {
      Header: quoteNameHeader,
      id: 'quoteName',
      accessor: 'quoteName',
      width: cssEnums.AUTO,
      sortType: sortByKey('quoteName'),
      tooltip: quoteNameHeaderTooltip,
    },
    {
      Header: totalQuotesHeader,
      id: 'numberOfQuotes',
      width: cssEnums.AUTO,
      accessor: 'numberOfQuotes',
      Cell: ({ value: numberOfQuotes }) => <StyledChip>{numberOfQuotes}</StyledChip>,
      className: 'left',
      headerClassName: 'left',
      disableSortBy: true,
    },
    {
      Header: EMPTY,
      id: 'deleteAction',
      width: cssEnums.AUTO,
      accessor: 'quoteId',
      Cell: ({ value: quoteId }) =>
        quoteId ? (
          <DeleteButton
            variant="danger"
            size="small"
            onClick={() => this.handleDeleteAction(quoteId)}
          >
            {removeQuoteModalButton}
          </DeleteButton>
        ) : null,

      className: 'left',
      headerClassName: 'left',
      disableSortBy: true,
    },
  ]

  /** Handler function to construct table cell metadata for Quote type (Alteration type) column */
  constructQuoteTypeColumnHeader = (quoteTypeHeader: string) => [
    {
      Header: quoteTypeHeader,
      id: 'quoteType',
      accessor: 'alterationType',
      width: cssEnums.AUTO,
    },
  ]

  setActiveTab = (tab: string) => {
    const tagEvent = createEvent({
      GA: {
        category: 'View policy details tab',
        action: tab,
      },
      Splunk: {
        attributes: {
          'workflow.name': `View policy details tab - ${tab}`,
        },
      },
    })
    tagEvent.end()
    this.setState({ activeTab: tab })
  }

  setIsNewBusinessFlag = (tab: string) => {
    const isNewBusiness = tab === NEW_BUSINESS
    this.setState({ isNewBusiness })
  }

  getQuoteSearchCallback = requestParam => {
    // Get full record to download - when you are in first page
    const { totalRecords, isNewBusiness } = this.state
    if (totalRecords > 0) {
      const downloadRequestParam = { ...requestParam, offset: 1, limit: totalRecords }
      const {
        actions: { getAllQuoteRecords },
      } = this.props
      getAllQuoteRecords({ ...downloadRequestParam, isNewBusiness }, (error, allQuotes) => {
        if (error) {
          this.setState({ allRecords: [], isDownloadAllQuoteLoading: false })
        } else {
          const { quotes } = allQuotes
          this.setState({ allRecords: [...quotes], isDownloadAllQuoteLoading: false })
          csvLinkRef.current.link.click()
        }
      })
    } else {
      this.setState({ allRecords: [], isDownloadAllQuoteLoading: false })
    }
  }

  // FIXME: Below function is broken due to sorted flag not available in state
  onFetchData = state => {
    const { pageIndex, pageSize, sortBy } = state
    const {
      actions: { getQuoteSearch },
    } = this.props
    const { quoteCollectionName, isNewBusiness } = this.state
    let requestParam
    if (pathOr(0, 'sortBy.length', state) === 0) {
      requestParam = {
        ...defaultSearchParams,
        offset: pageIndex + 1,
        limit: pageSize,
        isNewBusiness,
      }
    } else {
      requestParam = {
        sortBy: sortBy[0].id === 'quoteName' ? QUOTE_COLLECTION_NAME : UPDATED_DATE,
        orderBy: sortBy[0].desc ? 'desc' : 'asc',
        offset: pageIndex + 1,
        limit: pageSize,
        isNewBusiness,
      }
    }
    this.setState({ pageIndex })
    if (quoteCollectionName !== '') {
      requestParam.quoteCollectionName = quoteCollectionName
    }
    getQuoteSearch(requestParam, (err, quotes) => {
      if (!err) {
        this.setState({ totalRecords: quotes.totalRecords, pageIndex })
      }
    })
  }

  changeSearchHandler = (value: string): void => {
    this.setState({
      searchBarInput: value,
      quoteCollectionName: value,
    })
  }

  clickSearchHandler = () => {
    const {
      actions: { getQuoteSearch, initEmptyRows },
      fields,
    } = this.props
    const { existingBusinessTab } = reduceAuthorableFields(fields)
    const { searchBarInput, isNewBusiness } = this.state
    const searchParams = {
      ...defaultSearchParams,
      quoteCollectionName: searchBarInput,
      isNewBusiness,
    }
    initEmptyRows()
    this.setState({ pageIndex: DEFAULT_PAGE_INDEX })
    getQuoteSearch(searchParams)
    if (this.activeTab === existingBusinessTab) {
      const tagEvent = createEvent({
        GA: {
          category: 'List of quotes-digital',
          action: 'Search - Existing business',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'List of quotes-digital - Search - Existing business',
          },
        },
      })
      tagEvent.end()
    }
    const tagEvent = createEvent({
      GA: {
        category: 'List of quotes-digital',
        action: 'Search',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of quotes-digital - Search',
        },
      },
    })
    tagEvent.end()
  }

  // Handles close remove quote modal
  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
    })
  }

  handleDeleteAction = quoteIdForDel => {
    const { fields } = this.props
    const { existingBusinessTab } = reduceAuthorableFields(fields)
    if (this.activeTab === existingBusinessTab) {
      const tagEvent = createEvent({
        GA: {
          category: 'List of quotes-digital',
          action: 'Delete quote - Existing Business',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'List of quotes-digital - Delete quote - Existing Business',
          },
        },
      })
      tagEvent.end()
    }
    this.setState({
      isModalOpen: true,
      quoteIdForDel,
    })
  }

  // Handles confirm removal of quote in modal
  handleCoverDeleteBtn = () => {
    const {
      actions: { removeQuote, getQuoteSearch },
    } = this.props
    const { quoteIdForDel, isNewBusiness } = this.state

    removeQuote({ quoteId: quoteIdForDel }, err => {
      if (!err) {
        // Manually clear search bar if user clicks delete on modal. MM
        this.setState({
          searchBarInput: '',
        })
        getQuoteSearch({ ...defaultSearchParams, isNewBusiness })
      }
    })
    this.handleModalClose()
  }

  tableRowClickHandler = (rowInfo, cell) => {
    const {
      actions: {
        resetExistingClient,
        setAlterationTypeForAlterationsRoute,
        resetAlterations,
        resetCreateQuote,
        resetOccupationInStore,
      },
    } = this.props
    const { activeTab } = this.state
    // Clear existingClient store
    resetExistingClient()
    resetOccupationInStore()
    if (!rowInfo) {
      return
    }
    const {
      original: { quoteId, alterationTypeId },
    } = rowInfo
    const {
      column: { id },
    } = cell
    if (id === 'deleteAction' && quoteId) {
      return
    }
    if (activeTab === NEW_BUSINESS) {
      const tagEvent = createEvent({
        GA: {
          category: 'List of quotes-digital',
          action: 'Navigate to quote summary',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'List of quotes-digital - Navigate to quote summary',
          },
        },
      })
      tagEvent.end()
      quoteId && history.push(`quote-tool?manual=true&quoteId=${quoteId}`)
    } else {
      const tagEvent = createEvent({
        GA: {
          category: 'List of quotes-digital',
          action: 'Navigate to quote summary - Existing Business',
        },
        Splunk: {
          attributes: {
            'workflow.name':
              'List of quotes-digital - Navigate to quote summary - Existing Business',
          },
        },
      })
      tagEvent.end()
      resetAlterations()
      // reset create quote
      resetCreateQuote()
      setAlterationTypeForAlterationsRoute(alterationTypeId)
      quoteId && history.push(`${DECREASE_ALTS_QUOTE_ROUTE}?quoteCollectionId=${quoteId}`)
    }
  }

  handleDownloadAllQuotes = (allRecords: Array, done: Function) => {
    const { fields } = this.props
    const { existingBusinessTab } = reduceAuthorableFields(fields)
    if (this.activeTab === existingBusinessTab) {
      const tagEvent = createEvent({
        GA: {
          category: 'List of quotes-digital',
          action: 'Download Quotes list - Existing Business',
        },
        Splunk: {
          attributes: {
            'workflow.name':
              'List of quotes-digital - Navigate to quote summary - Download Quotes list - Existing Business',
          },
        },
      })
      tagEvent.end()
    }

    const tagEvent = createEvent({
      GA: {
        category: 'List of quotes-digital',
        action: 'Download',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of quotes-digital - Download',
        },
      },
    })
    tagEvent.end()
    if (allRecords.length) {
      done()
    } else {
      done(false)
      this.setState({ isDownloadAllQuoteLoading: true })
      this.getQuoteSearchCallback(defaultSearchParams)
    }
  }

  onSortedChange = () => {
    const tagEvent = createEvent({
      GA: {
        category: 'Application quotes list',
        action: 'Sort',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Application quotes list - Sort',
        },
      },
    })
    tagEvent.end()
  }

  resetAllRecords = () => {
    this.setState({ allRecords: [] })
  }

  render() {
    const { search, fields, alterationTypes } = this.props

    // Tab categories
    const { newBusinessTab } = reduceAuthorableFields(fields)
    const { tabs } = this

    const {
      searchBarInput,
      isModalOpen,
      allRecords,
      isDownloadAllQuoteLoading,
      activeTab = NEW_BUSINESS,
      pageIndex,
      isNewBusiness,
    } = this.state

    // Fetch all Table header columns from sitecore fields
    const {
      dateLastUpdatedHeader,
      quoteIdHeader,
      quoteNameHeader,
      totalQuotesHeader,
      quoteTypeHeader,
      quoteListingsSearchHeading,
      quoteListingsSearchPlaceholder,
      quoteNameHeaderTooltip,
      dateLastUpdatedHeaderTooltip,
      removeQuoteModalButton,
      quoteListingsNoMatchFound,

      quoteListingsNoQuotesFound,
    } = reduceAuthorableFields(fields)

    // Construct column headers
    const columnsForNewBusiness = this.constructColumnsForNewBusinessObject(
      dateLastUpdatedHeader,
      quoteIdHeader,
      quoteNameHeader,
      totalQuotesHeader,
      quoteNameHeaderTooltip,
      dateLastUpdatedHeaderTooltip,
      removeQuoteModalButton
    )
    const quoteTypeColumnHeader = this.constructQuoteTypeColumnHeader(quoteTypeHeader)
    const columnsForExistingBusiness = insertRecordInArrayAtPosition(
      quoteTypeColumnHeader,
      columnsForNewBusiness,
      3
    )

    // Removing Number of quotes header for existing buisness
    columnsForExistingBusiness.splice(4, 1)

    // Format table data
    const formattedResultsPerPage = filterAndFormatResults(
      search.results,
      isNewBusiness,
      alterationTypes
    )
    const allFormattedRecords = filterAndFormatResults(allRecords, isNewBusiness, alterationTypes)
    const pages = Math.ceil(search.totalRecords / DEFAULT_PAGE_LIMIT)
    const isResultsLoading = search.isLoading
    // Table columns
    const columns = isNewBusiness ? columnsForNewBusiness : columnsForExistingBusiness
    const heroAdvisorQuoteFields = {
      heading: get(fields, 'heading'),
      content: get(fields, 'content'),
    }
    return (
      <Fragment>
        <Hero fields={heroAdvisorQuoteFields} />
        <TabWrap>
          <Tabs>
            {Object.keys(tabs).map((eachTab, i) => (
              <Tab
                data-testid={`advisor-quotelisting-tab-${eachTab}`}
                id={tabs[eachTab]}
                hash={eachTab}
                key={eachTab}
                index={i}
                selected={activeTab === tabs[eachTab]}
                clickHandler={() => {
                  const tagEvent = createEvent({
                    GA: {
                      category: 'List of quotes-digital',
                      action:
                        eachTab === newBusinessTab ? 'New Business Tab' : 'Existing Business Tab',
                    },
                    Splunk: {
                      attributes: {
                        'workflow.name': `List of quotes-digital - ${
                          eachTab === newBusinessTab ? 'New Business Tab' : 'Existing Business Tab'
                        }`,
                      },
                    },
                  })
                  tagEvent.end()
                  this.setActiveTab(tabs[eachTab])
                  this.setIsNewBusinessFlag(tabs[eachTab])
                  this.fetchQuotes(tabs[eachTab] === NEW_BUSINESS)
                  this.resetAllRecords()
                }}
              >
                {tabs[eachTab]}
              </Tab>
            ))}
          </Tabs>
        </TabWrap>
        <Wrap>
          <SearchBoxContainer>
            <SearchBar
              title={quoteListingsSearchHeading}
              placeholder={quoteListingsSearchPlaceholder}
              value={searchBarInput}
              clickHandler={this.clickSearchHandler}
              changeHandler={this.changeSearchHandler}
            />
          </SearchBoxContainer>
          {pages === 0 && (
            <Card>
              <ErrorState
                message={
                  searchBarInput && search.totalRecords === 0
                    ? quoteListingsNoMatchFound
                    : quoteListingsNoQuotesFound
                }
                emptyState
                showBackground={false}
              />
            </Card>
          )}
          <QuotesTable
            fields={fields}
            results={formattedResultsPerPage}
            pages={pages}
            pageSize={DEFAULT_PAGE_LIMIT}
            currentPageIndex={pageIndex}
            columns={columns}
            allFormattedRecords={allFormattedRecords}
            csvHeaders={isNewBusiness ? CSV_HEADERS : CSV_HEADERS_EXISTING_BUSINESS}
            isLoading={isResultsLoading}
            isDownloadAllQuoteLoading={isDownloadAllQuoteLoading}
            onFetchData={this.onFetchData}
            onSortedChange={this.onSortedChange}
            tableRowClickHandler={this.tableRowClickHandler}
            downloadQuotesHandler={this.handleDownloadAllQuotes}
            ref={csvLinkRef}
          />
        </Wrap>
        <Modal
          isOpen={isModalOpen}
          title={fields.removeQuoteModalTitle}
          onClose={this.handleModalClose}
        >
          <SubHeading
            variant="h5"
            styleOverrides={{
              fontSize: fontSize.sm,
            }}
          >
            <Text field={fields.removeQuoteModalContent} />
          </SubHeading>
          <ConfirmCoverRemovalBtn type="secondary" onClick={this.handleCoverDeleteBtn}>
            <Text field={fields.removeQuoteModalButton} />
          </ConfirmCoverRemovalBtn>
        </Modal>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ quotes: { search }, advisor, productRules, masterList }) => ({
  search,
  advisor,
  productData: productRules.data,
  alterationTypes: get(masterList, 'data.alterationTypes', []),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvisorQuoteListing))
