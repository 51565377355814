// @flow
import React from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

import { PAGE_CONTENT, PAGE_CONTENT_HEADER } from '../../../constants/placeholders'

const SingleColumn = ({ style, rendering, ...otherProps }) => {
  const { placeholders } = rendering
  return (
    <div className="page-content">
      {placeholders['page-content-header'] && !!placeholders['page-content-header'].length && (
        <Placeholder name={PAGE_CONTENT_HEADER.name} rendering={rendering} {...otherProps} />
      )}
      <Placeholder name={PAGE_CONTENT.name} rendering={rendering} {...otherProps} />
    </div>
  )
}

export default SingleColumn
