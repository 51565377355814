import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontSize } = Variables

const accordionFieldsetCommon = {
  margin: 0,
  padding: 0,
  paddingBottom: space(2),
  borderWidth: 0,
}
const modalSpacing = 2
const modalBorderColour = colours.lightGrey

const styles = {
  subHeading: {
    color: colours.tertiaryTwo,
    fontWeight: 'normal',
  },

  confirmBtn: {
    margin: `${space(3, true)} 0`,
  },
  accordion: {
    marginBottom: space(2),
    marginLeft: space(-2),
    marginRight: space(-2),
    paddingLeft: space(2),
    paddingRight: space(2),
  },
  accordionHeading: {
    fontSize: fontSize.xs,
    lineHeight: 1.5,
    color: colours.mlcAquaAccessible,
  },
  accordionFooter: {
    borderTop: `1px solid ${modalBorderColour}`,
    padding: space(modalSpacing),
  },
  accordionIcon: {
    color: colours.tertiaryOne,
  },
  accordionFieldset: accordionFieldsetCommon,
}

export default styles
