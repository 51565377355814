import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import browserHistory from '../../../../utils/browserHistory'
import { configurePrimaryAuthClient } from '../../../../utils/oktaUtils'
import { actionCreators } from '../../../../actions'

const LoginRedirect = () => {
  const dispatch = useDispatch()
  const config = useSelector(state => state.config)

  const triggerAuth = async () => {
    if (window.authClient.token.isLoginRedirect()) {
      try {
        const authData = await window.authClient.token.parseFromUrl()
        const { tokens, state } = authData
        window.authClient.tokenManager.setTokens(tokens)
        dispatch(actionCreators.authoriseSuccess(tokens.accessToken, state))
      } catch (e) {
        browserHistory.push('/')
        console.error(e)
      }
    } else {
      window.authClient.token.getWithRedirect({
        responseType: ['token'],
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      configurePrimaryAuthClient({}, config)
      triggerAuth()
    }
  }, [])

  return <div />
}

export default LoginRedirect
