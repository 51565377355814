// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import Tabs from '@mlcl-digital/mlcl-design/lib/base/Tabs'
import Tab from '@mlcl-digital/mlcl-design/lib/base/Tab'
import { ErrorState, PageWrap, Button, TabWrap, Card } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// molecules
import PageHeader from '../../molecules/PageHeader'

// styles.
import styles, { styleOverrides } from './taskDashboard.styles'

import NewBusinessComponent from './components/NewBusiness'

// utils.
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
import { downloadAllTasks, csvTaskHeader } from '../../../utils/taskDashboardUtils'
import { idMaker } from '../../../utils/formUtils'

import { CSV_FILE_EXTENSION } from '../../../constants/documentTypes'

const Wrap = styled(PageWrap)(styles.offset)
const createTabs = fields => ({
  [idMaker(fields.newBusinessTabText)]: fields.newBusinessTabText,
})

export class TaskDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: '',
    }
    this.setActiveTab = this.setActiveTab.bind(this)
    const tagEvent = createEvent({
      GA: {
        category: 'Digital task',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Digital task - View',
        },
      },
    })
    tagEvent.end()
    this.csvLinkRef = React.createRef()
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab })
  }

  render() {
    const { fields, taskDashboard } = this.props
    const tasks = get(taskDashboard, 'records', [])
    const { heading, content, downloadTaskButtonText } = fields

    const { downloadTaskFileName, newBusinessTabText, taskDashboardOutstandingRequirementNoTasks } =
      reduceAuthorableFields(fields)
    const { formatTask, maxOutstandingRequirements, isTaskAvailableToDownload } =
      downloadAllTasks(tasks)

    const { activeTab } = this.state
    const tabs = createTabs(reduceAuthorableFields(fields))
    const tabbedComponents = {
      [idMaker(newBusinessTabText)]: (
        <NewBusinessComponent fields={reduceAuthorableFields(fields)} />
      ),
    }

    return (
      <Fragment>
        <PageHeader heading={heading} subHeading={content}>
          <span className="display-none lg-display-block">
            <Button
              onClick={() => {
                this.csvLinkRef.current.link.click()
                const tagEvent = createEvent({
                  GA: {
                    category: 'Download tasks list',
                    action: 'Select',
                  },
                  Splunk: {
                    attributes: {
                      'workflow.name': 'Download tasks list',
                    },
                  },
                })
                tagEvent.end()
              }}
              variant="whiteWithBorder"
              disabled={!isTaskAvailableToDownload}
            >
              {renderTextField(downloadTaskButtonText)}
            </Button>
          </span>
        </PageHeader>
        <TabWrap>
          <Tabs>
            {Object.keys(tabs).map((tab, index) => (
              <Tab
                id={tabs[tab]}
                hash={tabs[tab]}
                dataLocator={tabs[tab]}
                key={tab}
                index={index}
                selected={(activeTab === '' && index === 0) || activeTab === tabs[tab]}
                clickHandler={() => this.setActiveTab(tabs[tab])}
              >
                {tabs[tab]}
              </Tab>
            ))}
          </Tabs>
        </TabWrap>
        <Wrap>
          <CSVLink
            filename={
              !downloadTaskFileName.includes('.csv')
                ? `${downloadTaskFileName}${CSV_FILE_EXTENSION}`
                : downloadTaskFileName
            }
            ref={this.csvLinkRef}
            headers={csvTaskHeader(maxOutstandingRequirements)}
            data={formatTask}
          />
          {activeTab && tabbedComponents[activeTab]
            ? tabbedComponents[activeTab]
            : tabbedComponents[Object.keys(tabs)[0]]}
          {!isTaskAvailableToDownload > 0 && (
            <Card styleOverrides={styleOverrides}>
              <ErrorState
                message={taskDashboardOutstandingRequirementNoTasks}
                emptyState
                showBackground={false}
              />
            </Card>
          )}
        </Wrap>
      </Fragment>
    )
  }
}

TaskDashboard.propTypes = {
  // Sitecore authorable fields.
  fields: PropTypes.objectOf(PropTypes.object).isRequired,
  // outstanding requirement task entity
  taskDashboard: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = ({ taskDashboard: { taskDashboard } }) => ({
  taskDashboard,
})

const { resetNavigationType, resetFooterType } = actionCreators
const mapDispatchToProps = { resetNavigationType, resetFooterType }

export default connect(mapStateToProps, mapDispatchToProps)(TaskDashboard)
