import { Variables } from '@mlcl-digital/mlcl-design'
import {
  space,
  mediaQueries,
  coloursWithOpacity,
  buttonPadding,
  fontSize,
  fontFamily,
} from '../../../styles'

const { colours } = Variables

const styles = {
  container: isCustomer => ({
    marginTop: 0,
    backgroundColor: isCustomer ? colours.mlcAquaDark : colours.mlcAquaCustomer,
    textAlign: 'left',
    padding: `${space(1.5, true)} 0`,
    paddingLeft: space(3, true),
    position: 'relative',
    [mediaQueries.md]: {
      // different margin top in tablet view for
      // avoiding main nav covers notification bar
      marginTop: isCustomer ? space(3, true) : space(6, true),
    },
    [mediaQueries.lg]: {
      textAlign: 'center',
    },
    [mediaQueries.tb]: {
      marginTop: 0,
    },
  }),
  heading: {
    display: 'block',
    marginRight: space(4, true),
    color: colours.tertiarySix,
    [mediaQueries.lg]: {
      display: 'inline-block',
      marginBottom: 0,
      padding: `${space(1.5, true)} 0`,
    },
  },
  closebtn: {
    position: 'absolute',
    top: space(1.6, true),
    right: space(2, true),
    paddingRight: 0,
    '& svg': {
      color: colours.tertiarySix,
    },
  },
  rightButton: isCustomer => ({
    '&:hover, &:focus': {
      backgroundColor: isCustomer
        ? `${coloursWithOpacity(colours.mlcAquaAccessible, 0.6)}`
        : colours.mlcAquaDark,
    },
  }),
  rightLinkBtn: isCustomer => ({
    alignItems: 'center',
    backgroundColor: colours.mlcAquaAccessible,
    border: 'none',
    borderRadius: '2px',
    color: colours.tertiarySix,
    display: 'inline-flex',
    fontFamily: fontFamily.charlie,
    fontSize: fontSize.sm,
    height: '48px',
    justifyContent: 'center',
    lineHeight: '1.5rem',
    paddingBottom: buttonPadding.sm.vertical,
    paddingLeft: buttonPadding.sm.horizontal,
    paddingRight: buttonPadding.sm.horizontal,
    paddingTop: buttonPadding.sm.vertical,
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover, &:focus': {
      backgroundColor: isCustomer
        ? `${coloursWithOpacity(colours.mlcAquaAccessible, 0.6)}`
        : colours.mlcAquaDark,
    },
  }),
}

export default styles
