// @flow
import React, { Fragment } from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'

type ContentBlockProps = {
  fields: Object,
}

const ContentBlock = (props: ContentBlockProps) => {
  const { fields } = props
  return (
    <Fragment>
      <Text tag="h2" className="display-4" field={fields.heading} />

      <RichText className="contentDescription" field={fields.content} />
    </Fragment>
  )
}

export default ContentBlock
