// @flow
import React from 'react'
import AltsContactDetailsV2Organism from '../../../organisms/Alteration/AltsContactDetailsV2'

type AltsContactDetailsV2Props = {
  fields: Object,
  // Params
  params: Object,
  rendering: Object,
}

const AltsContactDetailsV2 = ({ fields, params, rendering }: AltsContactDetailsV2Props) => (
  <AltsContactDetailsV2Organism fields={fields} params={params} rendering={rendering} />
)

export default AltsContactDetailsV2
