import { space, colours, fontWeights } from '../../../../../styles'

const styles = {
  select: {
    maxWidth: space(50, true),
    marginTop: space(4, true),
  },
  individualDayWrapper: {
    maxWidth: space(96, true),
    marginTop: space(4, true),
  },
  contactWrapper: {
    marginTop: space(5, true),
  },
  contactRadioGroup: {
    margin: 0,
    marginTop: space(3, true),
  },
  errorMessage: {
    color: colours.red,

    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: space(1) / 4,
      fontWeight: fontWeights.bold,
    },
  },
}

export default styles
