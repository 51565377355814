import { space, colours, fontWeights } from '../../../../../styles'
import { responsiveTileStyle } from '../../styles'

const styles = {
  wrap: {
    marginTop: -space(3),
    marginBottom: space(11),
  },
  tableRow: {
    '.table-row': {
      cursor: 'pointer',
    },
  },
  articleHeading: {
    fontSize: space(4),
    lineHeight: space(3, true),
    fontWeight: fontWeights.semiBold,
    color: colours.mediumDarkGrey,
  },
  articleDescription: {
    fontSize: space(2),
    lineHeight: space(3, true),
    fontWeight: fontWeights.normal,
    maxWidth: space(94),
    maxHeight: space(93),
  },
  articleCardWrapper: {
    display: 'grid',
    gridGap: space(2),
    marginTop: space(8),
    ...responsiveTileStyle,
  },
  loaderWrap: {
    marginTop: space(11.5),
  },
  tagIcon: {
    marginRight: space(1),
  },
  tagArticleNotAvailable: {
    marginTop: space(5),
  },
}

export default styles
