// @flow
import React from 'react'
// Organism
import TimelineWithComponentsOrganism from '../../../organisms/TimelineWithComponents'

type HeaderBarProps = {
  fields: Object,
}

const HeaderBar = (props: HeaderBarProps) => <TimelineWithComponentsOrganism {...props} />

export default HeaderBar
