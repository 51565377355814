import { space, colours, fontSize, fontWeights } from '../../../../../../styles'

const styles = {
  button: {
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    padding: 0,
  },

  icon: {
    margin: space(1, true),
  },

  wrap: {
    background: colours.white,
    padding: `${space(1.5, true)} ${space(4, true)} ${space(1.5, true)}`,
    color: colours.mediumGreen,
    display: 'block',
    margin: `${space(5, true)} 0`,
    border: `2px dashed ${colours.lightestGrey}`,
  },
}

export default styles
