// @flow
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import queryString from 'query-string'
import { pathOr } from 'lodash/fp'
import get from 'lodash/get'
import {
  Table,
  Chip,
  Tooltip,
  ErrorState,
  Label,
  PageWrap,
  DownloadCSV,
  Icons,
  Button,
} from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// molecules
import Header from '../../molecules/PageHeader'
import SearchBar from '../../molecules/SearchBar'
import WithLoader from '../../molecules/WithLoader'

// utils.
import browser from '../../../utils/browserUtils'
import { dualSort } from '../../../utils/sortingUtils'
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'
import { isStringContainsNumber } from '../../../utils/formUtils'
import {
  getPolicyDetailsByMemberId,
  getPolicyIDBySearchInput,
  sortLifeAssuredByPolicyOwner,
} from '../../../utils/policyUtils'
import { isFeatureEnabledForAP } from '../../../utils/commonUtils'

// styles.
import styles from './clientListing.styles'

// constants
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_NUMBER } from '../../../constants/forms'
import {
  ORDER_BY_DESC,
  ORDER_BY_ASC,
  POLICY_OWNER_KEY,
  POLICY_OWNER,
  GENERATE_REPORTING_PAGE_PATH,
  LIFE_INSURED_KEY,
} from '../../../constants/application'
import { CSV_FILE_EXTENSION } from '../../../constants/documentTypes'

const { IconDownload16, IconWarning16 } = Icons

const Wrap = styled(PageWrap)(styles.wrap, styles.tableRow)
const Card = styled('div')(styles.card)
const IconDisabled = styled(IconDownload16)(styles.iconDisabled)
const DownloadDisabled = styled(Label)(styles.disabled)
// const DownloadCSV = styled(CSVLink)(ButtonStyles.base)
export const ButtonWrapper = styled.div(styles.buttonWrapper)
const IconExclaimation = styled(IconWarning16)(styles.iconExclaimation)
const ErrorCard = styled('div')(styles.errorCard)
//  default search params
const defaultSearchParams = {
  sortBy: POLICY_OWNER_KEY,
  orderBy: ORDER_BY_ASC,
  offset: DEFAULT_PAGE_NUMBER,
  limit: DEFAULT_PAGE_LIMIT,
}

type AdvisorClientListingProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // An object containing action creator functions.
  actions: {
    getMemberSearch: Function,
    resetNavigationType: Function,
    resetFooterType: Function,
    initEmptyClientRows: Function,
  },
  // Array of search results mapped to props from state from member search
  search: {
    results: Array<{
      bancsCustomerNo: String,
      lifeAssured: Array<{
        firstName: String,
        lastName: String,
      }>,
      policyOwner: {
        firstName: String,
        lastName: String,
        contactNo: String | Number,
      },
    }>,
  },
  policyStatus: Array,
  advisorDetails: Array,
  policies: Array,
  searchPolicy: Object,
  controlList: Array,
}

type AdvisorClientListingState = {
  searchInput: string,
  bancsAgencyCodes: Array<string>,
  totalRecords: Number,
}

// TODO: make sitecore authorable
const CSV_HEADERS = [
  { label: 'Policy Owner', key: 'policyOwner' },
  { label: 'Life Insured', key: 'lifeInsured' },
  { label: 'Contact Number', key: 'contactNumber' },
  { label: 'Email Address', key: 'email' },
]
const COLUMNS = [
  {
    Header: 'Policy Owner',
    id: 'policyOwner',
    width: 'auto',
    accessor: 'policyOwner',
    sortType: dualSort('policyOwner', 'lifeInsured'),
  },
  {
    Header: 'Life Insured',
    id: 'lifeInsured',
    accessor: 'lifeInsured',
    sortType: dualSort('lifeInsured', 'policyOwner'),
    width: 'auto',
  },
  {
    Header: 'Contact Number',
    accessor: 'contactNumber',
    width: 'auto',
    className: 'right',
    headerClassName: 'right',
    sortable: false,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    width: 'auto',
    className: 'right',
    headerClassName: 'right',
    sortable: false,
  },
]

const renderLifeAssured = (lifeAssuredList, policyOwnerBancsCustomerNo) => {
  if (!lifeAssuredList || lifeAssuredList.length === 0) return ''

  const sortedLifeAssured = [...lifeAssuredList].sort(item =>
    sortLifeAssuredByPolicyOwner(
      { bancsCustomerNo: policyOwnerBancsCustomerNo },
      item.bancsCustomerNo
    )
  )
  const [firstLifeAssured, ...toolTipLifeAssured] = sortedLifeAssured
  return (
    <>
      {`${firstLifeAssured.firstName} ${firstLifeAssured.lastName}`}
      &nbsp; &nbsp;
      {toolTipLifeAssured.length > 0 && (
        <>
          <Chip data-for={`${policyOwnerBancsCustomerNo}-tooltip`} data-tip variant="info">
            +{toolTipLifeAssured.length}
          </Chip>
          <Tooltip id={`${policyOwnerBancsCustomerNo}-tooltip`}>
            {toolTipLifeAssured.map(({ firstName, lastName }, index) => {
              const itemKey = index
              return (
                <div key={`${firstName}-${itemKey}`}>
                  {firstName} {lastName} <br />
                </div>
              )
            })}
          </Tooltip>
        </>
      )}
    </>
  )
}

const formatLifeAssuredForCSV = (lifeAssuredList, policyOwnerBancsCustomerNo) => {
  if (!lifeAssuredList || lifeAssuredList.length === 0) return ''
  const sortedLifeAssured = [...lifeAssuredList].sort(item =>
    sortLifeAssuredByPolicyOwner(
      { bancsCustomerNo: policyOwnerBancsCustomerNo },
      item.bancsCustomerNo
    )
  )
  return sortedLifeAssured.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ')
}

const formatResults = (data, contactListingsListItemBlank, isCSV) => {
  const formattedData = data.map(({ policyOwner, lifeAssured, isSMSF }) => ({
    policyOwner: `${policyOwner.fullName}`,
    bancsCustomerNo: policyOwner.bancsCustomerNo,
    isSMSF: isSMSF === 'Y',
    contactNumber:
      !policyOwner.contactNumber || !policyOwner.contactNumber.length
        ? contactListingsListItemBlank
        : policyOwner.contactNumber,
    email:
      !policyOwner.email || !policyOwner.email.length
        ? contactListingsListItemBlank
        : policyOwner.email,
    lifeInsured: isCSV
      ? formatLifeAssuredForCSV(lifeAssured, policyOwner.bancsCustomerNo)
      : renderLifeAssured(lifeAssured, policyOwner.bancsCustomerNo),
  }))

  return formattedData
}

const CSV_ASYNC_ON_CLICK = true

export class AdvisorClientListing extends Component<
  AdvisorClientListingProps,
  AdvisorClientListingState
> {
  isIE11 = browser.ie && browser.ie === 11

  constructor(props: AdvisorClientListingProps) {
    super(props)
    this.state = {
      searchInput: '',
      bancsAgencyCodes: [],
      allRecords: [],
      totalRecords: 0,
      hasError: false,
      pageSize: 20,
      pageIndex: 0,
      sortBy: [{ id: POLICY_OWNER, desc: false }],
    }
    this.csvLinkRef = React.createRef()
  }

  componentWillMount() {
    const {
      actions: { resetNavigationType, resetFooterType, initEmptyClientRows },
      advisorDetails,
    } = this.props
    const bancsAgencyCodes = advisorDetails.reduce((agencyCodes, item) => {
      agencyCodes.push(item.bancsAgencyCode)
      return agencyCodes
    }, [])

    const { partyNameLike } =
      history && history.location ? queryString.parse(history.location.search) : ''
    if (partyNameLike) {
      this.setState({
        searchInput: partyNameLike,
        bancsAgencyCodes,
      })
    } else {
      this.setState({
        bancsAgencyCodes,
      })
    }
    // init empty rows
    initEmptyClientRows()
    // set default navigation & footer
    resetNavigationType()
    resetFooterType()
  }

  getMemberSearchCallback = requestParam => {
    // Get full record to download - when you are in first page
    const { totalRecords, bancsAgencyCodes } = this.state
    if (totalRecords > 0) {
      const downloadRequestParam = {
        searchParam: {
          bancsAgencyCodes,
        },
        ...requestParam,
        offset: 1,
        limit: totalRecords,
      }

      const {
        actions: { getAllClientRecords },
      } = this.props
      getAllClientRecords(downloadRequestParam, (err2, allClients) => {
        if (err2) {
          this.setState({ allRecords: [], isDownloadAllClientListLoading: false })
        } else {
          const { clients } = allClients
          this.setState({ allRecords: [...clients], isDownloadAllClientListLoading: false })
          this.csvLinkRef.current.link.click()
        }
      })
    } else {
      this.setState({ allRecords: [], isDownloadAllClientListLoading: false })
    }
  }

  onFetchData = state => {
    const {
      actions: { getMemberSearch },
    } = this.props
    const { searchInput, bancsAgencyCodes } = this.state
    const searchParam = {
      bancsAgencyCodes,
      ...(searchInput
        ? {
            partyNameLike: `${searchInput}`,
          }
        : {}),
    }
    let requestParam
    if (pathOr(0, 'sortBy.length', state) === 0) {
      requestParam = {
        ...defaultSearchParams,
        offset: state.pageIndex + 1,
        limit: state.pageSize,
        searchParam,
      }
    } else {
      requestParam = {
        sortBy: state.sortBy[0].id === POLICY_OWNER ? POLICY_OWNER_KEY : LIFE_INSURED_KEY,
        orderBy: state.sortBy[0].desc ? ORDER_BY_DESC : ORDER_BY_ASC,
        offset: state.pageIndex + 1,
        limit: state.pageSize,
        searchParam,
      }
    }
    this.setState({ pageIndex: state.pageIndex, sortBy: state.sortBy })
    getMemberSearch(requestParam, (err, clients) => {
      if (!err) {
        this.setState({ totalRecords: clients.totalRecords, pageIndex: state.pageIndex })
      }
    })
  }

  changeHandler = (value: string): void => this.setState({ searchInput: value, hasError: false })

  clickSearchHandler = (): void => {
    const {
      actions: { getMemberSearch, searchPolicies },
    } = this.props
    const { searchInput, bancsAgencyCodes } = this.state

    let requestParam = {}
    this.setState({ pageIndex: 0 })
    const tagEvent = createEvent({
      GA: {
        category: 'Search for clients',
        action: 'AP - search for clients',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Search for clients',
        },
      },
    })
    tagEvent.end()
    requestParam = {
      ...defaultSearchParams,
      searchParam: {
        bancsAgencyCodes,
        partyNameLike: `${searchInput}`,
      },
    }
    if (!isStringContainsNumber(searchInput)) {
      getMemberSearch(requestParam)
    } else if (searchInput === '') {
      delete requestParam.searchParam.partyNameLike
      return getMemberSearch(requestParam)
    } else {
      searchPolicies(searchInput, (err, data) => {
        // handle error here
        if (err) {
          return this.setState({ hasError: true })
        }
        return getPolicyDetailsByMemberId(data, this.getAdviserPolicy)
      })
    }
    return null
  }

  getAdviserPolicy = (bancsPartyNo, isSMSF) => {
    const {
      policyStatus,
      actions: { getPolicies },
    } = this.props
    const { searchInput } = this.state
    getPolicies(bancsPartyNo, isSMSF, data => {
      const { businessData } = data
      if (!businessData) {
        return this.setState({ hasError: true })
      }
      const { policies } = businessData
      const policyId = getPolicyIDBySearchInput(policies, policyStatus, searchInput)
      if (!policyId) {
        return this.setState({ hasError: true })
      }
      // redirect on policy details
      const path = `/clients/details?clientId=${bancsPartyNo}&policyId=${policyId}&isSMSF=${isSMSF}&fromSearch=true`
      return history.push(path)
    })
  }

  handleGenerateReport = () => {
    // redirect to reporting page
    history.push(GENERATE_REPORTING_PAGE_PATH)
  }

  handleTableRowClick = rowData => {
    if (!rowData) return
    const {
      original: { bancsCustomerNo, isSMSF },
    } = rowData
    const tagEvent = createEvent({
      GA: {
        category: 'Access client details',
        action: 'AP - access client details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Access client details',
        },
      },
    })
    tagEvent.end()
    history.push(`/clients/details?clientId=${bancsCustomerNo}&isSMSF=${isSMSF}`)
  }

  handleDownloadAllClientList = (allRecords: Array, done: Function) => {
    const tagEvent = createEvent({
      GA: {
        category: 'Download client list in csv',
        action: 'Download list',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Access client details',
        },
      },
    })
    tagEvent.end()
    if (allRecords.length) {
      done()
    } else {
      done(false)
      this.setState({ isDownloadAllClientListLoading: true })
      this.getMemberSearchCallback(defaultSearchParams)
    }
  }

  render() {
    const { search, fields, searchPolicy, policies, controlList } = this.props

    // break-word does not work in IE11 if no hyphen, so break long emails at @

    if (this.isIE11) {
      search.results = search.results.map(r => {
        const result = { ...r }
        if (
          result.policyOwner &&
          result.policyOwner.email &&
          result.policyOwner.email.length >= 30
        ) {
          result.policyOwner = {
            ...result.policyOwner,
            email: result.policyOwner.email.replace(/@/, '\n@'),
          }
        }

        return result
      })
    }

    const {
      searchInput,
      allRecords,
      isDownloadAllClientListLoading,
      hasError,
      pageIndex,
      sortBy,
      pageSize,
    } = this.state
    const reducedFields = reduceAuthorableFields(fields)
    const {
      contactListingsListItemBlank,
      contactListingsSearchHeading,
      contactListingsSearchPlaceholder,
      contactListingsDownloadTitle,
      clientListingsNoClientFoundText,
      clientListingsNoMatchFoundText,
      clientListingsGenerateReport,
      clientListingsGenerateReportFileName,
      clientListingNoResultErrorMessage,
    } = reducedFields

    // initialize label with blank for initial row
    const blankLabel =
      search.results &&
      search.results.length &&
      search.results[0].policyOwner.bancsCustomerNo === ''
        ? ''
        : contactListingsListItemBlank

    const results =
      search.results && search.results.length ? formatResults(search.results, blankLabel) : []

    const pages =
      search && search.totalRecords
        ? Math.ceil(search.totalRecords / pageSize)
        : DEFAULT_PAGE_NUMBER
    const isResultsLoading = search.isLoading
    const isSearchPolicyLoading = get(searchPolicy, 'isFetchingSearchPolicy', false)
    const isPolicyLoading = get(policies, 'isFetchingPolicy', false)

    // get report control switch from masterdata api
    const showGenerateReportBtn = isFeatureEnabledForAP(controlList, 'enableGenerateReport')

    return (
      <Fragment>
        <Header heading={fields.heading} subHeading={fields.content}>
          {showGenerateReportBtn && (
            <ButtonWrapper>
              <Button variant="whiteWithBorder" onClick={this.handleGenerateReport}>
                {clientListingsGenerateReport}
              </Button>
            </ButtonWrapper>
          )}
        </Header>
        <Wrap>
          <SearchBar
            title={contactListingsSearchHeading}
            placeholder={contactListingsSearchPlaceholder}
            value={searchInput}
            clickHandler={this.clickSearchHandler}
            changeHandler={this.changeHandler}
          />
          {hasError ? (
            <ErrorCard>
              <IconExclaimation />
              <span>{clientListingNoResultErrorMessage}</span>
            </ErrorCard>
          ) : null}
          {results.length === 0 && (
            <Card>
              <ErrorState
                message={
                  search && searchInput && sortBy && search.totalRecords === 0
                    ? clientListingsNoMatchFoundText
                    : clientListingsNoClientFoundText
                }
                emptyState
                showBackground={false}
              />
            </Card>
          )}

          {results.length ? (
            <Table
              classes="table-row"
              clickHandler={this.handleTableRowClick}
              data={results}
              columns={COLUMNS}
              isManual
              pageSize={pageSize}
              onPageChange={this.onFetchData}
              currentIndex={pageIndex}
              isLoading={isSearchPolicyLoading || isPolicyLoading || isResultsLoading}
              pages={pages}
              styleOverrides={{
                tableBody: styles.tableBody,
                base: styles.tableBase,
                headerCell: styles.headerCell,
              }}
              defaultSorted={sortBy}
              footer={
                results.length ? (
                  <WithLoader
                    isLoading={isDownloadAllClientListLoading}
                    loaderProps={{ type: 'noHeight', spinnerSize: 20 }}
                  >
                    <DownloadCSV
                      filename={`${clientListingsGenerateReportFileName}${CSV_FILE_EXTENSION}`}
                      ref={this.csvLinkRef}
                      asyncOnClick={CSV_ASYNC_ON_CLICK}
                      headers={CSV_HEADERS}
                      data={formatResults(allRecords, blankLabel, true)}
                      onClick={(event, done) => this.handleDownloadAllClientList(allRecords, done)}
                    >
                      {contactListingsDownloadTitle}
                    </DownloadCSV>
                  </WithLoader>
                ) : (
                  <Fragment>
                    <IconDisabled />
                    <DownloadDisabled>{contactListingsDownloadTitle}</DownloadDisabled>
                  </Fragment>
                )
              }
            />
          ) : (
            []
          )}
        </Wrap>
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  client: { search },
  advisor: { advisorDetails },
  searchPolicy,
  policies,
  masterList,
}) => ({
  search,
  advisorDetails,
  searchPolicy,
  policies,
  policyStatus: get(masterList, 'data.policyStatus', []),
  controlList: get(masterList, 'data.featureControlSwitch', []),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdvisorClientListing, 'button')
)
