// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import Button from '../../atoms/Button'
import Card from '../../atoms/Card'
import Heading from '../../atoms/Heading'

import InputPhone from '../../molecules/InputPhone'
import Header from '../../molecules/PageHeader'

// schema.
import SCHEMA, { FORM_ID } from './updateContactDetails.schema'

// helpers.
import { errorCheck, getValue, validateAll } from '../../../utils/formUtils'
import { getCountryCode } from '../../../utils/contactUtils'
import { renderTextField } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'

// styles.
import styles from './updateContactDetails.styles'

// contants
import { DEFAULT_PHONE_CODE, DEFAULT_PHONE_ENTITY } from '../../../constants/contactDetails'
import {
  TOAST_ID_UPDATE_CONTACT_INFO,
  TOAST_ID_UPDATE_CONTACT_ERROR,
} from '../../../constants/toast'
import { NAVIGATION_ONLY_LOGO } from '../../../constants/navigation'
import { DASHBOARD_ROUTE } from '../../../constants/routes'

const Wrap = styled(PageWrap)(styles.offset)
const FormContainer = styled(Card)(styles.base)
const Form = styled('form')(styles.body)
const Section = styled('div')(styles.section)
const HeadingWrap = styled('div')(styles.headingWrap)

type UpdateContactDetailsProps = {
  // Sitecore authorable fields.
  fields: Object,
  // Redux suplied form state
  form: Object,
  // An object containing action creator functions.
  actions: {
    updateAdvisorDetails: Function,
    formSubmit: Function,
    formInit: Function,
    formSubmitComplete: Function,
    formUpdateValidations: Function,
    changeNavigationType: Function,
    formUpdateField: Function,
    formValidate: Function,
  },
  // master list data
  masterList: Object,
  // determines if the advisor data for update action is still being fetched from the server.
  isUpdating: boolean,
  // determines if welcome journey is completed
  hasCompletedWelcomeJourney: boolean,
}

export class UpdateContactDetails extends Component<UpdateContactDetailsProps> {
  constructor(props: Object) {
    super(props)
    const { actions, fields } = props
    const { formInit } = actions
    const schema = SCHEMA(fields)
    formInit(FORM_ID, schema, {
      mobile: '',
      mobilePhoneCode: DEFAULT_PHONE_CODE,
    })
  }

  componentDidMount() {
    const {
      actions: { changeNavigationType },
    } = this.props
    const { hasCompletedWelcomeJourney } = this.props
    if (!hasCompletedWelcomeJourney) {
      changeNavigationType(NAVIGATION_ONLY_LOGO)
    }
  }

  getUpdateDetailsPayload = (): Object => {
    const { form, masterList } = this.props
    const { mobile, mobilePhoneCode } = form.fields
    const mobileIdc = getValue(mobilePhoneCode.value)
    const phonesPayload = [
      {
        ...DEFAULT_PHONE_ENTITY,
        preferred: 'N',
        number: mobile.value,
        idc: getValue(mobilePhoneCode.value),
        countryCode: getCountryCode(mobileIdc, masterList),
      },
    ]

    const payload = {
      contactMethods: {
        phones: phonesPayload,
      },
    }

    return payload
  }

  handleSubmit = (): void => {
    const { form, actions, fields } = this.props
    const { formSubmit, formSubmitComplete, updateAdvisorDetails, formUpdateValidations } = actions
    const updateDetailsPayload = this.getUpdateDetailsPayload()

    if (form.isSending) return
    const schema = SCHEMA(fields)
    formUpdateValidations(FORM_ID, schema)
    formSubmit(FORM_ID, schema, () => {
      updateAdvisorDetails(updateDetailsPayload, (data, err) => {
        if (err) {
          toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
            toastId: TOAST_ID_UPDATE_CONTACT_INFO,
            type: toast.TYPE.ERROR,
          })
        } else {
          history.push(DASHBOARD_ROUTE)
        }
      })
    })

    if (validateAll(schema, form.fields)) {
      formSubmitComplete(FORM_ID)
    }
  }

  // handle changes on form elements.
  handleChange = ({ value, name }: Object): void => {
    const { actions, fields } = this.props
    const { formUpdateField, formValidate } = actions
    const schema = SCHEMA(fields)
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg
      )
      if (charactersCheck.error) return
    }

    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  render() {
    const { form, fields, isUpdating } = this.props

    if (!form) return null

    const { mobile, mobilePhoneCode } = form.fields
    const { ConfirmCTA } = fields

    const selectMobilePhoneCode = {
      label: renderTextField(fields.PhoneCodeLabel),
      value: mobilePhoneCode.value,
      name: 'mobilePhoneCode',
      id: 'mobilePhoneCode',
      changeHandler: this.handleChange,
    }
    const inputMobileNumber = {
      htmlFor: 'mobile',
      label: renderTextField(fields.MobileLabel),
      name: 'mobile',
      phonePlaceholder: fields.MobilePlcHolderText,
      changeHandler: this.handleChange,
      error: mobile.error.error,
      value: mobile.value,
      caption: mobile.error.error && mobile.error.errorMsg,
    }

    return (
      <Fragment>
        <Header heading={fields.Title} subHeading={fields.Subtitle} />
        <Wrap>
          <FormContainer>
            <HeadingWrap>
              <Heading size="3" element="2">
                {renderTextField(fields.ContactDetailsLabel)}
              </Heading>
            </HeadingWrap>
            <Form id="client" aria-labelledby="client-label">
              <Section>
                <InputPhone
                  selectPhoneCode={selectMobilePhoneCode}
                  inputPhoneNumber={inputMobileNumber}
                />
              </Section>
              <div>
                <Button type="secondary" onClick={this.handleSubmit} isLoading={isUpdating}>
                  {renderTextField(ConfirmCTA)}
                </Button>
              </div>
            </Form>
          </FormContainer>
        </Wrap>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  masterList: { data },
  advisor,
  authentication: { hasCompletedWelcomeJourney },
}: Object) => ({
  form: forms[FORM_ID],
  masterList: { data },
  isUpdating: advisor.isUpdating,
  hasCompletedWelcomeJourney,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContactDetails)
