// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, mediaQueries } = Variables

const styles = {
  headingWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: space(6, true),
    [mediaQueries.md]: {
      marginBottom: space(6, true),
    },
  },
  progressTimelineWrap: {
    padding: `0 ${space(4, true)} ${space(6, true)} 0`,
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
    },
  },
  pageWrapper: {
    background: colours.tertiarySix,
  },
}

export default styles
