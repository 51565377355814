// @flow
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Modal, Button } from '@mlcl-digital/mlcl-design'

// selectors
import { getIsManualQuoteModal } from '../../../../../../selectors/alterations'

// utils
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// styles
import styles from './manualQuoteModal.styles'

// actions
import { actionCreators } from '../../../../../../actions'

// styled components
const ModalFooter = styled('div')(styles.modalFooter)
const CancelButton = styled(Button)(styles.cancelButton)

type manualQuoteModalProps = {
  fields: Object,
}

const ManualQuoteModal = ({ fields }: manualQuoteModalProps) => {
  const dispatch = useDispatch()
  const isManualQuoteModal = useSelector(getIsManualQuoteModal)
  const handleClose = () => {
    dispatch(actionCreators.resetAltsCalculateFailureCount())
    dispatch(actionCreators.setIsAltsCalculateErrorModal(false))
  }
  const handleCreateManualQuote = () => {
    dispatch(
      actionCreators.openSidebar(
        'requestAltsManualQuote',
        undefined,
        undefined,
        undefined,
        undefined,
        'ChangeCoverSidebar'
      )
    )
    handleClose()
  }
  return (
    <Modal
      isOpen={isManualQuoteModal}
      title={fields.ManualQuoteModalHeading}
      onClose={handleClose}
      footer={
        <ModalFooter>
          <CancelButton variant="tertiary" size="small" onClick={handleClose}>
            {renderTextField(fields.CancelCTA)}
          </CancelButton>
          <Button variant="secondary" size="small" onClick={handleCreateManualQuote}>
            {renderTextField(fields.ManualQuoteModalButton)}
          </Button>
        </ModalFooter>
      }
    >
      <div>{renderTextField(fields.ManualQuoteModalDescription)}</div>
    </Modal>
  )
}

export default ManualQuoteModal
