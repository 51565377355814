// @flow
import React from 'react'
import { Global, css } from '@emotion/core'

import {
  fontFamily,
  CharlieStdFont,
  colours,
  space,
  mediaQueries,
  QUOTE_LEFT_SIDEBAR_WIDTH,
} from '..'
import headings from '../headings'
import { CHARLIE_STD, BASE_FONT_SIZE } from '../../constants/fonts'

const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: ${CHARLIE_STD};
        src: url(${CharlieStdFont.eot}); /* IE9 Compat Modes */
        src: local('CharlieStd-SemiBold'), local('CharlieStd-SemiBold'),
          url(${CharlieStdFont.eot}?#iefix) format('embedded-opentype'),
          url(${CharlieStdFont.woff2}) format('woff2'),
          /* Super Modern Browsers that supports woff2 */ url(${CharlieStdFont.woff}) format('woff'),
          /* Super Modern Browsers */ url(${CharlieStdFont.ttf}) format('truetype'),
          /* Modern Browsers */ url(${CharlieStdFont.svg}) format('svg'); /* Safari, Android, iOS, Legacy iOS */
        font-style: normal;
        font-weight: 500;
      }

      body {
        font-family: ${fontFamily.sourceSans};
        font-size: ${BASE_FONT_SIZE}px;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        color: ${colours.darkGrey};
      }

      h1 {
        ${headings.h1};
      }

      h2 {
        ${headings.h2};
      }

      h3 {
        ${headings.h3};
      }

      h4 {
        ${headings.h4};
      }

      h5 {
        ${headings.h5};
      }

      h6 {
        ${headings.h6};
      }

      small {
        ${headings.small};
      }

      button {
        cursor: pointer;
      }

      dl,
      dt,
      dd,
      ul,
      li,
      p {
        margin: 0;
        padding: 0;
      }

      p {
        margin-top: 0;
        margin-bottom: ${space(2, true)};
      }
      .grecaptcha-badge {
        z-index: 10;
      }

      .rich-text > iframe {
        max-width: 100%;
        height: auto;
      }

      /**
       * Global Remove Elements
       * ============== START ==============
       */
      .display-none {
        display: none;
      }
      ${mediaQueries.xs} {
        .xs-display-none {
          display: none;
        }
      }
      ${mediaQueries.sm} {
        .sm-display-none {
          display: none;
        }
      }
      ${mediaQueries.md} {
        .md-display-none {
          display: none;
        }
      }
      ${mediaQueries.lg} {
        .lg-display-none {
          display: none;
        }
      }
      ${mediaQueries.xl} {
        .xl-display-none {
          display: none;
        }
      }
      /**
       * ============== END ==============
       */

      /**
       * Global Block Elements
       * ============== START ==============
       */
      .display-block {
        display: block;
      }
      ${mediaQueries.xs} {
        .xs-display-block {
          display: block;
        }
      }
      ${mediaQueries.sm} {
        .sm-display-block {
          display: block;
        }
      }
      ${mediaQueries.md} {
        .md-display-block {
          display: block;
        }
      }
      ${mediaQueries.lg} {
        .lg-display-block {
          display: block;
        }
      }
      ${mediaQueries.xl} {
        .xl-display-block {
          display: block;
        }
      }
      /**
       * ============== END ==============
       */

      /**
       * Global General Classes
       * ============== START ==============
       */
      .no-margin {
        margin: 0;
      }
      .no-padding {
        padding: 0;
      }
      /**
       * ============== END ==============
       */

      /**
       * Fixed Left Sidebar on Quote pages
       * ============== START ==============
       */
      #quote-tool-footer,
      #quote-summary-footer {
        margin-left: ${QUOTE_LEFT_SIDEBAR_WIDTH};
      }
      #quote-tool-footer #quote-tool-footer-disclaimer,
      #quote-summary-footer #quote-summary-footer-disclaimer {
        background-color: ${colours.lightestGrey};
        color: ${colours.mediumGrey};
        padding-bottom: ${space(3, true)};
        padding-top: ${space(3, true)};
      }
      #quote-tool-footer-disclaimer > div,
      #quote-summary-footer-disclaimer > div {
        margin: 0;
        padding: 0 ${space(3, true)};
      }
      /**
       * ============== END ==============
       */
      /**
        * Overriding Page Container Styling 
        * ============== START ==============
        * TODO: Temporary fix. Should be removed once all the pages are converted to same background.
        */
      .page-container-white {
        background-color: ${colours.white} !important;
      }
      /**
       * ============== END ==============
       */
    `}
  />
)

export default GlobalStyles
