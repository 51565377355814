// @flow
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { PageWrap } from '@mlcl-digital/mlcl-design'
import Timeline from '@mlcl-digital/mlcl-design/lib/base/Timeline'

import { actionCreators } from '../../../actions'

import { makeTimelineData } from '../../../utils/timelineWithComponentUtils'

import styles from './timelineWithComponents.styles'

const PageWrapper = styled('div')(styles.pageWrapper)

type TimelineWithComponentsProps = {
  fields: Object,
  variant: string,
}
const TimelineWithComponents = ({ fields, variant }: TimelineWithComponentsProps) => {
  const timelineWithComponentsState = useSelector(state => state.timelineWithComponents)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actionCreators.setTimelineData(makeTimelineData(fields.Content)))
  }, [])
  const { data, activeIndex } = timelineWithComponentsState
  return (
    data && (
      <PageWrapper variant={variant}>
        <PageWrap>
          <Timeline data={data} activeIndex={activeIndex} variant={variant} />
        </PageWrap>
      </PageWrapper>
    )
  )
}

export default TimelineWithComponents
