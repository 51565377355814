import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, fontFamily, fontWeights, getButtonVariant, buttonSizes } = Variables
const styles = {
  base: ({ theme, variant, size }) => ({
    textAlign: 'center',
    border: 'none',
    textDecoration: 'none',
    justifyContent: 'center',
    display: 'inline-flex',
    letterSpacing: 1,
    alignItems: 'center',
    lineHeight: 1.33,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.sourceSans,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    ...buttonSizes[size],
    ...getButtonVariant(variant, theme),
  }),
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
    color: colours.tertiaryTwo,
    backgroundColor: colours.tertiaryThree,
  },
  transparent: {
    background: 'transparent',
  },
  spinner: {
    minHeight: 0,
  },
}

export default styles
