// @flow
import get from 'lodash/get'

// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  CUSTOMER_DETAILS_FETCH,
  CUSTOMER_CONTACT_DETAILS_UPDATE,
  CUSTOMER_CONTACT_DETAILS_UPDATE_RESET,
} from '../types/customerPersonalDetails'

// utils.
import { getBancsCustomerNumber } from '../../utils/cookieUtils'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const getCustomerDetails = () => ({
  type: EXPERIENCE_API,
  name: CUSTOMER_DETAILS_FETCH,
  verb: 'GET',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${getBancsCustomerNumber()}`,
})

export const resetCustomerContactDetailsHasUpdateError = () => ({
  type: CUSTOMER_CONTACT_DETAILS_UPDATE_RESET,
})

export const updateContactDetails = (data, callback) => ({
  type: EXPERIENCE_API,
  name: CUSTOMER_CONTACT_DETAILS_UPDATE,
  verb: 'PUT',
  data: {
    member: data,
  },
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${getBancsCustomerNumber()}`,
  callback: (err, dataReceived) => {
    const isError = get(err, 'status.type', '') === 'error'
    callback(dataReceived, isError)
    if (!isError) {
      return dataReceived
    }
    return false
  },
})
