import { Variables } from '@mlcl-digital/mlcl-design'

const { pxToRem, colours, fontFamily, mediaQueries, space } = Variables

const styles = {
  selectWrap: noOfOptions => ({
    fontFamily: fontFamily.charlie,
    marginBottom: 0,
    background: colours.mlcAquaDark,
    zIndex: 2,
    position: 'relative',
    padding: space(0.5),
    [mediaQueries.xs]: {
      width: pxToRem(224),
    },
    [mediaQueries.md]: {
      width: pxToRem(269),
    },
    '.mlc__menu-list': {
      maxHeight: noOfOptions * 50,
    },
  }),
}

export default styles
