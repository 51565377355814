// @flow
import React from 'react'

// components.
import KnowledgeBaseComponent from '../../../organisms/KnowledgeBase'

type KnowledgeBaseProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const KnowledgeBase = ({ fields }: KnowledgeBaseProps) => <KnowledgeBaseComponent fields={fields} />

export default KnowledgeBase
