// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// utils
import history from '../../../../../utils/browserHistory'

// atoms.
import Button from '../../../../atoms/Button'
import Panel from './Panel'
import CustomerInfo from './CustomerInfo'

// styles.
import styles from './leftPanel.styles'

// constants
import {
  QUOTE_STATUS_QUOTE_STAGE,
  QUOTE_STATUS_APPLICATION_STAGE,
} from '../../../../../constants/policies'
import {
  SAVE_QUOTE_COLLECTION,
  CONVERT_TO_DRAFT_APPLICATION,
  APPLICATION_STAGE_STEP,
} from '../../../../../constants/application'

type QuoteSummarySidebarProps = {
  // Sitecore fields
  fields: Object,
  // Create quote data which user has selected.
  quoteData: Object,
  // An object containing action creator functions.
  actions: Object,
  // Quote Action
  quoteAction: String,
  // Master data from Sitecore
  masterData: Object,
}

const PannelBtnWrapper = styled('div')(styles.pannelBtnWrapper)
const QuoteButton = styled(Button)(styles.quoteButton)

/**
 * QuoteSummarySidebar
 * This component will render the page left sidebar containing customers info,
 * ocuupation details, etc.
 */
export class QuoteSummarySidebar extends Component<QuoteSummarySidebarProps> {
  /**
   * handleContinueToApplication
   * method to redirect to application page
   */
  handleContinueToApplication = () => {
    const {
      actions: { updateQuoteStatus, updateQuoteIDToAction, saveQuote, updateApplicationStage },
      quoteAction,
      quoteData: { type, quoteId },
    } = this.props
    const quoteStatus = {
      actionName:
        quoteAction === SAVE_QUOTE_COLLECTION ? CONVERT_TO_DRAFT_APPLICATION : quoteAction,
      quoteType: type === QUOTE_STATUS_QUOTE_STAGE ? QUOTE_STATUS_APPLICATION_STAGE : type,
      underwritingMethod: null,
    }
    // update quote status
    updateQuoteStatus(quoteStatus)
    // update quote id to quote action
    if (quoteId) {
      updateQuoteIDToAction(quoteId)
    }
    // save quote
    saveQuote(() => {
      // logic to redirection
      const viewApplicationPath = '/application'
      history.replace(viewApplicationPath)
    })
    // update application
    updateApplicationStage(0, {
      stage0: APPLICATION_STAGE_STEP.completed,
    })
  }

  render() {
    const { fields, quoteData, masterData } = this.props
    const { quoteSummaryPannelBtnLabel } = fields

    return (
      <Panel>
        <CustomerInfo masterData={masterData} fields={fields} quoteData={quoteData} />
        <PannelBtnWrapper>
          <QuoteButton
            data-testid="continueBtn"
            onClick={this.handleContinueToApplication}
            type="primary"
          >
            {quoteSummaryPannelBtnLabel}
          </QuoteButton>
        </PannelBtnWrapper>
      </Panel>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(QuoteSummarySidebar)
