import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { space, mediaQueries } = Variables as VariablesType
const styles = {
  heading: {
    marginBottom: space(5),
  },
  ineligiblePoliciesContainer: {
    marginTop: space(12),
  },
  ineligiblCardContainer: {
    marginBottom: space(3),
    [mediaQueries.lg]: {
      marginBottom: space(4),
    },
  },
}

export default styles
