// @flow
import { EXPERIENCE_API_VERSION_1, EXPERIENCE_API_BSB_BASE } from '../../constants/apis'
import { EXPERIENCE_API } from '../types/experienceApi'
import { VALIDATE_BSB, INIT_BSB_LOOKUP } from '../types/bsbLookup'

export const initBsbLookup = fieldName => ({
  type: INIT_BSB_LOOKUP,
  payload: { fieldName },
})

export const validateBsb = field => dispatch => {
  dispatch({
    type: VALIDATE_BSB,
    payload: { field },
  })
  dispatch({
    type: EXPERIENCE_API,
    name: VALIDATE_BSB,
    verb: 'GET',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BSB_BASE}/${field.value}`,
    callback: (err, dataReceived) => ({
      field,
      dataReceived,
    }),
    errorPayload: {
      field,
    },
  })
}
