import { fontSize, colours, space, mediaQueries, fontWeights } from '../../../../../../styles'

const styles = {
  container: {
    borderTopWidth: 0,
    paddingTop: space(3),
    [mediaQueries.md]: {
      paddingLeft: space(15),
    },
    input: {
      borderColor: colours.mediumGrey,
    },
    label: {
      fontWeight: fontWeights.normal,
    },
    h6: {
      paddingBottom: space(2),
      fontSize: fontSize.sm,
    },
  },
  title: {
    display: 'none',
    [mediaQueries.md]: {
      paddingBottom: space(2),
      fontSize: fontSize.sm,
      display: 'block',
    },
  },
  innerHolder: {
    width: '100%',
    display: 'inline-block',
    padding: '2px 0',
    paddingBottom: space(1.5),
    [mediaQueries.md]: {
      width: '50%',
      paddingBottom: space(3),
      verticalAlign: 'top', // IE11 alignment
    },

    '&:last-child': {
      paddingBottom: space(3),
    },
  },
  containerToggleGroup: {
    label: {
      fontSize: fontSize.xs,
      fontWeight: fontWeights.semiBold,
    },
    'label:first-Child': {
      fontSize: fontSize.xxs,
      fontWeight: fontWeights.normal,
    },
  },
  halfWidth: {
    flex: '0 1 50%',
    width: '50%',
    '&&': {
      flex: '0 1 50%',
      width: '50%',
    },
  },
  subTitle: {
    color: colours.darkGrey,
    fontSize: fontSize.xxs,
    fontWeight: fontWeights.normal,
    margin: 0,
    paddingBottom: space(0.5),
  },
  form: {
    maxWidth: '450px',
  },
  footer: {
    marginTop: space(6),
    button: {
      width: '100%',
      marginBottom: space(3),
      cursor: 'pointer',
    },
    [mediaQueries.md]: {
      button: {
        width: '30%',
      },
    },
  },
  panelLabel: {
    color: colours.darkGrey,
  },
  text: {
    paddingBottom: space(3),
    fontWeight: fontWeights.normal,
  },
}

export default styles
