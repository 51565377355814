// @flow

import {
  DOWNLOAD_POLICIES,
  DOWNLOAD_POLICIES_SUCCESS,
  DOWNLOAD_POLICIES_ERROR,
  DOWNLOAD_POLICIES_FAILURE,
} from '../actions/types/clientReport'

export const initialState = {
  data: [],
  isLoading: false,
  hasFetchError: false,
}

const searchPolicy = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case DOWNLOAD_POLICIES: {
      return {
        ...state,
        data: [],
        isLoading: true,
        hasFetchError: false,
      }
    }
    case DOWNLOAD_POLICIES_SUCCESS: {
      const {
        businessData: { policies },
      } = action.payload

      return {
        ...state,
        data: policies,
        isLoading: false,
        hasFetchError: false,
      }
    }
    case DOWNLOAD_POLICIES_ERROR:
    case DOWNLOAD_POLICIES_FAILURE: {
      return {
        ...state,
        data: [],
        isLoading: false,
        hasFetchError: true,
      }
    }

    default: {
      return state
    }
  }
}

export default searchPolicy
