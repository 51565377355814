// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import debounce from 'lodash/debounce'
import BurgerMenu from '@mlcl-digital/mlcl-design/lib/base/BurgerMenu'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { actionCreators } from '../../../../../actions'
import { OKTA_CHANGE_PASSWORD_MODAL } from '../../../../../actions/types/okta'

// components.
import Button from '../../../../atoms/Button'
import { IconUser16 } from '../../../../atoms/Icons'
import Logo from '../../../../atoms/Logo'
import NavDropdown from '../../../../molecules/NavDropdown'
import NavItem from '../../../../atoms/NavItem'
import ChangePassword from '../../../ChangePassword'

// styles.
import styles from './adviserRegistrationNavigation.styles'
import { themeConsumer } from '../../../../../styles/ThemeContext'

import {
  reduceAuthorableFields,
  renderTextField,
  reduceContentListFields,
} from '../../../../../utils/sitecoreUtils'
import { logout } from '../../../../../utils/logoutUtils'
import { getProfileName } from '../../../../../utils/profileUtils'

// constants
import { NAV_LOGOUT_ITEM_ID, NAV_CHANGE_PASSWORD_ITEM_ID } from '../../../../../constants/sitecore'
import { ADVISOR_PORTAL } from '../../../../../constants/site'

type AdviserRegistrationNavigationProps = {
  // redux actions
  actions: Object<Object>,
  // sitecore fields
  fields: Object<Object>,
  // sitecore rendering
  rendering: Object,
  // redux modal state
  modal: Object<Object>,
  hideNavigationItems: boolean,
  advisor: Object,
  customerPersonalDetails: Object,
  navigation: Object,
  history: Object,
}

type AdviserRegistrationState = {
  // Sets the dropdown compoment toggle to open
  dropdown: Object,
  // Sets scrolling state for the header
  header: {
    isScrolling: boolean,
  },
}

const Header = styled('header')(styles.header)
const LogoContainer = styled('div')(styles.logoContainer)

const Nav = styled('nav')(styles.nav)
const Dropdown = styled(NavDropdown)()

const List = styled('ul')(({ theme }) => styles.list(theme, NavItem))
const Item = styled('li')(({ theme }) => styles.item(theme))
const ItemLink = styled(Item.withComponent(NavItem))(styles.itemLink)
const CustomerNavLinks = styled('li')(styles.helpLink)

const NavSubSection = styled('div')(styles.navSubSection)
const ProfileDropdown = styled(Dropdown)(styles.profileDropdpwn)
const Overlay = styled('div')(styles.overlay)

const QuickAction = styled(Item.withComponent('li'))(styles.quickAction)

const ProfileIcon = styled(IconUser16)(styles.profileIcon)
const ProfileNameText = styled('span')(styles.profileNameText)

const QuickActionButton = styled(Button)(styles.quickActionButton)
class AdviserRegistrationNavigation extends Component<
  AdviserRegistrationNavigationProps,
  AdviserRegistrationState
> {
  constructor() {
    super()

    this.state = {
      dropdown: {},
      header: {
        isScrolling: false,
      },
      showChangePassword: false,
    }

    this.handleScroll = debounce(this.handleScroll.bind(this), 5)
    this.handleOnClickMenu = this.handleOnClickMenu.bind(this)
    this.dropdownClose = this.dropdownClose.bind(this)
  }

  componentDidMount() {
    const {
      actions: { initModal },
    } = this.props
    initModal(OKTA_CHANGE_PASSWORD_MODAL)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.handleScroll.cancel()
  }

  setScrollingState(isScrolling) {
    this.setState(prevState => ({
      header: {
        ...prevState.header,
        isScrolling,
      },
    }))
  }

  menuItemClickHandler = (e: Object, item: Object) => {
    const {
      actions: { showModal, signOut },
      history,
    } = this.props

    if (item.id === NAV_CHANGE_PASSWORD_ITEM_ID) {
      e.preventDefault()

      showModal(OKTA_CHANGE_PASSWORD_MODAL)
    }

    if (item.id === NAV_LOGOUT_ITEM_ID) {
      e.preventDefault()
      logout({
        oktaSignOutAction: signOut,
        currentRoute: get(history, 'location.pathname', '/'),
      })
    }
  }

  handleScroll() {
    const {
      header: { isScrolling },
    } = this.state

    if (window.scrollY === 0 && isScrolling === true) {
      this.setScrollingState(false)
    } else if (window.scrollY !== 0 && isScrolling !== true) {
      this.setScrollingState(true)
    }
  }

  handleOnClickMenu() {
    const {
      actions: { toggleBurgerMenu },
      navigation: { isOpen: menuIsOpen },
    } = this.props

    toggleBurgerMenu(!menuIsOpen)
  }

  dropdownClose() {
    this.setState(prevState => ({
      ...prevState,
      dropdown: {},
    }))
  }

  render() {
    const {
      header: { isScrolling },
      dropdown,
    } = this.state

    const {
      actions,
      hideNavigationItems,
      fields,
      rendering,
      modal,
      advisor,
      customerPersonalDetails,
      navigation: { isOpen: menuIsOpen, showNotificationBanner },
    } = this.props
    const dropdownOpen = Object.keys(dropdown).length !== 0
    const { openSidebar } = actions

    const { logoHorizontal, logoVertical, profile, help, quicklinkAction } = fields

    const isChangePasswordModalVisible =
      modal && modal[OKTA_CHANGE_PASSWORD_MODAL] && modal[OKTA_CHANGE_PASSWORD_MODAL].isModalVisible

    const profileDisplayName = getProfileName(
      advisor,
      customerPersonalDetails,
      get(profile, 'fields.text.value', 'Account')
    )

    const ProfileName = (
      <ProfileNameText>
        <ProfileIcon /> {profileDisplayName}
      </ProfileNameText>
    )

    const isAdviserPortal = process.env.SITE === ADVISOR_PORTAL

    return (
      <Fragment>
        {dropdownOpen && <Overlay onClick={this.dropdownClose} />}
        {rendering && rendering.placeholders && (
          <Placeholder name="notification-banner" rendering={rendering} />
        )}
        <Header
          data-testid="adviser-registration-navigation"
          isScrolling={isScrolling}
          isNotificationBannerVisible={showNotificationBanner}
        >
          <LogoContainer>
            <Logo
              href=""
              horizontalSrc={get(logoHorizontal, 'value', '')}
              verticalSrc={get(logoVertical, 'value', '')}
              alt="MLC"
            />
            {!hideNavigationItems && (
              <BurgerMenu isOpen={menuIsOpen} onClick={this.handleOnClickMenu} />
            )}
          </LogoContainer>
          {!hideNavigationItems && (
            <Fragment>
              <Nav isOpen={menuIsOpen}>
                <List>
                  {profile && profile.fields && !!Object.keys(profile.fields).length && (
                    <Item showOnSmall>
                      <Dropdown
                        buttonText={profileDisplayName}
                        items={reduceContentListFields(get(profile, 'fields.secondary', []))}
                      />
                    </Item>
                  )}

                  {help && help.fields && !!Object.keys(help.fields).length && (
                    <ItemLink href={get(help, 'fields.href.value', '')} showOnSmall>
                      {renderTextField(get(help, 'fields.text', ''))}
                    </ItemLink>
                  )}
                </List>
              </Nav>
              <NavSubSection>
                <List>
                  {isAdviserPortal && quicklinkAction && quicklinkAction.fields && (
                    <QuickAction>
                      <QuickActionButton
                        type="secondary"
                        onClick={() => {
                          openSidebar('createQuote')
                        }}
                      >
                        {renderTextField(get(quicklinkAction, 'fields.text', ''))}
                      </QuickActionButton>
                    </QuickAction>
                  )}

                  {help && help.fields && !!Object.keys(help.fields).length && (
                    <ItemLink href={get(help, 'fields.href.value', '')}>
                      <CustomerNavLinks>
                        {renderTextField(get(help, 'fields.text', ''))}
                      </CustomerNavLinks>
                    </ItemLink>
                  )}
                </List>

                {profile && profile.fields && !!Object.keys(profile.fields).length && (
                  <ProfileDropdown
                    buttonText={ProfileName}
                    items={reduceContentListFields(get(profile, 'fields.secondary', []))}
                    variant="secondary"
                    menuItemClickHandler={this.menuItemClickHandler}
                  />
                )}
              </NavSubSection>
            </Fragment>
          )}
        </Header>
        {isChangePasswordModalVisible && (
          <ChangePassword
            isOpen={isChangePasswordModalVisible || false}
            fields={reduceAuthorableFields(fields)}
          />
        )}
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = ({ modal, advisor, customerPersonalDetails, navigation }) => ({
  modal,
  advisor,
  customerPersonalDetails,
  navigation,
})

export const Navigation = AdviserRegistrationNavigation // Exported for testing.

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(themeConsumer(withRouter(AdviserRegistrationNavigation), 'navigation'))
