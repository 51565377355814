import { Variables } from '@mlcl-digital/mlcl-design'
import {
  space,
  mediaQueries,
  fontSize,
  pxToRem,
  colours,
  fontFamily,
  fontWeights,
  zIndex,
  shadows,
  containerUtils,
} from '../../../../../styles'

const { colours: designColors } = Variables

const linkSpacing = {
  paddingTop: space(2),
  paddingBottom: space(2),
  display: 'flex',
  alignItems: 'center',
}

const styles = {
  header: ({ isScrolling }) => ({
    backgroundColor: colours.white,
    paddingTop: space(2),
    position: 'fixed',
    left: 0,
    right: 0,
    boxShadow: isScrolling ? shadows.mid : 'none',
    zIndex: zIndex.mainNavigation,

    '& + *': {
      paddingTop: space(8),

      [mediaQueries.md]: {
        paddingTop: space(11),
      },
    },

    [mediaQueries.md]: {
      padding: 0,
      paddingLeft: space(3),
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
    },
  }),
  logoContainer: {
    ...containerUtils.innerPaddingHorizontal,
    alignSelf: 'flex-start',
    marginBottom: space(2),
    display: 'flex',
    justifyContent: 'space-between',

    [mediaQueries.md]: {
      marginBottom: 0,
      marginRight: space(1),
      padding: 0,
      alignSelf: 'center',

      [mediaQueries.lg]: {
        marginRight: space(4),
      },
    },
  },
  nav: ({ isOpen }) => ({
    color: colours.darkGrey,
    display: isOpen ? 'block' : 'none',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    [mediaQueries.md]: {
      display: 'flex',
    },
  }),
  list: {
    fontFamily: fontFamily.charlie,

    '> li:last-child': {
      borderBottomWidth: 1,
      borderBottomColor: colours.lightGrey,
      borderBottomStyle: 'solid',

      [mediaQueries.md]: {
        borderBottomWidth: 0,
      },
    },

    [mediaQueries.md]: {
      display: 'flex',
      borderBottomWidth: 0,
    },
  },
  itemLink: ({ clickDisabled }) => ({
    pointerEvents: clickDisabled && 'none',
    color: clickDisabled && designColors.tertiaryThree,
    fontWeight: fontWeights.bold,
    border: 'none',

    [mediaQueries.md]: {
      paddingLeft: space(4),
      paddingRight: space(4),
      ...linkSpacing,
    },
    [mediaQueries.lg]: {
      paddingLeft: space(7),
      paddingRight: space(7),
    },
  }),
  item: ({ showOnSmall }) => ({
    display: 'block',
    padding: `0 ${space(3)}px`,
    textAlign: 'left',
    '&:hover': {
      backgroundColor: colours.offWhite,
    },

    [mediaQueries.md]: {
      padding: 0,
      border: 0,
      color: colours.darkGreen,
      display: showOnSmall && 'none',
      fontFamily: fontFamily.sourceSans,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
  button: ({ exitButton }) => ({
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(12),
    fontSize: fontSize.sm,
    fontFamily: fontFamily.charlie,
    fontWeight: fontWeights.semiBold,
    padding: `${space(2)}px 0`,
    height: '100%',
    borderRadius: 0,
    color: colours.darkGrey,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: colours.offWhite,
    },

    [mediaQueries.md]: {
      paddingLeft: space(2),
      paddingRight: space(2),
      color: exitButton ? colours.white : colours.darkestGrey,
      backgroundColor: exitButton && colours.mediumGreen,
      fontFamily: fontFamily.sourceSans,
      fontSize: fontSize.xs,

      '&:hover': {
        backgroundColor: exitButton && colours.lightGreen,
        color: exitButton && colours.white,
      },
    },
    [mediaQueries.lg]: {
      paddingRight: space(3.5),
      paddingLeft: space(3.5),
    },
  }),
}

export default styles
