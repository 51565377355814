import get from 'lodash/get'
import { SITECORE_API } from '../types/sitecoreApi'
import { GET_VISUALISER_DATA } from '../types/visualiser'
import { SITECORE_API_COMPUTE } from '../../constants/apis'

export const getVisualiserData = payload => (dispatch, getState) => {
  const {
    config: { MLCL_SITECORE_CMS_KEY },
  } = getState()

  dispatch({
    verb: 'POST',
    type: SITECORE_API,
    name: GET_VISUALISER_DATA,
    route: `${SITECORE_API_COMPUTE}/visualizer/incident`,
    data: payload,
    additionalHeaders: {
      CMSapikey: MLCL_SITECORE_CMS_KEY,
    },
    callback: (err, dataReceived) => ({
      data: get(dataReceived, 'data', null),
    }),
  })
}
