import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontWeights, fontSize, colours, mediaQueries } = Variables
const styles = {
  wrap: {
    marginBottom: space(24),
    position: 'relative',
  },
  searchBoxContainer: {
    width: '100%',
    marginTop: space(4),
    [mediaQueries.md]: {
      marginTop: space(6),
    },
  },
  tableRow: {
    '.table-row': {
      cursor: 'pointer',
    },
    button: {
      fontWeight: fontWeights.semiBold,
      fontSize: fontSize.xs,
    },
  },
  applicationSummaryLinkedTag: {
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.xs,
  },
  subHeading: {
    color: colours.darkGrey,
    fontWeight: 'normal',
  },
  confirmCoverRemovalBtn: {
    margin: `${space(3, true)} 0`,
  },
  headerSelFormBlock: {
    minWidth: space(15, true),
    color: colours.mediumGreen,
    textAlign: 'center',
    fontSize: fontSize.xs,
    zIndex: 10,
    position: 'relative',
    marginBottom: space(0),
  },
  table: {
    width: '100%',
  },
  rightPanel: {
    display: 'flex',
    flexBasis: '20%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  icon: {
    color: colours.mediumGreen,
    marginRight: space(1),
  },
  base: icon => ({
    fontSize: fontSize.md,
    color: colours.mediumGreen,
    fontWeight: fontWeights.bold,
    textDecoration: 'none',
    '&:hover, &:focus': {
      outline: 'none',
      color: colours.darkGreen,
      [icon]: {
        color: colours.darkGreen,
      },
    },
    '&:active': {
      color: colours.darkestGrey,
      [icon]: {
        color: colours.darkestGrey,
      },
    },
  }),
  crossIcon: {
    color: colours.functionalDanger,
  },
  roundPill: {
    borderRadius: '50%',
    backgroundColor: colours.tertiaryOne,
    color: colours.tertiarySix,
  },
}

export default styles
