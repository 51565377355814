import { mediaQueries, space } from '../../../../../styles'

const styles = {
  wrapper: {
    flex: '0 0 100%',
    textAlign: 'left',
    marginTop: space(2, true),
    display: 'flex',
    flexDirection: 'column',

    [mediaQueries.md]: {
      flexBasis: '30%',
      marginTop: 0,
    },
  },
  checkAnswersText: {
    marginTop: space(2, true),
  },
}

export default styles
