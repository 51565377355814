import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { space, colours, fontFamily, fontWeights, fontSize } = Variables as VariablesType

export default {
  header: {
    h3: {
      display: 'flex',
      fontFamily: fontFamily.sourceSans,
      borderTop: 0,
      marginBottom: 0,
      div: {
        fontWeight: fontWeights.light,
        margin: `${space(0.5, true)} ${space(1, true)}`,
        alignItems: 'center',
      },
    },
  },
  policyDetails: {
    display: 'flex',
    marginTop: space(1),
    span: {
      color: colours.tertiaryOne,
      '&:first-of-type': {
        fontWeight: fontWeights.semiBold,
        marginRight: space(1, true),
      },
    },
  },
  optOutSection: {
    padding: space(3),
    display: 'flex',
    borderBottom: `1px solid ${colours.tertiaryFour}`,
    justifyContent: 'space-between',
    span: {
      color: colours.tertiaryOne,
      '&:first-of-type': {
        marginLeft: space(1, true),
        fontWeight: fontWeights.semiBold,
      },
    },
  },
  body: {
    padding: 0,
  },
  footer: {
    color: colours.tertiaryOne,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    '> div': {
      flex: '1 1 50%',
      padding: space(3),
      fontSize: fontSize.sm,
      '&:nth-child(2)': {
        borderLeft: `1px solid ${colours.tertiaryFour}`,
        dd: {
          color: colours.mlcAquaAccessible,
        },
      },
      '&:last-of-type': {
        flexBasis: '100%',
      },
      dd: {
        fontWeight: fontWeights.bold,
        fontSize: fontSize.md,
      },
    },
  },
  coverSection: {
    display: 'flex',
    '> div': {
      flexBasis: '50%',
      padding: space(3),
      '&:last-of-type': {
        borderLeft: `1px solid ${colours.tertiaryFour}`,
      },
      h3: {
        fontFamily: fontFamily.sourceSans,
        marginBottom: space(4),
      },
      dl: {
        display: 'flex',
        color: colours.tertiaryOne,
        dt: {
          fontWeight: fontWeights.semiBold,
          marginRight: space(2),
        },
        dd: {
          '> div': {
            marginLeft: space(1),
          },
        },
      },
    },
  },
  benefitName: {
    margin: `${space(2, true)} 0`,
    fontFamily: fontFamily.sourceSans,
    svg: {
      marginRight: space(1),
    },
  },
  premiumSaved: {
    background: colours.mlcAquaAccessible,
    color: colours.tertiarySix,
    padding: space(3),
    '& >div:last-of-type': {
      fontSize: fontSize.md,
    },
  },
  errorMessage: {
    display: 'flex',
    padding: `${space(2, true)} ${space(3, true)}`,
    color: colours.functionalDangerDark,
    background: colours.functionalWarningLight,
    svg: {
      fontSize: fontSize.md,
    },
    span: {
      paddingLeft: space(1),
      fontSize: fontSize.xs,
      fontWeight: fontWeights.semiBold,
    },
  },
  radioGroup: {
    base: {
      margin: 0,
      '> div': {
        padding: `0 ${space(1, true)} 0 ${space(2, true)}`,
      },
    },
  },
}
