import { colours, space, fontSize, fontFamily, fontWeights, mediaQueries } from '../../../styles'

const styles = {
  conciergeFooterWrap: {
    width: '100%',
    padding: `${space(9, true)} 0}`,
    backgroundColor: colours.lightGreen,
    fontFamily: fontFamily.charlie,
  },
  conciergeFooterText: {
    fontFamily: fontFamily.charlie,
    fontWeight: fontWeights.semiBold,
    color: colours.darkGreen,
    marginBottom: 0,
    [mediaQueries.xs]: {
      fontSize: fontSize.lg,
    },
    [mediaQueries.sm]: {
      fontSize: fontSize.xl,
    },
    [mediaQueries.md]: {
      fontSize: fontSize.xl,
    },
  },
}
export default styles
