// @flow
import React from 'react'

// components.
import ArticleDetailPageComponent from '../../../organisms/KnowledgeBase/components/ArticleDetailPage'

type ArticleDetailPageProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const ArticleDetailPage = ({ fields }: ArticleDetailPageProps) => (
  <ArticleDetailPageComponent fields={fields} />
)

export default ArticleDetailPage
