// @flow
import React from 'react'
// Organism
import AltsTryAgainModalOrgs from '../../../organisms/AltsTryAgainModal'

type AltsTryAgainModalProps = {
  fields: Object,
}

const AltsTryAgainModal = ({ fields }: AltsTryAgainModalProps) => (
  <AltsTryAgainModalOrgs fields={fields} />
)

export default AltsTryAgainModal
