// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// components.
import CollectionInfo from './CollectionInfo'
import CustomerInfo from './CustomerInfo'
import QuoteSettings from './QuoteSettings'
import QuoteCost from './QuoteCost'
import QuoteDiscounts from './QuoteDiscounts'
import QuoteSelectDownloadAction from '../QuoteDownload'
import QuoteItem from './QuoteItem'

// styles.
import styles from './quoteToolPanel.styles'

// types.
import { type quoteCollectionType } from '../../../../../reducers/createQuote'

// helper
import { isIPCover } from '../../../../../utils/quoteUtils'

type QuoteSidebarProps = {
  // sitecore fields
  fields: Object<Object>,
  actions: {
    // Update Active Index
    updateQuoteActiveIndex: Function,
  },
  // create quote data which user has selected.
  createQuote: {
    activeIndex: number,
    isRecalculating: boolean,
    quotes: Array<quoteCollectionType>,
  },
  // product data from product rules.
  productData: Array<Object>,
}

const Wrap = styled('aside')(styles.wrap)
const ActiveCollection = styled('section')(styles.activeQuoteCollection)
const AdditionalInformation = styled('aside')(styles.additionalInformation)

const QuoteSidebar = (props: QuoteSidebarProps) => {
  const { fields, createQuote, productData } = props
  const {
    quoteEntityName,
    quoteEntityAge,
    quoteEntityGender,
    quoteEntitySmoker,
    quoteEntityMLCONTrack,
    quoteEntityPolicyFee,
    quoteEntityOptions,
    quoteEntityStampDuty,
    quoteEntityPolicyStruct,
    quoteEntityRemuneration,
    quoteEntityTACost,
    quoteEntityTBCost,
    quoteEntityRenameQuoteName,
    quoteEntityRenameButtonLabel,
    quoteUpdateAdviserDetailsTitle,
    quoteUpdateAdviserDetailsCompanyFormHeader,
    quoteUpdateAdviserDetailsNameLabel,
    quoteUpdateAdviserDetailsBusinessNameLabel,
    quoteUpdateAdviserDetailsAbnLabel,
    quoteUpdateAdviserDetailsContactFormHeader,
    quoteUpdateAdviserDetailsAddressLabel,
    quoteUpdateAdviserDetailsCantFindAddressLabel,
    quoteUpdateAdviserDetailsStreetPlaceholder,
    quoteUpdateAdviserDetailsHouseNoPlaceholder,
    quoteUpdateAdviserDetailsLocalityPlaceholder,
    quoteUpdateAdviserDetailsStatePlaceholder,
    quoteUpdateAdviserDetailsCountryPlaceholder,
    quoteUpdateAdviserDetailsPostCodePlaceholder,
    quoteUpdateAdviserDetailsManualToggleLabel,
    quoteUpdateAdviserDetailsContactLabel,
    quoteUpdateAdviserDetailsEmailLabel,
    quoteUpdateAdviserDetailsSubmitButton,
    quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg,
    quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg,
    quoteEntityPrimaryActionBtn,
    quoteEntitySecondaryActionBtn,
    quoteEntityTMCost,
    quoteEntityViewCostBreak,
    quoteEntityHideCostBreak,
    quoteEntityOnTrackModal,
    quoteEntityOnTrackModalTitle,
    quoteEntityOnTrackConfirm,
    QuoteEntityQuoteNameInvalidErrorMsg,
    QuoteEntityQuoteNameDuplicationErrorMsg,
    quoteEntityPolicyFeeModal,
    quoteEntityPolicyFeeModalTitle,
    quoteEntityPolicyFeeConfirm,
    deleteQuoteModalTitle,
    deleteQuoteModal,
    deleteQuoteConfirm,
    quoteEntityAdditionalInformation,
    SaveAdviserFromModalTitle,
    SaveAdviserFromModalDesc,
    SaveAdviserFromLabel,
    SaveAdviserFromInputPlaceholder,
    SaveAdviserFromButtonLabel,
    SaveAdviserInvalidInput,
    SaveAdviserBlankInput,
    SaveAdviserConflictError,
    SaveAdviserInternalServerError,
    SaveAdviserSuccessTitle,
    SaveAdviserSuccessDesc,
    SaveAdviserSuccessButtonLabel,
    QuoteSettingPolicyStructure,
    QuoteSettingSaveQuote,
    QuoteSettingDuplicateQuote,
    QuoteSettingDeleteQuote,
    quoteCollectionNameTitle,
    quoteCollectionNameDescription,
    quoteCollectionFirstNameLabel,
    quoteCollectionFirstNameError,
    quoteCollectionInvalidFirstNameError,
    quoteCollectionLastNameLabel,
    quoteCollectionLastNameError,
    quoteCollectionInvalidLastNameError,
    quoteCollectionNameSubmitButtonLabel,
    quoteDiscountMessagePrefix,
    quoteDiscountMessageAppliedToQuote,
    quoteDiscountMessageAppliedToBenefit,
    quoteDiscountMessageSuffix,
    saveQuoteErrorModalHeading,
    CalculateQuoteErrorModalHeading,
    CalculateQuoteMultipleErrorModalHeading,
    CalculateQuoteErrorModalLabel,
    HideCalculateQuoteErrorModalLabel,
    saveQuoteErrorModalNoBenefitInPolicy,
    saveQuoteErrorModalConfirm,
    dashboardConfirmQuoteModalHeading,
    dashboardConfirmQuoteModalDescription,
    dashboardConfirmQuoteModalSaveBtn,
    exitToDashboardBtn,
    saveQuoteTemplatePolicyOrBenefitEmpty,
    policyInsideSuper,
    policyOutsideSuper,
    quoteEntityNoAvailablePolicies,
    quoteDownloadModalTitle,
    quoteDownloadSelectAllButton,
    quoteDownloadModalSubmitButton,
    downloadQuoteSelectOptionsHeader,
    downloadQuoteBackButton,
    downloadQuoteSelectOptionError,
    downloadQuoteRequiredAdviserNameErrorMsg,
    downloadQuoteRequiredBusinessNameErrorMsg,
    downloadQuoteRequiredABNErrorMsg,
    downloadQuoteRequiredEmailAddressErrorMsg,
    downloadQuoteRequiredFullAddressErrorMsg,
    downloadQuoteRequiredStreetErrorMsg,
    downloadQuoteRequiredLocalityErrorMsg,
    downloadQuoteRequiredStateErrorMsg,
    downloadQuoteRequiredCountryErrorMsg,
    downloadQuoteRequiredPostCodeErrorMsg,
    downloadQuoteInvalidStreetErrorMsg,
    downloadQuoteInvalidStateErrorMsg,
    downloadQuoteInvalidPostCodeErrorMsg,
    downloadQuoteInvalidLocalityErrorMsg,
    quoteToolInsidePolicyNamePrefix,
    quoteToolOutsidePolicyNamePrefix,
    quoteToolWrapPolicySuffix,
    quoteToolSuperPolicySuffix,
    quoteToolOutsidePolicySuffix,
    incompleteSuperPolicyDetailsModalHeading,
    incompleteSuperPolicyDetailsModalMessage,
    incompleteSuperPolicyDetailsModalConfirm,
    QuoteUpdateAdviserDetailsPoBoxPlaceholder,
    QuoteHealthyLivingProgram,
    QuoteHealthyLivingProgramTooltip,
    QuoteHealthyLivingProgramDiscountLabel,
    QuoteHealthyLivingProgramDiscountTooltip,
    MultiCoverDiscountLabel,
    MultiCoverDiscountTooltip,
    QuoteHealthyLivingProgramDiscountAppliedLabel,
  } = fields

  const collectionInfoFields = {
    quoteEntityName,
    quoteCollectionNameTitle,
    quoteCollectionNameDescription,
    quoteCollectionFirstNameLabel,
    quoteCollectionFirstNameError,
    quoteCollectionInvalidFirstNameError,
    quoteCollectionLastNameLabel,
    quoteCollectionLastNameError,
    quoteCollectionInvalidLastNameError,
    quoteCollectionNameSubmitButtonLabel,
    saveQuoteErrorModalHeading,
    saveQuoteErrorModalNoBenefitInPolicy,
    saveQuoteErrorModalConfirm,
    dashboardConfirmQuoteModalHeading,
    dashboardConfirmQuoteModalDescription,
    dashboardConfirmQuoteModalSaveBtn,
    CalculateQuoteErrorModalHeading,
    CalculateQuoteMultipleErrorModalHeading,
    CalculateQuoteErrorModalLabel,
    HideCalculateQuoteErrorModalLabel,
    exitToDashboardBtn,
  }

  const customerInfoFields = {
    quoteEntityGender,
    quoteEntitySmoker,
    quoteEntityAge,
  }
  const quoteSettingsFields = {
    quoteEntityMLCONTrack,
    quoteEntityPolicyFee,
    quoteEntityOptions,
    quoteEntityPolicyStruct,
    quoteEntityRemuneration,
    quoteEntityOnTrackModal,
    quoteEntityOnTrackModalTitle,
    quoteEntityOnTrackConfirm,
    QuoteEntityQuoteNameInvalidErrorMsg,
    QuoteEntityQuoteNameDuplicationErrorMsg,
    quoteEntityPolicyFeeModal,
    quoteEntityPolicyFeeModalTitle,
    quoteEntityPolicyFeeConfirm,
    quoteEntityRenameQuoteName,
    quoteEntityRenameButtonLabel,
    deleteQuoteModalTitle,
    deleteQuoteModal,
    deleteQuoteConfirm,
    SaveAdviserFromModalTitle,
    SaveAdviserFromModalDesc,
    SaveAdviserFromLabel,
    SaveAdviserFromInputPlaceholder,
    SaveAdviserFromButtonLabel,
    SaveAdviserInvalidInput,
    SaveAdviserBlankInput,
    SaveAdviserConflictError,
    SaveAdviserInternalServerError,
    SaveAdviserSuccessTitle,
    SaveAdviserSuccessDesc,
    SaveAdviserSuccessButtonLabel,
    QuoteSettingPolicyStructure,
    QuoteSettingSaveQuote,
    QuoteSettingDuplicateQuote,
    QuoteSettingDeleteQuote,
    saveQuoteTemplatePolicyOrBenefitEmpty,
    QuoteHealthyLivingProgram,
    QuoteHealthyLivingProgramTooltip,
  }

  const quoteCostFields = {
    quoteEntityTACost,
    quoteEntityTBCost,
    quoteEntityTMCost,
    quoteEntityViewCostBreak,
    quoteEntityHideCostBreak,
    quoteEntityPolicyFee,
    quoteEntityStampDuty,
    policyInsideSuper,
    policyOutsideSuper,
    quoteEntityNoAvailablePolicies,
    quoteToolInsidePolicyNamePrefix,
    quoteToolOutsidePolicyNamePrefix,
    quoteToolWrapPolicySuffix,
    quoteToolSuperPolicySuffix,
    quoteToolOutsidePolicySuffix,
    QuoteHealthyLivingProgramDiscountLabel,
    QuoteHealthyLivingProgramDiscountTooltip,
    MultiCoverDiscountLabel,
    MultiCoverDiscountTooltip,
    QuoteHealthyLivingProgramDiscountAppliedLabel,
  }

  const quoteDownloadActionFields = {
    quoteUpdateAdviserDetailsTitle,
    quoteUpdateAdviserDetailsCompanyFormHeader,
    quoteUpdateAdviserDetailsNameLabel,
    quoteUpdateAdviserDetailsBusinessNameLabel,
    quoteUpdateAdviserDetailsAbnLabel,
    quoteUpdateAdviserDetailsContactFormHeader,
    quoteUpdateAdviserDetailsAddressLabel,
    quoteUpdateAdviserDetailsCantFindAddressLabel,
    quoteUpdateAdviserDetailsStreetPlaceholder,
    quoteUpdateAdviserDetailsHouseNoPlaceholder,
    quoteUpdateAdviserDetailsLocalityPlaceholder,
    quoteUpdateAdviserDetailsStatePlaceholder,
    quoteUpdateAdviserDetailsCountryPlaceholder,
    quoteUpdateAdviserDetailsPostCodePlaceholder,
    quoteUpdateAdviserDetailsManualToggleLabel,
    quoteUpdateAdviserDetailsContactLabel,
    quoteUpdateAdviserDetailsEmailLabel,
    quoteUpdateAdviserDetailsSubmitButton,
    quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg,
    quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg,
    quoteEntityPrimaryActionBtn,
    quoteEntitySecondaryActionBtn,
    quoteDownloadModalTitle,
    quoteDownloadSelectAllButton,
    quoteDownloadModalSubmitButton,
    downloadQuoteSelectOptionsHeader,
    downloadQuoteBackButton,
    downloadQuoteSelectOptionError,
    quoteCollectionNameTitle,
    quoteCollectionNameDescription,
    quoteCollectionFirstNameLabel,
    quoteCollectionFirstNameError,
    quoteCollectionInvalidFirstNameError,
    quoteCollectionLastNameLabel,
    quoteCollectionLastNameError,
    quoteCollectionInvalidLastNameError,
    quoteCollectionNameSubmitButtonLabel,
    downloadQuoteRequiredAdviserNameErrorMsg,
    downloadQuoteRequiredBusinessNameErrorMsg,
    downloadQuoteRequiredABNErrorMsg,
    downloadQuoteRequiredEmailAddressErrorMsg,
    downloadQuoteRequiredFullAddressErrorMsg,
    downloadQuoteRequiredStreetErrorMsg,
    downloadQuoteRequiredLocalityErrorMsg,
    downloadQuoteRequiredStateErrorMsg,
    downloadQuoteRequiredCountryErrorMsg,
    downloadQuoteRequiredPostCodeErrorMsg,
    downloadQuoteInvalidStreetErrorMsg,
    downloadQuoteInvalidStateErrorMsg,
    downloadQuoteInvalidPostCodeErrorMsg,
    downloadQuoteInvalidLocalityErrorMsg,
    quoteToolInsidePolicyNamePrefix,
    quoteToolOutsidePolicyNamePrefix,
    quoteToolWrapPolicySuffix,
    quoteToolSuperPolicySuffix,
    quoteToolOutsidePolicySuffix,
    incompleteSuperPolicyDetailsModalHeading,
    incompleteSuperPolicyDetailsModalMessage,
    incompleteSuperPolicyDetailsModalConfirm,
    QuoteUpdateAdviserDetailsPoBoxPlaceholder,
  }

  const quoteDiscountFields = {
    quoteDiscountMessagePrefix,
    quoteDiscountMessageAppliedToQuote,
    quoteDiscountMessageAppliedToBenefit,
    quoteDiscountMessageSuffix,
  }

  const { activeIndex, quotes, quoteCollectionName } = createQuote

  const mlcOnTrackAdditionalInformation = quotes[activeIndex].policyStructure.find(
    policy => policy.mlcOnTrack === false
  )

  /**
   * Update Quote Index as Active Index
   *
   * @param {number} newQuoteIndex - New quote index
   */

  const updateActiveIndex = newQuoteIndex => {
    const {
      actions: { updateQuoteActiveIndex, initiateSaveQuote, isIPCoverCalculated },
    } = props
    const newQuote = quotes[newQuoteIndex]

    let isIPCoverAvailable = false
    const ipCoverPolicy =
      newQuote.policyStructure &&
      newQuote.policyStructure.find(
        policy => policy.covers && policy.covers.some(cover => isIPCover(cover))
      )
    ipCoverPolicy &&
      ipCoverPolicy.covers &&
      ipCoverPolicy.covers.forEach(cover => {
        if (isIPCover(cover) && cover.coverAmount > 0) {
          isIPCoverAvailable = true
        }
      })
    if (!isIPCoverAvailable) {
      isIPCoverCalculated(false)
    }
    initiateSaveQuote(err => {
      if (!err) {
        updateQuoteActiveIndex(newQuoteIndex)
      }
    })
  }

  const filterName = quoteName => quoteName.replace(/ /g, '_').toLowerCase()

  return (
    <Wrap>
      <CollectionInfo fields={collectionInfoFields} quoteCollectionName={quoteCollectionName} />
      <CustomerInfo fields={customerInfoFields} createQuote={createQuote} />
      {quotes.map((quote, quoteIndex) =>
        quoteIndex === activeIndex ? (
          <ActiveCollection
            data-locator={`${filterName(quotes[activeIndex].quoteName)}_expanded`}
            key={quote.quoteName}
          >
            <QuoteSettings
              quoteEntityName={quotes[activeIndex].quoteName}
              quoteEntityMLCONTrack={quoteEntityMLCONTrack}
              quoteEntityPolicyFee={quoteEntityPolicyFee}
              quoteEntityPolicyStruct={quoteEntityPolicyStruct}
              quoteEntityRenameButtonLabel={quoteEntityRenameButtonLabel}
              quoteEntityRemuneration={quoteEntityRemuneration}
              quoteEntityOnTrackModal={quoteEntityOnTrackModal}
              quoteEntityOnTrackModalTitle={quoteEntityOnTrackModalTitle}
              quoteEntityOnTrackConfirm={quoteEntityOnTrackConfirm}
              quoteEntityPolicyFeeModal={quoteEntityPolicyFeeModal}
              deleteQuoteModal={deleteQuoteModal}
              quoteEntityPolicyFeeModalTitle={quoteEntityPolicyFeeModalTitle}
              deleteQuoteModalTitle={deleteQuoteModalTitle}
              deleteQuoteConfirm={deleteQuoteConfirm}
              quoteEntityPolicyFeeConfirm={quoteEntityPolicyFeeConfirm}
              fields={quoteSettingsFields}
              quote={quote}
              quoteIndex={quoteIndex}
              quotes={quotes}
              activeIndex={activeIndex}
              productRules={productData}
            />
            <QuoteCost fields={quoteCostFields} quoteIndex={quoteIndex} />
            <QuoteDiscounts
              fields={quoteDiscountFields}
              policyStructure={quote.policyStructure}
              productData={productData}
            />
            <QuoteSelectDownloadAction
              align="column"
              fields={quoteDownloadActionFields}
              hasDownloadIcon
            />
          </ActiveCollection>
        ) : (
          <QuoteItem
            key={quote.quoteName}
            quoteEntityName={quotes[quoteIndex].quoteName}
            quoteIndex={quoteIndex}
            updateActiveIndex={updateActiveIndex}
          />
        )
      )}

      {mlcOnTrackAdditionalInformation && mlcOnTrackAdditionalInformation.mlcOnTrack && (
        <AdditionalInformation>
          <Text field={quoteEntityAdditionalInformation} />
        </AdditionalInformation>
      )}
    </Wrap>
  )
}

const mapStateToProps = ({ createQuote, productRules }) => ({
  createQuote,
  productData: productRules.data,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSidebar)
