// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries } = Variables
const styles = {
  resetButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [mediaQueries.lg]: {
      width: space(25),
    },
  },
  resetButton: {
    marginTop: space(2),
    paddingRight: 0,
    [mediaQueries.lg]: {
      marginTop: 0,
      width: space(25),
      justifyContent: 'flex-end',
    },
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > button:nth-of-type(1)': {
      marginLeft: space(3, true),
    },
  },
}

export default styles
