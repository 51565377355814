import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, fontSize, fontWeights } = Variables

const styles = {
  label: {
    display: 'flex',
    alignItems: 'center',
    '& > h3': {
      marginBottom: 0,
    },
    '& > div': {
      marginRight: space(4),
    },
  },
  accordionIcon: {
    '& svg': {
      color: colours.tertiaryOne,
    },
  },
  children(disableScrollContent) {
    let dynamicAttr = {
      maxHeight: space(60, true),
    }
    if (disableScrollContent) {
      dynamicAttr = { maxHeight: 'auto' }
    }
    return {
      color: colours.tertiaryOne,
      overflowY: 'auto',

      '& h3': {
        fontSize: fontSize.sm,
        fontWeight: fontWeights.bold,
        marginBottom: space(3),
      },
      '& p': {
        marginBottom: space(3),
      },
      '& ul': {
        marginBottom: space(3),
      },
      ...dynamicAttr,
    }
  },
}

export default styles
