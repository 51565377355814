// @flow
import { mediaQueries, colours, space, fontSize } from '../../../styles'

const styles = {
  topBackground: {
    backgroundColor: colours.darkGreen,
    color: colours.white,
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: `${space(14, true)} 0`,
  },
  stepsContainer: {
    marginTop: `-${space(10, true)}`,
    padding: `${space(4, true)} 0`,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [mediaQueries.sm]: {
      justifyContent: 'space-between',
    },
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${space(6, true)} 0 ${space(10, true)}`,
  },
  submitButton: {
    width: space(34),
  },
  nav: {
    display: 'block',
    marginBottom: space(2),
    paddingLeft: 0,
    height: 'auto',
    fontSize: fontSize.xs,
    color: colours.white,
    svg: {
      marginRight: space(1),
      marginTop: space(-0.25, true),
      color: colours.white,
    },
    '&:hover': {
      color: colours.lightGreen,
      svg: {
        color: colours.lightGreen,
      },
    },
  },
  navSection: {
    h1: {
      color: colours.white,
    },
    padding: `${space(8, true)} 0 ${space(19, true)}`,
  },
  headerDescription: {
    color: colours.white,
  },
  modalBody: {
    padding: `0 0 ${space(6, true)} 0`,
  },
  displayLifeAssured: stage => ({
    display: Number(stage) === 1 ? 'block' : 'none',
  }),
}

export default styles
