// @FIXME: fix A11Y issues
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'

// styles.
import styles from './tabs.styles'

// icons.
import { IconChevronDown16, IconChevronUp16 } from '../../../../atoms/Icons'

type TabProps = {
  // unique identiifer to each tab link
  id: string,
  // tab text
  label: string,
  // funtion to trigger on tab click
  onClick: Function,
  // tab content
  children: Node,
  active: Boolean,
}

const TabPanel = styled('div')(styles.tabPanel)
const Icon = styled('span')(styles.icon)

const Tab = (props: TabProps) => {
  const { id, onClick, label, children, active } = props

  const handleClick = () => {
    onClick(id)
  }

  return (
    <li id={id}>
      <a
        role="button"
        tabIndex="0"
        onClick={handleClick}
        data-testid="updateId"
        className={active ? 'active' : ''}
      >
        {label}
      </a>
      <Icon>{active ? <IconChevronUp16 /> : <IconChevronDown16 />}</Icon>
      <TabPanel>{children}</TabPanel>
    </li>
  )
}

export default Tab
