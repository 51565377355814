import React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import Tabs from '@mlcl-digital/mlcl-design/lib/base/Tabs'
import Tab from '@mlcl-digital/mlcl-design/lib/base/Tab'
import { Variables } from '@mlcl-digital/mlcl-design'

export const TabContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&>div:nth-child(2)': {
    marginLeft: Variables.space(2),
  },
})

export const RetentionTabContainer = React.memo(({ onClick, activeTab, schema = [] }) => (
  <Tabs data-testid="retention-tab-container">
    {schema.map(({ label, id, value }) => (
      <li key={`tab-${id}`}>
        <Tab
          data-testid={`retention-tab-${label}`}
          clickHandler={onClick}
          selected={id === activeTab}
          index={id}
          hash={id}
        >
          <TabContent>
            <div className="badge">
              <div>{value}</div>
            </div>
            <div>{label}</div>
          </TabContent>
        </Tab>
      </li>
    ))}
  </Tabs>
))
RetentionTabContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string || PropTypes.number,
      value: PropTypes.string,
    })
  ).isRequired,
}
