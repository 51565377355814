// @flow
import React from 'react'

// components.
import QuoteToolRoot from '../../../organisms/QuoteTool'

type QuoteToolProps = {
  // Sitecore fields
  fields: Object<Object>,
}

const QuoteTool = ({ fields }: QuoteToolProps) => <QuoteToolRoot fields={fields} />

export default QuoteTool
