// @flow
import 'core-js/es7/array'

// types
import {
  RETENTION_DASHBOARD_FETCH_INIT,
  RETENTION_DASHBOARD_FETCH_SUCCESS,
  RETENTION_DASHBOARD_FETCH_ERROR,
  RETENTION_DASHBOARD_FETCH_FAILURE,
  RETENTION_DASHBOARD_NEW_PREMIUMS_FETCH_COMPLETE,
} from '../actions/types/retentionDashboard'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

import { sortListResults } from '../utils/sortingUtils'

export const initialState = {
  dashboardDetail: {
    results: {},
    anniversary: {},
    lapsed: {},
    cancelled: {},
    riskOfLapse: {},
    isLoading: false,
    hasFetchError: false,
    isDownloadListLoading: false,
    isNewPremiumLoading: false,
  },
}

export const getMaximumLimit = data => {
  const {
    businessData: {
      anniversary: { policyListing: anniversary } = {},
      lapsed: { policyListing: lapsed },
      riskOfLapse: { policyListing: riskOfLapse },
      cancelled: { policyListing: cancelled },
    },
  } = data
  return Math.max(
    ...[anniversary, riskOfLapse, lapsed, cancelled].map(({ totalRecords }) => totalRecords)
  )
}

const retentionDashboard = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case RETENTION_DASHBOARD_FETCH_INIT: {
      return {
        ...state,
        dashboardDetail: {
          ...state.dashboardDetail,
          isLoading: true,
        },
      }
    }

    case RETENTION_DASHBOARD_FETCH_SUCCESS: {
      const {
        businessData: {
          anniversary: { policyListing: anniversary } = {},
          lapsed: { policyListing: lapsed },
          riskOfLapse: { policyListing: riskOfLapse },
          cancelled: { policyListing: cancelled },
        } = {},
      } = action.payload
      return {
        ...state,
        dashboardDetail: {
          anniversary: {
            ...anniversary,
            policies: sortListResults(anniversary.policies, 'policyOwner', 'fullName'),
          },
          lapsed,
          cancelled,
          riskOfLapse,
          isLoading: false,
          limit: getMaximumLimit(action.payload),
        },
      }
    }

    case RETENTION_DASHBOARD_FETCH_ERROR:
    case RETENTION_DASHBOARD_FETCH_FAILURE: {
      return {
        ...state,
        dashboardDetail: {
          results: {},
          anniversary: {},
          lapsed: {},
          cancelled: {},
          riskOfLapse: {},
          isLoading: false,
          hasFetchError: true,
        },
      }
    }

    case RETENTION_DASHBOARD_NEW_PREMIUMS_FETCH_COMPLETE: {
      const { results } = action.payload
      return {
        ...state,
        dashboardDetail: {
          ...state.dashboardDetail,
          anniversary: {
            ...state.dashboardDetail.anniversary,
            policies: state.dashboardDetail.anniversary.policies.map(policy => {
              const shouldUpdateNewPremium = results.find(
                result => result?.bancsPolicyNo === policy?.bancsPolicyNo
              )
              if (shouldUpdateNewPremium)
                return {
                  ...policy,
                  newPremium: shouldUpdateNewPremium?.newPremium,
                }
              return policy
            }),
          },
        },
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default retentionDashboard
