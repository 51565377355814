import { Variables } from '@mlcl-digital/mlcl-design'

import { VariablesType } from '../../../../../types/ComponentLibrary'

const { mediaQueries, space, fontSize } = Variables as VariablesType

const styles = {
  wrap: {
    marginTop: space(3),
    marginBottom: space(7),
    padding: 0,
    [mediaQueries.md]: {
      marginTop: space(5),
      marginBottom: space(25),
      padding: `0 ${space(3, true)}`,
    },
  },
  headingButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: space(3),
    padding: `0 ${space(2, true)}`,
    [mediaQueries.md]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: space(5),
      padding: 0,
    },
  } as const,
  button: {
    marginTop: space(2),
    width: '100%',
    [mediaQueries.md]: {
      width: 'auto',
      marginTop: 0,
      marginLeft: space(3),
    },
  },
  heading: {
    marginBottom: space(1),
    [mediaQueries.md]: {
      marginBottom: 0,
      maxWidth: '40%',
    },
  },
  scrollToTopBtnWrap: {
    marginTop: space(3),
    padding: `0 ${space(2, true)}`,
    [mediaQueries.md]: {
      display: 'none',
    },
  },
  scrollToTopBtn: {
    width: '100%',
  },
  backBtnText: {
    marginRight: space(1),
  },
  backCTA: {
    margin: `0 0 ${space(2.5, true)} ${space(2, true)}`,
    fontSize: fontSize.xs,
    [mediaQueries.md]: {
      margin: `0 0 ${space(4, true)} 0`,
    },
  },
  backNavigationLink: {
    display: 'inline-block',
  },
  backToPolicyText: {
    marginLeft: space(1),
  },
  policyOwnerWrap: {
    marginBottom: space(10),
  },
  downloadCSVIcon: {
    marginRight: space(1),
    height: space(2),
    width: space(2),
  },
  buttonContainer: {
    [mediaQueries.md]: {
      display: 'flex',
    },
  },
  downloadCSVLink: {
    [mediaQueries.md]: {
      marginLeft: 0,
    },
  },
  spinner: {
    marginRight: space(1),
    minHeight: 0,
  },
}

export default styles
