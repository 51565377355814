import { space, colours, fontWeights, mediaQueries } from '../../../../../styles'

const textStyle = {
  fontSize: space(2),
  color: colours.mediumDarkGrey,
  margin: `0 ${space(2, true)} 0 0`,
}

const styles = {
  tagWrap: {
    display: 'flex',
    marginTop: space(7),
    [mediaQueries.xs]: {
      padding: `${space(3, true)} 0 0 0`,
    },
    [mediaQueries.md]: {
      padding: `${space(7, true)} 0 0 0`,
    },
    borderTop: `0.5px solid ${colours.lightestGrey}`,
  },
  tagHeading: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    ...textStyle,
    fontWeight: fontWeights.semiBold,
  },
  icon: {
    width: space(4),
    height: space(4),
    color: 'transparent',
  },
  tagText: {
    ...textStyle,
    fontWeight: fontWeights.normal,
    cursor: 'pointer',
  },
}

export default styles
