// @flow
import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import Tabs from '@mlcl-digital/mlcl-design/lib/base/Tabs'
import Tab from '@mlcl-digital/mlcl-design/lib/base/Tab'
import { Hero, PageWrap, TabWrap } from '@mlcl-digital/mlcl-design'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import { createEvent } from '../../../utils/telemetry'

// components.
import ApplicationListing from './components/ApplicationListing'

import styles from './applicationSearch.styles'

const Wrap = styled(PageWrap)(styles.wrap, styles.tableRow)

type ApplicationSearchProps = {
  fields: {
    newBusinessTab: {
      value: string,
    },
    existingBusinessTab: {
      value: string,
    },
  },
  location: {
    hash: string,
  },
}
export const ApplicationSearch = ({ fields, location: { hash } }: ApplicationSearchProps) => {
  const tabs = {
    NEW_BUSINESS: get(fields, 'newBusinessTab.value'),
    EXISTING: get(fields, 'existingBusinessTab.value'),
  }
  const activeTabValue = hash ? decodeURIComponent(hash.substring(1)) : tabs.NEW_BUSINESS
  const [activeTab, setActiveTab] = useState(activeTabValue)
  const handleTabChange = useCallback(tab => {
    setActiveTab(tabs[tab])

    const tagEvent = createEvent({
      GA: {
        category: 'List of applications',
        action:
          tab === 'NEW_BUSINESS'
            ? 'Select new business applications'
            : 'Select existing business tab applications',
      },
      Splunk: {
        attributes: {
          'workflow.name': `List of applications - ${
            tab === 'NEW_BUSINESS'
              ? 'Select new business applications'
              : 'Select existing business tab applications'
          }`,
        },
      },
    })
    tagEvent.end()
  }, [])

  const heroApplicationSearchFields = {
    heading: get(fields, 'heading', {}),
    content: get(fields, 'content', {}),
  }
  return (
    <>
      <Hero fields={heroApplicationSearchFields} />
      <TabWrap>
        <Tabs>
          {Object.keys(tabs).map((tab, index) => (
            <Tab
              data-testid={`application-listing-tabs-${tabs[tab]}`}
              id={tabs[tab]}
              hash={tabs[tab]}
              dataLocator={tabs[tab]}
              key={tab}
              index={index}
              selected={activeTab === tabs[tab]}
              clickHandler={() => handleTabChange(tab)}
            >
              {tabs[tab]}
            </Tab>
          ))}
        </Tabs>
      </TabWrap>

      <Wrap>
        <ApplicationListing fields={fields} isExistingApplications={activeTab === tabs.EXISTING} />
      </Wrap>
    </>
  )
}

export default withRouter(ApplicationSearch)
