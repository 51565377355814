import { space, colours, fontSize, SIDEBAR_DEFAULT_WIDTH } from '../../../../../styles'

const styles = {
  base: (
    theme,
    panels,
    position,
    visible,
    leftAligned,
    changingAlignment,
    sidebarWidth = SIDEBAR_DEFAULT_WIDTH,
    isDark
  ) => ({
    background: isDark ? colours.darkGreen : theme.bg,
    position: 'fixed',
    top: 0,
    ...(leftAligned
      ? {
          left: 0,
        }
      : {
          right: 0,
        }),
    bottom: 0,
    width: sidebarWidth,
    height: '100%',
    display: 'block',
    ...(changingAlignment
      ? {
          transitionTimingFunction: 'ease',
          transitionDuration: '.35s',
          transitionProperty: 'transform, width',
          transitionDelay: 0,
        }
      : null),
    zIndex: 1,
    [panels]: {
      transform: `translateX(-${(100 / (position + 2)) * position}%)`,
    },
  }),
  head: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    paddingLeft: space(4),
  },
  heading: {
    color: colours.white,
    fontSize: fontSize.xs,
    paddingTop: space(2),
  },
  panelWrap: {
    paddingTop: space(12),
    overflow: 'hidden',
  },
  panels: (index, animation = true) => ({
    width: `${(index + 2) * 100}%`,
    position: 'relative',
    transitionTimingFunction: 'ease',
    ...(animation && {
      transitionDuration: '0.35s',
    }),
    transitionProperty: 'transform',
    transform: 'translateX(0%)',
  }),
  button: {
    width: '100%',
  },
  secondaryButton: ({ styleOverrides }) => ({
    display: 'block',
    margin: '0 auto',
    marginBottom: space(2),
    ...styleOverrides,
  }),
  foot: (show, hasSecondaryAction, styleOverrides) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    padding: space(3),
    height: hasSecondaryAction ? 160 : 96,
    pointerEvents: show ? 'auto' : 'none',
    background: 'transparent',
    transitionTimingFunction: 'ease',
    transitionDuration: '.35s',
    transitionProperty: 'transform',
    transform: show ? 'translateY(0px)' : `translateY(${hasSecondaryAction ? '160px' : '96px'})`,
    ...styleOverrides,
  }),
  spinner: {
    minHeight: 0,
  },
}

export default styles
