import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontWeights, fontSize, colours, mediaQueriesMax } = Variables

const styles = {
  cardCell: ({ isHeader, content }) => ({
    '&:nth-of-type(2n)': {
      textAlign: 'left',
    },
    '&:nth-of-type(3n + 2)': {
      textAlign: 'right',
    },
    '&:nth-of-type(3n)': {
      textAlign: 'right',
    },
    [mediaQueriesMax.xl]: {
      ...(isHeader && { display: 'none' }),
      '&::before': {
        content: `"${content} : "`,
        display: 'block',
        fontWeight: fontWeights.semiBold,
      },
      '&:nth-of-type(n)': {
        textAlign: 'unset',
      },
      '&:nth-of-type(3n)': {
        paddingBottom: space(3),
        borderBottom: `1px solid ${colours.tertiaryThree}`,
      },
      '&:nth-last-of-type(1)': {
        paddingBottom: 'unset',
        borderBottom: 'unset',
      },
    },
  }),
  cardTable: ({ shouldDisplay }) => ({
    gridTemplateColumns: '2fr 1fr 1fr',
    [mediaQueriesMax.xl]: {
      gridTemplateColumns: '1fr',
      display: shouldDisplay ? 'grid' : 'none',
    },
  }),
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  marginRight: {
    marginRight: space(3),
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: `0 ${space(2, true)}`,
    textAlign: 'right',
    color: colours.tertiaryOne,
    dt: {
      width: '81%',
      marginBottom: space(1),
      fontWeight: fontWeights.semiBold,
      [mediaQueriesMax.xl]: {
        width: '70%',
      },
    },
    dd: {
      width: '19%',
      marginBottom: space(1),
      [mediaQueriesMax.xl]: {
        width: '30%',
      },
    },
    [mediaQueriesMax.xl]: {
      padding: 0,
    },
  },
  listTerm: ({ isHighlightTotalPremium }) => ({
    color: isHighlightTotalPremium ? colours.mlcAquaDark : colours.tertiaryTwo,
    fontWeight: fontWeights.semiBold,
  }),
  listTermDescription: ({ isHighlightTotalPremium }) => ({
    color: isHighlightTotalPremium ? colours.mlcAquaDark : colours.tertiaryTwo,
    fontWeight: fontWeights.semiBold,
  }),
  listTermLight: {
    color: colours.tertiaryTwo,
  },
  listTermDescriptionLight: {
    color: colours.tertiaryTwo,
  },
  footerItemValue: {
    fontSize: fontSize.md,
  },
  policyUnavailable: {
    background: colours.functionalDangerLight,
    color: colours.functionalDangerDark,
    padding: `${space(3, true)} ${space(4, true)}`,
  },
  tooltipContainer: {
    display: 'inline-block',
    marginRight: space(1),
  },
  infoIcon: {
    color: colours.tertiaryTwo,
  },
  tooltipContent: {
    width: '314px',
    textAlign: 'left',
  },
  aCTARowItem: {
    display: 'none',
    [mediaQueriesMax.xl]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: `1px solid ${colours.tertiaryThree}`,
      padding: space(3, true),
    },
  },
  connectedBenefit: {
    fontSize: fontSize.xxs,
    marginLeft: 'auto',
    marginTop: '4px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}

export default styles
