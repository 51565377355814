import {
  CORRESPONDENCEHISTORY_FETCH_INIT,
  CORRESPONDENCEHISTORY_FETCH_SUCCESS,
  CORRESPONDENCEHISTORY_FETCH_ERROR,
  CORRESPONDENCEHISTORY_FETCH_FAILURE,
  CORRESPONDENCEHISTORY_CLEAR,
} from '../actions/types/correspondenceHistory'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialFieldState = {
  isSending: false,
  isSendingSuccess: false,
  isError: false,
}

const correspondenceHistory = (state = initialFieldState, action) => {
  switch (action.type) {
    case CORRESPONDENCEHISTORY_FETCH_INIT: {
      return {
        ...state,
        isSending: true,
        isSendingSuccess: false,
        isError: false,
      }
    }

    case CORRESPONDENCEHISTORY_FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isSending: false,
        isSendingSuccess: true,
        isError: false,
      }
    }
    case CORRESPONDENCEHISTORY_FETCH_ERROR:
    case CORRESPONDENCEHISTORY_FETCH_FAILURE: {
      return {
        ...state,
        isSending: false,
        isSendingSuccess: false,
        isError: true,
      }
    }
    case CORRESPONDENCEHISTORY_CLEAR:
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialFieldState
    }
    default: {
      return state
    }
  }
}

export default correspondenceHistory
