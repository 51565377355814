// @flow
import React from 'react'
import { isBrowser } from '../../../../utils/browserUtils'

// components
import PersonalStatementComponent from '../../../organisms/PersonalStatement/index'

type PersonalStatementProps = {
  fields: Object<Object>,
}

const PersonalStatement = ({ fields }: PersonalStatementProps) =>
  isBrowser() && <PersonalStatementComponent fields={fields} />

export default PersonalStatement
