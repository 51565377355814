// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { colours, space, fontSize, fontWeights } = Variables as VariablesType

const styles = {
  footer: {
    padding: `${space(15, true)} 0 ${space(7, true)} 0`,
  },
  bulkUploadDescription: { color: colours.tertiaryOne },
  supportStaffBulkUploadWrapper: {
    width: '100%',
    '& label': {
      marginBottom: space(1),
    },
    flexBasis: 'calc(94% - 9px)',
  } as const,
  validationSummarySection: {
    marginBottom: space(6),
  },
  nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: fontSize.xs,
    padding: '17px 17px 22px 0',
    height: 'auto',
    color: colours.mlcAquaAccessible,
    svg: {
      marginRight: space(0.5),
    },
    '&:hover': {
      color: colours.mlcAquaAccessible,
      textDecoration: 'none',
    },
    textDecoration: 'underline',
  },
  uploadBtn: {
    fontSize: '18px',
  },
  bulkUploadInstructions: {
    p: { marginBottom: space(1.5) },
    color: colours.tertiaryOne,
    '& > table, th, td': {
      // TODO: refer to lib color once confirming with UX team
      border: '1px solid #B9B9B9',
      fontSize: fontSize.xsm,
    },
    '& th, td': {
      padding: '5px 20px',
    },
    th: {
      // TODO: refer to lib color once confirming with UX team
      backgroundColor: '#0000000F',
    },
    table: {
      marginBottom: space(1.5),
    },
  },
  errorsUl: {
    paddingLeft: space(2.5),
    color: colours.functionalDangerDark,
    fontSize: fontSize.xsm,
  },
  errorHeading: {
    color: colours.functionalDangerDark,
    fontWeight: fontWeights.semiBold,
    marginBottom: 0,
    marginTop: space(3),
  },
  listItem: {},
  errorListItem: {},
  CSVDropzoneWrapper: {
    marginTop: '44px',
    '& > div:first-child': {
      flexDirection: 'column',
      gap: '16px',
      padding: '16px 0 32px',
    },
    '& > div:nth-child(2)': {
      padding: space(2),
    },
    '& > div:nth-child(3)': {
      marginTop: space(1.75),
    },
    button: {
      height: '24px',
    },
  } as const,
  filelistWrapper: {
    button: {
      textDecoration: 'underline',
    },
  },
  declarationCheckbox: { '> label': { '> div': { fontSize: fontSize.xsm } } },
  supportStaffBulkUploadDeclarationGroupWrapper: {
    marginTop: space(3),
    width: '86%',
  },
}

export default styles
