// @flow
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Icons } from '@mlcl-digital/mlcl-design'

// Styles
import styles from './passwordCriteria.styles'

// Constants
import { USERNAME_FIELD_KEY, NEW_PASSWORD_FIELD_KEY } from '../Schema/login.schema'

// Mapping for password criteria
import passwordCriteriaMapping from '../passwordCriteriaMapping'

// Icons
const { IconTick16, IconClose16 } = Icons

// Styled components
const Wrapper = styled('div')(styles.wrapper)
const CriteriaList = styled('ul')(styles.criteriaList)
const CrossIcon = styled(IconClose16)(styles.crossIcon)
const ListItem = styled('li')(styles.listItem)

type passwordCriteriaPropTypes = {
  fields: Object,
  // redux form
  form: Object,
  // redux authentication
  authentication: Object,
}
type passwordCriteriaStateTypes = {
  // password criteria
  criteriaMapping: Object,
  // password criteria list
  criteriaList: Array<Object>,
}

class PasswordCriteria extends PureComponent<
  passwordCriteriaPropTypes,
  passwordCriteriaStateTypes
> {
  constructor(props: Object) {
    super(props)
    const { fields } = props
    this.state = {
      // Setting criteria mapping with messages in it
      criteriaMapping: passwordCriteriaMapping(fields),
      // criteria list with isError true/false and message
      criteriaList: this.getCriteriaList(
        (props.form.fields[NEW_PASSWORD_FIELD_KEY] &&
          props.form.fields[NEW_PASSWORD_FIELD_KEY].value) ||
          '',
        passwordCriteriaMapping(fields)
      ),
    }
  }

  componentWillReceiveProps(nextProps: Object) {
    const { form } = this.props
    const { criteriaMapping } = this.state
    if (
      form.fields[NEW_PASSWORD_FIELD_KEY] &&
      form.fields[NEW_PASSWORD_FIELD_KEY].value !==
        nextProps.form.fields[NEW_PASSWORD_FIELD_KEY].value
    ) {
      // When password field value is changed(when user types),
      // update critera list messages on UI(icons)
      this.setState({
        criteriaList: this.getCriteriaList(
          nextProps.form.fields[NEW_PASSWORD_FIELD_KEY].value,
          criteriaMapping
        ),
      })
    }
  }

  // Returns criteria list with isError true/false and message
  getCriteriaList = (password: string, criteriaMapping: Object): Array<Object> => {
    const {
      authentication: { passwordCriteria },
      form,
    } = this.props
    return passwordCriteria
      ? Object.keys(passwordCriteria).map(criteria => ({
          isError: !criteriaMapping[criteria].condition(
            password,
            passwordCriteria[criteria],
            form.fields[USERNAME_FIELD_KEY].value
          ),
          message: criteriaMapping[criteria].message,
        }))
      : []
  }

  rederCriteriaListItem = (): Array<Object> => {
    const { criteriaList } = this.state
    return criteriaList.map(criteria => (
      <ListItem key={criteria.message}>
        {criteria.isError ? <CrossIcon /> : <IconTick16 />}
        {criteria.message}
      </ListItem>
    ))
  }

  render() {
    const { fields } = this.props
    return (
      <Wrapper>
        <p>{fields.passwordFieldDescription.value}</p>
        <CriteriaList>{this.rederCriteriaListItem()}</CriteriaList>
      </Wrapper>
    )
  }
}

export default PasswordCriteria
