// @flow
import React, { Component } from 'react'

// components.
import { Input } from '@mlcl-digital/mlcl-design'
import ResendCodeCta from '../../../molecules/FieldCaptionCta'

// schemas
import { USERNAME_FIELD_KEY, OTP_FIELD_KEY } from '../Schema/login.schema'

type MFAFormProps = {
  // sitecore fields
  fields: Object,
  // handle input change
  handleChange: Function,
  // login form fields
  formFields: Object,
  // redux authentication
  authentication: Object,
  // redux actions
  actions: Object,
}

class MFAForm extends Component<MFAFormProps> {
  componentDidMount() {
    const {
      authentication: { isMFARequired, smsFactorId, sessionToken },
      actions: { sendOktaPrimaryAuthSMSFactor },
      formFields,
    } = this.props
    if (formFields[USERNAME_FIELD_KEY] && formFields[USERNAME_FIELD_KEY].value)
      if (isMFARequired && smsFactorId) {
        sendOktaPrimaryAuthSMSFactor(sessionToken)
      }
  }

  resendCodeHandler = () => {
    const {
      authentication: { isMFARequired, smsFactorId },
      actions: { resendOktaPrimaryAuthSMSFactor },
    } = this.props
    if (isMFARequired && smsFactorId) {
      resendOktaPrimaryAuthSMSFactor()
    }
  }

  render() {
    const {
      fields: {
        codeFieldLabel: { value: codeFieldLabel },
        codeFieldPlaceholder: { value: codeFieldPlaceholder },
        resendCode: { value: resendCode },
        codeFieldDescription: { value: codeFieldDescription },
        contactForCode: { value: contactForCode },
      },
      handleChange,
      formFields,
    } = this.props

    const ResendCodeCtaComponent = (
      <ResendCodeCta type="link" onClick={this.resendCodeHandler}>
        {resendCode}
      </ResendCodeCta>
    )

    // Using labels to create caption for Otp field containing resend code CTA and tel number
    const caption = (
      <div>
        {ResendCodeCtaComponent}
        {codeFieldDescription.match(new RegExp('{resendCode}(.*){contactForCode}'))[1]}
        {/* @FIXME: need to determine if this is sanitized */}
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: contactForCode }} />
        {codeFieldDescription.match(new RegExp('{contactForCode}(.*)'))[1]}
      </div>
    )

    return (
      <Input
        floatedLabel
        htmlFor={OTP_FIELD_KEY}
        label={codeFieldLabel}
        name={OTP_FIELD_KEY}
        changeHandler={handleChange}
        placeholder={codeFieldPlaceholder}
        caption={caption}
        captionWithIcon={false}
        value={(formFields[OTP_FIELD_KEY] && formFields[OTP_FIELD_KEY].value) || ''}
        required
      />
    )
  }
}

export default MFAForm
