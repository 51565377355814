// @flow
import get from 'lodash/get'
// utils
import { required, phone, phoneInputs } from '../../../utils/formUtils'

export const FORM_ID = 'updateContactDetails'
const schema = (fields: Object) => ({
  mobile: {
    condition: [
      required,
      { condition: phone, errorMsg: get(fields, 'MobileNoFormatValidator.value', '') },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: get(fields, 'MobileNoRequiredValidator.value', ''),
  },
  mobilePhoneCode: {
    condition: required,
  },
})

export default schema
