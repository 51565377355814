// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'

// components.
import { Input } from '@mlcl-digital/mlcl-design'

// schemas.
import { NEW_PASSWORD_FIELD_KEY, CONFIRM_NEW_PASSWORD_FIELD_KEY } from '../Schema/login.schema'

// styles
import styles from './resetPasswordForm.styles'

const ConfirmPasswordInput = styled(Input)(styles.confirmPasswordInput)

type ResetPasswordFormProps = {
  // sitecore fields
  fields: Object,
  // handle input change
  handleChange: Function,
  // login form
  formFields: Object,
}

class ResetPasswordForm extends PureComponent<ResetPasswordFormProps> {
  render() {
    const {
      fields: {
        resetPasswordPlaceholder: { value: resetPasswordPlaceholder },
        confirmNewPasswordPlaceholder: { value: confirmNewPasswordPlaceholder },
      },
      handleChange,
      formFields,
    } = this.props

    return (
      <Fragment>
        <ConfirmPasswordInput
          floatedLabel
          type="password"
          htmlFor={NEW_PASSWORD_FIELD_KEY}
          name={NEW_PASSWORD_FIELD_KEY}
          changeHandler={handleChange}
          placeholder={resetPasswordPlaceholder}
          value={
            (formFields[NEW_PASSWORD_FIELD_KEY] && formFields[NEW_PASSWORD_FIELD_KEY].value) || ''
          }
          required
        />
        <Input
          floatedLabel
          type="password"
          htmlFor={CONFIRM_NEW_PASSWORD_FIELD_KEY}
          name={CONFIRM_NEW_PASSWORD_FIELD_KEY}
          changeHandler={handleChange}
          placeholder={confirmNewPasswordPlaceholder}
          value={
            (formFields[CONFIRM_NEW_PASSWORD_FIELD_KEY] &&
              formFields[CONFIRM_NEW_PASSWORD_FIELD_KEY].value) ||
            ''
          }
          required
        />
      </Fragment>
    )
  }
}

export default ResetPasswordForm
