import { space, colours } from '../../../../../../styles'

const disclaimer = {
  disclaimerContainer: {
    padding: space(3, true),
    borderTop: `1px solid ${colours.lightestGrey}`,
  },
  disclaimerMessage: {
    paddingTop: space(1, true),
  },
}

export default disclaimer
