// @flow
import React from 'react'
import styled from '@emotion/styled'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// component
import ArticleCard from '../ArticleCard'

// styles.
import styles from './relatedArticle.styles'

type TagProps = {
  // title of the description
  title: String,
  // learn more text
  articleLinkText: string,
  // save article
  handleSetActiveArticle: Function,
  // articles array
  articles: Array,
}

const RelatedArticleWrap = styled(PageWrap)(styles.relatedArticleWrap)
const RelatedArticleHeading = styled('div')(styles.relatedArticleHeading)
const ArticleCardWrapper = styled('div')(styles.articleCardWrapper)

const RelatedArticle = (props: TagProps) => {
  const { title, articleLinkText, articles, handleSetActiveArticle } = props
  return (
    <RelatedArticleWrap>
      <RelatedArticleHeading>{title}</RelatedArticleHeading>
      <ArticleCardWrapper>
        {articles.map(article => (
          <ArticleCard
            key={article.id}
            title={article.title}
            articleId={article.id}
            shortDescription={article.shortDescription}
            categoryType={article.categoryType}
            footer={articleLinkText}
            handleSetActiveArticle={handleSetActiveArticle}
            link={article.url}
          />
        ))}
      </ArticleCardWrapper>
    </RelatedArticleWrap>
  )
}

export default RelatedArticle
