// @flow
import get from 'lodash/get'
import { required } from '../../../../../../../../utils/formUtils'

export const FORM_ID = 'masterkeyAccountMethod'

const schema = ({ fields }) => ({
  accountNumber: {
    condition: required,
    errorMsg: get(fields, 'cpEditPolicyPaymentDetailMasterKeyAccountNumberErrorMsg.value', ''),
    name: 'accountNumber',
    value: '',
  },
})

export default schema
