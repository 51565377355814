import {
  IP_COVER_CALCULATED,
  SET_IP_SUM_INSURED,
  IP_COVER_SUM_INSURED_ERROR_MODAL,
} from '../actions/types/calculateSaveQuote'

export const initialState = {
  isIPCalculated: false,
  calculatedIPsumInsured: 0,
  showIPSumInsuredErrorModal: false,
}
const calculateSaveQuote = (state = initialState, action) => {
  switch (action.type) {
    case IP_COVER_CALCULATED: {
      const { isIPCalculated } = action.payload
      return {
        ...state,
        isIPCalculated,
        calculatedIPsumInsured: isIPCalculated ? state.calculatedIPsumInsured : 0,
      }
    }
    case SET_IP_SUM_INSURED: {
      const { sumInsured } = action.payload
      return {
        ...state,
        calculatedIPsumInsured: sumInsured,
      }
    }
    case IP_COVER_SUM_INSURED_ERROR_MODAL: {
      const { showModal } = action.payload
      return {
        ...state,
        showIPSumInsuredErrorModal: showModal,
      }
    }
    default: {
      return state
    }
  }
}

export default calculateSaveQuote
