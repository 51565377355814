import { mediaQueries, colours, space } from '../../../../../styles'
import browser from '../../../../../utils/browserUtils'

const isIE11 = browser.ie && browser.ie === 11

const styles = {
  wrapper: {
    flex: '0 0 100%',
    textAlign: 'left',
    marginTop: space(2, true),
    display: 'flex',

    [mediaQueries.md]: {
      textAlign: 'right',
      marginTop: 0,
    },
  },
  updateButton: {
    margin: space(0),
    padding: space(0),
    background: 'transparent',
    border: space(0),
    color: colours.white,
    flex: '0 0 100%',
    textAlign: 'right',
    outline: 'none',
    minWidth: isIE11 ? space(18) : 0,

    '& svg': {
      color: colours.white,
    },
  },
}

export default styles
