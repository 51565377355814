import { space, mediaQueries, colours, fontSize, shadows } from '../../../styles'

const styles = {
  optionList: {
    listStyle: 'none',
    boxShadow: shadows.high,
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    maxHeight: space(30),
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  container: {
    position: 'relative',
    '& svg': {
      color: colours.black,
    },
  },
  mutiSelectWrapper: {
    fontSize: fontSize.sm,
    // lineHeight: space(4, true),

    [mediaQueries.md]: {
      fontSize: fontSize.xs,
    },
  },
  dropDown: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: `${space(1, true)} ${space(2, true)}`,
    height: space(6),
    overflow: 'auto',

    [mediaQueries.md]: {
      padding: `${space(1, true)} ${space(2, true)}`,
      height: space(6),
    },
  },
  option: {
    padding: `${space(1, true)} ${space(2, true)}`,
    height: space(6),
    ':nth-child(2n)': {
      background: colours.white,
    },
    ':nth-child(2n + 1)': {
      background: colours.lightestGrey,
    },
    ':hover': {
      background: colours.lightGrey,
    },
  },
  selectAllOption: {
    ':hover': {
      background: colours.lightestGrey,
    },
  },
  selectAllContainer: {
    '> button': {
      background: 'none',
      border: 'none',
      color: colours.mediumGreen,
      fontSize: fontSize.xxs,
      padding: '0',
      ':first-child': {
        marginRight: space(1),
      },
    },
  },
}

export default styles
