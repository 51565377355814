// @flow
import React from 'react'
// Organism
import AltsSubmissionV2Organism from '../../../organisms/AltsSubmissionV2'

type AltsSubmissionV2Props = {
  fields: Object,
}

const AltsSubmissionV2 = ({ fields }: AltsSubmissionV2Props) => (
  <AltsSubmissionV2Organism fields={fields} />
)

export default AltsSubmissionV2
