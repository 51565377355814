// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import { Loader, Input, Checkbox } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import Button from '../../../../atoms/Button'
import Card from '../../../../atoms/Card'
import Heading from '../../../../atoms/Heading'

import AddressLookup from '../../../../molecules/AddressLookUp'
import InputPhone from '../../../../molecules/InputPhone'

// schema.
import SCHEMA, { FORM_ID } from './contactDetailsForm.schema'

// helpers.
import {
  errorCheck,
  getValue,
  generateFieldsFromData,
  validateAll,
} from '../../../../../utils/formUtils'
import {
  getFullAddressWithState,
  getEmail,
  getPreferredItemBreakdown,
  getNonPreferredLatestItemBreakdown,
  getFullAddressWithStateNonPrefered,
  isAddressesSame,
  getCountryCode,
  getMobile,
  getPreferredValueForMobile,
  determineDistrict,
} from '../../../../../utils/contactUtils'
import { getAdviserNo } from '../../../../../utils/cookieUtils'

// styles.
import styles from '../../advisorAccountSettings.styles'

// contants
import { CONTACT_TYPES } from '../../../../../constants/forms'
import {
  POLICY_RELATIONSHIPS_OWNER,
  PREFERRED_NO,
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_WORK,
  ADDRESS_STATUS_DFL,
  COUNTRY_CODE,
} from '../../../../../constants/policies'
import { DEFAULT_PHONE_CODE, DEFAULT_PHONE_ENTITY } from '../../../../../constants/contactDetails'
import {
  TOAST_ID_UPDATE_CONTACT_INFO,
  TOAST_ID_UPDATE_CONTACT_ERROR,
} from '../../../../../constants/toast'

const Wrap = styled(Card)(styles.base)
const Form = styled('form')(styles.body)
const Header = styled('div')(styles.header)
const Title = styled(Heading)(styles.title)
const Section = styled('div')(styles.section)
const PostalAddressCheckbox = styled(Checkbox)(styles.postalAddressCheckbox)
const Details = styled('div')(styles.details)
const DetailsLabel = styled('div')(styles.label)
const ValueHeading = styled(Heading)(styles.values)
const CancelButton = styled(Button)(styles.cancelButton)

type ContactDetailsFormProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // Redux suplied form state
  form: Object<Object>,
  // Advisor state
  advisor: Object,
  // An object containing action creator functions.
  actions: {
    getAdvisorDetails: Function,
    updateAdvisorDetails: Function,
    formSubmit: Function,
    formInit: Function,
    formSubmitComplete: Function,
    formUpdateValidations: Function,
    updateSupportStaffDetails: Function,
  },
  // master list data
  masterList: Object,
  // Address lookup manual entry redux state mapped to props.
  isManual: boolean,
  isManualPostal: boolean,
  // determines if the advisor data is still being fetched from the server.
  isLoading: boolean,
  // determines if the advisor data for update action is still being fetched from the server.
  isUpdating: boolean,
  activeSupportStaff: Object,
}

type ContactDetailsFormState = {
  // has edit contact details enable
  editContactDetails: boolean,
  isAddressChanged: boolean,
  isPostalAddressChanged: boolean,
}

export const formatValues = data => {
  const { phones = [], emails = [], addresses = [] } = data
  const mobileObj = getMobile(phones)
  const mobile = mobileObj.number || ''
  const mobilePhoneCode = mobileObj.idc || DEFAULT_PHONE_CODE
  const email = getEmail(emails)
  const preferedAddress =
    addresses.length === 1 ? addresses[0] : getPreferredItemBreakdown(addresses)
  const nonPreferedAddress = getNonPreferredLatestItemBreakdown(addresses)
  const isPostalResidentialAddressSame =
    addresses.length < 2 || isAddressesSame(preferedAddress, nonPreferedAddress)

  const postalAddress = getFullAddressWithState([preferedAddress])
  const postalHouseNo = get(preferedAddress, 'houseNo', '')
  const postalStreet = get(preferedAddress, 'street', '')
  const postalLocality = get(preferedAddress, 'locality', '')
  const postalState = get(preferedAddress, 'state', '')
  const postalCountry = get(preferedAddress, 'country', '')
  const postalPostCode = get(preferedAddress, 'postCode', '')

  let address = postalAddress
  let houseNo = postalHouseNo
  let street = postalStreet
  let locality = postalLocality
  let state = postalState
  let country = postalCountry
  let postCode = postalPostCode

  if (!isPostalResidentialAddressSame) {
    address = getFullAddressWithStateNonPrefered([nonPreferedAddress])
    houseNo = get(nonPreferedAddress, 'houseNo', '')
    street = get(nonPreferedAddress, 'street', '')
    locality = get(nonPreferedAddress, 'locality', '')
    state = get(nonPreferedAddress, 'state', '')
    country = get(nonPreferedAddress, 'country', COUNTRY_CODE)
    postCode = get(nonPreferedAddress, 'postCode', '')
  }

  return {
    mobile,
    mobilePhoneCode,
    email,
    address,
    houseNo,
    street,
    locality,
    state,
    country,
    postCode,
    isPostalResidentialAddressSame,
    postalAddress,
    postalHouseNo,
    postalStreet,
    postalLocality,
    postalState,
    postalCountry,
    postalPostCode,
  }
}

export const getAdditionalData = phones => {
  const defaultData = {
    countryCode: COUNTRY_CODE,
    subType: ADDRESS_TYPE_WORK,
  }
  if (!phones || phones.length === 0) return defaultData
  const faxData = getPreferredItemBreakdown(phones, CONTACT_TYPES.FAX)
  const phoneData = getPreferredItemBreakdown(phones, CONTACT_TYPES.TEL)
  if (faxData) {
    return {
      countryCode: faxData.countryCode,
      subType: faxData.subType,
    }
  }
  if (phoneData) {
    return {
      countryCode: phoneData.countryCode,
      subType: phoneData.subType,
    }
  }

  return defaultData
}

export const getAddressForPayload = (
  form,
  addresses,
  isManual,
  isManualPostal,
  isAddressChanged,
  isPostalAddressChanged
) => {
  const {
    street,
    locality,
    state,
    country,
    postCode,
    houseNo,
    postalHouseNo,
    postalStreet,
    postalLocality,
    postalState,
    postalCountry,
    postalPostCode,
    isPostalResidentialAddressSame,
    postalType,
    postalNumber,
    postalPostalType,
    postalPostalNumber,
    postalAddressPONo,
  } = form.fields

  const addressesList = []
  const nonPrefAddress = getNonPreferredLatestItemBreakdown(addresses)
  const prefAddress = getPreferredItemBreakdown(addresses)
  if (!isPostalResidentialAddressSame.value || isPostalResidentialAddressSame.value === '') {
    prefAddress.street =
      postalPostalType && postalPostalType.value === 'PO BOX' ? '' : postalStreet.value
    prefAddress.houseNo = postalHouseNo.value
    prefAddress.locality = postalLocality.value
    prefAddress.state = postalState.value
    prefAddress.country = postalCountry.value || COUNTRY_CODE
    prefAddress.postCode = postalPostCode.value && postalPostCode.value.toString()
    prefAddress.district = determineDistrict(
      postalPostalType,
      postalPostalNumber,
      isManualPostal && !getValue(postalAddressPONo)
        ? ''
        : getValue(postalAddressPONo) || prefAddress.district
    )
    if (isPostalAddressChanged) {
      prefAddress.houseName = ''
    }

    nonPrefAddress.street = postalType && postalType.value === 'PO BOX' ? '' : street.value
    nonPrefAddress.houseNo = houseNo.value
    nonPrefAddress.locality = locality.value
    nonPrefAddress.state = state.value
    nonPrefAddress.country = country.value || COUNTRY_CODE
    nonPrefAddress.postCode = postCode.value && postCode.value.toString()
    nonPrefAddress.addressStatus =
      nonPrefAddress.addressStatus !== undefined ? nonPrefAddress.addressStatus : ADDRESS_STATUS_DFL
    nonPrefAddress.preferred = PREFERRED_NO
    nonPrefAddress.addressType =
      nonPrefAddress.addressType !== undefined ? nonPrefAddress.addressType : ADDRESS_TYPE_HOME
    nonPrefAddress.district = determineDistrict(
      postalType,
      postalNumber,
      isManual ? '' : nonPrefAddress.district
    )
    if (isAddressChanged) {
      nonPrefAddress.houseName = ''
    }
  } else {
    prefAddress.street = postalType && postalType.value === 'PO BOX' ? '' : street.value
    prefAddress.houseNo = houseNo.value
    prefAddress.locality = locality.value
    prefAddress.state = state.value
    prefAddress.country = country.value || COUNTRY_CODE
    prefAddress.postCode = postCode.value && postCode.value.toString()
    prefAddress.addressStatus =
      prefAddress.addressStatus !== undefined ? prefAddress.addressStatus : ADDRESS_STATUS_DFL
    prefAddress.addressType =
      prefAddress.addressType !== undefined ? prefAddress.addressType : ADDRESS_TYPE_HOME
    prefAddress.district =
      getValue(postalAddressPONo.value) ||
      determineDistrict(postalType, postalNumber, prefAddress.district)
    if (isAddressChanged) {
      prefAddress.houseName = ''
    }

    if (addresses.length > 1) {
      nonPrefAddress.street = postalType && postalType.value === 'PO BOX' ? '' : street.value
      nonPrefAddress.houseNo = houseNo.value
      nonPrefAddress.locality = locality.value
      nonPrefAddress.state = state.value
      nonPrefAddress.country = country.value || COUNTRY_CODE
      nonPrefAddress.postCode = postCode.value && postCode.value.toString()
      nonPrefAddress.preferred = PREFERRED_NO
      nonPrefAddress.district =
        getValue(postalAddressPONo.value) ||
        determineDistrict(postalType, postalNumber, nonPrefAddress.district)
      if (isAddressChanged) {
        nonPrefAddress.houseName = ''
      }
    }
  }
  if (nonPrefAddress.country) {
    addressesList.push(nonPrefAddress)
  }

  addressesList.push(prefAddress)

  return addressesList
}

export const ADDRESS_FIELDS = ['address', 'street', 'houseNo', 'locality', 'state', 'postCode']

export const POSTAL_ADDRESS_FIELDS = [
  'postalAddress',
  'postalStreet',
  'postalHouseNo',
  'postalLocality',
  'postalState',
  'postalPostCode',
]

export class ContactDetailsForm extends Component<
  ContactDetailsFormProps,
  ContactDetailsFormState
> {
  constructor() {
    super()
    this.state = {
      editContactDetails: false,
      isAddressChanged: false,
      isPostalAddressChanged: false,
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Advisers contact details - contact details tab',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Advisers contact details - contact details tab',
        },
      },
    })
    tagEvent.end()
  }

  componentWillMount() {
    const { actions, activeSupportStaff } = this.props
    const { getAdvisorDetails } = actions
    if (getAdviserNo()) {
      // support staff
      this.initForm(activeSupportStaff)
    } else {
      getAdvisorDetails(data => {
        this.initForm(data)
      })
    }
  }

  getUpdateDetailsPayload = (): Object => {
    const {
      form,
      advisor: { details: advisorDetails },
      activeSupportStaff,
      isManual,
      isManualPostal,
      masterList,
    } = this.props
    const { isAddressChanged, isPostalAddressChanged } = this.state

    const {
      contactMethods: { phones, emails, addresses },
    } = getAdviserNo() ? activeSupportStaff : advisorDetails
    const { mobile, mobilePhoneCode, email } = form.fields
    const phoneEntityMobile = getMobile(phones)
    const mobileIdc = getValue(mobilePhoneCode.value)
    const phonesPayload = [
      {
        ...DEFAULT_PHONE_ENTITY,
        preferred: getPreferredValueForMobile(phones),
        ...phoneEntityMobile,
        number: mobile.value,
        idc: getValue(mobilePhoneCode.value),
        countryCode: getCountryCode(mobileIdc, masterList),
      },
    ]

    const payload = {
      isTFNProvided: advisorDetails.isTFNProvided,
      contactMethods: {
        phones: phonesPayload,
        emails: [
          {
            ...getPreferredItemBreakdown(emails),
            email: email.value,
          },
        ],
        addresses: [],
      },
      requestedBy: POLICY_RELATIONSHIPS_OWNER,
    }

    payload.contactMethods.addresses = getAddressForPayload(
      form,
      addresses,
      isManual,
      isManualPostal,
      isAddressChanged,
      isPostalAddressChanged
    )

    return payload
  }

  closeEditDetailsForm = () => this.setState({ editContactDetails: false })

  handleSubmit = (): void => {
    const {
      form,
      actions,
      fields,
      isManual,
      isManualPostal,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
    } = this.props
    const {
      formSubmit,
      formInit,
      formSubmitComplete,
      updateAdvisorDetails,
      formUpdateValidations,
      updateSupportStaffDetails,
    } = actions
    const updateDetailsPayload = this.getUpdateDetailsPayload()

    if (form.isSending) return
    const isPOManual = Boolean(getValue(get(form, 'fields.postalAddressPONo', '')))
    const schema = SCHEMA(fields, isManual, isManualPostal, postalHidden, isPOManual)
    formUpdateValidations(FORM_ID, schema)
    formSubmit(FORM_ID, schema, () => {
      if (getAdviserNo()) {
        // support staff
        updateSupportStaffDetails(updateDetailsPayload, (data, err) => {
          if (err) {
            toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
              toastId: TOAST_ID_UPDATE_CONTACT_INFO,
              type: toast.TYPE.ERROR,
            })
          } else {
            const { businessData } = data
            const contactMethods = get(businessData, 'member.contactMethods', {})

            this.setState({
              editContactDetails: false,
            })

            formInit(FORM_ID, schema, formatValues(contactMethods))
          }
        })
      } else {
        updateAdvisorDetails(updateDetailsPayload, (data, err) => {
          if (err) {
            toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
              toastId: TOAST_ID_UPDATE_CONTACT_INFO,
              type: toast.TYPE.ERROR,
            })
          } else {
            const { businessData } = data
            const contactMethods = get(businessData, 'member.contactMethods', {})

            this.setState({
              editContactDetails: false,
            })

            const tagEvent = createEvent({
              GA: {
                category: 'Advisers contact details - contact details tab',
                action: 'Edit',
              },
              Splunk: {
                attributes: {
                  'workflow.name': 'Advisers contact details - contact details tab - Edit',
                },
              },
            })
            tagEvent.end()

            formInit(FORM_ID, schema, formatValues(contactMethods))
          }
        })
      }
    })

    if (validateAll(schema, form.fields)) {
      formSubmitComplete(FORM_ID)
    }
  }

  // handle changes on form elements.
  handleChange = ({ value, name }): void => {
    const {
      actions,
      fields,
      isManual,
      isManualPostal,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
      form,
    } = this.props
    const { formUpdateField, formValidate } = actions
    let isPOManual = false
    if (name === 'postalAddressPONo') {
      isPOManual = Boolean(getValue(value))
    } else {
      isPOManual = Boolean(getValue(get(form, 'fields.postalAddressPONo', '')))
    }
    const schema = SCHEMA(fields, isManual, isManualPostal, postalHidden, isPOManual)
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg,
        name === 'mobile' ? form.fields : {}
      )
      if (charactersCheck.error) return
    }

    const data = {
      error: errorCheck(
        value,
        schema[name].condition,
        schema[name].errorMsg,
        name === 'mobile' ? form.fields : {}
      ),
      value: getValue(value),
    }

    formUpdateField(FORM_ID, name, data)
    if (name === 'postalAddressPONo' && form.isDirty) {
      const postalAddressStreetErrorUpdate = {
        error: errorCheck(
          getValue(get(form, 'fields.postalStreet', '')),
          schema.postalStreet.condition
        ),
      }
      formUpdateField(FORM_ID, 'postalStreet', postalAddressStreetErrorUpdate)
    }
    formValidate(FORM_ID, schema)

    if (ADDRESS_FIELDS.includes(name)) {
      this.setState({ isAddressChanged: true })
    } else if (POSTAL_ADDRESS_FIELDS.includes(name)) {
      this.setState({ isPostalAddressChanged: true })
    }
  }

  addressChange = address => {
    const { name, data, value } = address
    const {
      actions,
      fields,
      isManual,
      isManualPostal,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
    } = this.props
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = SCHEMA(fields, isManual, isManualPostal, postalHidden)
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
    if (name === 'accountSettingsAddress') {
      this.setState({ isAddressChanged: true })
    } else {
      this.setState({ isPostalAddressChanged: true })
    }
  }

  addressToggleHandler = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props
    // We clear address fields when entering manual mode
    if (isManual) {
      formResetField(FORM_ID, ['address', 'houseNo', 'street', 'locality', 'state', 'postCode'])
    }
  }

  addressToggleHandlerPostal = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props
    // We clear address fields when entering manual mode
    if (isManual) {
      formResetField(FORM_ID, [
        'postalAddress',
        'postalHouseNo',
        'postalStreet',
        'postalLocality',
        'postalState',
        'postalPostCode',
        'postalAddressPONo',
      ])
    } else {
      // clear postalAddressPONo when entering auto mode
      formResetField(FORM_ID, ['postalAddressPONo'])
    }
  }

  togglePostalAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
      fields,
    } = this.props
    const { isManualPostal, isManual } = this.props
    const schema = SCHEMA(fields, isManual, isManualPostal, postalHidden)
    const data = {
      error: errorCheck(value, schema[name] && schema[name].condition),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  openEditContactDetails = () => {
    this.setState({
      editContactDetails: true,
    })
  }

  onCancel = () => {
    const {
      actions,
      fields,
      isManual,
      isManualPostal,
      advisor: {
        details: { contactMethods },
      },
    } = this.props
    const { formInit } = actions
    const schema = SCHEMA(fields, isManual, isManualPostal)

    formInit(FORM_ID, schema, formatValues(contactMethods))

    this.setState({
      editContactDetails: false,
    })
  }

  initForm(data: Object) {
    const { actions, fields, isManual, isManualPostal } = this.props
    const { formInit } = actions
    const schema = SCHEMA(fields, isManual, isManualPostal)
    const { contactMethods } = data
    formInit(FORM_ID, schema, formatValues(contactMethods))
  }

  render() {
    const { form, fields, isLoading, isUpdating } = this.props

    if (isLoading) return <Loader type="tab" />

    if (!form) return null

    const {
      mobile,
      email,
      address,
      street,
      houseNo,
      locality,
      state,
      country,
      postCode,
      isPostalResidentialAddressSame,
      postalAddress,
      postalHouseNo,
      postalAddressPONo,
      postalStreet,
      postalLocality,
      postalState,
      postalCountry,
      postalPostCode,
      mobilePhoneCode,
    } = form.fields

    const {
      isManual,
      isManualPostal,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
    } = this.props
    const addressInvalid = country.error.error
    const postalAddressInvalid = postalCountry.error.error
    const schema = SCHEMA(fields, isManual, isManualPostal, postalHidden)
    const {
      accountSettingsUseAsPostal,
      contactFormButton,
      contactFormCancelButton,
      contactFormEditButton,
    } = fields

    const { editContactDetails } = this.state

    const selectMobilePhoneCode = {
      label: fields.phoneCodeLabel,
      value: mobilePhoneCode.value,
      name: 'mobilePhoneCode',
      id: 'mobilePhoneCode',
      changeHandler: this.handleChange,
    }
    const inputMobileNumber = {
      htmlFor: 'mobile',
      label: fields.mobileLabel,
      name: 'mobile',
      phonePlaceholder: fields.mobilePlaceholder,
      changeHandler: this.handleChange,
      error: mobile.error.error,
      value: mobile.value,
      caption: mobile.error.error && mobile.error.errorMsg,
    }
    return (
      <Wrap>
        <Form id="client" aria-labelledby="client-label">
          <Header>
            <Title size="3" element="2">
              {fields.contactHeading}
            </Title>
            <p className="no-margin">{fields.contactIntro}</p>
          </Header>
          {!editContactDetails && (
            <Fragment>
              <Details>
                <DetailsLabel>{fields.mobileLabel}</DetailsLabel>
                <ValueHeading size="6" element="2">
                  {`${getValue(mobilePhoneCode.value)}${mobile.value}`}
                </ValueHeading>
              </Details>
              <Details>
                <DetailsLabel>{fields.emailLabel}</DetailsLabel>
                <ValueHeading size="6" element="2">
                  {email.value}
                </ValueHeading>
              </Details>
              <Details>
                <DetailsLabel>{fields.addressLabel}</DetailsLabel>
                <ValueHeading size="6" element="2">
                  {address.value}
                </ValueHeading>
              </Details>
              {!isPostalResidentialAddressSame.value ||
              isPostalResidentialAddressSame.value === '' ? (
                <Details>
                  <DetailsLabel>{fields.postalAddressLabel}</DetailsLabel>
                  <ValueHeading size="6" element="2">
                    {postalAddress.value}
                  </ValueHeading>
                </Details>
              ) : null}
            </Fragment>
          )}
          <Section>
            {editContactDetails && (
              <Fragment>
                <InputPhone
                  selectPhoneCode={selectMobilePhoneCode}
                  inputPhoneNumber={inputMobileNumber}
                />
                <Input
                  htmlFor="email"
                  label={fields.emailLabel}
                  name="email"
                  phonePlaceholder={fields.emailPlaceholder}
                  changeHandler={this.handleChange}
                  error={email.error.error}
                  value={email.value}
                  caption={email.error.error && email.error.errorMsg}
                />
                <AddressLookup
                  toggleHandler={this.addressToggleHandler}
                  name="accountSettingsAddress"
                  auto={{
                    label: fields.addressLabel,
                    placeholder: fields.addressPlaceholder,
                    toggleLabel: fields.cantFindAddressLabel,
                    value: address.value,
                    selectChangeHandler: this.addressChange,
                    addressError: address.error.error || addressInvalid,
                    addressErrorMessage:
                      (address.error.error && fields.addressRequiredErrorMessage) ||
                      (addressInvalid && fields.addressInvalidErrorMessage),
                  }}
                  manual={{
                    streetLabel: fields.streetLabel,
                    streetPlaceholder: fields.streetPlaceholder,
                    streetValue: street.value,
                    streetRequiredErrorMessage: street.error.errorMsg,
                    streetError: street.error.error,
                    houseNoLabel: fields.houseNoLabel,
                    houseNoPlaceholder: fields.houseNoPlaceholder,
                    houseNoValue: houseNo.value,
                    houseNoError: houseNo.error.error,
                    houseNoRequiredErrorMessage: houseNo.error.errorMsg,
                    houseNoMin: schema.houseNo.minimum,
                    localityLabel: fields.localityLabel,
                    localityPlaceholder: fields.localityPlaceholder,
                    localityLabelValue: locality.value,
                    localityError: locality.error.error,
                    localityRequiredErrorMessage: locality.error.errorMsg,
                    stateLabel: fields.stateLabel,
                    statePlaceholder: fields.statePlaceholder,
                    stateOptions: schema.state.options,
                    stateValue: state.value,
                    stateError: state.error.error,
                    stateRequiredErrorMessage: state.error.errorMsg,
                    countryLabel: fields.countryLabel,
                    countryPlaceholder: fields.countryPlaceholder,
                    countryValue: country.value,
                    countryError: country.error.error,
                    countryRequiredErrorMessage: fields.countryRequiredErrorMessage,
                    postCodeLabel: fields.postCodeLabel,
                    postCodePlaceholder: fields.postCodePlaceholder,
                    postCodeValue: postCode.value,
                    postCodeMin: schema.postCode.minimum,
                    postCodeError: postCode.error.error,
                    postCodeRequiredErrorMessage: postCode.error.errorMsg,
                    manualToggleLabel: fields.manualToggleLabel,
                    inputEntryHandler: this.handleChange,
                  }}
                />
                <PostalAddressCheckbox
                  text={accountSettingsUseAsPostal}
                  name="isPostalResidentialAddressSame"
                  htmlFor="isPostalResidentialAddressSame"
                  onChangeHandler={this.togglePostalAddress}
                  checked={isPostalResidentialAddressSame.value}
                />
              </Fragment>
            )}
            {!isPostalResidentialAddressSame.value && editContactDetails && (
              <AddressLookup
                isManual={
                  !postalAddress.value &&
                  (postalStreet.value ||
                    postalPostCode.value ||
                    postalHouseNo.value ||
                    postalLocality.value ||
                    postalState.value ||
                    postalCountry.value)
                }
                toggleHandler={this.addressToggleHandlerPostal}
                name="accountSettingsPostalAddress"
                auto={{
                  label: fields.postalAddressLabel,
                  placeholder: fields.postalAddressPlaceholder,
                  toggleLabel: fields.cantFindAddressLabel,
                  value: postalAddress.value,
                  selectChangeHandler: this.addressChange,
                  addressError: postalAddress.error.error || postalAddressInvalid,
                  addressName: 'postalAddress',
                  addressErrorMessage:
                    (postalAddress.error.error && fields.postalAddressRequiredErrorMessage) ||
                    (postalAddressInvalid && fields.postalAddressInvalidErrorMessage),
                }}
                manual={{
                  streetLabel: fields.streetLabel,
                  streetPlaceholder: fields.streetPlaceholder,
                  streetValue: postalStreet.value,
                  streetRequiredErrorMessage: postalStreet.error.errorMsg,
                  streetError: postalStreet.error.error,
                  streetName: 'postalStreet',
                  houseNoLabel: fields.houseNoLabel,
                  houseNoPlaceholder: fields.houseNoPlaceholder,
                  houseNoValue: postalHouseNo.value,
                  houseNoName: 'postalHouseNo',
                  postOfficeNoPlaceholder:
                    fields.ContactDetailsFormPostalAddressPONoFieldPlaceholder,
                  postOfficeNoValue: postalAddressPONo.value,
                  postOfficeNoError: postalAddressPONo.error.error,
                  postOfficeNoRequiredErrorMessage: get(
                    postalAddressPONo,
                    'error.errorMsg.value',
                    ''
                  ),
                  postOfficeNoName: 'postalAddressPONo',
                  localityLabel: fields.localityLabel,
                  localityPlaceholder: fields.localityPlaceholder,
                  localityLabelValue: postalLocality.value,
                  localityError: postalLocality.error.error,
                  localityRequiredErrorMessage: postalLocality.error.errorMsg,
                  localityName: 'postalLocality',
                  stateLabel: fields.stateLabel,
                  statePlaceholder: fields.statePlaceholder,
                  stateOptions: schema.postalState.options,
                  stateValue: postalState.value,
                  stateError: postalState.error.error,
                  stateRequiredErrorMessage: postalState.error.errorMsg,
                  stateName: 'postalState',
                  countryLabel: fields.countryLabel,
                  countryPlaceholder: fields.countryPlaceholder,
                  countryValue: postalCountry.value,
                  countryError: postalCountry.error.error,
                  countryRequiredErrorMessage: fields.countryRequiredErrorMessage,
                  countryName: 'postalCountry',
                  postCodeLabel: fields.postCodeLabel,
                  postCodePlaceholder: fields.postCodePlaceholder,
                  postCodeValue: postalPostCode.value,
                  postCodeError: postalPostCode.error.error,
                  postCodeRequiredErrorMessage: postalPostCode.error.errorMsg,
                  postCodeName: 'postalPostCode',
                  manualToggleLabel: fields.manualToggleLabel,
                  inputEntryHandler: this.handleChange,
                  postalType: 'postalPostalType',
                  postalNumber: 'postalPostalNumber',
                }}
              />
            )}
          </Section>
          {editContactDetails ? (
            <div>
              <Button type="secondary" onClick={this.handleSubmit} isLoading={isUpdating}>
                {contactFormButton}
              </Button>
              <CancelButton type="tertiary" onClick={this.onCancel}>
                {contactFormCancelButton}
              </CancelButton>
            </div>
          ) : (
            <div>
              <Button type="secondary" onClick={this.openEditContactDetails}>
                {contactFormEditButton}
              </Button>
            </div>
          )}
        </Form>
      </Wrap>
    )
  }
}

export const mapStateToProps = ({
  forms,
  advisor,
  addressLookup,
  masterList: { data },
  supportStaff: { activeSupportStaff },
}) => ({
  form: forms[FORM_ID],
  advisor,
  masterList: { data },
  isLoading: advisor.isLoading,
  isUpdating: advisor.isUpdating,
  isManual: addressLookup.accountSettingsAddress && addressLookup.accountSettingsAddress.isManual,
  isManualPostal:
    addressLookup.accountSettingsPostalAddress &&
    addressLookup.accountSettingsPostalAddress.isManual,
  activeSupportStaff,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsForm)
