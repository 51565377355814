// @flow
import React, { Node, Fragment } from 'react'
import styled from '@emotion/styled'
import { Button, Heading, Variables, Loader } from '@mlcl-digital/mlcl-design'
import get from 'lodash/get'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// components.
import Header from '../Header'
import Panel from '../Panel'

// utils.
import { convertStringToKey } from '../../../../../utils/commonUtils'
import { SIDEBAR_HEADING_DATA_LOCATOR } from '../../sidebar.locators'

// styles.
import styles from './bar.styles'
import { themeConsumer, getThemeProp } from '../../../../../styles/ThemeContext'

const { colours } = Variables
type BarProps = {
  // Set sidebar visibility.
  visible: boolean,
  // header change with secondary layout
  secondaryHeader?: boolean,
  // A function to be fired when sidebar is closed.
  closeHandler?: Function,
  // A function to be fired when nav item is clicked.
  navHandler?: Function,
  // What component to display in the header of the sidebar.
  heading: Node,
  // Schema for the panels to be in the sidebar.
  schema: Array<{
    title?: string,
    component: Node,
  }> | null,
  // Redux Dispatch function to be passed with callbacks.
  dispatch: Function,
  // Active hoerizontal tabs
  horizontalTabIndex: number,
  // To render all child labels from sitecore content editor
  fields: {},
  // application state for sidebar schema to refer to
  state: {},
  // A set of props passed in from state optionaly from openSidebar dispatches.
  panelProps: {},
  // active sub pannel number if any else undefined
  activeSubPanel?: number,
  // Direction to animate panel
  leftAligned: boolean,
  // Boolean for if sidebar is having direction changed.
  changingAlignment: boolean,
  // Boolean for showing loader on next button
  isNextBtnLoader: boolean,
}

// styled components.
const Head = styled(Header)(styles.head)
const SidebarHeading = styled(Heading)(styles.heading)
const PanelWrap = styled('div')(styles.panelWrap)
const Panels = styled('div')(({ index, animation }) => styles.panels(index, animation))
const Container = styled('section')(
  ({ theme, position, visible, leftAligned, changingAlignment, sidebarWidth, isDark }) =>
    styles.base(
      theme,
      Panels,
      position,
      visible,
      leftAligned,
      changingAlignment,
      sidebarWidth,
      isDark
    )
)
const Foot = styled('div')(({ show, hasSecondaryAction, styleOverrides }) =>
  styles.foot(show, hasSecondaryAction, styleOverrides)
)
const SecondaryButton = styled(Button)(styles.secondaryButton)
const NextButton = styled(Button)(styles.button)
const Spinner = styled(Loader)(styles.spinner)

const Bar = (props: BarProps) => {
  const {
    visible,
    closeHandler,
    schema,
    navHandler,
    dispatch,
    state,
    fields,
    panelProps,
    goBack,
    progressPanel,
    currentPanel,
    animateTo,
    sidebarWidth,
    activeSubPanel,
    leftAligned,
    changingAlignment,
    isNextBtnLoader,
  } = props
  if (!schema) return null
  const schemaCurrentPanel = Array.isArray(schema[currentPanel])
    ? schema[currentPanel][activeSubPanel]
    : schema[currentPanel]

  const { sidebar } = state
  const hasBothNextProps =
    !!schemaCurrentPanel.handleNext &&
    !!schemaCurrentPanel.callbackKey &&
    !!schemaCurrentPanel.props &&
    !!schemaCurrentPanel.props[schemaCurrentPanel.callbackKey]
  const hasFooter = hasBothNextProps ? sidebar.hideFooter : !!schemaCurrentPanel.handleNext
  const hasSecondaryAction = hasFooter && !!schemaCurrentPanel.handleSecondaryAction
  const isSecondaryHeader = get(schemaCurrentPanel, 'props.secondaryHeader', false)
  const isForcedClose = get(schemaCurrentPanel, 'props.forcedClose', false)
  const isforcedBackNav = get(schemaCurrentPanel, 'props.forcedBackNav', null)
  const isAnimate = get(schemaCurrentPanel, 'props.animation', true)
  const isTopfoot = schemaCurrentPanel.props?.topFoot
  // eslint-disable-next-line prefer-destructuring
  const nextTabSideBarLabelPanel = sidebar.nextTabSideBarLabelPanel

  let handleBackNavigation = navHandler
  if (currentPanel !== 0 && !isforcedBackNav) {
    handleBackNavigation = goBack
  }
  if (isforcedBackNav) {
    handleBackNavigation = () => isforcedBackNav(dispatch, state)
  }

  const renderFoot = () => (
    <Foot
      show={hasFooter}
      hasSecondaryAction={hasSecondaryAction}
      isDark={schemaCurrentPanel.isDark}
      styleOverrides={schemaCurrentPanel.props?.footStyleOverrides}
    >
      {hasSecondaryAction && (
        <SecondaryButton
          variant="tertiary"
          onClick={e => {
            e.preventDefault()
            if (schemaCurrentPanel)
              schemaCurrentPanel.handleSecondaryAction(dispatch, state, fields)
          }}
          styleOverrides={schemaCurrentPanel.props?.secondaryActionStyleOverrides}
        >
          {schemaCurrentPanel.secondaryActionText}
        </SecondaryButton>
      )}
      <NextButton
        disabled={
          isNextBtnLoader ||
          (schemaCurrentPanel.nextDisabled && schemaCurrentPanel.nextDisabled(state)) ||
          false
        }
        type="primary"
        onClick={e => {
          e.preventDefault()
          if (hasFooter) schemaCurrentPanel.handleNext(progressPanel, dispatch, state, fields)
        }}
      >
        {isNextBtnLoader ? (
          <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
        ) : (
          `${schemaCurrentPanel.nextText || 'Next'}`
        )}
      </NextButton>
    </Foot>
  )

  return (
    <Container
      visible={visible}
      {...getThemeProp(props)}
      position={animateTo}
      sidebarWidth={sidebarWidth}
      leftAligned={leftAligned}
      changingAlignment={changingAlignment}
      isDark={schemaCurrentPanel.isDark}
    >
      <Fragment>
        <Head
          {...getThemeProp(props)}
          closeHandler={closeHandler}
          navHandler={handleBackNavigation}
          secondaryHeader={isSecondaryHeader}
          forcedClose={isForcedClose}
          headerDataLocator={convertStringToKey(
            (fields[schemaCurrentPanel.sitecoreFieldHeadingKey] &&
              fields[schemaCurrentPanel.sitecoreFieldHeadingKey].value) ||
              SIDEBAR_HEADING_DATA_LOCATOR
          )}
          forcedBackNav={isforcedBackNav}
        >
          <SidebarHeading
            variant="h4"
            styleOverrides={{
              color: colours.tertiaryWhite,
            }}
          >
            {nextTabSideBarLabelPanel || (
              <Text field={fields[schemaCurrentPanel.sitecoreFieldHeadingKey]} />
            )}
          </SidebarHeading>
        </Head>

        <PanelWrap>
          <Panels index={currentPanel} animation={isAnimate}>
            {schema.map((item, i) =>
              i <= currentPanel + 1 ? (
                <Panel
                  panelProps={panelProps}
                  // @FIXME: need to determine a better key
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  id={i}
                  currentPanelId={currentPanel}
                  schema={schema[i]}
                  callback={progressPanel}
                  fields={fields}
                  hasFooter={hasFooter}
                  hasSecondaryAction={hasSecondaryAction}
                  activeSubPanel={activeSubPanel}
                  renderFoot={isTopfoot && renderFoot}
                />
              ) : null
            )}
          </Panels>
        </PanelWrap>
        {!isTopfoot && renderFoot()}
      </Fragment>
    </Container>
  )
}

Bar.defaultProps = {
  closeHandler: null,
  navHandler: null,
  secondaryHeader: false,
  activeSubPanel: undefined,
}

export default themeConsumer(Bar, 'bar')
