// @flow
import {
  FETCH_CREDIT_CARD_INFO_INIT,
  FETCH_CREDIT_CARD_INFO_SUCCESS,
  FETCH_CREDIT_CARD_INFO_ERROR,
  FETCH_CREDIT_CARD_INFO_FAILURE,
  FETCH_NAB_DATA,
  FETCH_NAB_DATA_SUCCESS,
  FETCH_NAB_DATA_ERROR,
  FETCH_NAB_DATA_FAILURE,
  DISPLAY_CREDIT_CARD_LISTING,
} from '../actions/types/captureCreditCard'

export const initialState = {
  isFetchingCreditCardInfo: false,
  isCreditCardInfoError: false,
  creditCardInfo: null,
  isFetchingNabData: false,
  isNabDataError: false,
  nabData: null,
  displayCreditCardListing: false,
}

const captureCreditCard = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case FETCH_CREDIT_CARD_INFO_INIT: {
      return {
        ...state,
        isFetchingCreditCardInfo: true,
        isCreditCardInfoError: false,
      }
    }
    case FETCH_CREDIT_CARD_INFO_SUCCESS: {
      return {
        ...state,
        creditCardInfo: action.payload,
        isFetchingCreditCardInfo: false,
        isCreditCardInfoError: false,
      }
    }
    case FETCH_CREDIT_CARD_INFO_ERROR:
    case FETCH_CREDIT_CARD_INFO_FAILURE: {
      return {
        ...state,
        isFetchingCreditCardInfo: false,
        isCreditCardInfoError: true,
      }
    }
    case FETCH_NAB_DATA: {
      return {
        ...state,
        isFetchingNabData: true,
        isNabDataError: false,
      }
    }
    case FETCH_NAB_DATA_SUCCESS: {
      return {
        ...state,
        nabData: action.payload,
        isFetchingNabData: false,
        isNabDataError: false,
      }
    }
    case FETCH_NAB_DATA_ERROR:
    case FETCH_NAB_DATA_FAILURE: {
      return {
        ...state,
        isFetchingNabData: false,
        isNabDataError: true,
      }
    }
    case DISPLAY_CREDIT_CARD_LISTING: {
      return {
        ...state,
        displayCreditCardListing: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default captureCreditCard
