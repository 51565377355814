// @flow
import React from 'react'

// components.
import HeaderComponent from '../../../organisms/Header'

type HeaderProps = {
  fields: Object,
}

const Header = (props: HeaderProps) => {
  const { fields } = props
  return <HeaderComponent fields={fields} />
}

export default Header
