/* eslint-disable no-unused-vars */
import { fontWeights, fontSize, colours, mediaQueries, space } from '../../../../../styles'

const styles = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  preferencesWrapper: {
    position: 'relative',
    marginTop: 0,
    minHeight: '1300px',

    [mediaQueries.md]: {
      marginTop: space(9),
    },
  },
  notificationWrapper: {
    display: 'block',
    float: 'right',
    right: 0,
    width: '100%',
    [mediaQueries.md]: {
      width: '40%',
    },
    div: {
      backgroundColor: colours.mediumGreen,
      color: colours.white,
    },
    button: {
      cursor: 'pointer',
    },
  },
  base: {
    overflowY: 'scroll',
  },
}
export default styles
