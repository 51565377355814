import { colours, fontSize, fontWeights } from '../../../styles'

const styles = {
  fieldCaptionCta: {
    color: colours.mediumGreen,
    padding: 0,
    lineHeight: 1,
    fontSize: fontSize.xxs,
    height: 'auto',
    fontWeight: fontWeights.normal,
  },
}

export default styles
