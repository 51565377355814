// @flow
import React from 'react'
import styled from '@emotion/styled'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'

// styles
import styles from './applicationDetails.styles'

const ApplicationDetailsContainer = styled('table')(styles.container)
const ItemContainer = styled('tr')(styles.itemContainer)
const Title = styled('h2')(styles.title)
const ApplicationDetailsWrapper = styled('div')(styles.wrapper)
const Label = styled('td')(styles.label)
const ValueContainer = styled('td')(styles.valueContainer)
const ValueWapper = styled('span')(styles.valueWapper)
const Column = styled('td')(styles.column)

type ApplicationDetailsPropTypes = {
  // quote collection
  quoteData: Object,
  // Sitecore fields
  fields: Object,
}

const ApplicationDetailsItem = (label, value) => (
  <ValueContainer>
    <table>
      <tbody>
        <tr>
          <Label>{renderTextField(label)}</Label>
          <Column>
            <ValueWapper>
              {typeof value === 'string'
                ? value
                : value.map(element => (
                    <span key={element}>
                      {element}
                      <br />
                    </span>
                  ))}
            </ValueWapper>
          </Column>
        </tr>
      </tbody>
    </table>
  </ValueContainer>
)

const getPolicyNumber = (policies = [], noPolicy) => {
  const policyNo = [...new Set(policies.map(policy => (policy.policyNo ? policy.policyNo : '')))]
  return policyNo.length ? policyNo : noPolicy.value
}

const ApplicationDetails = ({ quoteData, fields }: ApplicationDetailsPropTypes) => {
  const {
    appSummaryApplicationDetailsLabel,
    appSummaryApplicationIdLabel,
    appSummaryPolicyNoLabel,
    appSummaryPolicyNoNotAvailableLabel,
  } = fields
  const { quoteCollectionName } = quoteData
  const lifeInsured = quoteCollectionName.includes(',')
    ? quoteCollectionName.split(',').map(item => item.trim())
    : [quoteCollectionName]
  return (
    <ApplicationDetailsWrapper>
      <Title>{renderTextField(appSummaryApplicationDetailsLabel)}</Title>
      <ApplicationDetailsContainer>
        <tbody>
          <ItemContainer>
            {ApplicationDetailsItem(appSummaryApplicationIdLabel, lifeInsured)}
            {ApplicationDetailsItem(
              appSummaryPolicyNoLabel,
              getPolicyNumber(quoteData.policies, appSummaryPolicyNoNotAvailableLabel)
            )}
          </ItemContainer>
        </tbody>
      </ApplicationDetailsContainer>
    </ApplicationDetailsWrapper>
  )
}

export default ApplicationDetails
