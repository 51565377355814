// @flow
import React from 'react'
import styled from '@emotion/styled'

// atoms
import TagComponent from '../../../../../atoms/Tag'
import ButtonComponent from '../../../../../atoms/Button'

// styles.
import styles from './dataFeedRow.styles'
import { ignoreColumns } from '../../../../../../utils/stylesUtils'

type DataFeedRowProps = {
  // sitecore Fields
  fields: Object<Object>,
  // member Entity
  title: String,
  handleCancelDataFeed: Function,
}

const FullWidth = ignoreColumns('div')
const Line = styled(FullWidth)(styles.line)
const ItemBlockHidden = styled('div')(styles.lineBoxHidden)
const Tag = styled(TagComponent)(styles.activeBox)
const TextBox = styled('div')(styles.textBox)
const Button = styled(ButtonComponent)(styles.button)
const SmallButton = styled(Button)(styles.smallButton)

const DataFeedRow = (props: DataFeedRowProps) => {
  const { title, fields, handleCancelDataFeed } = props

  if (Array.isArray(title) && title.length === 0) {
    return null
  }

  return (
    <Line>
      <ItemBlockHidden>
        <Tag type="primary">{fields.dataFeedRegistrationActiveText}</Tag>
      </ItemBlockHidden>
      <TextBox>{title}</TextBox>
      <SmallButton type="tertiary" onClick={() => handleCancelDataFeed(title)}>
        {fields.dataFeedRegistrationCancelText}
      </SmallButton>
    </Line>
  )
}

export default DataFeedRow
