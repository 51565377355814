import { Variables } from '@mlcl-digital/mlcl-design'

import { VariablesType } from '../../../../../types/ComponentLibrary'

const { space, colours, fontWeights, fontStyles, fontSize, mediaQueries } =
  Variables as VariablesType

const styles = {
  wrapper: {
    background: colours.tertiarySix,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [mediaQueries.md]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  } as const,
  policyTypeContainer: {
    marginBottom: space(1),
    flexBasis: '100%',
    order: 1,
    '> div': {
      marginRight: space(1),
    },
    [mediaQueries.md]: {
      flexBasis: '50%',
      marginBottom: 0,
    },
  },
  ctaWrapper: {
    flexBasis: '100%',
    order: 3,
    [mediaQueries.md]: {
      flexBasis: '50%',
      order: 2,
      textAlign: 'right',
    },
  } as const,
  headingWrapper: {
    flexBasis: '100%',
    order: 2,
    marginBottom: space(2),
    flexDirection: 'column',
    display: 'flex',
    '> div': {
      alignItems: 'flex-start',
    },
    [mediaQueries.md]: {
      order: 3,
      flexDirection: 'row',
      '> div': {
        marginLeft: space(2, true),
        marginTop: space(0.25, true),
      },
      marginBottom: space(3),
      marginTop: space(1),
    },
  } as const,
  button: {
    [mediaQueries.md]: {
      width: 'auto',
    },
  },
  downloadIcon: {
    marginRight: space(1),
    height: space(2),
    width: space(2),
    [mediaQueries.md]: {
      width: 'auto',
      marginLeft: 0,
    },
  },

  downloadCTA: {
    marginBottom: space(4),
    paddingLeft: 0,
    fontSize: fontSize.xs,
    [mediaQueries.md]: {
      marginBottom: 0,
      padding: `${space(1, true)} ${space(2, true)}`,
    },
  },
  premiumTooltipContainer: {
    ...fontStyles.medium,
    '& > div:first-of-type': {
      fontWeight: fontWeights.semiBold,
    },
  },
  spinner: {
    marginRight: space(1),
    minHeight: 0,
  },
}

export default styles
