// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Heading } from '@mlcl-digital/mlcl-design'
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import styles from './title.styles'

type TitleProps = {
  heading: {
    value: String,
  },
  subHeading: {
    value: String,
  },
  subComponent: any,
  isTag: Boolean,
  theme: Object,
}

export const Wrapper = styled('div')(styles.wrapper)
export const PageHeading = styled(Heading)(({ theme }) => styles.heading(theme))
export const SubHeading = styled('p')(styles.subHeading)
export const HeadingSubComponentWrapper = styled('div')(styles.headingSubComponentWrapper)
export const SubComponent = styled('span')(styles.subComponent)

const Title = ({ theme, heading, subHeading, subComponent, isTag = false }: TitleProps) => {
  if (isTag) {
    return (
      <Wrapper>
        <HeadingSubComponentWrapper>
          <PageHeading theme={theme} variant="h1">
            {renderTextField(heading)}
          </PageHeading>
          {subComponent && <SubComponent>{subComponent}</SubComponent>}
        </HeadingSubComponentWrapper>
        {subHeading && <SubHeading>{renderTextField(subHeading)}</SubHeading>}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <PageHeading theme={theme} variant="h1">
        {renderTextField(heading)}
      </PageHeading>
      {subComponent}
      {subHeading && <SubHeading>{renderTextField(subHeading)}</SubHeading>}
    </Wrapper>
  )
}

export default Title
