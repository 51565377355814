import React, { useEffect, useState, useMemo } from 'react'
import PropTypes, { shape } from 'prop-types'
import moment from 'moment'
import { Chip, Button, Icons, Input, Select, Tooltip } from '@mlcl-digital/mlcl-design'
import { useDispatch, useSelector, batch } from 'react-redux'

import styled from '@emotion/styled'
import get from 'lodash/get'
import { createEvent } from '../../../utils/telemetry'
import styles from './altsDecreasePolicyCard.styles'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import { formatCurrency } from '../../../utils/quoteUtils'
import {
  filterBenifitsForConformationPage,
  getCoverPeriodFromModifiedOptions,
  getWaitingPeriodFromModifiedOptions,
  validateParentChildCoverAmounts,
} from '../../../utils/alteration'
import { getLinkedSgboBenefits } from '../../../utils/altsQuoteUtils'
import { isFeatureEnabled } from '../../../utils/featureToggling'

// components
import AltsDecreaseLoadingExclusionModal from './components/AltsDecreaseLoadingExclusionModal'
import { AltsDecreaseCoverRowOption } from './AltsDecreaseCoverRowOption'

// actions
import { actionCreators } from '../../../actions'
import {
  DECREASE_SUM_INSURED,
  BENEFIT_ALTS_TYPE_REMOVE,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
  UNCHANGED,
  SYSTEM_GENERATED_REASON,
} from '../../../constants/alterations'
import WithLoader from '../WithLoader'

// constants
import {
  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
  BENEFIT_NATURE_TYPE_RIDER_CONNECTED,
  BENEFIT_NATURE_TYPE_SATELLITE,
  BENEFIT_NATURE_TYPE_RIDER,
  NEW_STEPPED_LABEL,
  PREMIUM_STYLE_STEPPED,
} from '../../../constants/benefit'
import {
  getAltsProductsWaitingPeriods,
  getWaitingPeriodAndBenefitPeriodEligliblity,
  isAllChildBenefitsRemoved,
} from '../../../selectors/alterations'
import { getAlterationModalMessagesBasedOnCode } from '../../../selectors/masterData'
import { getPolicyStructure, getConfig } from '../../../selectors/common.selectors'
import { setIsValidationError } from '../../../actions/creators/alterations'
import { isSGBOBenefit } from '../../../utils/extendedQuoteUtils'
import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PRODUCT_CODE_NON_SUPER,
} from '../../../constants/policies'

// hooks
import useEffectSkipMount from '../../../hooks/useEffectSkipMount'

const RowContainer = styled('div')(styles.coverContainer)
const DataRow = styled('div')(({ isNewPremium, isRemoved, hasChanged, shouldHighlight }) =>
  styles.dataRow({ isNewPremium, isRemoved, hasChanged, shouldHighlight })
)
const StyledCardCell = styled('div')(({ isHeader, isCTA }) => styles.cardCell({ isHeader, isCTA }))
const SumInsuredInput = styled(Input)(({ error }) => styles.sumInsuredInput({ error }))
const BenefitNameContainer = styled('div')(styles.benefitNameContainer)
const BenefitName = styled('div')(styles.benefitName)
const LinkedBenefitBox = styled('div')(({ isChild }) => styles.linkedBenefitBox({ isChild }))
const OptionsRow = styled('div')(({ isWaitingPeriodEdited, isCoverPeriodEdited, isNewPremium }) =>
  styles.optionsRow({ isWaitingPeriodEdited, isCoverPeriodEdited, isNewPremium })
)
const OptionsButton = styled(Button)(({ hasChangedOptions }) =>
  styles.optionsButton({ hasChangedOptions })
)
const ResetButton = styled(Button)(({ isOptions }) => styles.resetButton({ isOptions }))
const StyledChip = styled(Chip)(styles.removedFeatureChip)
const TooltipContainer = styled('div')(styles.tooltipContainer)
const { IconChevronDown16, IconLink16, IconChevronUp16, IconInfo16 } = Icons
const InfoIcon = styled(IconInfo16)(styles.infoIcon)

const initialErrorState = {
  error: false,
  errorMsg: '',
}

const AltsDecreaseCoverRow = ({
  isNewPremium,
  policyType,
  activeTabValue,
  fields,
  bancsPolicyNo,
  alteration,
  lifeInsured,
  listOfBenefitRowIds,
  setBenefitRowIdsList,
  policyInstanceNo,
  isFetchingCalculateQuote,
  cover,
  isConfirmationPage,
  showedBenefitName,
  productId,
  isPolicyChangeDisabled,
}) => {
  const {
    premiumStyle,
    benefitName,
    parentBenefit = {},
    optimiserParentBenefitReference,
    optimiserParentBenefit,
    benefitCommencementDate,
    hideSumInsured,
    coverAmount,
    disableSumInsured,
    premiumAmount,
    isIPorBEBenefit,
    periods,
    isOptionsVisible,
    applicableFeatures,
    loading,
    exclusion,
    type,
    benefitInstanceNo,
    isRemoveDisabled,
    isCancelRemoveBenefitDisabled,
    apEnabledFeatures,
    childBenefits,
  } = cover
  const {
    CancelCTA,
    RemoveCTA,
    BenefitPeriod,
    WaitingPeriod,
    BenefitOptions,
    BenefitViewOptions,
    SumInsuredPlaceholder,
    Reduced,
    ResetRowCTA,
    RemoveBenefit,
    SumInsuredBlankErrorMsg,
    SumInsuredErrorMsg,
    ViewLinkedButtonLabel,
    ViewOptimizedCTA,
    HideOptionsDropdownLabel,
    AlteredLabel,
  } = fields
  const config = useSelector(getConfig)
  const isCaliRelabel = isFeatureEnabled('CaliRelabel', config?.FEATURES)
  const [editing, setEditing] = useState(false)
  const [optionsExpanded, setOptionsExpanded] = useState(false)
  const optionLabelMappingObj = {
    true: renderTextField(HideOptionsDropdownLabel),
    false: renderTextField(BenefitViewOptions),
  }
  const [shouldHighlight, setShouldHighlight] = useState(false)
  const [sumInsuredError, setSumInsuredError] = useState(initialErrorState)
  const dispatch = useDispatch()
  const alteredBenefits = get(alteration, 'alteredBenefits') || []
  const isFetchingPremiums = isFetchingCalculateQuote && isNewPremium && !isConfirmationPage
  const { benefitPeriodData, waitingPeriodData } = useSelector(state =>
    getAltsProductsWaitingPeriods(state, {
      type,
      periods,
      productId,
    })
  )
  // as requirement of RET-11708 only 'Rider Connected' and
  // 'Satellite' could be considered to check for blocking removal
  const removableChildBenefits =
    childBenefits &&
    childBenefits.filter(
      childBenefit =>
        childBenefit.benefitNature === BENEFIT_NATURE_TYPE_RIDER_CONNECTED ||
        childBenefit.benefitNature === BENEFIT_NATURE_TYPE_SATELLITE ||
        childBenefit.benefitNature === BENEFIT_NATURE_TYPE_RIDER
    )
  const hasAllChildBenefitsRemoved = useSelector(state =>
    isAllChildBenefitsRemoved(state, removableChildBenefits)
  )
  const policies = useSelector(getPolicyStructure)
  const currentAlteredBenefit = useMemo(
    () =>
      alteredBenefits.find(
        benefit => benefit.benefitCode === type && +benefit.benefitInstanceNo === +benefitInstanceNo
      ) || {},
    [alteredBenefits]
  )
  const {
    newGrossBenefitPremium,
    newSumInsured,
    benefitAlterationType,
    modifiedOptions,
    isSystemGenerated,
    systemGeneratedReason,
  } = currentAlteredBenefit
  const isRemoved = !isSystemGenerated && benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE
  const isAltered =
    isSystemGenerated &&
    (benefitAlterationType !== UNCHANGED ||
      (benefitAlterationType === UNCHANGED &&
        systemGeneratedReason !== SYSTEM_GENERATED_REASON.NONE))

  // Filter out removed benefits for conformation page
  const filterdBenefits =
    isConfirmationPage && modifiedOptions
      ? filterBenifitsForConformationPage(applicableFeatures, modifiedOptions)
      : applicableFeatures

  let waitingPeriod = modifiedOptions && getWaitingPeriodFromModifiedOptions(modifiedOptions)
  let isWaitingPeriodEdited = false

  if (waitingPeriod) {
    isWaitingPeriodEdited = true
  } else {
    waitingPeriod = {
      value: get(periods, 'waitingPeriod'),
      unit: get(periods, 'waitingPeriodUnit'),
    }
  }

  let coverPeriod = modifiedOptions && getCoverPeriodFromModifiedOptions(modifiedOptions)
  let isCoverPeriodEdited = false

  if (coverPeriod) {
    isCoverPeriodEdited = true
  } else {
    coverPeriod = {
      value: get(periods, 'coverPeriod'),
      unit: get(periods, 'coverPeriodUnit'),
    }
  }

  const largestWaitingPeriodSelected =
    get(periods, 'waitingPeriod') === get(waitingPeriodData[waitingPeriodData.length - 1], 'value')

  const largestBenefitPeriodSelected =
    get(periods, 'coverPeriod') === get(benefitPeriodData[benefitPeriodData.length - 1], 'value')

  const tooltipMessage = useSelector(state =>
    getAlterationModalMessagesBasedOnCode(
      state,
      'decreaseCoverBlockWaitingBenefitPeriodUpdatesFor59Plus'
    )
  )
  const { isBenefitPeriodDisabled, isWaitingPeriodDisabled } = useSelector(state =>
    getWaitingPeriodAndBenefitPeriodEligliblity(state, {
      type,
      benefitInstanceNo,
    })
  )

  useEffect(() => {
    setEditing(benefitAlterationType === DECREASE_SUM_INSURED)
  }, [currentAlteredBenefit])

  const hasChangedOptions =
    isNewPremium && (isRemoved || get(currentAlteredBenefit, 'modifiedOptions', false))

  const {
    apDecreaseBenefitPeriod,
    apDecreaseSumInsured,
    apIncreaseWaitingPeriod,
    apRemoveBenefit,
    apRemoveBenefitOption,
  } = apEnabledFeatures
  const dataRowId = `${policyInstanceNo}-${type}-${benefitInstanceNo}`

  const linkedSGBOBenefits = getLinkedSgboBenefits(type, childBenefits, policies)

  const isCoverASGBOBenefit = isSGBOBenefit(type)

  // reset the highlight when user clicks anywhere after a row was highlighted
  useEffect(() => {
    const resetHighlight = () => {
      if (listOfBenefitRowIds.includes(dataRowId) && shouldHighlight) setShouldHighlight(false)
    }
    document.addEventListener('click', resetHighlight)
    // clean up
    return function cleanup() {
      document.removeEventListener('click', resetHighlight)
    }
  }, [listOfBenefitRowIds, shouldHighlight])

  useEffect(() => {
    setOptionsExpanded(false)
  }, [activeTabValue])

  useEffectSkipMount(() => {
    dispatch(setIsValidationError(sumInsuredError.error))
  }, [sumInsuredError.error])

  useEffect(() => {
    if (listOfBenefitRowIds.includes(dataRowId)) {
      // Scroll the element into the center of viewport
      document
        .getElementById(dataRowId)
        .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      // Set the highlight to cause a re render to apply the highlight
      setShouldHighlight(true)
    }
  }, [listOfBenefitRowIds])

  const handleRemoveBenefit = () => {
    if (childBenefits) {
      if (!hasAllChildBenefitsRemoved) {
        batch(() => {
          dispatch(
            actionCreators.setModalDescription({
              modalType: 'altsErrorModal',
              description: 'altsCantCancelParentBenefitBeforeChild',
            })
          )
          dispatch(actionCreators.showModal('altsErrorModal'))
        })
        return
      }
    }
    dispatch(
      actionCreators.altsRemoveBenefit(
        {
          type,
          benefitInstanceNo,
          name: benefitName,
          optimiserParentBenefitReference,
        },
        bancsPolicyNo,
        policyInstanceNo
      )
    )
    dispatch(actionCreators.altsCalculateQuote())
    const event = createEvent({
      GA: {
        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
        action: 'Remove benefit',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Remove benefit`,
        },
      },
    })
    event.end()
  }

  /**
   * Updates error object corresponding to the field
   * @param {string} value - value of sum insured field
   */
  const updateSumInsuredError = value => {
    if (value === '') {
      setSumInsuredError({
        error: true,
        errorMsg: get(SumInsuredBlankErrorMsg, 'value', ''),
      })
    } else if (+value > +coverAmount) {
      setSumInsuredError({
        error: true,
        errorMsg: get(SumInsuredErrorMsg, 'value', ''),
      })
    } else if (+value === 0 && isRemoveDisabled) {
      setSumInsuredError({
        error: true,
        errorMsg: get(SumInsuredBlankErrorMsg, 'value', ''),
      })
    } else {
      setSumInsuredError(initialErrorState)
    }
  }

  useEffect(() => {
    // newSumInsured can be '' and we want to show error
    if (newSumInsured !== undefined) updateSumInsuredError(newSumInsured)
    dispatch(actionCreators.initModal('altsErrorModal'))
  }, [])

  // invoked on reset of sum insured or cancel of remove benefit
  const resetRowHandler = () => {
    setSumInsuredError(initialErrorState)
    dispatch(
      actionCreators.resetRemoveSumInsured(
        {
          type,
          benefitInstanceNo,
          optimiserParentBenefitReference,
        },
        bancsPolicyNo,
        policyInstanceNo
      )
    )
    dispatch(actionCreators.calculateAlteredPoliciesInQuote())
  }

  const resetWaitingBenefitPeriodHelper = (bType, bInstanceNo, isBenefitPeriod) =>
    dispatch(
      actionCreators.resetWaitingBenefitPeriodData(
        {
          type: bType,
          benefitInstanceNo: bInstanceNo,
        },
        bancsPolicyNo,
        isBenefitPeriod
      )
    )

  const resetWaitingBenefitPeriodHelperUtility = (bType, bInstanceNo, isBenefitPeriod) => {
    resetWaitingBenefitPeriodHelper(bType, bInstanceNo, isBenefitPeriod)

    linkedSGBOBenefits.length > 0 &&
      linkedSGBOBenefits.forEach(({ type: cbType, benefitInstanceNo: cbInstanceNo }) =>
        resetWaitingBenefitPeriodHelper(cbType, cbInstanceNo, isBenefitPeriod)
      )
  }

  // invoked on reset of waiting period
  const resetWaitingPeriod = () => {
    setSumInsuredError(initialErrorState)
    resetWaitingBenefitPeriodHelperUtility(type, benefitInstanceNo, false)

    dispatch(actionCreators.altsCalculateQuote())
  }

  // invoked on reset of benefit period
  const resetBenefitPeriod = () => {
    setSumInsuredError(initialErrorState)
    resetWaitingBenefitPeriodHelperUtility(type, benefitInstanceNo, true)

    dispatch(actionCreators.altsCalculateQuote())
  }

  const toggleOptionsExpanded = () => {
    const gaEvents = {
      newPremium: {
        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
        action: 'New premium - Manage options',
      },
      oldPremium: {
        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
        action: optionsExpanded
          ? 'Existing premium - Hide options'
          : 'Existing premium - View options',
      },
    }
    const gaEvent = isNewPremium ? 'newPremium' : 'oldPremium'
    const event = createEvent({
      GA: gaEvents[gaEvent],
      Splunk: {
        attributes: {
          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - ${gaEvents[gaEvent].action}`,
        },
      },
    })
    event.end()
    setOptionsExpanded(prev => !prev)
  }

  const handleSumInsuredChange = ({ value }) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(value)) {
      updateSumInsuredError(value)
      dispatch(
        actionCreators.alterSumInsured(
          {
            type,
            benefitInstanceNo,
            name: benefitName,
            coverAmount,
            optimiserParentBenefitReference,
          },
          value,
          bancsPolicyNo,
          policyInstanceNo
        )
      )
    }
  }

  let sumInsuredFieldValue
  const sumInsuredBlurHandler = () => {
    if (get(cover, 'childBenefits') || get(cover, 'parentBenefit')) {
      if (validateParentChildCoverAmounts(policies, cover)) {
        batch(() => {
          dispatch(
            actionCreators.setModalDescription({
              modalType: 'altsErrorModal',
              description: 'altsParentSumInsuredCantBeLessThanChildren',
            })
          )
          dispatch(actionCreators.showModal('altsErrorModal'))
        })
        return
      }
    }
    if (!sumInsuredError.error) {
      dispatch(actionCreators.altsCalculateQuote())
    }
  }

  const {
    name = '',
    commencementDate = '',
    policyNo = '',
    parentPolicyReferenceNo = '',
    parentType,
    parentBenefitInstanceNo,
  } = parentBenefit

  const policyName =
    get(cover, 'optimiserParentBenefitReference.benefitNature', '') ===
    BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
      ? get(optimiserParentBenefit, 'name', '')
      : name
  const formattedCommencementDate = commencementDate
    ? `, ${moment(commencementDate).format('Do MMMM gggg')}`
    : ''

  const benefitLabelPrefix =
    get(cover, 'optimiserParentBenefitReference.benefitNature', '') ===
    BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
      ? 'Optimised to '
      : ''
  const benefitLabel = benefitLabelPrefix.concat(
    `${policyNo} ${policyName}`.trim().concat(formattedCommencementDate)
  )

  const linkedBenefitClickHandler = id => {
    setBenefitRowIdsList(oldArray => [...new Set([...oldArray, id])])
  }

  const changeWaitingBenefitPeriodHelper = (
    bType,
    bInstanceNo,
    bName,
    bPolicyNo,
    value,
    isBPeriod
  ) =>
    dispatch(
      actionCreators.changeWaitingBenefitPeriod(
        {
          type: bType,
          benefitInstanceNo: bInstanceNo,
          name: bName,
        },
        bPolicyNo,
        value,
        isBPeriod
      )
    )

  const onChangeBenefitPeriod = ({ value }) => {
    if (value.value === periods.coverPeriod) {
      resetBenefitPeriod()
    } else if (value.value !== coverPeriod.value) {
      changeWaitingBenefitPeriodHelper(
        type,
        benefitInstanceNo,
        benefitName,
        bancsPolicyNo,
        value,
        true
      )
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(
          ({ type: cbType, benefitInstanceNo: cbInstanceNo, name: cbInstanceName }) =>
            changeWaitingBenefitPeriodHelper(
              cbType,
              cbInstanceNo,
              cbInstanceName,
              bancsPolicyNo,
              value,
              true
            )
        )

      dispatch(actionCreators.altsCalculateQuote())
      const event = createEvent({
        GA: {
          category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
          action: 'New premium tab - edit benefit period',
        },
        Splunk: {
          attributes: {
            'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - New premium tab - edit benefit period`,
          },
        },
      })
      event.end()
    }
  }

  const onChangeWaitingPeriod = ({ value }) => {
    if (value.value === periods.waitingPeriod && value.unit === periods.waitingPeriodUnit) {
      resetWaitingPeriod()
    } else if (value.value !== waitingPeriod.value) {
      changeWaitingBenefitPeriodHelper(
        type,
        benefitInstanceNo,
        benefitName,
        bancsPolicyNo,
        value,
        false
      )
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(
          ({ type: cbType, benefitInstanceNo: cbInstanceNo, name: cbInstanceName }) =>
            changeWaitingBenefitPeriodHelper(
              cbType,
              cbInstanceNo,
              cbInstanceName,
              bancsPolicyNo,
              value,
              false
            )
        )
      dispatch(actionCreators.altsCalculateQuote())

      const event = createEvent({
        GA: {
          category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
          action: 'New premium tab - edit waiting period',
        },
        Splunk: {
          attributes: {
            'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - New premium tab - edit waiting period`,
          },
        },
      })
      event.end()
    }
  }

  if (isRemoved) {
    sumInsuredFieldValue = 0
  } else if (newSumInsured !== undefined) {
    // sumInsuredFieldValue value when newSumInsured is '' or has value
    sumInsuredFieldValue = newSumInsured
  } else {
    sumInsuredFieldValue = parseInt(coverAmount, 10).toString()
  }

  let benefitPremium
  if (isNewPremium || isConfirmationPage) {
    if (isRemoved) {
      benefitPremium = 0
    } else {
      benefitPremium = newGrossBenefitPremium || premiumAmount
    }
  } else {
    benefitPremium = premiumAmount
  }

  function displayChip() {
    if (isRemoved) {
      return renderTextField(RemoveBenefit)
    }
    if (isAltered) {
      return renderTextField(AlteredLabel)
    }
    return renderTextField(Reduced)
  }

  return (
    <RowContainer>
      <DataRow
        id={dataRowId}
        isRemoved={isRemoved}
        hasChanged={editing || isAltered}
        isNewPremium={isNewPremium}
        shouldHighlight={shouldHighlight}
      >
        <StyledCardCell>
          <BenefitNameContainer>
            <BenefitName>
              {isCaliRelabel &&
              [POLICY_PRODUCT_CODE_SUPER, POLICY_PRODUCT_CODE_NON_SUPER].includes(productId) &&
              premiumStyle === PREMIUM_STYLE_STEPPED
                ? NEW_STEPPED_LABEL
                : premiumStyle}
            </BenefitName>
            <BenefitName>
              {showedBenefitName}
              {benefitCommencementDate
                ? `, ${moment(benefitCommencementDate).format('Do MMMM gggg')} `
                : ''}
            </BenefitName>
            {benefitLabel && (
              <LinkedBenefitBox
                isChild={false}
                id={`${parentPolicyReferenceNo}-${parentType}-${parentBenefitInstanceNo}`}
              >
                <IconLink16 /> <span>{benefitLabel}</span>
                <Button
                  variant="tertiary"
                  size="xsmall"
                  onClick={() => {
                    setBenefitRowIdsList([])
                    linkedBenefitClickHandler(
                      `${parentPolicyReferenceNo}-${parentType}-${parentBenefitInstanceNo}`
                    )
                    const event = createEvent({
                      GA: {
                        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                        action: isNewPremium
                          ? 'New premium tab - View linked'
                          : 'Existing premium - View linked',
                      },
                      Splunk: {
                        attributes: {
                          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - ${
                            isNewPremium
                              ? 'New premium tab - View linked'
                              : 'Existing premium - View linked'
                          }`,
                        },
                      },
                    })
                    event.end()
                  }}
                >
                  {get(cover, 'optimiserParentBenefitReference.benefitNature') ===
                  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
                    ? get(ViewOptimizedCTA, 'value', '')
                    : get(ViewLinkedButtonLabel, 'value', '')}
                </Button>
              </LinkedBenefitBox>
            )}
            {isNewPremium && (isRemoved || editing || isAltered) && (
              <Chip variant="closed">{displayChip()}</Chip>
            )}
            {loading.length || exclusion.length ? (
              <div>
                <AltsDecreaseLoadingExclusionModal
                  loading={loading}
                  exclusion={exclusion}
                  fields={fields}
                  benefitName={benefitName}
                  benefitCommencementDate={benefitCommencementDate}
                  isNewPremium={isNewPremium}
                />
              </div>
            ) : null}
          </BenefitNameContainer>
          {(editing || isRemoved) && isNewPremium && !disableSumInsured && (
            <ResetButton
              size="xsmall"
              variant="tertiary"
              onClick={resetRowHandler}
              disabled={isFetchingPremiums || isPolicyChangeDisabled}
            >
              {renderTextField(ResetRowCTA)}
            </ResetButton>
          )}
        </StyledCardCell>
        <StyledCardCell>
          {!hideSumInsured &&
            (isNewPremium ? (
              <SumInsuredInput
                type="text"
                name="sumInsured"
                changeHandler={handleSumInsuredChange}
                value={sumInsuredFieldValue}
                prefix="$"
                disabled={
                  disableSumInsured ||
                  !apDecreaseSumInsured ||
                  isFetchingPremiums ||
                  isPolicyChangeDisabled
                }
                error={sumInsuredError && sumInsuredError.error}
                caption={sumInsuredError && sumInsuredError.error ? sumInsuredError.errorMsg : ''}
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                  numeralPositiveOnly: true,
                }}
                placeholder={get(SumInsuredPlaceholder, 'value', '')}
                blurHandler={sumInsuredBlurHandler}
                errorVariant="warning"
              />
            ) : (
              <span>
                {formatCurrency(
                  parseInt(isConfirmationPage ? sumInsuredFieldValue : coverAmount, 10),
                  '$',
                  0
                )}
              </span>
            ))}
        </StyledCardCell>
        <StyledCardCell>
          <WithLoader
            isLoading={isFetchingPremiums}
            overlay
            isOverlayComponent
            loaderProps={{
              spinnerSize: 25,
            }}
          >
            {formatCurrency(benefitPremium)}
          </WithLoader>
        </StyledCardCell>
        <StyledCardCell>
          {(isConfirmationPage
            ? isIPorBEBenefit || !!filterdBenefits.length
            : isOptionsVisible) && (
            <OptionsButton
              variant="tertiary"
              size="xsmall"
              onClick={toggleOptionsExpanded}
              hasChangedOptions={hasChangedOptions}
              data-testid="option-button"
            >
              {isNewPremium
                ? renderTextField(BenefitOptions)
                : optionLabelMappingObj[optionsExpanded]}
              {optionsExpanded ? <IconChevronUp16 /> : <IconChevronDown16 />}
            </OptionsButton>
          )}
        </StyledCardCell>
        {isNewPremium && (
          <StyledCardCell isCTA>
            {!isRemoved && apRemoveBenefit && (
              <Button
                variant="danger"
                size="xsmall"
                onClick={handleRemoveBenefit}
                disabled={isFetchingPremiums || isRemoveDisabled || isPolicyChangeDisabled}
              >
                {renderTextField(RemoveCTA)}
              </Button>
            )}
            {isRemoved && (
              <Button
                variant="secondary"
                size="xsmall"
                onClick={resetRowHandler}
                disabled={
                  isFetchingPremiums || isCancelRemoveBenefitDisabled || isPolicyChangeDisabled
                }
              >
                {renderTextField(CancelCTA)}
              </Button>
            )}
          </StyledCardCell>
        )}
      </DataRow>
      {filterdBenefits.length > 0 &&
        optionsExpanded &&
        filterdBenefits.map(feature => (
          <AltsDecreaseCoverRowOption
            key={`option-${feature.featureName}`}
            feature={feature}
            isNewPremium={isNewPremium}
            fields={fields}
            isFetchingPremiums={isFetchingPremiums}
            bancsPolicyNo={bancsPolicyNo}
            lifeInsured={lifeInsured}
            benefitName={benefitName}
            benefitInstanceNo={benefitInstanceNo}
            type={type}
            optimiserParentBenefitReference={optimiserParentBenefitReference}
            alteredBenefits={alteredBenefits}
            isBenefitRemoved={isRemoved}
            policyInstanceNo={policyInstanceNo}
            isRemoveBenefitOptionFeature={apRemoveBenefitOption}
            isConfirmationPage={isConfirmationPage}
            linkedSGBOBenefits={linkedSGBOBenefits}
            isPolicyChangeDisabled={isPolicyChangeDisabled}
          />
        ))}
      {isIPorBEBenefit && optionsExpanded && (
        <>
          <OptionsRow isWaitingPeriodEdited={isWaitingPeriodEdited} isNewPremium={isNewPremium}>
            <StyledCardCell>
              <div>{renderTextField(WaitingPeriod)}</div>
            </StyledCardCell>
            <StyledCardCell>
              {isNewPremium &&
              waitingPeriodData.length > 1 &&
              !isWaitingPeriodDisabled &&
              !isCoverASGBOBenefit ? (
                <Select
                  placeholder={WaitingPeriod.value}
                  name={`options-waiting-period-${policyType}`}
                  id={`options-waiting-period-${policyType}`}
                  changeHandler={onChangeWaitingPeriod}
                  options={waitingPeriodData}
                  value={waitingPeriod.value}
                  variant="secondary"
                  disabled={
                    !apIncreaseWaitingPeriod ||
                    isFetchingPremiums ||
                    largestWaitingPeriodSelected ||
                    isPolicyChangeDisabled
                  }
                />
              ) : (
                <span>
                  {isConfirmationPage || isCoverASGBOBenefit
                    ? `${waitingPeriod.value} ${waitingPeriod.unit}`
                    : `${periods.waitingPeriod} ${periods.waitingPeriodUnit}`}
                </span>
              )}
            </StyledCardCell>
            <StyledCardCell>
              {isNewPremium && (isWaitingPeriodEdited || isRemoved) && (
                <StyledChip variant="closed">
                  {isRemoved ? renderTextField(RemoveBenefit) : renderTextField(AlteredLabel)}
                </StyledChip>
              )}
            </StyledCardCell>
            {isNewPremium && (
              <>
                {isWaitingPeriodDisabled ? (
                  <StyledCardCell isCTA>
                    <TooltipContainer>
                      <div data-tip data-for="removeBlocked">
                        <InfoIcon />
                      </div>
                      {tooltipMessage && (
                        <Tooltip id="removeBlocked" place="left">
                          {tooltipMessage}
                        </Tooltip>
                      )}
                    </TooltipContainer>
                  </StyledCardCell>
                ) : (
                  <>
                    {isWaitingPeriodEdited && (
                      <StyledCardCell isCTA>
                        <ResetButton
                          size="xsmall"
                          variant="tertiary"
                          onClick={resetWaitingPeriod}
                          disabled={
                            isFetchingPremiums || isCoverASGBOBenefit || isPolicyChangeDisabled
                          }
                          isOptions
                        >
                          {renderTextField(ResetRowCTA)}
                        </ResetButton>
                      </StyledCardCell>
                    )}
                  </>
                )}
              </>
            )}
          </OptionsRow>
          <OptionsRow isNewPremium={isNewPremium} isCoverPeriodEdited={isCoverPeriodEdited}>
            <StyledCardCell>
              <div>{renderTextField(BenefitPeriod)}</div>
            </StyledCardCell>
            <StyledCardCell>
              {isNewPremium &&
              benefitPeriodData.length > 1 &&
              !isBenefitPeriodDisabled &&
              !isCoverASGBOBenefit ? (
                <Select
                  placeholder={BenefitPeriod.value}
                  name={`options-cover-period-${policyType}`}
                  id={`options-cover-period-${policyType}`}
                  changeHandler={onChangeBenefitPeriod}
                  options={benefitPeriodData}
                  value={coverPeriod.value}
                  variant="secondary"
                  size="lg"
                  disabled={
                    !apDecreaseBenefitPeriod ||
                    isFetchingPremiums ||
                    largestBenefitPeriodSelected ||
                    isPolicyChangeDisabled
                  }
                />
              ) : (
                <span>
                  {isConfirmationPage || isCoverASGBOBenefit
                    ? `${coverPeriod.value} ${coverPeriod.unit}`
                    : `${periods.coverPeriod} ${periods.coverPeriodUnit}`}
                </span>
              )}
            </StyledCardCell>
            <StyledCardCell>
              {isNewPremium && (isCoverPeriodEdited || isRemoved) && (
                <StyledChip variant="closed">
                  {isRemoved ? renderTextField(RemoveBenefit) : renderTextField(AlteredLabel)}
                </StyledChip>
              )}
            </StyledCardCell>
            {isNewPremium && (
              <>
                {isBenefitPeriodDisabled ? (
                  <StyledCardCell isCTA>
                    <TooltipContainer>
                      <div data-tip data-for="removeBlocked">
                        <InfoIcon />
                      </div>
                      {tooltipMessage && (
                        <Tooltip id="removeBlocked" place="left">
                          {tooltipMessage}
                        </Tooltip>
                      )}
                    </TooltipContainer>
                  </StyledCardCell>
                ) : (
                  <>
                    {isCoverPeriodEdited && (
                      <StyledCardCell isCTA>
                        <ResetButton
                          size="xsmall"
                          variant="tertiary"
                          onClick={resetBenefitPeriod}
                          disabled={
                            isFetchingPremiums || isCoverASGBOBenefit || isPolicyChangeDisabled
                          }
                          isOptions
                        >
                          {renderTextField(ResetRowCTA)}
                        </ResetButton>
                      </StyledCardCell>
                    )}
                  </>
                )}
              </>
            )}
          </OptionsRow>
        </>
      )}
    </RowContainer>
  )
}

AltsDecreaseCoverRow.propTypes = {
  showedBenefitName: PropTypes.string,
  isNewPremium: PropTypes.bool,
  policyType: PropTypes.string,
  activeTabValue: PropTypes.string,
  productId: PropTypes.string,
  isConfirmationPage: PropTypes.bool,
  fields: shape({
    CancelCTA: shape({
      value: PropTypes.string,
    }),
    RemoveCTA: shape({
      value: PropTypes.string,
    }),
    Reduced: shape({
      value: PropTypes.string,
    }),
    ResetRowCTA: shape({
      value: PropTypes.string,
    }),
    RemoveBenefit: shape({
      value: PropTypes.string,
    }),
    RemoveOption: shape({
      value: PropTypes.string,
    }),
    BenefitPeriod: shape({
      value: PropTypes.string,
    }),
    WaitingPeriod: shape({
      value: PropTypes.string,
    }),
    BenefitOptions: shape({
      value: PropTypes.string,
    }),
    BenefitViewOptions: shape({
      value: PropTypes.string,
    }),
    LoadingAndExclusionsRemovedModalButton: shape({
      value: PropTypes.string,
    }),
    ViewLoadingsCTALabel: shape({
      value: PropTypes.string,
    }),
    ViewExclusionsCTALabel: shape({
      value: PropTypes.string,
    }),
    ViewLoadingsExclusionsCTALabel: shape({
      value: PropTypes.string,
    }),
    LoadingsModalHeader: shape({
      value: PropTypes.string,
    }),
    ExclusionsModalHeader: shape({
      value: PropTypes.string,
    }),
    LoadingsExclusionsModalHeader: shape({
      value: PropTypes.string,
    }),
    LoadingModalDescForRPM: shape({
      value: PropTypes.string,
    }),
    PercentageAppliedLabel: shape({
      value: PropTypes.string,
    }),
    LoadingsHeadingLabel: shape({
      value: PropTypes.string,
    }),
    ExclusionsHeadingLabel: shape({
      value: PropTypes.string,
    }),
    SumInsuredErrorMsg: shape({
      value: PropTypes.string,
    }),
    SumInsuredBlankErrorMsg: shape({
      value: PropTypes.string,
    }),
    AlteredLabel: shape({
      value: PropTypes.string,
    }),
  }),
  bancsPolicyNo: PropTypes.string.isRequired,
  alteration: shape({
    alteredBenefits: PropTypes.arrayOf(
      shape({
        benefitCode: PropTypes.string,
        benefitInstanceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        newSumInsured: PropTypes.number,
      })
    ),
  }),
  lifeInsured: PropTypes.string.isRequired,
  listOfBenefitRowIds: PropTypes.arrayOf(PropTypes.string),
  setBenefitRowIdsList: PropTypes.func,
  policyInstanceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isFetchingCalculateQuote: PropTypes.bool.isRequired,
  isPolicyChangeDisabled: PropTypes.bool.isRequired,
  cover: shape({
    isRemoveDisabled: PropTypes.bool.isRequired,
    isCancelRemoveBenefitDisabled: PropTypes.bool,
    premiumStyle: PropTypes.string,
    benefitName: PropTypes.string,
    benefitCommencementDate: PropTypes.string,
    hideSumInsured: PropTypes.bool,
    coverAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disableSumInsured: PropTypes.bool,
    premiumAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isIPorBEBenefit: PropTypes.bool,
    periods: shape({
      coverPeriod: PropTypes.string,
      waitingPeriod: PropTypes.string,
      coverPeriodUnit: PropTypes.string,
      waitingPeriodUnit: PropTypes.string,
    }),
    isOptionsVisible: PropTypes.bool,
    applicableFeatures: PropTypes.arrayOf(
      shape({
        featureApplicable: PropTypes.string,
        featureName: PropTypes.string,
        premium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    parentBenefit: shape({
      name: PropTypes.string,
      commencementDate: PropTypes.string,
      policyNo: PropTypes.string,
      parentPolicyReferenceNo: PropTypes.string,
      parentType: PropTypes.string.isRequired,
      parentBenefitInstanceNo: PropTypes.string.isRequired,
    }),
    optimiserParentBenefit: shape({
      name: PropTypes.string,
    }),
    loading: PropTypes.arrayOf(
      shape({
        endDate: PropTypes.string,
        loadingType: PropTypes.string,
        loadingValue: PropTypes.number,
        loadingValueType: PropTypes.string,
        startDate: PropTypes.string,
        reason: PropTypes.arrayOf(
          shape({
            reasonCode: PropTypes.string,
            reasonDescription: PropTypes.string,
          })
        ),
      })
    ),
    exclusion: PropTypes.arrayOf(
      shape({
        endDate: PropTypes.string,
        referenceNo: PropTypes.string,
        exclusionCode: PropTypes.string,
        startDate: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    type: PropTypes.string.isRequired,
    benefitInstanceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    optimiserParentBenefitReference: shape({
      parentPolicyReferenceNo: PropTypes.string,
      parentType: PropTypes.string,
      parentBenefitInstanceNo: PropTypes.string,
      benefitNature: 'Rider Optimiser',
    }),
    apEnabledFeatures: shape({
      apDecreaseBenefitPeriod: PropTypes.bool,
      apDecreaseSumInsured: PropTypes.bool,
      apIncreaseWaitingPeriod: PropTypes.bool,
      apRemoveBenefit: PropTypes.bool,
      apRemoveBenefitOption: PropTypes.bool,
    }),
    childBenefits: PropTypes.arrayOf(
      shape({
        childBenefitInstanceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        childBenefitType: PropTypes.string,
        childPolicyReferenceNo: PropTypes.string,
      })
    ),
  }).isRequired,
}

AltsDecreaseCoverRow.defaultProps = {
  showedBenefitName: '',
  isNewPremium: false,
  policyType: '',
  activeTabValue: '',
  productId: '',
  isConfirmationPage: false,
  fields: {
    CancelCTA: { value: 'Cancel' },
    RemoveCTA: { value: 'Remove' },
    Reduced: { value: 'Reduced' },
    ResetRowCTA: { value: 'Reset' },
    RemoveBenefit: { value: 'Removed Benefit' },
    RemoveOption: { value: 'Removed option' },
    BenefitPeriod: { value: '' },
    WaitingPeriod: { value: '' },
    BenefitOptions: { value: '' },
    BenefitViewOptions: { value: '' },
    LoadingAndExclusionsRemovedModalButton: { value: '' },
    ViewLoadingsCTALabel: { value: '' },
    ViewExclusionsCTALabel: { value: '' },
    ViewLoadingsExclusionsCTALabel: { value: '' },
    LoadingsModalHeader: { value: '' },
    ExclusionsModalHeader: { value: '' },
    LoadingsExclusionsModalHeader: { value: '' },
    LoadingModalDescForRPM: { value: '' },
    PercentageAppliedLabel: { value: '' },
    LoadingsHeadingLabel: { value: '' },
    ExclusionsHeadingLabel: { value: '' },
    SumInsuredBlankErrorMsg: { value: '' },
    SumInsuredErrorMsg: { value: '' },
    AlteredLabel: { value: '' },
  },
  alteration: null,
  listOfBenefitRowIds: [''],
  setBenefitRowIdsList: () => {},
}

export { AltsDecreaseCoverRow }
