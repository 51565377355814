// @flow
import React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { Disclaimer as DisclaimerAtom } from '@mlcl-digital/mlcl-design'
import { renderTextField, reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'

// styles
import styles from './disclaimer.styles'

const DisclaimerContainer = styled(DisclaimerAtom)(styles.disclaimerContainer)
const DisclaimerMessage = styled('div')(styles.disclaimerMessage)

type DisclaimerProps = {
  fields: Object,
  expiryDuration: Number,
  createdOn: String,
}

const Disclaimer = ({ fields, expiryDuration, createdOn }: DisclaimerProps) => {
  const { preAssessmentExpiryLabel, preAssessmentDisclaimerLabel } = fields
  const { preAssessmentDisclaimerDateFormat } = reduceAuthorableFields(fields)
  const disclaimerDate = moment(createdOn)
    .add(expiryDuration, 'days')
    .format(preAssessmentDisclaimerDateFormat)
  return (
    <DisclaimerContainer>
      <div>
        {renderTextField(preAssessmentExpiryLabel)}
        {` ${disclaimerDate}.`}
      </div>
      <DisclaimerMessage>{renderTextField(preAssessmentDisclaimerLabel, true)}</DisclaimerMessage>
    </DisclaimerContainer>
  )
}

export default Disclaimer
