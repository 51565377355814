// @flow
import React from 'react'
// Organism
import InflationProofingQuoteOrganism from '../../../organisms/Alteration/InflationProofingQuote'

type InflationProofingQuoteProps = {
  fields: Object,
}

const InflationProofingQuote = ({ fields }: InflationProofingQuoteProps) => (
  <InflationProofingQuoteOrganism fields={fields} />
)

export default InflationProofingQuote
