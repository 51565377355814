import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { space, mediaQueries } = Variables as VariablesType

const styles = {
  container: {
    paddingTop: space(5),
    paddingBottom: space(9),
    [mediaQueries.md]: {
      paddingBottom: space(18),
    },
  },
  headingWrap: {
    marginBottom: space(5),
    h2: {
      marginBottom: space(1),
    },
  },
  linkContainer: {
    marginTop: space(5),
    '> a': {
      marginRight: space(5),
      lineHeight: '20px',
    },
  },
  policiesContainer: {
    maxWidth: '720px',
  },
}

export default styles
