import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import FilterBar from '@mlcl-digital/mlcl-design/lib/base/FilterBar'
import Table from '@mlcl-digital/mlcl-design/lib/base/Table'
import FontAwesomeIcon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { Loader, ErrorState } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import moment from 'moment'

// Redux
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'

// Utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { downloadDocument } from '../../../../../utils/downloadDocumentUtils'
// @ts-expect-error file not in typescript
import { getPolicyOwner } from '../../../../../utils/policyUtils'

import styles from './coverManager.styles'

// selectors
import { getClientCoverManagerDocuments } from '../../../../../selectors/coverManagerDocuments'
import { getAlterations } from '../../../../../selectors/common.selectors'
import { getCoverManagerValidationMessageForAdviser } from '../../../../../selectors/premiumCalculator'

// types
import { Store } from '../../../../../types/store'
import { ClientPolicy, Relationship } from '../../../../../types/ClientPolicies'
import { SitecoreField } from '../../../../../types/sitecore'

// constants
import { POLICY_IDENTIFIER_POLICY_ID } from '../../../../../constants/policies'

const FilterBarContainer = styled('div')(styles.filterContainer)
const DownloadLink = styled('a')(styles.anchor)
const StateContainer = styled('div')(styles.stateContainer)
const Spinner = styled(Loader)(styles.spinner)
const ErrorMessage = styled('div')(styles.errorMessage)

const channel = 'Outbound'

type Fields = {
  SearchCoverManager: SitecoreField
  NoQuotesDownloadedSubmitted: SitecoreField
  QuoteNumber: SitecoreField
  CreatedDate: SitecoreField
  CoverManagerDownload: SitecoreField
}
type props = {
  policyData: ClientPolicy
  fields: Fields
}

const CoverManager = ({ policyData, fields }: props) => {
  const {
    policy: { identifiers },
  } = policyData

  const dispatch = useDispatch()

  const coverManagerDocuments = useSelector(getClientCoverManagerDocuments)
  const config = useSelector((state: Store) => state.config)
  const alterations = useSelector(getAlterations)
  const validationMessage = useSelector(getCoverManagerValidationMessageForAdviser)

  const { records } = coverManagerDocuments

  const [search, setSearch] = useState('')
  const [dateRange, setDateRange] = useState<{ to: Date | null; from: Date | null }>({
    to: null,
    from: null,
  })
  const [loadingRowState, setLoadingRowState] = useState<Array<number>>([])
  const [recordsToShow, setShowingRecords] = useState<
    Array<{
      date: string
      quoteNumber: string
      externalId: string
      isDownloading: boolean
    }>
  >([])

  const mapRecordsToState = useMemo(
    () =>
      recordsToShow.map((record, index) => ({
        ...record,
        downloadLink: (
          <DownloadLink
            onClick={() => {
              record.isDownloading = true
              setLoadingRowState([...loadingRowState, index])
              downloadDocument({}, 'GET', `/documents/${record.externalId}`, config, () => {
                record.isDownloading = false
                setLoadingRowState(
                  loadingRowState.filter(i => i !== index && record.isDownloading === true)
                )
              })
            }}
          >
            {loadingRowState.includes(index) ? (
              <Spinner
                type="noHeight"
                loaderContent="Downloading"
                borderSize={2}
                spinnerSize={16}
                textPosition="horizontal"
              />
            ) : (
              fields.CoverManagerDownload.value
            )}
          </DownloadLink>
        ),
      })),
    [recordsToShow, loadingRowState]
  )

  const columns = ({ CreatedDate = { value: '' }, QuoteNumber = { value: '' } }: Fields) => [
    {
      Header: CreatedDate.value,
      accessor: 'date',
    },
    {
      Header: QuoteNumber.value,
      accessor: 'quoteNumber',
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'downloadLink',
      disableSortBy: true,
    },
  ]

  const COLUMNS = columns(fields)

  useEffect(() => {
    let filteredRecords = records
    if (search) {
      filteredRecords = filteredRecords.filter(record =>
        record.quoteNumber.toLowerCase().includes(search.toLowerCase())
      )
    }
    if (dateRange.to || dateRange.from) {
      const dateString = 'DD MMM YYYY'
      if (dateRange.to && dateRange.from) {
        filteredRecords = filteredRecords.filter(
          item =>
            moment(item.date, dateString).isBetween(dateRange.from, dateRange.to) ||
            moment(item.date, dateString).isSame(dateRange.from) ||
            moment(item.date, dateString).isSame(dateRange.to)
        )
      } else if (dateRange.from) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.date, dateString).isSameOrAfter(dateRange.from)
        )
      } else if (dateRange.to) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.date, dateString).isSameOrBefore(dateRange.to)
        )
      }
    }
    setShowingRecords(filteredRecords)
  }, [search, dateRange])

  useEffect(() => {
    if (!alterations?.rules) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.getAlterationRulesForPolicies())
    }
  }, [])

  useEffect(() => {
    const policyNumber = get(
      identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID),
      'value'
    )
    if (!(records && records.length > 0) && alterations?.rules && !validationMessage) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.getCoverManagerDocuments({
          partyNumber: (
            getPolicyOwner(
              policyData.policy.relationships || [],
              false,
              false,
              true
            ) as Relationship
          )?.relatedParty?.identifiers.find(identifier => identifier.type === 'PARTY_NO')?.value,
          policyNumber,
          channel,
        })
      )
    }
  }, [identifiers, alterations?.rules, validationMessage])

  useEffect(() => {
    setShowingRecords(records)
  }, [records])

  return (
    <>
      {(coverManagerDocuments.isSending || alterations.isFetchingRules) && <Loader />}
      {records.length === 0 &&
        !validationMessage &&
        !coverManagerDocuments.isSending &&
        !alterations.isFetchingRules && (
          <StateContainer>
            <ErrorState
              emptyState
              showBackground={false}
              message={renderTextField(fields.NoQuotesDownloadedSubmitted)}
            />
          </StateContainer>
        )}

      {validationMessage && (
        <ErrorMessage>
          <div>
            <FontAwesomeIcon iconName={['far', 'triangle-exclamation']} />
            <span>{validationMessage}</span>
          </div>
        </ErrorMessage>
      )}

      {records.length > 0 && (
        <>
          <FilterBarContainer>
            <FilterBar
              searchPlaceholder={get(fields, 'SearchCoverManager.value', '') as string}
              onSearch={e => setSearch(e.value)}
              onDateRange={e => setDateRange(e)}
              showClearAll
            />
          </FilterBarContainer>
          <Table
            pageSize={20}
            data={mapRecordsToState}
            columns={COLUMNS}
            styleOverrides={{
              cell: styles.cell,
              footer: styles.tableFooter,
              headerCell: styles.cell,
            }}
          />
        </>
      )}
    </>
  )
}

export default CoverManager
