import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { space, mediaQueries } = Variables as VariablesType

const styles = {
  componentWrapper: {
    paddingTop: space(3),
    paddingBottom: space(18),
    [mediaQueries.lg]: {
      paddingTop: space(7),
      paddingBottom: space(15),
    },
  },
  backToHomeCTA: {
    marginBottom: space(6),
  },
  heading: {
    marginBottom: space(2),
  },
}

export default styles
