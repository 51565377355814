import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { colours, space, fontWeights, fontFamily } = Variables as VariablesType

export default {
  pageContainer: {
    margin: '0 auto',
    maxWidth: space(150, true),
    padding: `${space(5, true)} ${space(3, true)}`,
  },
  inflationProofingDetails: {
    maxWidth: '51%',
    marginBottom: space(6, true),
  },
  description: {
    color: colours.tertiaryOne,
    marginBottom: space(3),
  },
  tooltipContainer: {
    display: 'inline-block',
    marginLeft: space(0.5),
    '> div': {
      maxWidth: space(26, true),
      fontWeight: fontWeights.light,
      fontFamily: fontFamily.sourceSans,
    },
  },
  styleOverrides: {
    headerCell: {
      '&:last-of-type': {
        minWidth: '25%',
      },
    },
    cell: {
      '&:last-of-type': {
        minWidth: '25%',
        div: {
          flexGrow: '0.75',
        },
      },
    },
  },
}
