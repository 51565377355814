// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import moment from 'moment'
import { Hero, Loader, PageWrap } from '@mlcl-digital/mlcl-design'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// atoms.
import Heading from '../../../../atoms/Heading'

// utils.
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import history from '../../../../../utils/browserHistory'
import { getRole } from '../../../../../utils/middlewareUtils'

// components
import Tag from '../Tag'
import RelatedArticle from '../RelatedArticle'

// styles.
import styles from './articleDetailPage.styles'
import {
  ARTICLE_LISTING_PAGE_URL,
  ADVISER_ARTICLE_LISTING_PAGE_URL,
} from '../../../../../constants/knowledgeBase'
import { ADVISOR_PORTAL } from '../../../../../constants/site'

type ArticleDetailPageProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // An object containing action creator functions.
  actions: {
    getAllArticles: Function,
    resetFooterType: Function,
    setActiveCategoryType: Function,
    setActiveArticleId: Function,
    setActiveArticleTag: Function,
    setFetchArticlesLoading: Function,
  },
  knowledgeBase: Array<{
    // articles array
    articles: Array,
    // array of the categories
    categories: Array,
    // total pages for article
    totalPage: Number,
    // page number for article
    page: Number,
    config: Object,
  }>,
  // article id from store
  activeArticleId: Number,
  // article catefory from store
  activeCategoryType: Number,
  isAdviserPortal: Boolean,
}

type ArticleDetailPageState = {
  // article Id for the viewing the detail
  articleId: string,
  // artilce category type
  categoryType: string,
}

const Container = styled('section')(styles.container)
const ArticleContainer = styled('div')(styles.wrap, styles.offset, styles.tableRow)
const ArticleDetailWrapper = styled('section')(styles.articleDetailWrapper)
const ArticleHeading = styled(Heading)(styles.articleHeading)
const ArticleDate = styled('div')(styles.articleDate)
const ArticleImageWrap = styled('div')(styles.articleImageWrap)
const ArticleImage = styled('img')(styles.articleImage)
const ArticleDescriptionWrap = styled('div')(styles.articleDescriptionWrap)
const ArticleDescription = styled('div')(styles.articleDescription)
const ArticleVideoContainer = styled('div')(styles.articleVideoContainer)
const ArticleVideo = styled('div')(styles.articleVideo)

export class ArticleDetailPage extends Component<ArticleDetailPageProps, ArticleDetailPageState> {
  constructor(props) {
    super(props)
    this.state = {
      articleId: props.activeArticleId,
      categoryType: props.activeCategoryType,
    }
  }

  componentDidMount() {
    const tagEvent = createEvent({
      GA: {
        category: 'Accesses articles',
        action: 'Selects articles',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Accesses articles - Selects articles',
        },
      },
    })
    tagEvent.end()
    this.handleGetArticle()

    // Add event listener to a parent element
    if (this.parentElement) {
      this.parentElement.addEventListener('click', this.handleClick)
    }
  }

  componentWillUnmount() {
    // Remove event listener when component unmounts
    if (this.parentElement) {
      this.parentElement.removeEventListener('click', this.handleClick)
    }
  }

  handleClick = event => {
    // Check if the clicked element has a specific ID
    if (event.target.id === 'moneySmart') {
      // Prevent the default behavior of the link
      event.preventDefault()

      // Execute your custom GA code
      const tagEvent = createEvent({
        GA: { category: 'CP-Getyourestimate', action: 'Money Smart - Get your estimate' },
        Splunk: {
          attributes: {
            'workflow.name': 'Customer clicks get your estimate',
          },
        },
      })
      tagEvent.end()

      // Navigate to the external site after executing your code
      window.open(event.target.href, '_blank')
    }
  }

  handleGetArticle = () => {
    const {
      actions: {
        getAllArticles,
        setActiveArticleId,
        setActiveCategoryType,
        setFetchArticlesLoading,
      },
      fields: { linkedArticle },
      activeCategoryType,
    } = this.props
    const { articleId: localArticleId, categoryType } = this.state
    const articleId = get(linkedArticle, 'id', '')
    setFetchArticlesLoading()
    const articleListingParams = {
      portal: getRole(),
      categoryType: categoryType || activeCategoryType,
      pageIndex: '',
      noOfRecords: '',
      articleId: localArticleId || articleId.replace(/-/g, ''),
      searchText: '',
      isDetailPage: true,
    }
    setActiveArticleId(articleId)
    setActiveCategoryType(categoryType)
    getAllArticles(articleListingParams)
  }

  validateRendering = (name: string, children: Object) => {
    if (typeof name === 'string' && name !== '') {
      return children
    }
    return null
  }

  renderArticleDetailSection = () => {
    const { knowledgeBase } = this.props
    const article = get(knowledgeBase, 'articles[0]', [])
    const { articleHeader, description, video, image } = article
    return (
      <ArticleDetailWrapper>
        {articleHeader && <ArticleHeading size="2">{articleHeader}</ArticleHeading>}
        {this.validateRendering(
          image,
          <ArticleImageWrap>
            <ArticleImage src={image} alt="image" />
          </ArticleImageWrap>
        )}
        {this.validateRendering(
          video,
          <ArticleVideoContainer>
            <ArticleVideo
              className="rich-text"
              dangerouslySetInnerHTML={{
                __html: video,
              }}
            />
          </ArticleVideoContainer>
        )}
        {this.validateRendering(
          description,
          <ArticleDescriptionWrap className="rich-text">
            <ArticleDescription dangerouslySetInnerHTML={{ __html: description }} />
          </ArticleDescriptionWrap>
        )}
      </ArticleDetailWrapper>
    )
  }

  handleSetActiveArticle = (articleId: string, categoryType: string) => {
    const {
      actions: { setActiveArticleId, setActiveCategoryType },
    } = this.props
    setActiveArticleId(articleId)
    setActiveCategoryType(categoryType)
    this.setState({ articleId, categoryType }, () => this.handleGetArticle())
  }

  handleSetActiveArticleTag = articleTag => {
    const {
      actions: { setActiveArticleTag, setActiveCategoryType },
    } = this.props
    const { categoryType } = this.state
    setActiveCategoryType(categoryType)
    setActiveArticleTag(articleTag)
  }

  renderArticleDetailPage() {
    const {
      fields,
      knowledgeBase,
      knowledgeBase: { isFetchingArticles },
      isAdviserPortal,
    } = this.props
    const {
      articleDetailPageTagLabel,
      articleDetailPageLinkText,
      articleDetailPageRelatedArticle,
      backToKnowledgeBaseText,
    } = reduceAuthorableFields(fields)
    const relatedArticles = get(knowledgeBase, 'articles[0].relatedArticles', [])
    const tags = get(knowledgeBase, 'articles[0].tags', [])
    const heading = get(knowledgeBase, 'articles[0].title', [])
    const content = get(knowledgeBase, 'articles[0].shortDescription', [])
    const date = get(knowledgeBase, 'articles[0].updatedOn', [])
    const formattedTitle = {
      heading,
      content,
    }
    const navBar = {
      label: backToKnowledgeBaseText,
      onClick: () =>
        history.push(
          `/${isAdviserPortal ? ADVISER_ARTICLE_LISTING_PAGE_URL : ARTICLE_LISTING_PAGE_URL}`
        ),
      isVisible: true,
    }
    return (
      <div
        ref={element => {
          this.parentElement = element
        }}
      >
        <Hero fields={formattedTitle} navBar={navBar} />
        {isFetchingArticles ? (
          <Loader type="tab" />
        ) : (
          <Container>
            <PageWrap>
              <ArticleContainer>
                <ArticleDate> {moment(date).format('DD  MMMM  YYYY')} </ArticleDate>
                {this.renderArticleDetailSection()}
                <Tag
                  label={articleDetailPageTagLabel}
                  tags={tags}
                  handleSetActiveArticleTag={this.handleSetActiveArticleTag}
                />
              </ArticleContainer>
            </PageWrap>
            <RelatedArticle
              articles={relatedArticles}
              articleLinkText={articleDetailPageLinkText}
              title={articleDetailPageRelatedArticle}
              handleSetActiveArticle={this.handleSetActiveArticle}
            />
          </Container>
        )}
      </div>
    )
  }

  render() {
    return this.renderArticleDetailPage()
  }
}

const mapStateToProps = ({
  knowledgeBase: {
    knowledgeBase,
    knowledgeBase: { activeArticleId, activeCategoryType },
  },
  config,
}) => ({
  knowledgeBase,
  activeArticleId,
  activeCategoryType,
  isAdviserPortal: config && config.SITE === ADVISOR_PORTAL,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetailPage)
