// @flow
import { OKTA_API, OKTA_AUTHENTICATION } from '../types/okta'
import {
  OKTA_FORGOT_PASSWORD,
  OKTA_RESEND_CODE,
  OKTA_VERIFY_CODE,
  OKTA_VERIFY_EMAIL_CODE,
  OKTA_RESET_PASSWORD,
  OKTA_GET_USER_PROFILE,
  OKTA_RESET_ERROR_CODE,
  OKTA_UPDATE_USER_PROFILE,
  OKTA_TRIGGER_QUEUED_CALLBACK,
  OKTA_RESET_USER_PROFILE_ACCOUNT_STATUS,
  OKTA_SET_USER_ID,
  OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR,
  OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR,
  OKTA_RESET_AUTHENTICATION_STATE,
  SHOW_HIDE_IDEAL_TIMEOUT,
  OKTA_FORGOT_USERNAME,
  OKTA_FORGOT_USERNAME_FAILURE,
  OKTA_FORGOT_USERNAME_SET_RECAPTCHA,
  OKTA_RESET_IS_ERROR,
} from '../types/authentication'

import { authorizeUser } from './okta'

type forgotPasswordDataType = {
  username: string,
  factorType: string,
}
// Action to trigger API to send OTP
export const forgotPassword = (data: forgotPasswordDataType) => (dispatch, getState) => {
  const { config } = getState()
  dispatch({
    type: OKTA_API,
    name: OKTA_FORGOT_PASSWORD,
    verb: 'POST',
    data,
    route: `${config.MLCL_OKTA_PROXY_API}/api/v1/authn/recovery/password`,
  })
}

export const resendCode = (data: Object) => (dispatch, getState) => {
  const { config } = getState()
  dispatch({
    type: OKTA_API,
    name: OKTA_RESEND_CODE,
    verb: 'POST',
    data,
    route: `${config.MLCL_OKTA_PROXY_API}/api/v1/authn/recovery/factors/sms/resend`,
  })
}

export const triggerQueudCallback = (prevAction, callback) => dispatch => {
  dispatch({ type: OKTA_TRIGGER_QUEUED_CALLBACK })
  callback()
}

export const verifyCode = (passCode, callback) => (dispatch: Function, getState: Function) => {
  const {
    authentication: { stateToken },
    config,
  } = getState()

  dispatch({
    type: OKTA_API,
    name: OKTA_VERIFY_CODE,
    verb: 'POST',
    data: {
      stateToken,
      passCode,
    },
    route: `${config.MLCL_OKTA_PROXY_API}/api/v1/authn/recovery/factors/sms/verify`,
    queuedAction: prevAction => triggerQueudCallback(prevAction, callback),
  })
}

export const verifyEmailCode =
  (passCode: string, callback: Function) => (dispatch: Function, getState: Function) => {
    const { config } = getState()

    dispatch({
      type: OKTA_API,
      name: OKTA_VERIFY_EMAIL_CODE,
      verb: 'POST',
      data: {
        recoveryToken: passCode,
      },
      route: `${config.MLCL_OKTA_PROXY_API}/api/v1/authn/recovery/token`,
      queuedAction: prevAction => triggerQueudCallback(prevAction, callback),
    })
  }

export const updateUserProfile = (dataToUpdate, userId, callback) => (dispatch, getState) => {
  const { config } = getState()
  if (userId)
    dispatch({
      type: OKTA_API,
      name: OKTA_UPDATE_USER_PROFILE,
      verb: 'POST',
      data: {
        profile: {
          ...dataToUpdate,
        },
      },
      route: `${config.MLCL_OKTA_PROXY_API}/api/v1/users/${userId}`,
      callback,
    })
}

// update profile with queued action authorization
export const updateUserProfileWithActionQueued = (dataToUpdate, userId) => (dispatch, getState) => {
  const { config } = getState()
  if (userId)
    dispatch({
      type: OKTA_API,
      name: OKTA_UPDATE_USER_PROFILE,
      verb: 'POST',
      data: {
        profile: {
          ...dataToUpdate,
        },
      },
      route: `${config.MLCL_OKTA_PROXY_API}/api/v1/users/${userId}`,
      queuedAction: authorizeUser,
    })
}

export const sendOktaPrimaryAuthSMSFactor = token => (dispatch, getState) => {
  const {
    authentication: { smsFactorId },
    config,
  } = getState()

  if (smsFactorId && token) {
    dispatch({
      type: OKTA_API,
      name: OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR,
      verb: 'POST',
      data: { stateToken: token },
      route: `${config.MLCL_OKTA_API}/api/v1/authn/factors/${smsFactorId}/verify`,
    })
  }
}

export const queuedPasswordReset = callback => (dispatch, getState) => {
  const {
    authentication: { userId, hasCompletedWelcomeJourney, isMFARequired },
  } = getState()

  if (isMFARequired) {
    callback()
  } else if (!hasCompletedWelcomeJourney) {
    dispatch(updateUserProfileWithActionQueued({ requiresPasswordReset: false }, userId))
  } else {
    dispatch(updateUserProfileWithActionQueued({ requiresPasswordReset: false }, userId))
  }
  // Have kept 'else if' condition so as to avoid
  // any impact on the existing process that follows in 'else' condition
}

export const resetPassword =
  ({ newPassword }: { newPassword: string }, callback) =>
  (dispatch: Function, getState: Function) => {
    const {
      authentication: { stateToken },
      config,
    } = getState()

    dispatch({
      type: OKTA_API,
      name: OKTA_RESET_PASSWORD,
      verb: 'POST',
      data: {
        stateToken,
        newPassword,
      },
      route: `${config.MLCL_OKTA_PROXY_API}/api/v1/authn/credentials/reset_password`,
      queuedAction: () => queuedPasswordReset(callback),
    })
  }

export const getUserProfile =
  (username: String, callback) => (dispatch: Function, getState: Function) => {
    const { config } = getState()
    dispatch({
      type: OKTA_API,
      name: OKTA_GET_USER_PROFILE,
      verb: 'GET',
      route: `${config.MLCL_OKTA_PROXY_API}/api/v1/users/${username}`,
      queuedAction: prevAction => triggerQueudCallback(prevAction, callback),
    })
  }

export const setOktaUserId = username => ({
  type: OKTA_SET_USER_ID,
  payload: { userId: username },
})

export const oktaResetErrorCode = () => dispatch => dispatch({ type: OKTA_RESET_ERROR_CODE })

export const oktaResetIsError = () => dispatch => dispatch({ type: OKTA_RESET_IS_ERROR })

export const oktaResetUserAccountStatus = () => dispatch =>
  dispatch({ type: OKTA_RESET_USER_PROFILE_ACCOUNT_STATUS })

export const resendOktaPrimaryAuthSMSFactor = () => (dispatch, getState) => {
  const {
    authentication: { stateToken, isMFARequired, smsFactorId },
    config,
  } = getState()

  if (isMFARequired && smsFactorId) {
    dispatch({
      type: OKTA_API,
      name: OKTA_RESEND_CODE,
      verb: 'POST',
      data: { stateToken },
      route: `${config.MLCL_OKTA_API}/api/v1/authn/factors/${smsFactorId}/verify/resend`,
    })
  }
}

export const handleMFA = (prevAction, callback) => (dispatch, getState) => {
  const {
    authentication: { isMFARequired, smsFactorId },
  } = getState()

  if (isMFARequired && smsFactorId) {
    callback()
  } else {
    // authorize user
    dispatch(authorizeUser())
  }
}

export const verifyOktaPrimaryAuthSMSFactor = smsChallengeAnswer => (dispatch, getState) => {
  const {
    authentication: { stateToken, smsFactorId },
    config,
  } = getState()

  dispatch({
    type: OKTA_API,
    name: OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR,
    verb: 'POST',
    data: { passCode: smsChallengeAnswer, stateToken },
    route: `${config.MLCL_OKTA_API}/api/v1/authn/factors/${smsFactorId}/verify`,
    queuedAction: authorizeUser,
  })
}

export const oktaSignIn =
  ({ username, password }, callback) =>
  async (dispatch, getState) => {
    const { config } = getState()
    dispatch({
      type: OKTA_API,
      name: OKTA_AUTHENTICATION,
      verb: 'POST',
      data: { username: username.trim(), password },
      route: `${config.MLCL_OKTA_PROXY_API}/api/v1/authn`,
      queuedAction: prevAction => handleMFA(prevAction, callback),
    })
  }

export const handleForgotUserNameResponse = (prevAction, callback) => (dispatch, getState) => {
  const {
    authentication: { hasSentForgottonUsername },
  } = getState()
  callback(hasSentForgottonUsername)
  if (!hasSentForgottonUsername) {
    dispatch({
      type: OKTA_FORGOT_USERNAME_FAILURE,
    })
  }
}

export const oktaForgotUsername =
  (retrievalToken: String, callback: Function) => async (dispatch, getState) => {
    const {
      config,
      authentication: { recaptchaToken },
    } = getState()
    await dispatch({
      type: OKTA_API,
      name: OKTA_FORGOT_USERNAME,
      verb: 'POST',
      data: {
        businessData: {
          type: retrievalToken.includes('@') ? 'email' : 'mobile',
          value: retrievalToken,
          recaptcha: recaptchaToken,
        },
      },
      route: `${config.MLCL_OKTA_PROXY_API}/api/v1/forgot/username`,
      queuedAction: prevAction => handleForgotUserNameResponse(prevAction, callback),
      onError: () => callback(false),
      callback: isFailure => callback(!isFailure),
    })
  }

export const resetAuthenticationState = () => dispatch =>
  dispatch({
    type: OKTA_RESET_AUTHENTICATION_STATE,
  })

export const toggleIdealTimeout = route => dispatch =>
  dispatch({ type: SHOW_HIDE_IDEAL_TIMEOUT, payload: { route } })

export const setForgotUserNameReCaptchaToken = (recaptchaToken: String) => ({
  type: OKTA_FORGOT_USERNAME_SET_RECAPTCHA,
  payload: {
    recaptchaToken,
  },
})
