import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, fontWeights, fontSize, mediaQueries } = Variables

const styles = {
  pageWrapper: {
    background: colours.tertiarySix,
  },
  headingWrap: {
    marginBottom: space(6),
    padding: 0,
    [mediaQueries.lg]: {
      padding: 0,
    },
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: space(9),
    [mediaQueries.md]: {
      flexDirection: 'row',
    },
    '> div': {
      background: colours.tertiaryFive,
    },
  },
  applicationDetails: {
    marginBottom: space(3),
    [mediaQueries.md]: {
      width: '74.5%',
      marginBottom: 0,
    },
  },
  personalStatement: {
    [mediaQueries.md]: {
      width: '23.5%',
    },
  },
  applicationTypeHeader: {
    display: 'inline-block',
    padding: `${space(2, true)} ${space(3, true)}`,
    width: '100%',
    borderBottom: `1px solid ${colours.tertiaryThree}`,
    [mediaQueries.md]: {
      borderBottom: 0,
      padding: `${space(2, true)} ${space(4, true)}`,
    },
  },
  personalStatementHeader: {
    padding: `${space(5, true)} ${space(3, true)} ${space(2, true)}`,
    borderBottom: `1px solid ${colours.tertiaryThree}`,
  },
  label: {
    color: colours.tertiaryOne,
    fontWeight: fontWeights.semiBold,
    paddingRight: space(2),
    marginBottom: space(2),
  },
  value: {
    color: colours.tertiaryOne,
    marginBottom: space(2),
  },
  policyValue: {
    marginBottom: 0,
  },
  sectionHeading: {
    color: colours.tertiaryOne,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.sm,
    margin: 0,
  },
  contentSection: {
    padding: `${space(5, true)} ${space(3, true)} ${space(3, true)}`,
    display: 'flex',
    flexDirection: 'column',
    [mediaQueries.md]: {
      flexDirection: 'row',
      padding: `${space(6, true)} ${space(4, true)}`,
    },
  },
  psContentSection: {
    textAlign: 'center',
  },
  list: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '10%',
    [mediaQueries.md]: {
      width: '40%',
    },
    '> *': {
      width: '50%',
    },
  },
  whatHappensNext: {
    marginBottom: space(7),
  },
  linkContainer: {
    paddingBottom: space(9),
    [mediaQueries.md]: {
      paddingBottom: space(18),
    },
    '> a': {
      width: '100%',
      [mediaQueries.md]: {
        width: 'auto',
      },
    },
  },
  applicationType: {
    marginBottom: 0,
    color: colours.tertiaryOne,
  },
  tableHeader: {
    borderBottom: `1px solid ${colours.tertiaryThree}`,
  },
  policiesEffectiveDateList: {
    listStyle: 'none',
    width: '100%',
    marginRight: '10%',
    color: colours.tertiaryOne,
    [mediaQueries.md]: {
      width: '40%',
    },
    li: {
      '&:nth-child(1)': {
        fontWeight: fontWeights.semiBold,
      },
      display: 'flex',
      '> div': {
        width: '50%',
      },
      'div:nth-child(1)': {
        fontWeight: fontWeights.semiBold,
        paddingRight: space(2),
      },
    },
  },
}

export default styles
