import get from 'lodash/get'
import {
  STATES,
  PREFERRED_METHOD_OF_COMMS,
  PREFERRED_METHOD_OF_COMMS_FIELDS,
} from '../../../../constants/forms'
import {
  required,
  optional,
  email,
  phone,
  phoneInputs,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../../utils/formUtils'

export const FORM_ID = 'businessDetails'
const schema = () => (state, fields) => ({
  phones: {
    condition: [
      {
        condition: required,
        errorMsg: fields && fields.cpContactDetailsFormPhonesRequiredError.value,
      },
      {
        condition: phone,
        errorMsg: fields && fields.cpContactDetailsFormPhonesError.value,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields && fields.cpContactDetailsFormPhonesRequiredError.value,
  },
  phoneCode: {
    condition: optional,
  },
  address: {
    condition: state.isManualResidential ? optional : required,
  },
  [PREFERRED_METHOD_OF_COMMS]: {
    options: [
      {
        ...PREFERRED_METHOD_OF_COMMS_FIELDS.digital,
        tooltip: get(fields, 'digitalTooltip.value', ''),
      },
      {
        ...PREFERRED_METHOD_OF_COMMS_FIELDS.paper,
        tooltip: get(fields, 'paperTooltip.value', ''),
      },
    ],
    condition: [
      {
        condition: required,
        errorMsg: fields && fields.customerPersonalDetailsPreferredMethodOfCommsError,
      },
    ],
  },
  addressStreet: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.cpContactDetailsFormResidentialAddressStreetFieldError.value,
          }
        : optional,
      state.isManualResidential
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.cpContactDetailsFormResidentialAddressInvalidStreetFieldError.value,
          }
        : {},
    ],
  },
  addressHouseNo: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.cpContactDetailsFormResidentialAddressHouseNoFieldError.value,
          }
        : optional,
      state.isManualResidential
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.cpContactDetailsFormResidentialAddressInvalidHouseNoFieldError.value,
          }
        : {},
    ],
  },
  addressLocality: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.cpContactDetailsFormResidentialAddressLocalityFieldError.value,
          }
        : optional,
      state.isManualResidential
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.cpContactDetailsFormResidentialAddressInvalidLocalityFieldError.value,
          }
        : {},
    ],
  },
  addressState: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.cpContactDetailsFormResidentialAddressStateFieldError.value,
          }
        : optional,
      state.isManualResidential
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.cpContactDetailsFormResidentialAddressInvalidStateFieldError.value,
          }
        : {},
    ],
    options: STATES,
  },
  addressCountry: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.cpContactDetailsFormResidentialAddressCountryFieldError.value,
          }
        : optional,
    ],
  },
  addressPostCode: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.cpContactDetailsFormResidentialAddressPostCodeFieldError.value,
          }
        : optional,
      state.isManualResidential
        ? {
            condition: manualAddressPostalCode,
            errorMsg: fields.cpContactDetailsFormResidentialAddressInvalidPostCodeFieldError.value,
          }
        : {},
    ],
  },
  emails: {
    condition: [
      {
        condition: required,
        errorMsg: fields && fields.cpContactDetailsFormEmailsRequiredError.value,
      },
      { condition: email, errorMsg: fields && fields.cpContactDetailsFormEmailsError.value },
    ],
  },
})

export default schema
