// @flow
import React, { Component } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { PageWrap, Input } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import Icon from '@mlcl-digital/mlcl-design/lib/src/components/atoms/FontAwesomeIcon'
import { actionCreators } from '../../../actions'

// components.
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import { IconArrowLeft16 } from '../../atoms/Icons'
import InputPhone from '../../molecules/InputPhone'
import WithLoader from '../../molecules/WithLoader'

// constant
import {
  PERSONAL_STATEMENT,
  APPLICATION_STAGE_STEP,
  TOAST_ID_EMAIL,
  TOAST_ID_PHONE,
  STEP_TWO,
  APPLICATION_PATH,
} from '../../../constants/application'
import { POLICY_RELATIONSHIPS_LIFEASSURED, PREFERRED_YES } from '../../../constants/policies'
import { CONTACT_TYPES } from '../../../constants/forms'

// helpers.
import { errorCheck, getValue } from '../../../utils/formUtils'
import history from '../../../utils/browserHistory'
import { getEmail, getPhoneNumber } from '../../../utils/contactUtils'

// styles.
import styles from './customerCompletes.styles'

// schema.
import SCHEMA, { FORM_ID } from './customerCompletes.schema'

type CustomerCompletesProps = {
  // Sitecore authorable fields.
  fields: Object,
  // Redux form data of the component.
  form: Object,
  // createQuote containing quote data
  createQuote: Object,
  // An object containing action creator functions.
  actions: Object<Function>,
  // config object for all the constant
  config: Object,
  // advisor state
  advisor: Object,
  // masterdata
  masterList: Object,
}

const TopBackground = styled('div')(styles.topBackground)
const NavSection = styled('section')(styles.navSection)
const Nav = styled(NavLink)(styles.nav)
const Wrap = styled(PageWrap)(styles.wrap)
const Details = styled('div')(styles.details)
const HeaderDescription = styled(Heading)(styles.headerDescription)
const InputField = styled(Input)(styles.inputField)
const InputPhoneField = styled(InputPhone)(styles.inputField)
const FormContainer = styled('div')(styles.formContainer)
const FormContent = styled('p')(styles.formContent)
const Container = styled('div')(styles.container)
const InfoBox = styled('div')(styles.infoBox)
const InfoContentWrap = styled('div')(styles.infoContentWrap)
const InfoWrap = styled(PageWrap)(styles.infoWrap)
const InfoIcon = styled(Icon)(styles.infoIcon)
const InfoBoxHeader = styled('div')(styles.infoBoxHeader)
const InfoBoxContent = styled('div')(styles.infoBoxContent)
const InfoContainer = styled('div')(styles.infocontainer)
export class CustomerCompletes extends Component<CustomerCompletesProps> {
  constructor(props) {
    super(props)
    this.state = {
      isShowOverlayLoader: false,
    }
    this.getMemberDetails = this.getMemberDetails.bind(this)

    // setting schema
    this.schema = this.getSchema(true)
  }

  componentWillMount() {
    const {
      actions: { formInit, formValidate },
      createQuote: { activeIndex, quotes },
      masterList,
    } = this.props
    const { policyStructure } = quotes[activeIndex]
    const relatedMember = this.getMemberDetails(policyStructure[0])
    const relatedParty = get(relatedMember, 'relatedParty.contactMethods', {})
    const data = {
      emailAddress: '',
      contactNumber: '',
    }
    if (!isEmpty(relatedParty)) {
      const primaryPhoneIndex = relatedParty.phones.findIndex(phone => phone.preferred === 'Y')
      const primaryEmailIndex = relatedParty.emails.findIndex(email => email.preferred === 'Y')
      data.emailAddress = relatedParty.emails[primaryEmailIndex].email
      data.contactNumber = relatedParty.phones[primaryPhoneIndex].number
      data.contactNumberPhoneCode = masterList.countryCodeList.find(
        countryCode => countryCode.value === relatedParty.phones[primaryPhoneIndex].idc
      )
    }
    const schema = this.getSchema()
    formInit(FORM_ID, schema, data)
    formValidate(FORM_ID, schema)
  }

  // Returns stored schema. If updatedSchema is true, generates updated schema and returns it
  getSchema = (updatedSchema: boolean = false) => {
    if (!updatedSchema) {
      return this.schema
    }
    const { fields, advisor } = this.props
    const contactMethods = get(advisor, 'details.contactMethods', {})
    const advisorEmailAddress = contactMethods ? getEmail(get(contactMethods, 'emails', [])) : ''
    const advisorPhoneNumber = contactMethods
      ? getPhoneNumber(get(contactMethods, 'phones', []), CONTACT_TYPES.TEL)
      : ''
    return SCHEMA({
      fields,
      advisorEmailAddress,
      advisorPhoneNumber,
    })
  }

  getMemberDetails = policy =>
    policy &&
    policy.relationships &&
    policy.relationships.find(member =>
      member.role.find(role => role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
    )

  // handle changes on form elements.
  handleChange = ({ value, name }): void => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const schema = this.getSchema()
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg
      )
      if (charactersCheck.error) return
    }
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  // validate and update on blur for input elements.
  handleBlur = (event, name): void => {
    const { value } = event.target
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const schema = this.getSchema()
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  handleSubmit = (): void => {
    const {
      actions: {
        updateQuoteEntityMyLinkDetails,
        updateAdviserVerificationConsents,
        updateApplicationStage,
        resetUpdateQuoteSuccessFlag,
        updateDutyOfDisclosureConsents,
        saveQuote,
        updateLifeInsuredContactDetails,
      },
      form: {
        fields: { contactNumber, emailAddress, contactNumberPhoneCode },
        isValid,
      },
      createQuote: { activeIndex, quoteCollectionId },
      fields,
      advisor,
      config,
    } = this.props
    const advisorDetails = advisor.details
    const preferredEmailIndex =
      advisorDetails.contactMethods.emails &&
      advisorDetails.contactMethods.emails.findIndex(email => email.preferred === PREFERRED_YES)
    const preferredPhoneNoIndex =
      advisorDetails.contactMethods.phones &&
      advisorDetails.contactMethods.phones.findIndex(phone => phone.preferred === PREFERRED_YES)
    const adviserContact = {
      agencyName: get(advisorDetails, 'agencyName', ''),
      adviserEmail: get(advisorDetails, `contactMethods.emails[${preferredEmailIndex}].email`, ''),
      adviserTelephoneNumber: get(
        advisorDetails,
        `contactMethods.phones[${preferredPhoneNoIndex}].number`,
        ''
      ),
      adviserFirstName: get(advisorDetails, 'firstName', ''),
      adviserLastName: get(advisorDetails, 'lastName', ''),
    }
    const data = {
      email: emailAddress.value,
      phone: `+${getValue(contactNumberPhoneCode.value)}${contactNumber.value}`,
      url: `${config.MLCL_CUSTOMER_APP_PATH}/personalstatement?quoteCollectionId=${quoteCollectionId}`,
      referenceNumber: quoteCollectionId,
    }
    const updateStage = { stage2: APPLICATION_STAGE_STEP.partiallyComplete }
    const { customerCompletesPageEmailPhoneSuccessMessage } = fields
    if (!isValid) {
      if (
        (!emailAddress.value && !contactNumber.value) ||
        (emailAddress.error && contactNumber.error) ||
        (!emailAddress.value && contactNumber.error) ||
        (!contactNumber.value && emailAddress.error)
      ) {
        toast.error(emailAddress.error.errorMsg, {
          toastId: TOAST_ID_EMAIL,
        })
        toast.error(contactNumber.error.errorMsg, {
          toastId: TOAST_ID_PHONE,
        })
      }
      if (emailAddress.error || !emailAddress.value)
        toast.error(emailAddress.error.errorMsg, {
          toastId: TOAST_ID_EMAIL,
        })
      if (contactNumber.error || !contactNumber.value)
        toast.error(contactNumber.error.errorMsg, {
          toastId: TOAST_ID_PHONE,
        })
      return
    }
    toast(customerCompletesPageEmailPhoneSuccessMessage)
    updateQuoteEntityMyLinkDetails(data, adviserContact, activeIndex)
    // show overlay loader
    this.setState({ isShowOverlayLoader: true })
    // Also updating life insured contact details in relationships in quote entity
    const phone = {
      number: contactNumber.value,
      idc: getValue(contactNumberPhoneCode.value),
      countryCode: contactNumberPhoneCode.value.countryCode,
    }
    const email = {
      email: emailAddress.value,
    }
    updateLifeInsuredContactDetails({ phone, email })

    updateAdviserVerificationConsents(false, activeIndex)
    updateDutyOfDisclosureConsents({
      isDutyOfDisclosureChecked: false,
      isDutyOfDisclosurePrivacyChecked: false,
      targetedIndex: activeIndex,
    })
    updateApplicationStage(STEP_TWO, updateStage)
    resetUpdateQuoteSuccessFlag()
    saveQuote(err => {
      if (!err) {
        history.push(PERSONAL_STATEMENT)
      } else {
        // hide overlay loader if error
        this.setState({ isShowOverlayLoader: false })
      }
    })
  }

  render() {
    const { fields, form } = this.props
    if (!form) return null
    const { contactNumber, emailAddress, contactNumberPhoneCode } = form.fields
    const {
      customerCompletesPageHeading,
      customerCompletesPageContent,
      customerCompletesPageBackButtonLableText,
      customerCompletesPageFormTitle,
      customerCompletesPageFormContent,
      customerCompletesPageEmailLabel,
      customerCompletesPageEmailPlaceholder,
      customerCompletesPagePhoneLabel,
      customerCompletesPagePhonePlaceholder,
      customerCompletesPageConfirmButtonLabel,
      customerCompletesCountryCodeLabel,
      infoBoxOnlineStatementHeader,
      infoBoxOnlineStatementContent,
      infoBoxTeleUnderwritingHeader,
      infoBoxTeleUnderwritingContent,
    } = fields
    const selectPrimaryPhoneCode = {
      label: customerCompletesCountryCodeLabel,
      value: contactNumberPhoneCode.value.value,
      name: 'contactNumberPhoneCode',
      id: 'contactNumberPhoneCode',
      changeHandler: this.handleChange,
    }
    const inputPrimaryPhoneNumber = {
      htmlFor: 'contactNumber',
      name: 'contactNumber',
      label: customerCompletesPagePhoneLabel,
      changeHandler: this.handleChange,
      error: contactNumber.error.error,
      caption: contactNumber.error.error && contactNumber.error.errorMsg,
      value: contactNumber.value,
      placeholder: customerCompletesPagePhonePlaceholder,
    }
    const { isShowOverlayLoader } = this.state
    return (
      <WithLoader isLoading={isShowOverlayLoader} overlay>
        <TopBackground>
          <PageWrap>
            <NavSection>
              <Nav to={APPLICATION_PATH}>
                <IconArrowLeft16 />
                {customerCompletesPageBackButtonLableText}
              </Nav>
              <Heading size="1">{customerCompletesPageHeading}</Heading>
              <HeaderDescription size="6">{customerCompletesPageContent}</HeaderDescription>
            </NavSection>
          </PageWrap>
        </TopBackground>
        <Wrap>
          <Details>
            <Heading size="4">{customerCompletesPageFormTitle}</Heading>
            <form>
              <FormContainer>
                <FormContent>{customerCompletesPageFormContent}</FormContent>
                <Container>
                  <InfoContainer>
                    <InputField
                      htmlFor="emailAddress"
                      name="emailAddress"
                      label={customerCompletesPageEmailLabel}
                      changeHandler={this.handleChange}
                      error={emailAddress.error.error}
                      caption={emailAddress.error.error && emailAddress.error.errorMsg}
                      value={emailAddress.value}
                      placeholder={customerCompletesPageEmailPlaceholder}
                      focusOnMount
                    />
                    <InputPhoneField
                      selectPhoneCode={selectPrimaryPhoneCode}
                      inputPhoneNumber={inputPrimaryPhoneNumber}
                    />
                  </InfoContainer>
                  <InfoContainer>
                    <InfoWrap>
                      <InfoIcon iconName="far fa-arrow-pointer" />
                      <InfoContentWrap>
                        <InfoBox>
                          <InfoBoxHeader>{infoBoxOnlineStatementHeader}</InfoBoxHeader>
                          <InfoBoxContent>{infoBoxOnlineStatementContent}</InfoBoxContent>
                        </InfoBox>
                      </InfoContentWrap>
                    </InfoWrap>
                    <InfoWrap>
                      <InfoIcon iconName="far fa-phone" />
                      <InfoContentWrap>
                        <InfoBox>
                          <InfoBoxHeader>{infoBoxTeleUnderwritingHeader}</InfoBoxHeader>
                          <InfoBoxContent>{infoBoxTeleUnderwritingContent}</InfoBoxContent>
                        </InfoBox>
                      </InfoContentWrap>
                    </InfoWrap>
                  </InfoContainer>
                </Container>
              </FormContainer>
              <Button type="primary" onClick={this.handleSubmit}>
                {customerCompletesPageConfirmButtonLabel}
              </Button>
            </form>
          </Details>
        </Wrap>
      </WithLoader>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ forms, createQuote, advisor, config, masterList }) => ({
  form: forms[FORM_ID],
  createQuote,
  config,
  masterList: masterList.data,
  advisor,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerCompletes))
