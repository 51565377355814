// @flow
import React, { useState, useEffect, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Button, Icons, Heading, Variables } from '@mlcl-digital/mlcl-design'
import history from '../../../utils/browserHistory'

// styles
import styles from './NotificationBar.styles'

// utils
import { isLocalStorage } from '../../../utils/browserUtils'
import { shouldRenderNativeLink } from '../../../utils/commonUtils'

type NotificationBarProps = {
  isCustomer?: boolean,
  heading: string,
  buttonProps?: object,
}
const { fontSize } = Variables
const { IconClose16 } = Icons

const Container = styled('div')(({ isCustomer }) => styles.container(isCustomer))
export const RightButton = styled(Button)(({ isCustomer }) => styles.rightButton(isCustomer))
export const RightLinkBtn = styled('a')(({ isCustomer }) => styles.rightLinkBtn(isCustomer))
export const NotificationHeading = styled(Heading)(styles.heading)
export const CloseButton = styled(Button)(styles.closebtn)

const NotificationBar = (props: NotificationBarProps) => {
  const { isCustomer, heading, buttonProps } = props
  const [showNotificationBar, setShowNotificationBar] = useState(true)
  const buttonLink = get(buttonProps, 'href', '#')
  const buttonText = get(buttonProps, 'text', 'Find out more')

  // set localstorage at the beginning and intialize state from it
  // this is for browser to remember the closing action of the bar
  const getInitialVisibilityFromLocalStorage = () => {
    if (isLocalStorage()) {
      const initialVisibility = window.localStorage.getItem('NotificationBarVisible')
      if (!initialVisibility) {
        window.localStorage.setItem('NotificationBarVisible', showNotificationBar)
      } else {
        setShowNotificationBar(initialVisibility === 'true')
      }
    }
  }

  useEffect(() => {
    getInitialVisibilityFromLocalStorage()
  }, [])

  // close bar and set localstorage to false
  const closeNotificationBar = () => {
    setShowNotificationBar(false)
    if (isLocalStorage()) {
      window.localStorage.setItem('NotificationBarVisible', false)
    }
  }

  return (
    <Fragment>
      {showNotificationBar && heading && (
        <Container isCustomer={isCustomer}>
          <NotificationHeading
            variant="h5"
            styleOverrides={{
              fontSize: fontSize.sm,
            }}
          >
            {heading}
          </NotificationHeading>
          {buttonText &&
            buttonLink &&
            (shouldRenderNativeLink(buttonLink) ? (
              <RightLinkBtn isCustomer={isCustomer} href={buttonLink} target="_blank">
                {buttonText}
              </RightLinkBtn>
            ) : (
              <RightButton
                variant="primary"
                isCustomer={isCustomer}
                onClick={() => history.push(buttonLink)}
              >
                {buttonText}
              </RightButton>
            ))}
          <CloseButton
            variant="icon"
            onClick={() => closeNotificationBar()}
            ariaLabel="Close"
            data-testid="Close"
          >
            <IconClose16 />
          </CloseButton>
        </Container>
      )}
    </Fragment>
  )
}

NotificationBar.defaultProps = {
  isCustomer: false,
  buttonProps: {
    text: '',
    href: '#',
  },
}

export default NotificationBar
