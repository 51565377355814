// @flow
import React from 'react'
import styled from '@emotion/styled'

// styles.
import { space } from '../../../../styles'

const VideoWrap = styled('div')({
  marginBottom: space(3),
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  paddingBottom: '56.25%',
  display: 'block',
  maxWidth: '100%',
})

const VideoObject = styled('object')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

type VideoProps = {
  fields: {
    code: {
      value: string,
      editable: string,
    },
    title: {
      value: string,
      editable: string,
    },
  },
  params?: {
    autoPlay: boolean,
  },
}

const Video = (props: VideoProps) => {
  const { fields, params } = props
  return (
    <VideoWrap>
      <VideoObject
        data={`https://www.youtube.com/embed/${fields.code.value}?autoplay=${
          params.autoPlay ? 1 : 0
        }`}
      >
        {fields.title.value}
      </VideoObject>
    </VideoWrap>
  )
}

Video.defaultProps = {
  params: {
    autoPlay: false,
  },
}

export default Video
