// @flow
import React from 'react'

// components.
import AdvisorAccountSettings from '../../../organisms/AdvisorAccountSettings'

type AccountSettingsProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const AccountSettings = ({ fields }: AccountSettingsProps) => (
  <AdvisorAccountSettings fields={fields} />
)

export default AccountSettings
