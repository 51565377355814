import { mediaQueries, fontSize, colours, space } from '../../../../../styles'

const styles = {
  wrapper: {
    flex: '0 0 100%',

    [mediaQueries.md]: {
      flexBasis: '70%',
    },
  },
  title: {
    marginBottom: space(1),
    fontSize: fontSize.sm,
  },
  name: {
    color: colours.white,
    marginBottom: 0,
  },
  inlineDetails: {
    margin: space(0),
  },
}

export default styles
