// @flow
// types.
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_REQUEST_PROGRESS,
  FILE_UPLOAD_REQUEST_FAILURE,
  FILE_UPLOAD_REQUEST_SUCCESS,
  FILE_REMOVE_REQUEST,
  FILE_BROWSE_INIT,
  FILE_BROWSE_INIT_SUCCESS,
  FILE_UPLOAD_CREATE_WORK_ITEM,
  FILE_UPLOAD_MODAL_TOGGLE,
  FILE_UPLOAD_MODAL_RESET_DATA,
  UPLOADED_DOCUMENT_LIST,
  SAVE_UPLOADED_DOCUMENTS,
  SHOW_TASK_UPLOAD_CONFIRMATION_MODAL,
} from '../types/fileUpload'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'
import { makeRequestHeaders } from '../../utils/middlewareUtils'
import { getBancsCustomerNumber } from '../../utils/cookieUtils'
import { removeDotsFromFileName } from '../../utils/commonUtils'
import { filterListPostDocumentUploadSuccess } from './taskDashboard'

const browseFilesToUploadSuccess = files => ({
  type: FILE_BROWSE_INIT_SUCCESS,
  payload: {
    files,
  },
})

export const saveUploadedFiles = ({ quoteCollectionId, uploadedDocument }) => ({
  type: SAVE_UPLOADED_DOCUMENTS,
  payload: {
    quoteCollectionId,
    uploadedDocument,
  },
})

export const browseFilesToUpload = files => dispatch => {
  dispatch({ type: FILE_BROWSE_INIT })
  dispatch(browseFilesToUploadSuccess(files))
}

export const createWorkItem = bodyData => dispatch => {
  dispatch({
    type: EXPERIENCE_API,
    name: FILE_UPLOAD_CREATE_WORK_ITEM,
    verb: 'POST',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/general/serviceRequest`,
    data: {
      ...bodyData,
      ...(!bodyData.bancsCustomerNo && { bancsCustomerNo: getBancsCustomerNumber() }),
    },
    callback: (error, dataReceived, requestData) => ({
      dataReceived,
      error,
      requestData,
    }),
  })
}

export const removeFile = (files: Array, fileId: string) => dispatch => {
  const updatedFiles = files.filter(file => file.fileId !== fileId)
  dispatch({
    type: FILE_REMOVE_REQUEST,
    payload: {
      files: updatedFiles,
    },
  })
}

export const uploadFileRequestSend = (fileId: string) => ({
  type: FILE_UPLOAD_REQUEST,
  payload: {
    fileId,
    loading: 15,
    uploading: true,
  },
})

export const uploadFileInProgress = (fileId: string, loading: number) => ({
  type: FILE_UPLOAD_REQUEST_PROGRESS,
  payload: {
    fileId,
    loading: loading - 5,
    uploading: true,
  },
})

export const uploadFileRequestDone = (fileId, result) => ({
  type: FILE_UPLOAD_REQUEST_SUCCESS,
  payload: {
    ...result,
    fileId,
    loading: 100,
    uploading: false,
  },
})

export const uploadFileRequestFailure = (fileId, result) => ({
  type: FILE_UPLOAD_REQUEST_FAILURE,
  payload: {
    fileId,
    hasNetworkError: true,
    error: result,
  },
})

export const showTaskUploadConfirmationModal = (isTaskUploadConfirmationModal: boolean = true) => ({
  type: SHOW_TASK_UPLOAD_CONFIRMATION_MODAL,
  payload: {
    isTaskUploadConfirmationModal,
  },
})

export const uploadFile =
  (
    uploadRequest: FormData,
    { saveFileUpload, quoteCollectionId, uploadedDocument, isTaskUpload } = {},
    agreementReqId?: string
  ) =>
  (dispatch: Function, getState: Function) => {
    const fileId = uploadRequest.get('fileIdentifier')
    dispatch(uploadFileRequestSend(fileId))

    const {
      okta: { token },
      config,
    } = getState()
    const API_DOMAIN: string = config.MLCL_EXPERIENCE_API

    const headers = makeRequestHeaders('multipart/form-data', token, {
      'X-InitialRole': config.MLCL_UPLOAD_FILE_API_INITIAL_ROLE,
    })
    const url = `${API_DOMAIN}${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/documents`
    axios
      .post(url, uploadRequest, {
        headers,
        onUploadProgress: progressEvent =>
          dispatch(
            uploadFileInProgress(fileId, (progressEvent.loaded / progressEvent.total) * 100)
          ),
      })
      .then(res => {
        const result = res.data
        if (res.status >= 200 && res.status < 300) {
          dispatch(uploadFileRequestDone(fileId, result))
          if (isTaskUpload) {
            dispatch(showTaskUploadConfirmationModal(true))
          }
          if (agreementReqId !== null) {
            dispatch(filterListPostDocumentUploadSuccess(agreementReqId))
          }
          if (saveFileUpload) {
            dispatch(saveUploadedFiles({ quoteCollectionId, uploadedDocument }))
          }
        } else {
          dispatch(uploadFileRequestFailure(fileId, result))
        }
      })
      .catch(error => {
        const err = error && error.response && error.response.data
        dispatch(uploadFileRequestFailure(fileId, err))
      })
  }

// NOTE: If caller sends additional data, just pass it
// over as additionalData to reducer, whatever. MM.
export const toggleFileUploadModal = (showModal, ...additionalData) => ({
  type: FILE_UPLOAD_MODAL_TOGGLE,
  payload: {
    showModal,
    additionalData,
  },
})

export const resetFilesData = () => ({
  type: FILE_UPLOAD_MODAL_RESET_DATA,
})

export const getUploadedDocumentList = data => ({
  type: EXPERIENCE_API,
  name: UPLOADED_DOCUMENT_LIST,
  verb: 'POST',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/group/documents/metadata`,
  data,
  callback: (err, dataReceived, requestPayload) => ({
    dataReceived,
    requestPayload,
  }),
})

const generateFileUploadRequest = (prevActionPayload, uploadFileData) => (dispatch: Function) => {
  const {
    files,
    createWorkItemRequest,
    agreementReqId,
    uploadDocumentRequest,
    saveFileUpload,
    uploadedDocument,
    isTaskUpload,
  } = uploadFileData

  let workItemData = null
  if (prevActionPayload) {
    workItemData = prevActionPayload.payload && prevActionPayload.payload.dataReceived.businessData
  }

  // iterate all the files and form a request
  const getUploadDocumentKeys = Object.keys(uploadDocumentRequest)
  files.forEach(({ hasValidationError, file, loading, fileUploadError, fileId }) => {
    if (!hasValidationError && !loading && !fileUploadError) {
      const request = new FormData()
      if (getUploadDocumentKeys.length) {
        getUploadDocumentKeys.forEach(key => {
          request.append(key, uploadDocumentRequest[key])
        })
      }

      if (createWorkItemRequest && createWorkItemRequest.workTypeCode && workItemData) {
        request.append('workItemNo', workItemData.workItemReferenceNo)
      }
      request.append('file', file, removeDotsFromFileName(file.name))
      request.append('fileIdentifier', fileId)
      // trigger file upload
      dispatch(
        uploadFile(
          request,
          {
            saveFileUpload,
            quoteCollectionId: uploadDocumentRequest.agreementNumber,
            uploadedDocument,
            isTaskUpload,
          },
          agreementReqId
        )
      )
    }
  })
}

export const uploadFileWithWorkItem = fileUploadData => (dispatch, getState) => {
  if (fileUploadData.isCreateWorkItemDisabled || !fileUploadData.workItemRequired) {
    dispatch(generateFileUploadRequest({}, fileUploadData))
  } else {
    const bodyData = fileUploadData.createWorkItemRequest
    const { supportStaff } = getState()
    const isSupportStaff = !isEmpty(supportStaff?.activeSupportStaff)
    dispatch({
      type: EXPERIENCE_API,
      name: FILE_UPLOAD_CREATE_WORK_ITEM,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/general/serviceRequest`,
      data: {
        ...bodyData,
        bancsCustomerNo:
          bodyData && bodyData.bancsCustomerNo
            ? bodyData.bancsCustomerNo
            : getBancsCustomerNumber(),
      },
      additionalHeaders: isSupportStaff ? { 'X-InitialRole': 'Support_staff' } : {},
      callback: (error, dataReceived, requestData) => ({
        dataReceived,
        error,
        requestData,
      }),
      queuedAction: prevActionPayload =>
        generateFileUploadRequest(prevActionPayload, fileUploadData),
    })
  }
}
