import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { space, mediaQueries, colours, mediaQueriesMax } = Variables as VariablesType

const styles = {
  base: {
    position: 'relative',
    zIndex: 1,
    maxWidth: '400px',
    maxHeight: '470px',
    margin: '0 auto',
    [mediaQueriesMax.md]: {
      border: 'none',
    },
  },
  header: {
    padding: space(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    [mediaQueriesMax.md]: {
      borderBottom: 'none',
    },
  },
  headerOuterContent: {
    minWidth: '20%',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    h2: {
      textAlign: 'center',
      marginBottom: `${space(1, true)}`,
    },
    [mediaQueries.md]: {
      padding: `${space(3, true)} ${space(10, true)}`,
    },
    '& > div': {
      width: '100%',
    },
  },
  thirdPartyProgressContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    minHeight: '400px',
    paddingBottom: '106px',
  },

  thirdPartyQuoteProgressMessage: {
    textAlign: 'center' as const,
    color: colours.mediumGrey,
  },
}
export default styles
