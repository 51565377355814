import { colours, fontSize, mediaQueries, space } from '../../../../../styles'

const styles = {
  wrapper: {
    color: colours.white,
    backgroundColor: colours.mediumDarkGrey,
  },
  main: {
    paddingBottom: space(1),
    paddingTop: space(5),
    [mediaQueries.md]: {
      paddingBottom: space(15),
      paddingTop: space(9),
    },
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  navGroup: {
    flex: '0 0 50%',
    width: '50%',
    [mediaQueries.md]: {
      flex: '0 0 25%',
      width: '25%',
    },
  },
  navHeading: {
    color: colours.white,
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
    marginBottom: space(1),
  },
  navList: {
    fontSize: fontSize.xxs,
    listStyle: 'none',
    marginBottom: space(3),
    [mediaQueries.md]: {
      marginBottom: 0,
    },
  },
  navListItem: {
    lineHeight: fontSize.xs,
    marginBottom: space(1),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  secondary: ({ footerType = false }) => ({
    color: colours.mediumGrey,
    backgroundColor: footerType ? colours.lightestGrey : colours.black,
    fontSize: fontSize.xxs,
    lineHeight: fontSize.xs,
    paddingBottom: 24,
    paddingTop: 24,
    [mediaQueries.md]: {
      paddingBottom: footerType ? space(3) : space(9),
      paddingTop: footerType ? space(3) : space(7),
    },
  }),
  link: {
    color: colours.white,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}

export default styles
