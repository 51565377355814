// @flow
import { createSelector } from 'reselect'

import get from 'lodash/get'

import { getAuthenticated, getMyLinkAuthorised, getSessionStateMsg } from './common.selectors'
// @ts-expect-error file not in typescript
import history from '../utils/browserHistory'
import { CUSTOMER_TELE_UNDERWRITER_HOME_PAGE } from '../constants/personalStatement'

const myLinkTimeOut = 'myLinkTimeoutMsg'
const TeleTimeoutMsg = 'TeleTimeoutMsg'
const sessionTimeoutMsg = 'sessionTimeoutMsg'
const sessionDefaultMsg =
  'As you have not accessed the site for some time or you were idle, you are going to be logged out'

export const getSessionTimeoutMsg = createSelector(
  [getSessionStateMsg, getMyLinkAuthorised, getAuthenticated],
  (sessionStateMsg, mylinkAuthorised, authenticated) => {
    const currentUrl = history.location.pathname || ''
    let sessionMsgCode = sessionTimeoutMsg
    if (currentUrl.includes(CUSTOMER_TELE_UNDERWRITER_HOME_PAGE)) sessionMsgCode = TeleTimeoutMsg
    else if (!authenticated && mylinkAuthorised) sessionMsgCode = myLinkTimeOut
    return get(
      sessionStateMsg.find(({ code }) => code === sessionMsgCode),
      'value',
      sessionDefaultMsg
    )
  }
)
