// @flow
import get from 'lodash/get'

import {
  ABN_LOOK_UP_SUCCESS,
  ABN_LOOK_UP_FAILURE,
  ABN_LOOK_UP_ERROR,
  ABN_LOOK_UP_INIT,
  ABN_LOOK_UP_RESET,
} from '../actions/types/abnLookup'
import { ABN_STATUS_CANCELLED } from '../constants/abnLookup'

export const initialFieldState = {
  isLoading: false,
  error: false,
  identifierValue: '',
  companyName: '',
  isNoBusinessName: false,
  isAbnCancelled: false,
}

const abnLookup = (
  state = initialFieldState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case ABN_LOOK_UP_INIT: {
      return {
        ...state,
        isLoading: true,
        identifierValue: '',
        companyName: '',
        error: false,
        isNoBusinessName: false,
        isAbnCancelled: false,
      }
    }
    case ABN_LOOK_UP_SUCCESS: {
      const organisationName = get(action.payload, 'businessEntity.mainName.organisationName', '')
      const isABNStatus = get(action.payload, 'businessEntity.entityStatus.entityStatusCode', '')
      return {
        ...state,
        identifierValue: get(action.payload, 'businessEntity.ABN.identifierValue', ''),
        companyName: organisationName,
        isNoBusinessName: organisationName === '',
        error: !!get(action.payload, 'exception'),
        isLoading: false,
        isAbnCancelled: isABNStatus.toLowerCase() === ABN_STATUS_CANCELLED.toLowerCase(),
      }
    }
    case ABN_LOOK_UP_FAILURE:
    case ABN_LOOK_UP_ERROR: {
      return {
        ...state,
        identifierValue: '',
        companyName: '',
        error: true,
        isLoading: false,
        isNoBusinessName: false,
        isAbnCancelled: false,
      }
    }
    case ABN_LOOK_UP_RESET: {
      return {
        ...initialFieldState,
      }
    }
    default: {
      return state
    }
  }
}

export default abnLookup
