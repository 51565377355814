// @flow
import React from 'react'

// components.
import AdvisorQuoteListing from '../../../organisms/AdvisorQuoteListing'

type QuoteListingProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const QuoteListing = ({ fields }: QuoteListingProps) => <AdvisorQuoteListing fields={fields} />

export default QuoteListing
