// @flow
import { createSelector } from 'reselect'
import uniq from 'lodash/uniq'
import get from 'lodash/get'

// @ts-expect-error file not in typescript
import { getProcessingStatusFromAction } from '../utils/commonUtils'
import {
  getLifeInsuredFromPolicies,
  getPolicyOwner,
  getPolicyOwnerName,
  // @ts-expect-error file not in typescript
} from '../utils/policyUtils'

import { getCreateQuote, getMasterData, getPolicyStructure } from './common.selectors'

import { COMPLETE } from '../constants/application'
import { POLICY_CHILD_COVER_BENEFIT_CODE } from '../constants/policies'
import { Relationship } from '../types/ClientPolicies'

export const makePolicyOwner = createSelector([getCreateQuote], ({ activeIndex, quotes }) => {
  let ownersList: string[] = quotes[activeIndex].policyStructure.reduce((acc: string[], policy) => {
    const policyOwners = getPolicyOwner(policy.relationships, true, true) as Relationship[]
    const policyOwnerPersonnel = Array.isArray(policyOwners)
      ? (policyOwners.map(getPolicyOwnerName) as string[])
      : [getPolicyOwnerName(policyOwners) as string]
    return [...acc, ...policyOwnerPersonnel]
  }, [])
  ownersList = uniq(ownersList)
  return ownersList.join(', ')
})

export const makeLifeInsured = createSelector([getPolicyStructure], policyStructure => {
  const childLAList = policyStructure.flatMap(policy =>
    policy.covers.reduce((acc: string[], cover) => {
      if (cover.type === POLICY_CHILD_COVER_BENEFIT_CODE) {
        return [...acc, ...cover.benefitAssured.map(childLA => childLA.bancsCustomerNo)]
      }
      return acc
    }, [])
  )
  return getLifeInsuredFromPolicies(policyStructure, true)
    .filter((lifeInsured: Relationship) => !childLAList.includes(lifeInsured.bancsCustomerNo))
    .map(
      ({
        relatedParty = { firstName: '', lastName: '' },
      }: {
        relatedParty?: { firstName: string; lastName: string }
      }) => `${relatedParty?.firstName} ${relatedParty?.lastName}`
    )
    .join(', ')
})

export const makePolicyList = createSelector([getPolicyStructure], policyStructure =>
  policyStructure.filter(eachPolicyStructure =>
    get(eachPolicyStructure.alteration, 'isPolicyAltered', false)
  )
)

export const makeAltsTypeLabel = createSelector(
  [getMasterData, getCreateQuote],
  (masterData, createQuote) => {
    const altsObj =
      get(masterData, 'alterationTypes', []).find(
        item => item.code === createQuote.alterationType
      ) || {}
    return 'value' in altsObj ? altsObj.value : undefined
  }
)

export const makeIsApplicationComplete = createSelector(
  [getCreateQuote],
  createQuote =>
    getProcessingStatusFromAction(createQuote.action, createQuote.applicationStage) === COMPLETE
)
