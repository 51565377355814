import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { space, fontWeights, colours, fontSize } = Variables as VariablesType
export default {
  caption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: fontSize.xxs,
    lineHeight: fontSize.xs,
    marginTop: space(1),
    marginBottom: space(3),
  },
  captionInfoOne: {
    color: colours.mlcAquaAccessible,
    fontWeight: fontWeights.light,
    padding: 0,
    letterSpacing: 'normal',
    '&:hover:not(:disabled)': {
      color: colours.mlcAquaAccessible,
    },
  },
  captionInfoTwo: {
    textDecoration: 'none',
    color: colours.mlcAquaAccessible,
  },
}
