// @flow
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import { pathOr } from 'lodash/fp'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import {
  PageWrap,
  ToggleGroup,
  Checkbox as CheckboxComponent,
  Button,
} from '@mlcl-digital/mlcl-design'
import history from '../../../utils/browserHistory'
import { arrayToObject } from '../../../utils/commonUtils'

// components
import ApplicationDeclarationAccordion from './components/Accordion'
import MedicalAuthorityDeclaration from './components/MedicalAuthorityDeclaration'

// molecules
import Header from '../../molecules/PageHeader'
import Modal from '../../molecules/Modal'

// actions.
import { actionCreators } from '../../../actions'

// style
import styles from './applicationDeclaration.styles'
import TeleLayout from '../../molecules/PersonalStatementLayout'

// utils.
import { renderTextField } from '../../../utils/sitecoreUtils'
import { getFullName } from '../../../utils/contactUtils'
import {
  getTermAndCondition,
  getTermAndConditionLabel,
} from '../../../utils/termsAndConditionsUtils'
import { getScope } from '../../../utils/cookieUtils'
import { getAllPolicyOwnerName } from '../../../utils/quoteUtils'
import { getPolicyOwnerTitle } from '../../../utils/extendedQuoteUtils'

// selectors
import { getHasMedicalAuthorityBeenCompleted } from '../../../selectors/consents'

// data locators
import {
  LOCATOR_AUTHORISATION_CHECKBOX,
  LOCATOR_ADVISER_CHECKBOX,
  LOCATOR_DECLARATIONPO_CHECKBOX,
} from './applicationDeclaration.locators'

// constants
import {
  APPLICATION_REVIEW_PATH,
  SAVE_DRAFT_APPLICATION,
  TELE,
  ADV_F2F,
  ADV_PHN,
  MYLINK,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  COMPLETE_TELE,
  NOT_AVAILABLE,
  COMPLETE_MYLINK,
  POLICYOWNER,
} from '../../../constants/application'
import {
  CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE,
  CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE,
} from '../../../constants/personalStatement'
import { SCOPE_TYPE_TELE } from '../../../constants/okta'
import {
  QUOTE_STATUS_SUBMITTED_TO_BANCS_STAGE,
  QUOTE_STATUS_APPLICATION_STAGE,
  POLICY_PRODUCT_CODE_SUPER,
  SUPER_OWNER_INTERNAL,
  POLICY_BENEFIT_CI_PLUS,
} from '../../../constants/policies'
import {
  CALCULATED_URE_TERM,
  DECISON_TYPE_REFER,
  CALCULATED_URE_REFERRED,
} from '../../../constants/ure'
import { ADVISOR_PORTAL } from '../../../constants/site'
import WithLoader from '../../molecules/WithLoader'

const MainWrapper = styled('div')(styles.offset)
const FitnessTrackerContent = styled('div')(styles.fitnessTrackerContent)
const ErrorMessage = styled('p')(styles.errorMessage)
const ModalContent = styled('div')(styles.modalContent)
const ToggleGroupContainer = styled('div')(styles.toggleGroupContainer)
const Checkbox = styled(CheckboxComponent)(styles.checkbox)

type ExistingConsentsType = {
  MARKETING_CONSENT?: {
    name: 'MARKETING_CONSENT',
    value: string,
  },
  DECLARATION_FOR_ADVISOR?: {
    name: 'DECLARATION_FOR_ADVISOR',
    value: string,
  },
  DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED?: {
    name: 'DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED',
    value: string,
  },
  DECLARATION_AUTHORIZATION_FOR_POLICY_OWNER?: {
    name: 'DECLARATION_AUTHORIZATION_FOR_POLICY_OWNER',
    value: string,
  },
  DECLARATION_CUSTOMER_TELE_TERMS?: {
    name: 'DECLARATION_CUSTOMER_TELE_TERMS',
    value: string,
  },
  SHARE_PERS_STMT_WITH_ADVISOR?: {
    name: 'SHARE_PERS_STMT_WITH_ADVISOR',
    value: string,
  },
}

type ApplicationDeclarationProps = {
  fields: Object,
  lifeInsuredDetails: Object,
  existingConsents: ExistingConsentsType,
  isCustomerPortal: boolean,
  isFetching: boolean,
  quoteAction: Function,
  actions: {
    updateDeclarationAuthorisationConsents: Function,
    updateDeclarationPOConsents: Function,
    updateAdviserDeclarationConsents: Function,
    updateMarketingConsents: Function,
    resetDeclarationConsents: Function,
    changePersonalStatementCompletionStatus: Function,
    saveQuote: Function,
    retriggerFullUredata: Function,
  },
  activeQuote: Object,
  masterData: Object,
  targetedIndex: String,
  underwritingMethod: String,
  fullUREresult: Object,
  saveQuoteError: Object,
  isFetchingURE: boolean,
  isRefetchURE: boolean,
  config: Object,
  isTeleTermsConditions: Boolean,
  isPaddingNeeded: Boolean,
  hasMedicalAuthorityBeenCompleted: boolean,
  isMedicalAuthorityFormRequiredForAdviser: boolean,
}
type ApplicationDeclarationState = {
  termIdentifier: string,
  isRedirect: boolean,
  isPolicyOwnerVisible: boolean,
  lifeInsuredTitle: string,
  lifeInsuredContent: string,
  policyOwnerTitle: string,
  policyOwnerContent: string,
  adviserContent: string,
  marketingContent: string,
  isAuthorisationCompleted: boolean,
  isAuthorisationConfirmed: boolean,
  isPODeclarationCompleted: boolean,
  isPODeclarationConfirmed: boolean,
  isAdviserConfirmed: boolean,
  isTeleTermsCompleted: boolean,
  isAdviserAuthorityCompleted: boolean,
  isAdviserAuthoritySelected: boolean,
  showError: boolean,
  authoShowError: boolean,
  poShowError: boolean,
  isAdviserCompleted: boolean,
  poTermIdentifier: string,
  liTermIdentifier: string,
  adviserTermIdentifier: string,
  openSections: {
    authorisation: boolean,
    PODeclaration: boolean,
    adviser: boolean,
    consent: boolean,
    customerTele: boolean,
    adviserAuthority: boolean,
  },
  consentRadioShowError: boolean,
  isConsentRadio: boolean,
  isConsentCompleted: boolean,
  isAuthorisationRequired: boolean,
}

export class ApplicationDeclaration extends Component<
  ApplicationDeclarationProps,
  ApplicationDeclarationState
> {
  constructor(props) {
    super(props)
    const {
      existingConsents,
      quoteAction,
      activeQuote,
      masterData,
      lifeInsuredDetails,
      config,
      fields,
      hasMedicalAuthorityBeenCompleted,
    } = this.props
    const { termIdentifier, redirect, termNumber, isLIisThePolicyOwner } =
      getTermAndCondition(activeQuote)
    const policyStructure = activeQuote ? get(activeQuote, 'policyStructure', []) : []
    const SITE = config ? get(config, 'SITE', '') : ''
    const policyOwnerName = []
    policyStructure.forEach(policy => {
      if (
        !(
          policy.productId === POLICY_PRODUCT_CODE_SUPER &&
          policy.superFundName === SUPER_OWNER_INTERNAL
        )
      ) {
        policyOwnerName.push(...getAllPolicyOwnerName(policy, POLICYOWNER))
      }
    })
    const termAndConditionLabel =
      masterData[termIdentifier] && masterData[termIdentifier].length
        ? getTermAndConditionLabel(masterData[termIdentifier], termIdentifier, termNumber)
        : {}

    const lifeInsuredContent =
      termAndConditionLabel && termAndConditionLabel.li ? termAndConditionLabel.li : ''
    const policyOwnerContent =
      termAndConditionLabel && termAndConditionLabel.po ? termAndConditionLabel.po : ''
    const adviserContent =
      termAndConditionLabel && termAndConditionLabel.adviser ? termAndConditionLabel.adviser : ''
    const poTermIdentifier =
      termAndConditionLabel &&
      termAndConditionLabel.poIdentifier &&
      termAndConditionLabel.poIdentifier !== NOT_AVAILABLE
        ? termAndConditionLabel.poIdentifier
        : ''
    const liTermIdentifier =
      termAndConditionLabel &&
      termAndConditionLabel.liIdentifier &&
      termAndConditionLabel.liIdentifier !== NOT_AVAILABLE
        ? termAndConditionLabel.liIdentifier
        : ''
    const adviserTermIdentifier =
      termAndConditionLabel &&
      termAndConditionLabel.adviserIdentifier &&
      termAndConditionLabel.adviserIdentifier !== NOT_AVAILABLE
        ? termAndConditionLabel.adviserIdentifier
        : ''
    const lifeInsuredName =
      lifeInsuredDetails && lifeInsuredDetails.relatedParty
        ? getFullName(lifeInsuredDetails.relatedParty)
        : ''
    const isPolicyOwnerVisible = !(
      policyOwnerContent === '' || policyOwnerContent === NOT_AVAILABLE
    )
    if (
      (existingConsents.DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED ||
        existingConsents.DECLARATION_AUTHORIZATION_FOR_POLICY_OWNER ||
        existingConsents.DECLARATION_FOR_ADVISOR ||
        existingConsents.MARKETING_CONSENT) &&
      SITE === ADVISOR_PORTAL
    ) {
      this.resetConsents()
    }
    const isAuthorisationCompleted = !!(
      existingConsents.DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED &&
      existingConsents.DECLARATION_AUTHORIZATION_FOR_LIFE_INSURED.value
    )
    const isPODeclarationCompleted = false

    const isAdviserCompleted = false
    const isTeleTermsCompleted = !!(
      existingConsents.DECLARATION_CUSTOMER_TELE_TERMS &&
      existingConsents.DECLARATION_CUSTOMER_TELE_TERMS.value
    )
    const isAdviserAuthoritySelected = !!existingConsents.SHARE_PERS_STMT_WITH_ADVISOR
    const isAdviserAuthorityCompleted = existingConsents?.SHARE_PERS_STMT_WITH_ADVISOR?.value
    const isAuthorisationRequired = quoteAction === SAVE_DRAFT_APPLICATION
    this.state = {
      isRedirect: redirect,
      isPolicyOwnerVisible,
      lifeInsuredTitle: lifeInsuredName,
      lifeInsuredContent,
      policyOwnerTitle: getPolicyOwnerTitle(policyOwnerName, fields),
      policyOwnerContent,
      adviserContent,
      isAuthorisationCompleted,
      isAuthorisationConfirmed: isAuthorisationCompleted,
      isPODeclarationCompleted,
      isPODeclarationConfirmed: false,
      isAdviserConfirmed: false,
      isTeleTermsCompleted,
      isAdviserAuthorityCompleted,
      isAdviserAuthoritySelected,
      showError: false,
      authoShowError: false,
      poShowError: false,
      isAdviserCompleted,
      poTermIdentifier,
      liTermIdentifier,
      adviserTermIdentifier,
      openSections: {
        authorisation: !isAuthorisationCompleted,
        PODeclaration:
          (isAuthorisationCompleted || !isAuthorisationRequired) && !isPODeclarationCompleted,
        adviser:
          (isAuthorisationCompleted || !isAuthorisationRequired) &&
          (!isPolicyOwnerVisible || isPODeclarationCompleted) &&
          !isAdviserCompleted,
        consent:
          (isAuthorisationCompleted || !isAuthorisationRequired) &&
          (!isPolicyOwnerVisible || isPODeclarationCompleted) &&
          isAdviserCompleted,
        customerTele: !isTeleTermsCompleted,
        adviserAuthority:
          (isTeleTermsCompleted && !isAdviserAuthoritySelected) ||
          (isAuthorisationCompleted && !isAdviserAuthoritySelected),
        medicalAuthority: isAuthorisationCompleted && !hasMedicalAuthorityBeenCompleted,
      },
      isAuthorisationRequired,
      showRetryModal: false,
      saveQuoteInProgress: false,
      isLIisThePolicyOwner,
    }
  }

  componentDidMount() {
    // Redirect on application review page if - isRedirect is true
    const { isRedirect } = this.state
    const { isCustomerPortal, underwritingMethod } = this.props

    if (!isCustomerPortal && isRedirect) {
      const path = APPLICATION_REVIEW_PATH
      history.push(path)
    }

    // update underwriting result for tele or mylink
    if (isCustomerPortal && (underwritingMethod === MYLINK || underwritingMethod === TELE)) {
      this.updateUnderwritingResults()
    }
  }

  componentDidUpdate(prevProps: Object) {
    const { isFetching, fullUREresult, saveQuoteError, isFetchingURE, isRefetchURE } = this.props

    // if calculate ure status not present in refetching full ure, show error
    if (
      prevProps.isFetchingURE &&
      !isFetchingURE &&
      isRefetchURE &&
      !fullUREresult.calculatedUREstatus
    ) {
      this.setState({
        showRetryModal: true,
      })
    }
    // if refetch ure was done, on success of save quote, redirect user
    if (prevProps.isFetching && !isFetching && isRefetchURE) {
      if (saveQuoteError) {
        this.setState({
          showRetryModal: true,
        })
      } else {
        history.push(this.redirectToUrl)
      }
    }
  }

  updateUnderwritingResults = () => {
    const {
      activeQuote,
      actions: { updateCalculateUreStatus, createQuoteUpdateIsNonStandard },
    } = this.props
    const underwritingDetails = activeQuote ? get(activeQuote, 'underwritingDetails', {}) : {}
    if (underwritingDetails && underwritingDetails.status) {
      const { status } = underwritingDetails
      const doesHighLevelDecisionHasReferred = status.some(
        ({ benefitCode, underwritingDecision }) =>
          benefitCode === POLICY_BENEFIT_CI_PLUS && underwritingDecision === DECISON_TYPE_REFER
      )
      if (doesHighLevelDecisionHasReferred) {
        createQuoteUpdateIsNonStandard(false)
        updateCalculateUreStatus(CALCULATED_URE_REFERRED)
      }
    }
  }

  backClickHandler = () => {
    const { isTeleTermsConditions } = this.props
    const path = '/application'

    if (isTeleTermsConditions) {
      history.push('personalstatement/underwriting')
    }
    history.replace(path)
  }

  handleInCompleteAccordion = () => {
    const {
      isAuthorisationCompleted,
      isAdviserCompleted,
      isPODeclarationCompleted,
      isPolicyOwnerVisible,
      isAuthorisationRequired,
    } = this.state
    const { hasMedicalAuthorityBeenCompleted } = this.props
    this.setState({
      openSections: {
        medicalAuthority: isAuthorisationCompleted && !hasMedicalAuthorityBeenCompleted,
        authorisation: !isAuthorisationCompleted,
        PODeclaration:
          (isAuthorisationCompleted || !isAuthorisationRequired) && !isPODeclarationCompleted,
        adviser:
          (isAuthorisationCompleted || !isAuthorisationRequired) &&
          (!isPolicyOwnerVisible || isPODeclarationCompleted) &&
          !isAdviserCompleted,
        consent:
          (isAuthorisationCompleted || !isAuthorisationRequired) &&
          (!isPolicyOwnerVisible || isPODeclarationCompleted) &&
          isAdviserCompleted,
      },
    })
  }

  handleToggle = labelKey => openState => {
    this.setState({
      openSections: {
        [labelKey]: openState,
      },
    })
  }

  renderErrorMessage = errorMessage => <ErrorMessage>{errorMessage}</ErrorMessage>

  handlePODeclaration = () => {
    const { isPODeclarationConfirmed, poTermIdentifier } = this.state
    const {
      actions: { updateDeclarationPOConsents },
      targetedIndex,
    } = this.props
    if (isPODeclarationConfirmed) {
      this.setState({ isPODeclarationCompleted: true, poShowError: false }, () => {
        updateDeclarationPOConsents({
          isDeclarationPOChecked: true,
          targetedIndex,
          termNumber: poTermIdentifier,
        })
        this.handleRedirectionToReview()
        this.handleInCompleteAccordion()
      })
    } else {
      this.setState({ isPODeclarationCompleted: false, poShowError: true })
    }
  }

  handleTeleConsent = ({ value }) => {
    const {
      actions: { updateTeleCustomerTerm, changePersonalStatementCompletionStatus },
      targetedIndex,
    } = this.props
    const { isAdviserAuthorityCompleted, liTermIdentifier } = this.state
    this.setState({ isTeleTermsCompleted: value, showError: false }, () => {
      updateTeleCustomerTerm({
        isTeleTermConfirmed: value,
        targetedIndex,
        termNumber: liTermIdentifier,
      })
      const status = !!(isAdviserAuthorityCompleted && value)
      changePersonalStatementCompletionStatus(status)
    })
  }

  handleAdviserAuthorityConsent = ({ value }) => {
    const {
      actions: { updateAdviserAuthorityTerm, changePersonalStatementCompletionStatus },
      targetedIndex,
    } = this.props
    const updatedValue = value === 'yes'
    const { isTeleTermsCompleted } = this.state
    this.setState({ isAdviserAuthorityCompleted: updatedValue }, () => {
      updateAdviserAuthorityTerm({
        isAdviserAuthorityConfirm: updatedValue,
        targetedIndex,
      })
      changePersonalStatementCompletionStatus(isTeleTermsCompleted)
    })
  }

  handleAdviserAuthorityContinue = () => {
    const { hasMedicalAuthorityBeenCompleted } = this.props
    const { isAuthorisationCompleted, isTeleTermsCompleted } = this.state
    if ((isAuthorisationCompleted || isTeleTermsCompleted) && hasMedicalAuthorityBeenCompleted) {
      this.handleRedirectToCustomerStatement()
    }
  }

  closeRetryModal = () => {
    this.setState({ showRetryModal: false })
  }

  handleTeleContinue = () => {
    const { isTeleTermsCompleted, isAdviserAuthorityCompleted } = this.state
    if (isTeleTermsCompleted) {
      if (!isAdviserAuthorityCompleted) {
        this.setState(prevState => ({
          openSections: {
            ...prevState.openSections,
            adviserAuthority: true,
            customerTele: false,
          },
        }))
      } else {
        this.handleRedirectToCustomerStatement()
      }
    } else {
      this.setState({ showError: true })
    }
  }

  handleConfirmAdviser() {
    const { isAdviserConfirmed } = this.state
    this.setState({
      isAdviserConfirmed: !isAdviserConfirmed,
    })
  }

  handleRedirectToCustomerStatement() {
    const {
      actions: {
        updateQuoteStatus,
        saveQuote,
        changePersonalStatementCompletionStatus,
        retriggerFullUredata,
      },
      fullUREresult,
      activeQuote,
    } = this.props
    changePersonalStatementCompletionStatus(true)

    let quoteType = QUOTE_STATUS_SUBMITTED_TO_BANCS_STAGE
    let underwritingMethod = MYLINK
    let actionName = COMPLETE_MYLINK
    this.redirectToUrl = CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE

    if (fullUREresult && fullUREresult.calculatedUREstatus === CALCULATED_URE_TERM) {
      quoteType = QUOTE_STATUS_APPLICATION_STAGE
    }
    if (getScope() === SCOPE_TYPE_TELE) {
      actionName = COMPLETE_TELE
      underwritingMethod = TELE
      this.redirectToUrl = CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE
    }
    const quoteStatus = {
      actionName,
      quoteType,
      underwritingMethod,
    }
    this.setState({
      showRetryModal: false,
    })
    updateQuoteStatus(quoteStatus)
    const calculatedUREStatus = get(activeQuote, 'underwritingDetails.calculatedUREStatus')
    if (calculatedUREStatus) {
      saveQuote(err => {
        if (!err) {
          history.push(this.redirectToUrl)
        } else {
          this.setState({
            showRetryModal: true,
          })
        }
      })
    } else {
      // retrigger fetch ure to get calculatedUREresult, then save quote and redirect
      retriggerFullUredata()
    }
  }

  handleRedirectionToReview() {
    if (this.isDeclarationCompleted()) {
      const {
        isCustomerPortal,
        underwritingMethod,
        actions: { changePersonalStatementCompletionStatus },
      } = this.props
      let path = APPLICATION_REVIEW_PATH
      if (isCustomerPortal) {
        changePersonalStatementCompletionStatus(true)
        if (underwritingMethod === MYLINK) {
          path = CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE
        } else {
          path = CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE
        }
      }
      history.push(path)
    }
  }

  handleConfirmAuthorisation() {
    const { underwritingMethod } = this.props
    const { isAuthorisationConfirmed, isTeleTermsCompleted } = this.state
    this.setState({
      isAuthorisationConfirmed: !isAuthorisationConfirmed,
    })
    if (underwritingMethod === TELE) {
      this.setState({
        isTeleTermsCompleted: !isTeleTermsCompleted,
      })
    }
  }

  handleConfirmDeclarationPO() {
    const { isPODeclarationConfirmed } = this.state
    this.setState({
      isPODeclarationConfirmed: !isPODeclarationConfirmed,
    })
  }

  isDeclarationCompleted() {
    const {
      isPODeclarationCompleted,
      isAdviserCompleted,
      isAuthorisationRequired,
      isAuthorisationCompleted,
      isPolicyOwnerVisible,
    } = this.state

    const {
      hasMedicalAuthorityBeenCompleted,
      isMedicalAuthorityFormRequiredForAdviser,
      isTeleTermsConditions,
    } = this.props

    if (
      (isAuthorisationCompleted || !isAuthorisationRequired) &&
      (isPODeclarationCompleted || !isPolicyOwnerVisible) &&
      isAdviserCompleted &&
      (hasMedicalAuthorityBeenCompleted ||
        (!isMedicalAuthorityFormRequiredForAdviser && !isTeleTermsConditions))
    ) {
      return true
    }
    return false
  }

  handleAuthorisation() {
    const { isAuthorisationConfirmed, isAdviserAuthorityCompleted, liTermIdentifier } = this.state
    const { isTeleTermsConditions } = this.props
    const {
      actions: { updateDeclarationAuthorisationConsents },
      targetedIndex,
    } = this.props

    if (isAuthorisationConfirmed) {
      this.setState({ isAuthorisationCompleted: true, authoShowError: false }, () => {
        updateDeclarationAuthorisationConsents({
          isDeclarationAuthorizationChecked: true,
          targetedIndex,
          termNumber: liTermIdentifier,
        })

        if (isTeleTermsConditions) {
          if (!isAdviserAuthorityCompleted) {
            this.setState(prevState => ({
              openSections: {
                ...prevState.openSections,
                adviserAuthority: true,
                authorisation: false,
              },
            }))
          } else {
            this.handleRedirectToCustomerStatement()
          }
        } else {
          this.handleRedirectionToReview()
          this.handleInCompleteAccordion()
        }
      })
    } else {
      this.setState({ isAuthorisationCompleted: false, authoShowError: true })
    }
  }

  handleAdviser() {
    const { isAdviserConfirmed, adviserTermIdentifier } = this.state
    const {
      actions: { updateAdviserDeclarationConsents },
      targetedIndex,
    } = this.props
    if (isAdviserConfirmed) {
      this.setState({ isAdviserCompleted: true, showError: false }, () => {
        updateAdviserDeclarationConsents({
          isAdviserDeclarationChecked: true,
          targetedIndex,
          termNumber: adviserTermIdentifier,
        })
        this.handleRedirectionToReview()
        this.handleInCompleteAccordion()
      })
    } else {
      this.setState({ isAdviserCompleted: false, showError: true })
    }
  }

  resetConsents() {
    const {
      actions: { resetDeclarationConsents },
      targetedIndex,
    } = this.props
    resetDeclarationConsents({ targetedIndex })
  }

  // Common for Advisor and customer portal
  applyAdviserTerms() {
    const { fields, isMedicalAuthorityFormRequiredForAdviser } = this.props
    const {
      applicationDeclarationPageTitle,
      applicationDeclarationSubTitle,
      applicationDeclarationPageBackLink,
    } = fields
    const { isAuthorisationRequired, isPolicyOwnerVisible } = this.state

    return (
      <Fragment>
        <Header
          backClickHandler={this.backClickHandler}
          heading={applicationDeclarationPageTitle}
          subHeading={applicationDeclarationSubTitle}
          showBackIcon
          iconText={applicationDeclarationPageBackLink}
        />
        <PageWrap>
          <MainWrapper>
            {isAuthorisationRequired && this.renderAuthorisation()}
            {isMedicalAuthorityFormRequiredForAdviser && this.renderMedicalAuthority()}
            {isPolicyOwnerVisible && this.renderPODeclaration()}
            {this.renderAdviser()}
          </MainWrapper>
        </PageWrap>
      </Fragment>
    )
  }

  applyCustomerTeleTerms() {
    const { fields, underwritingMethod } = this.props
    const {
      customerTeleapplicationDeclarationPageTitle,
      customerTeleapplicationDeclarationSubTitle,
    } = fields

    return (
      <Fragment>
        <TeleLayout
          fields={fields}
          pageTitle={customerTeleapplicationDeclarationPageTitle}
          pageSubTitle={customerTeleapplicationDeclarationSubTitle}
          fillChildBackground={false}
        >
          <MainWrapper>
            {underwritingMethod === TELE && this.renderCustomerTeleTerms()}
            {underwritingMethod === MYLINK && this.renderAuthorisation()}
            {this.renderMedicalAuthority()}
            {/* Adviser Authority Consents for TELE ans MYLINK flow  */}
            {this.renderAdviserAuthority()}
          </MainWrapper>
        </TeleLayout>
      </Fragment>
    )
  }

  renderMedicalAuthority() {
    const { fields } = this.props
    const {
      openSections: { medicalAuthority },
      lifeInsuredTitle,
    } = this.state
    return (
      <MedicalAuthorityDeclaration
        fields={fields}
        isOpen={medicalAuthority}
        handleToggle={this.handleToggle}
        handleInCompleteAccordion={this.handleInCompleteAccordion}
        lifeInsuredName={lifeInsuredTitle}
      />
    )
  }

  renderCustomerTeleTerms() {
    const { fields, isPaddingNeeded } = this.props

    const {
      isTeleTermsCompleted,
      lifeInsuredTitle,
      openSections: { customerTele },
      showError,
      saveQuoteInProgress,
    } = this.state

    const {
      customerTeleTermsDeclarationTitle,
      customerTeleTermsDeclarationDesc,
      applicationDeclarationAuthorisationError,
    } = fields

    const applicationDecHeading = `${customerTeleTermsDeclarationTitle.value} ${lifeInsuredTitle}`
    return (
      <Fragment>
        <ApplicationDeclarationAccordion
          label={applicationDecHeading}
          fields={fields}
          checked={isTeleTermsCompleted}
          isOpen={customerTele}
          continueHandler={this.handleTeleContinue}
          toggleListener={this.handleToggle('customerTele')}
          isPaddingNeeded={isPaddingNeeded}
          inProgress={saveQuoteInProgress}
        >
          <FitnessTrackerContent>
            <Checkbox
              htmlFor="confirmTeleTerms"
              text={renderTextField(customerTeleTermsDeclarationDesc, true)}
              name="confirmTeleTerms"
              onChangeHandler={e => this.handleTeleConsent(e)}
              checked={isTeleTermsCompleted}
            />
            {showError &&
              this.renderErrorMessage(renderTextField(applicationDeclarationAuthorisationError))}
          </FitnessTrackerContent>
        </ApplicationDeclarationAccordion>
        {this.renderRetryModal()}
      </Fragment>
    )
  }

  renderPODeclaration() {
    const { fields, isPaddingNeeded, underwritingMethod } = this.props
    const {
      policyOwnerTitle,
      policyOwnerContent,
      isPODeclarationConfirmed,
      isPODeclarationCompleted,
      poShowError,
      openSections: { PODeclaration },
      saveQuoteInProgress,
    } = this.state
    const {
      policyOwnDeclarationHeading,
      applicationDeclarationPOLabelFacetoFace,
      applicationDeclarationPOLabelPhone,
      applicationDeclarationPOLabelMyLink,
      applicationDeclarationPOLabelTele,
      applicationDeclarationAuthorisationError,
    } = fields

    const poDeclarationHeading = `${policyOwnDeclarationHeading.value} ${policyOwnerTitle}`
    let poDeclarationLabel

    if (underwritingMethod === ADV_F2F) {
      poDeclarationLabel = applicationDeclarationPOLabelFacetoFace
    } else if (underwritingMethod === ADV_PHN) {
      poDeclarationLabel = applicationDeclarationPOLabelPhone
    } else if (underwritingMethod === MYLINK) {
      poDeclarationLabel = applicationDeclarationPOLabelMyLink
    } else if (underwritingMethod === TELE) {
      poDeclarationLabel = applicationDeclarationPOLabelTele
    }

    return (
      <ApplicationDeclarationAccordion
        label={poDeclarationHeading}
        fields={fields}
        checked={isPODeclarationCompleted}
        isOpen={PODeclaration}
        continueHandler={() => this.handlePODeclaration()}
        toggleListener={this.handleToggle('PODeclaration')}
        isPaddingNeeded={isPaddingNeeded}
        inProgress={saveQuoteInProgress}
      >
        <FitnessTrackerContent>
          {renderTextField(
            {
              value: policyOwnerContent,
            },
            true
          )}
        </FitnessTrackerContent>
        <Checkbox
          htmlFor="declarationPO"
          text={renderTextField(poDeclarationLabel, true)}
          name="declarationPO"
          onChangeHandler={() => this.handleConfirmDeclarationPO()}
          checked={isPODeclarationConfirmed}
          value={0}
          dataLocator={LOCATOR_DECLARATIONPO_CHECKBOX}
        />
        {poShowError &&
          this.renderErrorMessage(renderTextField(applicationDeclarationAuthorisationError))}
      </ApplicationDeclarationAccordion>
    )
  }

  // render life insured
  renderAuthorisation() {
    const { fields, isPaddingNeeded, underwritingMethod } = this.props
    const {
      lifeInsuredTitle,
      lifeInsuredContent,
      isAuthorisationConfirmed,
      isAuthorisationCompleted,
      authoShowError,
      openSections: { authorisation },
      saveQuoteInProgress,
      isLIisThePolicyOwner,
    } = this.state

    const {
      applicationDeclarationAuthorisationHeading,
      applicationDeclarationAuthorisationLabelFacetoFace,
      applicationDeclarationAuthorisationLIandPOLabelFacetoFace,
      applicationDeclarationAuthorisationLabelPhone,
      applicationDeclarationAuthorisationPOLabelPhone,
      applicationDeclarationAuthorisationLILabelTele,
      applicationDeclarationAuthorisationLIandPOLabelMyLink,
      applicationDeclarationAuthorisationLILabelMyLink,
      applicationDeclarationAuthorisationLIandPOLabelTele,
      applicationDeclarationAuthorisationError,
    } = fields

    let authTermsLabel

    // get checkbox text value on basic for - term and underwriting method
    if (underwritingMethod === ADV_F2F) {
      authTermsLabel = isLIisThePolicyOwner
        ? applicationDeclarationAuthorisationLIandPOLabelFacetoFace
        : applicationDeclarationAuthorisationLabelFacetoFace
    }
    if (underwritingMethod === ADV_PHN) {
      authTermsLabel = isLIisThePolicyOwner
        ? applicationDeclarationAuthorisationPOLabelPhone
        : applicationDeclarationAuthorisationLabelPhone
    }
    if (underwritingMethod === MYLINK) {
      authTermsLabel = isLIisThePolicyOwner
        ? applicationDeclarationAuthorisationLIandPOLabelMyLink
        : applicationDeclarationAuthorisationLILabelMyLink
    }
    if (underwritingMethod === TELE) {
      authTermsLabel = isLIisThePolicyOwner
        ? applicationDeclarationAuthorisationLIandPOLabelTele
        : applicationDeclarationAuthorisationLILabelTele
    }
    const applicationDecHeading = `${applicationDeclarationAuthorisationHeading.value} ${lifeInsuredTitle}`

    return (
      <ApplicationDeclarationAccordion
        label={applicationDecHeading}
        fields={fields}
        checked={isAuthorisationCompleted}
        isOpen={authorisation}
        continueHandler={() => this.handleAuthorisation()}
        toggleListener={this.handleToggle('authorisation')}
        isPaddingNeeded={isPaddingNeeded}
        inProgress={saveQuoteInProgress}
      >
        <FitnessTrackerContent>
          {renderTextField(
            {
              value: lifeInsuredContent,
            },
            true
          )}
        </FitnessTrackerContent>
        <Checkbox
          htmlFor="confirmAuthorisation"
          text={renderTextField(authTermsLabel, true)}
          name="confirmAuthorisation"
          onChangeHandler={() => this.handleConfirmAuthorisation()}
          checked={isAuthorisationConfirmed}
          value={0}
          dataLocator={LOCATOR_AUTHORISATION_CHECKBOX}
        />
        {authoShowError &&
          this.renderErrorMessage(renderTextField(applicationDeclarationAuthorisationError))}
      </ApplicationDeclarationAccordion>
    )
  }

  renderAdviser() {
    const { fields, isPaddingNeeded } = this.props
    const {
      adviserContent,
      isAdviserConfirmed,
      isAdviserCompleted,
      showError,
      openSections: { adviser },
      saveQuoteInProgress,
    } = this.state

    const {
      applicationDeclarationAdviserHeading,
      applicationDeclarationAdviserLabel,
      applicationDeclarationAuthorisationError,
    } = fields

    return (
      <ApplicationDeclarationAccordion
        label={renderTextField(applicationDeclarationAdviserHeading)}
        fields={fields}
        checked={isAdviserCompleted}
        isOpen={adviser}
        continueHandler={() => this.handleAdviser()}
        toggleListener={this.handleToggle('adviser')}
        isPaddingNeeded={isPaddingNeeded}
        inProgress={saveQuoteInProgress}
      >
        <FitnessTrackerContent>
          {renderTextField({ value: adviserContent }, true)}
        </FitnessTrackerContent>
        <Checkbox
          htmlFor="confirmAuthorisation"
          text={renderTextField(applicationDeclarationAdviserLabel, true)}
          name="confirmAuthorisation"
          onChangeHandler={() => this.handleConfirmAdviser()}
          checked={isAdviserConfirmed}
          dataLocator={LOCATOR_ADVISER_CHECKBOX}
        />
        {showError &&
          this.renderErrorMessage(renderTextField(applicationDeclarationAuthorisationError))}
      </ApplicationDeclarationAccordion>
    )
  }

  renderRetryModal() {
    const { showRetryModal } = this.state
    const {
      fields: { retrySubmitModalHeading, retrySubmitModalContent, retrySubmitModalButtonText },
    } = this.props
    return (
      <Modal
        isOpen={showRetryModal}
        onClose={this.closeRetryModal}
        title={renderTextField(retrySubmitModalHeading)}
      >
        <ModalContent>{renderTextField(retrySubmitModalContent, true)}</ModalContent>
        <Button type="secondary" onClick={this.handleTeleContinue}>
          {renderTextField(retrySubmitModalButtonText)}
        </Button>
      </Modal>
    )
  }

  renderAdviserAuthority() {
    const { fields, isPaddingNeeded } = this.props
    const {
      isAdviserAuthorityCompleted,
      isAdviserAuthoritySelected,
      openSections: { adviserAuthority },
      saveQuoteInProgress,
    } = this.state
    const {
      applicationDeclarationPageAdviserAuthorityLabel,
      applicationDeclarationPageAdviserAuthorityDesc,
      applicationDeclarationPageAdviserAuthorityCheckboxText,
    } = fields

    // We want to use an empty string here if there is no consent
    let authorityConsentedValue
    if (typeof isAdviserAuthorityCompleted === 'undefined') {
      authorityConsentedValue = ''
    } else {
      authorityConsentedValue = isAdviserAuthorityCompleted ? 'yes' : 'no'
    }

    return (
      <ApplicationDeclarationAccordion
        label={renderTextField(applicationDeclarationPageAdviserAuthorityLabel, true)}
        fields={fields}
        checked={isAdviserAuthoritySelected}
        isOpen={adviserAuthority}
        continueHandler={() => this.handleAdviserAuthorityContinue()}
        toggleListener={this.handleToggle('adviserAuthority')}
        isPaddingNeeded={isPaddingNeeded}
        inProgress={saveQuoteInProgress}
        disableButton={typeof isAdviserAuthorityCompleted === 'undefined'}
      >
        <FitnessTrackerContent>
          {renderTextField(applicationDeclarationPageAdviserAuthorityDesc, true)}
        </FitnessTrackerContent>
        <ToggleGroupContainer>
          <ToggleGroup
            htmlFor="consentAnswersSharing"
            name="consentAnswersSharing"
            handleChange={event => this.handleAdviserAuthorityConsent(event)}
            value={authorityConsentedValue}
            label={renderTextField(applicationDeclarationPageAdviserAuthorityCheckboxText, true)}
            variant="tab"
            error={false}
            options={[
              {
                label: 'Yes',
                value: 'yes',
              },
              {
                label: 'No',
                value: 'no',
              },
            ]}
          />
        </ToggleGroupContainer>
      </ApplicationDeclarationAccordion>
    )
  }

  render() {
    const { isTeleTermsConditions, isFetching } = this.props
    if (isTeleTermsConditions) {
      return (
        <WithLoader isLoading={isFetching} overlay>
          {this.applyCustomerTeleTerms()}
        </WithLoader>
      )
    }
    return (
      <WithLoader isLoading={isFetching} overlay>
        {this.applyAdviserTerms()}
      </WithLoader>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = state => {
  const { createQuote, ure, masterList, config } = state
  const targetedQuoteIndex = createQuote.activeIndex
  const relationships = pathOr(
    [],
    `quotes[${targetedQuoteIndex}].policyStructure[0].relationships`,
    createQuote
  )
  const lifeInsuredDetails = relationships.filter(
    relationship => relationship.role.indexOf(POLICY_RELATIONSHIPS_LIFEASSURED) > -1
  )

  const underwritingMethod = ure.underwritingMethod
    ? ure.underwritingMethod
    : pathOr(
        '',
        `quotes[${targetedQuoteIndex}].underwritingDetails.underwritingMethod`,
        createQuote
      )

  const isMedicalAuthorityFormRequiredForAdviser = [ADV_F2F, ADV_PHN].includes(underwritingMethod)
  return {
    existingConsents: arrayToObject(createQuote.quotes[targetedQuoteIndex].consents, 'name'),
    existingCovers: createQuote.quotes[targetedQuoteIndex].existingCovers,
    targetedIndex: targetedQuoteIndex,
    quoteAction: createQuote.action,
    activeQuote: createQuote.quotes[targetedQuoteIndex],
    policyStructure: createQuote.quotes[targetedQuoteIndex].policyStructure,
    quotes: createQuote.quotes[targetedQuoteIndex],
    lifeInsuredDetails: lifeInsuredDetails.length ? lifeInsuredDetails[0] : null,
    underwritingMethod,
    fullUREresult: ure.fullUREresult,
    isRefetchURE: ure.isRefetchURE,
    masterData: masterList.data,
    config,
    isFetching: createQuote.isFetchingData,
    saveQuoteError: createQuote.saveQuoteError,
    isFetchingURE: ure.isLoading,
    hasMedicalAuthorityBeenCompleted: getHasMedicalAuthorityBeenCompleted(state),
    isMedicalAuthorityFormRequiredForAdviser,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDeclaration)
