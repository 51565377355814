import { Variables } from '@mlcl-digital/mlcl-design'

import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space, mediaQueries, fontWeights } = Variables as VariablesType

const styles = {
  container: ({
    isAssociatedBenefit,
    isFirstChild,
  }: {
    isAssociatedBenefit: boolean
    isFirstChild: boolean
  }) => ({
    ...(!isAssociatedBenefit &&
      !isFirstChild && { borderTop: `1px solid ${colours.lightestGrey}` }),
    [mediaQueries.md]: {
      ...(isAssociatedBenefit && { paddingTop: 0, paddingBottom: space(2) }),
      ...(isFirstChild && { borderTop: `1px solid ${colours.lightestGrey}` }),
    },
  }),
  listItemWithTooltip: {
    display: 'flex',
    '> *': {
      padding: `0 ${space(0.5, true)}`,
    },
    'span:first-of-type': {
      paddingLeft: 0,
      fontWeight: fontWeights.semiBold,
    },
  },
  tooltipContent: {
    maxWidth: space(40, true),
  },
  additonalInfoContainer: {
    '> div': {
      marginTop: space(3, true),
      '&:first-of-type': {
        marginTop: space(1, true),
      },
      '&:last-of-type': {
        marginBottom: space(3, true),
      },
      '> p': {
        marginTop: space(3, true),
      },
    },
  },
  connectedBenefitCTA: {
    display: 'block',
    marginTop: space(4),
  },
}

export default styles
