// @FIXME: need to determine how the confirm functions are used
/* eslint-disable no-alert */
// @FIXME: sort method order
/* eslint-disable react/sort-comp */
// @flow
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { pathOr } from 'lodash/fp'
import moment from 'moment'
import get from 'lodash/get'
import {
  Loader,
  PageWrap,
  Checkbox,
  Button,
  Heading,
  RadioGroup,
  Radio,
} from '@mlcl-digital/mlcl-design'
import StatefulAccordion from '@mlcl-digital/mlcl-design/lib/base/StatefulAccordion'
import StatefulAccordionItem from '@mlcl-digital/mlcl-design/lib/base/StatefulAccordionItem'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'

// actions
import { actionCreators } from '../../../actions'

// selectors
import { getExistingClientBancsCustomerNo } from '../../../selectors/createQuote'

// molecules
import Header from '../../molecules/PageHeader'
import Modal from '../../molecules/Modal'
import WithLoader from '../../molecules/WithLoader'

// components
import UpdateMethod from './components/UpdateMethod'
import PersonalStatementModal from '../PersonalStatementModal'
import FollowUpContactForm from './components/FollowUpContactForm'
import ExistingCover from './components/ExistingCover'
import EnquiryIdExpiredModal from '../EnquiryIdExpiredModal'

// data locators
import { LOCATOR_DOD_DISCLOSURE, LOCATOR_DOD_PRIVACY } from './personalStatement.locators'

// schemas
import Schema, {
  FORM_ID as UNDERWRITING_FORM_ID,
} from './components/FollowUpContactForm/followUpContactForm.schema'
import schema, { FORM_ID, MLC_INSURANCE } from './components/ExistingCover/existingCover.schema'
// schema.
import { selectTimeOptions } from '../../sitecore/customer-portal/PersonalStatementMethod/personalStatementMethod.schema'

// utils
import { getSerializedFormState } from '../../../utils/formUtils'
import { getFullUrePayload } from '../../../utils/ureUtils'
import { renderTextField, reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'
import { arrayToObject, isFeatureEnabledForAP } from '../../../utils/commonUtils'
import { isIPCover } from '../../../utils/quoteUtils'

// styles
import styles from './personalStatement.styles'
import { space } from '../../../styles'

// constants
import { NEW_BUS } from '../../../constants/personalStatement'
import { NAVIGATION_APPLICATION_OVERVIEW } from '../../../constants/navigation'
import { BIG_T_BOOK } from '../../../constants/workItems'
import {
  MYLINK,
  TELE,
  APPLICATION_STAGE_STEP,
  APPLICATION_PATH,
  APPLICATION_TYPE_REPLACE,
  SAVE_DRAFT_APPLICATION,
} from '../../../constants/application'

const MainWrapper = styled('div')(styles.wrapper)
const PrivacyPolicy = styled('div')(styles.policy)
const DesDistribOb = styled('div')(styles.dado)
const ErrorMessage = styled('p')(styles.errorMessage)
const LoadingSection = styled('div')(styles.loadingSection)
export const ContinueBtn = styled(Button)(styles.continueBtn)
export const InitialContactDayWrap = styled('div')(styles.initialContactDaysWrap)
export const InitialContactTimeWrap = styled('div')(styles.intialContatcTimeWrap)
const TextareaTMD = styled('textarea')(styles.textAreaTMD)
const AccordionContentContainer = styled('div')(styles.accordionContentContainer)
const TelephoneHeader = styled('div')(styles.telephoneHeader)

type ExistingConsentsType = {
  DUTY_OF_DISCLOSURE?: {
    name: 'DUTY_OF_DISCLOSURE',
    value: string,
  },
  DUTY_OF_DISCLOSURE_PRIVACY?: {
    name: 'DUTY_OF_DISCLOSURE_PRIVACY',
    value: string,
  },
  FAST_TRACKING_MEDICAL_REQTS?: {
    name: 'FAST_TRACKING_MEDICAL_REQTS',
    value: string,
  },
  FAST_TRACKING_FOLLOWUP_INFO?: {
    name: 'FAST_TRACKING_FOLLOWUP_INFO',
    value: string,
  },
  CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS?: {
    name: 'CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS',
    value: string,
  },
  REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD?: {
    name: 'REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD',
    value: string,
  },
  TYPE_OF_ADVICE_PERSONAL_OR_GENERAL?: {
    name: 'TYPE_OF_ADVICE_PERSONAL_OR_GENERAL',
    value: string,
  },
  MLC_ON_TRACK_DECLINED?: {
    name: 'MLC_ON_TRACK_DECLINED',
    value: string,
  },
}

type PersonalStatementProps = {
  fields: Object<Object>,
  actions: {
    updateUnderwritingOptionsConsents: Function,
    updateDutyOfDisclosureConsents: Function,
    changeNavigationType: Function,
    fetchQuoteCollection: Function,
    updateEnquiryId: Function,
    updateApplicationStage: Function,
    ureInitalData: Function,
  },
  /** the consents array from the store. */
  existingConsents: ExistingConsentsType,
  targetedIndex: number,
  quoteAction: string,
  existingCovers: Object,
  existingCoverForm: Object,
  activeQuote: Object,
  ure: Object,
  underWritingForm: Object,
  existingClient: String,
  createQuote: Object,
  config: Object,
  controlList: Array,
  sidebar: Object,
}

export class PersonalStatement extends Component<PersonalStatementProps> {
  constructor(props) {
    super(props)
    const { existingConsents, quoteAction, fields, activeQuote, existingCovers = {} } = this.props
    const brandInfo = pathOr('', 'policyStructure[0].fitnessTracker', activeQuote)
    const coverInfo = pathOr(false, 'consent.name', existingCovers)
    const isMlcOnTrack =
      !!activeQuote.policyStructure &&
      activeQuote.policyStructure.some(policyStructure => policyStructure.mlcOnTrack)

    const isDodCompleted = false
    const isUnderwritingCompleted = !!(
      existingConsents.FAST_TRACKING_MEDICAL_REQTS && existingConsents.FAST_TRACKING_FOLLOWUP_INFO
    )
    const isTMDCompleted = !!(
      existingConsents.CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS &&
      existingConsents.TYPE_OF_ADVICE_PERSONAL_OR_GENERAL &&
      existingConsents.REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD !== ''
    )
    const isDodRequired = quoteAction !== MYLINK
    const isMlcOnTrackCompleted = !!(
      existingConsents.MLC_ON_TRACK_DECLINED &&
      existingConsents.MLC_ON_TRACK_DECLINED.value &&
      brandInfo !== ''
    )
    const isExistingCoverCompleted = !!coverInfo
    const isUnderwritingLastAccordion = !isMlcOnTrack && !isDodRequired
    const isMlcOnTrackLastAccordion = isMlcOnTrack && !isDodRequired
    const isDODLastAccordion = isMlcOnTrack && isDodRequired
    this.state = {
      isDodCompleted,
      isDisclosureConfirmed: false,
      isPolicyConfirmed: false,
      showError: false,
      flagForTMDTextAreaError: false,
      isUnderwritingCompleted,
      isTMDCompleted,
      isExistingCoverCompleted,
      isTrackMedicalAgreed: this.getInitialConsentState(
        existingConsents.FAST_TRACKING_MEDICAL_REQTS,
        'FAST_TRACKING_MEDICAL_REQTS'
      ),
      isTrackFollowupAgreed: this.getInitialConsentState(
        existingConsents.FAST_TRACKING_FOLLOWUP_INFO,
        'FAST_TRACKING_FOLLOWUP_INFO'
      ),
      meetsTMDRequirementRadio: this.getInitialConsentState(
        existingConsents.CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS,
        'CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS'
      ),
      isPersonalOrGeneralAdviceForTMDRadio: this.getInitialAdviceState(
        existingConsents.TYPE_OF_ADVICE_PERSONAL_OR_GENERAL,
        'TYPE_OF_ADVICE_PERSONAL_OR_GENERAL'
      ),
      notMeetsTMDRequirementReason: this.getInitialTMDReasonState(
        existingConsents.REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD,
        'REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD'
      ),
      isLifeInsured: false,
      isDodRequired,
      isOpenPersonalStatementModal: false,
      existingCoverSchema: schema(fields),
      showExistingCoverAnyPolicyError: false,
      isMlcOnTrackCompleted,
      openSections: {
        existingcover: !isExistingCoverCompleted,
        underwriting:
          (isExistingCoverCompleted && !isUnderwritingCompleted) ||
          (isUnderwritingLastAccordion && isUnderwritingCompleted),
        designAndDistributionObligation:
          isUnderwritingCompleted && isExistingCoverCompleted && !isTMDCompleted,
        mlcOnTrack:
          (isUnderwritingCompleted && isExistingCoverCompleted && !isMlcOnTrackCompleted) ||
          (isMlcOnTrackLastAccordion && isMlcOnTrackCompleted),
        dod:
          (isUnderwritingCompleted &&
            isExistingCoverCompleted &&
            (!isMlcOnTrack || isMlcOnTrackCompleted) &&
            !isDodCompleted) ||
          isDODLastAccordion,
      },
      disableContinue: false,
      isShowOverlayLoader: false,
      isCustomerRequireTele: false,
      callBeforeTime: '',
      callAfterTime: '',
      selectedDay: [],
      isCustomerRequireTeleComplete: true,
    }
  }

  componentDidMount() {
    const {
      actions: {
        updateEnquiryId,
        changeNavigationType,
        updateApplicationStage,
        ureInitalData,
        updateDutyOfDisclosureConsents,
      },
      targetedIndex,
      ure: { initialData },
    } = this.props
    // init ure data
    if (isEmpty(initialData)) ureInitalData()
    changeNavigationType(NAVIGATION_APPLICATION_OVERVIEW)
    this.handleUserRole()
    updateDutyOfDisclosureConsents({
      isDutyOfDisclosureChecked: false,
      isDutyOfDisclosurePrivacyChecked: false,
      targetedIndex,
    })

    updateApplicationStage(2, {
      stage2: APPLICATION_STAGE_STEP.partiallyComplete,
    })

    updateEnquiryId({
      isPreassessment: false,
      payload: getFullUrePayload(),
    })
  }

  componentWillReceiveProps(nextProps) {
    const { existingConsents, quoteAction } = this.props
    if (!isEqual(existingConsents, nextProps.existingConsents)) {
      this.handleStateChanges(nextProps)
    }

    // clear tele info when underwriting method changes
    if (quoteAction !== nextProps.quoteAction) {
      this.setState({
        isCustomerRequireTele: false,
        callBeforeTime: '',
        callAfterTime: '',
        selectedDay: [],
      })
    }
  }

  getTeleDetail() {
    const { callBeforeTime, callAfterTime, selectedDay } = this.state

    let selectedTime = ''
    if (callBeforeTime && callAfterTime) {
      selectedTime = selectTimeOptions.find(
        slot => slot.callAfterTime === callAfterTime && slot.callBeforeTime === callBeforeTime
      ).value
    }

    return [selectedDay, selectedTime]
  }

  renderErrorMessage = errorMessage => <ErrorMessage>{errorMessage}</ErrorMessage>

  getInitialConsentState = value => {
    if (!value) return undefined
    if (value.value === true) return 'yes'
    return 'no'
  }

  getInitialAdviceState = value => {
    if (!value || value.value === true) return 'personal'
    return 'general'
  }

  getInitialTMDReasonState = value => {
    if (!value) return ''
    return value.textResponse
  }

  backClickHandler = () => {
    const { fields } = this.props
    const { isDisclosureConfirmed, isUnderwritingCompleted, isExistingCoverCompleted } = this.state
    const { personalStatementIncompleteFormSubmissionError } = reduceAuthorableFields(fields)
    if (!isDisclosureConfirmed || !isUnderwritingCompleted || !isExistingCoverCompleted) {
      if (window.confirm(personalStatementIncompleteFormSubmissionError)) {
        history.replace(APPLICATION_PATH)
      }
    } else {
      history.replace(APPLICATION_PATH)
    }
  }

  handleTrackMedicalChange = value => {
    this.setState({
      isTrackMedicalAgreed: value,
    })
  }

  handleTrackFollowupChange = value => {
    this.setState({
      isTrackFollowupAgreed: value,
    })
  }

  handleTMDRequirementRadioChange = value => {
    this.setState({
      meetsTMDRequirementRadio: value,
      // We want to reset this to false each toggle due to textarea edge case
      isTMDCompleted: false,
    })
  }

  handleTMDAdviceChange = value => {
    this.setState({
      isPersonalOrGeneralAdviceForTMDRadio: value,
    })
  }

  handleToggle = labelKey => openState => {
    this.setState({
      openSections: {
        [labelKey]: openState,
      },
    })
  }

  handlePersonalStatementOpenModal = () => {
    this.setState({ isOpenPersonalStatementModal: true })
  }

  handlePersonalStatementCloseModal = actionData => {
    const { quoteAction } = this.props
    const updateAction = actionData || quoteAction
    this.setState({
      isOpenPersonalStatementModal: false,
      isDodRequired: updateAction !== MYLINK,
    })
  }

  handleInCompleteAccordion = () => {
    const { activeQuote } = this.props
    const isMlcOnTrack =
      !!activeQuote.policyStructure &&
      activeQuote.policyStructure.some(policyStructure => policyStructure.mlcOnTrack)
    const {
      isLifeInsured,
      isDodCompleted,
      isUnderwritingCompleted,
      isExistingCoverCompleted,
      isMlcOnTrackCompleted,
      isTMDCompleted,
    } = this.state
    this.setState({
      openSections: {
        existingcover: !isExistingCoverCompleted,
        underwriting: isExistingCoverCompleted && !isUnderwritingCompleted,
        mlcOnTrack: isUnderwritingCompleted && isExistingCoverCompleted && !isMlcOnTrackCompleted,
        designAndDistributionObligation:
          isUnderwritingCompleted && isExistingCoverCompleted && !isTMDCompleted,
        dod:
          isLifeInsured ||
          (isUnderwritingCompleted &&
            isExistingCoverCompleted &&
            (!isMlcOnTrack || isMlcOnTrackCompleted) &&
            isTMDCompleted &&
            !isDodCompleted),
      },
    })
  }

  updateUnderWritingOptions = () => {
    // check if its for updateing or for saving
    const { isTrackMedicalAgreed, isTrackFollowupAgreed, isUnderwritingCompleted } = this.state
    const {
      actions: { updateUnderwritingOptionsConsents },
      fields,
    } = this.props
    const {
      personalStatementFastTrackingMedicalContent,
      personalStatementFastTrackingFollowupContent,
    } = reduceAuthorableFields(fields)
    if (isUnderwritingCompleted) {
      updateUnderwritingOptionsConsents({
        isTrackMedicalAgreed: isTrackMedicalAgreed === 'yes',
        isTrackFollowupAgreed: isTrackFollowupAgreed === 'yes',
        fastTrackingMedicalContent: personalStatementFastTrackingMedicalContent,
        fastTrackingFollowupContent: personalStatementFastTrackingFollowupContent,
      })
      this.handleInCompleteAccordion()
    } else {
      updateUnderwritingOptionsConsents({
        isTrackMedicalAgreed: isTrackMedicalAgreed === 'yes',
        isTrackFollowupAgreed: isTrackFollowupAgreed === 'yes',
        fastTrackingMedicalContent: personalStatementFastTrackingMedicalContent,
        fastTrackingFollowupContent: personalStatementFastTrackingFollowupContent,
      })
      this.setState({ isUnderwritingCompleted: true, showError: false }, () => {
        this.handleInCompleteAccordion()
      })
    }
  }

  handleUnderwritingOptions(enableAdvisorSelectTeleUW) {
    const { isTrackMedicalAgreed, isTrackFollowupAgreed, isCustomerRequireTele, isDodRequired } =
      this.state
    const {
      actions: {
        formSubmit,
        deleteForms,
        updateUnderwritingFollowUpTimings,
        updateIsTeleFromAdviser,
        updateWorkTypeHistory,
        updateQuoteStatus,
        deleteWorkTypeHistory,
      },
      underWritingForm,
      createQuote,
      config,
      fields,
    } = this.props
    if (isTrackMedicalAgreed && isTrackFollowupAgreed) {
      if (isTrackFollowupAgreed === 'yes') {
        const {
          fields: { alternateContactOptions },
        } = underWritingForm
        const SCHEMA = Schema(reduceAuthorableFields(fields), alternateContactOptions.value)
        formSubmit(UNDERWRITING_FORM_ID, SCHEMA, (data, form) => {
          if (form.isValid) {
            updateUnderwritingFollowUpTimings(data)
            this.updateUnderWritingOptions()
            deleteForms([UNDERWRITING_FORM_ID])
          }
        })
      } else {
        updateUnderwritingFollowUpTimings({})
        deleteForms([UNDERWRITING_FORM_ID])
        this.updateUnderWritingOptions()
      }
      this.setState({ isCustomerRequireTeleComplete: true })
    } else {
      this.setState({ isUnderwritingCompleted: false, showError: true })
    }
    updateIsTeleFromAdviser(isCustomerRequireTele)
    if (isCustomerRequireTele) {
      updateWorkTypeHistory({
        type: BIG_T_BOOK,
        status: BIG_T_BOOK,
        preferredDays: ['Monday'],
        preferredCallAfterTime: '08:30',
        preferredCallBeforeTime: '17:30',
        dateCreated: moment().format('YYYY-MM-DD'),
        teleUWURL: `${config.MLCL_CUSTOMER_APP_PATH}/teleunderwriter?quoteCollectionId=${createQuote.quoteCollectionId}`,
      })
      updateQuoteStatus({
        underwritingMethod: TELE,
      })
    } else if (enableAdvisorSelectTeleUW) {
      deleteWorkTypeHistory()
      if (!isDodRequired) {
        updateQuoteStatus({
          underwritingMethod: MYLINK,
        })
      }
    }
  }

  resetExistingCover = () => {
    this.setState({ showExistingCoverAnyPolicyError: false })
  }

  handleExistingCover = () => {
    const { actions, existingCoverForm, existingClient, activeQuote } = this.props
    const { existingCovers: { existingInsurances = [] } = {} } = activeQuote
    const { existingCoverSchema } = this.state
    const {
      formSubmit,
      updateExisitingCover,
      updateByPushToExisitingCover,
      updateApplicationType,
    } = actions
    const {
      fields: {
        consent: { value: consentValue },
      },
    } = existingCoverForm

    const existingClientCovers =
      activeQuote.existingCovers && activeQuote.existingCovers.existingInsurances
    const isInforce = existingClientCovers && existingClientCovers.find(policy => policy.isFixed)
    if (
      !isInforce &&
      ((!consentValue.value && !existingClient) ||
        consentValue.value === undefined ||
        consentValue.value === '')
    ) {
      this.setState({ showExistingCoverAnyPolicyError: true })
    } else {
      this.setState({ showExistingCoverAnyPolicyError: false })
      formSubmit(FORM_ID, existingCoverSchema, data => {
        const updatedExistingPolicies =
          data.existingInsurances &&
          (data.existingInsurances.length > 0
            ? data.existingInsurances.reduce((updatedExistingPolicy, policy, policyIndex) => {
                let updatedPolicy = {
                  ...policy,
                  benefits: { ...policy.benefits },
                }
                updatedPolicy.benefits.value =
                  updatedPolicy.benefits.value &&
                  updatedPolicy.benefits.value.reduce((updatedBenefits, benefit, benefitIndex) => {
                    let updatedBenefit = { ...benefit }
                    const serializedBenefits = getSerializedFormState(updatedBenefit)
                    const { allowableFeatures = [] } = serializedBenefits
                    const formattedDate = moment(serializedBenefits.startdate, 'MM/YY')
                    const isIncomeAssureBenefit = isIPCover({
                      type: serializedBenefits.benefitCode,
                    })
                    updatedBenefit = {
                      ...serializedBenefits,
                      benefitInstanceNo: benefitIndex + 1,
                      replacingThisCover: serializedBenefits.replacingThisCover === 'yes',
                      allowableFeatures: allowableFeatures.map(elem => ({
                        ...elem,
                        selected: elem.selected === 'yes',
                      })),
                      sumInsured: Number(serializedBenefits.sumInsured.toString().replace(',', '')),
                      ...(isIncomeAssureBenefit
                        ? {
                            superGuaranteeSumInsured: Number(
                              serializedBenefits.superGuaranteeSumInsured
                                .toString()
                                .replace(',', '')
                            ),
                          }
                        : {}),
                      startdate: formattedDate.isValid()
                        ? formattedDate.format('YYYY-MM-DD')
                        : serializedBenefits.startdate,
                      ...(serializedBenefits.waitingPeriod
                        ? { waitingPeriod: updatedBenefit.waitingPeriod.value }
                        : {}),
                      ...(serializedBenefits.benefitPeriod
                        ? { benefitPeriod: updatedBenefit.benefitPeriod.value }
                        : {}),
                    }
                    updatedBenefits.push(updatedBenefit)
                    return updatedBenefits
                  }, [])
                updatedPolicy = {
                  ...getSerializedFormState(updatedPolicy),
                  policyInstanceNo: policyIndex + 1,
                }
                updatedExistingPolicy.push(updatedPolicy)
                return updatedExistingPolicy
              }, [])
            : [])
        let isReplacingCover = false
        if (updatedExistingPolicies.length) {
          isReplacingCover = updatedExistingPolicies.some(cover => {
            if (cover.insurer === MLC_INSURANCE) {
              return cover.benefits.some(benefit => benefit.replacingThisCover)
            }
            return false
          })
        } else {
          isReplacingCover = existingInsurances.some(cover => {
            if (cover.insurer === MLC_INSURANCE && cover.isFixed) {
              return cover.benefits.some(benefit => benefit.replacingThisCover)
            }
            return isReplacingCover
          })
        }
        if (isReplacingCover) {
          updateApplicationType(APPLICATION_TYPE_REPLACE)
        } else {
          updateApplicationType(NEW_BUS)
        }
        if (existingClient && existingClientCovers && isInforce) {
          updateByPushToExisitingCover(updatedExistingPolicies)
        } else {
          const updatedData = {
            consent: {
              name: 'EXISTING_COVER',
              value: data.consent === 'yes',
            },
            ...(updatedExistingPolicies.length
              ? { existingInsurances: updatedExistingPolicies }
              : {}),
          }
          updateExisitingCover({ existingCovers: updatedData })
        }

        this.setState({ isExistingCoverCompleted: true }, () => {
          this.handleInCompleteAccordion()
        })
      })
    }
  }

  handleStateChanges(nextProps) {
    const { existingConsents, activeQuote, existingCovers = {} } = nextProps
    const brandInfo = pathOr('', 'policyStructure[0].fitnessTracker', activeQuote)
    const coverInfo = pathOr(false, 'consent.name', existingCovers)
    this.setState({
      isDodCompleted: false,
      isDisclosureConfirmed: false,
      isPolicyConfirmed: false,
      isUnderwritingCompleted: !!(
        existingConsents.FAST_TRACKING_MEDICAL_REQTS && existingConsents.FAST_TRACKING_FOLLOWUP_INFO
      ),
      isExistingCoverCompleted: !!coverInfo,
      isMlcOnTrackCompleted: !!(
        existingConsents.MLC_ON_TRACK_DECLINED &&
        existingConsents.MLC_ON_TRACK_DECLINED.value &&
        brandInfo !== ''
      ),
    })
  }

  handleUserRole() {
    const {
      actions: { fetchQuoteCollection },
    } = this.props
    const { quoteCollectionId } = queryString.parse(history.location.search)
    if (quoteCollectionId) {
      this.setState({
        isLifeInsured: true,
      })
      fetchQuoteCollection(quoteCollectionId)
    }
  }

  handleConfirmDisclosure() {
    const { isDisclosureConfirmed } = this.state
    this.setState({
      isDisclosureConfirmed: !isDisclosureConfirmed,
    })
  }

  handleConfirmPolicy() {
    const { isPolicyConfirmed } = this.state
    this.setState({
      isPolicyConfirmed: !isPolicyConfirmed,
    })
  }

  handleDod() {
    const { isDisclosureConfirmed, isPolicyConfirmed } = this.state
    if (isDisclosureConfirmed && isPolicyConfirmed) {
      this.setState({ isDodCompleted: true, showError: false }, () => {
        this.handleInCompleteAccordion()
      })
    } else {
      this.setState({ isDodCompleted: false, showError: true })
    }
  }

  toggleContinueButton = value => {
    this.setState({ disableContinue: value })
  }

  showOverlayLoader = isShow => this.setState({ isShowOverlayLoader: isShow })

  handleRedirectionToURE() {
    const { quoteAction } = this.props

    const path = quoteAction !== MYLINK ? '/application/underwriting/engine' : '/application'
    this.showOverlayLoader(false)
    history.push(path)
  }

  renderDutyofClosure() {
    const { fields } = this.props
    const {
      isLifeInsured,
      isDodCompleted,
      isDisclosureConfirmed,
      isPolicyConfirmed,
      showError,
      openSections: { dod },
    } = this.state
    const {
      personalStatementConfirmDisclosureInsuredLabel,
      personalStatementConfirmPrivacyInsuredLink,
      personalStatementConfirmDisclosureLabel,
      personalStatementConfirmPrivacyLink,
      personalStatementDisclosureContent,
      personalStatementDisclosureHeading,
      personalStatementPrivacyContent,
      personalStatementDoDError,
      personalStatementContinueButton,
    } = fields
    return (
      <StatefulAccordion
        checked={isDodCompleted}
        label={renderTextField(personalStatementDisclosureHeading)}
        isOpen={dod}
        fields={fields}
        continueHandler={() => this.handleDod()}
        labelKey="dod"
        disableToggleIcon={isLifeInsured}
        disableScrollContent={isLifeInsured}
        toggleListener={this.handleToggle('dod')}
        continueText={renderTextField(personalStatementContinueButton)}
      >
        <AccordionContentContainer>
          <StatefulAccordionItem>
            {renderTextField(personalStatementDisclosureContent, true)}

            <Checkbox
              htmlFor="confirmDisclosure"
              text={
                isLifeInsured
                  ? renderTextField(personalStatementConfirmDisclosureInsuredLabel, true)
                  : renderTextField(personalStatementConfirmDisclosureLabel, true)
              }
              name="confirmDisclosure"
              value={0}
              onChangeHandler={() => this.handleConfirmDisclosure()}
              checked={isDisclosureConfirmed}
              dataLocator={LOCATOR_DOD_DISCLOSURE}
            />
            {showError &&
              !isDisclosureConfirmed &&
              this.renderErrorMessage(renderTextField(personalStatementDoDError))}

            <PrivacyPolicy>{renderTextField(personalStatementPrivacyContent, true)}</PrivacyPolicy>

            <Checkbox
              htmlFor="confirmPolicy"
              text={
                isLifeInsured
                  ? renderTextField(personalStatementConfirmPrivacyInsuredLink, true)
                  : renderTextField(personalStatementConfirmPrivacyLink, true)
              }
              name="confirmPolicy"
              onChangeHandler={() => this.handleConfirmPolicy()}
              checked={isPolicyConfirmed}
              dataLocator={LOCATOR_DOD_PRIVACY}
            />
            {showError &&
              !isPolicyConfirmed &&
              this.renderErrorMessage(renderTextField(personalStatementDoDError))}
          </StatefulAccordionItem>
        </AccordionContentContainer>
      </StatefulAccordion>
    )
  }

  toggleCustomerRequireTele() {
    const { isCustomerRequireTele } = this.state
    this.setState({
      isCustomerRequireTele: !isCustomerRequireTele,
    })
  }

  renderUnderwriting() {
    const {
      isUnderwritingCompleted,
      isTrackMedicalAgreed,
      isTrackFollowupAgreed,
      showError,
      openSections: { underwriting },
      isCustomerRequireTele,
      isDodRequired,
      isCustomerRequireTeleComplete,
    } = this.state
    const { fields, actions, controlList } = this.props
    const {
      personalStatementFastTrackingMedicalContent,
      personalStatementUnderwritingHeading,
      personalStatementFastTrackingMedicalDisagreeLabel,
      personalStatementFastTrackingMedicalAgreeLabel,
      personalStatementFastTrackingFollowupContent,
      personalStatementDoDError,
      personalStatementFastTrackingFollowupAgreeLabel,
      personalStatementFastTrackingFollowupDisagreeLabel,
      customerCompletesSectionContent,
      customerCompletesAssistanceCheckboxLabel,
      personalStatementContinueButton,
      CustomerCompletesHeading,
    } = fields

    const radioGroupFastTrackingMedicalOptions = [
      {
        key: 'no',
        text: renderTextField(personalStatementFastTrackingMedicalDisagreeLabel),
        value: 'no',
      },
      {
        key: 'yes',
        text: renderTextField(personalStatementFastTrackingMedicalAgreeLabel),
        value: 'yes',
      },
    ]

    const radioGroupFastTrackingFollowupOptions = [
      {
        key: 'no',
        text: renderTextField(personalStatementFastTrackingFollowupDisagreeLabel),
        value: 'no',
      },
      {
        key: 'yes',
        text: renderTextField(personalStatementFastTrackingFollowupAgreeLabel),
        value: 'yes',
      },
    ]

    const enableAdvisorSelectTeleUW = isFeatureEnabledForAP(
      controlList,
      'enableAdvisorSelectTeleUW'
    )

    return (
      <StatefulAccordion
        label={renderTextField(personalStatementUnderwritingHeading)}
        continueText={renderTextField(personalStatementContinueButton)}
        fields={fields}
        checked={isUnderwritingCompleted && isCustomerRequireTeleComplete}
        isOpen={underwriting}
        continueHandler={() => this.handleUnderwritingOptions(enableAdvisorSelectTeleUW)}
        labelKey="underwriting"
        toggleListener={this.handleToggle('underwriting')}
      >
        <AccordionContentContainer>
          {!isDodRequired && enableAdvisorSelectTeleUW && (
            <StatefulAccordionItem>
              <TelephoneHeader>
                <Icon iconName="far fa-phone" />
                <Heading size="LG" variant="h3">
                  {renderTextField(CustomerCompletesHeading)}
                </Heading>
              </TelephoneHeader>
              <Checkbox
                htmlFor="customerRequiresTele"
                text={renderTextField(customerCompletesAssistanceCheckboxLabel, true)}
                name="customerRequiresTele"
                onChangeHandler={() => this.toggleCustomerRequireTele()}
                checked={isCustomerRequireTele}
              />
              <p>{renderTextField(customerCompletesSectionContent, true)}</p>
            </StatefulAccordionItem>
          )}
          <StatefulAccordionItem>
            {renderTextField(personalStatementFastTrackingMedicalContent, true)}
            <RadioGroup
              type="inline"
              error={
                showError && !isTrackMedicalAgreed && renderTextField(personalStatementDoDError)
              }
            >
              {radioGroupFastTrackingMedicalOptions.map(option => (
                <Radio
                  key={option.value}
                  text={option.text}
                  checked={isTrackMedicalAgreed === option.value}
                  name={`fast-track-${option.value}`}
                  value={option.value}
                  htmlFor={`fast-track-${option.value}`}
                  handleOnChange={value => this.handleTrackMedicalChange(value)}
                />
              ))}
            </RadioGroup>
          </StatefulAccordionItem>
          <StatefulAccordionItem>
            {renderTextField(personalStatementFastTrackingFollowupContent, true)}
            <RadioGroup
              type="inline"
              error={
                showError && !isTrackFollowupAgreed && renderTextField(personalStatementDoDError)
              }
            >
              {radioGroupFastTrackingFollowupOptions.map(option => (
                <Radio
                  key={option.value}
                  text={option.text}
                  checked={isTrackFollowupAgreed === option.value}
                  name={`followup-${option.value}`}
                  value={option.value}
                  htmlFor={`followup-${option.value}`}
                  handleOnChange={value => this.handleTrackFollowupChange(value)}
                />
              ))}
            </RadioGroup>
            <FollowUpContactForm
              isTrackFollowupAgreed={isTrackFollowupAgreed}
              actions={actions}
              fields={fields}
            />
          </StatefulAccordionItem>
        </AccordionContentContainer>
      </StatefulAccordion>
    )
  }

  handleTMDOptions = () => {
    const {
      actions: { updateTMDConsents },
    } = this.props
    const {
      meetsTMDRequirementRadio,
      notMeetsTMDRequirementReason,
      isPersonalOrGeneralAdviceForTMDRadio,
    } = this.state
    if (meetsTMDRequirementRadio === 'no' && notMeetsTMDRequirementReason.trim() === '') {
      this.setState({ showError: true, flagForTMDTextAreaError: true })
      return
    }
    if (!meetsTMDRequirementRadio || !isPersonalOrGeneralAdviceForTMDRadio) {
      this.setState({ showError: true, flagForTMDTextAreaError: false })
      return
    }
    this.setState(
      { showError: false, flagForTMDTextAreaError: false, isTMDCompleted: true },
      () => {
        this.handleInCompleteAccordion()
      }
    )

    updateTMDConsents({
      meetsTMDRequirementRadio: meetsTMDRequirementRadio === 'yes',
      notMeetsTMDRequirementReason,
      isPersonalOrGeneralAdviceForTMDRadio: isPersonalOrGeneralAdviceForTMDRadio === 'personal',
    })
  }

  handleTMDReasonFieldChange = event => {
    const { value } = event.target
    this.setState({
      notMeetsTMDRequirementReason: value,
    })
  }

  renderDesignDistribObligation = () => {
    const {
      meetsTMDRequirementRadio,
      notMeetsTMDRequirementReason,
      isPersonalOrGeneralAdviceForTMDRadio,
      openSections: { designAndDistributionObligation },
      showError,
      isTMDCompleted,
      flagForTMDTextAreaError,
    } = this.state
    const { fields } = this.props
    const {
      designAndDistributionObligationsButtonTitle,
      designAndDistributionObligationsTMDRequirement,
      designAndDistributionObligationsTMDDisagreeLabel,
      designAndDistributionObligationsTMDAgreeLabel,
      designAndDistributionObligationsTMDReason,
      designAndDistributionObligationsTMDAdviceLabel,
      designAndDistributionObligationsTMDPersonalLabel,
      designAndDistributionObligationsTMDGeneralLabel,
      designAndDistributionObligationsTMDError,
      designAndDistributionObligationsTMDTextAreaError,
      personalStatementContinueButton,
    } = fields

    const radioGroupTMDRequirementOptions = [
      {
        key: 'no',
        text: renderTextField(designAndDistributionObligationsTMDDisagreeLabel),
        value: 'no',
      },
      {
        key: 'yes',
        text: renderTextField(designAndDistributionObligationsTMDAgreeLabel),
        value: 'yes',
      },
    ]

    const radioGroupTMDAdviceOptions = [
      {
        key: 'personal',
        text: renderTextField(designAndDistributionObligationsTMDPersonalLabel),
        value: 'personal',
      },
      {
        key: 'general',
        text: renderTextField(designAndDistributionObligationsTMDGeneralLabel),
        value: 'general',
      },
    ]

    return (
      <StatefulAccordion
        label={renderTextField(designAndDistributionObligationsButtonTitle)}
        fields={fields}
        checked={isTMDCompleted}
        isOpen={designAndDistributionObligation}
        continueHandler={this.handleTMDOptions}
        labelKey="designAndDistributionObligation"
        toggleListener={this.handleToggle('designAndDistributionObligation')}
        continueText={renderTextField(personalStatementContinueButton)}
      >
        <StatefulAccordionItem>
          <DesDistribOb>
            {renderTextField(designAndDistributionObligationsTMDRequirement, true)}
          </DesDistribOb>
          <DesDistribOb>
            <RadioGroup
              type="inline"
              error={
                showError &&
                !meetsTMDRequirementRadio &&
                renderTextField(designAndDistributionObligationsTMDError)
              }
            >
              {radioGroupTMDRequirementOptions.map(option => (
                <Radio
                  key={option.value}
                  text={option.text}
                  checked={meetsTMDRequirementRadio === option.value}
                  name={`TMDRequirementFollowUp-${option.value}`}
                  value={option.value}
                  htmlFor={`TMDRequirementFollowUp-${option.value}`}
                  handleOnChange={value => this.handleTMDRequirementRadioChange(value)}
                />
              ))}
            </RadioGroup>
          </DesDistribOb>
          {meetsTMDRequirementRadio === 'no' && (
            <DesDistribOb>
              {renderTextField(designAndDistributionObligationsTMDReason, true)}
            </DesDistribOb>
          )}
          {meetsTMDRequirementRadio === 'no' && (
            <TextareaTMD
              value={notMeetsTMDRequirementReason}
              onChange={this.handleTMDReasonFieldChange}
            />
          )}
          {showError &&
            flagForTMDTextAreaError &&
            this.renderErrorMessage(
              renderTextField(designAndDistributionObligationsTMDTextAreaError)
            )}
          <DesDistribOb>
            {renderTextField(designAndDistributionObligationsTMDAdviceLabel, true)}
          </DesDistribOb>
          <DesDistribOb>
            <RadioGroup
              type="inline"
              error={
                showError &&
                !isPersonalOrGeneralAdviceForTMDRadio &&
                renderTextField(designAndDistributionObligationsTMDError)
              }
            >
              {radioGroupTMDAdviceOptions.map(option => (
                <Radio
                  key={option.value}
                  text={option.text}
                  checked={isPersonalOrGeneralAdviceForTMDRadio === option.value}
                  name={`personalOrGeneralTMDAdvice-${option.value}`}
                  value={option.value}
                  htmlFor={`personalOrGeneralTMDAdvice-${option.value}`}
                  handleOnChange={value => this.handleTMDAdviceChange(value)}
                />
              ))}
            </RadioGroup>
          </DesDistribOb>
        </StatefulAccordionItem>
      </StatefulAccordion>
    )
  }

  renderExistingCover() {
    const {
      isExistingCoverCompleted,
      showExistingCoverAnyPolicyError,
      openSections: { existingcover },
      disableContinue,
    } = this.state
    const { fields, existingCovers } = this.props
    const { personalStatementExistingCoverHeading, personalStatementContinueButton } = fields
    return (
      <StatefulAccordion
        label={renderTextField(personalStatementExistingCoverHeading)}
        fields={fields}
        continueText={renderTextField(personalStatementContinueButton)}
        checked={isExistingCoverCompleted}
        isOpen={existingcover}
        labelKey="existingcover"
        continueHandler={this.handleExistingCover}
        toggleListener={this.handleToggle('existingcover')}
        disableContinue={disableContinue}
      >
        <StatefulAccordionItem>
          <ExistingCover
            fields={fields}
            resetExistingCover={this.resetExistingCover}
            renderErrorMessage={this.renderErrorMessage}
            existingCovers={existingCovers}
            showExistingCoverAnyPolicyError={showExistingCoverAnyPolicyError}
            toggleContinueButton={this.toggleContinueButton}
            disableContinue={disableContinue}
          />
        </StatefulAccordionItem>
      </StatefulAccordion>
    )
  }

  // eslint-disable-next-line react/sort-comp
  errorCallback = () => {
    const {
      actions: { closeUreErrorModal },
    } = this.props

    closeUreErrorModal()
    history.push(APPLICATION_PATH)
  }

  handleExpiredEnquiryId = () => {
    const {
      actions: { createEnquiryId, showEnquiryIdExpiredModal },
    } = this.props

    createEnquiryId(() => {}, getFullUrePayload(), false)
    showEnquiryIdExpiredModal(false)
  }

  handleContinue = () => {
    const { isDisclosureConfirmed, isPolicyConfirmed } = this.state
    const {
      actions: { updateDutyOfDisclosureConsents, saveQuote, updateQuoteStatus },
      targetedIndex,
      existingClient,
      createQuote: { action },
    } = this.props
    if (isDisclosureConfirmed && isPolicyConfirmed) {
      updateDutyOfDisclosureConsents({
        isDutyOfDisclosureChecked: true,
        isDutyOfDisclosurePrivacyChecked: true,
        targetedIndex,
      })
    }
    if (existingClient && action !== SAVE_DRAFT_APPLICATION) {
      const quoteStatus = {
        actionName: SAVE_DRAFT_APPLICATION,
      }
      // update quote status
      updateQuoteStatus(quoteStatus)
    }
    this.showOverlayLoader(true)
    saveQuote(err => {
      if (!err) {
        this.handleRedirectionToURE()
      } else {
        this.showOverlayLoader(false)
      }
    })
  }

  renderContinueBtn() {
    const {
      activeQuote,
      fields: { personalStatementContinueButton },
    } = this.props
    const isMlcOnTrack = pathOr('false', 'policyStructure[0].mlcOnTrack', activeQuote)
    const {
      isDodRequired,
      isDodCompleted,
      isUnderwritingCompleted,
      isExistingCoverCompleted,
      isMlcOnTrackCompleted,
      isCustomerRequireTeleComplete,
      isTMDCompleted,
      isLifeInsured,
    } = this.state
    const isEnabled =
      (!isDodRequired || isDodCompleted) &&
      (!isMlcOnTrack || isMlcOnTrackCompleted) &&
      (isLifeInsured || isTMDCompleted) &&
      isUnderwritingCompleted &&
      isExistingCoverCompleted &&
      isCustomerRequireTeleComplete
    return (
      <ContinueBtn onClick={this.handleContinue} disabled={!isEnabled}>
        {renderTextField(personalStatementContinueButton)}
      </ContinueBtn>
    )
  }

  render() {
    const {
      isLifeInsured,
      isDodRequired,
      isOpenPersonalStatementModal,
      isDisclosureConfirmed,
      isUnderwritingCompleted,
      isExistingCoverCompleted,
      isShowOverlayLoader,
    } = this.state
    const {
      fields,
      actions: { closeSidebar },
      ure: { isLoading, errorModal },
      sidebar: { open },
    } = this.props

    const { personalStatementIncompleteFormSubmissionError } = reduceAuthorableFields(fields)

    if (open && (!isDisclosureConfirmed || !isUnderwritingCompleted || !isExistingCoverCompleted)) {
      if (!window.confirm(personalStatementIncompleteFormSubmissionError)) {
        closeSidebar()
      }
    }

    const authorableFields = reduceAuthorableFields(fields)
    const {
      personalStatementPageBackLink,
      personalStatementPageTitle,
      personalStatementPageSubTitle,
      personalStatementServerErrorModalTitle,
      personalStatementServerErrorModalDescription,
      personalStatementEnquiryIdModalTitle,
      personalStatementEnquiryIdModalContent,
      personalStatementEnquiryIdModalButton,
    } = fields

    return (
      <WithLoader isLoading={isShowOverlayLoader} overlay>
        <Header
          backClickHandler={this.backClickHandler}
          heading={personalStatementPageTitle}
          subHeading={personalStatementPageSubTitle}
          showBackIcon
          iconText={personalStatementPageBackLink}
        >
          <UpdateMethod
            fields={fields}
            totalSteps={4}
            currentStep={1}
            isLifeInsured={isLifeInsured}
            handlePersonalStatementOpenModal={this.handlePersonalStatementOpenModal}
          />
        </Header>
        <PageWrap>
          <MainWrapper>
            {isLoading ? (
              <LoadingSection>
                <Loader spinnerSize={Number(space(10))} borderSize={Number(space(0.5))} />
              </LoadingSection>
            ) : (
              <Fragment>
                {!isLifeInsured && this.renderExistingCover()}
                {!isLifeInsured && this.renderUnderwriting()}
                {!isLifeInsured && this.renderDesignDistribObligation()}
                {isDodRequired && this.renderDutyofClosure()}
                {this.renderContinueBtn()}
              </Fragment>
            )}
          </MainWrapper>
        </PageWrap>
        {isOpenPersonalStatementModal && (
          <PersonalStatementModal
            fields={authorableFields}
            handlePersonalStatementCloseModal={this.handlePersonalStatementCloseModal}
            handleParentPageLoader={this.showOverlayLoader}
            parentPage="PersonalStatement"
          />
        )}
        <Modal
          onClose={this.errorCallback}
          showCloseButton
          shouldOverlayClose
          title={personalStatementServerErrorModalTitle.value}
          shouldFocusCloseButton
          enableScroll={false}
          isOpen={errorModal.isOpen}
        >
          <p>{personalStatementServerErrorModalDescription.value} </p>
        </Modal>
        <EnquiryIdExpiredModal
          enquiryIdModalTitle={renderTextField(personalStatementEnquiryIdModalTitle)}
          enquiryIdModalContent={renderTextField(personalStatementEnquiryIdModalContent, true)}
          enquiryIdModalButton={renderTextField(personalStatementEnquiryIdModalButton)}
          callback={this.handleExpiredEnquiryId}
          onClose={this.handleExpiredEnquiryId}
          payload={getFullUrePayload()}
          isPreassessment={false}
        />
      </WithLoader>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = state => {
  const {
    createQuote,
    forms,
    config,
    ure,
    sidebar,
    masterList: { data },
  } = state
  const targetedQuoteIndex = createQuote.activeIndex

  return {
    existingConsents: arrayToObject(createQuote.quotes[targetedQuoteIndex].consents, 'name'),
    existingCovers: createQuote.quotes[targetedQuoteIndex].existingCovers,
    targetedIndex: targetedQuoteIndex,
    quoteAction: ure.underwritingMethod,
    existingCoverForm: forms[FORM_ID],
    underWritingForm: forms[UNDERWRITING_FORM_ID],
    activeQuote: createQuote.quotes[targetedQuoteIndex],
    ure,
    sidebar,
    existingClient: getExistingClientBancsCustomerNo(state),
    createQuote,
    config,
    controlList: get(data, 'featureControlSwitch', []),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalStatement)
