// @flow
import React, { useState } from 'react'
import styled from '@emotion/styled'

// atom
import { IconChevronDown16, IconChevronUp16 } from '../../../../atoms/Icons'

// styles.
import styles from './filterTab.styles'

// constant
import {
  ARTICLE_CATEGORY_TYPE_MORE_TEXT,
  MAXIMUM_TAB_SIZE,
  DEFAULT_ACTIVE_TAB,
  ARTICLE_CATEGORY_TYPE_ALL_TEXT,
} from '../../../../../constants/knowledgeBase'

type FilterTabProps = {
  // options to diplay
  schema: Object,
  // handle onClick
  onClick: Function,
  // current active tab
  activeTab: number,
  // more label
  moreText: String,
  // handle load more categories dropdown
  handleLoadMoreCategory: Function,
  // is load more clicked
  isLoadMoreCategory: boolean,
  // show dropdown or not
  isDropdownVisible: boolean,
  // is the screen width is less than 770
  isMobileView: boolean,
}

const CategoryTabWrapper = styled('div')(styles.categoryTabWrapper)
const CategoryTab = styled('div')(({ isActive }) => styles.categoryTab(isActive))
const CategoryTabText = styled('div')(({ isActive }) => styles.categoryTabText(isActive))
const MoreButton = styled('button')(styles.moreButton)
const MoreText = styled('p')(styles.moreText)
const IconUp = styled(IconChevronUp16)(({ isActive }) => styles.icon(isActive))
const IconDown = styled(IconChevronDown16)(({ isActive }) => styles.icon(isActive))
const Wrap = styled('div')(styles.wrap)
const Option = styled('div')(({ isMobileView }) => styles.options(isMobileView))
const OptionText = styled('div')(styles.optionText)

const FilterTab = (props: FilterTabProps) => {
  const {
    schema,
    onClick,
    activeTab,
    moreText,
    handleLoadMoreCategory,
    isLoadMoreCategory,
    isDropdownVisible,
    isMobileView,
  } = props
  const [activeCategory, setCategory] = useState(DEFAULT_ACTIVE_TAB)

  const handleLoadMoreCategoryOptions = () => (
    <Option isMobileView={isMobileView}>
      {schema.map(tab => (
        <OptionText
          key={tab.id}
          onClick={() => {
            onClick(isMobileView ? DEFAULT_ACTIVE_TAB : null, tab.title)
            setCategory(tab.title)
          }}
        >
          {tab.title}
        </OptionText>
      ))}
    </Option>
  )

  const renderMoreOption = () => (
    <MoreButton
      onClick={() => {
        handleLoadMoreCategory()
        onClick(null, ARTICLE_CATEGORY_TYPE_MORE_TEXT)
      }}
    >
      <Wrap>
        <MoreText>{moreText}</MoreText>
        {isLoadMoreCategory ? <IconUp /> : <IconDown />}
      </Wrap>
      {isLoadMoreCategory && handleLoadMoreCategoryOptions()}
    </MoreButton>
  )

  const handleFilterTabs = () => {
    if (isMobileView) {
      return schema.filter(item => item.title === ARTICLE_CATEGORY_TYPE_ALL_TEXT)
    }
    return schema.splice(DEFAULT_ACTIVE_TAB, MAXIMUM_TAB_SIZE)
  }

  return (
    <CategoryTabWrapper>
      {schema &&
        handleFilterTabs().map((tab, i) => (
          <CategoryTab
            key={tab.id}
            isActive={activeTab === i}
            onClick={() => {
              if (isMobileView) {
                handleLoadMoreCategory()
                return
              }
              onClick(i, tab.title)
              setCategory(tab.title)
            }}
          >
            <CategoryTabText isActive={activeTab === i}>
              {(isMobileView && activeCategory) || tab.title}
              {isMobileView && (
                <React.Fragment>
                  {isLoadMoreCategory ? (
                    <IconUp isActive={activeTab === i} />
                  ) : (
                    <IconDown isActive={activeTab === i} />
                  )}
                </React.Fragment>
              )}
            </CategoryTabText>
          </CategoryTab>
        ))}
      {isMobileView && isLoadMoreCategory && handleLoadMoreCategoryOptions()}
      {!isMobileView && isDropdownVisible && renderMoreOption()}
    </CategoryTabWrapper>
  )
}

export default FilterTab
