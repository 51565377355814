import { space, colours, fontWeights, fontSize } from '../../../../../styles'

const styles = {
  loadMoreWrap: isDisabled => ({
    display: 'flex',
    marginTop: space(11.5),
    justifyContent: 'center',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
  loadMoreText: isDisabled => ({
    width: space(30),
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    color: isDisabled ? colours.lightGrey : colours.mediumGreen,
    border: `2px solid ${isDisabled ? colours.lightGrey : colours.mediumGreen}`,
    padding: `${space(1.5, true)} ${space(2, true)}`,
    textAlign: 'center',
  }),
}

export default styles
