// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import Loadable from 'react-loadable'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'
import { updateLoadingsFromQuote } from '../../../actions/creators/preAssessment'

// components.
import QuoteToolPanel from './components/QuoteToolPanel'
import history from '../../../utils/browserHistory'
import WithLoader from '../../molecules/WithLoader'
import SeriesCodeChangedAlert from '../../molecules/SeriesCodeChangedAlert'

// styles.
import styles from './quoteTool.style'
import { NAVIGATION_QUOTE } from '../../../constants/navigation'

// icons
import ChevronLeft32 from '../../atoms/Icons/IconChevronLeft32'
import { hasSeriesCodeExpired, isLoadingInQuotes } from '../../../utils/extendedQuoteUtils'

type QuoteToolProps = {
  // Sitecore fields
  fields: Object<Object>,
  // An object containing action creator functions.
  actions: Object<Function>,
  isFetchingData: Boolean,
  // flag to identify if products are still fetched
  isFetchingProductsData: Boolean,
  createQuote: Object,
  productRules: Object,
  isFetchingMasterData: boolean,
  preAssessment: Object,
  ure: Object,
}

const LoaderWrap = styled('div')(styles.loader)
const Wrap = styled('div')(styles.wrap)
const ShowHideButton = styled('button')(styles.showHideButton)

const QuoteToolPolicyDetail = Loadable({
  loader: () => import('./components/QuoteToolPolicyDetail'),
  loading() {
    return (
      <LoaderWrap>
        <Loader type="panel" />
      </LoaderWrap>
    )
  },
})

export class QuoteTool extends Component<QuoteToolProps> {
  constructor(props) {
    super(props)
    this.state = { isPanelVisible: true }

    this.togglePanel = this.togglePanel.bind(this)
  }

  componentWillMount() {
    const {
      actions,
      ure: { isUreTemplateFetching },
    } = this.props
    actions.setPoliciesReset()
    if (!isEmpty(history)) {
      const { manual, quoteId } = queryString.parse(history.location.search)
      if (manual === 'true') {
        actions.getQuoteForManual(quoteId)
        actions.getAllProducts()
        actions.getMasterList()
      }
    }
    actions.changeNavigationType(NAVIGATION_QUOTE)
    actions.resetFooterType()
    // when navigated from app listing and then to draft quote
    if (!isUreTemplateFetching) {
      this.updateLoadings()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      createQuote,
      ure: { isUreTemplateFetching },
    } = this.props

    // when navigated from quote listing to draft quote
    if (
      prevProps.createQuote.isFetchingData &&
      !createQuote.isFetchingData &&
      !isUreTemplateFetching
    ) {
      this.updateLoadings()
    }
  }

  updateLoadings() {
    const { createQuote, preAssessment, actions } = this.props

    // Code to update loadings in preAssessment state
    const isLoading = isLoadingInQuotes(createQuote.quotes)
    // If loadings is not present in preAssessment state but is present in quotes
    // e.g. navigated from quote listing or app listing
    if (!preAssessment.loadings.length && isLoading) {
      const allCovers = []
      createQuote.quotes.forEach(quote => {
        quote.policyStructure.forEach(policy => {
          allCovers.push(...policy.covers)
        })
      })
      actions.ureInitalData({
        queuedAction: updateLoadingsFromQuote({
          policyStructure: [
            {
              covers: allCovers,
            },
          ],
        }),
      })
    }
  }

  // hide left panel for tablet
  togglePanel() {
    const { isPanelVisible } = this.state

    this.setState({
      isPanelVisible: !isPanelVisible,
    })
  }

  render() {
    const {
      fields,
      isFetchingData,
      isFetchingProductsData,
      createQuote,
      productRules,
      isFetchingMasterData,
    } = this.props
    const { isPanelVisible } = this.state

    const { quotes, activeIndex } = createQuote

    const quote = quotes[activeIndex]
    const products = productRules.data

    let changeInSeriesCode = false

    if (!isFetchingData && !isFetchingProductsData && hasSeriesCodeExpired(quote, products)) {
      changeInSeriesCode = true
    }

    if (isFetchingProductsData || isFetchingMasterData) {
      return <WithLoader isLoading={isFetchingProductsData} overlay />
    }

    return (
      <WithLoader isLoading={isFetchingData} overlay>
        <Wrap isPanelVisible={isPanelVisible}>
          <QuoteToolPanel fields={fields} />
          <ShowHideButton onClick={this.togglePanel} isPanelVisible={isPanelVisible}>
            <ChevronLeft32 />
          </ShowHideButton>
          <QuoteToolPolicyDetail fields={fields} />
        </Wrap>
        <SeriesCodeChangedAlert fields={fields} isOpen={changeInSeriesCode} />
      </WithLoader>
    )
  }
}

export const mapStateToProps = ({ createQuote, productRules, masterList, preAssessment, ure }) => ({
  isFetchingData: createQuote.isFetchingData,
  isFetchingProductsData: productRules.isFetching,
  isFetchingMasterData: masterList.isFetching,
  createQuote,
  productRules,
  preAssessment,
  ure,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteTool)
