// @flow
// types.
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  CLIENT_DETAILS_FETCH,
  CLIENT_LISTING_FETCH,
  EXISTING_CLIENT_LISTING_FETCH,
  CLIENT_DETAILS_UPDATE,
  CLIENT_LISTING_FETCH_INIT,
  CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE,
  DELETE_SEARCHED_EXISITNG_CLIENTS,
  SET_SEARCHED_CLIENT,
  SET_CLIENT_LA_POLICYID,
  CREATE_CHANGE_COVER_WORK_ITEM,
  CREATE_CHANGE_COVER_WORK_ITEM_ERROR,
  CLIENT_DETAILS_UPDATE_RESET,
} from '../types/client'

// components
import { getAdvisorDetails } from './advisor'

// utils.
import { getBancsCustomerNumber } from '../../utils/cookieUtils'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const getClientDetails =
  (bancsCustomerNumber?: String, callback?: Function = () => {}) =>
  (dispatch: Function): Object => {
    dispatch({
      type: EXPERIENCE_API,
      name: CLIENT_DETAILS_FETCH,
      verb: 'GET',
      // FIXME: getBancsCustomerNumber() to be removed. Any code that relies on
      // cookie BancsCustomerNumber should be using getAdvisorDetails instead. MM
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${
        bancsCustomerNumber || getBancsCustomerNumber()
      }`,
      callback: (err, dataReceived) => {
        callback(dataReceived)

        // pass subset of response, or during error pass whole payload down.
        return dataReceived.businessData ? dataReceived.businessData.member : dataReceived
      },
    })
  }

export const getMemberSearch =
  (data?: Object, callback?: Function = () => {}) =>
  (dispatch: Function) => {
    const {
      sortBy,
      orderBy,
      offset,
      limit,
      searchParam,
      searchParam: { searchForLifeInsured = false },
    } = data
    const memberSearch = {
      type: EXPERIENCE_API,
      name: '',
      verb: 'POST',
      data: {
        searchType: ['Clients'],
        ...searchParam,
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/search?sortBy=${sortBy}&sort=${orderBy}&offset=${offset}&limit=${limit}`,
      callback: (err, dataReceived) => {
        if (callback) {
          const clientListing = get(dataReceived, 'businessData.clientListing', {})
          callback(err, clientListing)
        }
        return dataReceived
      },
    }

    if (searchForLifeInsured) {
      memberSearch.name = EXISTING_CLIENT_LISTING_FETCH
    } else {
      memberSearch.name = CLIENT_LISTING_FETCH
    }

    if (searchParam.bancsAgencyCodes.length) {
      return dispatch(memberSearch)
    }
    return dispatch(
      getAdvisorDetails(dataReceived => {
        const adviserData = get(dataReceived, 'adviserDetails', [])
        const bancsAgencyCodes = adviserData.reduce((agencyCodes, item) => {
          agencyCodes.push(item.bancsAgencyCode)
          return agencyCodes
        }, [])
        memberSearch.data = { ...memberSearch.data, bancsAgencyCodes }
        return dispatch(memberSearch)
      })
    )
  }

export const getAllClientRecords = (data?: Object, callback?: Function) => (dispatch: Function) => {
  const { sortBy, orderBy, offset, limit, searchParam } = data
  const memberSearch = {
    type: EXPERIENCE_API,
    name: 'CLIENT_LISTING_DOWNLOAD_FETCH',
    verb: 'POST',
    data: {
      searchType: ['Clients'],
      ...searchParam,
    },
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/search?sortBy=${sortBy}&sort=${orderBy}&offset=${offset}&limit=${limit}`,
    callback: (err, dataReceived) => {
      if (callback) {
        const clientListing = get(dataReceived, 'businessData.clientListing', {})
        callback(err, clientListing)
      }
      return dataReceived
    },
  }

  return dispatch(memberSearch)
}

export const initEmptyClientRows = () => dispatch => {
  dispatch({
    type: CLIENT_LISTING_FETCH_INIT,
  })
}

export const updateClientDetails =
  (payload: Object, callback: Function) =>
  (dispatch: Function, getState: Function): void => {
    const { supportStaff } = getState()
    const isSupportStaff = !isEmpty(supportStaff?.activeSupportStaff)
    // reset loading and error state.
    dispatch({
      type: CLIENT_DETAILS_UPDATE,
    })
    dispatch({
      type: EXPERIENCE_API,
      name: CLIENT_DETAILS_UPDATE,
      verb: 'PUT',
      data: {
        member: {
          ...payload.data,
        },
      },
      additionalHeaders: isSupportStaff ? { 'X-InitialRole': 'Support_staff' } : {},
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${
        payload.clientId || getBancsCustomerNumber()
      }`,
      callback: (err, dataReceived) => {
        callback(dataReceived, err)

        // pass subset of response, or during error pass whole payload down.
        return dataReceived.businessData ? dataReceived.businessData.member : dataReceived
      },
    })
  }

export const updateClientsBusinessContactDetails = (data, bancsCustomerNo) => ({
  type: EXPERIENCE_API,
  name: CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE,
  verb: 'PUT',
  data: {
    member: data,
  },
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${bancsCustomerNo}`,
})

export const deleteExistingSearchedClients = () => ({
  type: DELETE_SEARCHED_EXISITNG_CLIENTS,
})

export const setExistingClientDetails = (bancsCustomerNo = '') => ({
  type: SET_SEARCHED_CLIENT,
  payload: {
    bancsCustomerNo,
  },
})

export const setLifeAssuredAndPolicy = (bancsCustomerNo, bancsPolicyNo) => ({
  type: SET_CLIENT_LA_POLICYID,
  payload: {
    bancsCustomerNo,
    bancsPolicyNo,
  },
})

export const createChangeCoverWorkItemError = error => ({
  type: CREATE_CHANGE_COVER_WORK_ITEM_ERROR,
  payload: {
    error,
  },
})

export const resetClientContactDetailsHasUpdateError = () => ({
  type: CLIENT_DETAILS_UPDATE_RESET,
})

export const createChangeCoverWorkItem = (payload: Object, callback: Function) => ({
  type: EXPERIENCE_API,
  name: CREATE_CHANGE_COVER_WORK_ITEM,
  verb: 'POST',
  data: { ...payload },
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/general/serviceRequest`,
  callback: (err, dataReceived) => {
    if (callback) {
      callback(err, dataReceived)
    }
    return dataReceived
  },
})
