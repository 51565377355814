import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, fontSize, space, mediaQueriesMax, pxToRem, getButtonVariant } =
  Variables as VariablesType

export default {
  tableFooter: {
    backgroundColor: colours.tertiaryFive,
    [mediaQueriesMax.sm]: {
      justifyContent: 'center',
    },
    display: 'flex',
  },
  anchor: {
    fontSize: fontSize.xs,
    color: colours.mlcAquaAccessible,
    cursor: 'pointer',
  },
  filterContainer: {
    paddingLeft: space(2),
    paddingRight: space(2),
    marginTop: space(2.5),
    ' .mlc__value-container': {
      height: '34px',
    },
  },
  cell: {
    '&:nth-child(2)': {
      flex: 2,
    },
  },
  spinner: {
    minHeight: 0,
    color: colours.tertiaryOne,
    display: 'inline-block',
  },
  stateContainer: {
    marginTop: space(4),
  },
  info: ({ theme, variant }: { theme: { button: object }; variant: string }) => ({
    fontSize: pxToRem(14),
    color: colours.tertiaryOne,
    padding: `0 ${space(2, true)}`,
    a: {
      ...getButtonVariant(variant, theme.button),
    },
  }),
}
