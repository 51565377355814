// @flow
import React from 'react'

// components.
import UreToolRoot from '../../../organisms/UreTool'

type UreToolProps = {
  // Sitecore fields
  fields: Object<Object>,
}

const UreTool = ({ fields }: UreToolProps) => <UreToolRoot fields={fields} />

export default UreTool
