import { space, mediaQueries, colours, fontFamily, fontWeights } from '../../../../../styles'

const removeStyleFromMediaElements = {
  maxWidth: '100%',
  height: 'auto',
}

const MAX_WIDTH = {
  maxWidth: space(93),
}

const styles = {
  container: {
    paddingBottom: space(26),
  },
  offset: {
    background: colours.white,
    marginTop: -space(3),
    [mediaQueries.xs]: {
      padding: space(3),
    },
    [mediaQueries.md]: {
      padding: space(7),
      marginTop: -space(7),
    },
  },
  wrap: {
    marginTop: -space(3),
    marginBottom: space(11),
  },
  tableRow: {
    '.table-row': {
      cursor: 'pointer',
    },
  },
  articleImageWrap: {
    ...MAX_WIDTH,
    '&:first-child': {
      img: {
        marginTop: 0,
      },
    },
  },
  articleImage: {
    width: '100%',
    height: 'auto',
    marginTop: space(6),
  },
  articleDetailWrapper: {
    height: 'auto',
  },
  articleHeading: {
    color: colours.mediumDarkGrey,
    ...MAX_WIDTH,
  },
  articleDate: {
    color: colours.darkGrey,
    fontSize: space(2),
    lineHeight: space(12, true),
    fontFamily: fontFamily.charlie,
    fontWeight: fontWeights.bold,
  },
  articleDescriptionWrap: {
    marginTop: space(6),
    display: 'flex',
    ...MAX_WIDTH,
  },
  articleDescription: {
    fontSize: space(2),
    lineHeight: space(3, true),
    fontWeight: fontWeights.normal,
    width: '100%',
    img: {
      ...removeStyleFromMediaElements,
    },
    ul: {
      paddingInlineStart: space(5),
      margin: `${space(2, true)} 0`,
      ul: {
        margin: 0,
      },
    },
  },
  articleVideoContainer: {
    ...MAX_WIDTH,
  },
  articleVideo: {
    position: 'relative',
    marginTop: space(6),
    paddingBottom: '56.25%',
    width: '100%',
    height: 0,
    overflow: 'hidden',
    iframe: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
}

export default styles
