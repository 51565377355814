// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, colours, fontWeights, fontSize } = Variables
const styles = {
  pageWrap: {
    background: colours.tertiarySix,
    [mediaQueries.lg]: {
      paddingTop: space(9),
      paddingBottom: space(18),
    },
  },
  heading: {
    marginBottom: space(0.5),
  },
  description: {
    marginBottom: space(7),
  },
  tabs: {
    display: 'block',
    marginBottom: space(4),
    [mediaQueries.lg]: {
      display: 'flex',
      '& ul.hasResetButton ': {
        marginLeft: space(25),
      },
    },
    [mediaQueries.md]: {
      '& li:first-of-type a': {
        borderRadius: '5px 0 0 5px',
      },
      '& li:last-of-type a': {
        borderRadius: '0 5px 5px 0',
      },
    },
  },
  buttonsWrapper: {
    display: 'flex',
    '&>div': {
      flexGrow: 1,
      '&:last-of-type': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
  backLink: {
    color: colours.lightBlack,
    '& a': {
      paddingLeft: 0,
    },
    '& svg': {
      color: 'inherit',
      marginRight: space(1),
    },
    display: 'flex',
  },
  downloadButton: {
    marginRight: space(2),
  },
  cardContainer: {
    gridTemplateColumns: '1fr',
    '& > div': {
      marginBottom: space(4),
    },
  },
  modalDescription: {
    color: colours.black,
    marginBottom: space(4, true),
    span: {
      fontWeight: fontWeights.semiBold,
    },
  },
  mcdDiscountInfo: {
    padding: `${space(2, true)} ${space(3, true)}`,
    backgroundColor: colours.functionalInfoLight,
    marginBottom: space(4),
    '& > div > div': {
      display: 'flex',
      alignItems: 'flex-start',
      color: colours.functionalInfoDark,
      '& > svg': {
        fontSize: fontSize.md,
      },
      '& > span': {
        fontSize: fontSize.xs,
      },
    },
  },
}

export default styles
