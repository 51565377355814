import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontWeights, mediaQueries } = Variables
const styles = {
  container: () => ({
    minHeight: space(54, true),
    marginBottom: space(24, true),
  }),
  tabWrapper: {
    width: '100%',
    background: colours.tertiarySix,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${colours.tertiaryThree}`,
    '& > div:not(:last-child)': {
      borderRight: 0,
      borderBottom: `1px solid ${colours.tertiaryThree}`,
    },
    [mediaQueries.md]: {
      flexDirection: 'row',
      '& > div:not(:last-child)': {
        borderRight: `1px solid ${colours.tertiaryThree}`,
        borderBottom: 0,
      },
    },
  },
  tabs: ({ isDisable }) => ({
    width: '100%',
    height: space(18, true),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    cursor: isDisable ? 'not-allowed' : 'pointer',
  }),
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabTitle: {
    fontSize: space(2, true),
    lineHeight: space(3, true),
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
    padding: 0,
    marginBottom: 0,
  },
  tabValue: {
    textAlign: 'center',
    color: colours.tertiaryOne,
    fontSize: space(6, true),
    lineHeight: space(3, true),
    fontWeight: fontWeights.bold,
    padding: space(1),
    marginBottom: space(1),
    boxSizing: 'border-box',
  },
}

export default styles
