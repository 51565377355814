// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import Card from '../../../../atoms/Card'
import Panels from './Panels'
import VerticalTabs from '../../../../molecules/VerticalTabs'

// utils
import { getBancsCustomerNumber } from '../../../../../utils/cookieUtils'

// styles.
import styles from './advisorPreferencesForm.styles'
import { isIPCover } from '../../../../../utils/quoteUtils'

const Container = styled(Card)(styles.base)
const PreferencesWrapper = styled('div')(styles.preferencesWrapper)

type AdvisorPreferencesProps = {
  // authored text for for labels
  labels: {},
  // app data
  preferences: Array<Object>,
  // An object containing action creator functions.
  actions: Object<Function>,
  // determines if the advisor preferences are still being fetched from the server.
  isLoading: boolean,
  // determines if the advisor post preferences data are still being fetched from the server.
  isPreferencePostLoading: boolean,
  // determines if the advisor restore preferences data are still being fetched from the server.
  isPreferenceRestoreLoading: boolean,
  setFormTouched: Function,
  fields: Object,
  isError: Boolean,
}

class AdvisorPreferences extends Component<AdvisorPreferencesProps> {
  constructor(props) {
    super(props)
    this.handleRestoreDefaultPreferences = this.handleRestoreDefaultPreferences.bind(this)
    this.setInputBenefitType = this.setInputBenefitType.bind(this)
    this.setBenefitTypeAllowedFeature = this.setBenefitTypeAllowedFeature.bind(this)
    this.resetChildState = this.resetChildState.bind(this)
    this.state = {
      updatedInputBenefiType: '',
      updatedBenefitTypeAllowableFeature: null,
    }
    const event = createEvent({
      GA: {
        category: 'Quote defaults for each cover type both inside and outside super policies',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name':
            'Quote defaults for each cover type both inside and outside super policies',
        },
      },
    })
    event.end()
  }

  componentDidMount() {
    const {
      actions: { getAdvisorPreferences },
    } = this.props
    getAdvisorPreferences()
  }

  setInputBenefitType(updatedInputBenefiType) {
    this.setState({ updatedInputBenefiType })
  }

  setBenefitTypeAllowedFeature(updatedBenefitTypeAllowableFeature) {
    this.setState({ updatedBenefitTypeAllowableFeature })
  }

  resetChildState() {
    this.setState({
      updatedInputBenefiType: '',
      updatedBenefitTypeAllowableFeature: null,
    })
  }

  handleRestoreDefaultPreferences() {
    const {
      actions: { getAdvisorPreferencesRestore },
    } = this.props
    getAdvisorPreferencesRestore(getBancsCustomerNumber())
    const event = createEvent({
      GA: {
        category: 'Quote defaults for each cover type both inside and outside super policies',
        action: 'Restore all defaults',
      },
      Splunk: {
        attributes: {
          'workflow.name':
            'Quote defaults for each cover type both inside and outside super policies - Restore all defaults',
        },
      },
    })
    event.end()
  }

  render() {
    const {
      fields,
      preferences,
      isError,
      isLoading,
      setFormTouched,
      isPreferencePostLoading,
      isPreferenceRestoreLoading,
    } = this.props
    const { updatedInputBenefiType, updatedBenefitTypeAllowableFeature } = this.state

    if (isLoading) return <Loader type="tab" />

    return Array.isArray(preferences) && preferences.length > 0 ? (
      <Container>
        <PreferencesWrapper>
          <VerticalTabs
            restoreLink={fields.restoreDefault}
            handleClick={this.handleRestoreDefaultPreferences}
            resetChildState={this.resetChildState}
            isPreferenceRestoreLoading={isPreferenceRestoreLoading}
          >
            {preferences.map(preference =>
              preference.benefits.reduce((benefitPanelsList, benefit) => {
                if (benefit.displayOnChooseCover > 0) {
                  benefitPanelsList.push(
                    <div
                      key={benefit.benefitDisplayName}
                      id={`${preference.productId}_${benefit.benefitCode}`}
                      title={preference.productName}
                      label={
                        isIPCover({ type: benefit.benefitCode })
                          ? get(benefit.benefitType, 'value', '')
                          : benefit.benefitDisplayName
                      }
                    >
                      <Panels
                        key={benefit.benefitDisplayName}
                        productId={preference.productId}
                        productBenefitList={preference.benefits}
                        benefits={benefit}
                        fields={fields}
                        setInputBenefitType={this.setInputBenefitType}
                        setBenefitTypeAllowedFeature={this.setBenefitTypeAllowedFeature}
                        confirmNotification={this.confirmNotification}
                        updatedInputBenefiType={updatedInputBenefiType}
                        updatedBenefitTypeAllowableFeature={updatedBenefitTypeAllowableFeature}
                        isError={isError}
                        setFormTouched={setFormTouched}
                        isPreferencePostLoading={isPreferencePostLoading}
                      />
                    </div>
                  )
                }
                return benefitPanelsList
              }, [])
            )}
          </VerticalTabs>
        </PreferencesWrapper>
      </Container>
    ) : null
  }
}

const mapStateToProps = state => ({
  getAdvisorPreferences: state.getAdvisorPreferences,
  getAdvisorPreferencesRestore: state.getAdvisorPreferencesRestore,
  preferences: state.preferences.preferences,
  isError: state.preferences.error,
  isLoading: state.preferences.isLoading,
  isPreferencePostLoading: state.preferences.isPreferencePostLoading,
  isPreferenceRestoreLoading: state.preferences.isPreferenceRestoreLoading,
  hasFetchError: state.preferences.hasFetchError,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorPreferences)
