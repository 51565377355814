import {
  mediaQueries,
  fontFamily,
  fontSize,
  fontWeights,
  colours,
  space,
} from '../../../../../styles'

const styles = {
  wrapper: {
    flex: '0 0 100%',
    textAlign: 'left',
    marginTop: space(2, true),

    [mediaQueries.md]: {
      flexBasis: '30%',
      textAlign: 'right',
      marginTop: 0,
    },
  },
  box: {
    fontFamily: fontFamily.charlie,
    backgroundColor: colours.white,
    fontSize: fontSize.lg,
    color: colours.black,
    padding: `${space(2, true)} ${space(2, true)} ${space(1, true)}`,
    display: 'inline-block',
    lineHeight: space(5, true),
    borderRadius: '4px',
  },
  of: {
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.sm,
    position: 'relative',
    top: '-2px',
  },
  message: {
    fontSize: fontSize.xs,
    lineHeight: space(2, true),
    marginTop: space(1),
  },
}

export default styles
