const styles = {
  '& th,td': {
    minWidth: 'initial',
    flex: 2,
    '&:nth-of-type(2)': {
      flex: 7,
    },
    '&:first-of-type': {
      flex: 1,
      justifyContent: 'center',
      div: {
        textTransform: 'capitalize',
      },
    },
  },
}

export default styles
