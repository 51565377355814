import { space, colours, fontSize, fontFamily } from '../../../../../../styles'

const styles = {
  QuoteItemContent: {
    fontSize: fontSize.sm,
    paddingTop: space(3, true),
    paddingBottom: space(2, true),
    paddingLeft: space(4, true),
    paddingRight: space(3, true),
    display: 'block',
    borderBottom: `1px solid ${colours.darkestGrey} `,
    background: colours.darkestGreen,
  },

  quoteConfigLabel: {
    fontFamily: fontFamily.charlie,
    fontSize: fontSize.md,
    color: colours.white,
  },

  quoteSettingsIcon: {
    color: colours.white,
    margin: space(1, true),
    width: space(2),
    height: space(2),
    float: 'right',
  },

  QuoteButton: {
    cursor: 'pointer',
    width: '100%',
    background: 'transparent',
    border: 'none',
    textAlign: 'left',
    padding: 0,
    display: 'block',
  },
}

export default styles
