/* eslint-disable */
;(function (g, e, n, es, ys) {
  g['_genesysJs'] = e
  g[e] =
    g[e] ||
    function () {
      ;(g[e].q = g[e].q || []).push(arguments)
    }
  g[e].t = 1 * new Date()
  g[e].c = es
  ys = document.createElement('script')
  ys.async = 1
  ys.src = n
  ys.charset = 'utf-8'
  document.head.appendChild(ys)
})(window, 'Genesys', 'https://apps.mypurecloud.com.au/genesys-bootstrap/genesys.min.js', {
  environment: 'prod-apse2',
  debug: false, // optional - Enables Genesys browser console logging
  deploymentId: '6186d72f-7f2f-4700-966d-ff8323e6d4e0',
})

Genesys('subscribe', 'Toaster.ready', function (e) {
  console.log('Toaster plugin is ready', e)
})

Genesys('subscribe', 'Toaster.opened', function (e) {
  console.log('Toaster was opened', e)
})

Genesys('subscribe', 'Toaster.accepted', function (e) {
  console.log('Toaster was accepted', e)
})

Genesys('subscribe', 'Toaster.declined', function (e) {
  console.log('Toaster was declined', e)
})

Genesys('subscribe', 'Toaster.closed', function (e) {
  console.log('Toaster was closed', e)
})

Genesys('subscribe', 'Toaster.error', function (e) {
  console.log('Toaster error:', e.data)
})

Genesys('subscribe', 'CobrowseService.ready', e => console.log('CobrowseService: ready', e))

Genesys('subscribe', 'CobrowseService.sessionStarted', e => {
  console.log('Co-browse session started', e.data.joinCode, e.data.sessionId)
})

Genesys('subscribe', 'CobrowseService.sessionEnded', () => {
  console.log('Co-browse session ended')
})

Genesys('subscribe', 'CobrowseService.controlRequested', () => {
  console.log('CobrowseService.controlRequested')
})

Genesys('subscribe', 'CobrowseService.controlGranted', () => {
  console.log('CobrowseService.controlGranted')
})

Genesys('subscribe', 'CobrowseService.navigationRequested', e => {
  console.log('CobrowseService.navigationRequested')
})

Genesys('subscribe', 'CobrowseVoice.error', e => {
  console.log('Co-browse error', e.data)
})

Genesys('subscribe', 'CobrowseVoice.sessionStarted', e => {
  console.log('Co-browse session started', e.data.joinCode, e.data.sessionId)
})

Genesys('subscribe', 'CobrowseVoice.sessionEnded', () => {
  console.log('Co-browse session ended')
})

//HERE IS THE CODE this is manage the behaviour of DISCLAIMER TOASTER
Genesys('subscribe', 'Messenger.opened', () => {
  console.log('!!!Messenger.opened, will show the TOASTER for Disclaimer')
  Genesys(
    'command',
    'Toaster.open',
    {
      title: 'Please read below disclosure',
      body: 'All co-browsing sessions are recorded for quality and compliance purposes and will be stored for up to 90 days.\nBy accepting the use of co-browse, you agree to allow our support team to view your screen for the purpose of assisting with navigation and resolving your query. To protect your privacy and security, only content from the MLC Life Insurance website and portal will be visible to our team. Any credit card information, tax files numbers and login credentials including passwords will be automatically hidden and will not be visible to our team. This information will not be captured in the recording.\nFurther information about how we manage your personal information is in our privacy policy, found on our website at mlcinsurance.com.au/privacy-policy',
      buttons: {
        type: 'unary', // required when 'buttons' is present. Values: "unary" for one action button, "binary" for two action buttons
        primary: 'Accept', // optional, default value is "Accept"
      },
    },
    function () {
      /*fulfilled callback*/
      console.log('Toaster is opened')
    },
    function (error) {
      /*rejected callback*/
      console.log('There was an error running the Toaster.open command:', error)
    }
  )
})

Genesys('subscribe', 'Messenger.closed', () => {
  console.log('!!!Messenger.closed, closing the TOASTER for Disclaimer')
  Genesys(
    'command',
    'Toaster.close',
    {},
    function () {
      /*fulfilled callback*/
      console.log('Toaster closed')
    },
    function (error) {
      /*rejected callback*/
      console.log('There was an error running the Toaster.close command:', error)
    }
  )
})
