import { Variables } from '@mlcl-digital/mlcl-design'
import {
  fontWeights,
  space,
  fontSize,
  colours,
  mediaQueries,
  setColumns,
} from '../../../../../styles'

const { colours: libColours, fontSize: libFontSize } = Variables

export default {
  main: {
    padding: `${space(5, true)} 0`,
  },
  title: {
    fontSize: fontSize.lg,
    marginBottom: space(1, true),
  },
  subtitle: {
    fontSize: fontSize.xs,
    fontWeight: fontWeights.normal,
    marginBottom: 0,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: space(4, true),
  },
  listRow(header, colored) {
    return {
      display: 'flex',
      padding: `${space(2, true)}`,
      alignItems: 'center',
      ...(header && {
        fontWeight: fontWeights.semiBold,
        borderBottom: `1px solid ${colours.lightestGrey}`,
      }),
      ...(colored && {
        background: libColours.tertiaryFive,
      }),
      cursor: 'pointer',
      '&:hover': {
        background: libColours.tertiaryThree,
      },
      '&:last-of-type': {
        borderBottom: `1px solid ${libColours.tertiaryThree}`,
      },
    }
  },
  listItem(lastItem) {
    return {
      flex: '0 0 65px',
      [mediaQueries.sm]: {
        flex: '0 0 130px',
        ...(lastItem && {
          flexBasis: 'auto',
          textAlign: 'right',
        }),
      },
      [mediaQueries.md]: {
        flex: '0 0 140px',
      },
      ...(lastItem && {
        flexBasis: 'auto',
      }),
    }
  },
  status: {
    color: '#016A45',
    border: 'none',
    background: '#CCF5E7',
    padding: `0 ${space(1, true)}`,
    fontSize: fontSize.xxs,
    borderRadius: space(2, true),
    fontWeight: fontWeights.bold,
  },
  remove: {
    border: '0',
    padding: '0',
    background: 'none',
    color: colours.black,

    '&:hover, &:focus': {
      outline: 'none',
    },
  },
  removeIcon: {
    color: 'inherit',
  },
  modalBody: {
    paddingBottom: space(9, true),
  },
  modalFooter: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%',
    padding: space(3),
    backgroundColor: libColours.tertiaryFive,
    display: 'flex',
    gap: space(3),
  },
  offboardingModal: {
    '& > div > div > h2': {
      fontSize: libFontSize.lg,
    },
  },
  denyButton: {
    backgroundColor: libColours.mlcAquaAccessible,
    border: '0',
    borderRadius: '4px',
    width: '88px',
    padding: '0 8px',
    marginLeft: '16px',
    color: '#FFF',
    height: '36px',
    boxSizing: 'border-box',
  },
  cancelButton: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
      color: libColours.mlcAquaAccessible,
    },
    ' &:focus': {
      textDecoration: 'none',
      color: libColours.tertiaryOne,
    },
  },
  registerSupportStaffButton: {
    fontSize: libFontSize.xs,
    padding: `${space(1, true)} ${space(2, true)}`,
  },
  bulkUploadSupportStaffButton: {
    fontSize: libFontSize.xs,
    padding: `${space(1, true)} ${space(2, true)}`,
  },
  registerSupportStaffButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: space(2),
  },
  base: {
    paddingBottom: space(8),
    paddingLeft: space(4),
    paddingRight: space(2),
    paddingTop: space(3),
    overflow: 'auto',
    '& label': {
      marginBottom: 0,
    },
    [mediaQueries.md]: {
      ...setColumns(1.25),
      display: 'flex',
      paddingLeft: space(8),
    },
  },
}
