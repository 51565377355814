import { space, mediaQueries, colours } from '../../../../../styles'

import browser from '../../../../../utils/browserUtils'

const isIE11 = browser.ie && browser.ie === 11

const infoContainerFlex = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '& > *': {
    marginBottom: space(3, true),
  },
  [mediaQueries.md]: {
    '& > *': {
      flexBasis: '42%',
      maxWidth: '42%',
      marginRight: space(3, true),
    },
  },
  [mediaQueries.lg]: {
    '& > *': {
      flexBasis: '32%',
      maxWidth: '32%',
      marginRight: space(3, true),
    },
  },
  padding: `${space(6, true)} 0 0`,
  marginBottom: space(4, true),
}

const infoContainerGrid = {
  display: 'grid',
  gridRowGap: space(3, true),
  [mediaQueries.md]: {
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: space(3, true),
  },
  [mediaQueries.lg]: {
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: space(3, true),
  },
  padding: `${space(6, true)} 0 0`,
  marginBottom: space(4, true),
}

const styles = {
  infoContainer: isIE11 ? infoContainerFlex : infoContainerGrid,
  fileDownload: {
    background: colours.white,
  },
}

export default styles
