// @flow
import moment from 'moment'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'
import axios from 'axios'
import { toast } from 'react-toastify'

// utils
import isEmpty from 'lodash/isEmpty'
import { ALLOWED_DATE_FORMAT, DATE_FORMAT } from '../../utils/quoteUtils'
import { makeRequestHeaders } from '../../utils/middlewareUtils'

// types
import {
  ADVISER_REGISTRATION_INIT,
  ADVISER_REGISTRATION_SUCCESS,
  ADVISER_REGISTRATION_ERROR,
  ADVISER_REGISTRATION_FAILURE,
  ADVISER_REGISTRATION_RECAPTCHA_TOKEN,
} from '../types/adviserRegistration'

// constant
import { EXPERIENCE_API_VERSION_1, EXPERIENCE_API_BASE } from '../../constants/apis'
import {
  TERMS_AND_CONDITION,
  PARTY_TYPE,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  DATE_OF_BIRTH,
  ABN_NUMBER,
  ASIC_REP_NO,
  AFSL_NAME,
  AFSL_NUMBER,
  EMAIL,
  ADVISER_REGISTRATION_SMOKER_STATUS,
  ADVISER_REGISTRATION_LIFE_STATUS,
  ADVISER_REGISTRATION_GENDER,
  ADVISER_REGISTRATION_RESIDENTIAL_STREET,
  ADVISER_REGISTRATION_RESIDENTIAL_HOUSENO,
  ADVISER_REGISTRATION_RESIDENTIAL_LOCALITY,
  ADVISER_REGISTRATION_RESIDENTIAL_STATE,
  ADVISER_REGISTRATION_RESIDENTIAL_COUNTRY,
  ADVISER_REGISTRATION_RESIDENTIAL_POSTCODE,
  ADVISER_REGISTRATION_CONTACT_NUMBER,
  ADVISER_REGISTRATION_DEFAULT_COUNTRY_CODE,
  PREFERRED,
  ADDRESS_STATUS,
  ADDRESS_HOME_TYPE,
  PHONE_TYPE,
  PHONE_SUB_TYPE,
  PARTY_TYPE_PERSON,
  PARTY_TYPE_BUSINESS,
  ADVISER_REGISTRATION_CONTACT_NUMBER_CODE,
  ADVISER_REGISTRATION_CONTACT_NUMBER_COUNTRY_CODE,
  AUTHORIZED_REPRESENTATIVES_FIELD_NAME,
} from '../../constants/adviserRegistration'

import {
  TOAST_ADVISER_REGISTRATION_ERROR,
  TOAST_ID_ADVISER_REGISTRATION,
} from '../../constants/toast'

export const createAdviser = (data: Object, recaptchaToken: String) => (dispatch, getState) => {
  const { config } = getState()
  const API_DOMAIN: string = config.MLCL_EXPERIENCE_API

  const headers = makeRequestHeaders(
    'multipart/form-data',
    '',
    {
      'X-InitialRole': config.MLCL_UPLOAD_FILE_API_INITIAL_ROLE,
    },
    true
  )
  const url = `${API_DOMAIN}${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/general/registerAdviser`
  axios({
    url,
    method: 'POST',
    headers: {
      ...headers,
      response: recaptchaToken,
    },
    data,
  })
    .then(res => {
      const result = res.data
      if (res.status >= 200 && res.status < 300) {
        dispatch({
          type: ADVISER_REGISTRATION_SUCCESS,
          payload: result,
        })
      } else {
        dispatch({ type: ADVISER_REGISTRATION_ERROR, payload: { error: res.error } })
        toast(TOAST_ADVISER_REGISTRATION_ERROR, {
          autoClose: 3000,
          toastId: TOAST_ID_ADVISER_REGISTRATION,
          type: toast.TYPE.ERROR,
        })
      }
    })
    .catch(error => {
      const err = error && error.response && error.response.data
      dispatch({ type: ADVISER_REGISTRATION_FAILURE, payload: { error: err } })
      toast(TOAST_ADVISER_REGISTRATION_ERROR, {
        autoClose: 3000,
        toastId: TOAST_ID_ADVISER_REGISTRATION,
        type: toast.TYPE.ERROR,
      })
    })
}

export const registerAnAdviser =
  (
    data: Ojbect,
    recaptchaToken: String,
    fileToUpload: FormData,
    businessName: string,
    partyType: string,
    documentEntity: Array
  ) =>
  (dispatch: Function) => {
    const comment = {
      partyType: get(data, PARTY_TYPE, ''),
      gender: ADVISER_REGISTRATION_GENDER,
      smokerStatus: ADVISER_REGISTRATION_SMOKER_STATUS,
      lifeStatus: ADVISER_REGISTRATION_LIFE_STATUS,
      contactMethods: {
        addresses: [
          {
            houseNo: get(data, ADVISER_REGISTRATION_RESIDENTIAL_HOUSENO, ''),
            street: get(data, ADVISER_REGISTRATION_RESIDENTIAL_STREET, ''),
            locality: get(data, ADVISER_REGISTRATION_RESIDENTIAL_LOCALITY, ''),
            state: get(data, ADVISER_REGISTRATION_RESIDENTIAL_STATE, ''),
            country:
              data.residentialCountry === ''
                ? ADVISER_REGISTRATION_DEFAULT_COUNTRY_CODE
                : get(data, ADVISER_REGISTRATION_RESIDENTIAL_COUNTRY),
            postCode: get(data, ADVISER_REGISTRATION_RESIDENTIAL_POSTCODE, '').toString(),
            preferred: PREFERRED,
            addressType: ADDRESS_HOME_TYPE,
            emails: [
              {
                email: get(data, EMAIL, ''),
                preferred: PREFERRED,
                type: ADDRESS_HOME_TYPE,
              },
            ],
            addressStatus: ADDRESS_STATUS,
          },
        ],
        phones: [
          {
            number: get(data, ADVISER_REGISTRATION_CONTACT_NUMBER, ''),
            preferred: PREFERRED,
            type: PHONE_TYPE,
            subType: PHONE_SUB_TYPE,
            idc: get(data, ADVISER_REGISTRATION_CONTACT_NUMBER_CODE, ''),
            countryCode: get(data, ADVISER_REGISTRATION_CONTACT_NUMBER_COUNTRY_CODE, ''),
          },
        ],
      },
      asicRepNo: get(data, ASIC_REP_NO, ''),
      afslName: get(data, AFSL_NAME, ''),
      afslNumber: get(data, AFSL_NUMBER, ''),
      declaration: {
        termsAndCondition: get(data, TERMS_AND_CONDITION, ''),
      },
    }

    if (partyType === PARTY_TYPE_PERSON) {
      comment.title = get(data, TITLE, '')
      comment.firstName = get(data, FIRST_NAME, '')
      comment.lastName = get(data, LAST_NAME, '')
      comment.dateOfBirth = moment(get(data, DATE_OF_BIRTH, ''), ALLOWED_DATE_FORMAT, true).format(
        DATE_FORMAT
      )
    }

    if (partyType === PARTY_TYPE_BUSINESS) {
      comment.businessName = businessName
      comment.abnNumber = get(data, ABN_NUMBER, '')
      const authorizedReps = get(data, AUTHORIZED_REPRESENTATIVES_FIELD_NAME)
      comment.authorizedRepresentatives = isEmpty(authorizedReps)
        ? ''
        : authorizedReps.map(r =>
            mapValues(r, propV => {
              if (propV.error) return ''
              return isObject(propV.value) ? propV.value.value : propV.value
            })
          )
    }

    const request = new FormData()

    const formattedObject = JSON.stringify(comment)

    request.append('file', fileToUpload)
    request.append(
      'docSubTypeCode',
      documentEntity && documentEntity.length && get(documentEntity[0], 'documentCode', '')
    )
    request.append(
      'workType',
      documentEntity && documentEntity.length && get(documentEntity[0], 'workType', '')
    )
    request.append('comment', formattedObject)

    dispatch(createAdviser(request, recaptchaToken))
    dispatch({
      type: ADVISER_REGISTRATION_INIT,
    })
  }

export const setReCaptchaToken = (recaptchaToken: String) => ({
  type: ADVISER_REGISTRATION_RECAPTCHA_TOKEN,
  payload: {
    recaptchaToken,
  },
})
