import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space } = Variables

const styles = {
  progress: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressWrap: {
    color: colours.black,
    backgroundColor: colours.tertiarySix,
    border: `solid 1px ${colours.tertiaryThree}`,
    padding: space(4),
    marginBottom: space(4),
  },
}

export default styles
