// @flow
import React from 'react'

// components.
import ArticleTagPageComponent from '../../../organisms/KnowledgeBase/components/ArticleTagPage'

type ArticleTagPageProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const ArticleTagPage = ({ fields }: ArticleTagPageProps) => (
  <ArticleTagPageComponent fields={fields} />
)

export default ArticleTagPage
