import { colours, fontSize, space, QUOTE_LEFT_SIDEBAR_WIDTH } from '../../../../../styles'

const styles = {
  wrap: {
    position: 'fixed',
    height: '100%',
    width: QUOTE_LEFT_SIDEBAR_WIDTH,
    background: colours.darkGreen,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: space(10),
  },
  activeQuoteCollection: {
    background: colours.darkGreen,
  },
  additionalInformation: {
    color: colours.white,
    fontSize: fontSize.xxs,
    padding: space(3),
  },
}

export default styles
