import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, fontWeights, typography } = Variables

const styles = {
  offset: {
    marginTop: -space(7),
  },
  fitnessTrackerContent: {
    marginBottom: `${space(3, true)}`,
  },
  errorMessage: {
    color: colours.functionalDanger,
    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: '2px',
      fontWeight: fontWeights.bold,
    },
  },
  modalContent: {
    color: colours.tertiaryOne,
    minHeight: space(10, true),
    marginBottom: space(2),
  },
  toggleGroupContainer: {
    '& fieldset > div': {
      maxWidth: '468px',
    },
  },
  checkbox: {
    '& label:before': {
      top: '13px',
    },
    '& label>svg': {
      top: '13px',
    },
    '& label': {
      ...typography.paragraphs.XSMSemiBold,
    },
  },
}

export default styles
