// @flow
import React from 'react'
import AltsContactDetailsOrganism from '../../../organisms/Alteration/AltsContactDetails'

type AltsContactDetailsProps = {
  fields: Object,
  // Params
  params: Object,
}

const AltsContactDetails = ({ fields, params }: AltsContactDetailsProps) => (
  <AltsContactDetailsOrganism fields={fields} params={params} />
)

export default AltsContactDetails
