// @flow
import React, { useState, useEffect, Fragment } from 'react'
// redux
import { connect, useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
// TODO: Use Modal from UI LIb once its available.
import { Heading, Button, Checkbox /* , Modal */ } from '@mlcl-digital/mlcl-design'
import { objectOf, string, shape } from 'prop-types'
import styled from '@emotion/styled'
import { createEvent } from '../../../../utils/telemetry'
// Actions
import { actionCreators } from '../../../../actions'
// Components
import WithLoader from '../../../molecules/WithLoader'
import Modal from '../../../molecules/Modal'
import BackCTA from '../BackCTA'
// Styles
import styles from './styles'
import { colours } from '../../../../styles'
// Utilities
import history from '../../../../utils/browserHistory'
import { reduceAuthorableFields, renderTextField } from '../../../../utils/sitecoreUtils'
import {
  ALTERATIONS_APPLICATION_SUMMARY,
  DECREASE_ALTS_APPLICATION_SUMMARY,
} from '../../../../constants/routes'
import {
  makeAltsLifeInsuredNameAndPartyNo,
  getIsAdviserPortal,
} from '../../../../selectors/common.selectors'
import {
  getAltsProductRules,
  getAlterationType,
  getAlterationTypeSelectedByUser,
  isDecreaseQuote,
} from '../../../../selectors/alterations'
import ScrollToTop from '../../../atoms/ScrollToTop'
import {
  DECREASE_COVER_GA_TAG,
  DECREASE_COVER_DECLARATION_PAGE,
  ALTERATION_TYPES,
} from '../../../../constants/alterations'
import { ACTION_TYPES, SAVE_DRAFT_APPLICATION } from '../../../../constants/application'

// constants
import { NAVIGATION_DECREASE, FOOTER_DECREASE } from '../../../../constants/navigation'

// Styled Components
const Wrap = styled('div')(styles.wrap)
const ContainerWrap = styled('div')(styles.containerWrap)
const StyledHeading = styled(Heading)(styles.heading)
const FixedHeightFrame = styled('main')(styles.fixedHeightFrame)
const StyledDeclarationText = styled('div')(styles.declarationText)
const CTAContainer = styled('div')(styles.aCTAContainer)
const CTACheckbox = styled(Checkbox)(styles.aCTACheckbox)
const CTAButton = styled(Button)(styles.aCTAButton)

const WHITESPACE = ' '

export const AltsDeclaration = ({ fields, actions }) => {
  const dispatch = useDispatch()
  const timelineState = useSelector(state => state.timelineWithComponents)
  const { isFetchingData, saveQuoteError, action } = useSelector(state => state.createQuote)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const isAdvisorPortal = useSelector(getIsAdviserPortal)
  const productRules = useSelector(getAltsProductRules)
  const alterationType = useSelector(getAlterationType())
  const isDecreaseCover = useSelector(isDecreaseQuote)
  const [isChecked, setChecked] = useState(false)
  const alterationTypeSelectedByUser = useSelector(getAlterationTypeSelectedByUser)
  // Sitecore fields
  const {
    Header,
    DeclarationText,
    DeclarationConsent,
    SubmitCTA,
    ModalCTA,
    ModalDesc,
    ModalTitle,
  } = reduceAuthorableFields(fields)

  const [showLoader, setShowLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // Added state to prevent rendering of error modal on initial render
  const [skipInitialErrorRender, setErrorRender] = useState(true)

  useEffect(() => {
    if (alterationTypeSelectedByUser === ALTERATION_TYPES.DECREASE_RISK) {
      dispatch(actionCreators.changeNavigationType(NAVIGATION_DECREASE))
      dispatch(actionCreators.changeFooterType(FOOTER_DECREASE))
    }
  }, [alterationTypeSelectedByUser])

  useEffect(() => {
    setShowLoader(isFetchingData)
    if (isFetchingData) setErrorRender(false)
    if (!isFetchingData && !skipInitialErrorRender && Boolean(saveQuoteError) && !isDecreaseCover) {
      setShowModal(true)
    }
  }, [isFetchingData, saveQuoteError])

  useEffect(() => {
    if (timelineState.activeComponent === 'AltsDeclaration') {
      const tagEvent = createEvent({
        GA: {
          category: isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationType,
          action: isDecreaseCover
            ? 'Decrease cover declaration - try again'
            : 'Declaration and submission page load',
        },
        Splunk: {
          attributes: {
            'workflow.name': `${isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationType} - ${
              isDecreaseCover ? 'Declaration page load' : 'Declaration and submission page load'
            }`,
          },
        },
      })
      tagEvent.end()
    }
  }, [timelineState])

  const onCheckBoxChecked = () => {
    setChecked(!isChecked)
    const tagEvent = createEvent({
      GA: {
        category: isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationType,
        action: !isChecked ? 'Decrease cover - declaration on' : 'Decrease cover - declaration off',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationType} - ${
            !isChecked ? 'Declaration on' : 'Declaration off'
          }`,
        },
      },
    })
    tagEvent.end()
  }

  // Strip style attributes from declaration as it fails in CARM with escape quotes
  const stripStyleTag = str =>
    str.replace(str.slice(str.indexOf('style') - 1, str.indexOf('">') + 1), '')

  const onClick = () => {
    const {
      saveQuote,
      saveSelectedPoliciesQuote,
      updateAdviserDeclarationConsents,
      updateDeclarationAuthorisationConsents,
      updateQuoteStatus,
    } = actions

    const quoteCollectionName = `${firstName}${WHITESPACE}${lastName}`

    if (isChecked) {
      const consent = {
        targetedIndex: 0,
        [isAdvisorPortal ? 'isAdviserDeclarationChecked' : 'isDeclarationAuthorizationChecked']:
          isChecked,
        description: `<p>${DeclarationConsent}</p><p>${stripStyleTag(DeclarationText)}</p>`,
      }

      const saveConsentInCreateQuote = isAdvisorPortal
        ? updateAdviserDeclarationConsents
        : updateDeclarationAuthorisationConsents
      saveConsentInCreateQuote(consent)
    }

    if (isDecreaseCover && action === SAVE_DRAFT_APPLICATION) {
      updateQuoteStatus({
        actionName: ACTION_TYPES.SAVE_DIGITAL_ALTERATION,
      })
    }

    if (isDecreaseCover) {
      saveQuote(
        err => {
          if (!err) {
            history.push(DECREASE_ALTS_APPLICATION_SUMMARY)
          }
        },
        'id-1',
        quoteCollectionName,
        firstName,
        lastName,
        productRules
      )
    } else {
      saveSelectedPoliciesQuote(
        err => {
          if (!err) {
            history.push(ALTERATIONS_APPLICATION_SUMMARY)
          }
        },
        'id-1',
        quoteCollectionName,
        firstName,
        lastName,
        productRules
      )
    }

    const tagEvent = createEvent({
      GA: {
        category: isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationType,
        action: 'Decrease cover - submit application',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${
            isDecreaseCover ? DECREASE_COVER_GA_TAG : alterationType
          } - Decrease cover - submit application`,
        },
      },
    })
    tagEvent.end()
  }

  const onModalClose = () => {
    setShowModal(false)
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Modal close - declaration page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - Modal close - declaration page`,
        },
      },
    })
    tagEvent.end()
  }

  const onModalSubmit = () => {
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Try again on modal - declaration page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - Try again on modal - declaration page`,
        },
      },
    })
    tagEvent.end()
    setShowModal(false)
    onClick()
  }

  return (
    timelineState.activeComponent === DECREASE_COVER_DECLARATION_PAGE && (
      <Fragment>
        <WithLoader
          isLoading={showLoader}
          loaderProps={{ type: 'tab' }}
          childrenBackgroundColor={colours.white}
        >
          <ScrollToTop>
            <Wrap id="testId">
              <ContainerWrap>
                <BackCTA fields={fields} />
                <StyledHeading size="large" variant="h2">
                  {Header}
                </StyledHeading>
                <FixedHeightFrame>
                  <StyledDeclarationText>
                    {renderTextField(DeclarationText, true)}
                  </StyledDeclarationText>
                </FixedHeightFrame>
                <CTAContainer>
                  <CTACheckbox
                    htmlFor="declarationCheckBox"
                    name="consentCheckBox"
                    id="declarationCheckBox"
                    text={DeclarationConsent}
                    onChangeHandler={onCheckBoxChecked}
                    checked={isChecked}
                  />
                  <CTAButton id="ctaButton" disabled={!isChecked} onClick={onClick}>
                    {renderTextField(SubmitCTA)}
                  </CTAButton>
                </CTAContainer>
              </ContainerWrap>
            </Wrap>
          </ScrollToTop>
        </WithLoader>
        <Modal isOpen={showModal} onClose={onModalClose} title={ModalTitle}>
          <p>{renderTextField(ModalDesc)}</p>
          <Button type="secondary" onClick={onModalSubmit}>
            {ModalCTA}
          </Button>
        </Modal>
      </Fragment>
    )
  )
}

AltsDeclaration.propTypes = {
  // Sitecore authorable fields.
  fields: objectOf(
    shape({
      value: string,
    })
  ).isRequired,
}

// TODO:: Replace the alterations with a relevant selector
const mapStateToProps = ({ timelineWithComponents, actions, alterations }) => ({
  timelineWithComponents,
  actions,
  alterations,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AltsDeclaration)
