import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { Heading } from '@mlcl-digital/mlcl-design'

// FIXME: ts-expect-error comment can be removed when files are converted to typescript
// styles
import styles from './ineligiblePolicies.styles'

// components
// @ts-expect-error non-ts code
import { CardContainer } from '../../../../../molecules/CardChildren'
// @ts-expect-error non-ts code
import { AltsPolicyCard } from '../../../../../molecules/AltsPolicyCard'

// types
import { Store } from '../../../../../../types/store'
import { ClientPolicy } from '../../../../../../types/ClientPolicies'
import { SitecoreField } from '../../../../../../types/sitecore'

// selectors
import {
  getEligiblePolicies,
  // @ts-expect-error non-ts code
} from '../../../../../../selectors/alterations'
import { getMasterData } from '../../../../../../selectors/common.selectors'

// utils
// @ts-expect-error non-ts code
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
// @ts-expect-error non-ts code
import { getCollectionFrequency } from '../../../../../../utils/paymentUtils'
// @ts-expect-error non-ts code
import { addOptimiserSuffixToBenefitName } from '../../../../../../utils/policyUtils'
// @ts-expect-error non-ts code
import { decreasePolicyInvalidMessage } from '../../../../../../utils/alterationRules'

// constants
import { ALTERATION_TYPES } from '../../../../../../constants/alterations'
import { BENEFIT_STATE_INFORCE } from '../../../../../../constants/benefit'
import {
  POLICY_IDENTIFIER_POLICY_ID,
  POLICY_STATE_INFORCE,
} from '../../../../../../constants/policies'
import { getProductIdConfig } from '../../../../../../constants/forms'

const IneligibleCardContainer = styled(CardContainer)(styles.ineligiblCardContainer)
const SectionHeading = styled(Heading)(styles.heading)
const InEligiblePoliciesContainer = styled('div')(styles.ineligiblePoliciesContainer)

type IneligiblePoliciesProps = {
  fields: {
    PolicySectionIneligibleDecreaseHeaderText: SitecoreField
    policyDetailsSubHeading: SitecoreField
    PremiumFrequency: SitecoreField
  }
}

const IneligiblePolicies = ({ fields }: IneligiblePoliciesProps) => {
  const { policies, rules } = useSelector((state: Store) => state.alterations)
  const masterData = useSelector(getMasterData)
  const eligiblePolicies: ClientPolicy[] = useSelector(
    getEligiblePolicies(ALTERATION_TYPES.DECREASE_RISK)
  )
  const inforceIneligiblePolices = policies
    .filter(policyData => policyData?.policy?.status === POLICY_STATE_INFORCE)
    .filter(
      policy =>
        !eligiblePolicies.some((p: ClientPolicy) => p.bancsPolicyNo === policy.bancsPolicyNo)
    )

  const { PolicySectionIneligibleDecreaseHeaderText } = reduceAuthorableFields(fields) as {
    [key: string]: string
  }

  const renderPolicyItem = (policy: ClientPolicy) => {
    const {
      policy: {
        policyName,
        productId = '',
        identifiers,
        policyPremiumFrequency = '',
        paymentDetails,
        benefits,
      },
      bancsPolicyNo,
    } = policy

    const policyId = identifiers
      ? identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID)?.value
      : bancsPolicyNo

    const { policyDetailsSubHeading, PremiumFrequency } = fields

    const mainHeadingText = policyName || '-'
    const subHeadingText = `${policyDetailsSubHeading?.value} ${policyId || ''}`
    const collectionFrequency = (getCollectionFrequency(
      paymentDetails?.collectionFrequency || policyPremiumFrequency
    ) || '') as string
    const policyBenefitAmounts = benefits?.reduce<Array<{ benefitName: string; index: number }>>(
      (acc, item, i) => {
        if (item.benefitStatus === BENEFIT_STATE_INFORCE) {
          return [
            ...acc,
            {
              benefitName: addOptimiserSuffixToBenefitName(item, false) as string,
              index: i,
            },
          ]
        }
        return acc
      },
      []
    )

    const policyType = get(getProductIdConfig(productId), 'policyType', '') as string
    const props = {
      policyType,
      subHeadingText,
      mainHeadingText,
      policyUnavailableText: decreasePolicyInvalidMessage(
        rules?.businessData?.policies ?? [],
        bancsPolicyNo,
        (masterData?.alterationsModalMessages ?? []).concat(masterData?.rejectCPICardMessages ?? [])
      ) as string,
      collectionFrequency: (PremiumFrequency?.value ?? '').replace('##', collectionFrequency),
      policyBenefitAmounts,
      stampDuty: '',
      policyPremiumValue: '',
      policyFee: '',
      policyPremiumText: '',
      policySavingsLabel: '',
    }

    return (
      <AltsPolicyCard
        {...props}
        cardKeyPolicyNumber={policy.bancsPolicyNo}
        key={policy.bancsPolicyNo}
        fields={fields}
        flexGrow
      />
    )
  }

  return (
    inforceIneligiblePolices.length > 0 && (
      <InEligiblePoliciesContainer>
        <SectionHeading variant="h3">{PolicySectionIneligibleDecreaseHeaderText}</SectionHeading>
        <IneligibleCardContainer>
          {inforceIneligiblePolices.map(dataItem => renderPolicyItem(dataItem))}
        </IneligibleCardContainer>
      </InEligiblePoliciesContainer>
    )
  )
}

export default IneligiblePolicies
