// @flow
import React, { Fragment } from 'react'
// redux.
import { connect } from 'react-redux'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
// import { translate } from 'react-i18next'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { SiteWrap } from '@mlcl-digital/mlcl-design'
import get from 'lodash/get'
import WithLoader from './components/molecules/WithLoader'

// atoms
import ScrollToTop from './components/atoms/ScrollToTop'

// components.
import SessionTimeout from './components/molecules/SessionTimeout'
import Sidebar from './components/organisms/Sidebar'
import { CUSTOMER_TELE_UNDERWRITER_HOME_PAGE } from './constants/personalStatement'
import { getSessionTimeoutMsg } from './selectors/layout'

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
// const Navigation = translate()(MainNavigation)

type LayoutProps = {
  // An object containing action creator functions.
  actions: Object,
  // An object containing the routes data contract with Sitecore over JSS.
  route: Object,
  // Redux authentication
  authentication: Object<Object>,
  // History object
  history: Object<Object>,
  // Environment config
  config: Object<Object>,
  // Redux okta
  okta: Object<Object>,
  // Redux myLink
  myLink: Object<Object>,
  timeOutMsg: String,
}

class Layout extends React.PureComponent<LayoutProps> {
  // render default layout
  renderDefaultLayout = ({ actions, route, authentication }: LayoutProps) => (
    <SiteWrap
      id={route.name || ''}
      className={`page-container ${get(route, 'fields.cssClass.value', '')}`}
    >
      {route.placeholders['jss-navigation'] && (
        <Placeholder name="jss-navigation" rendering={route} actions={actions} />
      )}
      {route.placeholders['jss-main'] && (
        <Placeholder
          name="jss-main"
          rendering={route}
          actions={actions}
          authentication={authentication}
        />
      )}
      {route.placeholders['jss-footer-concierge'] && (
        <Placeholder name="jss-footer-concierge" rendering={route} actions={actions} />
      )}
      {route.placeholders['jss-footer'] && (
        <Placeholder
          name="jss-footer"
          rendering={route}
          actions={actions}
          pageId={route.name || ''}
        />
      )}
      <WithLoader isLoading={authentication.isLoggingOut} overlay />
    </SiteWrap>
  )

  // render sidebar layout
  renderSidebarLayout = ({ actions, route, authentication }: LayoutProps) => (
    <Sidebar name="jss-sidebar" rendering={route} actions={actions} authentication={authentication}>
      {this.renderDefaultLayout({ actions, route, authentication })}
    </Sidebar>
  )

  render() {
    const {
      route,
      actions,
      authentication,
      history,
      config,
      okta,
      myLink = {},
      timeOutMsg,
    } = this.props
    const { authenticated, showIdealSession } = authentication
    const { hasAuthorised } = myLink
    const hideConfirmButton = history.location.pathname.includes(
      CUSTOMER_TELE_UNDERWRITER_HOME_PAGE
    )
    const hasSidebar =
      (route.placeholders['jss-sidebar'] &&
        route.placeholders['jss-sidebar'].length &&
        route.placeholders['jss-sidebar'].some(sidebar => sidebar.dataSource)) ||
      false
    return (
      <Fragment>
        {/* authenticated will get true when user get login success */}
        {/* hasAuthorised will get true when user use mylink and telewriter  */}
        {showIdealSession && (authenticated || hasAuthorised) ? (
          <>
            <SessionTimeout
              message={timeOutMsg}
              confirmBtnLabel="Confirm"
              actions={actions}
              authentication={authentication}
              history={history}
              config={config}
              okta={okta}
              myLink={myLink}
              hideCTAButton={hideConfirmButton}
            />
          </>
        ) : null}
        <ScrollToTop>
          <Helmet>
            <title>
              {(route.fields && route.fields.metaTitle && route.fields.metaTitle.value) || 'Page'}
            </title>
          </Helmet>
          {hasSidebar
            ? this.renderSidebarLayout({ actions, route, authentication })
            : this.renderDefaultLayout({ actions, route, authentication })}
        </ScrollToTop>
      </Fragment>
    )
  }
}

export const mapStateToProps = state => ({
  timeOutMsg: getSessionTimeoutMsg(state),
})

export default withRouter(connect(mapStateToProps)(Layout))
