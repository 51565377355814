import get from 'lodash/get'
// helpers.
import {
  required,
  date,
  email,
  phone,
  optional,
  numeric,
  declarationRegex,
  ageRangeCondition,
  manualAddressRegex,
  manualAddressPostalCode,
  firstNameLastName,
  skipValidation,
} from '../../../utils/formUtils'
import { TITLES, ADVISER_TYPE, STATES } from '../../../constants/forms'
import {
  POLICY_PARTY_TYPE_INDIVIDUAL,
  POLICY_PARTY_TYPE_BUSINESS,
} from '../../../constants/policies'
import { AUTHORIZED_REPRESENTATIVES_FIELD_NAME } from '../../../constants/adviserRegistration'

export const FORM_ID = 'adviserRegistration'
export const RESIDENTIAL_ADDRESS_FIELD = 'residentialAddress'
export const RESIDENTIAL_STATE = 'residentialState'
export const EARNINGS = 'earningsPA'
export const PRIMARY_OCCUPATION = 'primaryOccupation'
export const DATE_OF_BIRTH = 'dateOfBirth'
export const CONFIRM_MODAL = 'confirmModal'

const schema = (isManualPostal, isManualResidential, postalHidden, fields, messagefields) => {
  const partyType = get(fields, 'partyType.value.value', POLICY_PARTY_TYPE_INDIVIDUAL)

  return {
    partyType: {
      condition: { condition: required },
      options: ADVISER_TYPE,
    },
    title: {
      condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && required,
      options: TITLES,
    },
    firstName: {
      condition: [
        { condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && required },
        {
          condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && firstNameLastName,
        },
      ],
    },
    lastName: {
      condition: [
        { condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && required },
        {
          condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && firstNameLastName,
        },
      ],
    },
    dateOfBirth: {
      condition: [
        { condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && required },
        { condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && date },
        {
          condition: partyType === POLICY_PARTY_TYPE_INDIVIDUAL && ageRangeCondition(11, 70),
        },
      ],
    },
    businessName: {
      condition: [{ condition: optional }],
    },
    termsAndCondition: {
      condition: [{ condition: declarationRegex }],
      errorMsg: '',
    },
    primaryAddress: {
      condition: [{ condition: optional }],
    },
    abnNumber: {
      condition: [
        { condition: partyType === POLICY_PARTY_TYPE_BUSINESS && required },
        { condition: partyType === POLICY_PARTY_TYPE_BUSINESS && numeric },
      ],
    },
    afslNumber: {
      condition: [{ condition: required }, { condition: numeric }],
    },
    asicRepNo: {
      condition: [{ condition: required }, { condition: numeric }],
    },
    afslName: {
      condition: [{ condition: required }],
    },
    email: {
      condition: [{ condition: required }, { condition: email }],
    },
    residentialAddress: {
      condition: isManualResidential ? optional : required,
    },
    isPostalResidentialAddressSame: { condition: optional },
    postalAddress: { condition: isManualPostal || postalHidden ? optional : required },
    contactNumber: {
      condition: [{ condition: required }, { condition: phone }],
    },
    contactNumberCode: {
      condition: optional,
    },
    residentialStreet: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(messagefields, 'adviserRegistrationStreetInvalidErrorMessage.value'),
        },
      ],
    },
    residentialHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(messagefields, 'adviserRegistrationHouseNoInvalidErrorMessage.value'),
        },
      ],
    },
    residentialLocality: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(messagefields, 'adviserRegistrationLocalityInvalidErrorMessage.value'),
        },
      ],
    },
    residentialState: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: get(messagefields, 'adviserRegistrationStateRequiredErrorMessage.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(messagefields, 'adviserRegistrationStateInvalidErrorMessage.value'),
        },
      ],
      options: STATES,
    },
    residentialCountry: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: get(messagefields, 'adviserRegistrationCountryRequiredErrorMessage.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(messagefields, 'adviserRegistrationCountryInvalidErrorMessage.value'),
        },
      ],
    },
    residentialPostCode: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: get(messagefields, 'adviserRegistrationPostCodeRequiredErrorMessage.value'),
            }
          : optional,
        {
          condition: manualAddressPostalCode,
          errorMsg: get(messagefields, 'adviserRegistrationPostCodeInvalidErrorMessage.value'),
        },
      ],
    },
    [AUTHORIZED_REPRESENTATIVES_FIELD_NAME]: {
      condition: skipValidation,
    },
  }
}

export default schema
