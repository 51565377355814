// @flow
import get from 'lodash/get'
import moment from 'moment'
import {
  POLICY_RELATIONSHIPS_AGENT,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_IDENTIFIER_POLICY_ID,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../constants/policies'
import { BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF } from '../constants/bancs'
import { getFullName } from './contactUtils'
import { getPrimaryLifeInsured, getPolicyOwner, getAllLifeInsured } from './policyUtils'

const WHITE_SEPARATOR = ' '

type RelationshipType = { roleCode: string }

export const checkAgentRole = (relationship: RelationshipType) =>
  relationship.roleCode === POLICY_RELATIONSHIPS_AGENT

export const fetchAgents = (relationships: Array<RelationshipType>) =>
  relationships.filter(checkAgentRole)

export const getPersonalDetailsData = (data: Object) => {
  const dateOfBirth = get(data, 'dateOfBirth', '')
  const fullName = getFullName(data) || ''
  const gender = get(data, 'gender', '')
  return {
    fullName,
    dateOfBirth:
      (dateOfBirth && dateOfBirth !== '' && moment(dateOfBirth).format('DD MMMM YYYY')) || '',
    gender,
  }
}

/*
  policyNumberList added in returned data, useful
  when finding unique policy owners in all policies and
  policy owner having data of list of policies in which it is owner
*/
export const getPolicyOwners = (policy: Object) => {
  const { relationships, identifiers } = policy
  const policyNo = identifiers?.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID)?.value

  // Case 1: If NULIS or IOOF policy owner, then LA details is displayed on UI
  let clientDetails = relationships.find(relationship =>
    [BANCS_CUSTOMER_NUMBER_IOOF, BANCS_CUSTOMER_NUMBER_NULIS].includes(relationship.bancsCustomerNo)
  )
  if (clientDetails) {
    // getPrimaryLifeInsured returns array with one element
    const lifeInsured = getPrimaryLifeInsured(relationships)?.[0]

    return [
      {
        relationship: { ...clientDetails, lifeInsured },
        policyNumberList: [policyNo],
      },
    ]
  }

  /*
    Case 2: SMSF policy - relationship with roleCode 'IET' is the owner,
    contains Trustee(level1 key) and Director details(level2 inside level1)
  */
  clientDetails = relationships.find(
    relationship => relationship.roleCode === POLICY_RELATIONSHIPS_SMSF
  )
  if (clientDetails) {
    return [{ relationship: clientDetails, policyNumberList: [policyNo] }]
  }

  // Case 3: All remaining type of policies, Owner is entity with roleCode 'OWR', can be multiple
  return relationships
    .filter(relationship => relationship.roleCode === POLICY_RELATIONSHIPS_OWNER)
    .map(relationship => ({
      relationship,
      policyNumberList: [policyNo],
    }))
}

export const getUniquePolicyOwners = (policyOwners: Object[]) => {
  const uniquePolicyOwners = []
  policyOwners.forEach(policyOwner => {
    const existingPolicyOwner = uniquePolicyOwners.find(
      item => item.relationship.bancsCustomerNo === policyOwner.relationship.bancsCustomerNo
    )
    if (existingPolicyOwner) {
      // there will be single element in policyNumberList in policyOwner object
      existingPolicyOwner.policyNumberList.push(policyOwner.policyNumberList[0])
    } else {
      uniquePolicyOwners.push(policyOwner)
    }
  })
  return uniquePolicyOwners
}

/**
 * Returns an array of relationships using the business rules:
 * 1. If bancsCustomerNo = NULIS for roleCode = OWR and roleCode = LA then return the LA record
 * 2. If bancsCustomerNo != NULIS for roleCode = OWR and roleCode = LA
 * then return primary OWR and LA records
 *    assuming OWR record != LA record
 * 3. If OWR and LA records are redundant, then return back the unique set of records
 * @returns Array<Object>: [{relationship}]
 * */
export const getUniqueRelationships: Array<Object> = (policies, fetchPrimaryLifeInsured = true) => {
  const allPolicyRelationships = []

  policies &&
    policies.forEach(eachPolicy => {
      const relationshipsInPolicy = get(eachPolicy, 'policy.relationships', [])

      let policyOwnersInPolicy = getPolicyOwner(relationshipsInPolicy)
      // Convert to array as getPolicyOwner can return an object or an array !!
      if (policyOwnersInPolicy && !Array.isArray(policyOwnersInPolicy)) {
        policyOwnersInPolicy = [policyOwnersInPolicy]
      }
      if (policyOwnersInPolicy) {
        // if there is1 record, then if there is a NULIS as OWR, then dont add that relationship
        if (policyOwnersInPolicy.length === 1) {
          if (
            ![BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(
              policyOwnersInPolicy[0].bancsCustomerNo
            )
          ) {
            allPolicyRelationships.push(policyOwnersInPolicy[0])
          }
        } else {
          // If more than one record, then there is no NULIS as OWR.
          allPolicyRelationships.push(...policyOwnersInPolicy)
        }
      }

      const lifeInsuredInPolicyArray = fetchPrimaryLifeInsured
        ? getPrimaryLifeInsured(relationshipsInPolicy)
        : getAllLifeInsured(relationshipsInPolicy)
      allPolicyRelationships.push(...lifeInsuredInPolicyArray)
    })

  // Derive an object with key as bancscustomerNo
  // and add roles key: an array of unique roleCodes
  const uniqueRelationshipsObj = {}
  allPolicyRelationships.forEach(relationship => {
    const { bancsCustomerNo, roleCode } = relationship
    if (Object.keys(uniqueRelationshipsObj).includes(bancsCustomerNo)) {
      if (!uniqueRelationshipsObj[bancsCustomerNo].roles.includes(roleCode)) {
        uniqueRelationshipsObj[bancsCustomerNo].roles =
          uniqueRelationshipsObj[bancsCustomerNo].roles.concat(roleCode)
      }
    } else {
      uniqueRelationshipsObj[bancsCustomerNo] = {
        ...relationship,
        roles: [relationship.roleCode],
      }
    }
  })

  // Return array of unique relationships
  return Object.values(uniqueRelationshipsObj)
}

/**
 * if roles: ['LA'] return LifeInsuredField
 * if roles: ['OWR'] return PolicyOwnerField
 * if roles: ['LA'] return PolicyOwnerAndLifeInsuredField
 * @returns Heading based on roles array
 */
const constructPartyRoleHeading = (
  roles,
  PolicyOwnerField,
  LifeInsuredField,
  PolicyOwnerAndLifeInsuredField
) => {
  if (roles.length === 1) {
    return roles.includes(POLICY_RELATIONSHIPS_OWNER) ? PolicyOwnerField : LifeInsuredField
  }
  return PolicyOwnerAndLifeInsuredField
}

/**
 * Returns heading based on roles and partyType
 * Uses firstName lastName for partyType=PER
 * Uses businessName for partyType=ORG
 */
export const constructHeading = (
  roleCode,
  roles,
  PolicyOwnerField,
  LifeInsuredField,
  PolicyOwnerAndLifeInsuredField,
  partyType,
  firstName,
  lastName,
  businessName
) => {
  const partyRoleHeading = constructPartyRoleHeading(
    roles,
    PolicyOwnerField,
    LifeInsuredField,
    PolicyOwnerAndLifeInsuredField
  )
  if (roleCode === POLICY_RELATIONSHIPS_OWNER) {
    return `${partyRoleHeading}${
      partyType === POLICY_PARTY_TYPE_INDIVIDUAL
        ? `${firstName}${WHITE_SEPARATOR}${lastName}`
        : `${businessName}`
    }`
  }
  return `${partyRoleHeading}${firstName}${WHITE_SEPARATOR}${lastName}`
}
