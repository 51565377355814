import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, mediaQueries, fontFamily, fontSize } = Variables

export default {
  pageContainer: {
    backgroundColor: colours.tertiarySix,
    margin: '0 auto',
    maxWidth: space(150, true),
    padding: '0 space(3, true)',
  },
  rowOneContainer: {
    [mediaQueries.md]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  leftContainer: {
    [mediaQueries.md]: {
      width: '45%',
    },
  },
  leftContent: {
    padding: `${space(5, true)} ${space(2, true)} ${space(3, true)}`,
    [mediaQueries.md]: {
      maxWidth: space(48, true),
      padding: space(2, true),
    },
  },
  rightContainer: {
    backgroundColor: colours.tertiaryFive,
    [mediaQueries.md]: {
      position: 'relative',
      width: '55%',
      display: 'flex',
      flexGrow: 1,
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '100%',
        top: 0,
        height: '100%',
        width: 'calc((100vw - 1200px)/2)',
        backgroundColor: colours.tertiaryFive,
      },
    },
  },
  rightChildContainer: {
    padding: `0 ${space(2, true)} ${space(5, true)}`,
    [mediaQueries.md]: {
      marginTop: space(2, true),
      padding: 0,
    },
  },
  leftChildContainer: {
    padding: `${space(5, true)} ${space(2, true)} ${space(1, true)}`,
    width: space(46, true),
    [mediaQueries.md]: {
      padding: space(2, true),
    },
  },
  rowTwoContainer: {
    padding: `${space(5, true)} ${space(2, true)} ${space(9, true)}`,
    [mediaQueries.md]: {
      padding: `${space(2, true)} ${space(2, true)} ${space(5, true)}`,
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  rowTwoLeftContent: { marginBottom: space(2, true) },
  rowTwoContainerRight: {
    [mediaQueries.md]: {
      width: '55%',
    },
  },
  rowTwoRightContent: {
    boxSizing: 'border-box',
    width: '100%',
    border: `2px solid  ${colours.functionalInfoDark}`,
    background: colours.functionalInfoLight,
    padding: space(3, true),
    display: 'inline-block',
    [mediaQueries.md]: {
      width: 'auto',
    },
  },

  styledText: {
    fontFamily: fontFamily.sourceSans,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    color: colours.functionalInfoDark,
    display: 'flex',
    ul: {
      marginLeft: space(3, true),
    },
  },

  listIcon: {
    svg: {
      width: space(3, true),
      height: space(3, true),
      marginRight: space(2, true),
      color: colours.functionalInfoDark,
    },
  },

  rowTwoCTAWrapper: { margin: `${space(3, true)} 0` },
  styledButton: {
    width: '100%',
    [mediaQueries.md]: {
      width: 'auto',
    },
  },
}
