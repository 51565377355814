// @flow
import React, { useCallback, Fragment, useState } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components
import Layout from '../../molecules/PersonalStatementLayout'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Checkbox from '../../atoms/Checkbox'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'

// constants
import { DASHBOARD_ROUTE, SUPPORT_STAFF_SELECT_ADVISER_ROUTE } from '../../../constants/routes'

// styles
import styles from './SupportStaffDeclaration.styles'

const Main = styled('div')(styles.main)
const ConsentTitle = styled(Heading)(styles.title)
const Consent = styled('div')(styles.consent)
const ContinueSection = styled('div')(styles.continueSection)
const ErrorMessage = styled('p')(styles.errorMessage)

type DeclarationProps = {
  fields: Object,
  actions: {
    saveSupportStaffConsent: Function,
  },
  activeSupportStaff: Object,
}

export const Declaration = (props: DeclarationProps) => {
  const { fields, actions, activeSupportStaff } = props
  const [hasError, toogleError] = useState(false)
  const [inputChecked, changeInput] = useState(false)
  const { saveSupportStaffConsent } = actions
  const {
    SupportStaffDeclarationPageTitle,
    SupportStaffDeclarationAccordianTitle,
    SupportStaffDeclarationConsentVersion,
    SupportStaffDeclarationConsentText,
    SupportStaffDeclarationSubmitButton,
    SupportStaffDeclarationConsentCheckboxLabel,
    SupportStaffDeclarationCheckboxErrorMessage,
  } = fields

  const handleSubmitConsent = useCallback(() => {
    if (!inputChecked) {
      toogleError(true)
      return
    }

    const { firstName, lastName, identifiers } = activeSupportStaff

    const customerNo = identifiers.find(identifier => identifier.type === 'BANCS_CUSTOMER_NO')

    const payload = {
      name: `${firstName} ${lastName}`.trimRight(),
      advsupportstaffid: `${get(customerNo, 'value', '')}`,
      hasacceptedterms: 'true',
      version: SupportStaffDeclarationConsentVersion.value,
    }

    saveSupportStaffConsent(payload, data => {
      if (data) {
        history.push(DASHBOARD_ROUTE)
      }
    })
  }, [hasError, inputChecked])

  const backClickHandler = useCallback(() => {
    history.push(SUPPORT_STAFF_SELECT_ADVISER_ROUTE)
  }, [])

  const inputHandler = useCallback(
    ({ value }) => {
      changeInput(value)
      toogleError(!value)
    },
    [hasError, inputChecked]
  )

  const renderErrorMessage = errorMessage => <ErrorMessage>{errorMessage}</ErrorMessage>

  const renderDeclaration = () => (
    <Fragment>
      <ConsentTitle size={3}>{renderTextField(SupportStaffDeclarationAccordianTitle)}</ConsentTitle>
      <Consent>
        {renderTextField(SupportStaffDeclarationConsentText, true)}
        <Checkbox
          htmlFor="confirmDisclosure"
          text={renderTextField(SupportStaffDeclarationConsentCheckboxLabel, true)}
          name="confirmDisclosure"
          value={0}
          onChangeHandler={inputHandler}
          checked={inputChecked}
        />
        {hasError &&
          renderErrorMessage(renderTextField(SupportStaffDeclarationCheckboxErrorMessage))}
      </Consent>
      <ContinueSection>
        <Button onClick={handleSubmitConsent}>
          {renderTextField(SupportStaffDeclarationSubmitButton)}
        </Button>
      </ContinueSection>
    </Fragment>
  )

  return (
    <Layout
      fields={fields}
      pageTitle={SupportStaffDeclarationPageTitle}
      fillChildBackground={false}
      showBackIcon
      backClickHandler={backClickHandler}
    >
      <Main>{renderDeclaration()}</Main>
    </Layout>
  )
}

export const mapStateToProps = ({ supportStaff: { activeSupportStaff } }) => ({
  activeSupportStaff,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Declaration)
