import {
  colours,
  fontSize,
  space,
  fontFamily,
  fontWeights,
  mediaQueries,
} from '../../../../../styles'

import browser from '../../../../../utils/browserUtils'

const isIE11 = browser.ie && browser.ie === 11

const styles = {
  wrapper: {
    width: '100%',
    backgroundColor: colours.white,
    padding: `${space(5, true)} ${space(3, true)} ${space(3, true)}`,
  },
  itemContainer: {
    color: colours.mediumDarkGrey,
    display: isIE11 ? 'table-row' : 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  itemContainerExcSuper: {
    justifyContent: 'flex-end',
  },
  valueContainer: {
    flex: 1,
    '&:first-child(-n+3)': {
      width: '50%',
      maxWidth: '50%',
    },
  },
  container: {
    width: '100%',
  },
  title: ({ applicationView }) => ({
    fontSize: fontSize.xs,
    fontFamily: fontFamily.sourceSans,
    lineHeight: fontSize.md,
    color: colours.darkGrey,
    marginBottom: space(4, true),
    fontWeight: applicationView ? 'bold' : 'normal',
    borderBottom: applicationView ? `1px solid ${colours.lightestGrey}` : 'none',
    paddingBottom: applicationView ? space(4, true) : 0,
  }),
  label: ({ applicationView }) => ({
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    width: applicationView ? space(23) : space(18),
  }),
  column: {
    width: '100%',
    display: 'inline-block',
    [mediaQueries.md]: {
      verticalAlign: 'top',
    },
  },
  valueWapper: {
    display: 'block',
    padding: `0 ${space(3, true)} ${space(4, true)}`,
  },
}

export default styles
