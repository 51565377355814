// utils
import {
  email,
  required,
  optional,
  phone,
  phoneInputs,
  postcode,
  manualAddressRegex,
  manualAddressPostalCode,
  getFieldValueFromForm,
  mobilePhone,
  regexTest,
} from '../../../../../utils/formUtils'

// constants
import { STATES } from '../../../../../constants/forms'
import { DEFAULT_PHONE_CODE } from '../../../../../constants/contactDetails'

export const FORM_ID = 'contactDetails'
const schema = (fields, isManual, isManualPostal, postalHidden, isPOManual) => ({
  mobile: {
    condition: [
      { condition: required, errorMsg: fields.ContactNoReqValidatiorMessage },
      {
        condition: (value, formFields) => {
          const phoneCodeValue = getFieldValueFromForm('mobilePhoneCode', {
            fields: formFields,
          })
          const phoneRegex = phoneCodeValue === DEFAULT_PHONE_CODE ? mobilePhone : phone
          return !regexTest(phoneRegex, value)
        },
        errorMsg: fields.ContactNoValidationMessage,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields.ContactNoReqValidatiorMessage,
  },
  mobilePhoneCode: {
    condition: required,
  },
  address: {
    condition: isManual ? optional : required,
  },
  street: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.streetRequiredErrorMessage,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.contactDetailsStreetInvalidErrorMessage,
          }
        : {},
    ],
  },
  houseNo: {
    condition: [
      optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.contactDetailsHouseNumberInvalidErrorMessage,
      },
    ],
    minimum: 0,
  },
  locality: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.localityRequiredErrorMessage,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.contactDetailsLocalityInvalidErrorMessage,
          }
        : {},
    ],
  },
  state: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.stateRequiredErrorMessage,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.contactDetailsStateInvalidErrorMessage,
          }
        : {},
    ],
    options: STATES,
  },
  country: {
    condition: [isManual ? required : optional],
  },
  postCode: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.postCodeRequiredErrorMessage,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressPostalCode,
            errorMsg: fields.contactDetailsPostCodeInvalidErrorMessage,
          }
        : {},
      postcode,
    ],
    minimum: 0,
  },
  email: {
    condition: [required, { condition: email, errorMsg: fields.emailValidError }],
    errorMsg: fields.emailError,
  },
  isPostalResidentialAddressSame: {
    condition: optional,
  },
  postalAddress: {
    condition: isManualPostal || postalHidden ? optional : required,
  },
  postalHouseNo: {
    condition: optional,
    minimum: 0,
  },
  postalAddressPONo: {
    condition: optional,
    minimum: 0,
  },
  postalStreet: {
    condition: [
      !isPOManual && isManualPostal
        ? {
            condition: required,
            errorMsg: fields.streetRequiredErrorMessage,
          }
        : optional,
      isManualPostal
        ? {
            condition: isPOManual && isManualPostal ? /^$/ : manualAddressRegex,
            errorMsg:
              isPOManual && isManualPostal
                ? fields.ContactDetailsFormPostalAddressInvalidStreetFieldErrorIfPOBox
                : fields.contactDetailsStreetInvalidErrorMessage,
          }
        : {},
    ],
  },
  postalLocality: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.localityRequiredErrorMessage,
          }
        : optional,
      isManualPostal
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.contactDetailsLocalityInvalidErrorMessage,
          }
        : {},
    ],
  },
  postalState: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.stateRequiredErrorMessage,
          }
        : optional,
      isManualPostal
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.contactDetailsStateInvalidErrorMessage,
          }
        : {},
    ],
    options: STATES,
  },
  postalCountry: {
    condition: [isManualPostal ? required : optional],
  },
  postalPostCode: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.postCodeRequiredErrorMessage,
          }
        : optional,
      isManualPostal
        ? {
            condition: manualAddressPostalCode,
            errorMsg: fields.contactDetailsPostCodeInvalidErrorMessage,
          }
        : {},
      postcode,
    ],
    minimum: 0,
  },
})

export default schema
