// @flow
import React from 'react'
// Organism
import AltsSubmissionOrganism from '../../../organisms/AltsSubmission'

type AltsSubmissionProps = {
  fields: Object,
}

const AltsSubmission = ({ fields }: AltsSubmissionProps) => (
  <AltsSubmissionOrganism fields={fields} />
)

export default AltsSubmission
