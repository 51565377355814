// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import { PopOverMenu, Icons, Variables } from '@mlcl-digital/mlcl-design'
import Date from '@mlcl-digital/mlcl-design/lib/base/Date'

// hooks.
import useComponentVisible from '../../../../../hooks/useComponentVisible'

// styles.
import styles from '../PolicySetupPolicy/policySetupPolicy.styles'

const { space } = Variables
const { IconAdd32, IconEdit32 } = Icons

export const AddIcon = styled(IconAdd32)(styles.icon, ({ active }) => active && styles.iconActive)
export const EditIcon = styled(IconEdit32)(styles.icon, ({ active }) => active && styles.iconActive)

type DateSettingActionProps = {
  // settings list items
  popoverItems: Array<Object>,
  // props for icon component
  iconProps: Object,
  // value to be displayed in card
  value: String,
  // moment date object
  selectedDate?: Object,
  maxDate?: string,
}

const todayDate = moment()

const DateSettingAction = ({
  iconProps,
  value,
  popoverItems,
  isOpenCalendar,
  maxDate,
  onChange,
  selectedDate,
}: DateSettingActionProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const handleIconClick = () => {
    setIsComponentVisible(true)
  }
  const dateChange = date => {
    onChange(date)
  }

  return (
    <Fragment>
      <Fragment>
        {value !== ' ' ? (
          <EditIcon {...iconProps} data-testid="Edit Inforce Date" onClick={handleIconClick} />
        ) : (
          <AddIcon {...iconProps} data-testid="Add Inforce Date" onClick={handleIconClick} />
        )}
      </Fragment>
      <div ref={ref}>
        {isComponentVisible && (
          <PopOverMenu
            isOpen={isComponentVisible}
            items={popoverItems}
            top={space(6, true)}
            right={space(-6, true)}
          />
        )}
      </div>
      {isOpenCalendar && (
        <Date
          onChange={dateChange}
          minDate={selectedDate}
          maxDate={maxDate}
          selected={selectedDate}
          dateFormat="mm/dd/yyyy"
          inline
        />
      )}
    </Fragment>
  )
}

DateSettingAction.defaultProps = {
  selectedDate: todayDate.toDate(),
  maxDate: todayDate.add(1, 'M').toDate(),
}

export default DateSettingAction
