/* eslint-disable max-len */
// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { NavLink as Link } from 'react-router-dom'

// components.
import Heading from '../../../../../atoms/Heading'
import { IconArrowLeft16 } from '../../../../../atoms/Icons'
import QuoteSelectDownloadAction from '../../../../QuoteTool/components/QuoteDownload'

// styles.
import styles from './header.styles'

type QuoteSummaryHeaderProps = {
  // Fields object containing authorable fields.
  fields: Object,
}

const QuoteSummaryHeaderRoot = styled('div')(styles.quoteSummaryHeaderRoot)
const QuoteSummaryBackLink = styled(Link)(styles.quoteSummaryBackLink)
const QuoteSummaryBackLinkIcon = styled(IconArrowLeft16)(styles.quoteSummaryBackLinkIcon)
const QuoteSummaryContent = styled('div')(styles.quoteSummaryContent)
const QuoteSummaryTitle = styled(Heading)(styles.quoteSummaryTitle)
const QuoteSummarySubTitle = styled('p')(styles.quoteSummarySubTitle)
const QuoteSummaryDownloadLinkWrapper = styled('div')(styles.quoteSummaryDownloadLinkWrapper)

/**
 * QuoteSummaryHeader
 * This is a react component which render the Quote Summary Page Header info
 * like title, subtitle, download link, back link
 *
 * @param {object} fields
 */
const QuoteSummaryHeader = ({ fields }: QuoteSummaryHeaderProps) => {
  const {
    quoteSummaryBackLink,
    quoteSummaryPageTitle,
    quoteSummaryPageSubtitle,
    quoteSummaryDownloadLink: quoteEntitySecondaryActionBtn,
    quoteUpdateSummaryAdviserDetailsTitle: quoteUpdateAdviserDetailsTitle,
    quoteUpdateSummaryAdviserDetailsCompanyFormHeader: quoteUpdateAdviserDetailsCompanyFormHeader,
    quoteUpdateSummaryAdviserDetailsNameLabel: quoteUpdateAdviserDetailsNameLabel,
    quoteUpdateSummaryAdviserDetailsBusinessNameLabel: quoteUpdateAdviserDetailsBusinessNameLabel,
    quoteUpdateSummaryAdviserDetailsAbnLabel: quoteUpdateAdviserDetailsAbnLabel,
    quoteUpdateSummaryAdviserDetailsContactFormHeader: quoteUpdateAdviserDetailsContactFormHeader,
    quoteUpdateSummaryAdviserDetailsAddressLabel: quoteUpdateAdviserDetailsAddressLabel,
    quoteUpdateSummaryAdviserDetailsCantFindAddressLabel:
      quoteUpdateAdviserDetailsCantFindAddressLabel,
    quoteUpdateSummaryAdviserDetailsStreetPlaceholder: quoteUpdateAdviserDetailsStreetPlaceholder,
    quoteUpdateSummaryAdviserDetailsHouseNoPlaceholder: quoteUpdateAdviserDetailsHouseNoPlaceholder,
    quoteUpdateSummaryAdviserDetailsLocalityPlaceholder:
      quoteUpdateAdviserDetailsLocalityPlaceholder,
    quoteUpdateSummaryAdviserDetailsStatePlaceholder: quoteUpdateAdviserDetailsStatePlaceholder,
    quoteUpdateSummaryAdviserDetailsCountryPlaceholder: quoteUpdateAdviserDetailsCountryPlaceholder,
    quoteUpdateSummaryAdviserDetailsPostCodePlaceholder:
      quoteUpdateAdviserDetailsPostCodePlaceholder,
    quoteUpdateSummaryAdviserDetailsManualToggleLabel: quoteUpdateAdviserDetailsManualToggleLabel,
    quoteUpdateSummaryAdviserDetailsContactLabel: quoteUpdateAdviserDetailsContactLabel,
    quoteUpdateSummaryAdviserDetailsEmailLabel: quoteUpdateAdviserDetailsEmailLabel,
    quoteUpdateSummaryAdviserDetailsSubmitButton: quoteUpdateAdviserDetailsSubmitButton,
    quoteUpdateSummaryAdviserDetailsPhoneInputInvalidErrorMsg:
      quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg,
    quoteUpdateSummaryAdviserDetailsEmailInputInvalidErrorMsg:
      quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg,
    quoteUpdateSummaryAdviserDetailsInvalidStreetErrorMsg: downloadQuoteInvalidStreetErrorMsg,
    quoteUpdateSummaryAdviserDetailsInvalidLocalityErrorMsg: downloadQuoteInvalidLocalityErrorMsg,
    quoteUpdateSummaryAdviserDetailseInvalidStateErrorMsg: downloadQuoteInvalidStateErrorMsg,
    quoteUpdateSummaryAdviserDetailsInvalidPostCodeErrorMsg: downloadQuoteInvalidPostCodeErrorMsg,
    quoteDownloadSummaryModalTitle: quoteDownloadModalTitle,
    quoteDownloadSummarySelectAllButton: quoteDownloadSelectAllButton,
    quoteDownloadSummaryModalSubmitButton: quoteDownloadModalSubmitButton,
    downloadQuoteSummarySelectOptionsHeader: downloadQuoteSelectOptionsHeader,
    downloadQuoteSummaryBackButton: downloadQuoteBackButton,
    downloadQuoteSummarySelectOptionError: downloadQuoteSelectOptionError,
    saveQuoteCollectionNameTitle: quoteCollectionNameTitle,
    saveQuoteCollectionNameDescription: quoteCollectionNameDescription,
    saveQuoteCollectionFirstNameLabel: quoteCollectionFirstNameLabel,
    saveQuoteCollectionFirstNameError: quoteCollectionFirstNameError,
    saveQuoteCollectionLastNameLabel: quoteCollectionLastNameLabel,
    saveQuoteCollectionLastNameError: quoteCollectionLastNameError,
    saveQuoteCollectionNameSubmitButtonLabel: quoteCollectionNameSubmitButtonLabel,
    saveQuoteCollectionInvalidFirstNameError: quoteCollectionInvalidFirstNameError,
    saveQuoteCollectionInvalidLastNameError: quoteCollectionInvalidLastNameError,
    downloadQuoteRequiredBusinessNameErrorMsg,
    downloadQuoteRequiredABNErrorMsg,
    downloadQuoteRequiredFullAddressErrorMsg,
    downloadQuoteRequiredEmailAddressErrorMsg,
    downloadQuoteRequiredStreetErrorMsg,
    downloadQuoteRequiredLocalityErrorMsg,
    downloadQuoteRequiredStateErrorMsg,
    downloadQuoteRequiredCountryErrorMsg,
    downloadQuoteRequiredPostCodeErrorMsg,
    downloadQuoteRequiredAdviserNameErrorMsg,
    quoteSummaryInsidePolicyNamePrefix: quoteToolInsidePolicyNamePrefix,
    quoteSummaryOutsidePolicyNamePrefix: quoteToolOutsidePolicyNamePrefix,
    quoteSummaryWrapPolicySuffix: quoteToolWrapPolicySuffix,
    quoteSummarySuperPolicySuffix: quoteToolSuperPolicySuffix,
    quoteSummaryOutsidePolicySuffix: quoteToolOutsidePolicySuffix,
  } = fields

  const quoteDownloadFields = {
    quoteUpdateAdviserDetailsTitle,
    quoteUpdateAdviserDetailsCompanyFormHeader,
    quoteUpdateAdviserDetailsNameLabel,
    quoteUpdateAdviserDetailsBusinessNameLabel,
    quoteUpdateAdviserDetailsAbnLabel,
    quoteUpdateAdviserDetailsContactFormHeader,
    quoteUpdateAdviserDetailsAddressLabel,
    quoteUpdateAdviserDetailsCantFindAddressLabel,
    quoteUpdateAdviserDetailsStreetPlaceholder,
    quoteUpdateAdviserDetailsHouseNoPlaceholder,
    quoteUpdateAdviserDetailsLocalityPlaceholder,
    quoteUpdateAdviserDetailsStatePlaceholder,
    quoteUpdateAdviserDetailsCountryPlaceholder,
    quoteUpdateAdviserDetailsPostCodePlaceholder,
    quoteUpdateAdviserDetailsManualToggleLabel,
    quoteUpdateAdviserDetailsContactLabel,
    quoteUpdateAdviserDetailsEmailLabel,
    quoteUpdateAdviserDetailsSubmitButton,
    quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg,
    quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg,
    quoteDownloadModalTitle,
    quoteDownloadSelectAllButton,
    quoteDownloadModalSubmitButton,
    downloadQuoteSelectOptionsHeader,
    downloadQuoteBackButton,
    downloadQuoteSelectOptionError,
    quoteEntitySecondaryActionBtn,
    quoteCollectionNameTitle,
    quoteCollectionNameDescription,
    quoteCollectionFirstNameLabel,
    quoteCollectionFirstNameError,
    quoteCollectionLastNameLabel,
    quoteCollectionLastNameError,
    quoteCollectionNameSubmitButtonLabel,
    downloadQuoteRequiredBusinessNameErrorMsg,
    downloadQuoteRequiredABNErrorMsg,
    downloadQuoteRequiredFullAddressErrorMsg,
    downloadQuoteRequiredEmailAddressErrorMsg,
    downloadQuoteRequiredStreetErrorMsg,
    downloadQuoteRequiredLocalityErrorMsg,
    downloadQuoteRequiredStateErrorMsg,
    downloadQuoteInvalidStateErrorMsg,
    downloadQuoteInvalidLocalityErrorMsg,
    downloadQuoteInvalidStreetErrorMsg,
    downloadQuoteInvalidPostCodeErrorMsg,
    downloadQuoteRequiredCountryErrorMsg,
    downloadQuoteRequiredPostCodeErrorMsg,
    downloadQuoteRequiredAdviserNameErrorMsg,
    quoteToolInsidePolicyNamePrefix,
    quoteToolOutsidePolicyNamePrefix,
    quoteToolWrapPolicySuffix,
    quoteToolSuperPolicySuffix,
    quoteToolOutsidePolicySuffix,
    quoteCollectionInvalidFirstNameError,
    quoteCollectionInvalidLastNameError,
  }

  return (
    <Fragment>
      <QuoteSummaryHeaderRoot>
        <QuoteSummaryBackLink to="/quote-tool">
          <QuoteSummaryBackLinkIcon />
          {quoteSummaryBackLink}
        </QuoteSummaryBackLink>
        <QuoteSummaryContent>
          <div>
            <QuoteSummaryTitle size="1">{quoteSummaryPageTitle}</QuoteSummaryTitle>
            <QuoteSummarySubTitle>{quoteSummaryPageSubtitle}</QuoteSummarySubTitle>
          </div>
          <QuoteSummaryDownloadLinkWrapper>
            <QuoteSelectDownloadAction
              align="row"
              fields={Object.keys(quoteDownloadFields).reduce(
                (fieldMap, field) => ({
                  ...fieldMap,
                  [field]: { value: quoteDownloadFields[field] },
                }),
                {}
              )}
              hasDownloadIcon
              rootStyle={styles.noPadding}
              hasPrimaryBtn={false}
              buttonType="tertiary"
              secondaryBtnTheme="primary"
              buttonStyle={styles.quoteSummaryDownloadLinkButton}
            />
          </QuoteSummaryDownloadLinkWrapper>

          <QuoteSummaryDownloadLinkWrapper />
        </QuoteSummaryContent>
      </QuoteSummaryHeaderRoot>
    </Fragment>
  )
}

export default QuoteSummaryHeader
