import { space, colours, fontSize, fontWeights } from '../../../styles'

const styles = {
  button: (isPaddingNeeded, isDisable) => ({
    flex: `0 1 calc(100% - ${space(1)}px)`,
    height: 'auto',
    justifyContent: 'flex-start',
    paddingTop: space(2.5),
    paddingBottom: space(2.5),
    paddingLeft: space(4),
    paddingRight: space(4),
    border: `2px dashed ${colours.lightestGrey}`,
    width: '100%',
    color: isDisable ? colours.lightestGrey : colours.mediumGreen,
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    padding: isPaddingNeeded ? space(2.5, true) : 0,
    cursor: isDisable ? 'not-allowed' : 'pointer',
    '&:hover, &:focus': {
      outline: 'none',
      color: isDisable ? colours.lightestGrey : colours.mediumGreen,
    },
  }),

  icon: isDisable => ({
    margin: space(1, true),
    color: isDisable ? colours.lightestGrey : colours.lightGreen,
  }),

  wrap: {
    flex: 'none',
    margin: `${space(2, true)} 0`,
    width: '100%',
  },
}

export default styles
