// @flow
import React from 'react'
import UpdateContactDetailsComponent from '../../../organisms/UpdateContactDetails'

type UpdateMobileProps = {
  // labels from sitecore for the page
  fields: {
    value: string,
  },
}

const UpdateMobile = ({ fields }: UpdateMobileProps) => (
  <UpdateContactDetailsComponent fields={fields} />
)

export default UpdateMobile
