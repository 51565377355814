// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import Button from '../../../../atoms/Button'
import Card from '../../../../atoms/Card'
import Heading from '../../../../atoms/Heading'
import Input from '../../../../atoms/Input'

// schema.
import SCHEMA, { FORM_ID } from './personalDetailsForm.schema'

// helpers.
import { errorCheck } from '../../../../../utils/formUtils'
import { getAdviserNo } from '../../../../../utils/cookieUtils'

// styles.
import styles from '../../advisorAccountSettings.styles'
import {
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../../../../../constants/policies'

const Wrap = styled(Card)(styles.base)
const Form = styled('form')(styles.form)
const Header = styled('div')(styles.header)
const Title = styled(Heading)(styles.title)
const Details = styled('div')(styles.details)
const DetailsLabel = styled('div')(styles.label)
const ValueHeading = styled(Heading)(styles.values)

type PersonalDetailsFormProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // Redux suplied form state
  form: Object<Object>,
  // An object containing action creator functions.
  actions: {
    getAdvisorDetails: Function,
    formInit: Function,
  },
  // Type of the party PER- Individual Party, ORG - Organization Party
  partyType: string,
  // advisor Details
  advisorDetails: Array<Object>,
  // determines if the advisor data is still being fetched from the server.
  isLoading: boolean,
  activeSupportStaff: Object,
  memberDetails: Object,
}

class PersonalDetailsForm extends Component<PersonalDetailsFormProps> {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount() {
    const { actions, activeSupportStaff } = this.props
    const { getAdvisorDetails } = actions
    if (getAdviserNo()) {
      // support staff
      this.initForm(activeSupportStaff)
    } else {
      getAdvisorDetails(data => {
        this.initForm(data, data.adviserDetails)
      })
    }
  }

  componentDidMount() {
    const tagEvent = createEvent({
      GA: {
        category: 'Adviser Personal details',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Adviser Personal details - View',
        },
      },
    })
    tagEvent.end()
  }

  // handle ASIC RepNo
  handleAdvisorCodes = () => {
    const { advisorDetails } = this.props
    let agencyCode = ''
    advisorDetails.forEach(advisor => {
      agencyCode = `${agencyCode}${agencyCode ? ',' : ''} ${advisor.agencyCode}`
    })
    return agencyCode
  }

  // handle organization details
  handleOrganizationDetails = () => {
    const { advisorDetails, fields, memberDetails, activeSupportStaff } = this.props
    const { personalDetailsBusinessNameLabel, abnNumberLabel, agencyCodesLabel } = fields
    let updatedMemberDetails = memberDetails
    // Show details of support staff if support staff login
    if (getAdviserNo()) {
      updatedMemberDetails = activeSupportStaff
    }
    const businessName = get(updatedMemberDetails, 'businessName', '')
    const abnNumber = get(updatedMemberDetails, 'abnNumber', '-')
    const advisorCodes = advisorDetails && advisorDetails.length ? this.handleAdvisorCodes() : ''
    return (
      <Wrap>
        <Header>
          <Title size="3" element="2">
            {fields.businessHeading}
          </Title>
          <p>{fields.businessIntro}</p>
        </Header>
        <Fragment>
          <Details>
            <DetailsLabel>{personalDetailsBusinessNameLabel}</DetailsLabel>
            <ValueHeading size="6" element="2">
              {businessName}
            </ValueHeading>
          </Details>
          <Details>
            <DetailsLabel>{abnNumberLabel}</DetailsLabel>
            <ValueHeading size="6" element="2">
              {abnNumber}
            </ValueHeading>
          </Details>
          <Details>
            <DetailsLabel>{agencyCodesLabel}</DetailsLabel>
            <ValueHeading size="6" element="2">
              {advisorCodes}
            </ValueHeading>
          </Details>
        </Fragment>
      </Wrap>
    )
  }

  // handle personal details
  handlePersoanlDetails = () => {
    const { form, fields } = this.props
    const editable = false
    if (!form) return null
    const { title, firstName, lastName, advisorCode } = form.fields
    return (
      <Wrap>
        <Form id="client" aria-labelledby="client-label">
          <Header>
            <Title size="3" element="2">
              {fields.personalHeading}
            </Title>
            <p>{fields.personalIntro}</p>
          </Header>
          <Input
            editable={editable}
            htmlFor="title"
            label={fields.titleLabel}
            name="title"
            placeholder={fields.titlePlaceholder}
            changeHandler={this.handleChange}
            error={title.error.error}
            value={title.value}
            caption={title.error.error && fields.titleError}
          />
          <Input
            editable={editable}
            htmlFor="firstName"
            label={fields.firstNameLabel}
            name="firstName"
            changeHandler={this.handleChange}
            error={firstName.error.error}
            value={firstName.value}
            caption={firstName.error.error && fields.firstNameError}
          />
          <Input
            editable={editable}
            htmlFor="lastName"
            label={fields.lastNameLabel}
            name="lastName"
            placeholder={fields.lastNamePlaceholder}
            changeHandler={this.handleChange}
            error={lastName.error.error}
            value={lastName.value}
            caption={lastName.error.error && fields.lastNameError}
          />
          <Input
            editable={editable}
            htmlFor="advisorCodes"
            label={fields.agencyCodesLabel}
            name="advisorCodes"
            placeholder={advisorCode.value}
            value={advisorCode.value}
          />
          {editable && <Button type="secondary">{fields.businessFormButton}</Button>}
        </Form>
      </Wrap>
    )
  }

  // handle changes on form elements.
  handleChange({ value, name }) {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions
    const data = {
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  initForm(data, adviserDetails = []) {
    const {
      actions: { formInit },
    } = this.props
    const { partyType } = data
    if (partyType === POLICY_PARTY_TYPE_INDIVIDUAL) {
      const { title, firstName, middleName, lastName } = data
      let advisorCode = ''
      adviserDetails.forEach(advisor => {
        advisorCode = `${advisorCode}${advisorCode ? ',' : ''} ${advisor.agencyCode}`
      })
      advisorCode = advisorCode.trim()
      formInit(FORM_ID, SCHEMA, {
        title,
        firstName,
        middleName,
        lastName,
        advisorCode,
      })
    }
  }

  render() {
    const { isLoading, partyType, activeSupportStaff } = this.props
    let updatedPartyType = partyType
    // Show details of support staff if support staff login
    if (getAdviserNo()) {
      updatedPartyType = activeSupportStaff.partyType
    }
    if (isLoading) return <Loader type="tab" />
    if (updatedPartyType === POLICY_PARTY_TYPE_BUSINESS) {
      return this.handleOrganizationDetails()
    }
    return this.handlePersoanlDetails()
  }
}

const mapStateToProps = ({ advisor, forms, supportStaff: { activeSupportStaff } }) => ({
  form: forms[FORM_ID],
  isLoading: advisor.isLoading,
  partyType: get(advisor, 'details.partyType', ''),
  advisorDetails: advisor.advisorDetails,
  memberDetails: advisor.details,
  activeSupportStaff,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsForm)
