import { colours, fontSize, space, fontWeights, pxToRem } from '../../../styles'

const styles = {
  textArea: {
    color: colours.darkGrey,
    fontSize: fontSize.xs,
  },
  footer: {
    marginTop: space(6),
    marginBottom: space(6),
  },
  formBlock: {
    maxWidth: pxToRem(496),
  },
  label: {
    marginRight: pxToRem(5),
  },
  anchor: {
    color: colours.lightGreen,
    textDecoration: 'none',
  },
  contentWrap: {
    fontSize: fontSize.xs,
  },
  contentWrapPimary: {
    fontSize: fontSize.md,
    fontWeight: fontWeights.semiBold,
    marginBottom: space(2),
    marginTop: space(3),
  },
  contentWrapHeadPimary: {
    fontSize: fontSize.md,
    fontWeight: fontWeights.semiBold,
    marginBottom: space(4),
    marginTop: space(3),
  },
  error: {
    color: colours.red,
  },
  blockWrap: {
    textAlign: 'center',
    width: '100%',
    marginTop: space(9),
    marginBottom: space(9),
  },
  loader: {
    margin: space(5),
    paddingTop: space(2),
  },
  contentFormBodyWrap: {
    marginTop: space(2),
  },
}

export default styles
