/* eslint-disable array-callback-return */
// @flow
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { withRouter } from 'react-router-dom'
import { pathOr } from 'lodash/fp'
import { PageWrap, Modal } from '@mlcl-digital/mlcl-design'
// components.
import PolicySetupPolicy from '../PolicySetupPolicy'
import Heading from '../../../../atoms/Heading'
import Button from '../../../../atoms/Button'

// utils.
import { policyHasOwner } from '../../../../../utils/quoteUtils'
import { CREATE_QUOTE_UPDATE_POLICY_STRUCTURE } from '../../../../../actions/types/createQuote'

// styles.
import styles from './policySetupView.styles'

// icons.
import { IconArrowLeft16 } from '../../../../atoms/Icons'

// styled components.
const Header = styled('header')(styles.header)
const Title = styled(Heading)(styles.title)
const Subtitle = styled(Heading)(styles.subtitle)
const Content = styled(PageWrap)(styles.content)
const Policies = styled('div')(styles.policies)
const BackButton = styled(Button)(styles.backButton)
const PolicySetupModal = styled(Modal)(styles.policySetupModal)
const ModalBody = styled('p')(styles.modalBody)
const ModalButton = styled(Button)(styles.modalButton)
const ModalFooter = styled('div')(styles.modalFooter)

// helpers.
const renderPolicy = (fields: Object) => (policy: Object) =>
  (
    <PolicySetupPolicy
      key={policy.productName}
      policy={policy}
      fields={fields}
      hasOwner={policyHasOwner(policy)}
    />
  )

type PolicySetupViewProps = {
  // sitecore fields
  fields: Object,
  // quote entity
  quote: Object,
  // action
  actions: Object,
  // history object
  history: Object,
}

const PolicySetupView = (props: PolicySetupViewProps) => {
  const { quote, fields, history, actions } = props
  const policies = pathOr([], 'policyStructure', quote)
  const [showSaveModal, setShowSaveModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [policiesPreviousState, setPoliciesPreviousState] = useState(policies)
  const dispatch = useDispatch()

  const saveQuoteCallBack = () => {
    history.push('/application')
  }
  // save policySetup
  const saveSetup = () => {
    const { saveQuote } = actions
    saveQuote(saveQuoteCallBack)
    setShowSaveModal(false)
  }

  const cancelSave = () => {
    dispatch({
      type: CREATE_QUOTE_UPDATE_POLICY_STRUCTURE,
      payload: {
        // using previous policies state to revert any user edited changes
        policyStructure: policiesPreviousState,
      },
    })
    history.push('/application')
    setShowSaveModal(false)
  }

  return (
    <Fragment>
      <Header>
        <PageWrap>
          <BackButton onClick={() => setShowSaveModal(true)} type="icon">
            <IconArrowLeft16 />
            <Text field={fields.backButtonLabel} />
          </BackButton>
          <Title size="1">
            <Text field={fields.pageTitle} />
          </Title>
          <Subtitle size="6">
            <Text field={fields.pageSubTitle} />
          </Subtitle>
        </PageWrap>
      </Header>
      <Content>
        {Array.isArray(policies) && policies[0] && (
          <Fragment>
            <Policies>{policies.map(renderPolicy(fields))}</Policies>
            <Button onClick={saveSetup}>
              <Text field={fields.saveButtonLabel} />
            </Button>
          </Fragment>
        )}
      </Content>
      <PolicySetupModal
        title={fields.backButtonModalTitle}
        onClose={() => setShowSaveModal(false)}
        isOpen={showSaveModal}
        footer={
          <ModalFooter>
            <ModalButton type="primary" onClick={saveSetup}>
              <Text field={fields.backButtonSave} />
            </ModalButton>
            <ModalButton type="secondary" onClick={cancelSave}>
              <Text field={fields.backButtonCancel} />
            </ModalButton>
          </ModalFooter>
        }
      >
        <ModalBody>
          <Text field={fields.backButtonModalDescription} />
        </ModalBody>
      </PolicySetupModal>
    </Fragment>
  )
}

export default withRouter(PolicySetupView)
