import { colours, fontWeights, space, fontSize } from '../../../../../styles'

const styles = {
  accordItemRoot: isPaddingNeeded => ({
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    padding: isPaddingNeeded ? space(4) : 0,
  }),
  accordItemLabel: {
    flex: 1,
  },
  accordItemIcon: {
    justifyContent: 'flex-end',
  },
  accordItemNoOfTask: {
    fontSize: fontSize.xs,
    color: colours.mediumGrey,
    fontWeight: fontWeights.normal,
    width: space(10),
    textAlign: 'right',
  },
  noOfTasks: {
    fontSize: fontSize.xs,
    color: colours.mediumGrey,
    fontWeight: fontWeights.normal,
    margin: 0,
    width: space(10),
    textAlign: 'right',
  },
}

export default styles
