import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { pxToRem, space } = Variables as VariablesType

const styles = {
  sectionHeading: {
    fontSize: pxToRem(20),
  },
  section: {
    maxWidth: space(58),
  },
  chechboxContainer: {
    marginBottom: space(2),
  },
}

export default styles
