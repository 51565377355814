import React from 'react'
import { Heading } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'

// components
import Person from './Person'

// types
import { PersonalDetailsProps } from '../../../../types/components/PersonalAndContactDetails'

// styles
import styles from '../personalAndContactDetails.styles'

const SectionHeading = styled(Heading)(styles.sectionHeading)

const PartyAsPerson = (props: PersonalDetailsProps) => {
  const { fields, isInternalSuperOwner } = props
  return (
    <>
      <SectionHeading variant="h2" size="small">
        {renderTextField(
          isInternalSuperOwner ? fields.LifeInsuredDetailsTitle : fields.PersonalDetailsTitle
        )}
      </SectionHeading>
      <Person {...props} />
    </>
  )
}

export default PartyAsPerson
