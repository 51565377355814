/* eslint-disable */
;(function () {
  var interceptFn = function (interceptHost, orgId, key, isTesting) {
    if (typeof IMI === 'object') return
    var url =
      '//' + interceptHost + '/intercept/v1/init/' + orgId + '/' + key + '?testing=' + isTesting
    this.go = function () {
      var scriptEl = document.createElement('script')
      scriptEl.type = 'text/javascript'
      scriptEl.async = !0
      scriptEl.src = url
      if (document.body) {
        document.body.appendChild(scriptEl)
      }
    }
    this.start = function () {
      if (navigator.userAgent.indexOf('MSIE') >= 0 && navigator.userAgent.indexOf('MSIE 11') < 0) {
        return
      }
      var _interceptFn = this
      if (!isTesting) {
        if (document.readyState != 'loading') _interceptFn.go()
        else if (window.addEventListener)
          window.addEventListener(
            'DOMContentLoaded',
            function () {
              _interceptFn.go()
            },
            !1
          )
        else if (window.attachEvent)
          window.attachEvent('onload', function () {
            _interceptFn.go()
          })
      } else {
        _interceptFn.go()
      }
    }
  }
  try {
    new interceptFn('intercept.inmoment.com.au', 3135, 'ePtjakh9WUbu8Pe45cNow==', !1).start()
  } catch (i) {}
})()
