// @flow
import { colours, fontFamily, fontSize, space } from '../../../styles'

const styles = {
  wrapper: () => ({
    top: space(11),
    'div.Toastify__toast': {
      background: colours.white,
      padding: space(2),
    },
    'div.Toastify__toast--success, div.Toastify__toast--default, div.Toastify__toast--info': {
      borderBottom: `${space(1, true)} solid ${colours.lighterGreen}`,
    },
    'div.Toastify__toast--error': {
      borderBottom: `${space(1, true)} solid ${colours.red}`,
    },
    'div.Toastify__toast--warning': {
      borderBottom: `${space(1, true)} solid ${colours.lightOrange}`,
    },
    'div.Toastify__toast-body': {
      color: colours.black,
      fontFamily: fontFamily.sourceSans,
      fontSize: fontSize.xs,
    },
    'button.Toastify__close-button': {
      color: colours.black,
      opacity: 1,
    },
  }),
  closeIcon: {
    color: colours.black,
  },
}

export default styles
