// @flow
import * as React from 'react'
import styled from '@emotion/styled'
import { Label } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './radio.styles'

type RadioProps = {
  // Value for input id and label for.
  htmlFor: string,
  // Label text.
  text: string,
  // Value of the name attribute.
  name: string,
  // Sets whether the radio button is disabled or not.
  disabled: boolean,
  // Value of the value attribute.
  value?: any,
  // Sets the checked state.
  checked?: boolean,
  // Handles onchange event.
  handleOnChange: Function,
  // An extra label for adding containing forms id for aria AA.
  labelledby?: string,
}

const Radio = (props: RadioProps) => {
  const {
    htmlFor,
    text,
    name,
    disabled,
    value,
    checked,
    handleOnChange,
    labelledby,
    dataLocator: propDataLocator,
  } = props
  const InputWrapper = styled('div')(styles.wrapper)
  const Input = styled('input')(styles.input)
  const RadioLabel = styled(Label)(styles.label)

  const onChangeHandler = e => {
    handleOnChange(e.target.value)
  }

  const dataLocator = {
    'data-locator': propDataLocator || `input-${'radio'}-${name}`,
  }

  return (
    <InputWrapper>
      <Input
        type="radio"
        id={htmlFor}
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChangeHandler}
        aria-labelledby={`${htmlFor}-label ${labelledby}`}
        checked={checked}
        {...dataLocator}
      />
      <RadioLabel htmlFor={htmlFor} id={`${htmlFor}-label`}>
        {text}
      </RadioLabel>
    </InputWrapper>
  )
}

Radio.defaultProps = {
  value: '',
  checked: false,
  labelledby: '',
}

export default Radio
