import { required } from '../../../../../utils/formUtils'

export const FORM_ID = 'personalDetails'
const schema = {
  advisorCode: {
    condition: required,
  },
  title: {
    condition: required,
  },
  firstName: {
    condition: required,
  },
  middleName: {
    condition: required,
  },
  lastName: {
    condition: required,
  },
}

export default schema
