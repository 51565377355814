// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Loader, PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// atoms
import { IconExclaimation16 } from '../../atoms/Icons'

// components.
import SearchBox from '../../molecules/SearchBar'
import FilterTab from './components/FilterTab'
import ArticleCard from './components/ArticleCard'
import LoadMore from './components/LoadMore'
import Header from '../../molecules/PageHeader'

// utils.
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { getRole } from '../../../utils/middlewareUtils'
import { isBrowser } from '../../../utils/browserUtils'

// styles.
import styles from './knowledgeBase.styles'

// constant
import {
  ARTICLE_NO_OF_RECORDS,
  ARTICLE_PAGE_INDEX,
  NO_MORE_ARTICLES_AVAILABLE,
  ARTICLE_CATEGORY_TYPE_MORE_TEXT,
  ARTICLE_CATEGORY_TYPE_ALL_TEXT,
  DEFAULT_ACTIVE_TAB,
  MAXIMUM_MOBILE_SCREEN_SIZE,
} from '../../../constants/knowledgeBase'
import { THEME_LIGHT, THEME_DARK } from '../../../constants/themes'
import { CUSTOMER_PORTAL } from '../../../constants/site'

type KnowledgeBaseProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // An object containing action creator functions.
  actions: {
    getAllArticles: Function,
    setActiveArticleId: Function,
    resetNavigationType: Function,
    resetFooterType: Function,
    setActiveCategoryType: Function,
    setArticleLoadMore: Function,
    setFetchArticlesLoading: Function,
  },
  // knowledge base data
  knowledgeBase: Array<{
    articles: Array,
    categories: Array,
    totalPage: Number,
    pageIndex: Number,
    totalNumberOfRecords: Number,
    isFetchingArticles: Boolean,
  }>,
}

type KnowledgeBaseState = {
  // qset active tab.
  activeTab: number,
  // search input text.
  searchText: string,
  // has error if search fails.
  hasError: boolean,
  // if load more category enable or disable
  isLoadMoreCategory: boolean,
  // array of articles
  articleList: Array,
  // pageIndex number
  pageIndex: number,
  // reocrds to show
  noOfRecords: number,
  // selected category type
  categoryType: string,
  // article id
  articleId: string,
  // total no of pages
  totalNumberOfRecords: number,
  // width of the screen
  screenWidth: number,
  // are there more articles to load
  hasNoMoreArticles: boolean,
}

const Wrap = styled(PageWrap)(styles.wrap, styles.offset, styles.tableRow)
const Card = styled('div')(styles.card)
const IconExclaimation = styled(IconExclaimation16)(styles.iconExclaimation)
const ArticleCardWrapper = styled('div')(styles.articleCardWrapper)
const Loading = styled(Loader)(styles.loaderWrap)
const ArticleNotAvailable = styled('h3')(styles.articleNotAvailable)

const theme = process.env.SITE === CUSTOMER_PORTAL ? THEME_LIGHT : THEME_DARK

export class KnowledgeBase extends Component<KnowledgeBaseProps, KnowledgeBaseState> {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: DEFAULT_ACTIVE_TAB,
      searchText: '',
      hasError: false,
      isLoadMoreCategory: false,
      articleList: [],
      pageIndex: ARTICLE_PAGE_INDEX,
      categoryType: '',
      noOfRecords: ARTICLE_NO_OF_RECORDS,
      articleId: '',
      totalNumberOfRecords: NO_MORE_ARTICLES_AVAILABLE,
      screenWidth: 0,
      hasNoMoreArticles: false,
    }
  }

  componentDidMount() {
    this.handleGetArticleList()
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
    const tagEvent = createEvent({
      GA: {
        category: 'Resources (Knowledge base)',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Resources (Knowledge base) - View',
        },
      },
    })
    tagEvent.end()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({ screenWidth: !!isBrowser() && window.innerWidth })
  }

  handleGetArticleList = isLoadMore => {
    const {
      actions: { getAllArticles, setFetchArticlesLoading },
    } = this.props
    !isLoadMore && setFetchArticlesLoading()
    const { categoryType, pageIndex, noOfRecords, articleId, articleList, searchText } = this.state
    const articleListingParams = {
      portal: getRole(),
      categoryType,
      pageIndex,
      noOfRecords,
      articleId,
      searchText: searchText || '',
    }
    getAllArticles(articleListingParams, (err, data) => {
      if (data) {
        this.setState({
          articleList:
            pageIndex === ARTICLE_PAGE_INDEX
              ? get(data, 'data.articles', [])
              : [...articleList, ...get(data, 'data.articles', [])],
          totalNumberOfRecords: get(data, 'data.totalNumberOfRecords', NO_MORE_ARTICLES_AVAILABLE),
          hasNoMoreArticles: get(data, 'data.page', '') === get(data, 'data.totalPage', ''),
        })
      }
    })
  }

  onClickHandler = () => {
    const {
      actions: { getAllArticles, setFetchArticlesLoading },
    } = this.props
    const { searchText, pageIndex, noOfRecords, articleId } = this.state
    const tagEvent = createEvent({
      GA: {
        category: 'Knowledge base pages',
        action: 'Search',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Knowledge base pages - Search',
        },
      },
    })
    tagEvent.end()
    if (searchText !== '') {
      const articleListingParams = {
        portal: getRole(),
        categoryType: '',
        pageIndex,
        noOfRecords,
        articleId,
        searchText: searchText || '',
      }
      setFetchArticlesLoading()
      getAllArticles(articleListingParams, (err, data) => {
        if (err) {
          this.setState({
            hasError: true,
            pageIndex: ARTICLE_PAGE_INDEX,
            noOfRecords: ARTICLE_NO_OF_RECORDS,
            categoryType: '',
          })
          return
        }
        this.setState({
          articleList: get(data, 'data.articles', []),
          activeTab: DEFAULT_ACTIVE_TAB,
        })
      })
    } else {
      this.setState(
        {
          activeTab: DEFAULT_ACTIVE_TAB,
          pageIndex: ARTICLE_PAGE_INDEX,
          noOfRecords: ARTICLE_NO_OF_RECORDS,
          categoryType: '',
        },
        () => this.handleGetArticleList()
      )
    }
  }

  onChangeHanlder = (value: string): void => {
    this.setState({ searchText: value, hasError: false })
  }

  setActiveTab = (activeTab: number, categoryType: string) => {
    const {
      actions: { setActiveCategoryType },
    } = this.props
    if (categoryType === ARTICLE_CATEGORY_TYPE_MORE_TEXT) {
      return
    }
    this.setState(
      {
        activeTab,
        categoryType: categoryType === ARTICLE_CATEGORY_TYPE_ALL_TEXT ? '' : categoryType,
        pageIndex: ARTICLE_PAGE_INDEX,
        isLoadMoreCategory: false,
      },
      () => this.handleGetArticleList(false)
    )
    setActiveCategoryType(categoryType)
  }

  handleLoadMoreCategory = () =>
    this.setState(prevState => ({ isLoadMoreCategory: !prevState.isLoadMoreCategory }))

  handleLoadMore = () => {
    const { pageIndex } = this.state
    const {
      actions: { setArticleLoadMore },
    } = this.props
    setArticleLoadMore()
    this.setState(
      {
        pageIndex: pageIndex + 1,
      },
      () => {
        this.handleGetArticleList(true)
      }
    )
  }

  handleSetActiveArticle = (articleId: Number) => {
    const {
      actions: { setActiveArticleId, setActiveCategoryType },
    } = this.props
    const { categoryType } = this.state
    setActiveArticleId(articleId)
    setActiveCategoryType(categoryType)
  }

  handleFormatCategories = () => {
    const formatArray = [{ title: ARTICLE_CATEGORY_TYPE_ALL_TEXT, id: 0 }]
    const { knowledgeBase } = this.props
    const categories = get(knowledgeBase, 'categories', [])
    categories.map((title, id) =>
      formatArray.push({
        title,
        id: id + 1,
      })
    )
    return formatArray
  }

  renderKnowledgeBasePage() {
    const {
      fields,
      knowledgeBase,
      knowledgeBase: { isFetchingArticles, isLoadingMoreArticles },
    } = this.props
    const {
      knowledgeBaseSearchBoxTitleText,
      knowledgeBaseSearchBoxPlaceholderText,
      knowledgeBaseSearchBoxErrorMessage,
      articleLinkText,
      articleLoadMoreText,
      articleCategoryMoreText,
      articleNotAvailableMessage,
      heading,
      content,
      cpKnowledgeBaseBannerImageUrl,
      cpKnowledgeBaseBannerImageAlt,
    } = reduceAuthorableFields(fields)
    const {
      searchText,
      hasError,
      activeTab,
      isLoadMoreCategory,
      articleList,
      totalNumberOfRecords,
      screenWidth,
      hasNoMoreArticles,
    } = this.state
    const hasMoreArticle = get(knowledgeBase, 'articles', [])
    const hasCategory = get(knowledgeBase, 'categories', [])
    return (
      <Fragment>
        <Header
          theme={theme}
          heading={{ value: heading }}
          subHeading={{ value: content }}
          imageSrc={cpKnowledgeBaseBannerImageUrl}
          imageAlt={cpKnowledgeBaseBannerImageAlt}
        />
        <Wrap>
          <SearchBox
            title={knowledgeBaseSearchBoxTitleText}
            placeholder={knowledgeBaseSearchBoxPlaceholderText}
            value={searchText}
            clickHandler={this.onClickHandler}
            changeHandler={this.onChangeHanlder}
          />
          {hasError ? (
            <Card>
              <IconExclaimation />
              <span>{knowledgeBaseSearchBoxErrorMessage}</span>
            </Card>
          ) : null}
          {hasCategory.length ? (
            <FilterTab
              schema={this.handleFormatCategories()}
              activeTab={activeTab}
              onClick={this.setActiveTab}
              moreText={articleCategoryMoreText}
              handleLoadMoreCategory={this.handleLoadMoreCategory}
              isLoadMoreCategory={isLoadMoreCategory}
              isDropdownVisible={this.handleFormatCategories().length > ARTICLE_NO_OF_RECORDS}
              isMobileView={screenWidth < MAXIMUM_MOBILE_SCREEN_SIZE}
            />
          ) : null}
        </Wrap>
        <Wrap onClick={() => this.setState({ isLoadMoreCategory: false })}>
          {isFetchingArticles ? (
            <Loading />
          ) : (
            <ArticleCardWrapper>
              {articleList.map(article => (
                <ArticleCard
                  key={article.id}
                  title={article.title}
                  shortDescription={article.shortDescription}
                  articleId={article.id}
                  categoryType={article.categoryType}
                  footer={articleLinkText}
                  handleSetActiveArticle={this.handleSetActiveArticle}
                  link={article.url}
                />
              ))}
            </ArticleCardWrapper>
          )}
          {!hasNoMoreArticles || totalNumberOfRecords !== NO_MORE_ARTICLES_AVAILABLE ? (
            <div>
              {isLoadingMoreArticles ? (
                <Loading />
              ) : (
                <LoadMore
                  label={articleLoadMoreText}
                  onClick={this.handleLoadMore}
                  stopScrollToTop
                  hasMoreArticle={
                    isFetchingArticles || hasMoreArticle.length === NO_MORE_ARTICLES_AVAILABLE
                  }
                />
              )}
            </div>
          ) : null}
          {hasNoMoreArticles || totalNumberOfRecords === NO_MORE_ARTICLES_AVAILABLE ? (
            <ArticleNotAvailable>{articleNotAvailableMessage}</ArticleNotAvailable>
          ) : null}
        </Wrap>
      </Fragment>
    )
  }

  render() {
    return this.renderKnowledgeBasePage()
  }
}

const mapStateToProps = ({
  knowledgeBase: {
    knowledgeBase,
    knowledgeBase: { activeArticleId, activeCategoryType },
  },
}) => ({
  knowledgeBase,
  activeArticleId,
  activeCategoryType,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase)
