import React from 'react'
import { ErrorState, Heading, PageWrap, Variables } from '@mlcl-digital/mlcl-design'
import TabBar from '@mlcl-digital/mlcl-design/lib/base/TabBar'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styled from '@emotion/styled'

// Table Component
// @ts-expect-error file not in typescript
import RetentionTable from '../RetentionTable'

// styles
import styles from './retentionNew.styles'

// types
import { Policy } from '../../../../../types/ClientPolicies'
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'
import { VariablesType } from '../../../../../types/ComponentLibrary'
import { Client } from '../../../../../types/client'

type Tab = {
  label: string
  value: string
  id: string
}

type retentionDashboardNewProps = {
  onClick: () => void
  activeTab: string
  'data-testid': string
  downloadTitle: string
  fields: fieldsType
  columns: string[]
  csvFileName: string
  policies: Policy[]
  rowClickHandler: () => void
  getAllPoliciesNewPremium: (
    policies: Policy[],
    startIndex: number,
    endIndex: number
  ) => Promise<string[]>
  canPolicyNewPremiumFetching: boolean
  isNewPremiumLoading: boolean
  isDownloadListLoading: boolean
  pagesNewPremiumLoadedList: boolean
  isRentionNewPremiumEnabled: boolean
  message: string
  data: { policies: Policy[] }
  tabSchema: Tab[]
  isRetentionUpliftEnabled: boolean
  clientDetails: Client['details']
  getClientDetails: () => void
} & RouteComponentProps

const RetentionPageWrap = styled(PageWrap)(styles.pageWrap)

const RetentionDashboardNew = ({
  data,
  onClick,
  activeTab,
  tabSchema,
  location,
  ...restProps
}: retentionDashboardNewProps) => {
  const TableComponent =
    data?.policies?.length > 0 ? RetentionTable : (ErrorState as React.ReactNode)
  const { space } = Variables as VariablesType
  const schema = tabSchema.map(tabItem => ({
    title: tabItem.label,
    id: tabItem.id,
    component: TableComponent,
    isVisible: true,
    props: {
      activeTab,
      policiesNumber: tabItem.value,
      ...(TableComponent === ErrorState && {
        styleOverrides: {
          container: { marginTop: space(9), marginBottom: space(24) },
        },
      }),
      ...restProps,
    },
  }))

  return (
    <RetentionPageWrap>
      <Heading variant="h4" size="large">
        Retention
      </Heading>
      <TabBar
        onTabChange={onClick}
        currentTab={typeof activeTab === 'number' ? activeTab : 0}
        // @ts-expect-error facing issue with schema type defined in components library
        schema={schema}
        location={location}
        isMobileAllOptions
        variant="navigation-level2"
        styleOverrides={{
          base: {
            background: 'none',
          },
        }}
      />
    </RetentionPageWrap>
  )
}

export default withRouter(RetentionDashboardNew)
