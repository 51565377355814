// @flow
import React from 'react'

// components.
import AdvisorClientListing from '../../../organisms/AdvisorClientListing'

type ClientListingProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const ClientListing = ({ fields }: ClientListingProps) => <AdvisorClientListing fields={fields} />

export default ClientListing
