import {
  GET_VISUALISER_DATA_INIT,
  GET_VISUALISER_DATA_SUCCESS,
  GET_VISUALISER_DATA_ERROR,
  GET_VISUALISER_DATA_FAILURE,
} from '../actions/types/visualiser'

const initialState = {
  isFetching: false,
  data: null,
  error: null,
}

const visualiser = (state = initialState, action) => {
  switch (action.type) {
    case GET_VISUALISER_DATA_INIT: {
      return {
        isFetching: true,
        data: null,
        error: null,
      }
    }
    case GET_VISUALISER_DATA_SUCCESS: {
      const { data } = action.payload
      return {
        isFetching: false,
        data,
        error: null,
      }
    }
    case GET_VISUALISER_DATA_ERROR:
    case GET_VISUALISER_DATA_FAILURE: {
      const { error } = action
      return {
        isFetching: false,
        data: null,
        error,
      }
    }
    default: {
      return state
    }
  }
}

export default visualiser
