// @flow
import React from 'react'

// components.
import AppsContainer from '../../../organisms/ApplicationSearch'

type AppsProps = {
  fields: Object<Object>,
}

const ApplicationSearch = ({ fields }: AppsProps) => <AppsContainer fields={fields} />

export default ApplicationSearch
