// @flow
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import { isBrowser } from '../../../utils/browserUtils'
import { LOGIN_ROUTE } from '../../../constants/routes'

// styling
import styles from './banner.styles'

// components
import { IconInfo16, IconClose16 } from '../../atoms/Icons'
import Button from '../../atoms/Button'

const Wrapper = styled('div')(({ isCustomer, closeButtonDisabled }) =>
  styles.wrapper(isCustomer, closeButtonDisabled)
)
const InfoIconContainer = styled('div')(styles.infoIconContainer)
const ContentContainer = styled('div')(styles.contentContainer)
const Message = styled('p')(styles.message)
const BannerLinkComponent = styled('a')(styles.link)
const IconInfo = styled(IconInfo16)(styles.iconInfo)
const CloseIcon = styled(IconClose16)(styles.closeIcon)
const notificationBannerSessionName = 'showNotificationBanner'

type BannerProps = {
  // labels from sitecore
  fields: Object,
  // redux actions
  actions: Object<Object>,
  // redux navigation state
  navigation: Object,
  // rendering parameters from sitecore
  params: Object,
}

class Banner extends PureComponent<BannerProps> {
  componentDidMount() {
    const {
      actions,
      fields: { BannerEnabled, ShowOnLoginScreenOnly },
    } = this.props
    actions.notificationBannerIsVisible(
      get(BannerEnabled, 'value') &&
        this.showBannerInThisSession() &&
        this.showBannerOnThisPage(get(ShowOnLoginScreenOnly, 'value'))
    )
  }

  handleClick = () => this.hideBannerForThisSession()

  hideBannerForThisSession = () => {
    isBrowser() && sessionStorage.setItem(notificationBannerSessionName, false)

    const { actions } = this.props
    actions.notificationBannerIsVisible(false)
  }

  showBannerInThisSession = () => {
    const showBanner = isBrowser() && sessionStorage.getItem(notificationBannerSessionName)
    return showBanner === null ? true : showBanner === 'true'
  }

  showBannerOnThisPage = ShowOnLoginScreenOnly => {
    const isLoginScreen = isBrowser() && window.location.pathname === LOGIN_ROUTE
    return isLoginScreen || !ShowOnLoginScreenOnly
  }

  render() {
    const {
      fields: { BannerMessage, BannerLink, BannerEnabled, ShowOnLoginScreenOnly },
      navigation: { showNotificationBanner },
      params: { disableCloseButton },
    } = this.props
    const bannerLinkText = get(BannerLink, 'value.text', null)

    return get(BannerEnabled, 'value') &&
      showNotificationBanner &&
      this.showBannerOnThisPage(get(ShowOnLoginScreenOnly, 'value')) ? (
      <Wrapper closeButtonDisabled={disableCloseButton}>
        <InfoIconContainer>
          <IconInfo />
        </InfoIconContainer>
        <ContentContainer>
          <Message>{renderTextField(BannerMessage)}</Message>
          {bannerLinkText && (
            <BannerLinkComponent
              href={get(BannerLink, 'value.href', '#')}
              target={get(BannerLink, 'value.target', '_self')}
            >
              {get(BannerLink, 'value.text', '')}
            </BannerLinkComponent>
          )}
        </ContentContainer>
        {(!disableCloseButton || disableCloseButton === 'false') && (
          <Button type="icon" onClick={this.handleClick}>
            <CloseIcon />
          </Button>
        )}
      </Wrapper>
    ) : null
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = ({ navigation }) => ({
  navigation,
})

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
