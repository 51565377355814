import { Variables } from '@mlcl-digital/mlcl-design'

const { space } = Variables

const styles = {
  paginationContainer: {
    marginTop: space(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}

export default styles
