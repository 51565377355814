import { space, colours, fontSize, fontWeights, fontFamily } from '../../../../../../styles'

const styles = {
  collectionInfoRoot: {
    padding: `${space(2, true)} ${space(4, true)}`,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colours.darkestGrey}`,
    background: colours.darkestGreen,
  },

  collectionLabel: {
    fontSize: fontSize.xxs,
    fontWeight: fontWeights.semiBold,
    color: colours.white,
  },

  collectionName: {
    fontSize: fontSize.lg,
    color: colours.white,
    fontWeight: fontWeights.normal,
    fontFamily: fontFamily.charlie,
    margin: 0,
  },

  wrap: {
    display: 'flex',
  },

  quoteEditIcon: {
    margin: space(1.5, true),
    color: colours.white,
    flexShrink: 0,
  },
}

export default styles
