// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
// components.
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { IconFileUpload64 } from '../../../../atoms/Icons'
import Button from '../../../../atoms/Button'
// styles.
import styles from './dropzone.styles'

const Container = styled('div')(styles.container)
const FileInput = styled('input')(styles.fileInput)
const BrowseLink = styled(Button)(styles.browseLink)
const DescriptionBox = styled('div')(styles.descriptionBox)
const FileUploadIcon = styled(IconFileUpload64)(({ isFileUploading }) =>
  styles.fileUploadIcon(isFileUploading)
)
const FileArrowUpIcon = styled(Icon)(styles.infoIcon)
const IconWrapper = styled('div')(styles.iconWrapper)

type DropzoneProps = {
  // to enable html5 multi input
  hasMultipleFiles: boolean,
  // handle on drag and drop
  handleFileDrop: Function,
  // handle on file change
  validateFiles: Function,
  // return true if any file upload is going on
  isFileUploading: boolean,
  // disable uploading of multiple files
  isDisableMultipleFiles: boolean,
  bulkUploadIcon: boolean,
  acceptFileType: string,
  isBrowseDropNotAllowed: boolean,
}

class Dropzone extends Component<DropzoneProps> {
  constructor(props) {
    super(props)
    this.fileInputRef = React.createRef()
  }

  handleChange = async ({ target }) => {
    const { files } = target
    const { handleFileDrop, validateFiles, isFileUploading } = this.props
    if (isFileUploading) {
      return
    }

    // eslint-disable-next-line no-unused-expressions
    Object.keys(files).length && handleFileDrop(await validateFiles(files))
    // set target to blank to handle same file browsing browser limitation
    target.value = ''
  }

  openFileDialog = () => {
    const { isFileUploading } = this.props
    if (isFileUploading) return false

    return this.fileInputRef.current.click()
  }

  render() {
    const {
      hasMultipleFiles,
      isFileUploading,
      isDisableMultipleFiles,
      bulkUploadIcon,
      acceptFileType,
      isBrowseDropNotAllowed,
    } = this.props
    return (
      <Container isBrowseDropNotAllowed={isBrowseDropNotAllowed} isFileUploading={isFileUploading}>
        <FileInput
          ref={this.fileInputRef}
          type="file"
          accept={acceptFileType || ''}
          multiple={hasMultipleFiles}
          onChange={this.handleChange}
          data-testid="drop-zone-input"
        />
        {bulkUploadIcon ? (
          <>
            <IconWrapper>
              <FileArrowUpIcon
                iconName={['far', 'fa-file-arrow-up']}
                isFileUploading={isFileUploading}
              />
            </IconWrapper>
            <DescriptionBox onClick={this.openFileDialog} isFileUploading={isFileUploading}>
              Drop file here or
              <BrowseLink
                style={{ display: 'block', margin: '0 auto' }}
                disabled={isFileUploading || isDisableMultipleFiles}
                type="tertiary"
              >
                Browse
              </BrowseLink>
            </DescriptionBox>
          </>
        ) : (
          <>
            <FileUploadIcon isFileUploading={isFileUploading} data-testid="drop-zone-icon" />
            <DescriptionBox onClick={this.openFileDialog}>
              Drop form here or
              <BrowseLink disabled={isFileUploading || isDisableMultipleFiles} type="tertiary">
                Browse
              </BrowseLink>
              to upload..
            </DescriptionBox>
          </>
        )}
      </Container>
    )
  }
}
export default Dropzone
