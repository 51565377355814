/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import moment from 'moment'
import { Loader, Notification } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import Card from '../../../../atoms/Card'
import Heading from '../../../../atoms/Heading'
import Select from '../../../../atoms/Select'
import AddNewItem from '../../../../molecules/AddNewItem'
import DataFeedRow from './DataFeedRow'
import Modal from '../../../../molecules/Modal'
import Btn from '../../../../atoms/Button'

// styles.
import styles from './dataFeedRegistrations.styles'
import Checkbox from '../../../../atoms/Checkbox'

// constants
import {
  DATA_FEED_COIN,
  DATA_FEED_CANCELLED_SUCCESS_CODE,
  DATA_FEED_SUBSCRIBED,
  DATA_FEED_UNSUBSCRIBED,
} from '../../../../../constants/dataFeedRegistration'
import { POLICY_PARTY_TYPE_BUSINESS } from '../../../../../constants/policies'
import { PARTY_TYPE_PERSON } from '../../../../../constants/adviser'

// utils
import { camelCase, getVersionFromUrl } from '../../../../../utils/commonUtils'
import { DATE_FORMAT } from '../../../../../utils/quoteUtils'
import { ignoreColumns } from '../../../../../utils/stylesUtils'
import { getAdviserNumber } from '../../../../../utils/adviserUtils'

const Wrap = styled(Card)(styles.base)
const Header = styled('div')(styles.header)
const Title = styled(Heading)(styles.title)
const HalfWidthSelect = styled(Select)(styles.halfColumn)
const AddNewDataFeed = ignoreColumns(AddNewItem)
const AddDataFeedWrapper = styled('div')(styles.addDataFeedWrapper)
const ModalBody = styled('div')(styles.modalBody)
const ModalDropDownLabel = styled('div')(styles.modalDropDownLabel)
const Button = styled(Btn)(styles.modalButton)
const CancelButton = styled(Btn)(styles.modalCancelButton)
const TermsAndCondition = styled('div')(styles.termsAndCondition)
const TermsAndConditionLabel = styled('p')(styles.termsAndConditionLabel)
const TermsAndConditionLink = styled('a')(styles.termsAndConditionLink)
const ErrorMessage = styled('p')(styles.error)
const Row = styled('div')(styles.row)

type DataFeedRegistrationProps = {
  // Sitecore authorable fields.
  fields: Object,
  // Redux suplied form state
  advisor: Object,
  // An object containing action creator functions.
  actions: Object,
  // MasterList
  masterList: Object,
  // data feed registration api triggered
  dataFeedRegistration: { isLoading: boolean },
}

type DataFeedRegistrationState = {
  // show error if add or cancel data feed fails
  showError: boolean,
  // is add data feed modal open or close
  isAddDataFeedModalOpen: boolean,
  // is add data feed confirmation modal open or close
  isDataFeedConfirmationModalOpen: boolean,
  // is cancel data feed modal open or close
  isDataFeedCancelModalOpen: boolean,
  // if cancel data feed modal was successfully cancelled
  isDataFeedCancellationSuccess: boolean,
  // data feed to add
  selectedDataFeed: string,
  // data feed to cancel
  cancelDataFeed: string,
  // is terms & conditions checked
  isChecked: boolean,
  // add datafeed terms & conditions error message
  isCheckedError: boolean,
  // cancel data feed checkbox
  isCancelCheckBox: boolean,
  // cancel datafeed terms & conditions error message
  isCancelError: boolean,
}

export class DataFeedRegistration extends Component<
  DataFeedRegistrationProps,
  DataFeedRegistrationState
> {
  state = {
    showError: false,
    isAddDataFeedModalOpen: false,
    isDataFeedConfirmationModalOpen: false,
    isDataFeedCancelModalOpen: false,
    isDataFeedCancellationSuccess: false,
    isChecked: false,
    isCheckedError: false,
    isCancelCheckBox: false,
    isCancelError: false,
    selectedDataFeed: '',
    cancelDataFeed: '',
  }

  componentWillMount() {
    const { actions, masterList } = this.props
    const { getAdvisorDetails, getMasterList } = actions
    const isMarterListAvailable = get(masterList, 'data.researchPartners')
    getAdvisorDetails()
    if (!isMarterListAvailable) {
      getMasterList()
    }
  }

  componentDidMount() {
    const tagEvent = createEvent({
      GA: {
        category: 'Data feed',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Data feed - View',
        },
      },
    })
    tagEvent.end()
  }

  handleAddDataFeed = event =>
    this.setState({ selectedDataFeed: get(event, 'value.value', ''), showError: false })

  handleOpenAddDataFeedModal = () => {
    this.setState({
      isAddDataFeedModalOpen: true,
      selectedDataFeed: '',
    })

    const tagEvent = createEvent({
      GA: {
        category: 'Add data feed',
        action: 'Select',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Add data feed',
        },
      },
    })
    tagEvent.end()
  }

  handleOpenConfirmationModal = () => {
    const { selectedDataFeed } = this.state
    if (selectedDataFeed === '') {
      this.setState({ showError: true })
    } else {
      this.setState({
        isDataFeedConfirmationModalOpen: true,
        isAddDataFeedModalOpen: false,
        showError: false,
      })
    }
  }

  handleCancelDataFeed = (title: Array) => {
    this.setState({ isDataFeedCancelModalOpen: true, cancelDataFeed: title })

    const tagEvent = createEvent({
      GA: {
        category: 'Cancel data feed',
        action: 'Select',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Cancel data feed',
        },
      },
    })
    tagEvent.end()
  }

  handleCancelDataFeedConfirmation = () => {
    const {
      fields: {
        dataFeedRegistrationSuccessfulCancelFeedErrorCode,
        dataFeedRegistrationTermAndConditionsUrl,
      },
      actions,
      advisor: { bancsAgencyCodes },
      advisor,
    } = this.props

    const {
      dataFeedRegistrationCancellation,
      getAdvisorDetails,
      dataFeedAcceptTermsAndConditions,
    } = actions

    const { cancelDataFeed, isCancelCheckBox } = this.state

    const formattedCurrentDate = moment().format(DATE_FORMAT)

    const handleMatchCancelDataFeed = this.handleMatchCancelDataFeed(cancelDataFeed)

    const reqPayload = {
      bancsAgencyCodes,
      researchPartnerDetails: [
        {
          researchPartnerName: handleMatchCancelDataFeed,
          linkingId: '',
          effectiveEndDate: formattedCurrentDate,
        },
      ],
    }

    const partyType = get(advisor, 'details.partyType', '')
    const version = getVersionFromUrl(dataFeedRegistrationTermAndConditionsUrl)

    const termsAndConditionRequest = {
      adviserId: getAdviserNumber(advisor),
      adviserName: this.getAdviserName(partyType, advisor),
      feedProvider: handleMatchCancelDataFeed,
      version,
      action: DATA_FEED_UNSUBSCRIBED,
    }

    if (!isCancelCheckBox) return this.setState({ isCancelError: true })

    dataFeedAcceptTermsAndConditions(termsAndConditionRequest)
    dataFeedRegistrationCancellation(reqPayload, (err, newRecords) => {
      const {
        status: { code },
      } = newRecords
      if (code === dataFeedRegistrationSuccessfulCancelFeedErrorCode) {
        this.setState({ isDataFeedCancellationSuccess: true, isDataFeedCancelModalOpen: false })
      }
      if (code === DATA_FEED_CANCELLED_SUCCESS_CODE) {
        getAdvisorDetails()
        this.setState({
          isDataFeedCancelModalOpen: false,
          isCancelError: false,
          isCancelCheckBox: false,
        })
      }
    })
  }

  getAdviserName = (partyType: String, advisor: Array) => {
    switch (partyType) {
      case PARTY_TYPE_PERSON:
        return `${get(advisor, 'details.firstName', '')} ${get(advisor, 'details.lastName', '')}`
      case POLICY_PARTY_TYPE_BUSINESS:
        return get(advisor, 'details.businessName', '')
      default:
        return ''
    }
  }

  handleMatchCancelDataFeed = (cancelDataFeed: Array) => {
    if (cancelDataFeed) {
      if (cancelDataFeed[0] === DATA_FEED_COIN)
        return this.getResearchPartnerName(cancelDataFeed).toLowerCase()
      return camelCase(this.getResearchPartnerName(cancelDataFeed))
    }
  }

  closeRemoveModal = (key: string) =>
    this.setState({
      [key]: false,
      isChecked: false,
      isCheckedError: false,
      isCancelError: false,
      isCancelCheckBox: false,
    })

  handleConfirmation = () => {
    const {
      actions,
      advisor: { bancsAgencyCodes },
      advisor,
      fields: { dataFeedRegistrationTermAndConditionsUrl },
    } = this.props
    const { dataFeedRegistration, getAdvisorDetails, dataFeedAcceptTermsAndConditions } = actions
    const { selectedDataFeed, isChecked } = this.state

    const reqPayload = {
      bancsAgencyCodes,
      researchPartnerDetails: [
        {
          researchPartnerName: selectedDataFeed,
          linkingId: '',
        },
      ],
    }

    const partyType = get(advisor, 'details.partyType', '')
    const version = getVersionFromUrl(dataFeedRegistrationTermAndConditionsUrl)

    if (!isChecked) return this.setState({ isCheckedError: true })

    const termsAndConditionRequest = {
      adviserId: getAdviserNumber(advisor),
      adviserName: this.getAdviserName(partyType, advisor),
      feedProvider: selectedDataFeed,
      version,
      action: DATA_FEED_SUBSCRIBED,
    }
    dataFeedAcceptTermsAndConditions(termsAndConditionRequest)
    dataFeedRegistration(reqPayload, (err, newRecords) => {
      if (newRecords) {
        getAdvisorDetails()
        this.setState({
          isDataFeedConfirmationModalOpen: false,
          isChecked: false,
          isCheckedError: false,
        })
        const tagEvent = createEvent({
          GA: {
            catgeory: 'Data feeds from third party',
            action: 'Add',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Data feeds from third party',
            },
          },
        })
        tagEvent.end()
      }
    })
  }

  dataFeedOption = () => {
    const { advisor, masterList } = this.props
    const advisorDetails = get(advisor, 'advisorDetails[0].researchPartnerNames', [])
    const researchPartners = get(masterList, 'data.researchPartners', [])

    const dataFeedOptionList = researchPartners.map(item => ({
      label: item.value,
      value: item.code,
    }))

    const keyToFilterOut = []
    // eslint-disable-next-line no-unused-expressions
    advisorDetails.length &&
      advisorDetails.map(
        researchPartnerName => researchPartnerName && keyToFilterOut.push(researchPartnerName)
      )

    return dataFeedOptionList.filter(option => !keyToFilterOut.includes(option.value))
  }

  handleFormatDataFeedTitle = (title: string) => {
    const { masterList } = this.props
    const researchPartners = get(masterList, 'data.researchPartners', [])
    const formattedTitle = []
    researchPartners.map(item => title === item.code && formattedTitle.push(item.value))
    return formattedTitle
  }

  getResearchPartnerName = (value: Array) => {
    const { masterList } = this.props
    const researchPartners = get(masterList, 'data.researchPartners', [])
    let dataFeedCode = ''
    researchPartners.filter(item => {
      if (item.value.toLowerCase() === value[0].toLowerCase()) {
        dataFeedCode = item.value
      }
    })
    return dataFeedCode
  }

  formatString = str =>
    str
      .replace(/([A-Z])/g, ' $1')
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')

  toggleCheckbox = (isCancel: boolean) => {
    const { isChecked, isCancelCheckBox } = this.state
    if (isCancel) {
      return this.setState({ isCancelCheckBox: !isCancelCheckBox, isCancelError: false })
    }
    return this.setState({ isChecked: !isChecked, isCheckedError: false })
  }

  render() {
    const {
      fields: {
        dataFeedRegistrationHeading,
        dataFeedRegistrationIntro,
        addNewDataFeedButton,
        addNewDataFeedErrorMessage,
        dataFeedRegistrationModalAddFeedTitle,
        dataFeedRegistrationModalAddFeedHeading,
        dataFeedRegistrationModalPlaceholder,
        dataFeedRegistrationModalDropdownTitle,
        dataFeedRegistrationModalAddButtonText,
        dataFeedRegistrationModalConfirmationTitle,
        dataFeedRegistrationModalConfirmationBody,
        dataFeedRegistrationModalConfirmationButton,
        dataFeedRegistrationModalConfirmationMsgFirstPart,
        dataFeedRegistrationModalConfirmationMsgSecondPart,
        dataFeedRegistrationModalCancelFeedTitle,
        dataFeedRegistrationModalCancelFeedMsgFirstPart,
        dataFeedRegistrationModalCancelFeedMsgSecondPart,
        dataFeedRegistrationModalCancelFeedButton,
        dataFeedRegistrationSuccessfulCancelFeedTitle,
        dataFeedRegistrationSuccessfulCancelFeedMessage,
        dataFeedRegistrationSuccessfulCancelFeedConfirm,
        dataFeedRegistrationTermAndConditionsAccept,
        dataFeedRegistrationTermAndConditions,
        dataFeedRegistrationTermAndConditionsError,
        dataFeedRegistrationTermAndConditionsUrl,
      },
      fields,
      advisor,
      dataFeedRegistration: { isLoading },
    } = this.props

    const { advisorDetails } = advisor

    const {
      isAddDataFeedModalOpen,
      isDataFeedConfirmationModalOpen,
      isDataFeedCancelModalOpen,
      isDataFeedCancellationSuccess,
      showError,
      selectedDataFeed,
      cancelDataFeed,
      isChecked,
      isCheckedError,
      isCancelCheckBox,
      isCancelError,
    } = this.state
    return (
      <Wrap>
        <Header>
          <Title size="3" element="2">
            {dataFeedRegistrationHeading}
          </Title>
          <p>{dataFeedRegistrationIntro}</p>
        </Header>
        {advisorDetails &&
          advisorDetails.slice(0, 1).map(
            ({ researchPartnerNames }) =>
              researchPartnerNames &&
              researchPartnerNames.map((title, index) => (
                <DataFeedRow
                  // @FIXME: need to use better key value
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  title={this.handleFormatDataFeedTitle(title)}
                  fields={fields}
                  handleCancelDataFeed={this.handleCancelDataFeed}
                />
              ))
          )}
        <AddDataFeedWrapper>
          <AddNewDataFeed
            displayLabel={addNewDataFeedButton}
            clickHandler={() =>
              this.dataFeedOption().length ? this.handleOpenAddDataFeedModal() : null
            }
            isDisable={!this.dataFeedOption().length}
            isPaddingNeeded
          />
        </AddDataFeedWrapper>
        <Modal
          isOpen={isAddDataFeedModalOpen}
          onClose={() => this.closeRemoveModal('isAddDataFeedModalOpen')}
          title={dataFeedRegistrationModalAddFeedTitle}
        >
          {showError && <Notification variant="error">{addNewDataFeedErrorMessage}</Notification>}
          <ModalBody>{dataFeedRegistrationModalAddFeedHeading}</ModalBody>
          <ModalDropDownLabel className="no-margin">
            {dataFeedRegistrationModalDropdownTitle}
          </ModalDropDownLabel>
          <HalfWidthSelect
            name="dataFeedOption"
            id="dataFeedOption"
            options={this.dataFeedOption()}
            value={selectedDataFeed}
            placeholder={dataFeedRegistrationModalPlaceholder}
            noOfDropdownOptionToBeDisplayed={3}
            changeHandler={event => this.handleAddDataFeed(event)}
          />
          <Button type="secondary" onClick={this.handleOpenConfirmationModal}>
            {dataFeedRegistrationModalAddButtonText}
          </Button>
        </Modal>
        <Modal
          isOpen={isDataFeedConfirmationModalOpen}
          onClose={() => this.closeRemoveModal('isDataFeedConfirmationModalOpen')}
          title={dataFeedRegistrationModalConfirmationTitle}
        >
          <ModalBody>{`${dataFeedRegistrationModalConfirmationMsgFirstPart} ${this.formatString(
            selectedDataFeed
          )} ${dataFeedRegistrationModalConfirmationMsgSecondPart}`}</ModalBody>
          {dataFeedRegistrationModalConfirmationBody !== '' && (
            <ModalBody>{dataFeedRegistrationModalConfirmationBody}</ModalBody>
          )}
          <TermsAndCondition>
            <Checkbox
              name="termsAndCondition"
              htmlFor="termsAndCondition"
              onChangeHandler={() => this.toggleCheckbox(false)}
              checked={isChecked}
            />
            <div>
              <Row>
                <TermsAndConditionLabel>
                  {dataFeedRegistrationTermAndConditionsAccept}
                </TermsAndConditionLabel>
                <TermsAndConditionLink
                  href={dataFeedRegistrationTermAndConditionsUrl}
                  target="_blank"
                >
                  {dataFeedRegistrationTermAndConditions}
                </TermsAndConditionLink>
              </Row>
              {isCheckedError && (
                <ErrorMessage>{dataFeedRegistrationTermAndConditionsError}</ErrorMessage>
              )}
            </div>
          </TermsAndCondition>
          {isLoading ? (
            <Loader />
          ) : (
            <Button type="secondary" onClick={this.handleConfirmation}>
              {dataFeedRegistrationModalConfirmationButton}
            </Button>
          )}
        </Modal>
        <Modal
          isOpen={isDataFeedCancelModalOpen}
          onClose={() => this.closeRemoveModal('isDataFeedCancelModalOpen')}
          title={dataFeedRegistrationModalCancelFeedTitle}
        >
          <ModalBody>{`${dataFeedRegistrationModalCancelFeedMsgFirstPart} ${cancelDataFeed} ${dataFeedRegistrationModalCancelFeedMsgSecondPart}`}</ModalBody>
          <TermsAndCondition>
            {/* @FIXME: label is not correctly atributed to the checkbox  */}
            <Checkbox
              name="termsAndCondition"
              htmlFor="termsAndCondition"
              onChangeHandler={() => this.toggleCheckbox(true)}
              checked={isCancelCheckBox}
            />
            <div>
              <Row>
                <TermsAndConditionLabel>
                  {dataFeedRegistrationTermAndConditionsAccept}
                </TermsAndConditionLabel>
                <TermsAndConditionLink
                  href={dataFeedRegistrationTermAndConditionsUrl}
                  target="_blank"
                >
                  {dataFeedRegistrationTermAndConditions}
                </TermsAndConditionLink>
              </Row>
              {isCancelError && (
                <ErrorMessage>{dataFeedRegistrationTermAndConditionsError}</ErrorMessage>
              )}
            </div>
          </TermsAndCondition>
          {isLoading ? (
            <Loader />
          ) : (
            <CancelButton type="secondary" onClick={this.handleCancelDataFeedConfirmation}>
              {dataFeedRegistrationModalCancelFeedButton}
            </CancelButton>
          )}
        </Modal>
        <Modal
          isOpen={isDataFeedCancellationSuccess}
          onClose={() => this.closeRemoveModal('isDataFeedCancellationSuccess')}
          title={dataFeedRegistrationSuccessfulCancelFeedTitle}
        >
          <ModalBody>{dataFeedRegistrationSuccessfulCancelFeedMessage}</ModalBody>
          <Button
            type="secondary"
            onClick={() => this.closeRemoveModal('isDataFeedCancellationSuccess')}
          >
            {dataFeedRegistrationSuccessfulCancelFeedConfirm}
          </Button>
        </Modal>
      </Wrap>
    )
  }
}

export const mapStateToProps = ({ advisor, dataFeedRegistration, masterList }) => ({
  advisor,
  dataFeedRegistration,
  masterList,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataFeedRegistration)
