// @flow

/**
 * Utility function to insert records within an array
 * @param {*} recordsToInsert : array of records to insert
 * @param {*} originalArray : original array to be used to insert
 * @param {*} arrayIndexInsertPosition : position starting from 0
 * @returns new array with records inserted
 */
export function insertRecordInArrayAtPosition(
  recordsToInsert: Array,
  originalArray: Array,
  arrayIndexInsertPosition: Number
) {
  const arrayClone = [...originalArray]
  arrayClone.splice(arrayIndexInsertPosition, 0, ...recordsToInsert)
  return arrayClone
}
