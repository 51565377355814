// @flow
// components.
import React from 'react'
import Navigation from '../../../organisms/Navigation'

type NavigationRootProps = {
  // sitecore fields
  fields: Object,
  // sitecore rendering
  rendering: Object,
  // params object
  params: Object,
}

const NavigationRoot = ({ fields, rendering, params }: NavigationRootProps) => (
  <Navigation fields={fields} rendering={rendering} params={params} />
)

export default NavigationRoot
