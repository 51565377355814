// @flow
import {
  ADDRESS_LOOKUP_FETCH,
  ADDRESS_LOOKUP_FETCH_SUCCESS,
  ADDRESS_LOOKUP_FETCH_ERROR,
  ADDRESS_LOOKUP_FETCH_FAILURE,
  ADDRESS_LOOKUP_INIT,
  ADDRESS_LOOKUP_CLEAR,
  ADDRESS_SET_MANUAL,
} from '../actions/types/addressLookup'

export const initialFieldState = {
  isLoading: false,
  error: false,
  addresses: [],
  isManual: false,
}

const addressLookup = (
  state: Object = {},
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case ADDRESS_LOOKUP_INIT: {
      const { fieldName, isManual } = action.payload
      return {
        ...state,
        [fieldName]: {
          ...initialFieldState,
          isManual: isManual || false,
        },
      }
    }
    case ADDRESS_LOOKUP_CLEAR: {
      const { fieldName } = action.payload
      return {
        ...state,
        [fieldName]: {
          ...state[fieldName],
          isLoading: false,
          error: false,
          addresses: [],
        },
      }
    }
    case ADDRESS_LOOKUP_FETCH: {
      const { fieldName } = action.payload
      return {
        ...state,
        [fieldName]: {
          ...state[fieldName],
          error: false,
          isLoading: true,
        },
      }
    }
    case ADDRESS_LOOKUP_FETCH_SUCCESS: {
      const { fieldName, addresses } = action.payload
      return {
        ...state,
        [fieldName]: {
          ...state[fieldName],
          addresses,
          error: false,
          isLoading: false,
        },
      }
    }
    case ADDRESS_LOOKUP_FETCH_ERROR:
    case ADDRESS_LOOKUP_FETCH_FAILURE: {
      const { fieldName } = action.payload
      return {
        ...state,
        [fieldName]: {
          ...state[fieldName],
          addresses: [],
          error: true,
          isLoading: false,
        },
      }
    }
    case ADDRESS_SET_MANUAL: {
      const { fieldName, isManual } = action.payload
      return {
        ...state,
        [fieldName]: {
          ...state[fieldName],
          isManual,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default addressLookup
