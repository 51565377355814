// @flow
import { colours, shadows, space, fontWeights, fontSize, pxToRem } from '../../../../../styles'

const styles = {
  names: {
    display: 'flex',
  },
  downloadBox: {
    boxShadow: shadows.low,
    background: colours.white,
    borderColor: colours.lightestGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 2,
    display: 'flex',
    marginBottom: space(2, true),
    div: {
      paddingLeft: space(1, true),
    },
    button: {
      fontWeight: fontWeights.semiBold,
      fontSize: fontSize.xs,
    },
  },
  iconDocument: {
    color: colours.black,
    float: 'left',
    marginLeft: space(1, true),
    marginTop: space(1, true),
  },
  selectFormType: {
    boxShadow: shadows.low,
    marginBottom: space(2, true),
  },
  button: {
    position: 'relative',
  },
  subHeading: {
    color: colours.black,
    marginBottom: space(4, true),
    lineHeight: space(3, true),
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xs,
  },
  box: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: space(1, true),
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colours.lightestGrey,
    borderStyle: 'solid',
    backgroundColor: colours.white,
    transition: 'border .25s ease-in-out',
    boxShadow: shadows.low,
  },

  progress: {
    '&:after': {
      position: 'absolute',
      content: '',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  },

  notification: {
    marginTop: space(2, true),
    marginBottom: 0,
  },
  dropDownValueContainer: {
    marginLeft: space(5, true),
  },
  textarea: ({ value }) => ({
    fontSize: pxToRem(16),
    fontWeight: fontWeights.light,
    color: colours.darkestGrey,
    borderColor: value ? colours.darkestGrey : colours.lightGrey,
    height: pxToRem(140),
    lineHeight: pxToRem(22),
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    width: '100%',
    borderWidth: '1px',
    backgroundColor: colours.white,
    borderStyle: 'solid',
    textDecoration: 'none',
    boxSizing: 'border-box',
    marginBottom: 0,
    padding: pxToRem(10),
    '&:hover:not(:disabled)': {
      borderColor: colours.lightGreen,
    },
    '&:focus:not(:disabled)': {
      outline: '1px',
      borderColor: colours.lightGreen,
    },
  }),
  textareaWrap: {
    marginBottom: space(2),
  },
}

export default styles
