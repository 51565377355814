/* eslint-disable no-unused-vars */
import {
  colours,
  containerUtils,
  fontFamily,
  fontSize,
  iconWidth,
  mediaQueries,
  space,
  fontWeights,
} from '../../../styles'

/** Shared betweed primary medium up screen and secondary dropdown */
const secondaryStyles = {
  container: {
    color: colours.white,
  },
  button: {
    backgroundColor: colours.mediumGreen,
    borderWidth: 0,
  },
  list: {
    li: {
      backgroundColor: colours.mediumGreen,
      borderTopWidth: 0,
      fontSize: fontSize.xs,
      fontFamily: 'inherit',
      position: 'relative',
      '&:hover': {
        backgroundColor: colours.mediumGreen,
        color: colours.white,
      },

      '&:before': {
        content: '""',
        height: 1,
        backgroundColor: colours.darkGreen,
        opacity: 0.2,
        position: 'absolute',
        left: space(2),
        right: space(2),
      },

      a: {
        paddingTop: space(3.5),
        paddingBottom: space(3.5),
      },
    },
  },
}

const styles = {
  base: {
    container: theme => ({
      fontFamily: fontFamily.charlie,
      color: 'currentColor',
      position: 'relative',
    }),
    button: theme => ({
      ...containerUtils.innerPaddingHorizontal,
      backgroundColor: colours.white,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      display: 'inline-flex',
      cursor: 'pointer',
      alignItems: 'center',
      color: 'currentColor',
      justifyContent: 'space-between',
      paddingRight: space(2),
      position: 'relative',
      appearance: 'none',
      textAlign: 'left',
      width: '100%',
      paddingTop: space(2),
      paddingBottom: space(2),

      '&:focus': {
        color: colours.white,
        outlineWidth: 0,
      },

      [mediaQueries.md]: {
        paddingLeft: space(2),
        paddingRight: space(2),
        paddingTop: space(4),
        paddingBottom: space(4),
      },

      [mediaQueries.lg]: {
        paddingLeft: space(),
        paddifontngRight: space(4),
      },
    }),
    buttonText: {
      fontSize: fontSize.sm,
      lineHeight: fontSize.md,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: fontWeights.semiBold,
      [mediaQueries.md]: {
        fontSize: fontSize.sm,
      },
    },
    indicator: theme => ({
      color: 'currentColor',
      width: iconWidth.sm,
      height: iconWidth.sm,
      [mediaQueries.md]: {
        marginLeft: space(1),
      },
    }),
    list:
      theme =>
      ({ isOpen }) => ({
        listStyle: 'none',
        margin: 0,
        display: isOpen ? 'block' : 'none',

        [mediaQueries.md]: {
          position: 'absolute',
          left: 0,
          right: 0,
        },
      }),
  },

  primary: {
    container: ({ isOpen }) => ({
      '&::last-of-type': {
        button: {
          borderBottomWidth: 1,
        },
      },
      [isOpen && mediaQueries.md]: {
        fontFamily: fontFamily.sourceSans,
        ...secondaryStyles.container,
      },
    }),
    button: ({ isOpen }) => ({
      borderColor: colours.lightGrey,

      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 1,
        backgroundColor: colours.lightGrey,
        top: 0,
        bottom: 0,
        right: space(8),
      },

      '&:focus': {
        backgroundColor: colours.darkGrey,
        borderColor: colours.darkestGrey,

        '&:after': {
          backgroundColor: colours.darkestGrey,
        },
      },

      [mediaQueries.md]: {
        ...secondaryStyles.button,
        backgroundColor: isOpen && secondaryStyles.button.backgroundColor,
        border: 0,
        fontFamily: fontFamily.sourceSans,

        '&:focus': {
          backgroundColor: secondaryStyles.button.backgroundColor,
          borderColor: secondaryStyles.button.borderColor,

          svg: {
            color: colours.white,
          },
        },

        '&:after': {
          content: 'none',
        },
      },
    }),
    indicator: ({ isOpen }) => ({
      width: iconWidth.lg,
      height: iconWidth.lg,

      [mediaQueries.md]: {
        width: iconWidth.sm,
        height: iconWidth.sm,
        color: isOpen && colours.white,
      },
    }),
    list: {
      [mediaQueries.md]: {
        ...secondaryStyles.list,
      },
    },
  },
  secondary: {
    ...secondaryStyles,
    button: ({ isOpen }) => ({
      ...secondaryStyles.button,
      borderRadius: !isOpen && '0 0 2px 2px',
    }),
    list: (theme, item) => ({
      li: {
        ...secondaryStyles.list.li,
        fontFamily: fontFamily.sourceSans,
      },
    }),
  },
}

export default styles
