import { Variables } from '@mlcl-digital/mlcl-design'
import { cssEnums } from '../../../../styles'

const { space, fontWeights, fontSize, colours } = Variables

const quoteTableStyles = {
  card: {
    background: colours.white,
    padding: `${space(5, true)} ${space(4, true)} ${space(3, true)} ${space(5, true)}`,
    display: 'flex',
    flexDirection: 'row',
  },
  headerCell: {
    '&:last-of-type': {
      minWidth: cssEnums.AUTO,
    },
  },
  tableBody: {
    tr: {
      cursor: 'pointer',
      '&:hover': {
        background: colours.tertiaryThree,
      },
      'td:last-of-type': {
        minWidth: cssEnums.AUTO,
        justifyContent: 'flex-end',
      },
    },
  },
  disabled: {
    color: colours.mediumGreen,
    fontSize: fontSize.md,
    fontWeight: fontWeights.bold,
    opacity: '0.4',
    cursor: 'not-allowed',
  },
  icon: {
    color: colours.mediumGreen,
    marginRight: space(1),
  },
  iconDisabled: {
    opacity: '0.4',
    marginRight: space(1),
    cursor: 'not-allowed',
  },
  buttonWrapper: {
    marginInlineStart: 'auto',
  },
  button: {
    display: 'block',
    padding: space(1, true),
    margin: 0,
    width: '100%',
    background: 0,
    textDecoration: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      background: colours.whiteGrey,
    },
    /* Override Tertiary button styles */
    border: `1px solid ${colours.darkestGrey}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    /* Override button content */
    fontWeight: fontWeights.normal,
  },
}

export default quoteTableStyles
