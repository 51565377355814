import { createSelector } from 'reselect'
import moment from 'moment'

// selectors
import { getRulesForAlterationPolicies } from './altsDeclaration.selectors'
import {
  getAlterationPolicies,
  getIsAdviserPortal,
  getMasterData,
  getAlterations,
} from './common.selectors'

// constants
import { ALTERATION_TYPES } from '../constants/alterations'
import {
  PAYMENT_FREQUENCY_MULTIPLIER,
  REPORTING_PAYMENT_FREQUENCY_OPTIONS,
} from '../constants/forms'
import { ESCALATION_ANNUAL_FREQUENCY } from '../constants/benefit'

// types
import { PolicyEligiblityKey } from '../types/alterations'
import { Relationship } from '../types/ClientPolicies'
import { Fields as AltsContactDetailsFields } from '../types/components/AltsContactDetails'

// utils
import {
  getPolicyTypeLabel,
  getConnectedBenefits,
  getBenefeciaryName,
  getPrimaryLifeInsured,
  // @ts-expect-error file not in typescript
} from '../utils/policyUtils'
import {
  pickVariantByRange,
  getAllLifeInsuredName,
  getEscalationDeadlineDaysForPolicy,
  getBenefitDetailsForPolicyCard,
} from '../utils/alterationPolicies'
// @ts-expect-error file not in typescript
import { policyInvalidMessageForUplift } from '../utils/alterationRules'
// @ts-expect-error file not in typescript
import { getRejectCPISavings, getLinkedPolicyList } from '../utils/alteration'
// @ts-expect-error file not in typescript
import { formatCurrency } from '../utils/quoteUtils'
import {
  getUniqueRelationships,
  constructHeading,
  getPolicyOwners,
  // @ts-expect-error file not in typescript
} from '../utils/relationshipUtils'
import { getContactDetailsData } from '../utils/contactUtils'

export const getAlterationPoliciesByEligibilty = (alterationType: string, isEligible: boolean) =>
  createSelector(
    [getAlterationPolicies, getRulesForAlterationPolicies, getIsAdviserPortal],
    (alterationPolicies, rules, isAdviserPortal) => {
      const policiesListInRules = rules?.businessData?.policies || []
      return alterationPolicies.filter(({ bancsPolicyNo }: { bancsPolicyNo: string }) => {
        const matchingPolicyinAlterationRules = policiesListInRules.find(
          policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
        )
        let policyEligibilityKey: PolicyEligiblityKey
        switch (alterationType) {
          case ALTERATION_TYPES.DECREASE_RISK: {
            policyEligibilityKey = isAdviserPortal
              ? 'decrease_PolicyEligibleForAdviser'
              : 'decrease_PolicyEligibleForCustomer'
            break
          }
          default: {
            policyEligibilityKey = isAdviserPortal
              ? 'rejectCPI_PolicyEligibleForAdviser'
              : 'rejectCPI_PolicyEligibleForCustomer'
          }
        }
        return (
          matchingPolicyinAlterationRules?.assesment?.[policyEligibilityKey] ===
          (isEligible ? 'Y' : 'N')
        )
      })
    }
  )

export const getAlterationPoliciesData = (alterationType: string, isEligible = true) =>
  createSelector(
    [
      getAlterationPoliciesByEligibilty(alterationType, isEligible),
      getRulesForAlterationPolicies,
      getMasterData,
    ],
    (policies, rules, masterData) =>
      policies.map(({ policy, bancsPolicyNo }) => {
        const policiesListInRules = rules?.businessData?.policies || []
        const matchingPolicyinAlterationRules = policiesListInRules.find(
          policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
        )
        const escalationDeadLine = getEscalationDeadlineDaysForPolicy(rules, bancsPolicyNo)
        return {
          bancsPolicyNo,
          policyNumber: policy.identifiers?.find(item => item.type === 'POLICY_ID')?.value,
          productType: getPolicyTypeLabel(policy.productClass),
          anniversary: moment(
            matchingPolicyinAlterationRules?.assesment.rejectCPI_EscalationDate,
            'DD/MM/YYYY'
          ).format('DD MMM YYYY'),
          lifeInsured: getAllLifeInsuredName(policy.relationships),
          ...(isEligible && {
            daysRemaining: {
              variant: pickVariantByRange(escalationDeadLine),
              percentage: Math.ceil((escalationDeadLine / 105) * 100),
              label: escalationDeadLine,
            },
          }),
          ...(!isEligible && {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            reasonOptOut: policyInvalidMessageForUplift(
              rules?.businessData?.policies ?? [],
              bancsPolicyNo,
              (masterData?.alterationMessages ?? []).concat(masterData?.rejectCPICardMessages ?? [])
            ),
          }),
        }
      })
  )

export const getAlterationPoliciesCardsData = (alterationType: string) =>
  createSelector(
    [getAlterationPoliciesByEligibilty(alterationType, true), getAlterations],
    (policies, { rules, policySelectionMap }) => {
      let selectedPolicyPrimaryLifeInsured = ''
      let selectedPolicyEscalationDate = ''
      const selectedPolicy = Object.keys(policySelectionMap).find(
        objKey => policySelectionMap[objKey] === true
      )
      if (selectedPolicy) {
        const alterationPolicy = policies.find(
          altPolicy => altPolicy.bancsPolicyNo === selectedPolicy
        )
        selectedPolicyPrimaryLifeInsured = (
          getPrimaryLifeInsured(alterationPolicy?.policy.relationships) as Relationship[]
        )[0]?.bancsCustomerNo
        selectedPolicyEscalationDate = alterationPolicy?.escalation?.escalationDueDate ?? ''
      }

      return policies.map(policy => {
        const {
          policy: {
            identifiers,
            productClass,
            relationships = [],
            policyPremiumFrequency,
            paymentDetails,
            benefits = [],
          } = {},
          bancsPolicyNo,
          escalation,
        } = policy
        const policiesListInRules = rules?.businessData?.policies || []
        const matchingPolicyinAlterationRules = policiesListInRules.find(
          policyinRule => policyinRule.bancsPolicyNo === bancsPolicyNo
        )
        const isSelected = (policySelectionMap && !!policySelectionMap[bancsPolicyNo]) || false
        const policyNumber = identifiers?.find(item => item.type === 'POLICY_ID')?.value || ''

        const policyDataWithEscalation = (escalation?.policyChangeDetails || []).find(
          item => item.changeType === 'Escalation'
        )
        const policyDataWithoutEscalation = (escalation?.policyChangeDetails || []).find(
          item => item.changeType === 'Re-Rate'
        )
        const collectionFrequency = paymentDetails?.collectionFrequency ?? policyPremiumFrequency

        return {
          policyNumber,
          productType: getPolicyTypeLabel(productClass),
          anniversary: moment(
            matchingPolicyinAlterationRules?.assesment.rejectCPI_EscalationDate,
            'DD/MM/YYYY'
          ).format('DD MMM YYYY'),
          lifeInsured: getAllLifeInsuredName(relationships),
          collectionFrequency:
            (
              REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
                frequency => frequency.value === collectionFrequency
              ) || {}
            ).label?.toLowerCase() ?? '',
          benefitDataWithEscalation: getBenefitDetailsForPolicyCard(
            benefits,
            policyDataWithEscalation?.revisedBenefitDetails
          ),
          policyPremiumWithEscalation: formatCurrency(policyDataWithEscalation?.newNetPremium),
          linkedInternalPolicyList: getLinkedPolicyList(policies, policy),
          linkedPolicies: [
            ...((getConnectedBenefits(policies, policy)?.connectedBenefitsInfo as string[]) || []),
          ].filter(i => !i.includes(policyNumber)),
          hasDifferentPrimaryLifeInsured:
            (selectedPolicyPrimaryLifeInsured &&
              selectedPolicyPrimaryLifeInsured !==
                (getPrimaryLifeInsured(relationships) as Relationship[])[0].bancsCustomerNo) ||
            false,
          hasDifferentAnniversaryDate:
            (selectedPolicyEscalationDate &&
              selectedPolicyEscalationDate !== policy?.escalation?.escalationDueDate) ||
            false,
          isSelected,
          ...(isSelected && {
            benefitDataWithoutEscalation: getBenefitDetailsForPolicyCard(
              benefits,
              policyDataWithoutEscalation?.revisedBenefitDetails
            ),
            policyPremiumWithoutEscalation: formatCurrency(
              policyDataWithoutEscalation?.newNetPremium
            ),
            savings: formatCurrency(getRejectCPISavings(policy)),
            ...(collectionFrequency !== ESCALATION_ANNUAL_FREQUENCY && {
              annualSavings: formatCurrency(
                Math.abs(getRejectCPISavings(policy)) *
                  Number(
                    PAYMENT_FREQUENCY_MULTIPLIER.find(
                      frequency => frequency.value === collectionFrequency
                    )?.label
                  )
              ),
            }),
          }),
        }
      })
    }
  )

export const getIsSelectAllPoliciesAvailable = createSelector(
  [getRulesForAlterationPolicies],
  rules =>
    !(
      rules?.businessData?.assesment?.CP_multiplePrimaryLifeInsured === 'Y' ||
      rules?.businessData?.assesment?.rejectCPI_AnniversaryDateConflictForCustomer === 'Y'
    )
)

export const getSelectedPoliciesPartyContactDetailsList = (
  alterationType: string,
  fields: AltsContactDetailsFields
) =>
  createSelector(
    [getAlterationPoliciesByEligibilty(alterationType, true), getAlterations],
    (eligiblePolicies, { policySelectionMap }) => {
      // Show contact details of the selected policy parties
      const selectedPolicies = eligiblePolicies.filter(
        ({ bancsPolicyNo }) => policySelectionMap[bancsPolicyNo] === true
      )

      // Object with Policy Owner array and Life Insured
      const uniqueRelationships = getUniqueRelationships(
        selectedPolicies,
        false
      ) as (Relationship & {
        roles: string[]
      })[]
      return uniqueRelationships.map(relationship => {
        const {
          roleCode,
          roles,
          relatedParty: { partyType, firstName, lastName, businessName },
        } = relationship
        return {
          heading: constructHeading(
            roleCode,
            roles,
            fields.PolicyOwner && fields.PolicyOwner.value,
            fields.LifeInsured?.value,
            fields.PolicyOwnerAndLifeInsured?.value,
            partyType,
            firstName,
            lastName,
            businessName
          ),
          data: getContactDetailsData(relationship.relatedParty),
        }
      })
    }
  )

export const getSelectedPoliciesData = (alterationType: string) =>
  createSelector(
    [getAlterationPoliciesByEligibilty(alterationType, true), getAlterations],
    (eligiblePolicies, { policySelectionMap }) => {
      // Show contact details of the selected policy parties
      const selectedPolicies = eligiblePolicies.filter(
        ({ bancsPolicyNo }) => policySelectionMap[bancsPolicyNo] === true
      )
      return selectedPolicies.map(policy => {
        const { policy: { identifiers, relationships = [] } = {} } = policy
        const policyNumber = identifiers?.find(item => item.type === 'POLICY_ID')?.value || ''
        const allPolicyOwners = getPolicyOwners(policy.policy) as Array<{
          relationship: Relationship
          policyNumberList: string[]
        }>
        const policyOwnerNames = allPolicyOwners
          .map(({ relationship }) => `${getBenefeciaryName(relationship.relatedParty) as string}`)
          .join(', ')
        return {
          policyNumber,
          lifeInsured: getAllLifeInsuredName(relationships),
          policyOwners: policyOwnerNames,
        }
      })
    }
  )
