// @flow
import styled from '@emotion/styled'
import React, { Fragment } from 'react'
import { Icons, Table, Label, DownloadCSV } from '@mlcl-digital/mlcl-design'

import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

import WithLoader from '../../../molecules/WithLoader'
import quoteTableStyles from './quotesTable.styles'

import { CSV_FILE_EXTENSION } from '../../../../constants/documentTypes'

const IconDisabled = styled(Icons.IconDownload16)(quoteTableStyles.iconDisabled)
const DownloadDisabled = styled(Label)(quoteTableStyles.disabled)

const CSV_ASYNC_ON_CLICK = true

type QuotesTableProps = {
  // sitecore fields
  fields: {
    quoteListingFilterByText: string,
    quoteListingsNoQuotesFound: string,
    quoteDownloadButton: string,
    quoteListingsDownloadFilename: string,
  },
  // columns configuration
  columns: Array<Object>,
  // Data to be displayed in table
  results: Array<Object>,
  // Number of pages for pagination
  pages: Number,
  // Number of records per page
  pageSize: Number,
  // current page index
  currentPageIndex: Number,
  // Boolean flag to indicate data is still fetched
  isLoading: Boolean,
  // Data to be downloaded on CSV button click
  allFormattedRecords: Array<Object>,
  // CSV File headers
  csvHeaders: Array<String>,
  // Flag to indicate whether download quote api call is running
  isDownloadAllQuoteLoading: Boolean,
  // Hanlder functions for Fetch data
  onFetchData: Function,
  // Hanlder functions for Sorting a column
  onSortedChange: Function,
  // Hanlder functions for click
  tableRowClickHandler: Function,
  // Hanlder functions for download quote button click
  downloadQuotesHandler: Function,
}

const QuotesTable = React.forwardRef(
  (
    {
      fields,
      columns,
      results,
      pages,
      pageSize,
      currentPageIndex,
      isLoading,
      allFormattedRecords,
      csvHeaders,
      isDownloadAllQuoteLoading,
      onFetchData,
      onSortedChange,
      tableRowClickHandler,
      downloadQuotesHandler,
    }: QuotesTableProps,
    ref
  ) => {
    const { quoteDownloadButton, quoteListingsDownloadFilename } = reduceAuthorableFields(fields)
    // FIXME: Change the DownloadDisabled based on decision from UX
    return (
      <Fragment>
        {results.length ? (
          <Table
            classes="table-row"
            useTableOptions={{ disableSortRemove: true }}
            columns={columns}
            defaultSorted={[{ id: 'updatedDate', desc: true }]}
            data={results}
            isManual
            pageSize={pageSize}
            pages={pages}
            currentIndex={currentPageIndex}
            isLoading={isLoading}
            footer={
              results.length ? (
                <WithLoader
                  isLoading={isDownloadAllQuoteLoading}
                  loaderProps={{ type: 'noHeight', spinnerSize: 20 }}
                >
                  <DownloadCSV
                    filename={`${quoteListingsDownloadFilename}${CSV_FILE_EXTENSION}`}
                    ref={ref}
                    asyncOnClick={CSV_ASYNC_ON_CLICK}
                    headers={csvHeaders}
                    data={allFormattedRecords}
                    onClick={(event, done) => downloadQuotesHandler(allFormattedRecords, done)}
                  >
                    {quoteDownloadButton}
                  </DownloadCSV>
                </WithLoader>
              ) : (
                <Fragment>
                  <IconDisabled />
                  <DownloadDisabled>{quoteDownloadButton}</DownloadDisabled>
                </Fragment>
              )
            }
            onPageChange={onFetchData}
            onSortedChange={onSortedChange}
            clickHandler={tableRowClickHandler}
            styleOverrides={{
              tableBody: quoteTableStyles.tableBody,
              base: quoteTableStyles.tableBase,
              headerCell: quoteTableStyles.headerCell,
            }}
          />
        ) : (
          []
        )}
      </Fragment>
    )
  }
)

export default QuotesTable
