import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { space, mediaQueries, colours } = Variables as VariablesType

export default {
  container: {
    [mediaQueries.md]: {
      borderBottom: `1px solid ${colours.lightestGrey}`,
    },
    '&:nth-child(2)': {
      [mediaQueries.md]: {
        borderTop: `1px solid ${colours.lightestGrey}`,
      },
    },
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'flex-start' as const,
    '& > div:nth-child(2)': {
      marginLeft: space(1),
      whiteSpace: 'nowrap' as const,
    },
    '& span': {
      width: 'min-content' as const,
      wordBreak: 'initial' as const,
      [mediaQueries.md]: {
        width: 'initial',
      },
    },
  },
  addBeneficiaryButton: {
    width: '100%',
    [mediaQueries.md]: {
      width: 'initial',
    },
  },
}
