// @flow
import React from 'react'
// Organism
import AltsAlterationSaveModalOrgs from '../../../organisms/AltsAlterationSaveModal'

type AltsExitModalProps = {
  fields: Object,
}

const AltsAlterationSaveModal = ({ fields }: AltsExitModalProps) => (
  <AltsAlterationSaveModalOrgs fields={fields} />
)

export default AltsAlterationSaveModal
