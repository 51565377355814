// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'

// components.
import HeadingComponent from '../../../../../../atoms/Heading'
import QuoteSettingActions from './QuoteSettingActions'

// utils.
import { convertStringToKey } from '../../../../../../../utils/commonUtils'
// styles.
import styles from './quoteName.styles'

type QuoteNameProps = {
  value: String,
  quoteNameSettingActionsText: String,
  popoverItems: Array,
}

const Text = styled('span')(styles.quoteName)
const Heading = styled(HeadingComponent)(styles.heading)
const Container = styled('div')(styles.container)

class QuoteName extends Component<QuoteNameProps> {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({ value })
  }

  render() {
    const { value } = this.state
    const { quoteNameSettingActionsText, popoverItems } = this.props

    return (
      <Container>
        <Heading data-locator={convertStringToKey(value)} element="2">
          <Text>{value}</Text>
        </Heading>

        <QuoteSettingActions
          quoteNameSettingActionsText={quoteNameSettingActionsText}
          popoverItems={popoverItems}
        />
      </Container>
    )
  }
}

export default QuoteName
