import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, setColumns, fontSize, colours } = Variables

const styles = {
  offset: {
    marginTop: -space(7, true),
  },
  body: {
    [mediaQueries.lg]: {
      ...setColumns(1.25),
    },
  },
  header: {
    marginBottom: space(3, true),
    color: colours.tertiaryOne,
  },
  section: {
    marginBottom: space(8, true),
  },
  subHeading: {
    padding: `${space(2, true)} 0px`,
    borderBottom: `solid 1px ${colours.tertiaryThree}`,
    maxWidth: space(74),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: space(2),

    [mediaQueries.md]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: space(74),
    },
    '& > div:first-child': {
      fontWeight: 'bold',
    },
    '& > div:last-child': {
      [mediaQueries.md]: {
        textAlign: 'right',
      },
    },
    '&:last-child > div': {
      padding: 0,
    },
  },
  label: {
    flexBasis: '50%',
    fontSize: fontSize.xxs,
    color: colours.tertiaryOne,
    [mediaQueries.md]: {
      fontSize: fontSize.xs,
    },
  },
  button: {
    margin: `${space(3, true)} 0 ${space(7, true)} 0`,
  },
  values: {
    fontSize: fontSize.xs,
    color: colours.tertiaryOne,
    margin: 0,
    [mediaQueries.lg]: {
      maxWidth: space(38),
    },
  },
  authorities: {
    marginBottom: space(10),
  },
}
export default styles
