// @flow
// types.
import get from 'lodash/get'
import { EXPERIENCE_API } from '../types/experienceApi'
import { DOWNLOAD_POLICIES } from '../types/clientReport'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const downloadPolicies =
  (searchData: Object, callback?: Function) =>
  (dispatch: Function): Object => {
    // set the isLoading key in the store.
    dispatch({
      type: DOWNLOAD_POLICIES,
    })
    // dispatch the action to call the api.
    dispatch({
      type: EXPERIENCE_API,
      name: DOWNLOAD_POLICIES,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/advisers/advanceSearch`,
      data: {
        ...searchData,
      },
      callback: (err, dataReceived) => {
        if (callback) {
          const policies = get(dataReceived, 'businessData.policies', [])
          callback(err, policies)
        }
        return dataReceived
      },
    })
  }
