// @flow
import { colours, shadows, space, fontWeights, mediaQueries } from '../../../../../styles'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: space(1, true),
    marginBottom: space(2, true),
    padding: space(2, true),
    position: 'relative',
    flex: 1,
    alignItems: 'flex-start',
    borderWidth: 1,
    borderRadius: 2,
    borderStyle: 'solid',
    transition: 'border .25s ease-in-out',
    boxShadow: shadows.low,
    color: colours.darkestGrey,
    borderColor: colours.lightestGrey,
    background: colours.white,

    [mediaQueries.md]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  documentIcon: {
    marginRight: space(3, true),
    color: colours.darkestGrey,
  },

  downloadFileButton: {
    a: {
      textDecoration: 'none',
      color: colours.mediumGreen,
      fontWeight: fontWeights.bold,
      wordBreak: 'normal',
    },
  },
  documentNameWrapper: ({ onClick }) => ({
    cursor: onClick !== null ? 'pointer' : 'default',
  }),
  tag: {
    color: colours.darkestGrey,
    background: colours.lightestGrey,
    margin: `${space(0.5, true)} ${space(3, true)}`,
  },
}

export default styles
