import { colours, space, fontWeights, fontSize, mediaQueries } from '../../../styles'

const styles = {
  verticaltabs: {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative',
    h6: {
      marginBottom: 0,
      fontWeight: fontWeights.semiBold,
      fontSize: fontSize.sm,
    },
    ul: {
      listStyle: 'none',
      li: {
        padding: `${space(2, true)} 0`,
        borderTop: `1px solid ${colours.lightGrey}`,
        fontSize: fontSize.xs,
        fontWeight: fontWeights.semiBold,
        color: colours.black,
        [mediaQueries.md]: {
          marginTop: space(3),
          padding: 0,
          paddingRight: space(4),
          borderTop: 0,
          fontWeight: fontWeights.normal,
          paddingLeft: space(8),
          width: '25%',
          a: {
            cursor: 'pointer',
            '&:hover': {
              color: colours.mediumGreen,
            },
            '&.active': {
              color: colours.mediumGreen,
              fontWeight: fontWeights.semiBold,
            },
          },
        },
      },
      'li:last-child': {
        marginBottom: space(4),
        [mediaQueries.md]: {
          marginBottom: 0,
        },
      },
      'div > li': {
        borderTop: 0,
      },
    },
    [mediaQueries.md]: {
      paddingLeft: space(2),
      paddingRight: space(2),

      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '29%',
        top: '0',
        backgroundColor: colours.lightGrey,
        height: '100%',
        width: space(0.125),
      },
    },
  },
  listHeading: {
    '&:nth-child(n)': {
      borderTop: 0,
      padding: 0,
      paddingTop: space(2),
      paddingBottom: space(4),
      [mediaQueries.md]: {
        paddingLeft: space(8),
        paddingRight: space(3),
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  title: {
    li: {
      paddingTop: 0,
      border: 0,
      borderTopWidth: 0,
      h6: {
        fontSize: fontSize.sm,
      },
    },
    'li:last-child': {
      marginBottom: 0,
    },
  },
  restoreDefault: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: space(4),
    [mediaQueries.md]: {
      width: '100%',
      justifyContent: 'left',
      cursor: 'pointer',
      paddingTop: space(2),
      div: {
        paddingLeft: space(8),
      },
    },
    button: {
      fontWeight: fontWeights.semiBold,
      paddingLeft: space(1),
      fontSize: fontSize.xs,
      cursor: 'pointer',
    },
  },
}
export default styles
