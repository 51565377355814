// @flow
import React from 'react'
import styled from '@emotion/styled'
import styles from './status.styles'

const Wrapper = styled('div')(styles.wrapper)
const StatusBox = styled('div')(styles.box)
const StatusMessage = styled('div')(styles.message)
const Of = styled('span')(styles.of)

type StatusProps = {
  fields: Object<Object>,
  currentStep: number,
  totalSteps: number,
}

const Status = ({ fields, currentStep, totalSteps }: StatusProps) => (
  <Wrapper>
    <StatusBox>
      {currentStep} <Of>{fields.applicationStepSeparatorText}</Of> {totalSteps}
    </StatusBox>
    <StatusMessage>{fields.applicationStepComponentsMessage}</StatusMessage>
  </Wrapper>
)

export default Status
