// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Input, Tooltip, Icons, Button } from '@mlcl-digital/mlcl-design'
import { get } from 'lodash'

// schemas.
import { USERNAME_FIELD_KEY } from '../Schema/login.schema'

// styles
import styles from '../PasswordForm/passwordForm.styles'
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { ADVISOR_PORTAL } from '../../../../constants/site'

const { IconInfo16 } = Icons

const Wrapper = styled('div')(styles.wrapper)
const TooltipParentContainer = styled('div')(styles.tooltipParentContainer)
const InfoIcon = styled(IconInfo16)(styles.infoIconEarnings)
const InfoIconParent = styled('span')(styles.infoIconParent)
const TooltipStyled = styled(Tooltip)(styles.tooltipStyled)
const Label = styled('div')(styles.labelStyled)
const LabelParent = styled('div')(styles.labelParent)
const InputWrap = styled(Input)(styles.inputWrap)
const Caption = styled('div')(styles.caption)
const CaptionInfoOne = styled(Button)(styles.captionInfoOne)
const CaptionInfoTwo = styled('a')(styles.captionInfoTwo)

type UserNameFormProps = {
  // sitecore fields.
  fields: Object,
  // handle input change
  handleChange: Function,
  // redux form
  formFields: Object,
  components: Object,
  config: Object,
}

const UserNameForm = (props: UserNameFormProps) => {
  const {
    fields: {
      usernamePlaceholder,
      usernameTooltip,
      UsernameIdTooltip,
      UsernameLabel,
      usernameTroubleShootLink,
    },
    components: { handleLoadForgottenUsernameForm },
    handleChange,
    formFields,
    config: { SITE },
  } = props

  return (
    <Fragment>
      {SITE === ADVISOR_PORTAL && (
        <LabelParent>
          <Label>{renderTextField(UsernameLabel)}</Label>
          <TooltipParentContainer>
            <InfoIconParent data-tip data-for="usernameIdTooltip">
              <InfoIcon />
            </InfoIconParent>
            <TooltipStyled id="usernameIdTooltip" place="top">
              {renderTextField(UsernameIdTooltip, true)}
            </TooltipStyled>
          </TooltipParentContainer>
        </LabelParent>
      )}
      <InputWrap
        type="text"
        htmlFor={USERNAME_FIELD_KEY}
        name={USERNAME_FIELD_KEY}
        changeHandler={handleChange}
        placeholder={get(usernamePlaceholder, 'value')}
        value={(formFields[USERNAME_FIELD_KEY] && formFields[USERNAME_FIELD_KEY].value) || ''}
        autoComplete="on"
        required
      />
      <Caption>
        <CaptionInfoOne onClick={handleLoadForgottenUsernameForm} variant="tertiary" size="xsmall">
          {get(usernameTooltip, 'value', '')}
        </CaptionInfoOne>
        <CaptionInfoTwo
          href={get(usernameTroubleShootLink, 'value.href')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {get(usernameTroubleShootLink, 'value.text', '')}
        </CaptionInfoTwo>
      </Caption>
      <Wrapper>
        <Input
          htmlFor="password"
          name="password"
          type="password"
          placeholder="password"
          value=""
          autoComplete="password"
          required
        />
      </Wrapper>
    </Fragment>
  )
}

export default UserNameForm
