// @flow
import React, { useCallback, Fragment } from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

import styles from './ChangeAdviserNav.styles'
import history from '../../../../../utils/browserHistory'

import { SUPPORT_STAFF_SELECT_ADVISER_ROUTE } from '../../../../../constants/routes'
import { ADVISER_NO_KEY } from '../../../../../constants/adviser'

import { space } from '../../../../../styles'

import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { universalCookie } from '../../../../../utils/cookieUtils'

export const Wrapper = styled('div')(styles.navWrapper)
export const ChangeButton = styled('button')(styles.change)

type ChangeAdviserProps = {
  activeAdviser: {
    customerNumber: string,
    firstName: string,
    surname: string,
    organisationName: string,
  },
  fields: {
    changeAdviserLabel: Object,
  },
  actions: {
    restAdvisorData: Function,
  },
}

export const ChangeAdviserNav = (props: ChangeAdviserProps) => {
  const { activeAdviser, fields, actions } = props
  const { customerNumber, organisationName, surname, firstName } = activeAdviser
  const { changeAdviserLabel } = fields
  const { restAdvisorData } = actions

  const handleOnChange = useCallback(() => {
    restAdvisorData()
    universalCookie.remove(ADVISER_NO_KEY)
    history.push(SUPPORT_STAFF_SELECT_ADVISER_ROUTE)
  }, [])
  const name = organisationName || `${surname}, ${firstName}`
  if (customerNumber) {
    return (
      <Fragment>
        <Global
          styles={css`
            .page-container {
              padding-top: ${space(4, true)};
            }
          `}
        />
        <Wrapper>
          {name} - {customerNumber}
          <ChangeButton onClick={handleOnChange} type="button">
            {renderTextField(changeAdviserLabel)}
          </ChangeButton>
        </Wrapper>
      </Fragment>
    )
  }
  return null
}

export const mapStateToProps = ({ supportStaff: { activeAdviser } }) => ({
  activeAdviser,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAdviserNav)
