import { Variables } from '@mlcl-digital/mlcl-design'

const { fontSize, space, mediaQueries, colours, mediaQueriesMax } = Variables

const styles = {
  base: {
    position: 'relative',
    zIndex: 1,
    maxWidth: space(73.5),
    margin: '0 auto',
    [mediaQueriesMax.md]: {
      border: 'none',
    },
  },
  header: {
    padding: space(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    [mediaQueriesMax.md]: {
      borderBottom: 'none',
    },
    '& a ': {
      maxWidth: space(8.75),
    },
  },
  headerOuterContent: {
    minWidth: '20%',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    h2: {
      textAlign: 'center',
      marginBottom: `${space(1, true)}`,
    },
    [mediaQueries.md]: {
      padding: `${space(3, true)} ${space(10, true)}`,
    },
    '& > div': {
      width: '100%',
    },
  },
  form: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      width: '100%',
    },
  },
  footer: {
    textAlign: 'center',
    flex: 1,
    width: '100%',
    padding: space(2),
    borderTop: 'none',
    color: colours.tertiaryOne,
    [mediaQueriesMax.md]: {
      background: 'none',
    },
  },
  nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: fontSize.xs,
    padding: '17px',
    height: 'auto',
    svg: {
      marginRight: space(0.5),
      color: 'inherit',
    },
  },
  loginWrapper: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: fontSize.xs,
    '& a': {
      textDecoration: 'none',
      display: 'inline',
      padding: 0,
      color: colours.mlcAquaDark,
      marginLeft: space(1),
    },
  },

  formSubmitButton: {
    width: '100%',
    [mediaQueries.md]: {
      width: 'auto',
    },
  },

  spinner: {
    minHeight: 0,
  },
  descriptionWrapper: {
    textAlign: 'center',
    marginBottom: space(3),
    color: colours.tertiaryOne,
    '& .adviserInfo': {
      width: space(39),
      textAlign: 'left',
      opacity: 1,
    },
  },
  thirdPartyProgressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '400px',
    paddingBottom: '106px',
  },

  thirdPartyQuoteProgressMessage: {
    textAlign: 'center',
    color: colours.mediumGrey,
  },
}
export default styles
