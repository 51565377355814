import React, { Component } from 'react'
import get from 'lodash/get'
import styled from '@emotion/styled'
import Proptypes from 'prop-types'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Select } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

import history from '../../../utils/browserHistory'
import { showBeneficiariesBasedOnAllPolicies } from '../../../utils/policyUtils'
import { shouldRenderNativeLink } from '../../../utils/commonUtils'
import { isBrowser } from '../../../utils/browserUtils'
import styles from './customerConcierge.styles'
import { CUSTOMER_CONCIERGE_OPTIONS } from '../../../constants/customer'
import { CUSTOMER_MAKE_A_PAYMENT } from '../../../constants/routes'

// selectors
import { getIsPremiumCalculatorEligible } from '../../../selectors/premiumCalculator'
import { getIsAnyPolicyAltered } from '../../../selectors/createQuote'

const SelectWrap = styled(Select)(({ noOfOptions, styleOverrides }) => ({
  ...styles.selectWrap(noOfOptions),
  ...styleOverrides,
}))

export class CustomerConcierge extends Component {
  onSelectOfOption = option => {
    const { params, actions, isAnyPolicyAltered } = this.props
    const { setIsConfirmLeaveModal } = actions
    const tagEvent = createEvent({
      GA: {
        category: 'Customer selects option in concierge',
        action: `CP concierge - ${option.value.label}`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Customer selects option in concierge - ${option.value.label}`,
        },
      },
    })
    tagEvent.end()

    if (option.value && option.value.link && option.value.link === CUSTOMER_MAKE_A_PAYMENT) {
      const event = createEvent({
        GA: { category: 'Make a payment - Concierge', action: 'CP - make a payment' },
        Splunk: {
          attributes: {
            'workflow.name': 'Make a payment - Concierge',
          },
        },
      })
      event.end()
    }
    if (params?.Identifier === 'premiumCalculator' && isAnyPolicyAltered) {
      setIsConfirmLeaveModal(true, option.value.link)
    } else {
      shouldRenderNativeLink(option.value.link)
        ? isBrowser() && window.open(option.value.link, '_blank')
        : history.push(option.value.link)
    }
  }

  onFocusHandler = () => {
    const tagEvent = createEvent({
      GA: { category: 'CP-SelectOption', action: 'Select Option' },
      Splunk: {
        attributes: {
          'workflow.name': 'Selects concierge',
        },
      },
    })
    tagEvent.end()
  }

  createOptions = () => {
    const { fields, policies, isPremiumCalculatorEligible } = this.props
    const {
      cpConciergeChangingMyAddress,
      cpConciergeDownloadingCertificateCurrency,
      cpConciergeUpdatingBeneficiaries,
      cpConciergeUpdatingContactDetails,
      cpConciergeUpdatingPaymentDetails,
      cpConciergeMakeAPayment,
      ConciergeAssessMyCurrentNeeds,
    } = fields
    return [
      {
        label: get(fields, 'cpChangingMyAddress.value', cpConciergeChangingMyAddress),
        link: CUSTOMER_CONCIERGE_OPTIONS.customerPersonalDetails,
      },
      {
        label: get(
          fields,
          'cpDownloadingCertificateCurrency.value',
          cpConciergeDownloadingCertificateCurrency
        ),
        link: CUSTOMER_CONCIERGE_OPTIONS.documents,
      },
      ...(Object.keys(policies).length !== 0 &&
      showBeneficiariesBasedOnAllPolicies(Object.values(policies))
        ? [
            {
              label: get(fields, 'cpUpdatingBeneficiaries.value', cpConciergeUpdatingBeneficiaries),
              link: CUSTOMER_CONCIERGE_OPTIONS.beneficiaries,
            },
          ]
        : []),
      {
        label: get(fields, 'cpUpdatingContactDetails.value', cpConciergeUpdatingContactDetails),
        link: CUSTOMER_CONCIERGE_OPTIONS.customerPersonalDetails,
      },
      {
        label: get(fields, 'cpUpdatingPaymentDetails.value', cpConciergeUpdatingPaymentDetails),
        link: CUSTOMER_CONCIERGE_OPTIONS.paymentDetails,
      },
      {
        label: get(fields, 'cpMakeAPayment.value', cpConciergeMakeAPayment),
        link: CUSTOMER_CONCIERGE_OPTIONS.makeAPayment,
      },
      ...(isPremiumCalculatorEligible
        ? [
            {
              label: get(fields, 'AssessMyCurrentNeeds.value', ConciergeAssessMyCurrentNeeds),
              link: CUSTOMER_CONCIERGE_OPTIONS.assessNeeds,
            },
          ]
        : []),
    ]
  }

  render() {
    const {
      fields: { cpConciergeSelectOption, cpCustomerConciergeSelectOption },
      styleOverrides,
    } = this.props
    const placeholderText = cpConciergeSelectOption
      ? cpConciergeSelectOption.value
      : cpCustomerConciergeSelectOption
    const options = this.createOptions()
    return (
      <SelectWrap
        noOfOptions={options.length}
        placeholder={placeholderText}
        options={options}
        changeHandler={this.onSelectOfOption}
        variant="tertiary"
        name="conciergeDropdown"
        id="conciergeDropdown"
        onFocus={this.onFocusHandler}
        styleOverrides={styleOverrides.selectWrap}
      />
    )
  }
}
const { shape, object, objectOf, string, bool } = Proptypes
CustomerConcierge.propTypes = {
  fields: shape({
    cpConciergeChangingMyAddress: string,
    cpConciergeDownloadingCertificateCurrency: string,
    cpConciergeUpdatingBeneficiaries: string,
    cpConciergeUpdatingContactDetails: string,
    cpConciergeUpdatingPaymentDetails: string,
  }).isRequired,
  styleOverrides: objectOf(object),
  policies: objectOf(object).isRequired,
  params: shape({
    Identifier: string,
  }).isRequired,
  actions: Object.isRequired,
  isAnyPolicyAltered: bool.isRequired,
  isPremiumCalculatorEligible: bool.isRequired,
}

CustomerConcierge.defaultProps = {
  styleOverrides: {},
}

export const mapStateToProps = state => {
  const { customerPolicies, createQuote } = state
  return {
    policies: customerPolicies,
    isAnyPolicyAltered: getIsAnyPolicyAltered({ createQuote }),
    isPremiumCalculatorEligible: getIsPremiumCalculatorEligible(state),
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerConcierge)
