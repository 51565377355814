// @flow
/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import styled from '@emotion/styled'
import { SectionHeader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// styles
import applicationReviewStyles from '../../applicationReview.styles'
import styles from './documentUpload.styles'

// components
import UploadCard from '../UploadCard'
import FileUpload from '../../../../molecules/FileUpload'
import FileDownload from '../../../../molecules/FileUpload/components/FileDownload'

// utils
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { BENEFICIARY_TYPE_BINDING_CODE } from '../../../../../constants/forms'
import { POLICY_PRODUCT_CODE_SUPER } from '../../../../../constants/policies'
import { REFERRED } from '../../../../../constants/application'

// TODO: Change this function when exact data is available
const getDocumentdata = (documents, currectDoc) => {
  let filteredDocument = documents.filter(
    elem =>
      elem.documentTypeName && elem.documentTypeName.toLowerCase() === currectDoc.toLowerCase()
  )
  filteredDocument = filteredDocument[0] || false
  return filteredDocument
}
// TODO: Change this function when exact data is available
const getdocumentList = (documents, isUnderWritingUnderReview) => {
  const documentList = []
  const certificateId = getDocumentdata(documents, 'Certified ID')
  const autorityToCancel = getDocumentdata(documents, 'Authority to cancel')
  const medicalEvidence = getDocumentdata(documents, 'Medical evidence')
  const financialEvidence = getDocumentdata(documents, 'Financial evidence')
  const quote = getDocumentdata(documents, 'Quote')
  const other = getDocumentdata(documents, 'Other')
  if (certificateId) {
    documentList.push(certificateId)
  }
  if (autorityToCancel) {
    documentList.push(autorityToCancel)
  }
  if (medicalEvidence && isUnderWritingUnderReview) {
    documentList.push(medicalEvidence)
  }
  if (financialEvidence && isUnderWritingUnderReview) {
    documentList.push(financialEvidence)
  }
  if (quote && isUnderWritingUnderReview) {
    documentList.push(quote)
  }
  if (other && isUnderWritingUnderReview) {
    documentList.push(other)
  }
  return documentList
}

const uploadDocCategories = (
  documents,
  fields,
  isBeneficiaryTypeBinding,
  isUnderWritingUnderReview
) => [
  ...(isBeneficiaryTypeBinding
    ? [
        {
          headerText: fields.uploadDocumentBeneficiaryNominationHeaderText,
          wrapperText: fields.uploadDocumentBeneficiaryNominationBodyText,
          footerText: fields.uploadDocumentBeneficiaryNominationFooterText,
          data: getDocumentdata(documents, fields.uploadDocumentBeneficiaryNominationFormName),
        },
        {
          headerText: fields.uploadDocumentOtherDocumentsHeaderText,
          wrapperText: fields.uploadDocumentOtherDocumentsBodyText,
          footerText: fields.uploadDocumentOtherDocumentsFooterText,
          data: getdocumentList(documents, isUnderWritingUnderReview),
        },
      ]
    : [
        {
          headerText: fields.uploadDocumentOtherDocumentsHeaderText,
          wrapperText: fields.uploadDocumentOtherDocumentsBodyText,
          footerText: fields.uploadDocumentOtherDocumentsFooterText,
          data: getdocumentList(documents, isUnderWritingUnderReview),
        },
      ]),
]

const Container = styled('div')(applicationReviewStyles.infoContainer, styles.infoContainer)
const FileDownloadComponent = styled(FileDownload)(styles.fileDownload)

type DocumentUploadProps = {
  // sitecore fields
  fields: Object,
  // qoute collection data
  quoteData: {
    quoteCollectionId: String,
  },
}

const checkBeneficiaryTypeBinding = ({ policyStructure }) => {
  let isBeneficiaryTypeBinding = false
  const superPolicy = policyStructure.filter(quote => quote.productId === POLICY_PRODUCT_CODE_SUPER)
  if (!superPolicy.length) {
    return isBeneficiaryTypeBinding
  }

  superPolicy.map(
    policy =>
      policy.beneficiaries &&
      policy.beneficiaries.forEach(beneficiary => {
        if (beneficiary.type === BENEFICIARY_TYPE_BINDING_CODE) {
          isBeneficiaryTypeBinding = true
        }
      })
  )

  return isBeneficiaryTypeBinding
}

// TODO: Add getUploadedDocumentList action when done from mule end
export class DocumentUploadComponent extends React.PureComponent<DocumentUploadProps> {
  constructor(props) {
    super(props)
    this.state = {
      docSubtypeCode: '',
      selectedData: {},
    }
    const {
      activeQuote,
      activeQuote: { underwritingDetails = {} },
    } = props
    this.isBeneficiaryTypeBinding = checkBeneficiaryTypeBinding(activeQuote)
    this.isUnderWritingUnderReview = underwritingDetails.calculatedUREStatus === REFERRED
  }

  openModal = data => {
    this.setState({ selectedData: data })
  }

  dropDownChange = ({ value }) => {
    this.setState({ docSubtypeCode: value })
  }

  renderRecentUploads = recentUploads =>
    recentUploads.map(document => (
      <FileDownloadComponent
        key={`${document.documentTypeName}`}
        formDownloadMeta={{
          documentName: document.documentTypeName,
        }}
        showDownload={false}
        documentTypeTag={document.documentType}
      />
    ))

  render() {
    const {
      fields,
      documents,
      quoteData: { quoteCollectionId },
      fileUploadInfo: { isModalOpen, [quoteCollectionId]: recentUploads = [] },
      config,
    } = this.props
    const reducedFields = reduceAuthorableFields(fields)
    const { documentUploadSectionHeading, uploadDocumentDropDownPlaceHolder } = reducedFields
    const relatedDocuments = uploadDocCategories(
      documents,
      reducedFields,
      this.isBeneficiaryTypeBinding,
      this.isUnderWritingUnderReview
    )
    const { docSubtypeCode, selectedData } = this.state
    const {
      uploadDocumentModalHeading,
      uploadDocumentModalSubHeading,
      uploadDocumentModalConfirm,
      uploadDocumentMaxFileSizeError,
      uploadDocumentWrongMimeType,
      uploadDocumentFileUploading,
      uploadDocumentUploadSuccess,
      uploadDocumentUploadFailure,
      uploadDocumentWorkItemFailure,
      uploadDocumentWorkItemSuccess,
      uploadDocumentFileRemove,
      uploadDocumentNoFileError,
      uploadDocumentWrongFileNameError,
      uploadDocumentDropDownSelectError,
    } = reduceAuthorableFields(fields)
    const fileUploadProps = {
      modalMeta: {
        modalHeading: uploadDocumentModalHeading,
        modalSubHeading: uploadDocumentModalSubHeading,
        modalConfirmButton: uploadDocumentModalConfirm,
      },
      fileUploadMeta: {
        maxFileSizeError: uploadDocumentMaxFileSizeError,
        wrongFileType: uploadDocumentWrongMimeType,
        fileUploading: uploadDocumentFileUploading,
        fileUploadSuccess: uploadDocumentUploadSuccess,
        fileUploadFailure: uploadDocumentUploadFailure,
        createWorkItemError: uploadDocumentWorkItemFailure,
        createWorkItemSuccess: uploadDocumentWorkItemSuccess,
        fileRemoveButtonLabel: uploadDocumentFileRemove,
        noFileUploadedError: uploadDocumentNoFileError,
        wrongFileNameError: uploadDocumentWrongFileNameError,
        noSelectedDocSubtypeCode: uploadDocumentDropDownSelectError,
      },
      workItemRequired: false,
      saveFileUpload: true,
      document: selectedData,
      ...(selectedData.length
        ? {
            dropDownData: selectedData.reduce((dataArray, item) => {
              dataArray.push({
                label: item.documentTypeName,
                value: item.documentCode,
              })
              return dataArray
            }, []),
            uploadDocumentRequest: {
              docSubTypeCode: docSubtypeCode.value,
              agreementNumber: quoteCollectionId,
            },
          }
        : {
            formDownloadMeta: {
              documentName: selectedData.documentTypeName,
              documentPath: {
                value: `<a href="${config.MLCL_SITECORE_API}${selectedData.link}" target="_blank">${selectedData.linkText}</a>`,
              },
              docSubTypeCode: selectedData.documentCode,
            },
            uploadDocumentRequest: {
              docSubTypeCode: selectedData.documentCode,
              agreementNumber: quoteCollectionId,
            },
          }),
      dropDown: {
        placeHolder: uploadDocumentDropDownPlaceHolder,
      },
      dropDownChange: this.dropDownChange,
      disableMultipleFilesUpload: true,
    }

    return (
      <React.Fragment>
        <SectionHeader heading={documentUploadSectionHeading} />
        <Container>
          {relatedDocuments.map(
            category =>
              category.headerText && (
                <UploadCard
                  key={category.headerText}
                  headerText={category.headerText}
                  wrapperText={category.wrapperText}
                  footerText={category.footerText}
                  fields={fields}
                  data={category.data}
                  openModal={this.openModal}
                />
              )
          )}
        </Container>
        {this.renderRecentUploads(recentUploads)}

        {isModalOpen && <FileUpload {...fileUploadProps} />}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  fileUploadInfo,
  masterList: { data = {} },
  createQuote: { quotes, activeIndex },
  config,
}) => ({
  fileUploadInfo,
  documents: data.documentTypes || [],
  activeQuote: quotes[activeIndex],
  config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploadComponent)
