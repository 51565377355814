// @flow
// components.
import React from 'react'
import ContactDetailsOrganism from '../../../molecules/ContactDetails'

type ContactDetailsProps = {
  // sitecore fields
  fields: Object<Object>,
}

const ContactDetails = (props: ContactDetailsProps) => {
  const { fields } = props
  return <ContactDetailsOrganism fields={fields} {...props} />
}

export default ContactDetails
