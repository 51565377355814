import { Variables } from '@mlcl-digital/mlcl-design'
import { space, colours, shadows, fontWeights } from '../../../../../styles'

import browser from '../../../../../utils/browserUtils'

const { fontSize } = Variables

const isIE11 = browser.ie && browser.ie === 11

const styles = {
  buttonElement: {
    display: 'block',
    flexBasis: isIE11 ? '42%' : `calc(50% - ${space(1, true)})`,
    boxShadow: shadows.mid,
    border: `solid 1px ${colours.lightGrey}`,
    background: colours.white,
    padding: space(1),
    textDecoration: 'none',
    '& > *': {
      display: 'block',
    },
  },
  buttonTitle: {
    color: colours.darkestGrey,
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    paddingTop: '4px',
  },
  icon: {
    margin: `0 auto ${space(1, true)}`,
    color: colours.black,
    width: space(5, true),
    height: '32px',
    fontWeight: fontWeights.bold,
    fontSize: fontSize.lgr,
  },
  buttonIcon: {
    color: colours.lightGreen,
    fontSize: fontSize.smd,
  },
  buttonText: {
    color: colours.darkGrey,
    fontSize: fontSize.xsm,
    fontWeight: fontWeights.light,
    lineHeight: fontSize.md,
  },
  buttonDescription: {
    color: colours.darkGrey,
    fontSize: fontSize.xsm,
    fontWeight: fontWeights.semiBold,
    lineHeight: fontSize.md,
    padding: '4px',
    textAlign: 'center',
  },
}
export default styles
