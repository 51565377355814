// @FIXME: fix method sorting
/* eslint-disable react/sort-comp */
// @flow
import React, { Component, Fragment } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'
import { getExistingClientBancsCustomerNo } from '../../../../../selectors/createQuote'

// atoms
import ButtonComponent from '../../../../atoms/Button'
import IconEdit16 from '../../../../atoms/Icons/IconEdit16'
import IconClose16 from '../../../../atoms/Icons/IconClose16'

// molecules
import RadioGroup from '../../../../molecules/RadioGroup'
import ToggleGroup from '../../../../molecules/ToggleGroup'
import Modal from '../../../../molecules/Modal'

// components
import AddPolicy from '../../../QuoteTool/components/QuoteToolPolicyDetail/AddPolicy'

// constants
import { POLICY_PRODUCT_CODE_NON_SUPER } from '../../../../../constants/policies'
import { BENEFIT_REPLACE } from '../../../../../constants/personalStatement'
import { DEFAULT_INSURERS } from '../../../../../constants/forms'
// eslint-disable-next-line import/no-named-as-default
import Policy from './ExistingPolicy'

// utils
import { getProduct, getTotalSumInsuredForQuote } from '../../../../../utils/quoteUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { getSerializedFormState, generateFieldsFromData } from '../../../../../utils/formUtils'
import { getNonFixedExistingCovers } from '../../../../../utils/policyUtils'
import { ignoreColumns } from '../../../../../utils/stylesUtils'
import { getShortStartDateFormat } from '../../../../../utils/extendedQuoteUtils'

// schema
import schema, { FORM_ID, defaults } from './existingCover.schema'
import {
  FORM_ID as EXISTING_POLICY_FORM_ID,
  schema as existingPolicySchema,
  defaults as existingPolicyDefaults,
} from './ExistingPolicy/existingPolicy.schema'

// styles
import styles from './existingCover.styles'

const FullWidthToggleGroup = ignoreColumns(ToggleGroup)
const ExitingCover = styled('div')(styles.exitingCoverSection)
const ExitingCoverHeader = styled('div')(styles.exitingCoverHeader)
const ExitingCoverDescription = styled('div')(styles.exitingCoverDescription)
const ExitingCoverDetails = styled('div')(styles.exitingCoverDetails)
const DetailsLeft = styled('div')(styles.detailsLeft)
const DetailsRight = styled('div')(styles.detailsRight)
const BenefitTitle = styled('div')(styles.benefitTitle)
const DetailsItem = styled('div')(styles.detailsItem)
const Button = styled(ButtonComponent)(styles.button)
const ModalDescription = styled('div')(styles.modalTextDescription)
const PolicyLayout = styled('div')(styles.policyLayout)
const PolicyTitle = styled('div')(styles.policyTitle)
const EditIcon = styled(IconEdit16)(styles.icon)
const DeleteIcon = styled(IconClose16)(styles.icon)
const ActionButton = styled(Button)(styles.actionButton)
const PolicyDetailContainer = styled('div')(styles.policyDetailContainer)
const ActionItemContainer = styled('div')(styles.actionItemContainer)
const InsurerText = styled('div')(styles.insurerText)
const PolicyText = styled('div')(styles.policyText)

const getBenefitReplaceOptions = fields => {
  const { personalStatementExistingCoverOptionKeep, personalStatementExistingCoverOptionReplace } =
    reduceAuthorableFields(fields)
  return [
    { label: `${personalStatementExistingCoverOptionKeep}`, value: 'Keep', shortLabel: 'K' },
    { label: `${personalStatementExistingCoverOptionReplace}`, value: 'Replace', shortLabel: 'R' },
  ]
}

type ExistingCoverProps = {
  fields: Object,
  actions: Object,
  form: Object,
  benefits: Object,
  renderErrorMessage: Function,
  existingCovers: Object,
  showExistingCoverAnyPolicyError: Boolean,
  existingClient: String,
  features: Array<Object>,
  existingClientCovers: Object,
  toggleContinueButton: Function,
  disableContinue: Boolean,
  resetExistingCover: Function,
  quote: Object,
}

export class ExistingCover extends Component<ExistingCoverProps> {
  constructor(props) {
    super(props)

    this.addPolicy = this.addPolicy.bind(this)
    this.handleExistingCoverChange = this.handleExistingCoverChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.removePolicy = this.removePolicy.bind(this)
    this.removeAllPolicies = this.removeAllPolicies.bind(this)
    this.getCurrentPolicy = this.getCurrentPolicy.bind(this)
    this.updatePolicy = this.updatePolicy.bind(this)
    this.state = {
      SCHEMA: schema(props.fields),
      togglePolicyStatusModal: false,
      activePolicyToggle: { policyInstanceNo: '', benefitInstanceNo: '', action: '' },
      currentSelectedPolicy: 0,
      addNewPolicy: false,
      editingPolicyIndex: -1,
    }
  }

  componentWillMount() {
    const { actions, form, existingCovers, existingClient, existingClientCovers } = this.props
    const { SCHEMA } = this.state
    const { formInit, getPoliciesForLifeAssured } = actions
    let updatedExistingCovers = false
    if (existingCovers) {
      updatedExistingCovers = this.modifyExistingCover()
    }

    if (!form) formInit(FORM_ID, SCHEMA, updatedExistingCovers || defaults())

    // Api calls when existing client with only inforce policy
    // invoke only once
    if (
      existingClient &&
      (!existingClientCovers ||
        (!existingClientCovers.existingInsurances &&
          existingClientCovers &&
          existingClientCovers.consent &&
          existingClientCovers.consent.name === 'EXISTING_COVER' &&
          existingClientCovers.consent.value))
    ) {
      getPoliciesForLifeAssured(existingClient)
    }
  }

  componentDidUpdate() {
    const { toggleContinueButton, disableContinue } = this.props
    const { addNewPolicy, editingPolicyIndex } = this.state

    if ((addNewPolicy || editingPolicyIndex > -1) && !disableContinue) {
      toggleContinueButton(true)
    }
  }

  componentWillUnmount() {
    const {
      actions: { deleteForms },
    } = this.props
    deleteForms([FORM_ID])
  }

  getCurrentPolicy(policyIndex) {
    const { form } = this.props
    const {
      fields: {
        existingInsurances: { value: existingInsurances },
      },
    } = form
    return existingInsurances[policyIndex]
  }

  handleModalClose = () => {
    this.setState({ togglePolicyStatusModal: false })
  }

  handleModalConfirm = () => {
    const {
      actions: {
        createQuoteCalculate,
        createQuoteSetPolicyStructureOnTrack,
        updatedPolicyReplaceStatus,
      },
      quote,
    } = this.props
    const { activePolicyToggle } = this.state

    createQuoteSetPolicyStructureOnTrack(false, 'mlcOnTrack')

    // check if total sum insured for the quote is over 0, then recalculate.
    if (quote && getTotalSumInsuredForQuote(quote.policyStructure) > 0) {
      createQuoteCalculate()
    }

    this.setState({ togglePolicyStatusModal: false })

    updatedPolicyReplaceStatus(
      activePolicyToggle.policyInstanceNo,
      activePolicyToggle.benefitInstanceNo,
      activePolicyToggle.action
    )
  }

  editHandler = policyIndex => () => {
    this.resetAddNewPolicyForm()
    this.setState({ editingPolicyIndex: policyIndex })
  }

  deleteHandler = policyIndex => {
    this.removePolicy(policyIndex)
  }

  updateCurrentSelectedPolicy = policyIndex => {
    this.setState({ currentSelectedPolicy: policyIndex })
  }

  resetAddNewPolicyForm = () => {
    const { toggleContinueButton } = this.props
    toggleContinueButton(false)
    this.setState({ addNewPolicy: false })
  }

  modifyExistingCover() {
    const { existingCovers, existingClient, existingClientCovers } = this.props
    const coversToProcess = !existingClient
      ? existingCovers
      : getNonFixedExistingCovers(existingClientCovers)
    const updatedExistingPolicies =
      coversToProcess &&
      coversToProcess.existingInsurances &&
      coversToProcess.existingInsurances.reduce((updatedExistingPolicy, policy) => {
        let updatedPolicy = {
          ...policy,
          benefits: [...policy.benefits],
          insurer: DEFAULT_INSURERS.filter(insurer => insurer.value === policy.insurer)[0],
        }
        updatedPolicy.benefits =
          updatedPolicy.benefits &&
          updatedPolicy.benefits.reduce((updatedBenefits, benefit) => {
            let updatedBenefit = { ...benefit }
            updatedBenefit = {
              ...generateFieldsFromData(updatedBenefit),
              ...generateFieldsFromData({
                startdate: moment(benefit.startdate, 'YYYY-MM-DD').format('MM/YY'),
              }),
              ...generateFieldsFromData({
                replacingThisCover: benefit.replacingThisCover ? 'yes' : 'no',
              }),
              ...generateFieldsFromData({
                allowableFeatures: benefit.allowableFeatures
                  ? benefit.allowableFeatures.map(elem => ({
                      ...elem,
                      selected: elem.selected ? 'yes' : 'no',
                    }))
                  : [],
              }),
            }
            updatedBenefits.push(updatedBenefit)
            return updatedBenefits
          }, [])

        updatedPolicy = {
          ...generateFieldsFromData(updatedPolicy),
        }
        updatedExistingPolicy.push(updatedPolicy)
        return updatedExistingPolicy
      }, [])
    return {
      consent: {
        name: coversToProcess.consent.name,
        value:
          coversToProcess.consent.value && coversToProcess.consent.value !== 'false' ? 'yes' : 'no',
      },
      existingInsurances: updatedExistingPolicies,
    }
  }

  handleExistingCoverChange(value) {
    const { actions, form, existingCovers, resetExistingCover, toggleContinueButton } = this.props
    const { formUpdateField, formReset } = actions
    const { SCHEMA } = this.state
    const {
      fields: { consent },
    } = form
    const updatedConsent = {
      error: consent.error,
      value: {
        name: consent.value.name,
        value,
      },
    }
    let updatedExistingCovers = false
    if (existingCovers) {
      updatedExistingCovers = this.modifyExistingCover()
    }
    formReset(FORM_ID, SCHEMA, updatedExistingCovers || defaults())
    if (value === 'no') {
      this.removeAllPolicies()
      toggleContinueButton(false)
      this.resetAddNewPolicyForm()
      this.resetEditMode()
    }
    if (
      value === 'yes' &&
      !(
        updatedExistingCovers &&
        updatedExistingCovers.existingInsurances &&
        updatedExistingCovers.existingInsurances.length
      )
    ) {
      // this.addPolicy()
      this.setState({ addNewPolicy: true })
      toggleContinueButton(true)
    }

    resetExistingCover()
    formUpdateField(FORM_ID, 'consent', updatedConsent)
  }

  handleInputChange(policyIndex, value) {
    const { form } = this.props
    const currentPolicy = this.getCurrentPolicy(policyIndex)
    const {
      fields: { existingInsurances },
    } = form
    const { name, value: inputValue } = value
    currentPolicy[name] = {
      error: false,
      value: inputValue,
    }
    existingInsurances.value[policyIndex] = currentPolicy
  }

  removePolicy(policyIndex) {
    const { actions, form } = this.props
    const { formUpdateField } = actions
    const {
      fields: { existingInsurances },
    } = form
    if (existingInsurances.value.length === 1) {
      this.handleExistingCoverChange('')
    }
    existingInsurances.value.splice(policyIndex, 1)
    formUpdateField(FORM_ID, 'existingInsurances', existingInsurances)
  }

  removeAllPolicies() {
    const { actions, form } = this.props
    const { formUpdateField } = actions
    const {
      fields: { existingInsurances },
    } = form
    if (existingInsurances.value.length === 1) {
      this.handleExistingCoverChange()
    }
    existingInsurances.value = []
    formUpdateField(FORM_ID, 'existingInsurances', existingInsurances)
  }

  addPolicy() {
    const { actions, form, fields } = this.props
    const { formReset } = actions
    const { addNewPolicy } = this.state
    const {
      fields: { existingInsurances },
    } = form
    this.resetEditMode()
    if (!addNewPolicy) {
      formReset(EXISTING_POLICY_FORM_ID, existingPolicySchema(fields), existingPolicyDefaults())
    }
    this.setState({
      currentSelectedPolicy: existingInsurances.value.length - 1,
      addNewPolicy: true,
    })
  }

  resetEditMode = () => {
    this.setState({ editingPolicyIndex: -1 })
  }

  handleToggle(event, policyInstanceNo, benefitInstanceNo) {
    const {
      actions: { updatedPolicyReplaceStatus },
    } = this.props
    const { quote } = this.props
    const mlcOnTrack =
      quote && quote.policyStructure.length ? quote.policyStructure[0].mlcOnTrack : false

    this.setState({
      activePolicyToggle: {
        policyInstanceNo,
        benefitInstanceNo,
        action: event.value,
      },
    })
    if (event.value.toLowerCase() === BENEFIT_REPLACE.toLowerCase() && mlcOnTrack) {
      this.setState({ togglePolicyStatusModal: true })
    } else {
      this.setState({ togglePolicyStatusModal: false })
      updatedPolicyReplaceStatus(policyInstanceNo, benefitInstanceNo, event.value)
    }
  }

  updatePolicy(currentPolicy, policyIndex) {
    const { actions, form } = this.props
    const { formUpdateField } = actions
    const {
      fields: { existingInsurances },
    } = form
    const updatedExistingInsurances = { ...existingInsurances }
    if (updatedExistingInsurances && updatedExistingInsurances.value) {
      updatedExistingInsurances.value[policyIndex] = currentPolicy
    } else {
      updatedExistingInsurances.value = [currentPolicy]
    }
    formUpdateField(FORM_ID, 'existingInsurances', updatedExistingInsurances)
  }

  renderPolicy(currentPolicy, policyIndex, existingInsurances, existingInsurancesError) {
    const { fields, benefits, renderErrorMessage, actions } = this.props
    const { currentSelectedPolicy } = this.state

    return (
      <Policy
        fields={fields}
        key={policyIndex}
        actions={actions}
        currentPolicy={currentPolicy ? getSerializedFormState(currentPolicy) : ''}
        policyIndex={policyIndex}
        handleInputChange={this.handleInputChange}
        removePolicy={this.removePolicy}
        benefits={benefits}
        existingInsurances={existingInsurances}
        existingInsurancesError={existingInsurancesError && existingInsurancesError[policyIndex]}
        updatePolicy={this.updatePolicy}
        renderErrorMessage={renderErrorMessage}
        currentSelectedPolicy={currentSelectedPolicy}
        updateCurrentSelectedPolicy={this.updateCurrentSelectedPolicy}
        resetAddNewPolicyForm={this.resetAddNewPolicyForm}
        resetEditMode={this.resetEditMode}
        handleExistingCoverChange={this.handleExistingCoverChange}
      />
    )
  }

  // Render what all options are selected in existing covers
  renderSelectedBenefitOptions(options) {
    const { features } = this.props
    const selectedOptions = []
    if (options)
      options.forEach(option => {
        const featureFound = features.find(feature => feature.code === option.code)
        if (featureFound) selectedOptions.push(featureFound.value)
      })

    return selectedOptions.length ? selectedOptions.join() : ''
  }

  renderDescripiton() {
    const { fields } = this.props
    const { personalStatementExistingCoverDescription } = reduceAuthorableFields(fields)
    return (
      <ExitingCoverDescription>{personalStatementExistingCoverDescription}</ExitingCoverDescription>
    )
  }

  renderExistingClientCovers() {
    const { existingClientCovers, fields } = this.props

    const {
      personalStatementExistingCoverPolicyNumber,
      personalStatementExistingCoverSumInsured,
      personalStatementExistingCoverStartDate,
      personalStatementExistingCoverOptions,
    } = reduceAuthorableFields(fields)
    const covers = existingClientCovers && existingClientCovers.existingInsurances

    return covers
      ? covers.map((cover, policyIndex) =>
          cover.isFixed ? (
            <ExitingCover key={`${cover.policyNo}`}>
              <ExitingCoverHeader>
                {personalStatementExistingCoverPolicyNumber} {cover.policyNo}
              </ExitingCoverHeader>
              {cover.benefits &&
                cover.benefits.map((benefit, benefitIdx) => (
                  <ExitingCoverDetails key={`${benefit.benefitCode}-${cover.policyNo}`}>
                    <DetailsLeft>
                      <BenefitTitle>{benefit.benefitName}</BenefitTitle>
                      <DetailsItem>
                        {personalStatementExistingCoverSumInsured}
                        {': '}
                        {benefit.sumInsured}
                      </DetailsItem>
                      <DetailsItem>
                        {personalStatementExistingCoverStartDate}
                        {': '}
                        {getShortStartDateFormat(benefit.startdate)}
                      </DetailsItem>
                      <DetailsItem>
                        {personalStatementExistingCoverOptions}
                        {': '}
                        {this.renderSelectedBenefitOptions(benefit.allowableFeatures)}
                      </DetailsItem>
                    </DetailsLeft>
                    <DetailsRight>
                      <FullWidthToggleGroup
                        labelSize="sm"
                        htmlFor={`option-${cover.externalPolicyNo}-${policyIndex}-${benefit.benefitCode}-${benefitIdx}`}
                        variant="tab"
                        label={fields.lifeInsuranceGender}
                        name={`option-${cover.externalPolicyNo}-${policyIndex}-${benefit.benefitCode}-${benefitIdx}`}
                        labelledby={`option-${cover.externalPolicyNo}-${policyIndex}-${benefit.benefitCode}-${benefitIdx}`}
                        handleChange={event =>
                          this.handleToggle(
                            event,
                            cover.policyInstanceNo,
                            benefit.benefitInstanceNo
                          )
                        }
                        value={
                          benefit.replacingThisCover
                            ? getBenefitReplaceOptions(fields)[1].label
                            : getBenefitReplaceOptions(fields)[0].label
                        }
                        options={getBenefitReplaceOptions(fields)}
                        error=""
                        caption=""
                      />
                    </DetailsRight>
                  </ExitingCoverDetails>
                ))}
            </ExitingCover>
          ) : null
        )
      : null
  }

  renderReplacePolicyModal = () => {
    const { togglePolicyStatusModal } = this.state
    const { fields } = this.props

    return (
      <Fragment>
        <Modal
          isOpen={togglePolicyStatusModal}
          onClose={this.handleModalClose}
          title={fields.personalStatementMlcOnTrackButtonTitle.value}
        >
          <ModalDescription>
            {renderTextField(fields.personalStatementMlcOnTrackModalMessage)}
          </ModalDescription>
          <div>
            <Button type="secondary" onClick={this.handleModalConfirm}>
              {renderTextField(fields.personalStatementEnquiryIdModalButton)}
            </Button>
          </div>
        </Modal>
      </Fragment>
    )
  }

  renderAddPolicy() {
    const { fields, form } = this.props
    const { existingCoverAddPolicy } = fields
    const {
      fields: {
        existingInsurances: { error, value: existingInsurances },
      },
    } = form
    const { error: existingInsurancesError } = error
    const { editingPolicyIndex, addNewPolicy } = this.state
    return (
      <div>
        {existingInsurances !== '' &&
          existingInsurances &&
          existingInsurances.map((policy, index) => {
            if (editingPolicyIndex === index) {
              return this.renderPolicy(policy, index, existingInsurances, existingInsurancesError)
            }
            return this.renderPolicyLayout(policy, index)
          })}
        {addNewPolicy
          ? this.renderPolicy(
              '',
              existingInsurances !== '' ? existingInsurances.length : 0,
              existingInsurances,
              existingInsurancesError
            )
          : ''}
        <AddPolicy quoteEntityAddPolicy={existingCoverAddPolicy} clickHandler={this.addPolicy} />
      </div>
    )
  }

  renderPolicyLayout(currentPolicy, policyIndex) {
    const { fields } = this.props
    const { existingCoverPolicyNumberText } = reduceAuthorableFields(fields)
    return (
      <PolicyLayout>
        <PolicyDetailContainer>
          <InsurerText>{currentPolicy.insurer.value.value}</InsurerText>
          {currentPolicy.policyNo.value && (
            <PolicyText>{`${existingCoverPolicyNumberText}: ${currentPolicy.policyNo.value}`}</PolicyText>
          )}
        </PolicyDetailContainer>
        <ActionItemContainer>
          <ActionButton type="icon" onClick={this.editHandler(policyIndex)}>
            <EditIcon />
          </ActionButton>

          <ActionButton
            type="icon"
            onClick={() => {
              this.removePolicy(policyIndex)
            }}
          >
            <DeleteIcon />
          </ActionButton>
        </ActionItemContainer>
      </PolicyLayout>
    )
  }

  render() {
    const {
      fields,
      form,
      renderErrorMessage,
      showExistingCoverAnyPolicyError,
      existingClient,
      existingClientCovers,
    } = this.props
    const { addNewPolicy, editingPolicyIndex } = this.state

    if (!form) return null
    const {
      fields: {
        consent,
        existingInsurances: { error, value: existingInsurances },
      },
    } = form
    const { error: existingInsurancesError } = error
    const {
      existingCoverAddPolicy,
      existingCoverAnyPolicy,
      personalStatementExistingCoverDisagreeLabel,
      personalStatementExistingCoverAgreeLabel,
      personalStatementExistingCoverProvidePolicyText,
      existingCoverMandatoryError,
    } = fields
    const radioGroupExitingCoverOptions = [
      {
        key: 'no',
        text: renderTextField(personalStatementExistingCoverDisagreeLabel),
        value: 'no',
      },
      {
        key: 'yes',
        text: renderTextField(personalStatementExistingCoverAgreeLabel),
        value: 'yes',
      },
    ]
    const isExistingCovers =
      existingClient &&
      existingClientCovers &&
      existingClientCovers.existingInsurances &&
      existingClientCovers.existingInsurances.find(policy => policy.isFixed)

    if (isExistingCovers) {
      return (
        <Fragment>
          {this.renderDescripiton()}
          {this.renderExistingClientCovers()}
          {this.renderAddPolicy()}
          {this.renderReplacePolicyModal()}
        </Fragment>
      )
    }

    return (
      <Fragment>
        <div>{renderTextField(existingCoverAnyPolicy, true)}</div>
        <RadioGroup
          options={radioGroupExitingCoverOptions}
          handleChange={this.handleExistingCoverChange}
          selectedItem={consent.value.value}
          name="existingCoverOptions"
        />

        {showExistingCoverAnyPolicyError &&
          !consent.value.value &&
          renderErrorMessage(renderTextField(existingCoverMandatoryError))}
        {consent.value.value === 'yes' ? (
          <Fragment>
            <PolicyTitle>
              {renderTextField(personalStatementExistingCoverProvidePolicyText, true)}
            </PolicyTitle>
            <div>
              {existingInsurances !== '' &&
                existingInsurances &&
                existingInsurances.map((policy, index) => {
                  if (editingPolicyIndex === index) {
                    return this.renderPolicy(
                      policy,
                      index,
                      existingInsurances,
                      existingInsurancesError
                    )
                  }
                  return this.renderPolicyLayout(policy, index)
                })}
              {addNewPolicy
                ? this.renderPolicy(
                    '',
                    existingInsurances !== '' ? existingInsurances.length : 0,
                    existingInsurances,
                    existingInsurancesError
                  )
                : ''}
            </div>
            <AddPolicy
              quoteEntityAddPolicy={existingCoverAddPolicy}
              clickHandler={this.addPolicy}
            />
          </Fragment>
        ) : null}
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = state => {
  const { forms, productRules, createQuote, masterList } = state
  const { data } = productRules
  // get product by productId
  const productData = get(getProduct(data, POLICY_PRODUCT_CODE_NON_SUPER), 'benefits', [])
  const benefits =
    productData.length && productData.filter(benefit => benefit.displayOnExistingCover)
  const { quotes, activeIndex } = createQuote
  const {
    data: { features },
  } = masterList
  return {
    form: forms[FORM_ID],
    benefits,
    existingClient: getExistingClientBancsCustomerNo(state),
    existingClientCovers: quotes[activeIndex].existingCovers,
    features,
    quote: quotes[activeIndex],
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExistingCover)
