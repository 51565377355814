import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, fontSize, mediaQueries, fontWeights } = Variables

const styles = {
  lifeInsuredContainer: isCustomer => ({
    width: '100%',
    background: colours.tertiarySix,
    marginTop: space(-8),
    padding: `${space(4, true)}`,
    marginBottom: space(15),
    ...(isCustomer && {
      padding: 0,
      marginTop: 0,
    }),

    '& .hideItem': {
      display: 'none',
    },

    [mediaQueries.md]: {
      padding: `${space(8, true)} ${space(13, true)}`,
      ...(isCustomer && {
        padding: 0,
        marginTop: 0,
      }),
    },
  }),
  lifeInsuredWrapper: {
    [mediaQueries.md]: {
      maxWidth: space(75),
    },
  },
  declarationDescriptions: {
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
  },
  footer: {
    padding: `${space(7, true)} 0`,
  },
  fieldset: withoutFlex => ({
    padding: `0  0 ${space(1, true)}`,
    display: `${withoutFlex ? 'block' : 'flex'}`,
    width: '100%',
    border: 'none',
    position: 'relative',
    margin: 0,
    justifyContent: 'space-between',
  }),
  allowableFeatures: {
    paddingBottom: space(3),
    borderBottom: `1px solid ${colours.tertiaryFive}`,
    marginBottom: space(3),
  },
  sectionXsm: (withoutMargin, transparent) => ({
    width: `calc(30% - ${withoutMargin ? '0px' : space(3, true)})`,
    [transparent && 'input']: {
      color: colours.tertiaryOne,
      fontSize: 'inherit',
    },
  }),
  sectionXl: withoutMargin => ({
    width: `calc(70% - ${withoutMargin ? '0px' : space(3, true)})`,
  }),
  sectionMd: withoutMargin => ({
    width: `calc(50% - ${withoutMargin ? '0px' : space(3, true)})`,
  }),
  fullWidth: {
    flex: '0 0 100%',
    overflow: 'auto',
  },
  sectionLg: {
    width: '60%',
  },
  sectionSm: {
    width: `calc(40% - ${space(3, true)})`,
  },
  buttonContainer: (secondary = false) => ({
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    color: !secondary ? colours.functionalAcceptDark : colours.tertiaryOne,
    position: 'absolute',
    right: 0,
    bottom: 0,
  }),
  button: {
    color: 'inherit',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
  },
  modalButton: {
    marginTop: space(4),
  },
  noResults: {
    padding: space(2),
  },
}

export default styles
