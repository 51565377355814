// @flow
import React from 'react'

// sitecore.
import { RichText as RichTextField } from '@sitecore-jss/sitecore-jss-react'

type RichTextFields = {
  fields: {
    text: {
      value: string,
      editable: string,
    },
  },
}

const RichText = (props: RichTextFields) => {
  const { fields } = props
  return <RichTextField field={fields.text} tag="div" className="rte-content" />
}

export default RichText
