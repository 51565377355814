import { Variables } from '@mlcl-digital/mlcl-design'

import { containerUtils } from '../../../../styles'

const { space, colours } = Variables

export default {
  wrap: {
    ...containerUtils.pageWrapper,
    ...containerUtils.innerPaddingHorizontal,
    backgroundColor: colours.tertiarySix,
    minHeight: space(54, true),
  },
  formWrap: {
    padding: `${space(9, true)} ${space(2, true)}`,
  },
  heading: {
    marginBottom: 0,
  },
  subHeading: {
    marginBottom: space(2, true),
  },
}
