import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { space, colours, fontWeights, mediaQueries } = Variables as VariablesType
const styles = {
  container: () => ({
    minHeight: space(54, true),
    marginBottom: space(24, true),
  }),
  tabWrapper: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    [mediaQueries.md]: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      gap: 40,
    },
  } as const,
  tabs: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    [mediaQueries.md]: {
      background: colours.tertiarySix,
      flexBasis: 'calc(50% - 20px)',
    },
  } as const,
  tileWrapper: {
    padding: `${space(4, true)} ${space(3, true)}`,
    cursor: 'pointer',
  } as const,
  tabContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '+ div > div': {
      justifyContent: 'center',
    },
  } as const,
  tabTitle: {
    fontSize: space(2, true),
    lineHeight: space(3, true),
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
    padding: 0,
    marginBottom: 0,
    [mediaQueries.md]: {
      fontSize: space(3, true),
    },
  } as const,
  tabValue: {
    textAlign: 'center',
    color: colours.tertiaryOne,
    fontSize: space(6, true),
    lineHeight: space(3, true),
    fontWeight: fontWeights.bold,
    padding: space(1),
    boxSizing: 'border-box',
    margin: 'auto 0',
    marginRight: space(1),
    [mediaQueries.md]: {
      color: colours.mlcAquaAccessible,
    },
  } as const,
  tileContent: {
    backgroundColor: colours.functionalDangerDark,
    color: colours.tertiarySix,
    padding: space(2.5, true),
    width: '100%',
  } as const,
  infoIcon: {
    color: colours.tertiarySix,
  } as const,
  toolTip: {
    maxWidth: space(30, true),
  } as const,
}

export default styles
