import moment from 'moment'
import { createSelector } from 'reselect'
import { Store } from '../types/store'

const getCorrespondencyHistory = (state: Store) => state.correspondenceHistory

export const getClientCorrespondencyHistory = createSelector(
  [getCorrespondencyHistory],
  correspondenceHistory => {
    const { isSending, isError, businessData } = correspondenceHistory
    return {
      isSending,
      isError,
      records: ((businessData || {}).documents || []).map(document => ({
        date: moment(document.createdDate).format('DD MMM YYYY'),
        description: document.docSubType,
        externalId: document.externalId,
        isDownloading: false,
      })),
    }
  }
)
