// @flow
import {
  colours,
  space,
  shadows,
  mediaQueries,
  QUOTE_LEFT_SIDEBAR_WIDTH_NUMERIC,
} from '../../../styles'

import browser from '../../../utils/browserUtils'

const isIE11 = browser.ie && browser.ie === 11

const styles = {
  loader: {
    background: colours.offWhite,
    height: '100vh',
    width: `calc(100% - ${space(54, true)})`,
  },

  wrap:
    () =>
    ({ isPanelVisible }) => ({
      display: 'flex',
      marginLeft: `${isPanelVisible ? 0 : `-${QUOTE_LEFT_SIDEBAR_WIDTH_NUMERIC}px`}`,
      transition: 'all 100ms ease-in-out',
      marginBottom: space(24),
    }),
  showHideButton: ({ isPanelVisible }) => ({
    display: 'none',
    position: 'fixed',
    marginTop: space(3, true),
    left: `${isPanelVisible ? `${QUOTE_LEFT_SIDEBAR_WIDTH_NUMERIC}px` : 0}`,
    width: space(5),
    height: space(5),
    background: colours.white,
    border: 0,
    outline: 0,
    boxShadow: shadows.low,
    textAlign: 'center',
    padding: 0,
    transition: 'all 100ms ease-in-out',
    '& svg': {
      color: colours.black,
      height: space(2.5, true),
      transform: `rotate(${isPanelVisible ? 0 : 180}deg)`,
    },
    [mediaQueries.md]: {
      display: isIE11 ? 'none' : 'inline-block',
    },
    '@media only screen and (min-width: 1400px)': {
      display: 'none',
    },
  }),
}

export default styles
