// helpers.
import { required } from '../../../utils/formUtils'

export const FORM_ID = 'changePassword'
export const CURRENT_PASSWORD_FIELD_KEY = 'currentPassword'
export const NEW_PASSWORD_FIELD_KEY = 'newPassword'
export const CONFIRM_NEW_PASSWORD_FIELD_KEY = 'confirmNewPassword'

export const comparePasswordFields = fields => {
  let hasError
  if (
    fields &&
    fields[NEW_PASSWORD_FIELD_KEY] &&
    fields[CONFIRM_NEW_PASSWORD_FIELD_KEY] &&
    fields[NEW_PASSWORD_FIELD_KEY].value &&
    fields[CONFIRM_NEW_PASSWORD_FIELD_KEY].value
  ) {
    hasError = fields[NEW_PASSWORD_FIELD_KEY].value !== fields[CONFIRM_NEW_PASSWORD_FIELD_KEY].value
  } else {
    hasError = false
  }
  return hasError
}

export const currentPasswordSchema = fields => ({
  [CURRENT_PASSWORD_FIELD_KEY]: {
    condition: required,
    errorMsg: fields.currentPasswordRequired,
  },
})

export const newPasswordSchema = fields => ({
  [NEW_PASSWORD_FIELD_KEY]: {
    condition: [
      { condition: required, errorMsg: fields.newPasswordRequired },
      {
        condition: (value, formFields) => comparePasswordFields(formFields),
        errorMsg: fields.passwordNotChanged,
      },
    ],
    errorMsg: fields.passwordNotChanged,
  },
  [CONFIRM_NEW_PASSWORD_FIELD_KEY]: {
    condition: [
      { condition: required, errorMsg: fields.confirmNewPasswordRequired },
      {
        condition: (value, formFields) => comparePasswordFields(formFields),
        errorMsg: fields.passwordNotChanged,
      },
    ],
    errorMsg: fields.passwordNotChanged,
  },
})
