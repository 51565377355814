// @flow
/* eslint-disable react/no-unused-state */
import React, { Fragment, Component } from 'react'
import get from 'lodash/get'
import styled from '@emotion/styled'
import mlclUre from '@mlcl-digital/ure/lib'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// constant
import { NAVIGATION_APPLICATION_OVERVIEW } from '../../../constants/navigation'
import {
  PERSONAL_STATEMENT,
  CUSTOMER_PERSONAL_STATEMENT,
  URE_BY_ADVISER,
} from '../../../constants/application'
import { CUSTOMER_PORTAL } from '../../../constants/site'

// actions
import { actionCreators } from '../../../actions'

// components
import Header from '../../molecules/PageHeader'
import Heading from '../../atoms/Heading'

// icons
import { IconTick16 } from '../../atoms/Icons'

// style
import styles from './ureEngine.styles'
import history from '../../../utils/browserHistory'
import Modal from '../../molecules/Modal'
import { isBrowser } from '../../../utils/browserUtils'
import { getScope } from '../../../utils/cookieUtils'
import { getUREAssignedParty } from '../../../utils/quoteUtils'
import { SCOPE_TYPE_TELE } from '../../../constants/okta'
import {
  CUSTOMER_TELE_UNDERWRITER_UNDERWRITING_PAGE,
  CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE,
} from '../../../constants/personalStatement'

const MainWrapper = styled('div')(styles.wrapper)
const Label = styled(Heading)(({ checked }) => styles.label(checked))
const List = styled('li')(({ checked }) => styles.list(checked))
const ListWrapper = styled('ul')(styles.listWrapper)
const UreSection = styled('section')(styles.ureSection)

type FullUreEngineProps = {
  fields: Object<Object>,
  actions: Object<Object>,
  // Access to url parameters via react router.
  location: {
    search: string,
  },
  ure: Object,
  accessToken: Object,
  createQuote: Object,
  config: Object,
  isUreFrmCP: Boolean,
}

const renderLabel = (label, checked = false, activeClass = false) => {
  const StyledStatus = styled('span')(() => styles.status(checked, activeClass))
  return (
    <Label size={6} checked={activeClass}>
      <StyledStatus>{checked ? <IconTick16 /> : null}</StyledStatus>
      {label}
    </Label>
  )
}
let ignoreFirstClose = false
// eslint-disable-next-line prefer-const
let ureInstance = {}
let quoteTimeout = ''
export class FullUreEngine extends Component<FullUreEngineProps> {
  componentDidMount() {
    ignoreFirstClose = true
    const {
      config: { SITE },
    } = this.props
    if (SITE === CUSTOMER_PORTAL) {
      this.pollForQuoteChanges()
    }
    this.handleEnquiryId()
  }

  componentDidUpdate(prevProps) {
    const {
      ure: { activeEnquiryId: activeEnquiryIdPrev, activeEnquiryIds: activeEnquiryIdsPrev },
      config: { SITE },
      createQuote: prevQuote,
    } = prevProps
    const {
      ure: { activeEnquiryId, activeEnquiryIds },
      createQuote: newQuote,
    } = this.props
    const activeEnquiryIdIndex = activeEnquiryIds.findIndex(
      enquiry => enquiry.enquiryId === activeEnquiryIdPrev
    )
    if (
      prevQuote !== newQuote &&
      getUREAssignedParty(newQuote) === URE_BY_ADVISER &&
      SITE === CUSTOMER_PORTAL
    ) {
      history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
    }

    if (
      activeEnquiryId !== activeEnquiryIdPrev ||
      (activeEnquiryIds[activeEnquiryIdIndex] &&
        activeEnquiryIds[activeEnquiryIdIndex].updatedOn &&
        activeEnquiryIds[activeEnquiryIdIndex].updatedOn !==
          activeEnquiryIdsPrev[activeEnquiryIdIndex].updatedOn)
    )
      this.initiateUreForm()
  }

  componentWillUnmount() {
    if (ureInstance && ureInstance.destroy) {
      ureInstance.destroy()
    }
    clearInterval(quoteTimeout)
  }

  onCloseErrorModal = () => {
    const {
      actions: { closeUreErrorModal },
      isUreFrmCP,
    } = this.props

    if (isUreFrmCP) {
      if (getScope() === SCOPE_TYPE_TELE) {
        history.push(CUSTOMER_TELE_UNDERWRITER_UNDERWRITING_PAGE)
      } else {
        history.push(CUSTOMER_PERSONAL_STATEMENT)
      }
    } else {
      history.push(PERSONAL_STATEMENT)
    }

    closeUreErrorModal()
  }

  pollForQuoteChanges = () => {
    const {
      actions: { checkQuoteActionStatus },
      createQuote: { quoteCollectionId },
    } = this.props
    quoteTimeout = setInterval(() => {
      checkQuoteActionStatus(quoteCollectionId)
    }, 8000)
  }

  onStepCompleted = lastAction => {
    if (!lastAction.error) {
      // TODO: functions for future functionality
    }
  }

  onDestroy = () => {
    // TODO: functions for future functionality
  }

  eventSubscriber = event => {
    /* Since URE plugin track last event, next request after
      any completed request always gets CLOSE_ENQUIRY_REQUEST,
      in this case it always triggers onFormCompleted so to avoid
      multiple onFormCompleted callback trigger this check is here */

    if (
      isBrowser() &&
      event &&
      event.type === 'ENQUIRY_REQUEST' &&
      ignoreFirstClose &&
      window.location.pathname.includes('underwriting/engine')
    ) {
      ignoreFirstClose = false
    }
    return false
  }

  // @FIXME: Need to resolve in the URE package why this callback is
  // triggered for a 'filled form' but not technically completed form
  onFormCompleted = closeAction => {
    const {
      actions: { fullUreOfCompleteOfEnquiry },
      isUreFrmCP,
    } = this.props
    const {
      payload: {
        allAnswers: { ENQUIRY_TYPE },
      },
    } = closeAction.event

    if (
      ignoreFirstClose ||
      ENQUIRY_TYPE.indexOf('Retail') === -1 ||
      (isBrowser() && window.location.pathname.includes('ure-tool'))
    ) {
      return
    }
    clearInterval(quoteTimeout)
    // check ure from customer portal
    fullUreOfCompleteOfEnquiry(closeAction, isUreFrmCP ? 'isUreFrmCP' : undefined)
  }

  backClickHandler = () => {
    const {
      actions: { resetFullUreResultData },
      isUreFrmCP,
    } = this.props
    resetFullUreResultData()
    history.push(isUreFrmCP ? CUSTOMER_PERSONAL_STATEMENT : PERSONAL_STATEMENT)
  }

  handleEnquiryId() {
    const {
      ure,
      ure: { activeEnquiryId },
      actions: {
        changeNavigationType,
        listAllEnquiryIds,
        getPayloadForEnquiryId,
        ureInitalData,
        setActiveEnquiryId,
      },
      createQuote: { activeIndex, quotes },
    } = this.props

    if (ureInstance.destroy) ureInstance.destroy()
    const umeBenefits = get(ure, 'initialData.umeBenefits')
    if (!(umeBenefits && umeBenefits.length)) ureInitalData()
    changeNavigationType(NAVIGATION_APPLICATION_OVERVIEW)
    listAllEnquiryIds()
    if (!activeEnquiryId) {
      const enquiryId = get(quotes, `[${activeIndex}].underwritingDetails.enquiryId`)
      setActiveEnquiryId(enquiryId)
      getPayloadForEnquiryId(enquiryId)
    }
    if (activeEnquiryId) {
      getPayloadForEnquiryId(activeEnquiryId)
    }
  }

  initiateUreForm() {
    const { ure, accessToken, config } = this.props
    const { activeEnquiryId, activeEnquiryIds } = ure
    const API_DOMAIN: string = config.MLCL_EXPERIENCE_API

    if (ureInstance.destroy) ureInstance.destroy()

    const activeEnquiryIdIndex = activeEnquiryIds.findIndex(
      enquiry => enquiry.enquiryId === activeEnquiryId
    )
    const basicConfig = {
      authToken: accessToken,

      /**
       * Uncomment baseEndpoint to use local API instead of mlc live instance
       */
      /**
       * Local API
       */
      // baseEndpoint: 'http://localhost:8080/v1',
      /**
       * Cloudhub API
       */
      baseEndpoint: `${API_DOMAIN}/v1/digital/retailproxy/ure`,
      answers:
        activeEnquiryIdIndex !== -1 ? { ...activeEnquiryIds[activeEnquiryIdIndex].payload } : {},
      enquiryId: activeEnquiryId,
    }

    // main code
    ;(async () => {
      ureInstance = await mlclUre.init(document.getElementById('ure'), {
        ...basicConfig,
        showSubNav: true,
        // TODO: hooks for future functionality
        eventSubscriber: this.eventSubscriber,
        /* onStepCompleted: this.onStepCompleted,
        onDestroy: this.onDestroy, */
        onFormCompleted: this.onFormCompleted,
        sessionTimeout: false,
      })
    })()
  }

  initUre(enquiryId: string) {
    const {
      actions: { getPayloadForEnquiryId, setActiveEnquiryId },
      ure: { activeEnquiryIds, activeEnquiryId },
    } = this.props
    return () => {
      if (enquiryId === activeEnquiryId) return
      if (
        activeEnquiryIds.find(
          enquiry => enquiry.enquiryId === enquiryId && get(enquiry, 'payload.RETAIL')
        )
      ) {
        setActiveEnquiryId(enquiryId)
      } else {
        getPayloadForEnquiryId(enquiryId)
      }
    }
  }

  render() {
    const {
      fields: {
        ureEngineServerErrorModalTitle,
        ureEngineServerErrorModalDescription,
        ureEnginePageTitle,
        ureEnginePageSubTitle,
        ureEnginePageBackLink,
      },
      ure: { activeEnquiryIds, activeEnquiryId, errorModal },
    } = this.props
    return (
      <Fragment>
        <Header
          backClickHandler={this.backClickHandler}
          heading={ureEnginePageTitle}
          subHeading={ureEnginePageSubTitle}
          showBackIcon
          iconText={ureEnginePageBackLink}
        />
        <PageWrap>
          <MainWrapper>
            <ListWrapper>
              {activeEnquiryIds &&
                activeEnquiryIds.length > 1 &&
                activeEnquiryIds.map(enquiry => (
                  <List
                    checked={enquiry.enquiryId === activeEnquiryId}
                    onClick={this.initUre(enquiry.enquiryId)}
                    key={enquiry.enquiryId}
                  >
                    {renderLabel(
                      enquiry.name,
                      enquiry.isEnquired,
                      enquiry.enquiryId === activeEnquiryId
                    )}
                  </List>
                ))}
            </ListWrapper>
            <UreSection id="ure" />
          </MainWrapper>
        </PageWrap>
        <Modal
          onClose={this.onCloseErrorModal}
          showCloseButton
          shouldOverlayClose
          title={ureEngineServerErrorModalTitle.value}
          shouldFocusCloseButton
          enableScroll={false}
          isOpen={errorModal.isOpen}
        >
          <p>{ureEngineServerErrorModalDescription.value}</p>
        </Modal>
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ ure, okta, createQuote, config }: Object) => ({
  ure,
  accessToken: okta.token,
  createQuote,
  config,
})

export default connect(mapStateToProps, mapDispatchToProps)(FullUreEngine)
