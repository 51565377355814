// @flow
import React from 'react'
// Organism
import PremiumIncreaseModalOrganism from '../../../organisms/PremiumIncreaseModal'

type PremiumIncreaseModalProps = {
  fields: Object,
}

const PremiumIncreaseModal = ({ fields }: PremiumIncreaseModalProps) => (
  <PremiumIncreaseModalOrganism fields={fields} />
)

export default PremiumIncreaseModal
