import {
  required,
  optional,
  firstNameLastName,
  numeric,
  date,
  getFieldValueFromForm,
  belowMinimumCondition,
  aboveMaximumCondition,
} from '../../../utils/formUtils'

export const FORM_ID = 'reportingTool'

const getSearchTypeCondition = form => {
  if (
    getFieldValueFromForm('firstName', form) ||
    getFieldValueFromForm('lastName', form) ||
    getFieldValueFromForm('companyName', form) ||
    getFieldValueFromForm('ageFrom', form) ||
    getFieldValueFromForm('ageTo', form) ||
    getFieldValueFromForm('gender', form) ||
    getFieldValueFromForm('state', form)
  ) {
    return required
  }
  return optional
}

const schema =
  () =>
  (form = {}, fields) => ({
    searchType: {
      condition: getSearchTypeCondition(form),
      errorMsg: fields.reportingSearchTypeErrorMessage,
    },
    firstName: {
      condition: [
        {
          condition: form.fields && form.fields.firstName.value ? firstNameLastName : optional,
          errorMsg: fields.reportingFirstNameInvalidError,
        },
      ],
    },
    lastName: {
      condition: [
        {
          condition: form.fields && form.fields.lastName.value ? firstNameLastName : optional,
          errorMsg: fields.reportingLastNameInvalidError,
        },
      ],
    },
    companyName: {},
    ageFrom: {
      condition: [
        {
          condition: numeric,
        },
        {
          condition: form.fields && form.fields.ageTo.value ? required : optional,
          errorMsg: fields.reportingFromToErrorMessage,
        },
        {
          condition:
            form.fields &&
            aboveMaximumCondition(form.fields.ageTo.value ? Number(form.fields.ageTo.value) : 0),
          errorMsg: fields.reportingRangeToErrorMessage,
        },
      ],
    },
    ageTo: {
      condition: [
        {
          condition: numeric,
        },
        {
          condition: form.fields && form.fields.ageFrom.value ? required : optional,
          errorMsg: fields.reportingFromToErrorMessage,
        },
        {
          condition:
            form.fields &&
            belowMinimumCondition(
              form.fields.ageFrom.value ? Number(form.fields.ageFrom.value) : 0
            ),
          errorMsg: fields.reportingRangeFromErrorMessage,
        },
      ],
    },
    gender: {},
    state: {},
    product: {},
    sumInsuredFrom: {
      condition: [
        {
          condition: numeric,
        },
        {
          condition: form.fields && form.fields.sumInsuredTo.value ? required : optional,
          errorMsg: fields.reportingFromToErrorMessage,
        },
        {
          condition:
            form.fields &&
            aboveMaximumCondition(
              form.fields.sumInsuredTo.value ? Number(form.fields.sumInsuredTo.value) : 0
            ),
          errorMsg: fields.reportingRangeFromErrorMessage,
        },
      ],
    },
    sumInsuredTo: {
      condition: [
        {
          condition: numeric,
        },
        {
          condition: form.fields && form.fields.sumInsuredFrom.value ? required : optional,
          errorMsg: fields.reportingFromToErrorMessage,
        },
        {
          condition:
            form.fields &&
            belowMinimumCondition(
              form.fields.sumInsuredFrom.value ? Number(form.fields.sumInsuredFrom.value) : 0
            ),
          errorMsg: fields.reportingRangeToErrorMessage,
        },
      ],
    },
    premiumPaymentFrequency: {},
    premiumFrom: {
      condition: [
        { condition: numeric },
        {
          condition: form.fields && form.fields.premiumTo.value ? required : optional,
          errorMsg: fields.reportingFromToErrorMessage,
        },
        {
          condition:
            form.fields &&
            aboveMaximumCondition(
              form.fields.premiumTo.value ? Number(form.fields.premiumTo.value) : 0
            ),
          errorMsg: fields.reportingRangeFromErrorMessage,
        },
      ],
    },
    premiumTo: {
      condition: [
        {
          condition: numeric,
        },
        {
          condition: form.fields && form.fields.premiumFrom.value ? required : optional,
          errorMsg: fields.reportingFromToErrorMessage,
        },
        {
          condition:
            form.fields &&
            belowMinimumCondition(
              form.fields.premiumFrom.value ? Number(form.fields.premiumFrom.value) : 0
            ),
          errorMsg: fields.reportingRangeToErrorMessage,
        },
      ],
    },
    insuranceAnniversary: {
      condition: [
        {
          condition: form.fields && form.fields.insuranceAnniversary.value ? date : optional,
          errorMsg: fields.reportingDateInvalidError,
        },
      ],
    },
    policyStatus: {},
    insuranceOptions: {},
    adviserCode: {},
  })

export default schema
