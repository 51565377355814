// @flow
// shared action creators.
import * as application from './creators/application'
import * as advisorPreferences from './creators/advisorPreferences'
import * as advisorRegistration from './creators/adviserRegistration'
import * as createQuote from './creators/createQuote'
import * as policies from './creators/policies'
import * as searchPolicy from './creators/searchPolicy'
import * as productRules from './creators/productRules'
import * as quoteTemplate from './creators/quoteTemplate'
import * as sidebar from './creators/sidebar'
import * as client from './creators/client'
import * as quotes from './creators/quotes'
import * as advisor from './creators/advisor'
import * as occupation from './creators/occupation'
import * as preAssessmentActions from './creators/preAssessment'
import * as ure from './creators/ure'
import * as enquiryIdExpiredModalActions from './creators/enquiryIdExpiredModal'
import * as abnLookup from './creators/abnLookup'
import * as captureCreditCardActions from './creators/captureCreditCard'
import * as bsbLookupActions from './creators/bsbLookup'
import * as fileUpload from './creators/fileUpload'
import * as retentionDashboardAction from './creators/retentionDashboardAction'
import * as dataFeedRegistration from './creators/dataFeedRegistration'
import * as nulis from './creators/nulis'
import * as clientReport from './creators/clientReport'
import * as contactMLC from './creators/contactMLC'
import * as coverManagerDocuments from './creators/coverManagerDocuments'
import * as correspondeHistory from './creators/correnspondenceHistory'
import * as taskDashboardAction from './creators/taskDashboard'
import * as knowledgeBaseAction from './creators/knowledgeBase'
import * as fundsLookup from './creators/fundsLookup'
import * as visualiser from './creators/visualiser'
import * as calculateSaveQuote from './creators/calculateSaveQuote'
import * as lifeAssured from './creators/lifeAssured'
import * as thirdParty from './creators/thirdParty'
import * as supportStaff from './creators/supportStaff'

export default {
  ...application,
  ...advisorPreferences,
  ...advisorRegistration,
  ...createQuote,
  ...policies,
  ...searchPolicy,
  ...productRules,
  ...client,
  ...advisor,
  ...quoteTemplate,
  ...sidebar,
  ...occupation,
  ...preAssessmentActions,
  ...ure,
  ...enquiryIdExpiredModalActions,
  ...quotes,
  ...abnLookup,
  ...captureCreditCardActions,
  ...bsbLookupActions,
  ...fileUpload,
  ...retentionDashboardAction,
  ...nulis,
  ...clientReport,
  ...contactMLC,
  ...dataFeedRegistration,
  ...coverManagerDocuments,
  ...correspondeHistory,
  ...taskDashboardAction,
  ...knowledgeBaseAction,
  ...fundsLookup,
  ...visualiser,
  ...calculateSaveQuote,
  ...lifeAssured,
  ...thirdParty,
  ...supportStaff,
}
