// @flow
/* eslint-disable no-param-reassign */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'

// actions
import { actionCreators } from '../../../../../actions'

// component
import Select from '../../../../atoms/Select'
import RadioGroup from '../../../../molecules/RadioGroup'
import Checkbox from '../../../../atoms/Checkbox'
import Input from '../../../../atoms/Input'

// constants
import Schema, { FORM_ID, DEFAULTS, DAYS, PRIMARY, OTHER } from './followUpContactForm.schema'
import { errorCheck } from '../../../../../utils/formUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'

// styles
import styles from './followUpContactForm'

// constants
import { POLICY_RELATIONSHIPS_LIFEASSURED, PREFERRED_YES } from '../../../../../constants/policies'

type UnderWritingProps = {
  actions: Object,
  form: Object,
  isTrackFollowupAgreed: boolean,
  fields: Object,
  createQuote: { quotes: Object, activeIndex: string },
}

const HalfWidthSelect = styled(Select)(styles.select)
const HalfWidthInput = styled(Input)(styles.select)
const IndividualDayWrapper = styled.div(styles.individualDayWrapper)
const ContactWrapper = styled.div(styles.contactWrapper)
const ContactRadioGroup = styled.div(styles.contactRadioGroup)
const ErrorMessage = styled('p')(styles.errorMessage)

const getSelectedValue = (value, fields) => {
  const SCHEMA = Schema(reduceAuthorableFields(fields))
  switch (value) {
    case SCHEMA.preferred.options[0].label: {
      const { callAfterTime, callBeforeTime } = SCHEMA.preferred.options[0]
      return { callAfterTime, callBeforeTime }
    }
    case SCHEMA.preferred.options[1].label: {
      const { callAfterTime, callBeforeTime } = SCHEMA.preferred.options[1]
      return { callAfterTime, callBeforeTime }
    }
    case SCHEMA.preferred.options[2].label: {
      const { callAfterTime, callBeforeTime } = SCHEMA.preferred.options[2]
      return { callAfterTime, callBeforeTime }
    }
    case SCHEMA.preferred.options[3].label: {
      const { callAfterTime, callBeforeTime } = SCHEMA.preferred.options[3]
      return { callAfterTime, callBeforeTime }
    }
    default: {
      return {}
    }
  }
}

function getMemberDetails(policy) {
  return (
    policy &&
    policy.relationships &&
    policy.relationships.find(member =>
      member.role.find(role => role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
    )
  )
}

export class FollowUpContactFormComponent extends React.Component<UnderWritingProps> {
  constructor(props) {
    super(props)
    this.state = {
      selectedPreferred: '',
      selectedDays: {},
      contactNumber: '',
      anyDay: false,
    }
  }

  componentWillMount() {
    const {
      actions,
      form,
      fields,
      createQuote: { quotes, activeIndex },
    } = this.props
    const { policyStructure, fastTrackingFollowupInfo = {} } = quotes[activeIndex]
    const { preferred = [], alternateContact } = fastTrackingFollowupInfo
    const relatedMember = getMemberDetails(policyStructure[0])
    const relatedParty = relatedMember && relatedMember.relatedParty
    const primaryPhoneIndex =
      relatedParty &&
      relatedParty.contactMethods.phones.findIndex(phone => phone.preferred === PREFERRED_YES)
    const contactNumber = get(relatedParty, `contactMethods.phones[${primaryPhoneIndex}].number`)
    const selectedDays = preferred.reduce((selectedDay, elem) => {
      selectedDay[elem.day] = true
      return selectedDay
    }, {})
    const SCHEMA = Schema(reduceAuthorableFields(fields))
    const callAfterTime = get(fastTrackingFollowupInfo, 'preferred[0].callAfterTime', '')
    const callBeforeTime = get(fastTrackingFollowupInfo, 'preferred[0].callBeforeTime', '')
    const selectedPreferred = callAfterTime
      ? SCHEMA.preferred.options.filter(
          elem => elem.callAfterTime === callAfterTime && elem.callBeforeTime === callBeforeTime
        )[0]
      : ''
    this.setState({
      selectedPreferred,
      selectedDays,
      contactNumber,
    })
    const { formInit } = actions
    if (!form)
      formInit(
        FORM_ID,
        SCHEMA,
        DEFAULTS(
          Object.values(selectedDays).includes(true),
          get(selectedPreferred, 'label', ''),
          preferred,
          alternateContact
        )
      )
  }

  setPreferredDays = value => {
    const { fields } = this.props
    const { selectedDays, anyDay } = this.state
    let updatedPrefered = []
    if (anyDay) {
      updatedPrefered = DAYS.reduce((dayArray, dayElem) => {
        dayArray.push({ day: dayElem.value, ...getSelectedValue(value, fields) })
        return dayArray
      }, [])
    } else {
      updatedPrefered = Object.keys(selectedDays).reduce((dayArray, dayElem) => {
        if (selectedDays[dayElem]) {
          dayArray.push({ day: dayElem, ...getSelectedValue(value, fields) })
        }
        return dayArray
      }, [])
    }

    return updatedPrefered
  }

  handleSelectChange = ({ value }) => {
    const { actions, fields } = this.props
    const { formUpdateField } = actions
    const SCHEMA = Schema(reduceAuthorableFields(fields))
    this.setState({ selectedPreferred: value })
    const preferredItems = {
      error: errorCheck(SCHEMA.preferred.condition),
      value: this.setPreferredDays(value.label),
    }
    const selectedTimeSlot = {
      error: errorCheck(SCHEMA.selectedTimeSlot.condition),
      value: value.label,
    }
    formUpdateField(FORM_ID, 'preferred', preferredItems)
    formUpdateField(FORM_ID, 'selectedTimeSlot', selectedTimeSlot)
  }

  handlePhoneNumberSelection = value => {
    const { actions, fields } = this.props
    const { formUpdateField } = actions
    const SCHEMA = Schema(reduceAuthorableFields(fields), value)

    const alternateContactOption = {
      error: errorCheck(value, SCHEMA.alternateContactOptions.condition),
      value,
    }
    formUpdateField(FORM_ID, 'alternateContactOptions', alternateContactOption)
    if (value === PRIMARY) {
      const alternateContact = {
        error: errorCheck('', SCHEMA.alternateContact.condition),
        value: '',
      }
      formUpdateField(FORM_ID, 'alternateContact', alternateContact)
    }
  }

  handleInputChange = ({ value }) => {
    const { actions, fields, form } = this.props
    const { formUpdateField } = actions
    const {
      fields: { alternateContactOptions },
    } = form
    const SCHEMA = Schema(reduceAuthorableFields(fields), alternateContactOptions.value)

    const alternateContact = {
      error: errorCheck(value, SCHEMA.alternateContact.condition),
      value,
    }
    formUpdateField(FORM_ID, 'alternateContact', alternateContact)
  }

  checkboxHandler = ({ name, value }) => {
    const { selectedDays, selectedPreferred, anyDay } = this.state
    if (anyDay) {
      return
    }
    selectedDays[name] = value
    this.setState({ selectedDays })
    const { actions, fields } = this.props
    const { formUpdateField } = actions
    const SCHEMA = Schema(reduceAuthorableFields(fields))
    const isAnyDaySelected = {
      error: errorCheck(SCHEMA.isAnyDaySelected.condition),
      value: Object.values(selectedDays).includes(true),
    }
    formUpdateField(FORM_ID, 'isAnyDaySelected', isAnyDaySelected)

    if (selectedPreferred) {
      const preferredItems = {
        error: errorCheck(SCHEMA.preferred.condition),
        value: this.setPreferredDays(selectedPreferred.value),
      }
      formUpdateField(FORM_ID, 'preferred', preferredItems)
    }
  }

  allSelectCheckboxHandler = () => {
    const { anyDay, selectedPreferred, selectedDays } = this.state
    this.setState({ selectedDays: mapValues(selectedDays, () => false) })
    this.setState(
      {
        anyDay: !anyDay,
      },
      () => {
        const { actions, fields } = this.props
        const { formUpdateField } = actions
        const SCHEMA = Schema(reduceAuthorableFields(fields))
        const isAnyDaySelected = {
          error: errorCheck(SCHEMA.isAnyDaySelected.condition),
          value: !anyDay,
        }
        const preferredItems = {
          error: errorCheck(SCHEMA.preferred.condition),
          value: selectedPreferred.value ? this.setPreferredDays(selectedPreferred.value) : [],
        }
        formUpdateField(FORM_ID, 'preferred', preferredItems)
        formUpdateField(FORM_ID, 'isAnyDaySelected', isAnyDaySelected)
      }
    )
  }

  render() {
    const { isTrackFollowupAgreed, fields, form } = this.props
    if (!form) return null
    const { selectedPreferred, selectedDays, anyDay, contactNumber } = this.state
    const {
      fields: { alternateContact, alternateContactOptions, isAnyDaySelected, selectedTimeSlot },
    } = form
    if (!isTrackFollowupAgreed || isTrackFollowupAgreed === 'no') return null
    const {
      personalStatementFastTrackTimeHeading,
      personalStatementFastTrackContactNumberHeading,
      radioGroupFastTrackingContactNumberOptionOther,
    } = fields
    const reducedFields = reduceAuthorableFields(fields)
    const SCHEMA = Schema(reducedFields)
    const {
      radioGroupFastTrackingContactNumberOptionPrimary,
      personalStatementFastTrackTimeSelectPlaceholder,
      personalStatementFastTrackContactNumberLabel,
    } = reducedFields
    const radioGroupFastTrackingContactNumberOptions = [
      {
        key: PRIMARY,
        text: renderTextField({
          value: `${radioGroupFastTrackingContactNumberOptionPrimary} ${contactNumber || ''}`,
        }),
        value: PRIMARY,
      },
      {
        key: OTHER,
        text: renderTextField(radioGroupFastTrackingContactNumberOptionOther),
        value: OTHER,
      },
    ]
    return (
      <React.Fragment>
        {renderTextField(personalStatementFastTrackTimeHeading, true)}

        <IndividualDayWrapper>
          <Checkbox
            key="AnyDay"
            htmlFor="AnyDay"
            text="Any weekday"
            name="AnyDay"
            disabled={false}
            value="AnyDay"
            direction="left"
            variant="box"
            onChangeHandler={this.allSelectCheckboxHandler}
            checked={anyDay}
          />
          {DAYS.map(day => (
            <Checkbox
              key={day.label}
              htmlFor={day.label}
              text={day.label}
              name={day.label}
              disabled={false}
              value={day.value}
              direction="left"
              variant="box"
              onChangeHandler={this.checkboxHandler}
              checked={selectedDays[day.label]}
            />
          ))}
          {isAnyDaySelected.error.error && (
            <ErrorMessage>{isAnyDaySelected.error.errorMsg}</ErrorMessage>
          )}
          <HalfWidthSelect
            placeholder={personalStatementFastTrackTimeSelectPlaceholder}
            value={selectedPreferred.value}
            name={SCHEMA.preferred.field}
            id="anyDaySelect"
            changeHandler={value => this.handleSelectChange(value)}
            options={SCHEMA.preferred.options}
          />
          {selectedTimeSlot.error.error && (
            <ErrorMessage>{selectedTimeSlot.error.errorMsg}</ErrorMessage>
          )}
        </IndividualDayWrapper>
        <ContactWrapper>
          {renderTextField(personalStatementFastTrackContactNumberHeading, true)}
          <ContactRadioGroup>
            <RadioGroup
              options={radioGroupFastTrackingContactNumberOptions}
              selectedItem={alternateContactOptions.value}
              handleChange={this.handlePhoneNumberSelection}
              name="followUpFastTrackContactNumber"
              error={
                alternateContactOptions.error &&
                alternateContactOptions.error.error &&
                alternateContactOptions.error.errorMsg
              }
            />
          </ContactRadioGroup>
          {alternateContactOptions.value === OTHER ? (
            <HalfWidthInput
              htmlFor="contactNumber"
              name="contactNumber"
              label={personalStatementFastTrackContactNumberLabel}
              changeHandler={this.handleInputChange}
              error={alternateContact.error.error}
              caption={alternateContact.error.error && alternateContact.error.errorMsg}
              value={alternateContact.value}
            />
          ) : (
            ''
          )}
        </ContactWrapper>
      </React.Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ forms, createQuote }) => ({
  form: forms[FORM_ID],
  createQuote,
})

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpContactFormComponent)
