import {
  COVERMANAGERDOCUMENTS_FETCH_INIT,
  COVERMANAGERDOCUMENTS_FETCH_SUCCESS,
  COVERMANAGERDOCUMENTS_FETCH_ERROR,
  COVERMANAGERDOCUMENTS_FETCH_FAILURE,
  COVERMANAGERDOCUMENTS_CLEAR,
} from '../actions/types/coverManagerDocuments'

export const initialFieldState = {
  isSending: false,
  isError: false,
}

const coverManagerDocuments = (
  state = initialFieldState,
  action: { type: string; payload?: object; error: boolean }
) => {
  switch (action.type) {
    case COVERMANAGERDOCUMENTS_FETCH_INIT: {
      return {
        ...state,
        isSending: true,
        isError: false,
      }
    }

    case COVERMANAGERDOCUMENTS_FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isSending: false,
        isError: false,
      }
    }
    case COVERMANAGERDOCUMENTS_FETCH_ERROR:
    case COVERMANAGERDOCUMENTS_FETCH_FAILURE: {
      return {
        ...state,
        isSending: false,
        isError: true,
      }
    }
    case COVERMANAGERDOCUMENTS_CLEAR: {
      return initialFieldState
    }
    default: {
      return state
    }
  }
}

export default coverManagerDocuments
