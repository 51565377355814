import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontSize, pxToRem, fontWeights } = Variables

const styles = {
  modalDescription: {
    color: colours.black,
    marginBottom: space(4, true),
  },
  buttonComponent: ({ type = false }) => ({
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(12),
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    color: type === 'white' ? colours.white : colours.darkGrey,
  }),
  modalButtonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '2.5rem',
  },
}

export default styles
