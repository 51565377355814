import { SitecoreFields } from '../../welcomePage.types'

// @ts-expect-error non TS code
import { required, mobilePhone, email, regexTest } from '../../../../../utils/formUtils'

export const FORGOTTEN_USERNAME_FORM_KEY = 'forgottenUserName'
export const FORM_ID = 'forgottenUserName'

export const forgottenUserNameFormSchema = (fields: SitecoreFields) => ({
  [FORGOTTEN_USERNAME_FORM_KEY]: {
    condition: [
      {
        condition: (value: string) => {
          if (regexTest(email, value)) {
            return false
          }
          return !regexTest(mobilePhone, value)
        },
        errorMsg: fields.forgotUsernameError?.value,
      },
      {
        condition: required,
        errorMsg: fields.forgotUsernameError?.value,
      },
    ],
  },
})
