import React, { useMemo } from 'react'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import { Heading } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { isPartyTypeOrg, getContactDetailsData } from '../../../../utils/contactUtils'

// types
import { propTypes } from '../../../../types/components/PersonalAndContactDetails'

// styles
import styles from '../personalAndContactDetails.styles'

const EditButton = styled(Button)(styles.editButton)
const SectionHeading = styled(Heading)(styles.sectionHeading)
const EmailAddress = styled(LabelValuePair)(styles.emailAddress)

const PersonalDetails = (props: propTypes) => {
  const { fields, data, openForm } = props
  const { relatedParty } = data || {}
  const {
    emailAddress,
    telePhoneNumber,
    residentialAddress,
    preferredMethodOfComms,
    postalAddress,
  } = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      getContactDetailsData(relatedParty) as {
        emailAddress: string
        telePhoneNumber: string
        residentialAddress: string
        preferredMethodOfComms: string
        postalAddress: string
      },
    [relatedParty]
  )
  const isPartyTypeBusiness = isPartyTypeOrg(relatedParty?.partyType)
  return (
    <Container>
      <SectionHeading variant="h2" size="small">
        {renderTextField(fields.ContactDetailsTitle)}
      </SectionHeading>
      <GridContainer>
        <LabelValuePair
          label={renderTextField(isPartyTypeBusiness ? fields.PhoneNumber : fields.MobileNumber)}
          value={`+${telePhoneNumber}`}
        />
        <EmailAddress label={renderTextField(fields.EmailAddress)} value={emailAddress} />
        <LabelValuePair
          label={renderTextField(fields.CommunicationMethod)}
          value={preferredMethodOfComms}
        />
        <LabelValuePair
          label={renderTextField(
            isPartyTypeBusiness ? fields.WorkAddress : fields.ResidentialAddress
          )}
          value={residentialAddress}
        />
        <LabelValuePair
          label={renderTextField(fields.PostalAddress)}
          value={postalAddress || residentialAddress}
        />
      </GridContainer>
      <EditButton onClick={openForm} variant="link">
        {renderTextField(fields.Edit)}
      </EditButton>
    </Container>
  )
}

export default PersonalDetails
