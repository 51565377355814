import React from 'react'
import { Input } from '@mlcl-digital/mlcl-design'
import { FORGOTTEN_USERNAME_FORM_KEY } from '../Schema/login.schema'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

const ForgottonUserNameForm = props => {
  const { fields, handleChange, formFields } = props
  const { forgotUsernameInputLabel, forgotUsernameCaption } = reduceAuthorableFields(fields)
  return (
    <>
      <Input
        type="text"
        htmlFor={FORGOTTEN_USERNAME_FORM_KEY}
        name={FORGOTTEN_USERNAME_FORM_KEY}
        changeHandler={handleChange}
        placeholder={forgotUsernameInputLabel}
        caption={forgotUsernameCaption}
        captionDangerousHTML
        value={
          (formFields[FORGOTTEN_USERNAME_FORM_KEY] &&
            formFields[FORGOTTEN_USERNAME_FORM_KEY].value) ||
          ''
        }
        autoComplete="on"
        required
      />
    </>
  )
}

export default ForgottonUserNameForm
