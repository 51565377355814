// status value should be 'yetToStart' | 'inProgress' | 'completed'

const stepsSchema = [
  {
    status: 'completed',
    iconType: 'IconTick32',
    displayNameId: 'applicationQuoteStepTitle',
    statusTextId: 'applicationStepCompletedText',
    path: '',
  },
  {
    status: 'inProgress',
    iconType: 'IconUser32',
    displayNameId: 'applicationInsuredDetailStepTitle',
    statusTextId: 'applicationStepIncompleteText',
    path: '',
  },
  {
    status: 'inProgress',
    iconType: 'IconEdit32',
    displayNameId: 'applicationPersonalDetailsStepTitle',
    statusTextId: 'applicationStepIncompleteText',
    path: '',
  },
  {
    status: 'inProgress',
    iconType: 'IconUmbrella32',
    displayNameId: 'applicationPolicySetupStepTitle',
    statusTextId: 'applicationStepIncompleteText',
    path: '/application/policy-setup',
  },
]

export default stepsSchema
