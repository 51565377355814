import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, fontSize, space, fontFamily, mediaQueries, fontWeights } = Variables

const styles = {
  container: {
    width: '100%',
    backgroundColor: colours.tertiarySix,
    border: `solid 1px ${colours.tertiaryThree}`,
    [mediaQueries.md]: {
      width: '25%',
    },
  },
  acticityHeader: {
    color: colours.darkestGrey,
    fontSize: fontSize.xs,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamily.sourceSans,
    marginBottom: space(1),
  },
  activityStatus: {
    marginBottom: space(1),
    fontWeight: fontWeights.bold,
    color: colours.lightGrey,
    '> span': {
      color: colours.orange,
    },
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${space(0, true)} ${space(2, true)} ${space(4, true)}`,
  },

  list: ({ heading }) => ({
    listStyleType: 'none',
    padding: heading
      ? `0 ${space(3, true)} ${space(2, true)} ${space(2, true)}`
      : `0 ${space(3, true)} 0`,
    margin: heading ? `${space(3, true)} 0` : '0',
    borderBottom: heading ? `1px solid ${colours.lightGrey}` : 'none',
    background: colours.white,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:last-child': {
      div: {
        '&:after': {
          content: 'none',
          position: 'absolute',
          left: space(3),
          borderLeft: `2px solid ${colours.red}`,
          height: '100%',
          top: space(4),
        },
      },
    },
  }),
  tick: {
    color: colours.tertiarySix,
  },
  iconDotGrey: {
    color: colours.darkGrey,
    opacity: 0.4,
  },
  listIcon: ({ active }) => ({
    backgroundColor: active ? colours.lightGrey : colours.mlcAquaCustomer,
    borderRadius: '100%',
    width: space(3),
    height: space(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    left: space(1),
    right: 'auto',
  }),
  listContent: {
    padding: `0 ${space(3, true)} ${space(3, true)} ${space(3, true)}`,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '19px',
      borderLeft: `2px solid ${colours.lightGrey}`,
      height: '100%',
      top: space(3),
    },
  },
  statusName: {
    color: colours.black,
  },
  statusDecription: { fontSize: fontSize.xxs, opacity: 0.4, color: colours.darkGrey },
}

export default styles
