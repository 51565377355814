// @flow
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from '@mlcl-digital/mlcl-design/lib/base/Tabs'
import Tab from '@mlcl-digital/mlcl-design/lib/base/Tab'
import InlineMessage from '@mlcl-digital/mlcl-design/lib/base/InlineMessage'
import { Button, Heading, Icons, PageWrap } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { createEvent } from '../../../../utils/telemetry'

// utilities
import { renderTextField } from '../../../../utils/sitecoreUtils'
import history from '../../../../utils/browserHistory'
import { scrollToTop } from '../../../../utils/formUtils'

// actions
import { actionCreators } from '../../../../actions'

// components
import { AltsDecreasePolicyCard } from '../../../molecules/AltsDecreasePolicyCard'
import ResetLinkAndModal from './components/ResetLinkAndModal'
import WithLoader from '../../../molecules/WithLoader'
import LinkComponent from '../../../atoms/Link'
import { CalculateQuoteErrorModal } from '../../../molecules/CalculateQuoteErrorModal'
import DecreaseReEntry from './components/DecreaseReEntry'
import ManualQuoteModal from './components/ManualQuoteModal'
import IneligiblePolicies from './components/IneligiblePolicies'

// styles
import styles from './altsDecreaseQuote.styles'
import { colours } from '../../../../styles'

// constants
import {
  DECREASE_COVER_CONFIRMATION_PAGE,
  DECREASE_COVER_GA_TAG,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
  DECREASE_COVER_QUOTE_COMPONENT_NAME,
  EXISTING_PREMIUM_TAB,
  NEW_PREMIUM_TAB,
  SAVE_QUOTE_ACTION_TYPE,
  ALTERATION_TYPES,
} from '../../../../constants/alterations'
import { NAVIGATION_DECREASE, FOOTER_DECREASE } from '../../../../constants/navigation'

// selectors
import {
  getDecreaseCoverPolicyDetails,
  getAlterationTypeForAlterationsRoute,
  getAltsCalculateErrorModalData,
  getAltsProductRules,
  getIsManualQuoteModal,
  getIsValidationError,
  getIsPageActionsDisabled,
} from '../../../../selectors/alterations'
import { getIsAnyPolicyAltered } from '../../../../selectors/createQuote'
import { DASHBOARD_ROUTE } from '../../../../constants/routes'
import {
  getConfig,
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
} from '../../../../selectors/common.selectors'
import { ACTION_TYPES } from '../../../../constants/application'
import { updateQuoteIDToAction, updateQuoteStatus } from '../../../../actions/creators/createQuote'
import { downloadDocument } from '../../../../utils/downloadDocumentUtils'
import { DOC_TYPE_ALTERATION_QUOTE } from '../../../../constants/documentTypes'
import { generateCorrelationID } from '../../../../utils/commonUtils'
import { ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL } from '../../../../constants/modal'
import { buildQuoteAfterSave } from '../../../../utils/extendedQuoteUtils'

const SectionHeading = styled(Heading)(styles.heading)
const Description = styled('p')(styles.description)
const TabsContainer = styled('div')(styles.tabs)
const PageWrapper = styled(PageWrap)(styles.pageWrap)
const ButtonsWrapper = styled('div')(styles.buttonsWrapper)
const DownloadButton = styled(Button)(styles.downloadButton)
const BackLink = styled('div')(styles.backLink)
const CardContainerComponent = styled('div')(styles.cardContainer)
const MCDDiscountInfoWrapper = styled('div')(styles.mcdDiscountInfo)

type AltsDecreaseQuotePropTypes = {
  fields: Object,
  params: Object,
  location: {
    hash: string,
  },
}
const redirectToDashboard = () => history.push(DASHBOARD_ROUTE)
const WHITESPACE = ' '

export const AltsDecreaseQuote = ({
  fields,
  params,
  location: { hash },
}: AltsDecreaseQuotePropTypes) => {
  const dispatch = useDispatch()
  const { quoteCollectionId } = queryString.parse(history.location.search)
  const [isReEntryAPIsInProgress, setIsReEntryAPIsInProgress] = useState(!!quoteCollectionId)
  const timelineState = useSelector(state => state.timelineWithComponents)
  const alterationsState = useSelector(state => state.alterations)
  const quotesData = useSelector(state => getDecreaseCoverPolicyDetails(state, fields))
  const isAnyPolicyAltered = useSelector(getIsAnyPolicyAltered)
  const activeTabValue = hash ? decodeURIComponent(hash.substring(1)) : NEW_PREMIUM_TAB
  const [activeTab, setActiveTab] = useState(activeTabValue)
  const alterationTypeForAlterationsRoute = useSelector(getAlterationTypeForAlterationsRoute)
  const formattedError = useSelector(getAltsCalculateErrorModalData)
  const createQuote = useSelector(getCreateQuote)
  const { isFetchingData, action, activeIndex, quotes, isDownloadQuoteInProgress } = createQuote
  const productRules = useSelector(getAltsProductRules)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const isManualQuoteModal = useSelector(getIsManualQuoteModal)
  const config = useSelector(getConfig)
  const quoteCollectionName = `${firstName}${WHITESPACE}${lastName}`
  const isConfirmationPage = timelineState.activeComponent === DECREASE_COVER_CONFIRMATION_PAGE
  const { quoteId } = quotes[activeIndex]
  const newPremiumRef = useRef(false)
  const isValidationError = useSelector(getIsValidationError)
  const isPageActionsDisabled = useSelector(getIsPageActionsDisabled)

  useEffect(() => {
    if (alterationTypeForAlterationsRoute === ALTERATION_TYPES.DECREASE_RISK) {
      dispatch(actionCreators.changeNavigationType(NAVIGATION_DECREASE))
      dispatch(actionCreators.changeFooterType(FOOTER_DECREASE))
    }
  }, [alterationTypeForAlterationsRoute])

  useEffect(() => {
    scrollToTop(0)
    if (isConfirmationPage) {
      setActiveTab(EXISTING_PREMIUM_TAB)
      const tagEvent = createEvent({
        GA: {
          category: DECREASE_COVER_GA_TAG,
          action: 'Decrease cover confirmation page',
        },
        Splunk: {
          attributes: {
            'workflow.name': `${DECREASE_COVER_GA_TAG} - Decrease cover confirmation page`,
          },
        },
      })
      tagEvent.end()
    } else {
      setActiveTab(NEW_PREMIUM_TAB)
    }
  }, [isConfirmationPage])

  const { IconChevronLeft16 } = Icons

  useEffect(() => {
    if (params.AlterationType !== alterationTypeForAlterationsRoute) {
      redirectToDashboard()
    }
  }, [alterationTypeForAlterationsRoute])

  useEffect(() => {
    const tagEvent = createEvent({
      GA: {
        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
        action: 'Page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Page load`,
        },
      },
    })
    tagEvent.end()
    // Generate quote modal initiation
    dispatch(actionCreators.initModal(ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL))
    return () => {
      // Clear the flag on unmount to avoid accessing the link directly
      dispatch(actionCreators.setAlterationTypeForAlterationsRoute(''))
    }
  }, [])

  // GA Tags when tabs gets changes.
  useEffect(() => {
    if (!isConfirmationPage)
      if (activeTab === NEW_PREMIUM_TAB) {
        if (newPremiumRef.current) {
          const tagEvent = createEvent({
            GA: {
              category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
              action: 'New premium tab',
            },
            Splunk: {
              attributes: {
                'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - New premium tab`,
              },
            },
          })
          tagEvent.end()
        }

        newPremiumRef.current = true
      } else {
        const tagEvent = createEvent({
          GA: {
            category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
            action: 'Existing premium tab',
          },
          Splunk: {
            attributes: {
              'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Existing premium tab`,
            },
          },
        })
        tagEvent.end()
      }
  }, [activeTab])

  const handleClick = type => {
    if (isConfirmationPage) {
      const tagEvent = createEvent({
        GA: {
          category: DECREASE_COVER_GA_TAG,
          action: 'Decrease cover confirmation - continue',
        },
        Splunk: {
          attributes: {
            'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Continue - decrease cover confirmation page`,
          },
        },
      })
      tagEvent.end()
      // update quote id to quote action
      if (quoteId) {
        dispatch(updateQuoteIDToAction(quoteId))
      }
      if (action === ACTION_TYPES.SAVE_QUOTE_COLLECTION) {
        dispatch(
          updateQuoteStatus({
            actionName: ACTION_TYPES.CONVERT_TO_DRAFT_APPLICATION,
          })
        )
      }
    }
    dispatch(
      actionCreators.saveQuote(
        (err, response) => {
          if (!err) {
            switch (type) {
              case SAVE_QUOTE_ACTION_TYPE.CONTINUE_CLICK: {
                dispatch(actionCreators.timelineNextState())
                break
              }
              case SAVE_QUOTE_ACTION_TYPE.DOWNLOAD_QUOTE_CLICK: {
                dispatch(actionCreators.updateDownloadQuoteProgress(true))
                const { quotes: builtQuoteData } = buildQuoteAfterSave(
                  response,
                  createQuote,
                  productRules,
                  null
                )
                downloadDocument(
                  {
                    docType: DOC_TYPE_ALTERATION_QUOTE,
                    quote: builtQuoteData[activeIndex],
                    correlationID: generateCorrelationID(),
                    lifeInsuredFirstName: firstName,
                    lifeInsuredLastName: lastName,
                  },
                  null,
                  null,
                  config,
                  error => {
                    dispatch(actionCreators.updateDownloadQuoteProgress(false))
                    if (error)
                      dispatch(actionCreators.showModal(ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL))
                  }
                )
                break
              }
              default: {
                break
              }
            }
          }
        },
        type,
        quoteCollectionName,
        firstName,
        lastName,
        productRules
      )
    )
  }
  const tabs = [
    {
      id: NEW_PREMIUM_TAB,
      title: fields.NewQuotesHeader,
    },
    {
      id: EXISTING_PREMIUM_TAB,
      title: fields.ExistingQuotesHeader,
    },
  ]

  const handleClose = () => {
    dispatch(actionCreators.setIsAltsCalculateErrorModal(false))
  }

  const onClickBack = () => {
    dispatch(actionCreators.setAlterationTypeSelectedByUser(params.AlterationType))
    dispatch(actionCreators.timelinePrevState())
    setActiveTab(NEW_PREMIUM_TAB)
    const tagEvent = createEvent({
      GA: {
        category: DECREASE_COVER_GA_TAG,
        action: 'Decrease cover confirmation - exit',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Exit - decrease cover confirmation page`,
        },
      },
    })
    tagEvent.end()
  }

  return (
    (timelineState.activeComponent === DECREASE_COVER_QUOTE_COMPONENT_NAME ||
      isConfirmationPage) && (
      <>
        {isReEntryAPIsInProgress && (
          <DecreaseReEntry
            fields={fields}
            setIsReEntryAPIsInProgress={setIsReEntryAPIsInProgress}
            quoteCollectionId={quoteCollectionId}
          />
        )}
        {!isReEntryAPIsInProgress && (
          // TODO: update loading state based on API, adde placeholder for card component.
          <WithLoader
            loaderProps={{
              loaderContent: renderTextField(
                isDownloadQuoteInProgress
                  ? fields.DownloadQuoteLoaderMessage
                  : fields.SaveLoaderMessage
              ),
            }}
            isLoading={isFetchingData || isDownloadQuoteInProgress}
            childrenBackgroundColor={colours.white}
            overlay
          >
            <PageWrapper>
              <BackLink>
                {isConfirmationPage && (
                  <LinkComponent
                    testid="decrease-back-link"
                    variant="tertiary"
                    to="/DecreaseCover/Alterations"
                    onClick={onClickBack}
                  >
                    <IconChevronLeft16 /> {renderTextField(fields.BackCTA)}
                  </LinkComponent>
                )}
              </BackLink>
              <SectionHeading size="large" variant="h2">
                {isConfirmationPage
                  ? renderTextField(fields.policySectionHeaderTextConfirmationPage)
                  : renderTextField(fields.policySectionHeaderText)}
              </SectionHeading>
              <Description>
                {isConfirmationPage
                  ? renderTextField(fields.policySectionSubHeaderTextConfirmationPage)
                  : renderTextField(fields.policySectionSubHeaderText)}
              </Description>
              {!isConfirmationPage && (
                <TabsContainer>
                  <Tabs
                    variant="center-with-background"
                    className={activeTab === NEW_PREMIUM_TAB && 'hasResetButton'}
                  >
                    {tabs.map((tab, index) => (
                      <li key={`tab-${tab.id}`}>
                        <Tab
                          data-testid={`application-listing-tabs-${tab.id}`}
                          id={tab.id}
                          hash={tab.id}
                          dataLocator={tab.id}
                          key={tab.id}
                          index={index}
                          selected={activeTab === tab.id}
                          clickHandler={() => setActiveTab(tab.id)}
                          variant="center-with-background"
                        >
                          {renderTextField(tab.title)}
                        </Tab>
                      </li>
                    ))}
                  </Tabs>
                  {activeTab === NEW_PREMIUM_TAB && (
                    <ResetLinkAndModal isDisabled={!isAnyPolicyAltered} fields={fields} />
                  )}
                </TabsContainer>
              )}
              <CardContainerComponent>
                <AltsDecreasePolicyCard
                  isNewPremium={activeTab === NEW_PREMIUM_TAB}
                  quotesData={quotesData}
                  fields={fields}
                  activeTabValue={activeTabValue}
                  isFetchingCalculateQuote={alterationsState.isFetchingCalculateQuote}
                  isConfirmationPage={isConfirmationPage}
                />
              </CardContainerComponent>
              <MCDDiscountInfoWrapper>
                <InlineMessage
                  iconName={['far', 'fa-circle-info']}
                  size="md"
                  message={renderTextField(fields.MCDInfo)}
                />
              </MCDDiscountInfoWrapper>
              {(activeTab === NEW_PREMIUM_TAB || isConfirmationPage) && (
                <ButtonsWrapper>
                  <div>
                    {!isConfirmationPage && (
                      <Button
                        onClick={() => {
                          handleClick(SAVE_QUOTE_ACTION_TYPE.SAVE_CLICK)
                          const tagEvent = createEvent({
                            GA: {
                              category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                              action: 'Save',
                            },
                            Splunk: {
                              attributes: {
                                'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Save`,
                              },
                            },
                          })
                          tagEvent.end()
                        }}
                        variant="tertiary"
                        disabled={
                          !isAnyPolicyAltered ||
                          alterationsState.isFetchingCalculateQuote ||
                          isValidationError ||
                          isPageActionsDisabled
                        }
                      >
                        {renderTextField(fields.SaveCTA)}
                      </Button>
                    )}
                  </div>
                  <div>
                    {!isConfirmationPage && (
                      <DownloadButton
                        disabled={
                          !isAnyPolicyAltered ||
                          alterationsState.isFetchingCalculateQuote ||
                          isValidationError ||
                          isPageActionsDisabled
                        }
                        onClick={() => {
                          handleClick(SAVE_QUOTE_ACTION_TYPE.DOWNLOAD_QUOTE_CLICK)
                          const tagEvent = createEvent({
                            GA: {
                              category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                              action: 'Decrease cover download in quote screen',
                            },
                            Splunk: {
                              attributes: {
                                'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Download quote`,
                              },
                            },
                          })
                          tagEvent.end()
                        }}
                        variant="secondary"
                      >
                        {renderTextField(fields.DownloadQuoteCTA)}
                      </DownloadButton>
                    )}
                    <Button
                      disabled={
                        !isAnyPolicyAltered ||
                        alterationsState.isFetchingCalculateQuote ||
                        isValidationError ||
                        isPageActionsDisabled
                      }
                      onClick={() => {
                        handleClick(SAVE_QUOTE_ACTION_TYPE.CONTINUE_CLICK)
                        const tagEvent = createEvent({
                          GA: {
                            category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                            action: 'Continue',
                          },
                          Splunk: {
                            attributes: {
                              'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Continue`,
                            },
                          },
                        })
                        tagEvent.end()
                      }}
                    >
                      {renderTextField(fields.ContinueCTA)}
                    </Button>
                  </div>
                </ButtonsWrapper>
              )}
              <IneligiblePolicies fields={fields} />
              <CalculateQuoteErrorModal
                formattedError={formattedError}
                fields={fields}
                isOpen={alterationsState.isAltsCalculateErrorModal && !isManualQuoteModal}
                handleModalClose={handleClose}
                modalCTA={fields.ErrorCTA}
              />
              <ManualQuoteModal fields={fields} />
            </PageWrapper>
          </WithLoader>
        )}
      </>
    )
  )
}

export default withRouter(AltsDecreaseQuote)
