import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, fontWeights, fontSize, typography } = Variables

const styles = {
  topBackground: {
    backgroundColor: colours.darkGreen,
    color: colours.white,
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: space(10, true),
    paddingBottom: space(16, true),
  },
  textAreaTMD: {
    width: '675px',
    height: '125px',
    resize: 'none',
    marginTop: `${space(2, true)}`,
    '&:hover': {
      borderColor: colours.lightGreen,
    },
    '&:focus': {
      outline: '1px',
      borderColor: colours.lightGreen,
    },
  },
  backButton: {
    margin: space(0),
    padding: space(0),
    background: 'transparent',
    border: space(0),
    color: colours.white,
    flex: '0 0 100%',
    textAlign: 'left',
    marginBottom: space(4),
    outline: 'none',

    '& svg': {
      color: colours.white,
    },
  },
  wrapper: {
    marginTop: `${space(-6, true)}`,
  },
  policy: {
    marginTop: `${space(3, true)}`,
  },
  dado: {
    marginTop: `${space(2, true)}`,
  },
  errorMessage: {
    color: colours.red,

    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: '2px',
      fontWeight: fontWeights.bold,
    },
  },
  invalidUser: {
    backgroundColor: colours.white,
    color: colours.red,
    textAlign: 'center',
    paddingTop: space(5, true),
    marginTop: space(1, true),
    minHeight: space(40, true),
  },
  selectFitnessTracker: {
    maxWidth: space(23, true),
  },
  infoContent: {
    maxWidth: space(107, true),
  },
  radioMlcOnTrack: {
    maxWidth: space(107, true),
  },
  fitnessTrackerContent: {
    marginBottom: `${space(3, true)}`,
  },
  loadingSection: {
    padding: space(10),
  },
  continueBtn: {
    margin: `${space(3, true)} 0 ${space(17, true)}`,
  },
  intialContatcTimeWrap: {
    marginTop: space(5, true),
  },
  initialContactDaysWrap: {
    marginTop: space(5, true),
    maxWidth: space(100, true),
  },
  caption: {
    fontSize: fontSize.md,
    marginBottom: space(3),
  },
  select: {
    maxWidth: space(50, true),
  },
  accordionContentContainer: {
    '& h3': {
      ...typography.paragraphs.SMSemiBold,
    },
    '& label': {
      marginBottom: space(2),
    },
  },
  telephoneHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: space(2),
    '& h3': {
      marginBottom: 0,
      marginLeft: space(1),
    },
    '& svg': {
      height: '23px',
      color: colours.mlcAquaAccessible,
    },
  },
}

export default styles
