import { Variables } from '@mlcl-digital/mlcl-design'

const { colours } = Variables

const styles = {
  pageWrapper: ({ variant }) => ({
    background: variant === 'horizontal' ? colours.tertiarySix : colours.tertiaryFive,
  }),
}

export default styles
