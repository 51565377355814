// @flow
import { SITECORE_API } from '../types/sitecoreApi'
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  QUOTE_TEMPLATE_GET_ADVISOR_MANAGED,
  QUOTE_TEMPLATE_GET_POPULAR_PROTECTION,
  QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE,
  QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE,
  QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE,
  QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL,
} from '../types/quoteTemplate'

// constants.
import {
  EXPERIENCE_API_BASE,
  EXPERIENCE_API_VERSION_1,
  SITECORE_API_BASE,
} from '../../constants/apis'
// utils.
import { getBancsCustomerNumber } from '../../utils/cookieUtils'
import { destructAdvisorTemplatePolicyStructure } from '../../utils/quoteUtils'
import { convertCoversToFeatures } from './createQuote'

export const getTemplates = () => dispatch => {
  // getting adviserCode from cookie
  const adviserCode = getBancsCustomerNumber()
  dispatch({
    type: SITECORE_API,
    name: QUOTE_TEMPLATE_GET_ADVISOR_MANAGED,
    verb: 'GET',
    route: `${SITECORE_API_BASE}/advisertemplates?adviserCode=${adviserCode}`,
    callback: (err, dataReceived) => ({
      ...dataReceived,
      data:
        dataReceived && dataReceived.data && dataReceived.data.length
          ? destructAdvisorTemplatePolicyStructure(dataReceived.data)
          : [],
    }),
  })
}

export const getPopularProtection = () => ({
  type: SITECORE_API,
  name: QUOTE_TEMPLATE_GET_POPULAR_PROTECTION,
  verb: 'GET',
  route: `${SITECORE_API_BASE}/mlcltemplates`,
  callback: (err, dataReceived) => {
    const sortedData = {
      data: [],
      error: dataReceived.errors,
    }
    sortedData.data = dataReceived.data.sort(
      (a, b) => a.rank - b.rank || (a.dateUpdated > b.dateUpdated ? -1 : 1)
    )
    return sortedData
  },
})

export const renameAdviserTemplate =
  ({ oldTemplateName, newTemplateName }) =>
  dispatch => {
    // getting adviserCode from cookie
    const adviserCode = getBancsCustomerNumber()
    dispatch({
      type: SITECORE_API,
      name: QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE,
      verb: 'POST',
      route: `${SITECORE_API_BASE}/advisertemplates`,
      data: {
        oldTemplateName,
        newTemplateName,
        adviserCode,
        actionType: 'PUT',
      },
      callback: (err, dataReceived) => ({
        ...dataReceived,
        data: dataReceived.data.length
          ? destructAdvisorTemplatePolicyStructure(dataReceived.data)
          : [],
      }),
    })
  }

export const deleteAdviserTemplate =
  ({ templateName }) =>
  dispatch => {
    // getting adviserCode from cookie
    const adviserCode = getBancsCustomerNumber()
    dispatch({
      type: SITECORE_API,
      name: QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE,
      verb: 'POST',
      route: `${SITECORE_API_BASE}/advisertemplates`,
      data: {
        templateName,
        adviserCode,
        actionType: 'DELETE',
      },
      callback: (err, dataReceived) => ({
        ...dataReceived,
        data: dataReceived.data.length
          ? destructAdvisorTemplatePolicyStructure(dataReceived.data)
          : [],
      }),
    })
  }

export const saveAdviserTemplate = ({ templateName, policyStructure }) => ({
  type: SITECORE_API,
  name: QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE,
  verb: 'POST',
  route: `${SITECORE_API_BASE}/advisertemplates`,
  data: {
    templateName,
    policyStructure,
    actionType: 'POST',
  },
  callback: (err, dataReceived, requestPayload) => ({
    dataReceived,
    requestPayload,
  }),
})

export const getQuoteForManual = quoteId => ({
  type: EXPERIENCE_API,
  name: QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL,
  verb: 'GET',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/${quoteId}`,
  queuedAction: convertCoversToFeatures,
})
