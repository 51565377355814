import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

// components
// @ts-expect-error File not in typescript
import AltsLandingPageV1 from './v1'
import AltsLandingPageV2 from './v2'

// utils
// @ts-expect-error File not in typescript
import { isFeatureEnabled } from '../../../utils/featureToggling'

// selectors
import { getConfig } from '../../../selectors/common.selectors'

// types
import { Props } from '../../../types/components/AltsLandingPage'

// constants
import { ALTERATION_TYPES } from '../../../constants/alterations'

const AltsLandingPage = (props: Props) => {
  const config = useSelector(getConfig)
  const isInflationProofingUplift = useMemo(
    () => isFeatureEnabled('InflationProofingUplift', config.FEATURES),
    []
  )
  const { params } = props
  return isInflationProofingUplift && params?.AlterationType === ALTERATION_TYPES.REJECT_CPI ? (
    <AltsLandingPageV2 {...props} />
  ) : (
    <AltsLandingPageV1 {...props} />
  )
}

export default AltsLandingPage
