// @flow
import { colours, space } from '../../../styles'

const styles = {
  wrapper(fillBg) {
    return {
      position: 'relative',
      marginTop: `${space(-6, true)}`,
      ...(fillBg && { backgroundColor: colours.white, padding: space(4), marginBottom: space(6) }),
    }
  },
}

export default styles
