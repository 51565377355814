// @flow
/* eslint-disable max-len */
import React from 'react'
import styled from '@emotion/styled'

// styles.
import styles from './progressBar.styles'

const Bar = styled('div')(({ active, percentage }) => styles.bar(active, percentage))

type ProgressBarTypes = {
  fields: Object,
  submitHandler: Function,
  actions: Object,
  setBackground: Function,
  setPageTitle: Function,
}

const ProgressBar = ({ displayText, ...otherProps }: ProgressBarTypes) => (
  <Bar {...otherProps}>{displayText}</Bar>
)

export default ProgressBar
