import { Variables } from '@mlcl-digital/mlcl-design'
import browser from '../../../utils/browserUtils'
import { IE11 } from '../../../constants/browser'

const isIE11 = browser.ie && browser.ie === IE11

const { space, fontSize, colours, fontWeights, mediaQueries } = Variables

const cardContainerFlex = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  '& > div': {
    marginBottom: space(3, true),
  },

  [mediaQueries.lg]: {
    '& > div': {
      flexBasis: '49%',
    },
  },
}

const cardContainerGrid = {
  display: 'grid',
  gridRowGap: space(3, true),
  [mediaQueries.lg]: {
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: space(3, true),
  },
}

const styles = {
  table: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1.5fr',
    gridRowGap: space(3, true),
    paddingLeft: space(3),
    paddingRight: space(3),
    paddingBottom: space(3),
    paddingTop: space(3),
  },
  cell: ({ isHeader, bold, danger }) => ({
    '&:nth-of-type(2n)': {
      textAlign: 'right',
    },
    fontSize: isHeader ? fontSize.xxs : 'initial',
    fontWeight: bold ? fontWeights.semiBold : fontWeights.normal,
    ...(danger && {
      color: colours.functionalDangerDark,
    }),
  }),
  cardFooter: ({ noFooterBorder, noFooterReverse }) => ({
    borderTop: noFooterBorder ? 'none' : `solid 1px ${colours.tertiaryThree}`,
    display: 'flex',
    flexDirection: noFooterReverse ? 'row' : 'row-reverse',
    minHeight: space(8.25),
  }),
  cardBase: ({ borderless, flexGrow }) => ({
    background: colours.tertiaryFive,
    ...(borderless && { border: 'none' }),
    ...(flexGrow && { flexGrow: '1' }),
    '& > div:not(:last-of-type)': {
      background: colours.tertiarySix,
    },
  }),
  cardBody: ({ large }) => ({
    padding: 0,
    color: colours.tertiaryOne,
    background: colours.tertiarySix,
    '& > div:not(:last-of-type)': {
      borderBottom: `solid 1px ${colours.tertiaryThree}`,
    },
    [mediaQueries.md]: {
      padding: large ? `${space(9)}px ${space(12)}px ${space(6.25)}px ${space(6.25)}px` : 0,
    },
  }),
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    '*': {
      margin: '0 auto',
      textAlign: 'center',
      fontSize: fontSize.xxs,
    },
    span: {
      fontSize: fontSize.xs,
      fontWeight: fontWeights.semiBold,
    },
  },
  cardDivide: ({ large }) => ({
    display: 'flex',
    '& > div': {
      width: '100%',
      padding: space(3),
      textAlign: 'center',
      flex: large ? 1 : 'initial',
      borderRight: `solid 1px ${colours.tertiaryThree}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
      '&:first-of-type': large
        ? {
            [mediaQueries.md]: {
              flex: 5,
              textAlign: 'left',
            },
          }
        : undefined,
    },
  }),
  cardGrid: ({ large }) => ({
    [mediaQueries.md]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: large ? `${space(9)}px ${space(12)}px ${space(6.25)}px ${space(6.25)}px` : 0,
    },
  }),
  gridItem: {
    [mediaQueries.md]: {
      width: '45%',
    },
    '& > div': {
      [mediaQueries.md]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: space(4.5),
      },
    },
  },
  cardContainer: isIE11 ? cardContainerFlex : cardContainerGrid,
}

export default styles
