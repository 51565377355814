import { colours, space } from '../../../styles'
import ureAppStyles from './ureApp.style'

const styles = {
  topBackground: {
    backgroundColor: colours.darkGreen,
    color: colours.white,
    minHeight: space(40),
    display: 'flex',
    h1: {
      color: colours.white,
      width: '100%',
    },
  },
  pageWrapHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    width: space(180),
    alignItems: 'center',
  },
  pageContent: {
    marginTop: space(-6),
    ...ureAppStyles,
  },
}
export default styles
