/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import HeadingWithIcon from '@mlcl-digital/mlcl-design/lib/base/HeadingWithIcon'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'
import { Chip, Button } from '@mlcl-digital/mlcl-design'
// @ts-ignore
import FileUpload from '../../../../molecules/FileUpload'

// Redux
import { getFileUploadOpen } from '../../../../../selectors/clientPayment'
import { getFileUploadDetailsMeta } from '../../../../../selectors/clientPolicies'

// Types
import { ClientPolicy } from '../../../../../types/ClientPolicies'
import { Document as DocumentEntity } from '../../../../../types/Document'
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'

// Styles
import styles from './beneficiaries.styles'

// Constants
import {
  BENEFICIARY_TYPE_LAPSING_BINDING_CODE,
  BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE,
  BENEFICIARY_TYPE_NON_BINDING_CODE,
} from '../../../../../constants/forms'
import {
  POLICY_RELATIONSHIPS_LIFE_BENEFICIARY,
  POLICY_PARTY_TYPE_INDIVIDUAL,
  POLICY_STATE_INFORCE,
} from '../../../../../constants/policies'

// Utils
import { getClientBeneficiaries } from '../../../../../utils/clientBeneficiaries'
import { createEvent } from '../../../../../utils/telemetry'
// @ts-expect-error
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error
import { actionCreators } from '../../../../../actions'
// @ts-ignore
import { shouldShowBeneficiaries } from '../../../../../utils/policyUtilsTs'

const ChipContainer = styled('div')(styles.chipContainer)
const BeneficiaryContainer = styled(Container)(styles.container)
const AddBeneficiaryButton = styled(Button)(styles.addBeneficiaryButton)

type BeneficiariesProps = {
  policyData: ClientPolicy
  fields: fieldsType
}

const Beneficiaries = ({ policyData, fields }: BeneficiariesProps) => {
  const { bancsPolicyNo } = policyData
  const { relationships, policyAttributes, status, productId } = policyData.policy
  const beneficiaries = relationships.filter(
    o => o.roleCode === POLICY_RELATIONSHIPS_LIFE_BENEFICIARY
  )
  const beneficiaryData = getClientBeneficiaries(beneficiaries, policyAttributes)
  const bindingColorMap = {
    [BENEFICIARY_TYPE_LAPSING_BINDING_CODE]: 'success',
    [BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE]: 'warningLight',
    [BENEFICIARY_TYPE_NON_BINDING_CODE]: 'warningLight',
  } as const

  const displayBeneficiaries = shouldShowBeneficiaries(policyData.policy)

  const fileUploadOpen = useSelector(getFileUploadOpen)
  const fileUploadMetadata = useSelector(state =>
    getFileUploadDetailsMeta(state, { fields, type: 'beneficiary', productId })
  )
  const dispatch = useDispatch()

  const handleFileUploadModal = () => {
    const event = createEvent({
      GA: {
        category: 'Add beneficiary',
        action: 'Add using document upload',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Add beneficiary using document upload',
        },
      },
    })
    event.end()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.toggleFileUploadModal(true))
  }

  const renderFileUpload = () => {
    const {
      FileUploadModalConfirm,
      FileUploadMaxFileCount,
      FileUploadMaxFileSize,
      FileUploadMaxFileSizeError,
      FileUploadWrongMimeType,
      FileUploadUploading,
      FileUploadSuccess,
      FileUploadFailure,
      FileUploadWorkItemFailure,
      FileUploadWorkItemSuccess,
      FileUploadFileRemove,
      FileUploadNoFileError,
      FileUploadWrongFileNameError,
    } = reduceAuthorableFields(fields)

    const analytics = {
      category: 'Adding or editing beneficiary details',
      action: 'Confirm beneficiary form upload',
    }

    const analyticsForDownload = {
      category: 'Customer selects edit beneficiary details Download',
      action: 'Select',
    }

    const {
      modalHeading,
      modalSubHeading,
      documentName,
      documentPath,
      docSubTypeCode,
      workType,
    }: DocumentEntity = fileUploadMetadata

    return (
      <FileUpload
        data-testid="policy-details-upload-modal"
        formDownloadMeta={{
          documentName,
          documentPath,
          docSubTypeCode,
        }}
        modalMeta={{
          modalHeading,
          modalSubHeading,
          modalConfirmButton: FileUploadModalConfirm as string,
        }}
        dropzoneMeta={{
          maxFileNumber: FileUploadMaxFileCount as number,
          maxFileSize: FileUploadMaxFileSize as number,
        }}
        fileUploadMeta={{
          maxFileSizeError: FileUploadMaxFileSizeError as string,
          wrongFileType: FileUploadWrongMimeType as string,
          fileUploading: FileUploadUploading as string,
          fileUploadSuccess: FileUploadSuccess as string,
          fileUploadFailure: FileUploadFailure as string,
          createWorkItemError: FileUploadWorkItemFailure as string,
          createWorkItemSuccess: FileUploadWorkItemSuccess as string,
          fileRemoveButtonLabel: FileUploadFileRemove as string,
          noFileUploadedError: FileUploadNoFileError as string,
          wrongFileNameError: FileUploadWrongFileNameError as string,
        }}
        createWorkItemRequest={{
          workTypeCode: workType,
          bancsPolicyNo,
        }}
        uploadDocumentRequest={{
          docSubTypeCode,
          workType,
        }}
        analyticsForDownload={analyticsForDownload}
        analytics={analytics}
      />
    )
  }

  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'View policy details tab',
        action: 'Beneficiaries',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'View policy details beneficiaries',
        },
      },
    })
    event.end()
  }, [policyData])

  return (
    <>
      {displayBeneficiaries &&
        beneficiaryData.map(
          ({
            beneficiaryName,
            beneficiaryRelationshipType,
            percentangeAllocationValue,
            dateOfBirth,
            address,
            policyPartyType,
            bindingTypeValue,
            bindingType,
          }) => (
            <BeneficiaryContainer key={beneficiaryName}>
              <ChipContainer>
                <HeadingWithIcon
                  iconName={
                    policyPartyType === POLICY_PARTY_TYPE_INDIVIDUAL
                      ? ['far', 'user']
                      : ['far', 'building-columns']
                  }
                  headingContent={beneficiaryName || ''}
                />
                {bindingType && (
                  <Chip variant={bindingColorMap[bindingType]}>{bindingTypeValue}</Chip>
                )}
              </ChipContainer>

              <GridContainer>
                <LabelValuePair
                  label={renderTextField(fields.Relationship)}
                  value={beneficiaryRelationshipType}
                />
                <LabelValuePair
                  label={renderTextField(fields.BenefitPercentage)}
                  value={percentangeAllocationValue}
                />
                {dateOfBirth && (
                  <LabelValuePair label={renderTextField(fields.DateOfBirth)} value={dateOfBirth} />
                )}
                {address && (
                  <LabelValuePair label={renderTextField(fields.Address)} value={address} />
                )}
              </GridContainer>
            </BeneficiaryContainer>
          )
        )}
      {displayBeneficiaries && (
        <Container>
          <AddBeneficiaryButton
            disabled={status !== POLICY_STATE_INFORCE}
            variant="secondaryWithTheme"
            type="button"
            onClick={() => handleFileUploadModal()}
          >
            {renderTextField(fields.AddOrEditBeneficiaryButtonLabel)}
          </AddBeneficiaryButton>
        </Container>
      )}
      {!displayBeneficiaries && (
        <Container>{renderTextField(fields.BeneficiariesNotApplicable)}</Container>
      )}
      {fileUploadOpen && renderFileUpload()}
    </>
  )
}

export default Beneficiaries
