import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { PageWrap } from '@mlcl-digital/mlcl-design'

import { bool, shape, string } from 'prop-types'
import styles from './conciergeFooter.styles'
import CustomerConcierge from '../CustomerConcierge'

const ConciergeFooterWrap = styled('div')(styles.conciergeFooterWrap)
const ConciergeFooterText = styled('h2')(styles.conciergeFooterText)

export const ConciergeFooter = props => {
  const { fields, hasCompletedWelcomeJourney, params } = props
  const { cpCustomerConciergeFooterText } = fields
  return hasCompletedWelcomeJourney ? (
    <ConciergeFooterWrap data-testid="concierge-footer">
      <PageWrap>
        <ConciergeFooterText>{cpCustomerConciergeFooterText}</ConciergeFooterText>
        <CustomerConcierge fields={fields} params={params} />
      </PageWrap>
    </ConciergeFooterWrap>
  ) : null
}

ConciergeFooter.propTypes = {
  fields: shape({
    cpConciergeChangingMyAddress: string,
    cpConciergeDownloadingCertificateCurrency: string,
    cpConciergeUpdatingBeneficiaries: string,
    cpConciergeUpdatingContactDetails: string,
    cpConciergeUpdatingPaymentDetails: string,
  }).isRequired,
  params: shape({
    Identifier: string,
  }).isRequired,
  hasCompletedWelcomeJourney: bool.isRequired,
}

export const mapStateToProps = ({ authentication: { hasCompletedWelcomeJourney } }) => ({
  hasCompletedWelcomeJourney,
})

export default connect(mapStateToProps)(ConciergeFooter)
