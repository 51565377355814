// @flow
// constants.
import { NULIS_EXTRA_FIELDS } from '../types/nulis'

export const showExtraField = flag => ({
  type: NULIS_EXTRA_FIELDS,
  payload: {
    flag,
  },
})
