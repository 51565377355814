import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { space, colours } = Variables as VariablesType

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(3),
  },
  subHeading: {
    padding: `${space(2, true)} 0px`,
    borderBottom: `solid 1px ${colours.tertiaryThree}`,
    maxWidth: space(74),
  },
  buttonContainer: {
    borderTop: `1px solid ${colours.tertiaryFour}`,
    paddingTop: space(8),
    button: {
      marginRight: space(2.5),
    },
  },
}

export default styles
