import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, colours, fontWeights, fontSize, fontFamily, pxToRem } = Variables

const link = {
  fontSize: fontSize.sm,
  color: colours.mlcAquaAccessible,
  textDecoration: 'none',
  border: `1px solid ${colours.mlcAquaAccessible}`,
  fontFamily: fontFamily.sourceSans,
  width: space(20, true),
  display: 'block',
  marginRight: space(3),
  padding: `${space(1, true)} ${space(2, true)}`,
  textAlign: 'center',
  height: space(6),
}

const styles = {
  base: {
    '& label': {
      marginBottom: 0,
    },

    [mediaQueries.md]: {
      display: 'flex',
      paddingRight: space(13),
    },
  },
  header: {
    marginBottom: space(4),
    marginRight: space(1),
    paddingBottom: space(3),
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',

    [mediaQueries.md]: {
      marginBottom: space(5),
      marginRight: space(8),
    },
  },
  sectionHeader: {
    fontWeight: fontWeights.bold,
    fontSize: fontSize.md,
    marginBottom: space(3),
  },
  title: {
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.bold,
    marginBottom: space(1),
  },
  offset: {
    marginTop: -space(7),
    background: colours.tertiarySix,
    paddingLeft: space(2),
    paddingRight: space(2),
    paddingTop: space(3),
    marginBottom: space(5),

    [mediaQueries.md]: {
      paddingLeft: space(13),
      paddingRight: space(13),
      paddingTop: space(7),
    },
  },
  halfWidthInput: {
    [mediaQueries.md]: {
      width: `calc(50% - ${space(1, true)})`,
    },
  },
  inputLabel: {
    '& label': {
      color: colours.tertiaryTwo,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16),
    },
  },
  selectLabel: {
    color: colours.tertiaryTwo,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
  },
  quarterWidthInput: {
    width: `calc(50% - ${space(1, true)})`,
  },
  fieldset: useFlex => ({
    padding: `0  0 ${space(1, true)}`,
    display: `${useFlex ? 'flex' : 'block'}`,
    width: '100%',
    border: 'none',
    position: 'relative',
    margin: 0,
    justifyContent: 'space-between',

    [mediaQueries.md]: {
      display: 'flex',
    },
  }),
  holder: {
    [mediaQueries.md]: {
      width: `calc(50% - ${space(1, true)})`,
    },
  },
  buttonPanel: {
    paddingTop: space(3),
    paddingBottom: space(3),
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0',
  },
  buttonsHolder: {
    display: 'block',

    [mediaQueries.md]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  button: {
    width: space(20, true),
    display: 'block',
    marginRight: space(3),
  },
  icon: {
    color: colours.mlcAquaAccessible,
    marginRight: space(1),
    border: `1px solid ${colours.mlcAquaAccessible}`,
  },
  spinner: {
    minHeight: 0,
    marginTop: space(1),
  },
  form: {
    marginBottom: space(5),
    maxWidth: space(100),
    width: '100%',
  },
  download: icon => ({
    ...link,
    fontWeight: fontWeights.semiBold,
    '&:hover, &:focus': {
      color: colours.tertiarySix,
      backgroundColor: colours.mlcAquaAccessible,
      [icon]: {
        color: colours.tertiarySix,
      },
    },
    '&:active': {
      color: colours.tertiarySix,
      backgroundColor: colours.tertiaryOne,
      [icon]: {
        color: colours.tertiarySix,
      },
    },
  }),
  downloadFullReport: icon => ({
    fontSize: fontSize.sm,
    color: colours.tertiarySix,
    textDecoration: 'none',
    border: `1px solid ${colours.tertiarySix}`,
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.semiBold,
    width: space(44, true),
    display: 'block',
    marginRight: space(3),
    padding: `${space(1, true)} ${space(2, true)}`,
    textAlign: 'center',
    height: space(6),
    '&:hover, &:focus': {
      outline: 'none',
      color: colours.mlcAquaDark,
      [icon]: {
        color: colours.mlcAquaDark,
      },
      background: colours.tertiarySix,
    },
    '&:active': {
      color: colours.tertiaryOne,
      [icon]: {
        color: colours.tertiaryOne,
      },
    },
  }),
  redirectLink: {
    ...link,
    borderRadius: 4,
    marginRight: 0,
    width: space(26, true),
    fontSize: fontSize.xs,
    height: space(5),
  },
  loaderText: {
    color: colours.mediumGrey,
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
    marginBottom: space(2),
  },
  modalDescription: {
    color: colours.tertiaryOne,
    marginBottom: space(4, true),
  },
  downloadCSVButton: {
    marginRight: space(3, true),
  },
}

export default styles
