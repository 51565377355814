// @flow
import { SET_LIFE_ASSURED_NAME } from '../actions/types/lifeAssured'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  firstName: '',
  lastName: '',
}

const lifeAssured = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case SET_LIFE_ASSURED_NAME: {
      const { firstName, lastName } = action.payload
      return {
        ...state,
        firstName,
        lastName,
      }
    }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default lifeAssured
