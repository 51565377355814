/* eslint-disable no-unused-vars */
import { mediaQueries } from '../../../styles'

const styles = {
  container: theme => ({
    display: 'block',
    width: 110,
    [mediaQueries.md]: {
      width: 90,
      height: 58,
    },
  }),
  image: theme => ({
    marginBottom: 0,
    height: '100%',
  }),
  horizontal: theme => ({
    display: 'block',
    [mediaQueries.md]: {
      display: 'none',
    },
  }),
  vertical: theme => ({
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
    },
  }),
}

export default styles
