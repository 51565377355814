// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'

// styles.
import styles from './wrap.styles'

type SidebarWrapProps = {
  // contents inside the wrap.
  children: Node,
  // if the wrap should move.
  move: boolean,
  // function to fire on click of overlay when opened.
  closeHandler: Function,
  // current sidebar width, used to animate this component.
  sidebarWidth: number,
  // Direction animate wrap when sidebar is open.
  leftAligned: boolean,
  // disable closing of sidebar when clicked outside it
  isSidebarClosingAllowed: boolean,
}

const Overlay = styled('div')(styles.overlay)
const Wrap = styled('div')(({ move, sidebarWidth, leftAligned }) =>
  styles.base(move, leftAligned, sidebarWidth)
)
const SidebarWrap = (props: SidebarWrapProps) => {
  const { children, move, closeHandler, sidebarWidth, leftAligned, isSidebarClosingAllowed } = props
  const handleClick = () => {
    if (isSidebarClosingAllowed) {
      closeHandler()
    }
  }
  return (
    <Wrap move={move} sidebarWidth={sidebarWidth} leftAligned={leftAligned}>
      {move && <Overlay onClick={handleClick} />}
      {children}
    </Wrap>
  )
}

export default SidebarWrap
