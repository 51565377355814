import React from 'react'
import PropTypes from 'prop-types'
import { Button, Chip, Tooltip, Icons } from '@mlcl-digital/mlcl-design'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { createEvent } from '../../../utils/telemetry'

import styles from './altsDecreasePolicyCard.styles'

import { renderTextField } from '../../../utils/sitecoreUtils'

// actions
import { actionCreators } from '../../../actions'
import {
  isBenefitOptionRemoved,
  isRemoveEligibleForRemoval,
  getFeatureFromMasterData,
} from '../../../utils/alteration'

// selectors
import { getMasterData } from '../../../selectors/common.selectors'
import { getBenefitsFromRulesForFeature } from '../../../selectors/alterations'

// constants
import { DECREASE_COVER_GA_TAG_QUOTE_SCREEN } from '../../../constants/alterations'
import { isSGBOBenefit } from '../../../utils/extendedQuoteUtils'

const FeatureRow = styled('div')(({ isRemoved, isNewPremium }) =>
  styles.featureRow({ isRemoved, isNewPremium })
)
const StyledCardCell = styled('div')(({ isHeader, isCTA }) => styles.cardCell({ isHeader, isCTA }))
const StyledChip = styled(Chip)(styles.removedFeatureChip)
const ButtonFeature = styled(Button)(styles.featureButton)
const { IconInfo16 } = Icons
const InfoIcon = styled(IconInfo16)(styles.infoIcon)
const TooltipContainer = styled('div')(styles.tooltipContainer)
const InfoMessage = styled('span')(styles.infoMessageSpan)

const AltsDecreaseCoverRowOption = ({
  feature,
  fields,
  isNewPremium,
  isFetchingPremiums,
  bancsPolicyNo,
  benefitName,
  benefitInstanceNo,
  type,
  optimiserParentBenefitReference,
  alteredBenefits,
  isBenefitRemoved,
  policyInstanceNo,
  isRemoveBenefitOptionFeature,
  isConfirmationPage,
  linkedSGBOBenefits,
  isPolicyChangeDisabled,
}) => {
  const { CancelOptionCTA, CancelCTA, RemoveOption, BlockRemovalOption } = fields
  const { featureName, isSubBenefit } = feature
  const masterData = useSelector(getMasterData)
  const benefits = useSelector(getBenefitsFromRulesForFeature)
  const dispatch = useDispatch()
  const featureDetails = getFeatureFromMasterData(feature, masterData)
  const displayedFeatureName = get(featureDetails, 'value', '')
  const isRemoveEligible = isRemoveEligibleForRemoval({
    benefits,
    type,
    benefitInstanceNo,
    featureName,
    isSubBenefit,
  })
  const tooltipMessage = get(featureDetails, 'removeOptionTooltipMessage', '')

  // Checks wheather the benefit option is removed or not from policy structure for new premium
  const isRemoved =
    isBenefitRemoved ||
    (isNewPremium &&
      !!alteredBenefits.length &&
      isBenefitOptionRemoved({
        featureName,
        type,
        benefitInstanceNo,
        alteredBenefits,
      }))

  const cancelBenefitOptionHelper = (bType, bInstanceNo, bName) =>
    dispatch(
      actionCreators.cancelBenefitOption(
        {
          type: bType,
          benefitInstanceNo: bInstanceNo,
          name: bName,
          optimiserParentBenefitReference,
        },
        bancsPolicyNo,
        featureName,
        policyInstanceNo
      )
    )

  const removeBenefitOptionHelper = (bType, bInstanceNo, bName) =>
    dispatch(
      actionCreators.removeBenefitOption(
        {
          type: bType,
          benefitInstanceNo: bInstanceNo,
          name: bName,
          optimiserParentBenefitReference,
        },
        bancsPolicyNo,
        featureName,
        policyInstanceNo
      )
    )
  const toggleRemoving = () => {
    const event = createEvent({
      GA: {
        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
        action: 'Remove benefit option',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Remove benefit option`,
        },
      },
    })
    event.end()
    if (isRemoved) {
      cancelBenefitOptionHelper(type, benefitInstanceNo, benefitName)
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(
          ({ type: cbType, benefitInstanceNo: cbInstanceNo, name: cbInstanceName }) =>
            cancelBenefitOptionHelper(cbType, cbInstanceNo, cbInstanceName)
        )
    } else {
      removeBenefitOptionHelper(type, benefitInstanceNo, benefitName)
      linkedSGBOBenefits.length > 0 &&
        linkedSGBOBenefits.forEach(
          ({ type: cbType, benefitInstanceNo: cbInstanceNo, name: cbInstanceName }) =>
            removeBenefitOptionHelper(cbType, cbInstanceNo, cbInstanceName)
        )
    }

    dispatch(actionCreators.altsCalculateQuote())
  }

  const isCoverASGBOBenefit = isSGBOBenefit(type)

  return (
    <FeatureRow
      key={`option-${displayedFeatureName}`}
      isRemoved={isRemoved}
      isNewPremium={isNewPremium}
    >
      <StyledCardCell>
        {displayedFeatureName}
        {isNewPremium && isRemoved && (
          <StyledChip variant="closed">{renderTextField(RemoveOption)}</StyledChip>
        )}
      </StyledCardCell>
      {isNewPremium && isRemoveBenefitOptionFeature && !isConfirmationPage && (
        <StyledCardCell isCTA>
          {isRemoveEligible ? (
            <ButtonFeature
              variant={isRemoved ? 'secondary' : 'danger'}
              size="xsmall"
              onClick={toggleRemoving}
              disabled={
                isFetchingPremiums ||
                isBenefitRemoved ||
                isCoverASGBOBenefit ||
                isPolicyChangeDisabled
              }
            >
              {isRemoved ? renderTextField(CancelCTA) : renderTextField(CancelOptionCTA)}
            </ButtonFeature>
          ) : (
            <TooltipContainer>
              <div data-tip data-for="removeBlocked">
                <InfoMessage>{renderTextField(BlockRemovalOption)}</InfoMessage>
                <InfoIcon />
              </div>
              {tooltipMessage && (
                <Tooltip
                  id="removeBlocked"
                  place="left"
                  onClick={() => {
                    const event = createEvent({
                      GA: {
                        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                        action: 'Remove benefit option tooltip',
                      },
                      Splunk: {
                        attributes: {
                          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Remove benefit option tooltip`,
                        },
                      },
                    })
                    event.end()
                  }}
                >
                  {tooltipMessage}
                </Tooltip>
              )}
            </TooltipContainer>
          )}
        </StyledCardCell>
      )}
    </FeatureRow>
  )
}

AltsDecreaseCoverRowOption.propTypes = {
  feature: PropTypes.shape({
    featureApplicable: PropTypes.string,
    featureName: PropTypes.string,
    premium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  fields: PropTypes.shape({
    CancelOptionCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
    CancelCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
    RemoveOption: PropTypes.shape({
      value: PropTypes.string,
    }),
    BlockRemovalOption: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  isNewPremium: PropTypes.bool,
  isConfirmationPage: PropTypes.bool,
  isFetchingPremiums: PropTypes.bool,
  benefitName: PropTypes.string,
  benefitInstanceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bancsPolicyNo: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isBenefitRemoved: PropTypes.bool.isRequired,
  policyInstanceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isRemoveBenefitOptionFeature: PropTypes.bool.isRequired,
  optimiserParentBenefitReference: PropTypes.shape({
    parentPolicyReferenceNo: PropTypes.string,
    parentType: PropTypes.string,
    parentBenefitInstanceNo: PropTypes.string,
    benefitNature: 'Rider Optimiser',
  }),
  alteredBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      benefitCode: PropTypes.string,
      benefitInstanceNo: PropTypes.string,
      newSumInsured: PropTypes.number,
    })
  ),
  linkedSGBOBenefits: PropTypes.arrayOf(PropTypes.shape({})),
  isPolicyChangeDisabled: PropTypes.bool.isRequired,
}

AltsDecreaseCoverRowOption.defaultProps = {
  feature: {},
  fields: {
    CancelCTA: { value: 'Cancel' },
    CancelOptionCTA: { value: 'Cancel Option' },
    RemoveOption: { value: 'Removed options' },
    BlockRemovalOption: { value: '' },
  },
  isNewPremium: false,
  isFetchingPremiums: false,
  isConfirmationPage: false,
  benefitName: '',
  optimiserParentBenefitReference: null,
  alteredBenefits: [],
  linkedSGBOBenefits: [],
}

export { AltsDecreaseCoverRowOption }
