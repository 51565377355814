import { space, mediaQueries, setColumns, colours, fontSize } from '../../../../../styles'

const styles = {
  fullWidth: {
    width: '100%',
  },
  fullWidthSelect: {
    width: space(5),
  },
  halfColumn: {
    maxWidth: space(51),

    [mediaQueries.md]: {
      width: `calc(50% - ${space(1, true)})`,
    },
  },
  line: {
    [mediaQueries.md]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, auto)',
      borderStyle: 'solid',
      borderTopWidth: 1,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: 1,
      borderColor: colours.lightestGrey,
      lineHeight: space(0.6),
    },
  },
  lineBox: {
    justifySelf: 'stretch',
  },
  lineBoxHidden: {
    display: 'none',
    [mediaQueries.md]: {
      justifySelf: 'stretch',
      display: 'block',
    },
  },
  activeBox: {
    marginLeft: space(2),
    marginTop: space(2),
    fontSize: space(2),
    justifySelf: 'stretch',
    height: space(3),
    paddingLeft: space(3),
    paddingRight: space(3),
    paddingTop: 2,
    paddingBottom: 2,
    display: 'inline',
  },
  textBox: {
    color: colours.darkestGrey,
    whiteSpace: 'nowrap',
    width: space(17),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [mediaQueries.md]: {
      overflow: 'visible',
      width: 'auto',
    },
  },
  textBoxUserId: {
    color: colours.darkestGrey,
  },
  smallButton: {
    width: 'auto',
    padding: `${space(0, true)} ${space(1, true)}`,
  },
  button: {
    height: 'auto',
    textAlign: 'center',
    width: '100%',
    fontSize: fontSize.xs,
    background: 'none',
    padding: 0,
  },
  offset: {
    marginTop: -space(7),
  },
  base: {
    paddingBottom: space(8),
    paddingLeft: space(2),
    paddingRight: space(2),
    paddingTop: space(3),

    [mediaQueries.md]: {
      ...setColumns(1.25),
      display: 'flex',
      paddingTop: space(7),
      paddingLeft: space(13),
      paddingRight: space(13),
    },
  },
  body: {
    maxWidth: '450px',
    [mediaQueries.lg]: {
      ...setColumns(1.25),
    },
  },
  header: {
    marginBottom: space(3),
  },
  title: {
    marginBottom: space(1),
  },
  section: {
    marginBottom: space(8),
  },
  addDataFeedWrapper: {
    flex: 'none',
    width: '100%',
    margin: `${space(4, true)} 0`,

    '& button': {
      paddingTop: space(1.5),
      paddingBottom: space(1.5),
    },
  },
  modalBody: {
    padding: '0 0 2rem 0',
  },
  modalButton: {
    marginTop: space(4),
  },
  modalCancelButton: {
    color: colours.red,
    marginTop: space(4),
    border: `2px solid ${colours.red}`,
  },
  modalDropDownLabel: {
    fontSize: fontSize.xxs,
  },
  error: {
    fontSize: fontSize.xxs,
    color: colours.red,
    margin: 0,
  },
  termsAndCondition: {
    display: 'flex',
  },
  termsAndConditionLabel: {
    margin: `0 ${space(0.5, true)} 0 0`,
  },
  termsAndConditionLink: {
    fontSize: fontSize.xs,
    color: colours.mediumGreen,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
}

export default styles
