// @flow
import React, { Component } from 'react'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import PolicySetupView from './components/PolicySetupView'
import WithLoader from '../../molecules/WithLoader'

// constants.
import { NAVIGATION_APPLICATION_OVERVIEW } from '../../../constants/navigation'

type PolicySetupContainerProps = {
  // quote to display policies from.
  quote: Object,
  // action
  actions: Object,
  // sitecore fields.
  fields: Object,
  // action creator to set navigation type.
  changeNavigationType: Function,
  // action creator to set default footer.
  resetFooterType: Function,
  isFetchingData: Boolean,
}

class PolicySetupContainer extends Component<PolicySetupContainerProps> {
  constructor(props) {
    super(props)
    const {
      changeNavigationType,
      resetFooterType,
      actions: { updatePolicyName },
    } = this.props
    changeNavigationType(NAVIGATION_APPLICATION_OVERVIEW)
    resetFooterType()
    updatePolicyName()
  }

  render() {
    const { fields, quote, actions, isFetchingData } = this.props
    return (
      <WithLoader isLoading={isFetchingData} overlay loaderProps={{ loaderContent: 'Saving' }}>
        <PolicySetupView fields={fields} quote={quote} actions={actions} />
      </WithLoader>
    )
  }
}

// TODO: currently selecting active quote for demonstration purposes
const selectActiveQuote = (createQuote: Object) => createQuote.quotes[createQuote.activeIndex]

const mapStateToProps = (state: Object) => ({
  quote: selectActiveQuote(state.createQuote),
  isFetchingData: state.createQuote.isFetchingData,
})

const { changeNavigationType, resetFooterType } = actionCreators
export const mapDispatchToProps = dispatch => ({
  changeNavigationType,
  resetFooterType,
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PolicySetupContainer)
