import { colours, shadows, space, mediaQueries, fontWeights, fontSize } from '../../../styles'

const styles = {
  // this will automatically apply if you wrap your component inside '<Card>{your component}</Card>'
  base: {
    marginBottom: space(3),
    padding: space(3),
    borderStyle: 'none',
    width: '100%',
    display: 'block',
    background: colours.white,
    boxShadow: shadows.mid,
  },

  // this can be used with base class to modify default box-shadow and padding styles
  // do not write your custom padding or box-shadow version. Either use 'base' or 'wrapper' or both
  wrapper: {
    padding: `${space(5, true)} ${space(4, true)}`,
    boxShadow: shadows.high,

    [mediaQueries.md]: {
      padding: `${space(8, true)} ${space(5, true)}`,
    },
  },

  // this can be used with base class to modify default box-shadow and padding styles
  // do not write your custom padding or box-shadow version. Either use 'base' or 'wrapper' or both
  title: {
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
  },

  // this will help to align siblings in column in mobile and row in desktop
  containerWithColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    [mediaQueries.md]: {
      flexDirection: 'row',
    },
  },

  /**
   * If your card layout similar to 'card details' then can use below classes
   *
   * ======================== START ==========================
   */
  keyValueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: space(2, true),

    [mediaQueries.md]: {
      flexDirection: 'row',
      marginBottom: 'auto',
    },
  },
  keyValue: {
    marginTop: 0,
    marginBottom: 0,

    [mediaQueries.md]: {
      marginTop: space(1, true),
      marginBottom: space(2, true),
    },
  },
  key: {
    width: '100%',
    opacity: '0.4',

    [mediaQueries.md]: {
      width: '30%',
    },
  },
  value: {
    width: '100%',

    [mediaQueries.md]: {
      width: '65%',
    },
  },
  /**
   * ======================== END ==========================
   */
}

export default styles
