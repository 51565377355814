// @flow
import { toast } from 'react-toastify'
// types.
import { SITECORE_URE_BASE } from '../../constants/apis'
import { SITECORE_API } from '../types/sitecoreApi'
import {
  KNOWLEDGE_BASE_LIST_FETCH,
  KNOWLEDGE_BASE_LOAD_MORE,
  KNOWLEDGE_BASE_FETCH_ARTICLE,
  SET_ACTIVE_ARTICLE_ID,
  SET_ACTIVE_ARTICLE_CATEGOTY_TYPE,
  SET_ACTIVE_ARTICLE_TAG,
  KNOWLEDGE_BASE_ID,
} from '../types/knowledgeBase'

export const getAllArticles =
  (data, callback?: Function = () => {}) =>
  (dispatch: Function, getState: Function) => {
    const {
      config: { MLCL_SITECORE_CMS_KEY },
    } = getState()

    dispatch({
      type: SITECORE_API,
      name: KNOWLEDGE_BASE_LIST_FETCH,
      verb: 'POST',
      route: `${SITECORE_URE_BASE}/knowledgebase/articles`,
      data,
      additionalHeaders: {
        CMSapikey: MLCL_SITECORE_CMS_KEY,
      },
      callback: (err, dataReceived) => {
        if (err) {
          toast('Error in Data Fetching', {
            toastId: KNOWLEDGE_BASE_ID,
            type: toast.TYPE.ERROR,
          })
        }
        callback(err, dataReceived)
        return dataReceived
      },
    })
  }

export const setArticleLoadMore = () => ({
  type: KNOWLEDGE_BASE_LOAD_MORE,
})

export const setFetchArticlesLoading = () => ({
  type: KNOWLEDGE_BASE_FETCH_ARTICLE,
})

export const setActiveArticleId = (activeArticleId: Number) => ({
  type: SET_ACTIVE_ARTICLE_ID,
  payload: {
    activeArticleId,
  },
})

export const setActiveCategoryType = (activeCategoryType: String) => ({
  type: SET_ACTIVE_ARTICLE_CATEGOTY_TYPE,
  payload: {
    activeCategoryType,
  },
})

export const setActiveArticleTag = (activeTag: String) => ({
  type: SET_ACTIVE_ARTICLE_TAG,
  payload: {
    activeTag,
  },
})
