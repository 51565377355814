// @FIXME: I don't think label tags should be used here since accordian
// isn't an actual HTML input, I could be wrong though
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React, { Component, Fragment } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import {
  Chip,
  Tooltip,
  Table,
  Select,
  Accordion,
  ErrorState,
  Label,
  Icons,
} from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'

import { actionCreators } from '../../../../../actions'

// constants
import {
  DEFAULT_PAGE_LIMIT,
  DEFAULT_PAGE_NUMBER,
  APP_FILTER_OPTIONS,
} from '../../../../../constants/forms'
import {
  ALTERATIONS_APPLICATION_SUMMARY,
  DECREASE_ALTS_APPLICATION_SUMMARY,
  DECREASE_ALTS_QUOTE_ROUTE,
} from '../../../../../constants/routes'
import {
  STATUS_QUOTE_APPLICATION,
  STATUS_DRAFT_APPLICATION,
  STATUS_SUBMITTED_APPLICATION,
  STATUS_COMPLETED_APPLICATION,
  UPDATED_DATE,
  ORDER_BY_DESC,
  ORDER_BY_ASC,
  FILTER_CATEGORY_ALL,
  QUOTE_NAME,
  SAVE_DRAFT_APPLICATION,
  REGISTER_MY_LINK,
  WIP_MYLINK,
  COMPLETE_MYLINK,
  TELE,
  COMPLETE_APPLICATION,
  COMPLETE_TELE,
  APPLICATION_STAGE_NTU,
  APPLICATION_STAGE_DECLINED,
  APPLICATION_STAGE_POLICY_ISSUED,
  CONVERT_TO_DRAFT_APPLICATION,
  APPLICATION_TYPES,
  ACTION_TYPES,
} from '../../../../../constants/application'
import { ALTERATION_TYPES, ALTERATION } from '../../../../../constants/alterations'
import { CSV_FILE_EXTENSION } from '../../../../../constants/documentTypes'

// molecules
import SearchBox from '../../../../molecules/SearchBar'
import WithLoader from '../../../../molecules/WithLoader'

// utils.
import history from '../../../../../utils/browserHistory'
import { dualSort, sortDate } from '../../../../../utils/sortingUtils'
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { getBancsCustomerNumber } from '../../../../../utils/cookieUtils'
import {
  getStatusChipVariant,
  getApplicationFilter,
  getAlterationTypeLabel,
  getApplicationTypeLabel,
  getMethodChipVariant,
} from '../../../../../utils/applicationUtils'

// styles.
import styles from '../../applicationSearch.styles'

const { IconMinus16, IconPlus16 } = Icons

const Card = styled('div')(styles.card)
const TablePanel = styled('div')(styles.tablePanel)
const TablePanelDesktop = styled('div')(styles.tablePanelDesktop)
const TablePanelMobile = styled('div')(styles.tablePanelMobile)
const AccordionMobile = styled(Accordion)(styles.accordionMobile)
const FilterOption = styled('button')(styles.filterOption)
const FilterOptionActive = styled('button')(styles.filterOptionActive)
const RightPanel = styled('div')(styles.rightPanel)
const Tag = styled('div')(styles.applicationSummaryLinkedTag)
const AppSelect = styled(Select)(styles.appSelect)
const DownloadDisabled = styled(Label)(styles.disabled)
const DownloadCSV = styled(CSVLink)(() => styles.base)
const ChipContainer = styled('div')(styles.chipContainer)
const LeftPanel = styled('div')(styles.leftPanel)

const renderText = (quoteStatus, fields) => {
  switch (quoteStatus) {
    case STATUS_QUOTE_APPLICATION:
      return `${fields.appPagesStatusDraft}`
    case STATUS_DRAFT_APPLICATION:
      return `${fields.appPagesStatusDraft}`
    case STATUS_SUBMITTED_APPLICATION:
      return `${fields.appPagesStatusSubmitted}`
    case STATUS_COMPLETED_APPLICATION:
      return `${fields.appPagesStatusClosed}`
    default:
      return null
  }
}

const defaultSearchParams = {
  sortBy: UPDATED_DATE,
  orderBy: ORDER_BY_DESC,
  offset: DEFAULT_PAGE_NUMBER,
  limit: DEFAULT_PAGE_LIMIT,
}

const CSV_HEADERS = [
  { label: 'Date last updated', key: 'updatedDate' },
  { label: 'Application ID', key: 'quoteId' },
  { label: 'Life Insured', key: 'quoteName' },
  { label: 'Status', key: 'quoteStatus' },
]

const CSV_HEADERS_ALTS = [
  { label: 'Status', key: 'quoteStatus' },
  { label: 'Date last updated', key: 'updatedDate' },
  { label: 'Application ID', key: 'quoteId' },
  { label: 'Life Insured', key: 'quoteName' },
  { label: 'Alteration type', key: 'alterationType' },
]

type AppsProps = {
  // Sitecore authorable fields.
  fields: Object,
  // An object containing action creator functions.
  actions: {
    fetchQuoteCollection: Function,
    getAllProducts: Function,
    getMasterList: Function,
    getQuoteSearch: Function,
    resetNavigationType: Function,
    resetFooterType: Function,
    ureInitalData: Function,
    initEmptyRows: Function,
    resetAllSavedState: Function,
    setPoliciesReset: Function,
    getAllQuoteRecords: Function,
  },
  search: Array<Object>,
  isProductRules: boolean,
  masterList: Object,
  ure: Object,
  isFetchingData: Boolean,
  advisor: Object,
  isExistingApplications: boolean,
  alterationTypes: Array<Object>,
  applicationTypes: Array<Object>,
}

type AppsState = {
  openAccordion: boolean,
  searchInput: string,
  filterCategory: string,
  sortOrder: {
    sortBy: string,
    orderBy: string,
  },
  allRecords: Array<Object>,
  totalRecords: number,
  quoteCollectionName: string,
  offset: number,
  isDownloadAllQuoteLoading: boolean,
}

const renderLifeInsured = lifeInsured => {
  if (!lifeInsured.includes(',')) {
    return lifeInsured
  }
  const lifeInsuredArray = lifeInsured.split(',').map(LI => LI.trim())
  const [firstLifeInsured, ...restOfLifeInsured] = lifeInsuredArray
  return (
    <>
      {firstLifeInsured}
      &nbsp;&nbsp;
      <ChipContainer>
        <Chip variant="info" data-for={`${lifeInsured}-tooltip`} data-tip>
          +{restOfLifeInsured.length}
        </Chip>
      </ChipContainer>
      <Tooltip id={`${lifeInsured}-tooltip`}>
        {restOfLifeInsured.map(LA => (
          <>
            {LA} <br />
          </>
        ))}
      </Tooltip>
    </>
  )
}

const columnsNewBusiness = fields => [
  {
    Header: fields.appPagesApplicationStatus,
    id: 'quoteStatus',
    accessor: 'quoteStatus',
    Cell: ({ value: quoteStatus }: Object) => (
      <Tag>
        <Chip variant={getStatusChipVariant(quoteStatus)}>{renderText(quoteStatus, fields)}</Chip>
      </Tag>
    ),
    disableSortBy: true,
  },
  {
    Header: fields.appPagesDateLastUpdated,
    id: UPDATED_DATE,
    accessor: 'updatedDate',
    sortType: sortDate(UPDATED_DATE, null, 'DD-MM-YYYY'),
    className: 'left',
    headerClassName: 'left',
    tooltip: fields.SortByDate,
  },
  {
    Header: fields.appPagesApplicationId,
    id: 'quoteId',
    accessor: 'quoteId',
    disableSortBy: true,
  },
  {
    Header: fields.appPagesLifeInsured,
    id: QUOTE_NAME,
    accessor: 'quoteName',
    Cell: ({ value: quoteName }) => renderLifeInsured(quoteName),
    sortType: dualSort(QUOTE_NAME, UPDATED_DATE),
    tooltip: fields.SortByLifeInsured,
  },
]

const columnsExistingBusiness = fields => [
  {
    Header: fields.appPagesApplicationStatus,
    id: 'quoteStatus',
    accessor: 'quoteStatus',
    Cell: ({ value: quoteStatus }: Object) => (
      <Tag>
        <Chip variant={getStatusChipVariant(quoteStatus)}>{renderText(quoteStatus, fields)}</Chip>
      </Tag>
    ),
    disableSortBy: true,
  },
  {
    Header: fields.appPagesDateLastUpdated,
    id: UPDATED_DATE,
    accessor: 'updatedDate',
    sortType: sortDate(UPDATED_DATE, null, 'DD-MM-YYYY'),
    className: 'left',
    headerClassName: 'left',
    tooltip: fields.SortByDate,
  },
  {
    Header: fields.appPagesApplicationId,
    id: 'quoteId',
    accessor: 'quoteId',
    disableSortBy: true,
  },
  {
    Header: fields.appPagesLifeInsured,
    id: QUOTE_NAME,
    accessor: 'quoteName',
    Cell: ({ value: quoteName }) => renderLifeInsured(quoteName),
    sortType: dualSort(QUOTE_NAME, UPDATED_DATE),
    tooltip: fields.SortByLifeInsured,
  },
  {
    Header: fields.alterationTypeHeader,
    id: 'alterationType',
    accessor: 'alterationType',
    disableSortBy: true,
  },
  {
    Header: fields.ApplicationMethodHeader,
    id: 'applicationMethod',
    accessor: 'applicationType',
    Cell: ({ value: applicationType }: Object) => (
      <Tag>
        <Chip variant={getMethodChipVariant(applicationType)}>
          {applicationType === APPLICATION_TYPES.DigiAlt
            ? fields.ApplicationMethodDigital
            : fields.ApplicationMethodPaper}
        </Chip>
      </Tag>
    ),
    disableSortBy: true,
  },
]

const formatQuoteStatus = (applicationStage, action, isNonStandard) => {
  if (
    [
      COMPLETE_MYLINK,
      COMPLETE_TELE,
      COMPLETE_APPLICATION,
      ACTION_TYPES.SAVE_DIGITAL_ALTERATION,
    ].indexOf(action) > -1
  ) {
    if (!isNonStandard) {
      if (
        [
          APPLICATION_STAGE_NTU,
          APPLICATION_STAGE_DECLINED,
          APPLICATION_STAGE_POLICY_ISSUED,
        ].indexOf(applicationStage) > -1
      )
        return STATUS_COMPLETED_APPLICATION
      return STATUS_SUBMITTED_APPLICATION
    }
    return STATUS_DRAFT_APPLICATION
  }
  return STATUS_DRAFT_APPLICATION
}

const formatResults = (
  data,
  APPLICATION_TYPE,
  isExistingApplications,
  alterationTypes,
  applicationTypes
) =>
  data.map(
    ({
      quoteCollectionId,
      quoteCollectionName,
      applicationStage = '',
      updatedDate,
      action,
      applicationType,
      isNonStandard,
      alterationType,
      manualQuoted,
    }) => ({
      quoteId: quoteCollectionId,
      quoteName: quoteCollectionName,
      quoteStatus: formatQuoteStatus(applicationStage, action, isNonStandard || false),
      updatedDate: updatedDate ? moment(updatedDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : '',
      manualQuote: manualQuoted,
      applicationType,
      action,
      ...(isExistingApplications && {
        alterationType:
          applicationType === APPLICATION_TYPES.DigiAlt
            ? getAlterationTypeLabel(alterationType, alterationTypes)
            : getApplicationTypeLabel(applicationType, applicationTypes),
        alterationTypeCode: alterationType,
      }),
    })
  )

const CSV_ASYNC_ON_CLICK = true

export class ApplicationListingComponent extends Component<AppsProps, AppsState> {
  constructor(props: Object) {
    super(props)
    this.state = {
      openAccordion: true,
      searchInput: '',
      filterCategory: FILTER_CATEGORY_ALL,
      sortOrder: {
        sortBy: UPDATED_DATE,
        orderBy: ORDER_BY_DESC,
      },
      allRecords: [],
      totalRecords: 0,
      quoteCollectionName: '',
      offset: 1,
    }
    const tagEvent = createEvent({
      GA: {
        category: 'List of applications',
        action: 'View applications list',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of applications - View applications list',
        },
      },
    })
    tagEvent.end()
    this.csvLinkRef = React.createRef()
  }

  componentWillMount() {
    const {
      actions: {
        resetNavigationType,
        resetFooterType,
        initEmptyRows,
        resetAllSavedState,
        setPoliciesReset,
      },
      advisor,
    } = this.props
    const bancsAdviserCustomerNo = getBancsCustomerNumber()
    const advisorDetails = get(advisor, 'advisorDetails', [])
    const adviserNumber = get(advisorDetails[0], 'customerDetails.customerNo', '')
    resetNavigationType()
    resetFooterType()
    initEmptyRows()
    setPoliciesReset()
    resetAllSavedState(adviserNumber, bancsAdviserCustomerNo)
  }

  componentDidUpdate(prevProps: Object) {
    const {
      isExistingApplications,
      actions: { getQuoteSearch },
    } = this.props
    const {
      searchInput,
      filterCategory,
      sortOrder: { sortBy, orderBy },
      offset,
    } = this.state
    // when tab changed
    if (prevProps.isExistingApplications !== isExistingApplications) {
      const searchParams = {
        ...defaultSearchParams,
        quoteCollectionName: searchInput,
        filterParam: getApplicationFilter(isExistingApplications, filterCategory),
        sortBy,
        orderBy,
      }
      // If user is already on first page, onFetchData will not be
      // called as no change in page for table
      if (offset === 1) {
        getQuoteSearch(searchParams, (err, quotes) => {
          // initialising total records for new/alteration business,
          // will be used to download all the records
          if (!err) {
            this.setState({ totalRecords: quotes.totalRecords, allRecords: [] })
          }
        })
      } else {
        // Resetting page to first page,
        // resetting all records for api call for download
        this.setState({
          offset: 1,
          allRecords: [],
        })
      }
    }
  }

  changeSearchHandler = (value: string): void => {
    this.setState({ searchInput: value })
  }

  handleApplicationSearch = () => {
    const {
      searchInput,
      filterCategory,
      sortOrder: { sortBy, orderBy },
      offset,
    } = this.state
    const { isExistingApplications } = this.props
    const {
      actions: { getQuoteSearch },
    } = this.props
    const searchParams = {
      ...defaultSearchParams,
      quoteCollectionName: searchInput,
      filterParam: getApplicationFilter(isExistingApplications, filterCategory),
      sortBy,
      orderBy,
    }
    const tagEvent = createEvent({
      GA: {
        category: 'List of applications',
        action: isExistingApplications ? 'Search for Existing Business' : 'Search for New Business',
      },
      Splunk: {
        attributes: {
          'workflow.name': `List of applications - ${
            isExistingApplications ? 'Search for Existing Business' : 'Search for New Business'
          }`,
        },
      },
    })
    tagEvent.end()
    // Resetting page to first page
    this.setState({
      offset: 1,
      quoteCollectionName: searchInput,
    })
    // If user is already on first page, onFetchData will not be
    // called as no change in page
    if (offset === 1) {
      getQuoteSearch(searchParams)
    }
  }

  clickSearchHandler = () => {
    const {
      actions: { initEmptyRows },
    } = this.props
    initEmptyRows()
    return this.handleApplicationSearch()
  }

  getQuoteSearchCallback = (requestParam: Object) => {
    // Get full record to download - when you are in first page
    const { isExistingApplications } = this.props
    const { totalRecords } = this.state
    if (totalRecords > 0) {
      const downloadRequestParam = {
        ...requestParam,
        offset: 1,
        limit: totalRecords,
        filterParam: getApplicationFilter(isExistingApplications),
      }
      const {
        actions: { getAllQuoteRecords },
      } = this.props
      getAllQuoteRecords(downloadRequestParam, (err2, allQuotes) => {
        if (err2) {
          this.setState({ allRecords: [], isDownloadAllQuoteLoading: false })
        } else {
          const { quotes } = allQuotes
          this.setState({ allRecords: [...quotes], isDownloadAllQuoteLoading: false })
          this.csvLinkRef.current.link.click()
        }
      })
    } else {
      this.setState({ allRecords: [], isDownloadAllQuoteLoading: false })
    }
  }

  onFetchData = (state: Object) => {
    const {
      actions: { getQuoteSearch },
      isExistingApplications,
    } = this.props
    const { quoteCollectionName, filterCategory, totalRecords } = this.state
    let requestParam

    if (get(state, 'sorted.length', 0) === 0) {
      requestParam = {
        ...defaultSearchParams,
        offset: state.pageIndex + 1,
        filterParam: getApplicationFilter(isExistingApplications, filterCategory),
      }
    } else {
      requestParam = {
        sortBy: state.sorted[0].id === QUOTE_NAME ? 'quoteCollectionName' : UPDATED_DATE,
        orderBy: state.sorted[0].desc ? ORDER_BY_DESC : ORDER_BY_ASC,
        offset: state.pageIndex + 1,
        limit: state.defaultPageSize,
        filterParam: getApplicationFilter(isExistingApplications, filterCategory),
      }
    }

    if (quoteCollectionName !== '') {
      requestParam.quoteCollectionName = quoteCollectionName
    }

    getQuoteSearch(requestParam, (err, quotes) => {
      // initialising total records only first time(without any filters),
      // will be used to download all the records
      if (!err && !totalRecords) {
        this.setState({ totalRecords: quotes.totalRecords })
      }
    })

    this.setState({
      sortOrder: {
        sortBy: get(state, 'sorted[0].id') === QUOTE_NAME ? 'quoteCollectionName' : UPDATED_DATE,
        orderBy:
          (get(state, 'sorted[0].id') !== QUOTE_NAME && ORDER_BY_DESC) ||
          (get(state, 'sorted[0].desc') ? ORDER_BY_DESC : ORDER_BY_ASC),
      },
      offset: state.pageIndex + 1,
    })
  }

  rowClickHandler = rowInfo => {
    if (!rowInfo) {
      return
    }
    const {
      original: {
        quoteStatus,
        quoteId,
        action,
        alterationTypeCode,
        alterationType,
        manualQuote,
        applicationType,
      },
    } = rowInfo

    const {
      actions: {
        fetchQuoteCollection,
        getMasterList,
        getAllProducts,
        ureInitalData,
        resetExistingClient,
        setAlterationTypeForAlterationsRoute,
        resetAlterations,
        resetCreateQuote,
        setActiveEnquiryId,
        resetOccupationInStore,
      },
      isProductRules,
      masterList,
      isExistingApplications,
      ure: { initialData },
    } = this.props

    const tagEvent = createEvent({
      GA: {
        category: 'List of applications',
        action: 'Select application',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of applications - Select application',
        },
      },
    })
    tagEvent.end()

    // Clear existingClient store
    resetExistingClient()
    // Clear previously selected occupation
    resetOccupationInStore()
    fetchQuoteCollection(quoteId, (err, dataReceived) => {
      if (!err) {
        if (
          ((!isExistingApplications || manualQuote) && alterationType !== ALTERATION) ||
          applicationType === APPLICATION_TYPES.ALT
        ) {
          // init ure data
          if (isEmpty(initialData)) ureInitalData()
          // get product data
          if (!isProductRules) {
            getAllProducts()
          }
          // get masterList data
          if (isEmpty(masterList.data)) getMasterList()
          // getting enquiryId from response and setting it to ActiveEnquiryID to Fixing RET-13552
          if (dataReceived && dataReceived.quotes) {
            const { quotes, quoteIDToAction } = dataReceived
            const activeQuote = quotes.find(quote => quote.quoteId === quoteIDToAction)
            const enquiryId = get(activeQuote, 'underwritingDetails.enquiryId', false)
            if (enquiryId) {
              setActiveEnquiryId(enquiryId)
            }
          }
          if (
            quoteStatus === STATUS_SUBMITTED_APPLICATION ||
            quoteStatus === STATUS_COMPLETED_APPLICATION ||
            (quoteStatus === STATUS_DRAFT_APPLICATION &&
              (action === REGISTER_MY_LINK ||
                action === WIP_MYLINK ||
                action === COMPLETE_MYLINK ||
                action === TELE ||
                action === COMPLETE_APPLICATION ||
                action === COMPLETE_TELE))
          ) {
            history.push(`/application/details?quoteCollectionId=${quoteId}`)
          } else if (
            quoteStatus === STATUS_DRAFT_APPLICATION &&
            (action === SAVE_DRAFT_APPLICATION || action === CONVERT_TO_DRAFT_APPLICATION)
          ) {
            history.push(`/application?quoteCollectionId=${quoteId}&fromApplicationSearch=true`)
          }
        } else if (alterationTypeCode === ALTERATION_TYPES.REJECT_CPI) {
          history.push(ALTERATIONS_APPLICATION_SUMMARY)
        } else if (alterationTypeCode === ALTERATION_TYPES.DECREASE_RISK) {
          if (
            quoteStatus === STATUS_SUBMITTED_APPLICATION ||
            quoteStatus === STATUS_COMPLETED_APPLICATION
          ) {
            history.push(DECREASE_ALTS_APPLICATION_SUMMARY)
          } else {
            resetAlterations()
            // reset create quote
            resetCreateQuote()
            setAlterationTypeForAlterationsRoute(ALTERATION_TYPES.DECREASE_RISK)
            history.push(`${DECREASE_ALTS_QUOTE_ROUTE}?quoteCollectionId=${quoteId}`)
          }
        }
      }
    })
  }

  handleQuoteCategoryChange = e => {
    const {
      isExistingApplications,
      actions: { getQuoteSearch },
    } = this.props
    const {
      quoteCollectionName,
      sortOrder: { sortBy, orderBy },
      offset,
    } = this.state
    const searchParams = {
      ...defaultSearchParams,
      quoteCollectionName,
      sortBy,
      orderBy,
      filterParam: getApplicationFilter(isExistingApplications, e.value.value),
    }
    // resetting page to first page after apply filter
    this.setState({ filterCategory: e.value.value, offset: 1 })
    // If user is already on first page, onFetchData will not be
    // called as no change in page
    if (offset === 1) {
      getQuoteSearch(searchParams)
    }
  }

  handleAccordionToggle = isOpen => {
    this.setState({ openAccordion: isOpen })
  }

  handleFilter = () => event => {
    const {
      isExistingApplications,
      actions: { getQuoteSearch },
    } = this.props
    const {
      quoteCollectionName,
      sortOrder: { sortBy, orderBy },
    } = this.state
    const searchParams = {
      ...defaultSearchParams,
      quoteCollectionName,
      sortBy,
      orderBy,
      filterParam: getApplicationFilter(isExistingApplications, event.target.dataset.value),
    }
    // resetting page to first page after apply filter
    this.setState({ filterCategory: event.target.dataset.value, offset: 1 })
    getQuoteSearch(searchParams)
  }

  renderOptions = filterCategory =>
    APP_FILTER_OPTIONS.map(options =>
      options.value === filterCategory ? (
        <FilterOptionActive
          type="button"
          onClick={this.handleFilter(options.value)}
          data-value={options.value}
          key={options.value}
        >
          {options.label}
        </FilterOptionActive>
      ) : (
        <FilterOption
          type="button"
          onClick={this.handleFilter(options.value)}
          data-value={options.value}
          key={options.value}
        >
          {options.label}
        </FilterOption>
      )
    )

  handleDownloadAllQuotes = (allRecords: Array, done: Function) => {
    const tagEvent = createEvent({
      GA: {
        category: 'List of applications',
        action: 'Download client application list in CSV',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of applications - Download client application list in CSV',
        },
      },
    })
    tagEvent.end()
    if (allRecords.length) {
      done()
    } else {
      done(false)
      this.setState({ isDownloadAllQuoteLoading: true })
      this.getQuoteSearchCallback(defaultSearchParams)
    }
  }

  onPageChange = () => {
    const tagEvent = createEvent({
      GA: {
        category: 'List of applications',
        action: 'Paginate',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of applications - Paginate',
        },
      },
    })
    tagEvent.end()
  }

  onSortedChange = () => {
    const tagEvent = createEvent({
      GA: {
        category: 'List of applications',
        action: 'Sort',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of applications - Sort',
        },
      },
    })
    tagEvent.end()
  }

  render() {
    const {
      search,
      fields,
      masterList: { data },
      isFetchingData,
      isExistingApplications,
      alterationTypes,
      applicationTypes,
    } = this.props
    const APPLICATION_TYPE = get(data, 'applicationType', [])
    const {
      searchInput,
      filterCategory,
      openAccordion,
      allRecords,
      isDownloadAllQuoteLoading,
      offset,
    } = this.state
    const results =
      search.results && search.results.length
        ? formatResults(
            search.results,
            APPLICATION_TYPE,
            isExistingApplications,
            alterationTypes,
            applicationTypes
          )
        : []
    const pages = Math.ceil(search.totalRecords / DEFAULT_PAGE_LIMIT)
    const isResultsLoading = search.isLoading
    const {
      appsPageSearchHeading,
      appsPageSearchPlaceholder,
      appPagesFilterByText,
      appPagesMobileFiltersTitle,
      appPagesNoResultFound,
      appPagesNoMatch,
      appDownloadButton,
      appsPageDownloadListFileName,
    } = reduceAuthorableFields(fields)

    const COLUMNS = isExistingApplications
      ? columnsExistingBusiness(reduceAuthorableFields(fields))
      : columnsNewBusiness(reduceAuthorableFields(fields))
    return (
      <WithLoader isLoading={isFetchingData} overlay>
        <TablePanelDesktop>
          <Card>
            <TablePanel>
              <LeftPanel>
                <SearchBox
                  title={appsPageSearchHeading}
                  placeholder={appsPageSearchPlaceholder}
                  value={searchInput}
                  clickHandler={this.clickSearchHandler}
                  changeHandler={this.changeSearchHandler}
                />
              </LeftPanel>
              <RightPanel>
                <div>{appPagesFilterByText}</div>
                <AppSelect
                  value={filterCategory}
                  name="filterQuoteCategory"
                  id="filterQuoteCategory"
                  changeHandler={this.handleQuoteCategoryChange}
                  options={APP_FILTER_OPTIONS}
                  variant="secondary"
                  labelStyle={styles.headerSelItemLabel}
                />
              </RightPanel>
            </TablePanel>
          </Card>
        </TablePanelDesktop>

        <TablePanelMobile>
          <AccordionMobile
            labelKey="quote-cost"
            label={<label>{appPagesMobileFiltersTitle}</label>}
            openIcon={<IconPlus16 />}
            closeIcon={<IconMinus16 />}
            isOpen={openAccordion}
            toggleListener={this.handleAccordionToggle}
          >
            {this.renderOptions(filterCategory)}
          </AccordionMobile>
        </TablePanelMobile>
        {results.length === 0 && (
          <Card>
            <ErrorState
              message={
                search && searchInput && search.totalRecords === 0
                  ? appPagesNoMatch
                  : appPagesNoResultFound
              }
              emptyState
              showBackground={false}
            />
          </Card>
        )}
        {results.length ? (
          <Table
            isManual
            useTableOptions={{ disableSortRemove: true }}
            onPageChange={this.onFetchData}
            onSort={this.onSortedChange}
            pages={pages}
            pageSize={DEFAULT_PAGE_LIMIT}
            currentIndex={offset - 1}
            defaultSorted={[
              {
                id: UPDATED_DATE,
                desc: true,
              },
            ]}
            classes="table-row"
            data={results}
            columns={COLUMNS}
            isLoading={isResultsLoading}
            clickHandler={this.rowClickHandler}
            styleOverrides={{
              tableBody: styles.tableBody,
              tableHead: styles.tableHead,
            }}
            footer={
              results.length ? (
                <WithLoader
                  isLoading={isDownloadAllQuoteLoading}
                  loaderProps={{ type: 'noHeight', spinnerSize: 20 }}
                >
                  <DownloadCSV
                    filename={`${appsPageDownloadListFileName}${CSV_FILE_EXTENSION}`}
                    ref={this.csvLinkRef}
                    asyncOnClick={CSV_ASYNC_ON_CLICK}
                    headers={isExistingApplications ? CSV_HEADERS_ALTS : CSV_HEADERS}
                    data={formatResults(
                      allRecords,
                      APPLICATION_TYPE,
                      isExistingApplications,
                      alterationTypes,
                      applicationTypes
                    )}
                    onClick={(event, done) => this.handleDownloadAllQuotes(allRecords, done)}
                  >
                    {appDownloadButton}
                  </DownloadCSV>
                </WithLoader>
              ) : (
                <Fragment>
                  <DownloadDisabled>{appDownloadButton}</DownloadDisabled>
                </Fragment>
              )
            }
          />
        ) : (
          []
        )}
      </WithLoader>
    )
  }
}

const mapStateToProps = ({
  quotes: { search },
  createQuote: { isFetchingData },
  productRules,
  masterList,
  ure,
  advisor,
}) => ({
  isFetchingData,
  search,
  isProductRules: !!productRules.data && productRules.data.length,
  masterList,
  alterationTypes: get(masterList, 'data.alterationTypes', []),
  applicationTypes: get(masterList, 'data.applicationType', []),
  ure,
  advisor,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationListingComponent))
