import moment from 'moment'
import { createSelector } from 'reselect'
import { Store } from '../types/store'

const getCoverManagerDocuments = (state: Store) => state.coverManagerDocuments

export const getClientCoverManagerDocuments = createSelector(
  [getCoverManagerDocuments],
  coverManagerDocuments => {
    const { isSending, isError, businessData } = coverManagerDocuments
    return {
      isSending,
      isError,
      records: ((businessData || {}).documents || []).map(document => ({
        date: moment(document.createdDate).format('DD MMM YYYY'),
        quoteNumber: document.quoteNumber,
        externalId: document.externalId,
        isDownloading: false,
      })),
    }
  }
)
