import { space, mediaQueries, colours, fontWeights } from '../../../styles'
import { responsiveTileStyle } from './styles'

const styles = {
  offset: {
    marginTop: -space(3),
    [mediaQueries.md]: {
      marginTop: -space(7),
    },
  },
  wrap: {
    position: 'relative',
    marginTop: -space(3),
    marginBottom: space(5),
    [mediaQueries.lg]: {
      marginBottom: space(16),
    },
    button: {
      zIndex: 1,
    },
  },
  tableRow: {
    '.table-row': {
      cursor: 'pointer',
    },
  },
  articleImage: {
    width: space(74),
    height: space(49),
    margin: `${space(6, true)} 0`,
  },
  articleHeading: {
    fontSize: space(4, true),
    lineHeight: space(3, true),
    fontWeight: fontWeights.semiBold,
    color: colours.mediumDarkGrey,
  },
  articleDescription: {
    fontSize: space(2, true),
    lineHeight: space(3, true),
    fontWeight: fontWeights.normal,
    maxWidth: space(94),
    color: colours.mediumDarkGrey,
  },
  articleNavbar: {
    position: 'absolute',
    top: space(20),
    left: space(18),
    right: space(18),
    display: 'flex',
    justifyContent: 'space-between',
  },
  navbarTextWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  navbarText: {
    fontSize: space(2),
    fontWeight: fontWeights.semiBold,
    color: colours.white,
    margin: `0 ${space(0.5, true)}`,
  },
  icon: {
    width: space(2),
    height: space(2),
    color: colours.white,
  },
  iconExclaimation: {
    color: colours.red,
    marginTop: space(0.5, true),
    marginRight: space(1, true),
  },
  card: {
    color: colours.red,
    padding: `0 ${space(4, true)}`,
    display: 'flex',
    flexDirection: 'row',
  },
  articleCardWrapper: {
    display: 'grid',
    gridGap: space(2),
    marginTop: space(8),
    ...responsiveTileStyle,
  },
  loaderWrap: {
    marginTop: space(11.5),
  },
  articleNotAvailable: {
    marginTop: space(5),
  },
}

export default styles
