import React from 'react'
import styled from '@emotion/styled'
import { createEvent } from '../../../utils/telemetry'

// components.
// @ts-expect-error file not in typescript
// eslint-disable-next-line import/named
import { IconChevronDown32, IconChevronUp32 } from '../../atoms/Icons'
// @ts-expect-error file not in typescript
import NavItem from '../../atoms/NavItem'

// hooks.
import useComponentVisible from '../../../hooks/useComponentVisible'

// styles.
import styles from './newNavDropdown.styles'
// @ts-expect-error file not in typescript
import { themeConsumer } from '../../../styles/ThemeContext'
// @ts-expect-error file not in typescript
import { getClassnameProp } from '../../../utils/stylesUtils'

// constants
import {
  RETENTION_NAV_ID,
  BENEFICIARIES_NAV_ID,
  CP_MAKE_A_PAYMENT_NAV_ID,
  REPORTING_NAV_ID,
  CLIENT_LIST_NAV_ID,
  PAYMENT_DETAILS_NAV_ID,
} from '../../../constants/navigation'

type NavDropDownProps = {
  /** Sets the button text */
  buttonText: string
  /** List of navigation items */
  items: Array<{
    id:
      | 'retention'
      | 'beneficiaries'
      | 'makeAPayment'
      | 'reporting'
      | 'clientList'
      | 'paymentDetails'
    href: string
    text: string
  }>
  /** handle onToggle for addon operations  */
  onToggle?: () => void
  /** Set the button variant value */
  variant?: 'primary' | 'secondary'
  menuItemClickHandler: (
    e: React.MouseEvent<HTMLElement>,
    item: {
      id: string
      href: string
      text: string
    }
  ) => void
}

type StylesVariantType = 'primary' | 'secondary' | undefined

const setVariantStyle = (variant?: StylesVariantType) => (el: string) =>
  // @ts-expect-error index type issue
  variant && (styles[variant][el] as Record<string, string | (({ isOpen: boolean }) => object)>)

const Container = styled('div')(
  // @ts-expect-error emotion styled type issue
  styles.base.container,
  ({ variant }: { variant: StylesVariantType }) => setVariantStyle(variant)('container')
)
const Button = styled('button')(
  styles.base.button,
  // @ts-expect-error emotion styled type issue
  ({ variant }: { variant: StylesVariantType }) => setVariantStyle(variant)('button'),
  {
    height: '100%',
  }
)
// @ts-expect-error emotion styled issue
const ButtonText = styled('span')(styles.base.buttonText)
// @ts-expect-error emotion styled issue
const IndicatorCollapsed = styled(IconChevronDown32)(
  styles.base.indicator,
  ({ variant }: { variant: StylesVariantType }) => setVariantStyle(variant)('indicator')
)
const IndicatorExpanded = styled(IndicatorCollapsed.withComponent(IconChevronUp32))()
// @ts-expect-error emotion styled issue
const List = styled('ul')(styles.base.list, ({ variant }: { variant: StylesVariantType }) =>
  setVariantStyle(variant)('list')
)

const NavDropDown = (props: NavDropDownProps) => {
  const { buttonText, items, variant, onToggle, menuItemClickHandler } = props
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const showDropDownList = () => {
    // show dropdown list
    setIsComponentVisible(!isComponentVisible)
    onToggle && onToggle()
  }

  const gaEvent = {
    [RETENTION_NAV_ID]: {
      category: 'Clients (retention)',
      action: 'View',
    },
    [BENEFICIARIES_NAV_ID]: {
      category: 'Customer Name - Global Navigation',
      action: 'Update my beneficiaries',
    },
    [CP_MAKE_A_PAYMENT_NAV_ID]: {
      category: 'Make a payment - My insurance',
      action: 'CP - make a payment',
    },
    [REPORTING_NAV_ID]: {
      category: 'Resources (Reporting)',
      action: 'AP - access reporting',
    },
    [CLIENT_LIST_NAV_ID]: {
      category: 'Clients (client list)',
      action: 'AP - client list',
    },
    [PAYMENT_DETAILS_NAV_ID]: {
      category: 'Payment Details',
      action: 'View',
    },
  }

  const handleListItemClick = (
    e: React.MouseEvent<HTMLElement>,
    item: {
      id: keyof typeof gaEvent
      href: string
      text: string
    }
  ) => {
    // hide dropdown list
    setIsComponentVisible(false)
    if (menuItemClickHandler) {
      menuItemClickHandler(e, item)
    }
    // Analytics
    const event = createEvent({
      GA: gaEvent[item.id],
      Splunk: {
        attributes: {
          'workflow.name': gaEvent[item.id]?.category,
        },
      },
    })
    event.end()
  }

  return (
    <Container
      ref={ref}
      data-test-id="navdropdown"
      {...getClassnameProp(props)}
      isOpen={isComponentVisible}
      variant={variant}
    >
      <Button
        // @ts-expect-error emotion styled issue
        isOpen={isComponentVisible}
        onClick={showDropDownList}
        variant={variant}
      >
        <ButtonText>{buttonText}</ButtonText>
        {isComponentVisible ? (
          <IndicatorExpanded isOpen={isComponentVisible} variant={variant} />
        ) : (
          <IndicatorCollapsed isOpen={isComponentVisible} variant={variant} />
        )}
      </Button>
      <div>
        <List
          isOpen={isComponentVisible}
          // @ts-expect-error emotion styled issue
          variant={variant}
        >
          {items.map(item => (
            <NavItem
              key={item.text}
              href={item.href}
              onClick={(e: React.MouseEvent<HTMLElement>) => handleListItemClick(e, item)}
            >
              {item.text}
            </NavItem>
          ))}
        </List>
      </div>
    </Container>
  )
}

NavDropDown.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  variant: 'primary',
  // eslint-disable-next-line react/default-props-match-prop-types
  onToggle: undefined,
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default themeConsumer(NavDropDown, 'navigation')
