import { Variables } from '@mlcl-digital/mlcl-design'

const { space } = Variables

const styles = {
  offset: {},
  csv: {
    width: 0,
    height: 0,
    overflow: 'hidden',
  },
}

export const styleOverrides = {
  base: {
    marginBottom: space(3),
    borderStyle: 'none',
  },
}
export default styles
