// @flow
import React, { useState, useEffect } from 'react'
import get from 'lodash/get'

// redux
import { useDispatch, useSelector, connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Heading } from '@mlcl-digital/mlcl-design'
import { any, func, bool, objectOf, arrayOf, string, object, shape } from 'prop-types'
import styled from '@emotion/styled'
// Actions
import { actionCreators } from '../../../../actions'
// Components
// Styles
import styles from './styles'
import { colours } from '../../../../styles'

// Component
import { ContactDetailsForm } from './components/ContactDetailsForm'
import { BackCTA } from '../BackCTA'

import { FORM_ID } from './components/ContactDetailsForm/contactDetailsForm.schema'
import { generateFormId } from './components/ContactDetailsForm/utils'
import { reduceAuthorableFields, renderTextField } from '../../../../utils/sitecoreUtils'
import { getPartyType } from './utils'
import { getUniqueRelationships, constructHeading } from '../../../../utils/relationshipUtils'
import {
  getEligiblePolicies,
  getAlterationType,
  getAdvisorEmail,
  getCurrentDate,
  getMembersContactDetails,
  getAltsProductRules,
  getAlterationTypeSelectedByUser,
} from '../../../../selectors/alterations'
import ScrollToTop from '../../../atoms/ScrollToTop'
import {
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
} from '../../../../selectors/common.selectors'
import WithLoader from '../../../molecules/WithLoader'

// constants
import { NAVIGATION_DECREASE, FOOTER_DECREASE } from '../../../../constants/navigation'
import { ALTERATION_TYPES } from '../../../../constants/alterations'
import { sortByKey } from '../../../../utils/sortingUtils'

// Styled Components
const Wrap = styled('div')(styles.wrap)
const FormWrap = styled('div')(styles.formWrap)
const StyledHeading = styled(Heading)(styles.heading)
const SubHeading = styled('div')(styles.subHeading)

const WHITESPACE = ' '

const AltsContactDetails = ({
  fields,
  params,
  forms,
  actions,
  alterations,
  hasCompletedWelcomeJourney,
  masterList,
  isManualResidential,
  isManualPostal,
  currentDate,
}) => {
  const dispatch = useDispatch()
  const timelineState = useSelector(state => state.timelineWithComponents)
  const eligiblePolicies = useSelector(getEligiblePolicies(get(params, 'AlterationType', '')))
  const alterationTypeForGATagging = useSelector(getAlterationType())
  const advisorEmailAddress = useSelector(getAdvisorEmail)
  const membersContactDetailsState = useSelector(getMembersContactDetails)
  const productRules = useSelector(getAltsProductRules)
  const { firstName: firstNameLA, lastName: lastNameLA } = useSelector(
    makeAltsLifeInsuredNameAndPartyNo
  )
  const quoteCollectionName = `${firstNameLA}${WHITESPACE}${lastNameLA}`
  const { action } = useSelector(getCreateQuote)
  const [isSaveQuoteAPILoading, setIsSaveQuoteAPILoading] = useState(false)
  const alterationTypeSelectedByUser = useSelector(getAlterationTypeSelectedByUser)

  const handleClick = () => {
    dispatch(actionCreators.timelineNextState())
  }

  // Object with Policy Owner array and Life Insured
  const uniqueRelationships = getUniqueRelationships(eligiblePolicies)
  // Sitecore fields
  const {
    Title,
    Subtitle,
    PolicyOwner,
    LifeInsured,
    PolicyOwnerAndLifeInsured,
    AddressHelpText,
    ContinueCTA,
  } = reduceAuthorableFields(fields)

  useEffect(() => {
    if (alterationTypeSelectedByUser === ALTERATION_TYPES.DECREASE_RISK) {
      dispatch(actionCreators.changeNavigationType(NAVIGATION_DECREASE))
      dispatch(actionCreators.changeFooterType(FOOTER_DECREASE))
    }
  }, [alterationTypeSelectedByUser])

  // Construct Client details Array (policy owner/ life insured)
  // Array<{contactMethods, bancsCustomerNo, partyType}>
  const membersContactDetails = uniqueRelationships
    .sort(sortByKey('roleCode', true))
    .map(({ bancsCustomerNo, ...restOfRelationship }) => {
      // Use the contactMethods from Bancs Member update API, else use it from policy
      const contactMethods =
        get(membersContactDetailsState[bancsCustomerNo], 'contactMethods') ||
        get(restOfRelationship, 'relatedParty.contactMethods')
      return {
        ...(contactMethods && { contactMethods }),
        bancsCustomerNo,
        partyType: getPartyType(restOfRelationship),
      }
    })

  // Container for all contact details forms
  const contactDetailsForms = {}
  uniqueRelationships.forEach((_, index) => {
    const formIndex = generateFormId(FORM_ID, index)
    contactDetailsForms[formIndex] = forms[formIndex]
  })

  const renderContactFormForEachRelationship = () =>
    [...uniqueRelationships]
      .sort(sortByKey('roleCode', true))
      .map(
        (
          {
            bancsCustomerNo,
            roleCode,
            roles,
            relatedParty: {
              partyType,
              firstName,
              lastName,
              businessName,
              contactMethods: contactMethodsFromPolicy,
            },
          },
          index
        ) => (
          <ContactDetailsForm
            key={bancsCustomerNo}
            fields={fields}
            params={params}
            actions={actions}
            quoteActionType={action}
            firstNameLA={firstNameLA}
            lastNameLA={lastNameLA}
            quoteCollectionName={quoteCollectionName}
            productRules={productRules}
            customerPersonalDetails={{
              details: {
                contactMethods: {
                  ...(get(membersContactDetailsState[bancsCustomerNo], 'contactMethods') ||
                    contactMethodsFromPolicy),
                },
              },
              partyType,
            }}
            closeContactDetailsForm={null}
            isManualResidential={isManualResidential}
            isManualPostal={isManualPostal}
            form={forms[generateFormId(FORM_ID, index)]}
            hasCompletedWelcomeJourney={hasCompletedWelcomeJourney}
            masterList={masterList}
            formIdIndex={index}
            formHeading={constructHeading(
              roleCode,
              roles,
              PolicyOwner,
              LifeInsured,
              PolicyOwnerAndLifeInsured,
              partyType,
              firstName,
              lastName,
              businessName
            )}
            formSubHeading={AddressHelpText}
            ContinueCTA={ContinueCTA}
            membersContactDetails={membersContactDetails}
            membersContactDetailsState={membersContactDetailsState}
            alterations={alterations}
            hideCTAButtons={index === uniqueRelationships.length - 1}
            isAlterations
            alterationType={alterationTypeForGATagging}
            contactDetailsForms={contactDetailsForms}
            advisorEmailAddress={advisorEmailAddress}
            handleClick={handleClick}
            currentDate={currentDate}
            setIsSaveQuoteAPILoading={setIsSaveQuoteAPILoading}
          />
        )
      )

  return (
    timelineState.activeComponent === 'AltsContactDetails' && (
      <Wrap>
        <ScrollToTop>
          <WithLoader
            loaderProps={{ loaderContent: renderTextField(fields.SaveLoaderMessage) }}
            isLoading={isSaveQuoteAPILoading}
            childrenBackgroundColor={colours.white}
            overlay
          >
            <FormWrap>
              <BackCTA fields={fields} />
              <StyledHeading size="large" variant="h2">
                {Title}
              </StyledHeading>
              <SubHeading>{renderTextField(Subtitle)}</SubHeading>
              {renderContactFormForEachRelationship()}
            </FormWrap>
          </WithLoader>
        </ScrollToTop>
      </Wrap>
    )
  )
}

AltsContactDetails.propTypes = {
  // Sitecore authorable fields.
  fields: objectOf(
    shape({
      value: string,
    })
  ).isRequired,
  // params from Sitecore
  params: shape({
    AlterationType: string,
  }),
  // An object containing action creator functions.
  actions: objectOf(func).isRequired,
  // One of the createQuote action types
  quoteActionType: string,
  // MasterList
  masterList: objectOf(any).isRequired,
  // Forms
  forms: objectOf(
    shape({
      isValid: bool,
      isSending: bool,
      isDirty: bool,
      fields: object,
    })
  ),
  // alterations redux object
  alterations: shape({
    bancsCustomerNo: string,
    clientId: string,
    hasFormSubmitted: bool,
    hasNetworkError: bool,
    hasUpdateError: bool,
    isFetchingPolicies: bool,
    isFetchingRejectCPI: bool,
    isPoliciesFetchError: bool,
    memberContactDetailsForm: shape({ hasNetworkError: bool, hasFormSubmitted: bool }),
    members: arrayOf({ hasUpdateError: bool }),
    membersContactDetails: objectOf(
      shape({
        hasUpdateError: bool,
        isUpdateLoading: bool,
      })
    ),
    policies: arrayOf(object),
    rejectCPIData: objectOf(
      shape({
        isNotInDateRange: bool,
        rejectCPIAlreadyApplied: bool,
        isMultipleLifeInsured: bool,
      })
    ),
    rejectCPIError: null,
  }),
}

const mapStateToProps = state => {
  const {
    timelineWithComponents,
    forms,
    alterations,
    addressLookup: { residentialAddress, postalAddress },
    authentication: { hasCompletedWelcomeJourney },
    masterList,
  } = state
  const { isManual: isManualResidential } = residentialAddress || {}
  const { isManual: isManualPostal } = postalAddress || {}
  return {
    timelineWithComponents,
    forms,
    alterations,
    hasCompletedWelcomeJourney,
    masterList,
    isManualResidential,
    isManualPostal,
    currentDate: getCurrentDate(state),
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AltsContactDetails)
