import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

// components
import { Heading } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../actions'

// selectors
import { getSelectedPoliciesPartyContactDetailsList } from '../../../../selectors/alterationPolicies'

// types
import { Props, PartyContactDetails } from '../../../../types/components/AltsContactDetails'
import { Store } from '../../../../types/store'

// styles
import styles from './altsContactDetailsV2.styles'

const Wrapper = styled('div')(styles.wrapper)
const ButtonContainer = styled('div')(styles.buttonContainer)

const AltsContactDetailsV2 = ({ fields, params, rendering }: Props) => {
  const customerContactDetailsList = useSelector(
    getSelectedPoliciesPartyContactDetailsList(params?.AlterationType, fields)
  ) as PartyContactDetails[]
  const addressLookup = useSelector((state: Store) => state.addressLookup)
  const [editStates, setEditStates] = useState<boolean[]>(
    Array((customerContactDetailsList || []).length).fill(false)
  )

  const handleEditChange = (index: number, isEditing: boolean) => {
    const newEditStates = [...editStates]
    newEditStates[index] = isEditing
    setEditStates(newEditStates)
  }

  const dispatch = useDispatch()

  const handleBackClick = () => {
    dispatch(actionCreators.timelinePrevState())
  }

  const handleContinueClick = () => {
    dispatch(actionCreators.timelineNextState())
  }

  const isAnyContactDetailInEditState = editStates.some((isEditing: boolean) => isEditing)

  const isManualResidential = addressLookup.residentialAddress?.isManual
  const isManualPostal = addressLookup?.postalAddress?.isManual
  return (
    <Wrapper>
      <p>{renderTextField(fields.Subtitle)}</p>
      {rendering?.placeholders?.['contact-details'] &&
        customerContactDetailsList?.map((customer, index) => (
          <>
            <Heading size="small" variant="h3" styleOverrides={styles.subHeading}>
              {customer.heading}
            </Heading>
            <Placeholder
              name="contact-details"
              rendering={rendering}
              index={index}
              customerContactDetails={customer.data}
              isManualResidential={isManualResidential}
              isManualPostal={isManualPostal}
              onParentEditChange={handleEditChange}
              hasCompletedWelcomeJourney
            />
          </>
        ))}
      <ButtonContainer>
        <Button variant="secondaryWithTheme" onClick={handleBackClick}>
          {renderTextField(fields.BackCTA)}
        </Button>
        <Button disabled={isAnyContactDetailInEditState} onClick={handleContinueClick}>
          {renderTextField(fields.ContinueCTA)}
        </Button>
      </ButtonContainer>
    </Wrapper>
  )
}

export default AltsContactDetailsV2
