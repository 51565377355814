import {
  colours,
  containerUtils,
  fontFamily,
  fontSize,
  mediaQueries,
  shadows,
  space,
  zIndex,
  fontWeights,
  SIDEBAR_DEFAULT_WIDTH,
} from '../../../../../styles'

import browser from '../../../../../utils/browserUtils'

const isIE11 = browser.ie && browser.ie === 11

const linkSpacing = {
  paddingTop: space(2),
  paddingBottom: space(2),
  display: 'flex',
  alignItems: 'center',
}

const styles = {
  logoImage: () => ({
    marginBottom: 0,
  }),
  header:
    () =>
    ({ isScrolling, isSidebarOpen, sidebarWidth = SIDEBAR_DEFAULT_WIDTH }) => ({
      backgroundColor: colours.white,
      paddingTop: space(2),
      position: 'fixed',
      left: 0,
      right: 0,
      boxShadow: isScrolling ? shadows.mid : 'none',
      zIndex: zIndex.mainNavigation,

      '& + *': {
        paddingTop: space(8),

        [mediaQueries.md]: {
          paddingTop: space(11),
        },
      },

      [mediaQueries.md]: {
        padding: 0,
        paddingLeft: space(3),
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        // IE11 fixed header does not move with parent, so must also move header
        transform: isIE11 && isSidebarOpen ? `translateX(-${sidebarWidth}px)` : 'translateX(0)',
        transitionTimingFunction: 'ease',
        transitionDuration: '.2s',
        transitionDelay: isIE11 && isSidebarOpen ? '.05s' : '0',
      },
    }),
  logoContainer: () => ({
    ...containerUtils.innerPaddingHorizontal,
    alignSelf: 'flex-start',
    marginBottom: space(2),
    display: 'flex',
    justifyContent: 'space-between',

    [mediaQueries.md]: {
      marginBottom: 0,
      marginRight: space(1),
      padding: 0,
      alignSelf: 'center',

      [mediaQueries.lg]: {
        marginRight: space(4),
      },
    },
  }),
  nav: () => props => ({
    display: props.isOpen ? 'block' : 'none',
    color: colours.darkGrey,
    [mediaQueries.md]: {
      display: 'block',
    },
  }),
  item:
    () =>
    ({ showOnSmall }) => ({
      display: 'flex',
      alignItems: 'center',

      [mediaQueries.md]: {
        border: 0,
        color: colours.darkGreen,
        display: showOnSmall && 'none',
        fontFamily: fontFamily.sourceSans,

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    }),

  list: () => ({
    fontFamily: fontFamily.charlie,

    '> li:last-child': {
      borderBottomWidth: 1,
      borderBottomColor: colours.lightGrey,
      borderBottomStyle: 'solid',

      [mediaQueries.md]: {
        borderBottomWidth: 0,
      },
    },

    [mediaQueries.md]: {
      display: 'flex',
      borderBottomWidth: 0,
    },
  }),
  itemLink: () => ({
    [mediaQueries.md]: {
      paddingLeft: space(2),
      paddingRight: space(2),
      ...linkSpacing,

      '& > *': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    [mediaQueries.lg]: {
      paddingLeft: space(2.5),
      paddingRight: space(2.5),
    },
    fontWeight: fontWeights.bold,
  }),

  itemLinkShort: () => ({
    '& > *': {
      fontSize: fontSize.xxs,
    },
  }),

  navSubSection: () => ({
    display: 'none',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    [mediaQueries.md]: {
      display: 'flex',
    },
  }),

  primaryDropdown: () => ({
    [mediaQueries.lg]: {
      // minWidth: 192,
    },
  }),

  quickAction: () => ({
    paddingTop: space(2),
    paddingBottom: space(2),
  }),

  quickActionButton: () => ({
    paddingLeft: space(2),
    paddingRight: space(2),
    marginRight: space(4),
    marginLeft: space(2),
  }),

  exitToDashboard: {
    backgroundColor: colours.mediumGreen,
    paddingLeft: space(2),
    paddingRight: space(2),

    '& > *': {
      color: colours.white,
      ...linkSpacing,
    },
    '&:hover': {
      backgroundColor: colours.mediumGreen,
      color: colours.white,
    },
  },
}

export default styles
