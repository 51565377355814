// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { colours } = Variables
const styles = {
  modalFooter: {
    textAlign: 'right',
  },
  modalDescription: {
    color: colours.tertiaryOne,
  },
}

export default styles
