// components.
import PersonalDetailsForm from './components/PersonalDetailsForm'
import ContactDetailsForm from './components/ContactDetailsForm'
import AdvisorPreferencesForm from './components/AdvisorPreferencesForm'
import DataFeedRegistration from './components/DataFeedRegistration'
// utils.
import { idMaker } from '../../../utils/formUtils'
import { getAdviserNo } from '../../../utils/cookieUtils'

import SupportStafList from './components/SupportStaffList'

const schema = props => [
  {
    title: props.fields.personalTabTitle,
    id: idMaker(props.fields.personalTabTitle),
    component: PersonalDetailsForm,
    isVisible: true,
    props,
  },
  {
    title: props.fields.contactTabTitle,
    id: idMaker(props.fields.contactTabTitle),
    component: ContactDetailsForm,
    isVisible: true,
    props,
  },
  {
    title: props.fields.dataFeedRegistrationTabTitle,
    id: idMaker(props.fields.dataFeedRegistrationTabTitle),
    component: DataFeedRegistration,
    isVisible: !getAdviserNo(),
    props,
  },
  {
    title: props.fields.advisorPreferencesTabTitle,
    id: idMaker(props.fields.advisorPreferencesTabTitle),
    component: AdvisorPreferencesForm,
    isVisible: true,
    props,
  },
  {
    title: props.fields.supportStaffTabTitle,
    id: idMaker(props.fields.supportStaffTabTitle),
    component: SupportStafList,
    isVisible: !getAdviserNo(),
    props,
  },
]

export default schema
