// @flow
import React from 'react'

import QuoteSummaryRoot from '../../../organisms/QuoteSummary'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

type QuoteSummaryProps = {
  fields: Object<Object>,
}

const QuoteSummary = ({ fields }: QuoteSummaryProps) => (
  <QuoteSummaryRoot fields={reduceAuthorableFields(fields)} />
)

export default QuoteSummary
