import { space, colours, fontSize } from '../../../styles'

const styles = {
  modalDescription: {
    marginBottom: space(5),
    color: colours.black,
  },
  errorDescription: {
    textAlign: 'left',
    color: colours.black,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  userIcon: {
    margin: `0 auto ${space(1, true)}`,
    color: colours.black,
    width: space(5, true),
    height: '32px',
  },
  twouserIcon: {
    fontSize: fontSize.xxs,
    color: colours.lightGreen,
  },
}
export default styles
