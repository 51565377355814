// @flow
import React, { Fragment, Component } from 'react'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { pathOr } from 'lodash/fp'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { actionCreators } from '../../../actions'
import { checkIfApplicationPageShowLoader } from '../../../selectors/quoteAndApply'

// atoms
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import {
  IconTick32,
  IconUser32,
  IconEdit32,
  IconUmbrella32,
  IconArrowLeft16,
} from '../../atoms/Icons'

// components
import InsuredDetails from './components/InsuredDetails'
import PersonalStatementModal from '../PersonalStatementModal'
import Status from './components/Status'
import Step from './components/Step'
import LifeInsuredDetails from '../LifeInsuredDetails'

// molecules
import Modal from '../../molecules/Modal'
import WithLoader from '../../molecules/WithLoader'

// constant
import { NAVIGATION_APPLICATION_OVERVIEW } from '../../../constants/navigation'
import { CALCULATED_URE_TERM } from '../../../constants/ure'
import {
  APPLICATION_STAGE_STEP,
  APPLICATION_REVIEW_PATH,
  PERSONAL_STATEMENT,
  RESULTS_PAGE,
  ADVISER_VERIFICATION,
  MYLINK,
  YES,
  STEP_ONE,
  STEP_TWO,
  STAGE0,
  STAGE1,
  STAGE2,
  APPLICATION_DECLARATION_PATH,
  STEP_ZERO,
  APPLICATION_EDIT_PAGE,
  SAVE_DRAFT_APPLICATION,
  CONVERT_TO_DRAFT_APPLICATION,
  TELE,
} from '../../../constants/application'

// utils.
import history from '../../../utils/browserHistory'
import { arrayToObject } from '../../../utils/commonUtils'
import { scrollToTop } from '../../../utils/formUtils'
import { getTermAndCondition } from '../../../utils/termsAndConditionsUtils'
import { getLifeAsssured } from '../../../utils/quoteUtils'
import { policyCompletionCheck, hasSeriesCodeExpired } from '../../../utils/extendedQuoteUtils'
import { getMissingRequirement } from '../../../utils/policyUtils'
import { isEnquiyIdExpired } from '../../../utils/ureUtils'
import { extractLoadingsFromQuote } from '../../../utils/preAssessmentBenefitUtils'

// style
import styles from './overview.styles'

// schema
import stepsSchema from './steps.schema'
import SeriesCodeChangedAlert from '../../molecules/SeriesCodeChangedAlert'

const TopBackground = styled('div')(styles.topBackground)
const InfoContainer = styled('section')(styles.infoContainer)
const StepsContainer = styled('section')(styles.stepsContainer)
const NavSection = styled('section')(styles.navSection)
const SubmitContainer = styled('div')(styles.submitContainer)
const SubmitButton = styled(Button)(styles.submitButton)
const NavButton = styled(Button)(styles.nav)
const HeaderDescription = styled(Heading)(styles.headerDescription)
const DisplayLifeAssured = styled('div')(({ stage }) => styles.displayLifeAssured(stage))
const ModalBody = styled('div')(styles.modalBody)

type ApplicationOverviewProps = {
  quoteData: Object,
  fields: Object,
  actions: Object,
  // data of redux which keeps stage status and stage value
  application: Object,
  // form of lifeAssured
  lifeInsuredForm: Object,
  ure: Object,
  productRules: Object,
  rawFields: Object,
  // Checks createQuote.isFetching to determine whether we should show a loader
  shouldOverPageShowLoader: Boolean,
  quoteCollectionId: Object,
  fileUploadInfo: Object,
  location: Object,
}

class ApplicationOverview extends Component<ApplicationOverviewProps> {
  constructor(props) {
    super(props)
    this.state = {
      isOpenPersonalStatementModal: false,
      isAllStagesCompleted: false,
      isShowOverlayLoader: false,
    }
    this.setStage = this.setStage.bind(this)
    this.handlePath = this.handlePath.bind(this)
  }

  componentDidMount() {
    const {
      actions: { changeNavigationType, resetFooterType, updateApplicationStage },
      quoteData: { activeIndex, quotes, action },
      // fetch underwritingMethod from URE - till enquiry Id is not created
      actions,
      ure,
      quoteCollectionId,
      fileUploadInfo,
      application,
      location: { search },
    } = this.props
    const { fromApplicationSearch, fromApplicationSummary } = queryString.parse(search)
    this.getQuoteCollection(fromApplicationSearch)
    const { existingCovers, underwritingDetails, policyStructure, myLinkDetails } =
      quotes[activeIndex]
    const underwritingMethod = get(underwritingDetails, 'underwritingMethod', '')
    const calculatedUREStatus = get(underwritingDetails, 'calculatedUREStatus', '')
    const isSatisfied = get(underwritingDetails, 'isSatisfied', '')
    const existingConsents =
      quotes[activeIndex].consents && quotes[activeIndex].consents.length
        ? arrayToObject(quotes[activeIndex].consents, 'name')
        : []

    let isUnderwritingCompleted = false
    const isMlcOnTrack = pathOr('false', 'policyStructure[0].mlcOnTrack', quotes[activeIndex])
    if (existingConsents) {
      if (isMlcOnTrack) {
        isUnderwritingCompleted = !!existingConsents.MLC_ON_TRACK_DECLINED
      } else {
        isUnderwritingCompleted = !!(
          (existingConsents.FAST_TRACKING_MEDICAL_REQTS &&
            existingConsents.FAST_TRACKING_FOLLOWUP_INFO) ||
          (existingConsents.ADVISER_VERIFICATION && existingConsents.ADVISER_VERIFICATION.value)
        )
      }
    }

    const coverInfo = get(existingCovers, 'consent.name')
    const { quoteData } = this.props
    const hasEnquiryExpired = this.hasEnquiryExpired()
    const lifeAssured = getLifeAsssured(quoteData)
    const isLifeAssuredValid =
      lifeAssured && lifeAssured.relatedParty && lifeAssured.relatedParty.identifiers
    if (fromApplicationSearch && isLifeAssuredValid) {
      updateApplicationStage(STEP_ZERO, { stage1: APPLICATION_STAGE_STEP.completed })
    } else if (isLifeAssuredValid) {
      updateApplicationStage(STEP_ONE, { stage1: APPLICATION_STAGE_STEP.completed })
    } else {
      updateApplicationStage(STEP_ONE, { stage1: APPLICATION_STAGE_STEP.partiallyComplete })
    }
    // If we're from the application summary screen, it means we always want the personal
    // statement partially completed to make sure we always calculate the correct URE response
    if (fromApplicationSummary) {
      updateApplicationStage(STEP_TWO, { stage2: APPLICATION_STAGE_STEP.partiallyComplete })
    } else {
      // If My Link Details are empty in MYLink/Tele with existing covers and Fast Tracking answered
      // then the Personal Statement stage is still Partially complete
      const isMyLinkDetailsUpdated = get(myLinkDetails, 'application.url')
      if (
        (underwritingMethod === MYLINK || underwritingMethod === TELE) &&
        coverInfo &&
        isUnderwritingCompleted &&
        isMyLinkDetailsUpdated
      ) {
        updateApplicationStage(STEP_TWO, { stage2: APPLICATION_STAGE_STEP.lifeAssuredCompleted })
      } else if (underwritingDetails && isSatisfied && !hasEnquiryExpired) {
        if (calculatedUREStatus !== CALCULATED_URE_TERM) {
          updateApplicationStage(STEP_TWO, { stage2: APPLICATION_STAGE_STEP.completed })
        } else {
          updateApplicationStage(STEP_TWO, { stage2: APPLICATION_STAGE_STEP.partiallyComplete })
        }
      } else if (underwritingDetails && (!isSatisfied || hasEnquiryExpired)) {
        updateApplicationStage(STEP_TWO, { stage2: APPLICATION_STAGE_STEP.partiallyComplete })
      } else {
        updateApplicationStage(STEP_TWO, { stage2: APPLICATION_STAGE_STEP.incomplete })
      }
    }

    let policySetupComplete = []
    if (policyStructure && policyStructure.length) {
      policyStructure.forEach(policy => {
        if (policySetupComplete) {
          policySetupComplete = policySetupComplete.concat(policyCompletionCheck(policy))
        }
      })
    }
    const checkStatusArray = status => status
    if (policySetupComplete.every(checkStatusArray)) {
      updateApplicationStage(2, {
        stage3: APPLICATION_STAGE_STEP.completed,
      })
    } else if (policySetupComplete.includes(true)) {
      updateApplicationStage(2, {
        stage3: APPLICATION_STAGE_STEP.partiallyComplete,
      })
    } else {
      updateApplicationStage(2, {
        stage3: APPLICATION_STAGE_STEP.incomplete,
      })
    }
    const { updateApplicationStatus, updateQuoteStatus } = actions
    const missingRequirement = getMissingRequirement(
      policyStructure,
      ure,
      fileUploadInfo,
      quoteCollectionId,
      existingConsents
    )
    updateApplicationStatus(missingRequirement)
    const { stageStatus } = application
    if (
      stageStatus.stage1 === APPLICATION_STAGE_STEP.completed &&
      action === CONVERT_TO_DRAFT_APPLICATION
    ) {
      updateQuoteStatus({ actionName: SAVE_DRAFT_APPLICATION })
    }
    changeNavigationType(NAVIGATION_APPLICATION_OVERVIEW)
    resetFooterType()
  }

  setStage(stage) {
    const {
      actions: { updateApplicationStage, createQuoteSetIsSatisfied },
      quoteData: { activeIndex, quotes },
    } = this.props
    const activeQuoteConsents = quotes[activeIndex].consents
    const isAvailable =
      (activeQuoteConsents &&
        activeQuoteConsents.filter(
          item => item.name === ADVISER_VERIFICATION && item.value === YES
        )) ||
      []

    if (stage === 0) {
      return () => {
        createQuoteSetIsSatisfied(false)
        history.push(APPLICATION_EDIT_PAGE)
      }
    }

    if (stage === 2) {
      const hasPSCompleted = get(quotes, `[${activeIndex}]underwritingDetails.isSatisfied`, false)
      const hasEnquiryExpired = this.hasEnquiryExpired()

      if (hasPSCompleted && !hasEnquiryExpired) {
        return () => {
          history.push(RESULTS_PAGE)
        }
      }
      if (isAvailable.length) {
        return () => {
          history.push(PERSONAL_STATEMENT)
        }
      }
      return () => {
        this.setState({ isOpenPersonalStatementModal: true })
      }
    }

    return () => {
      updateApplicationStage(stage)
    }
  }

  // eslint-disable-next-line react/sort-comp
  hasEnquiryExpired() {
    const {
      quoteData: { activeIndex, quotes },
      ure,
    } = this.props
    const createdOn = get(quotes, `[${activeIndex}]underwritingDetails.createdOn`, '')
    const enquiryIdValidityPeriod = get(ure, 'initialData.umeExpiryPeriod')
    let hasEnquiryExpired = false

    if (createdOn && enquiryIdValidityPeriod) {
      hasEnquiryExpired = isEnquiyIdExpired(createdOn, enquiryIdValidityPeriod)
    }

    return hasEnquiryExpired
  }

  handlePersonalStatementCloseModal = () => {
    this.setState({ isOpenPersonalStatementModal: false })
  }

  handleBackEvent = () => {
    const {
      application: { stage },
      fields: { lifeInsuranceUnsavedDataPopupLabel },
      lifeInsuredForm,
    } = this.props
    if (lifeInsuredForm.isDirty) {
      // @FIXME: should we be using confirms here?
      // eslint-disable-next-line no-alert
      if (window.confirm(lifeInsuranceUnsavedDataPopupLabel)) {
        this.setStage(stage - 1)()
      }
    } else {
      this.setStage(stage - 1)()
    }
  }

  getQuoteCollection = fromApplicationSearch => {
    const {
      actions: { fetchQuoteCollection, ureInitalData, updateLoading },
      quoteData: { activeIndex, quotes },
    } = this.props
    const { quoteCollectionId } = queryString.parse(history.location.search)
    if (quoteCollectionId) {
      fetchQuoteCollection(quoteCollectionId)
    }
    ureInitalData({}, (error, payload) => {
      if (fromApplicationSearch) {
        const umeBenefits = get(payload, 'data.umeBenefits', [])
        const { policyStructure } = quotes[activeIndex]
        updateLoading(extractLoadingsFromQuote(umeBenefits, policyStructure))
      }
    })
  }

  handleReviewPath = () => {
    const {
      application: { stageStatus },
      quoteData: { activeIndex, quotes },
      actions: { createQuoteCalculate },
    } = this.props
    const activeQuote = quotes[activeIndex]
    const completedStages = Object.keys(stageStatus).filter(
      key =>
        stageStatus[key] === APPLICATION_STAGE_STEP.completed ||
        stageStatus[key] === APPLICATION_STAGE_STEP.lifeAssuredCompleted
    )
    if (completedStages.length !== 4) {
      this.setState({
        isAllStagesCompleted: true,
      })
    } else {
      const { redirect } = getTermAndCondition(activeQuote)
      const path = redirect ? APPLICATION_REVIEW_PATH : APPLICATION_DECLARATION_PATH
      createQuoteCalculate(
        {
          initiateSaveQuote: true,
        },
        err => {
          if (!err) {
            history.push(path)
          }
        }
      )
    }
  }

  handlePath = path => () => history.push(path)

  closeRemoveModal = () => {
    this.setState({
      isAllStagesCompleted: false,
    })
  }

  handleScrollToTop = () => scrollToTop(0)

  showOverlayLoader = isShow => this.setState({ isShowOverlayLoader: isShow })

  // quoteCollactionName value comes from store else it will pick from fields fallback value
  render() {
    const { isOpenPersonalStatementModal, isAllStagesCompleted, isShowOverlayLoader } = this.state
    const icons = {
      IconTick32,
      IconUser32,
      IconEdit32,
      IconUmbrella32,
    }
    const {
      quoteData,
      fields,
      application: { stage, stageStatus },
      productRules,
      rawFields,
      shouldOverPageShowLoader,
    } = this.props
    // quoteCollectionName value comes from store else it will pick from fields fallback value
    const quoteCollectionName = pathOr(
      fields.applicationQuoteCollectionName,
      'quoteCollectionName',
      quoteData
    )
    const mandatories = pathOr({}, 'quotes[0].memberMandatories', quoteData)
    mandatories.quoteCollectionName = quoteCollectionName
    const disableSubmit = stage < 2

    const { quotes, activeIndex } = quoteData

    const quote = quotes[activeIndex]
    const products = productRules.data

    let changeInSeriesCode = false

    if (hasSeriesCodeExpired(quote, products)) {
      changeInSeriesCode = true
    }

    return (
      <WithLoader isLoading={isShowOverlayLoader || shouldOverPageShowLoader} overlay>
        <TopBackground>
          <PageWrap>
            {Number(stage) !== 1 && (
              <InfoContainer>
                <InsuredDetails
                  fields={fields}
                  mandatories={mandatories}
                  title={fields.applicationPageTitle}
                />
                <Status
                  fields={fields}
                  totalSteps={4}
                  currentStep={
                    Object.keys(stageStatus).filter(
                      key =>
                        stageStatus[key] === APPLICATION_STAGE_STEP.completed ||
                        stageStatus[key] === APPLICATION_STAGE_STEP.lifeAssuredCompleted
                    ).length
                  }
                />
              </InfoContainer>
            )}
            {Number(stage) === 1 && (
              <NavSection>
                <NavButton data-testid="backButton" onClick={this.handleBackEvent} type="icon">
                  <IconArrowLeft16 />
                  {fields.lifeInsuranceBack}
                </NavButton>
                <Heading size="1">{fields.lifeInsuranceCaption}</Heading>
                <HeaderDescription size="6">{fields.lifeInsuranceCaptionDetails}</HeaderDescription>
              </NavSection>
            )}
          </PageWrap>
        </TopBackground>
        <PageWrap>
          {Number(stage) !== 1 && (
            <Fragment>
              <StepsContainer>
                {stepsSchema.map((step, index) => {
                  let { iconType } = step
                  const { displayNameId, path } = step
                  if (
                    stageStatus[`stage${index}`] === APPLICATION_STAGE_STEP.completed ||
                    stageStatus[`stage${index}`] === APPLICATION_STAGE_STEP.lifeAssuredCompleted
                  ) {
                    iconType = 'IconTick32'
                  } else if ([`stage${index}`] === STAGE0) {
                    iconType = 'IconTick32'
                  } else if ([`stage${index}`] === STAGE1) {
                    iconType = 'IconUser32'
                  } else if ([`stage${index}`] === STAGE2) {
                    iconType = 'IconEdit32'
                  } else {
                    iconType = 'IconEdit32'
                  }
                  return (
                    <Step
                      key={displayNameId}
                      status={stageStatus[`stage${index}`]}
                      fields={fields}
                      icon={icons[iconType]}
                      onButtonClickHandler={path ? this.handlePath(path) : this.setStage(index)}
                      displayName={fields[displayNameId]}
                      statusText={fields[stageStatus[`stage${index}`]]}
                      stage={stage}
                      step={index}
                      stageStatus={stageStatus.stage1}
                    />
                  )
                })}
              </StepsContainer>
              <SubmitContainer>
                {/* This Application Review button will be
                  removed in future and margin associated with it */}
                <SubmitButton
                  type="primary"
                  disabled={disableSubmit}
                  onClick={this.handleReviewPath}
                  isLoading={quoteData.isRecalculating}
                >
                  {fields.applicationReviewButtonText}
                </SubmitButton>
              </SubmitContainer>
            </Fragment>
          )}
          <DisplayLifeAssured stage={stage}>
            <LifeInsuredDetails fields={fields} handleScrollToTop={this.handleScrollToTop} />
          </DisplayLifeAssured>
          {isOpenPersonalStatementModal && (
            <PersonalStatementModal
              fields={fields}
              handlePersonalStatementCloseModal={this.handlePersonalStatementCloseModal}
              handleParentPageLoader={this.showOverlayLoader}
              parentPage="ApplicationOverview"
            />
          )}
          <Modal
            isOpen={isAllStagesCompleted}
            onClose={this.closeRemoveModal}
            title={fields.applicationModalTitle}
            shouldFocusCloseButton={false}
          >
            <ModalBody>{fields.applicationModalBody}</ModalBody>

            <Button type="secondary" onClick={this.closeRemoveModal}>
              {fields.applicationModalButton}
            </Button>
          </Modal>
          <SeriesCodeChangedAlert fields={rawFields} isOpen={changeInSeriesCode} shouldRedirect />
        </PageWrap>
      </WithLoader>
    )
  }
}

export const mapStateToProps = (state: Object) => {
  const { application, createQuote, forms, ure, productRules } = state
  return {
    application,
    ure,
    quoteData: createQuote,
    lifeInsuredForm: get(forms, 'lifeInsuredDetails', {}),
    productRules,
    shouldOverPageShowLoader: checkIfApplicationPageShowLoader(state),
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationOverview))
