// @flow
import React, { Fragment, Component } from 'react'
import get from 'lodash/get'
import styled from '@emotion/styled'
import mlclUre from '@mlcl-digital/ure/lib'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import '@mlcl-digital/ure/css/style.css'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// actions
import { actionCreators } from '../../../actions'

// utils
import { isBrowser } from '../../../utils/browserUtils'

// style
import styles from './ure.styles'
import Heading from '../../atoms/Heading'

const MainWrapper = styled('div')(styles.wrapper)
const TopBackground = styled('div')(styles.topBackground)
const PageWrapHeader = styled(PageWrap)(styles.pageWrapHeader)
const HeaderContainer = styled('div')(styles.headerContainer)
const PageContent = styled('div')(styles.pageContent)

type UreToolProps = {
  fields: Object<Object>,
  actions: Object<Object>,
  // Access to url parameters via react router.
  location: {
    search: string,
  },
  ure: Object,
  accessToken: Object,
  createQuote: Object,
  config: Object,
}

let ignoreFirstClose = false
// eslint-disable-next-line prefer-const
let ureInstance = {}
export class UreTool extends Component<UreToolProps> {
  componentDidMount() {
    const {
      ure: { activeEnquiryId },
    } = this.props
    if (activeEnquiryId) this.initiateUreForm()
  }

  componentDidUpdate(prevProps) {
    const {
      ure: { activeEnquiryId: activeEnquiryIdPrev },
    } = prevProps
    const {
      ure: { activeEnquiryId },
    } = this.props
    if (activeEnquiryId && activeEnquiryIdPrev !== activeEnquiryId) this.initiateUreForm()
  }

  componentWillUnmount() {
    if (ureInstance && ureInstance.destroy) ureInstance.destroy()
  }

  onStepCompleted = lastAction => {
    if (!lastAction.error) {
      // TODO: functions for future functionality
    }
  }

  onDestroy = () => {
    // TODO: functions for future functionality
  }

  onFormCompleted = closeAction => {
    const {
      actions: { saveAnswersInUre, getResultsOfUre, openSidebar },
      ure: {
        callBackUrl,
        initialData: { umeBenefits, umeExpiryPeriod },
      },

      createQuote: { quotes, activeIndex },
    } = this.props
    const {
      payload,
      payload: {
        allAnswers: { ENQUIRY_TYPE },
      },
    } = closeAction.event
    const activeQuote = quotes[activeIndex]
    if (
      ignoreFirstClose ||
      ENQUIRY_TYPE.indexOf('Pre-sale') === -1 ||
      (isBrowser() && window.location.pathname.includes('underwriting/engine'))
    ) {
      return
    }
    saveAnswersInUre(payload)
    getResultsOfUre({
      ...payload,
      umeBenefits,
      expiry: {
        noOfDays: umeExpiryPeriod,
        createdOn: get(activeQuote, 'preAssessmentDetails.createdOn', ''),
      },
    })

    if (callBackUrl) {
      if (isBrowser()) document.documentElement.scrollTop = 0
      openSidebar('preAssessment')
    }
    ignoreFirstClose = true
  }

  // eslint-disable-next-line class-methods-use-this
  eventSubscriber(event) {
    /* Since URE plugin track last event, next request after any
      completed request always gets CLOSE_ENQUIRY_REQUEST,
      in this case it always triggers onFormCompleted so to avoid
      multiple onFormCompleted callback trigger this check is here */

    if (
      isBrowser() &&
      event &&
      event.type === 'ENQUIRY_REQUEST' &&
      ignoreFirstClose &&
      window.location.pathname.includes('ure-tool')
    ) {
      ignoreFirstClose = false
    }
  }

  initiateUreForm() {
    const { ure, accessToken, config } = this.props
    const { activeEnquiryId, answers } = ure
    const API_DOMAIN: string = config.MLCL_EXPERIENCE_API

    const basicConfig = {
      authToken: accessToken,
      baseEndpoint: `${API_DOMAIN}/v1/digital/retailproxy/ure`,
      answers: {
        ...answers[activeEnquiryId].allAnswers,
      },
      enquiryId: activeEnquiryId,
    }

    // main code
    ;(async () => {
      ureInstance = await mlclUre.init(document.getElementById('ure'), {
        ...basicConfig,
        showSubNav: true,
        // TODO: hooks for future functionality
        /*  onStepCompleted: this.onStepCompleted,
          onDestroy: this.onDestroy, */
        eventSubscriber: this.eventSubscriber,
        onFormCompleted: this.onFormCompleted,
        sessionTimeout: false,
      })
    })()
  }

  render() {
    const { fields } = this.props
    const { preAssessmentTitle } = fields
    return (
      <Fragment>
        <TopBackground>
          <PageWrapHeader>
            <HeaderContainer>
              <Heading size={1}>{preAssessmentTitle.value}</Heading>
            </HeaderContainer>
          </PageWrapHeader>
        </TopBackground>
        <PageContent>
          <PageWrap>
            <MainWrapper>
              <div id="ure" />
            </MainWrapper>
          </PageWrap>
        </PageContent>
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ ure, okta, createQuote, config }: Object) => ({
  ure,
  accessToken: okta.token,
  createQuote,
  config,
})

export default connect(mapStateToProps, mapDispatchToProps)(UreTool)
