// @flow
import React from 'react'
// Organism
import TaskUploadConfirmationModalOrganism from '../../../organisms/TaskUploadConfirmationModal'

type TaskUploadConfirmationModalProps = {
  fields: Object,
}

const TaskUploadConfirmationModal = ({ fields }: TaskUploadConfirmationModalProps) => (
  <TaskUploadConfirmationModalOrganism fields={fields} />
)

export default TaskUploadConfirmationModal
