import { createSelector } from 'reselect'
import get from 'lodash/get'
import moment from 'moment'
import { getInforceCustomerPoliciesCardData } from './customerPolicies'
import { getCustomerRelationships } from './customerPolicyDetails'
import { getCollectionFrequency } from '../utils/paymentUtils'
import { getMaskedCardNumber } from '../utils/commonUtils'
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
import { getPaymentCollectionMethodOnPaymentHistory } from '../utils/paymentMethods'
import { CUST_PAYMENT_OPTIONS, getProductIdConfig } from '../constants/forms'
import { FORM_ID } from '../components/organisms/CustomerPaymentDetails/components/PaymentDetails/paymentDetails.schema'
import { FORM_ID as ROLLOVER_PAYMENT_FORM } from '../components/organisms/CustomerPaymentDetails/components/modals/PaymentDetailsModal/components/RolloverPayment/rolloverPayment.schema'
import { FORM_ID as MASTER_KEY_PAYMENT_FORM } from '../components/organisms/CustomerPaymentDetails/components/modals/PaymentDetailsModal/components/MasterKey/masterKey.schema'
import { getPolicyStatuses, getCustomerPolicies } from './common'
import {
  EXP_CUSTOMER_STATUS_UNLOADED,
  EXP_CUSTOMER_STATUS_LOADING,
  EXP_CUSTOMER_STATUS_FAILED,
} from '../constants/apis'

import {
  ROLLOVER_FROM_EXTERNAL_FUND,
  MASTER_KEY_ACCOUNT,
  ELIGIBLE_MLC_ACCOUNT,
  CC,
  DD,
  PAYMENT_INSTRUCTION_PURPOSE,
  PAYMENT_INSTRUCTION_COLLECTION_BASIS,
  PAYMENT_INSTRUCTION_INSTRUMENT_TYPE,
} from '../constants/customerPaymentDetails'

import {
  CREDIT_CARD_ID,
  DIRECT_DEBIT_ID,
  BPAY,
  CHEQUE,
  MLC_ACC,
  ROLL_OVER_ID,
  // NAVIGATOR,
  MASTER_KEY_ACCOUNT_ID,
  WRAPPER_NAVIGATOR_ID,
  MASTER_KEY_LABEL,
  ROLL_OVER_LABEL,
  PAYMENT_HISTORY_DATE_FORMAT,
  // POLICY_IDENTIFIER_POLICY_ID,
} from '../constants/policies'

const getCustomerRelationshipsObject = state => state.customerRelationships

export const getPaymentDetails = (paymentInstrument = {}) => {
  const {
    collectionMethod,
    creditCardDetails = {},
    debitCardDetails = {},
    fundDetails = {},
  } = paymentInstrument

  const { cardOwnerName = '-', panID = '-', cardExpiryDate = '-' } = creditCardDetails
  const { accountName = '-', accountNo = '-', bsb = '-' } = debitCardDetails
  const {
    uniqueSuperannuationId = '-',
    externalFundName = '-',
    externalFundABN = '-',
    externalFundProductName = '-',
    externalMembershipNo = '-',
  } = fundDetails
  const paymentData = {
    [CREDIT_CARD_ID]: [cardOwnerName, getMaskedCardNumber(panID), cardExpiryDate],
    [DIRECT_DEBIT_ID]: [accountName, accountNo, bsb],
    [BPAY]: () => [],
    [CHEQUE]: () => [],
    [ROLL_OVER_ID]: [
      uniqueSuperannuationId,
      externalFundName,
      externalFundABN,
      externalFundProductName,
      externalMembershipNo,
    ],
    [MLC_ACC]: [externalMembershipNo],
  }

  return paymentData[collectionMethod] || []
}

const isChequeOrBpay = paymentMethod => paymentMethod === CHEQUE || paymentMethod === BPAY

export const getPoliciesAndPaymentDetails = createSelector(
  [
    getInforceCustomerPoliciesCardData,
    getCustomerPolicies,
    getCustomerRelationships,
    getPolicyStatuses,
  ],
  (policies, policiesStore, relationships, statuses) => {
    const mapData = policies.map(policy => {
      const policyState = policiesStore.find(p => p.policyNo === policy.bancsPolicyNo)
      const { paymentInstrumentLoaded } = statuses.find(p => p.policyNo === policy.bancsPolicyNo)
      const payerBancsCustomerNo = get(policyState, 'payers.0', '')
      const payer = relationships.find(
        relationship => relationship.bancsCustomerNo === payerBancsCustomerNo
      )
      const missingPaymentAddressDetails = get(payer, 'contactMethods.addresses', []).length === 0
      const paymentInstrument = get(policyState, 'paymentInstrument', {})
      const { collectionFrequency, collectionMethod, fundDetails = {} } = paymentInstrument
      const { sourceFundingSystem = '-' } = fundDetails
      const isSuper = policy.policyType === 'Inside Super'
      const additionalPaymentMeta = policyState
        ? {
            collectionFrequency:
              getCollectionFrequency(collectionFrequency) || policy.collectionFrequency,
            collectionMethod,
            paymentDetails: getPaymentDetails(paymentInstrument),
            sourceFundingSystem,
            // missingPaymentAddressDetails
            shouldShowForm:
              isSuper ||
              isChequeOrBpay(collectionMethod) ||
              !collectionMethod ||
              missingPaymentAddressDetails,
            isSuper,
            hasFailed: policy.hasFailed || paymentInstrumentLoaded === 'FAILED',
          }
        : {}
      return {
        ...policy,
        ...additionalPaymentMeta,
      }
    })
    return mapData
  }
)

export const getActivePolicy = state => state.paymentDetailsActivePolicy

const getActivePoliciesPaymentMeta = createSelector(
  [getActivePolicy, getCustomerPolicies],
  (bancsPolicyNo, policies) => {
    const { paymentInstrument, isAssociatedToSuperFund, productId } =
      policies.find(policy => policy.policyNo === bancsPolicyNo) || {}
    return {
      paymentInstrument,
      isAssociatedToSuperFund,
      productId,
    }
  }
)

const getPoliciesPaymentHistoryMeta = createSelector([getCustomerPolicies], policies => {
  const paymentDetailsMeta = []
  policies.map(
    policy =>
      policy.paymentHistory &&
      policy.paymentInstrument &&
      paymentDetailsMeta.push({
        policyId: policy.policyId,
        history: policy.paymentHistory && get(policy.paymentHistory, 'history', []),
        details: policy.paymentInstrument,
      })
  )
  return paymentDetailsMeta
})
export const getActivePaymentInstrument = createSelector(
  [getActivePoliciesPaymentMeta],
  ({ paymentInstrument }) => paymentInstrument
)

export const getPaymentOptions = createSelector(
  [getActivePoliciesPaymentMeta],
  ({ isAssociatedToSuperFund, productId }) => {
    let options = CUST_PAYMENT_OPTIONS
    if (!getProductIdConfig(productId).supportsNavigatorPayment) {
      options = CUST_PAYMENT_OPTIONS.filter(({ value }) => value !== 'WRAP')
    }
    if (isAssociatedToSuperFund) {
      options = options.concat(
        {
          label: ROLL_OVER_LABEL,
          value: ROLL_OVER_ID,
        },
        {
          label: MASTER_KEY_LABEL,
          value: MASTER_KEY_ACCOUNT_ID,
        }
      )
    }
    return options
  }
)
const setCollectionMethod = collectionMethod => {
  if (collectionMethod === WRAPPER_NAVIGATOR_ID || collectionMethod === MASTER_KEY_ACCOUNT) {
    return ELIGIBLE_MLC_ACCOUNT
  }
  return collectionMethod
}

const getForm = state => get(state.forms[FORM_ID], 'fields.paymentDetails.value.value', '')

const getRollOverForm = state => state.forms[ROLLOVER_PAYMENT_FORM]
const getMasterKeyPaymentForm = state => state.forms[MASTER_KEY_PAYMENT_FORM]

export const getPaymentDetailFormType = createSelector([getForm], form => form)

export const editPaymentMethodRequestMeta = createSelector(
  [getPaymentDetailFormType, getRollOverForm, getMasterKeyPaymentForm],
  (formType, rolloverPaymentForm, masterKeyPaymentForm) => {
    const editPaymentMethodRequest = {
      collectionMethod: setCollectionMethod(formType),
    }
    if (
      masterKeyPaymentForm &&
      (formType === MASTER_KEY_ACCOUNT_ID || formType === WRAPPER_NAVIGATOR_ID)
    ) {
      const {
        accountNumber: { value: membershipAccountNumber },
      } = masterKeyPaymentForm.fields
      editPaymentMethodRequest.fundDetails = { membershipAccountNumber }
    }
    if (rolloverPaymentForm && formType === ROLLOVER_FROM_EXTERNAL_FUND) {
      const {
        fundName: { value: externalFundName },
        abn: { value: externalFundABN },
        usi: { value: internalUSI },
        membershipNumber: { value: externalMembershipNo },
        productName: { value: externalFundProductName },
      } = rolloverPaymentForm.fields
      editPaymentMethodRequest.fundDetails = {
        externalFundName,
        externalFundABN,
        internalUSI,
        externalMembershipNo,
        externalFundProductName,
      }
    }
    return editPaymentMethodRequest
  }
)

const getCaptureCreditCard = state => state.captureCreditCard
const getCreditCardForm = state => state.forms.custCreditCardPayment
const getDirectDebitForm = state => state.forms.directDebit
const getBsbLookup = state => state.bsbLookup
export const getUpdatedPaymentInstrument = createSelector(
  [
    getPaymentDetailFormType,
    getActivePaymentInstrument,
    getActivePolicy,
    getCreditCardForm,
    getCaptureCreditCard,
    getDirectDebitForm,
    getBsbLookup,
  ],
  (
    formType,
    paymentInstrument,
    bancsPolicyNo,
    creditCardForm,
    captureCreditCard,
    directDebitForm,
    bsbLookup
  ) => {
    let updatedPaymentInstrument = {
      policyDetails: [{ bancsPolicyNo }],
      bancsPolicyNo,
      collectionFrequency: paymentInstrument.collectionFrequency,
      bancsPayerCustomerNo: paymentInstrument.bancsPayerCustomerNo,
      purpose: PAYMENT_INSTRUCTION_PURPOSE,
      collectionBasis: PAYMENT_INSTRUCTION_COLLECTION_BASIS,
      paymentDrawDay: paymentInstrument.paymentDrawDay,
      instrumentType: PAYMENT_INSTRUCTION_INSTRUMENT_TYPE,
      firstCollectionDate: paymentInstrument.nextCollectionDate,
      collectionMethod: setCollectionMethod(formType),
    }

    if (formType === CC) {
      const { cardHolderName, expiryDate, cardType } = creditCardForm.fields
      const { nabData } = captureCreditCard
      updatedPaymentInstrument = {
        ...updatedPaymentInstrument,
        creditCardDetails: {
          tokenNumber: get(nabData, 'token', 0),
          panID: get(nabData, 'pan', ''),
          cardExpiryDate: expiryDate.value,
          cardOwnerName: cardHolderName.value,
          cardType: cardType.value,
        },
      }
    }
    if (formType === DD) {
      const { accountName, accountNumber } = directDebitForm.fields
      const { directDebitBsb } = bsbLookup
      updatedPaymentInstrument = {
        ...updatedPaymentInstrument,
        debitCardDetails: {
          accountName: accountName.value,
          accountNo: accountNumber.value,
          bsb: get(directDebitBsb, 'data.branchCode', ''),
          bankName: get(directDebitBsb, 'data.bsbName', ''),
          branchAddress: get(directDebitBsb, 'data.address', ''),
        },
      }
    }
    return updatedPaymentInstrument
  }
)

export const getFileUploadFormName = createSelector(
  [getActivePoliciesPaymentMeta],
  ({ productId }) => getProductIdConfig(productId).customerPayment
)

export const getPaymentHistory = createSelector([getPoliciesPaymentHistoryMeta], paymentDetails =>
  paymentDetails.flatMap(
    paymentEntity =>
      paymentEntity.history &&
      paymentEntity.history.map(
        payment =>
          payment &&
          ({
            policyId: paymentEntity.policyId,
            paymentMethod:
              getPaymentCollectionMethodOnPaymentHistory(paymentEntity.details, payment) || '-',
            date: moment(payment.paymentDate, PAYMENT_HISTORY_DATE_FORMAT).format('DD/MM/YYYY'),
            amount: dollarAmountWithCommasAndTwoDecimal(payment.paymentAmount),
          } ||
            [])
      )
  )
)

export const shouldPaymentDetailsPageShowLoader = createSelector(
  [getPolicyStatuses, getCustomerPolicies, getCustomerRelationshipsObject],
  (statuses, policies, relationships) => {
    const payersBancsCustomerNos = [...new Set(policies.flatMap(policy => policy.payers))]
    return (
      statuses.some(
        status =>
          status.paymentInstrumentLoaded === EXP_CUSTOMER_STATUS_LOADING ||
          status.paymentInstrumentLoaded === EXP_CUSTOMER_STATUS_UNLOADED
      ) || payersBancsCustomerNos.some(customerNo => !relationships[customerNo].hasRelatedParty)
    )
  }
)

export const shouldMakeAPaymentPageShowLoader = createSelector([getPolicyStatuses], statuses =>
  statuses.some(status =>
    [status.loaded, status.paymentInstrumentLoaded].includes(
      EXP_CUSTOMER_STATUS_LOADING,
      EXP_CUSTOMER_STATUS_UNLOADED
    )
  )
)

export const checkIfPaymentHistoryFailed = createSelector([getPolicyStatuses], statuses =>
  statuses.some(status => status.paymentHistory === EXP_CUSTOMER_STATUS_FAILED)
)

export const checkIfEveryPaymentInstrumentFailed = createSelector(
  [getPolicyStatuses],
  statuses =>
    statuses.length > 0 &&
    statuses.every(status => status.paymentInstrumentLoaded === EXP_CUSTOMER_STATUS_FAILED)
)

export const checkIfSomePaymentInstumentFailed = createSelector(
  [getPolicyStatuses],
  statuses =>
    statuses.length > 0 &&
    statuses.some(status => status.paymentInstrumentLoaded === EXP_CUSTOMER_STATUS_FAILED)
)
