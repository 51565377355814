// @flow
import { NULIS_EXTRA_FIELDS } from '../actions/types/nulis'

export const initialState = {
  showNulisExtraFields: false,
}

const nulis = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case NULIS_EXTRA_FIELDS: {
      const { flag } = action.payload
      return {
        ...state,
        showNulisExtraFields: flag,
      }
    }

    default: {
      return state
    }
  }
}

export default nulis
