// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import queryString from 'query-string'
import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import QuoteSummaryPanel from './components/LeftPanel'
import QuoteSummaryDetail from './components/PolicyDetail'
import WithLoader from '../../molecules/WithLoader'

// utils.
import { formatManualQuote } from '../../../utils/quoteUtils'
import history from '../../../utils/browserHistory'

// styles.
import styles from './quoteSummary.styles'
import { NAVIGATION_QUOTE } from '../../../constants/navigation'

type QuoteSummaryProps = {
  // Sitecore fields
  fields: Object,
  // An object containing action creator functions.
  actions: Object<Function>,
  // active quote object
  quote: Object<Object>,
  // Quote Action
  quoteAction: String,
  isFetchingData: Boolean,
  // Master data from Sitecore
  masterData: Object,
}

const QuoteSummaryRoot = styled('div')(styles.quoteSummaryRoot)

/**
 * QuoteSummary
 * This component will render the customer information on left sidebar
 * and the current active quote premium information on right section
 */
export class QuoteSummary extends Component<QuoteSummaryProps> {
  componentWillMount() {
    const { actions } = this.props
    const { manual, quoteId } = queryString.parse(history.location.search)
    if (manual === 'true') {
      actions.getQuoteForManual(quoteId)
      actions.getAllProducts()
      actions.getMasterList()
    }
    actions.changeNavigationType(NAVIGATION_QUOTE)
    actions.resetFooterType()
  }

  render() {
    const { fields, quote, quoteAction, isFetchingData, masterData } = this.props

    const quoteData = quote
    const { draftQuoteSummaryAgeLabel } = fields

    const updatedFields = {
      ...fields,
      quoteSummaryAgeLabel: draftQuoteSummaryAgeLabel,
    }

    return (
      <WithLoader isLoading={isFetchingData} overlay>
        <QuoteSummaryRoot>
          <QuoteSummaryPanel
            fields={updatedFields}
            quoteData={quoteData}
            quoteAction={quoteAction}
            masterData={masterData}
          />
          <QuoteSummaryDetail fields={fields} quoteData={quoteData} history={history} />
        </QuoteSummaryRoot>
      </WithLoader>
    )
  }
}

// selector function to retrieve the active quote.
export const selectActiveQuote = ({ quotes, activeIndex }: Object): Object => quotes[activeIndex]

export const mapStateToProps = ({ createQuote, masterList, quoteTemplates: { manualQuote } }) => ({
  quote: selectActiveQuote(createQuote),
  manualQuote: formatManualQuote(get(manualQuote, 'quotes[0]', {})),
  quoteAction: createQuote.action,
  isFetchingData: createQuote.isFetchingData,
  masterData: get(masterList, 'data', []),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSummary)
