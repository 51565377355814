// @flow
import React from 'react'
import PolicySetupContainer from '../../../organisms/PolicySetup'

type PolicySetupProps = {
  fields: Object,
}

const PolicySetup = ({ fields }: PolicySetupProps) => <PolicySetupContainer fields={fields} />

export default PolicySetup
