// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Label } from '@mlcl-digital/mlcl-design'
// components.
import { IconChevronUp16, IconChevronDown16 } from '../../atoms/Icons'
import Checkbox from '../../atoms/Checkbox'

// styles.
import styles from './multiSelectDropdown.styles'

// utils
import { isBrowser } from '../../../utils/browserUtils'

const DropDownContainer = styled('div')(styles.container)
const OptionList = styled('ul')(styles.optionList)
const MultiSelectDropdown = styled('div')(styles.dropDown)
const MutiSelectWrapper = styled('div')(styles.mutiSelectWrapper)
const Option = styled('li')(styles.option)
const SelectAllOption = styled('li')(styles.option, styles.selectAllOption)
const SelectAllContainer = styled('div')(styles.selectAllContainer)

type MultiSelectProps = {
  // Name of form field
  name: String,
  // options to render
  options: Array<Object>,
  // component label
  label: String,
  // selected options
  selectedOptions: Array<Object>,
  // callback when check box selcted/unselected
  handleOptionChange: Function,
  // Handle case for selecting all options,
  handleSelectAll: Function,
  // Handle case for deselecting all options,
  handleDeselectAll: Function,
  // Abbreviate multiple selected to say 'multiple' instead.
  abbreviateMultipleSelected: Boolean,
}

export class MultiSelectDropdownComponent extends Component<MultiSelectProps> {
  constructor() {
    super()
    this.state = {
      isOptionsOpen: false,
    }
  }

  componentDidMount() {
    // Adding event on document so that dropdown can be closed when clicked outside
    if (isBrowser()) {
      document.addEventListener('click', this.documentClickHandler, false)
    }
  }

  documentClickHandler = () => {
    this.closeDropdown()
  }

  closeDropdown = () => {
    const { isOptionsOpen } = this.state
    if (isOptionsOpen) {
      this.setState({
        isOptionsOpen: false,
      })
    }
  }

  getValueArray = (array = []) => (array ? array.join(',') : '')

  getMultiLabel = (array = []) => {
    const { abbreviateMultipleSelected } = this.props
    if (array && array.length > 0) {
      if (abbreviateMultipleSelected && array.length > 1) {
        return 'Multiple'
      }
      return array.join(',')
    }
    return ''
  }

  toggleDropdown = () => {
    const { isOptionsOpen } = this.state
    this.setState({ isOptionsOpen: !isOptionsOpen })
  }

  wrapperClickHandler = (event: Object) => {
    // Stopping event propagation so that click event does not
    // reach document when clicked anywhere in dropdown
    event.nativeEvent.stopImmediatePropagation()
  }

  render() {
    const {
      options = [],
      label,
      selectedOptions = [],
      handleOptionChange,
      name,
      handleSelectAll,
      handleDeselectAll,
    } = this.props
    const { isOptionsOpen } = this.state
    return (
      <DropDownContainer>
        <Label>{label}</Label>
        <MutiSelectWrapper onClick={this.wrapperClickHandler}>
          <MultiSelectDropdown onClick={this.toggleDropdown}>
            <span>{isOptionsOpen ? 'Select' : this.getMultiLabel(selectedOptions)}</span>
            {isOptionsOpen ? <IconChevronUp16 /> : <IconChevronDown16 />}
          </MultiSelectDropdown>
          <OptionList>
            {isOptionsOpen && (handleSelectAll || handleDeselectAll) && (
              <SelectAllOption>
                <SelectAllContainer>
                  {handleSelectAll && (
                    <button type="button" onClick={() => handleSelectAll(name)}>
                      Select all
                    </button>
                  )}
                  {handleDeselectAll && (
                    <button type="button" onClick={() => handleDeselectAll(name)}>
                      Deselect all
                    </button>
                  )}
                </SelectAllContainer>
              </SelectAllOption>
            )}

            {isOptionsOpen &&
              options.map((option, index) => (
                <Fragment key={`option-${option.name}-${option.value}`}>
                  <Option>
                    <Checkbox
                      text={option.label}
                      name={`${name}-${index}`}
                      htmlFor={`${name}-${index}`}
                      disabled={false}
                      value={option.value}
                      variant="multiselect"
                      checked={selectedOptions.indexOf(option.value) > -1}
                      onChangeHandler={handleOptionChange}
                    />
                  </Option>
                </Fragment>
              ))}
          </OptionList>
        </MutiSelectWrapper>
      </DropDownContainer>
    )
  }
}

export default MultiSelectDropdownComponent
