import React, { useState, useCallback } from 'react'
import TabBar from '@mlcl-digital/mlcl-design/lib/base/TabBar'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

// FIXME: ts-expect-error comment can be removed when files are converted to typescript
// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { isFeatureEnabled } from '../../../../../utils/featureToggling'

// components
import Benefits from '../Benefits'
import Payment from '../Payment'
import Correspondence from '../Correspondence'
import Activities from '../Activities'
import Beneficiaries from '../Beneficiaries'
import CoverManager from '../CoverManager'

// types
import { ClientPolicy } from '../../../../../types/ClientPolicies'
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'

// constants
import { POLICY_MEMBER_IDENTIFIER_CUSTOMER } from '../../../../../constants/policies'

// selectors
import { getAdvisor } from '../../../../../selectors/advisor'
import { getMasterData, getConfig } from '../../../../../selectors/common.selectors'

type policyAdditionalDetailsProps = {
  fields: fieldsType
  policyData: ClientPolicy
  onPolicyTabChange: (index: number) => void
} & RouteComponentProps

const PolicyAdditionalDetails = ({
  fields,
  policyData,
  location,
  onPolicyTabChange,
}: policyAdditionalDetailsProps) => {
  const [currentTab, setCurrentTab] = useState(0)
  const onTabChange = useCallback((tab: number) => {
    setCurrentTab(tab)
  }, [])

  const advisor = useSelector(getAdvisor)
  const masterData = useSelector(getMasterData)
  const config = useSelector(getConfig)

  const isCoverManagerFeature = isFeatureEnabled('CoverManager', config.FEATURES)
  const advisorCustomerNo = advisor?.details?.identifiers?.find(
    ({ type }) => type === POLICY_MEMBER_IDENTIFIER_CUSTOMER
  )?.value

  const schema = [
    {
      title: renderTextField(fields.Benefits),
      id: 'benefits',
      component: Benefits,
      isVisible: true,
      props: {
        policyData,
        fields,
        onPolicyTabChange,
      },
    },
    {
      title: renderTextField(fields.Payments),
      id: 'payments',
      component: Payment,
      isVisible: true,
      props: {
        policyData,
        fields,
      },
    },
    {
      title: renderTextField(fields.Beneficiaries),
      id: 'beneficiaries',
      component: Beneficiaries,
      isVisible: true,
      props: {
        policyData,
        fields,
      },
    },
    {
      title: renderTextField(fields.Correspondence),
      id: 'correspondence',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      component: Correspondence,
      isVisible: true,
      props: {
        policyData,
        fields,
      },
    },
    {
      title: renderTextField(fields.Activities),
      id: 'activities',
      component: Activities,
      isVisible: true,
      props: {
        policyData,
        fields,
      },
    },
    {
      title: renderTextField(fields.CoverManager),
      id: 'coverManager',
      component: CoverManager,
      isVisible: isCoverManagerFeature
        ? masterData.coverManagerEligibleAdviserCodes?.some(
            item => item.value === advisorCustomerNo
          )
        : false,
      props: {
        policyData,
        fields,
      },
    },
  ]

  return (
    <TabBar
      onTabChange={onTabChange}
      currentTab={currentTab}
      // @ts-expect-error facing issue with schema type defined in components library
      schema={schema}
      disableHash
      location={location}
      isMobileAllOptions
    />
  )
}

export default withRouter(PolicyAdditionalDetails)
