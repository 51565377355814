/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment from 'moment'
import {
  BENEFICIARY_TYPE_BINDING_CODE,
  SUPER_ESTATE_OPTIMISER_RELATIONSHIP_BENEFICIARY_MAPPING,
  SEO_BINDING_LABEL,
  BENEFICIARY_TYPES_CODES,
  RELATIONSHIP_BENEFICIARY_FORM,
} from '../constants/forms'
import { POLICY_PARTY_TYPE_INDIVIDUAL, POLICY_PARTY_TYPE_BUSINESS } from '../constants/policies'
import { Relationship, PolicyAttributes, RelatedParty } from '../types/ClientPolicies'
// @ts-ignore
import { DATE_FORMAT_WITH_MONTH_NAME } from './quoteUtils'
// @ts-ignore
import { getFullAddressWithState } from './contactUtils'

const RELATIONSHIP_LEGAL_REP = 'LGLRPSNT'

const getBenefciaryName = (relatedParty: RelatedParty) => {
  const { firstName, middleName, lastName, businessName, partyType } = relatedParty
  return partyType === POLICY_PARTY_TYPE_INDIVIDUAL
    ? [firstName, middleName, lastName].filter(Boolean).join(' ')
    : businessName
}

const getBindingTypeText = (bindingType: string | undefined) => {
  const match = BENEFICIARY_TYPES_CODES.find(code => code.value === bindingType)
  return match && match.label
}

const getRelationshipText = (beneficiaryRelationshipType: string | undefined) => {
  const found =
    beneficiaryRelationshipType &&
    RELATIONSHIP_BENEFICIARY_FORM.find(code => code.value === beneficiaryRelationshipType)
  return found && found.label
}

/**
 *  Returns mapped Life Beneficiary data from Advisor Policies.relationships payload
 * @param beneficiaries
 * @param policyAttributes
 * @returns
 */

export const getClientBeneficiaries = (
  beneficiaries: Array<Relationship>,
  policyAttributes: PolicyAttributes | undefined
) => {
  const superEstateOptimiserBenefeciaries = (policyAttributes || []).map(({ attributeValue }) => {
    const beneficiaryMapping =
      SUPER_ESTATE_OPTIMISER_RELATIONSHIP_BENEFICIARY_MAPPING[attributeValue]
    return {
      bindingType: BENEFICIARY_TYPE_BINDING_CODE,
      bindingTypeValue: SEO_BINDING_LABEL,
      beneficiaryName: beneficiaryMapping.label,
      percentangeAllocationValue: beneficiaryMapping.benefitPercentage,
      personalDetailsSchema: null,
      beneficiaryRelationshipType: beneficiaryMapping.relationshipType,
      key: beneficiaryMapping.label,
      address: undefined,
      dateOfBirth: undefined,
      policyPartyType: POLICY_PARTY_TYPE_BUSINESS as string,
    }
  })

  const benefeciaries = beneficiaries.map((beneficiary, index) => {
    const {
      bancsCustomerNo,
      beneficiaryRelationshipType,
      relatedParty,
      percentageAllocation,
      bindingType,
    } = beneficiary

    const beneficiaryName = relatedParty ? getBenefciaryName(relatedParty) : '-'
    return {
      bindingType,
      bindingTypeValue: getBindingTypeText(bindingType),
      beneficiaryName,
      policyPartyType: relatedParty && relatedParty.partyType,
      percentangeAllocationValue: percentageAllocation ? `${percentageAllocation}%` : '-',
      ...(relatedParty &&
        relatedParty.partyType === POLICY_PARTY_TYPE_INDIVIDUAL && {
          dateOfBirth: moment(relatedParty.dateOfBirth).format(DATE_FORMAT_WITH_MONTH_NAME),
          address: getFullAddressWithState(relatedParty.contactMethods.addresses),
        }),
      beneficiaryRelationshipType: getRelationshipText(beneficiaryRelationshipType) || '',
      bancsCustomerNo,
      key: `${beneficiaryRelationshipType || ''}-${beneficiaryName || ''}-${index}`,
      formStateToEdit: beneficiary,
      isLegalRepresentive: beneficiaryRelationshipType === RELATIONSHIP_LEGAL_REP,
      isContainAddressDOB:
        !!(relatedParty.contactMethods && relatedParty.contactMethods.addresses) &&
        !!relatedParty.dateOfBirth,
    }
  })
  return [...superEstateOptimiserBenefeciaries, ...benefeciaries]
}
