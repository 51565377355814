// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'
import { renderTextField } from '../../../../../utils/sitecoreUtils'

import styles from './downloadRecords.styles'

const Wrapper = styled('div')(styles.wrapper)
const CheckAnswersText = styled('p')(styles.checkAnswersText)

type DownloadRecordsProps = {
  fields: Object<Object>,
  disabled: boolean,
  onClick: Function,
  isDownloadROATextEnabled: boolean,
}

const DownloadRecords = ({
  fields,
  disabled,
  onClick,
  isDownloadROATextEnabled,
}: DownloadRecordsProps) => {
  const { underwritingResultsDownloadText, UnderwritingResultsCheckAnswers } = fields
  const handleOnClick = e => {
    onClick(e)
  }
  return (
    <Wrapper>
      <Button
        variant="secondaryWithOutline"
        onClick={handleOnClick}
        type="button"
        disabled={disabled}
      >
        {renderTextField(underwritingResultsDownloadText)}
      </Button>
      {isDownloadROATextEnabled && (
        <CheckAnswersText>{renderTextField(UnderwritingResultsCheckAnswers)}</CheckAnswersText>
      )}
    </Wrapper>
  )
}

export default DownloadRecords
