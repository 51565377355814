/* eslint-disable no-unused-vars */
import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'
import {
  colours,
  containerUtils,
  fontFamily,
  fontSize,
  fontWeights,
  shadows,
  space,
  zIndex,
  SIDEBAR_DEFAULT_WIDTH,
  // @ts-expect-error file not in typescript
} from '../../../../../styles'
// @ts-expect-error file not in typescript
import browser from '../../../../../utils/browserUtils'

const { colours: libColours, mediaQueries } = Variables as VariablesType
const isIE11 = browser.ie && browser.ie === 11

const linkSpacing = {
  paddingTop: space(2),
  paddingBottom: space(2),
  display: 'flex',
  alignItems: 'center',
}

const styles = {
  logoImage: {
    marginBottom: 0,
  },
  overlay: {
    zIndex: zIndex.mainNavigation - 1,
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  } as const,
  header: ({
    isScrolling,
    isSidebarOpen,
    sidebarWidth = SIDEBAR_DEFAULT_WIDTH,
    isNotificationBannerVisible,
  }: {
    isScrolling: boolean
    isSidebarOpen: boolean
    sidebarWidth?: number
    isNotificationBannerVisible: boolean
  }) =>
    ({
      backgroundColor: colours.white,
      paddingTop: space(2),
      position: 'fixed',
      left: 0,
      right: 0,
      boxShadow: isScrolling ? shadows.mid : 'none',
      zIndex: zIndex.mainNavigation,
      borderBottom: `1px solid ${libColours.tertiaryThree}`,

      '& + *': {
        paddingTop: isNotificationBannerVisible ? space(15.5) : space(8),

        [mediaQueries.tb]: {
          paddingTop: isNotificationBannerVisible ? space(18.5) : space(11),
        },
      },

      [mediaQueries.tb]: {
        padding: `0 0 0 ${space(3, true)}`,
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'start',
        // IE11 fixed header does not move with parent, so must also move header
        transform: isIE11 && isSidebarOpen ? `translateX(-${sidebarWidth}px)` : 'translateX(0)',
        transitionTimingFunction: 'ease',
        transitionDuration: '.2s',
        transitionDelay: isIE11 && isSidebarOpen ? '.05s' : '0',
      },
    } as const),
  logoContainer: {
    ...containerUtils.innerPaddingHorizontal,
    alignSelf: 'flex-start',
    marginBottom: space(2),
    display: 'flex',
    justifyContent: 'space-between',

    [mediaQueries.tb]: {
      marginBottom: 0,
      marginRight: space(1),
      padding: 0,
      alignSelf: 'center',

      [mediaQueries.lg]: {
        marginRight: space(4),
        minHeight: space(11),
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  nav: ({ isOpen }: { isOpen: boolean }) => ({
    display: isOpen ? 'block' : 'none',
    color: colours.darkGrey,
    [mediaQueries.tb]: {
      display: 'block',
    },
  }),
  item: ({ showOnSmall }: { showOnSmall?: boolean }) =>
    ({
      display: 'block',

      [mediaQueries.tb]: {
        border: 0,
        color: colours.darkGreen,
        display: showOnSmall ? 'none' : 'block',
        fontFamily: fontFamily.sourceSans,
        '&:hover': {
          backgroundColor: libColours.tertiaryFive,
          color: libColours.mlcAquaAccessible,
        },
      },
    } as const),
  helpLink: {
    fontWeight: fontWeights.semiBold,
    listStyle: 'none',
    fontSize: fontSize.sm,
    textAlign: 'right',
  } as const,
  customerPrimaryDropdown: {
    width: space(13, true),
  },
  list: {
    fontFamily: fontFamily.charlie,

    '> li:last-child': {
      borderBottomWidth: 1,
      borderBottomColor: colours.lightGrey,
      borderBottomStyle: 'solid',

      [mediaQueries.tb]: {
        borderBottomWidth: 0,
      },
    },

    [mediaQueries.tb]: {
      display: 'flex',
      height: '100%',
      borderBottomWidth: 0,
    },
  } as const,
  itemLink: ({ selected }: { selected: boolean }) => ({
    fontWeight: fontWeights.light,
    [mediaQueries.tb]: {
      ...(selected && {
        background: libColours.tertiaryFive,
        color: libColours.mlcAquaAccessible,
        borderBottom: `4px solid ${libColours.mlcAquaAccessible}`,
        fontWeight: fontWeights.semiBold,
      }),
      ...linkSpacing,
      '& > *': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      a: {
        fontSize: fontSize.sm,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: `${space(2, true)} ${space(2.5, true)}`,
      },
      padding: 0,
    },
  }),

  itemLinkShort: {
    '& > *': {
      fontSize: fontSize.xxs,
    },
  },

  navSubSection: {
    display: 'none',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    [mediaQueries.tb]: {
      display: 'flex',
    },
  },

  // just show on tablet
  navTabletSection: {
    display: 'none',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    [mediaQueries.md]: {
      display: 'flex',
    },

    [mediaQueries.tb]: {
      display: 'none',
    },
  },

  primaryDropdown: ({ selected }: { selected: boolean }) => ({
    [mediaQueries.lg]: {
      '&>button': {
        ...(selected && {
          background: libColours.tertiaryFive,
          color: libColours.mlcAquaAccessible,
          borderBottom: `4px solid ${libColours.mlcAquaAccessible}`,
          fontWeight: fontWeights.semiBold,
        }),
        '&:hover': {
          backgroundColor: 'inherit',
          color: libColours.mlcAquaAccessible,
        },
        '&:focus': {
          backgroundColor: libColours.mlcAquaAccessible,
          borderBottom: 'none',
          '&>span': {
            fontWeight: fontWeights.semiBold,
            color: libColours.tertiarySix,
          },
        },
        '&>span': {
          fontWeight: selected ? fontWeights.semiBold : fontWeights.light,
        },
      },
      // minWidth: 192,
    },
  }),

  profileIcon: {
    color: 'currentColor',
    marginRight: space(1),
  },

  profileNameText: {
    display: 'inline-flex',
    alignItems: 'baseline',
  },

  profileDropdpwn: {
    width: '64%',
    minWwidth: 120,
    maxWidth: 208,
    height: '100%',
  },

  quickAction: {
    paddingTop: space(2),
    paddingBottom: space(2),
    paddingRight: space(4),
  },

  quickActionButton: {
    paddingLeft: space(2),
    paddingRight: space(2),
  },
}

export default styles
