// @flow
import { Variables } from '@mlcl-digital/mlcl-design'
import { mediaQueriesMax } from '../../../styles'

const { colours, fontSize, space, shadows, fontWeights, mediaQueries, fontFamily } = Variables

const styles = {
  backButton: {
    margin: space(0),
    padding: space(0),
    background: 'transparent',
    border: space(0),
    color: colours.white,
    flex: '0 0 100%',
    textAlign: 'left',
    marginBottom: space(4),
    outline: 'none',

    '& svg': {
      color: colours.white,
    },
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',

    paddingBottom: space(2, true),
    justifyContent: 'space-between',
  },
  accordion: {
    flex: 1,
    padding: `${space(4, true)} ${space(6, true)}`,
    margin: `0 ${space(4, true)}`,
    backgroundColor: colours.white,
    boxShadow: shadows.low,
    alignSelf: 'flex-start',
  },
  accordionHeading: {
    fontSize: fontSize.xs,
    lineHeight: 1.5,
    marginBottom: 0,
  },
  accordionIcon: {
    color: colours.black,
  },
  accordionMailIcon: {
    marginRight: space(4),
  },
  accordionFieldset: {
    margin: 0,
    padding: `${space(4, true)} 0 ${space(2, true)}`,
    borderWidth: 0,
  },
  formSection: {
    width: '80%',
  },
  poliyContainer: {
    marginBottom: `${space(4, true)}`,
  },
  wrap: {
    width: '100%',
    [mediaQueries.md]: {
      width: '73%',
    },
  },
  offset: {
    marginTop: space(2),
    [mediaQueries.md]: {
      marginTop: space(3),
    },
  },
  section: {
    marginTop: space(6),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    fontSize: space(4),
    fontWeight: fontWeights.semiBold,
    color: colours.mediumGrey,
  },
  subHeading: {
    fontSize: fontSize.xs,
    color: colours.lightBlack,
  },
  notificationRowWrap: {
    marginTop: space(5),
    marginBottom: space(10),
  },
  spinner: {
    minHeight: 0,
    marginLeft: space(2, true),
  },
  applicationEditConfirmTitle: {
    marginBottom: space(2),
    fontWeight: fontWeights.bold,
  },
  applicationEditConfirmBody: {
    marginBottom: space(4),
    color: colours.black,
    fontFamily: fontFamily.charlie,
  },
  clientDetailsWrapper: {
    border: `solid 1px ${colours.tertiaryThree}`,
    borderTop: 0,
  },
  infoBox: {
    color: colours.mlcAquaAccessible,
    paddingLeft: space(4, true),
    [mediaQueriesMax.md]: { paddingLeft: space(2, true) },
  },
  infoBoxHeader: { fontWeight: fontWeights.semiBold, fontSize: fontSize.xsm },
  infoWrap: {
    marginTop: `${space(-4, true)}`,
    background: colours.tertiarySix,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colours.lightGrey,
    boxShadow: shadows.low,
    width: '100%',
    padding: `${space(2, true)} ${space(5, true)}`,
    maxWidth: `${space(144, true)}`,
    [mediaQueriesMax.md]: {
      width: '100%',
      height: '100%',
      padding: space(2),
      maxWidth: `${space(89, true)}`,
    },
  },
  infoContentWrap: { padding: space(1) },
  infoIcon: {
    color: colours.mlcAquaAccessible,
    fontSize: fontSize.smd,
    width: '25px',
    float: 'left',
    marginTop: space(1),
    paddingTop: space(1),
    [mediaQueriesMax.md]: {
      marginBottom: '-50px',
      marginLeft: '-15px',
      paddingLeft: space(1),
    },
  },
  infoBoxContent: { fontSize: fontSize.xsm },
}

export default styles
