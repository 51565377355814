// @flow
import React from 'react'

// components.
import AdvisorDashboard from '../../../organisms/AdvisorDashboard'

type AdvisorDashboardProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const Dashboard = ({ fields }: AdvisorDashboardProps) => <AdvisorDashboard fields={fields} />

export default Dashboard
