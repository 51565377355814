// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

// design.
import { Tooltip, Icons } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './paymentBreakupRow.styles'

type QuoteSummaryPaymentBreakupRowProps = {
  // To display premium breakup label
  name: string,
  // To display premium amount associated with label
  amount: number,
  // To display tooltip with amount field
  toolTip: string,
  // Unique ID to differentiate ID for tooltip
  uniqueId: string,
}
const { IconInfo16 } = Icons
const PaymentBreakupRowRoot = styled('div')(styles.paymentBreakupRowRoot)
const PaymentBreakupRowLabel = styled('div')(styles.paymentBreakupRowLabel)
const PaymentBreakupRowAmount = styled('div')(styles.paymentBreakupRowAmount)
const InfoIconContainer = styled('div')(styles.infoIconContainer)
const TooltipAmount = styled(Tooltip)(styles.tooltipAmount)
const InfoIcon = styled(IconInfo16)(styles.infoIcon)

/**
 * QuoteSummaryPaymentBreakupRow
 * This Component will render a row containing name of the breakup policy
 * (like stamp duty, total premium amount, etc) on left hand side and
 * amount associated with it on right hand side
 *
 * @param {string} name
 * @param {number} amount
 */
const QuoteSummaryPaymentBreakupRow = ({
  name,
  amount,
  toolTip = '',
  uniqueId = '',
}: QuoteSummaryPaymentBreakupRowProps) => (
  <Fragment>
    <PaymentBreakupRowRoot>
      <PaymentBreakupRowLabel>{name}</PaymentBreakupRowLabel>
      <PaymentBreakupRowAmount>
        {amount}
        {toolTip && (
          <InfoIconContainer>
            <div data-tip data-for={`amountToolTip${uniqueId}`} data-testid="paymentRowTooltip">
              <InfoIcon />
            </div>
            <TooltipAmount id={`amountToolTip${uniqueId}`} place="left">
              {toolTip}
            </TooltipAmount>
          </InfoIconContainer>
        )}
      </PaymentBreakupRowAmount>
    </PaymentBreakupRowRoot>
  </Fragment>
)

export default QuoteSummaryPaymentBreakupRow
