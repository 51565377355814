import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'

// @ts-expect-error non-ts-code
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { SitecoreFields } from '../../welcomePage.types'

import styles from './forgotUserNameConfirmation.styles'

type Props = {
  fields: SitecoreFields
  goBack: () => void
}

const ForgotUserNameConfirmation = (props: Props) => {
  const Wrapper = styled('div')(styles.wrapper)
  const Help = styled('div')(styles.help)

  const { fields, goBack } = props
  const { forgotUsernameConfirmationHelp } = fields

  return (
    <>
      <Wrapper>
        <Help>{renderTextField(forgotUsernameConfirmationHelp, true)}</Help>
      </Wrapper>
      <Button type="button" variant="primary" onClick={goBack}>
        Back to login
      </Button>
    </>
  )
}

export default ForgotUserNameConfirmation
