// @flow
import get from 'lodash/get'

// types
import { SITECORE_API } from '../types/sitecoreApi'
import { SITECORE_API_BASE } from '../../constants/apis'
import {
  ADVISOR_PREFERENCES_GET,
  ADVISOR_PREFERENCES_POST,
  ADVISOR_PREFERENCES_RESTORE,
  ADVISOR_PREFERENCES_UPDATE,
} from '../types/advisorPreferences'

import { getAllProducts } from './productRules'

// utilities
import { getBancsCustomerNumber } from '../../utils/cookieUtils'

export const getAdvisorPreferences =
  () =>
  (dispatch: Function): Object => {
    dispatch({
      type: ADVISOR_PREFERENCES_GET,
    })

    dispatch({
      type: SITECORE_API,
      name: ADVISOR_PREFERENCES_GET,
      verb: 'GET',
      route: `${SITECORE_API_BASE}/preferences?adviserCode=${getBancsCustomerNumber()}`,
      callback: (err, dataReceived) => dataReceived,
    })
  }

export const postAdvisorPreferences =
  (
    {
      adviserCode,
      productId,
      benefitDisplayName,
      benefitCode,
      inputbenefitType,
      premiumStructure,
      allowableCoverStyle,
      allowableTpdDefinition,
      allowableFeatures,
      allowableDefermentPeriod,
      allowableBenefitPeriod,
      remuneration,
      remunerationDiscount,
    },
    updateProducts
  ) =>
  (dispatch: Function): void => {
    // reset loading and error state.
    dispatch({
      type: ADVISOR_PREFERENCES_POST,
    })
    // dispatch action to trigger api call.
    dispatch({
      type: SITECORE_API,
      name: ADVISOR_PREFERENCES_POST,
      verb: 'POST',
      route: `${SITECORE_API_BASE}/preferences`,
      data: {
        adviserCode,
        productId,
        benefitDisplayName,
        benefitCode,
        inputbenefitType,
        allowableCoverStyle,
        allowableTpdDefinition,
        premiumStructure,
        allowableFeatures,
        allowableDefermentPeriod,
        allowableBenefitPeriod,
        remuneration,
        remunerationDiscount,
        actionType: 'POST',
      },
      callback: (err, dataReceived, requestPayload) => ({
        dataReceived,
        requestPayload,
      }),
      ...(updateProducts
        ? {
            queuedAction: () => getAllProducts(),
          }
        : null),
    })
  }

export const getAdvisorPreferencesRestore =
  adviserCode =>
  (dispatch: Function): void => {
    // reset loading and error state.
    dispatch({
      type: ADVISOR_PREFERENCES_RESTORE,
    })

    dispatch({
      type: SITECORE_API,
      name: ADVISOR_PREFERENCES_RESTORE,
      verb: 'POST',
      route: `${SITECORE_API_BASE}/preferences`,
      data: {
        adviserCode,
        actionType: 'DELETE',
      },
      callback: (err, dataReceived, requestPayload) => ({
        dataReceived,
        requestPayload,
      }),
    })
  }

export const updateAdvisorPreferences =
  (data: Array) =>
  (dispatch: Function, getState: Function): void => {
    let records = get(getState().preferences, 'preferences', [])
    records = records.map(preference => {
      if (preference.productId === data.productId) {
        return {
          ...preference,
          benefits: preference.benefits.map(benefit => {
            if (benefit.benefitCode === data.benefitCode) {
              return data
            }
            return benefit
          }),
        }
      }
      return preference
    })
    dispatch({
      type: ADVISOR_PREFERENCES_UPDATE,
      payload: {
        records,
      },
    })
  }
