// @flow
import React from 'react'
import Script from '../../atoms/Script'
import { walkMeConfig } from '../../../constants/footer'

type WalkmeProps = {
  fields: Object,
}

const callBack = () => {
  // eslint-disable-next-line no-underscore-dangle
  window._walkmeConfig = walkMeConfig
}

const Walkme = ({ fields }: WalkmeProps) => {
  const { walkmeScriptSrc } = fields
  if (walkmeScriptSrc && walkmeScriptSrc.value) {
    return <Script src={walkmeScriptSrc.value} loadedCallback={callBack} scriptId="walkMeScript" />
  }
  return null
}

export default Walkme
