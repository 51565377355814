import { space, fontSize, colours } from '../../../../../../styles'

const styles = {
  section: {
    padding: `${space(2, true)} ${space(3, true)}`,
  },
  preAssessmentCheckbox: {
    paddingBottom: space(1),
  },
  fieldSet: {
    paddingBottom: space(1),
  },
  button: {
    padding: 0,
    fontSize: fontSize.xs,
  },
  error: {
    color: colours.red,
    marginBottom: 0,
  },
  benefitTag: {
    marginLeft: space(1),
  },
  enquiryLines: {
    padding: space(3, true),
    borderTop: `1px solid ${colours.lightestGrey}`,
  },
  msgLine: {
    margin: 0,
    color: colours.black,
  },
  preAssessmentTitle: {
    fontSize: fontSize.md,
  },
  enquiryHeading: {
    fontSize: fontSize.xs,
    marginBottom: space(0.5),
  },
  enquiryRow(noMargin) {
    return {
      ...(!noMargin && { marginTop: space(2) }),
    }
  },
}
export default styles
