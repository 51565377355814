import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { pathOr } from 'lodash/fp'

// components
import { Heading } from '@mlcl-digital/mlcl-design'
import WithLoader from '../WithLoader'

// styles.
import styles from './tile.styles'

import history from '../../../utils/browserHistory'

const TabWrapper = styled('div')(styles.tabWrapper)
const Tabs = styled('div')(styles.tabs)
const TabContent = styled('div')(styles.tabContent)
const TabValue = styled(Heading)(styles.tabValue)
const TabTitle = styled('p')(styles.tabTitle)

const Tile = props => {
  const { schema, onClick, href, allowNavigation, activeTab, showLoader, showLoaderProps } = props
  const isLoading = pathOr(false, 'isLoading', showLoaderProps)

  const handleClick = id => onClick(id)

  const checkNavigation = id => {
    if (href) {
      return `${href}#${id}`
    }
    return `#${id}`
  }

  const renderTabValue = value => (
    <TabValue variant="h2" size="xlarge">
      {value}
    </TabValue>
  )

  const renderTabs = (label, id, value) => (
    <Tabs
      data-testid={`${id}-tile`}
      key={id}
      onClick={() => (allowNavigation ? history.push(checkNavigation(id)) : null)}
      isDisable={false}
      withBorder={id === activeTab}
    >
      <TabContent onClick={() => onClick && handleClick(id)}>
        {showLoader ? (
          <WithLoader isLoading={isLoading}>{renderTabValue(value)}</WithLoader>
        ) : (
          renderTabValue(value)
        )}
        <TabTitle>{label}</TabTitle>
      </TabContent>
    </Tabs>
  )

  return (
    <TabWrapper>{schema.map(({ label, id, value }) => renderTabs(label, id, value))}</TabWrapper>
  )
}

Tile.defaultProps = {
  onClick: null,
  activeTab: null,
}

Tile.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.object).isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  allowNavigation: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  showLoaderProps: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  activeTab: PropTypes.string,
}

export default Tile
export { Tile }
