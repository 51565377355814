// @flow
import get from 'lodash/get'

/** Helper function to fetch the bancsCustomerNo within a client detail
 * @return string : a bancsCustomerNo
 */
export const getBancsCustomerNo = (clientDetails: Object) => {
  const customerIdentifiers = get(clientDetails, 'relatedParty.identifiers', [])
  const bancsIdentifier = customerIdentifiers.find(
    identifier => identifier.type === 'BANCS_CUSTOMER_NO'
  )
  return bancsIdentifier && bancsIdentifier.value
}

export const getPartyType = relationship => get(relationship, 'relatedParty.partyType', '')
