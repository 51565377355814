// @flow
import React from 'react'
import styled from '@emotion/styled'

// atom
import { IconTag16 } from '../../../../atoms/Icons'

// styles.
import styles from './tag.styles'

// utils
import history from '../../../../../utils/browserHistory'
import { idMaker } from '../../../../../utils/formUtils'

// constant
import { ARTICLE_TAG_PAGE_URL } from '../../../../../constants/knowledgeBase'

type TagProps = {
  tags: Array,
  label: String,
  handleSetActiveArticleTag: Function,
}

const TagWrap = styled('div')(styles.tagWrap)
const TagHeading = styled('div')(styles.tagHeading)
const Title = styled('div')(styles.title)
const TagText = styled('div')(styles.tagText)
const ExploreTopicIcon = styled(IconTag16)(styles.icon)

const Tag = (props: TagProps) => {
  const { label, tags, handleSetActiveArticleTag } = props

  return (
    <TagWrap>
      <TagHeading>
        <ExploreTopicIcon />
        <Title>{label}</Title>
      </TagHeading>
      {tags &&
        tags.map(tag => (
          <TagText
            key={idMaker(tag)}
            onClick={() => {
              handleSetActiveArticleTag(tag)
              history.push(`/${ARTICLE_TAG_PAGE_URL}`)
            }}
          >
            {tag}
          </TagText>
        ))}
    </TagWrap>
  )
}

export default Tag
