// @flow
import React, { Fragment } from 'react'

// redux.
import { connect } from 'react-redux'

// components.
import MainFooter from './components/MainFooter'
import Walkme from '../../molecules/Walkme'

// constants.
import { FOOTER_MAIN, FOOTER_DISABLED, FOOTER_DECREASE } from '../../../constants/navigation'

type FooterProps = {
  // Sitecore fields
  fields: Object,
  // user updated data of Quote
  footerType: string,
  // page id
  pageId: string,
}

const Footer = (props: FooterProps) => {
  const { fields, footerType, pageId } = props
  switch (footerType) {
    case FOOTER_DISABLED:
      return null
    case FOOTER_DECREASE:
      return <MainFooter fields={fields} pageId={pageId} hideFooterLinks />
    case FOOTER_MAIN:
    default: {
      return (
        <Fragment>
          <MainFooter fields={fields} pageId={pageId} />
          <Walkme fields={fields} />
        </Fragment>
      )
    }
  }
}

const mapStateToProps = ({ navigation: { footerType } }) => ({
  footerType,
})

export default connect(mapStateToProps)(Footer)
