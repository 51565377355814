import { space, fontSize, colours, fontWeights } from '../../../styles'

const styles = {
  background: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
    background: [
      colours.darkGreen,
      `linear-gradient(transparent
        50%, ${colours.darkGreen} 50%)`,
    ],
  },
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '90%',
    transform: 'translate(5%,-50%)',
    background: colours.white,
    padding: `0 0 ${space(8, true)} 0`,
  },
  heading: {
    textAlign: 'center',
    fontSize: fontSize.md,
    color: colours.merlin,
    fontWeight: fontWeights.semiBold,
    margin: `${space(8, true)} 0 0 0`,
  },
  description: {
    textAlign: 'center',
    fontSize: fontSize.xs,
    margin: `${space(3, true)} 0 ${space(4, true)} 0`,
  },
  messageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  lockedIcon: {
    marginTop: space(8),
  },
}
export default styles
