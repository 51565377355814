// @flow
import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'

// redux.
import { connect } from 'react-redux'

// components.
import MainNav from './components/MainNavigation'
import MainNavUplift from './components/NewMainNavigation'
import QuoteNavigation from './components/QuoteNavigation'
import ApplicationNavigation from './components/ApplicationNavigation'
import AdviserRegistrationNavigation from './components/AdviserRegistrationNavigation'
import ChangeAdviserNav from './components/ChangeAdviserNav'
import DecreaseNavigation from './components/DecreaseNavigation'

// constants.
import {
  NAVIGATION_MAIN,
  NAVIGATION_QUOTE,
  NAVIGATION_DECREASE,
  NAVIGATION_DISABLED,
  NAVIGATION_APPLICATION_OVERVIEW,
  NAVIGATION_ONLY_LOGO,
} from '../../../constants/navigation'

import { CHANGE_ADVISER_NAV_EXCLUSION_ROUTES } from '../../../constants/routes'

// utils
import { isFeatureEnabled } from '../../../utils/featureToggling'

type NavigationProps = {
  // Sitecore fields
  fields: Object<Object>,
  // sitecore rendering
  rendering: Object,
  // user updated data of Quote
  navigationType: string,
  location: Object,
  // dashboard uplift feature toggle
  isDashboardUpliftEnabled: boolean,
}

const showChangeAdviserNav = (pathName: string) =>
  !CHANGE_ADVISER_NAV_EXCLUSION_ROUTES.includes(pathName)

const Navigation = (props: NavigationProps) => {
  const { fields, navigationType, location, rendering, isDashboardUpliftEnabled } = props

  const renderLogoWithNavbar = () => {
    switch (navigationType) {
      case NAVIGATION_DECREASE:
        return <DecreaseNavigation fields={fields} />
      case NAVIGATION_QUOTE:
        return <QuoteNavigation fields={fields} />
      case NAVIGATION_APPLICATION_OVERVIEW:
        return <ApplicationNavigation fields={fields} />
      case NAVIGATION_ONLY_LOGO: {
        return (
          <AdviserRegistrationNavigation
            hideNavigationItems
            fields={fields}
            rendering={rendering}
          />
        )
      }
      case NAVIGATION_DISABLED:
        return null
      case NAVIGATION_MAIN:
      default: {
        return isDashboardUpliftEnabled ? (
          <MainNavUplift fields={fields} rendering={rendering} />
        ) : (
          <MainNav fields={fields} rendering={rendering} />
        )
      }
    }
  }
  return (
    <Fragment>
      {showChangeAdviserNav(location.pathname) && <ChangeAdviserNav fields={fields} />}
      {renderLogoWithNavbar()}
    </Fragment>
  )
}

const mapStateToProps = ({ navigation: { navigationType }, config }) => ({
  navigationType,
  isDashboardUpliftEnabled: isFeatureEnabled('AdvisorDashboardUplift', config.FEATURES),
})

export default connect(mapStateToProps)(withRouter(Navigation))
