// @flow
import {
  RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_INIT,
  RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_SUCCESS,
  RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_FAILURE,
  SAVE_ACTIVE_SUPPORT_STAFF_DATA,
  SAVE_ACTIVE_ADVISER_DATA,
  RESET_ADVISOR_SUPPORT_STAFF,
} from '../actions/types/supportStaff'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  advisorList: {
    isFetching: false,
    data: {},
  },
  activeSupportStaff: {},
  activeAdviser: {},
}

const supportStaffReducer = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case OKTA_RESET_AUTHENTICATION_STATE:
    case RESET_ADVISOR_SUPPORT_STAFF: {
      return initialState
    }
    case RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_INIT: {
      return {
        ...state,
        advisorList: {
          isFetching: true,
          data: {},
        },
      }
    }

    case RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        advisorList: {
          isFetching: false,
          data,
        },
      }
    }

    case RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_FAILURE: {
      return {
        ...state,
        advisorList: {
          isFetching: false,
          data: {},
        },
      }
    }

    case SAVE_ACTIVE_SUPPORT_STAFF_DATA: {
      const { data } = action.payload
      return {
        ...state,
        activeSupportStaff: {
          ...data,
        },
      }
    }

    case SAVE_ACTIVE_ADVISER_DATA: {
      const { data } = action.payload
      return {
        ...state,
        activeAdviser: {
          ...data,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default supportStaffReducer
