import { colours, space, fontSize } from '../../../../../../../../styles'

const styles = {
  optionsButton: {
    padding: 0,
    height: space(4),
    fontSize: fontSize.xxs,
    color: colours.white,
    '&:hover': {
      color: colours.lightGreen,
    },
  },
  optionsButtonActive: {
    color: colours.lightGreen,
  },
  popoverMenu: {
    top: space(5),
    right: space(2.5),
  },
}

export default styles
