// @flow
import React, { Fragment, PureComponent } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { toast } from 'react-toastify'
import { Button, PageWrap, SectionHeader } from '@mlcl-digital/mlcl-design'
import { pathOr } from 'lodash/fp'
import Icon from '@mlcl-digital/mlcl-design/lib/src/components/atoms/FontAwesomeIcon'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { createEvent } from '../../../utils/telemetry'
import history from '../../../utils/browserHistory'
import { actionCreators } from '../../../actions'

// atoms
import TagComponent from '../../atoms/Tag'

// molecules
import Modal from '../../molecules/Modal'
import PageHeader from '../../molecules/PageHeader'
import TasksListComponent from '../../molecules/TasksList'

// components
import ClientDetails from './components/ClientDetails'
import PolicyDetailsComponent from '../ApplicationPolicyDetails'
import ActivityComponent from './components/Activity'
import ApplicationStatusComponent from './components/ApplicationStatus'
import TaskCard from './components/TaskCard'
import Notification from '../../molecules/TasksList/components/TaskRow'
import ApplicationDetails from './components/ApplicationDetails'

// style
import styles from './details.styles'

// constants
import {
  REC_OF_APPS_DOC_CODE,
  QUOTE_STATUS_APPLICATION_STAGE,
  QUOTE_STATUS_SUBMITTED_TO_BANCS_STAGE,
  POLICY_RELATIONSHIPS_LIFEASSURED,
} from '../../../constants/policies'
import {
  TOAST_ROA_DOWNLOAD_ERROR_POLICY_MSG,
  TOAST_ROA_DOWNLOAD_ERROR_POLICY_ID,
} from '../../../constants/toast'
import {
  APPLICATION_PATH,
  RESULTS_PAGE,
  TELE,
  MYLINK,
  TERM,
  COMPLETE_MYLINK,
  COMPLETE_TELE,
  NOTIFICATION_UW,
  NOTIFICATION_NB,
  PROCESSING,
  SAVE_DRAFT_APPLICATION,
  ADV_F2F,
} from '../../../constants/application'

// utils
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
import { getProcessingStatusFromAction } from '../../../utils/commonUtils'
import { downloadDocument } from '../../../utils/downloadDocumentUtils'
import { getActiveQuote, shouldShowLifeInsuredDetails } from '../../../utils/quoteUtils'
import { CALCULATED_URE_TERM } from '../../../constants/ure'
import { idMaker } from '../../../utils/formUtils'
import SeriesCodeChangedAlertComponent from '../../molecules/SeriesCodeChangedAlert'
import { hasSeriesCodeExpired, isNotSeriesSpecified } from '../../../utils/extendedQuoteUtils'

const Container = styled('div')(styles.container)
const Wrap = styled('div')(styles.wrap)
const Wrapper = styled(PageWrap)(styles.offset)
const NotificationRowWrap = styled('div')(styles.notificationRowWrap)
const ApplicationEditConfirmTitle = styled('h2')(styles.applicationEditConfirmTitle)
const ApplicationEditConfirmBody = styled('div')(styles.applicationEditConfirmBody)
const ClientDetailsWrapper = styled('div')(styles.clientDetailsWrapper)
const InfoBox = styled('div')(styles.infoBox)
const InfoContentWrap = styled('div')(styles.infoContentWrap)
const InfoWrap = styled(PageWrap)(styles.infoWrap)
const InfoIcon = styled(Icon)(styles.infoIcon)
const InfoBoxHeader = styled('div')(styles.infoBoxHeader)
const InfoBoxContent = styled('div')(styles.infoBoxContent)
type SubmittedApplicationPropTypes = {
  // Sitecore fields
  fields: Object,
  // Life insured details
  lifeInsuredDetails: Object,
  quoteData: Object,
  actions: Object,
  // env constant config
  config: Object,
  correspondenceHistory: Object,
  productRules: Object,
  // Master data from Sitecore
  masterData: Object,
}

export class SubmittedApplicationComponent extends PureComponent<SubmittedApplicationPropTypes> {
  constructor(props: Object) {
    super(props)
    this.state = {
      applicationEditConfirmModalIsOpen: false,
      applicationDownloadErrorModal: false,
    }
    const {
      actions: { resetNavigationType },
    } = props
    // setting navigation to main navigation(dashboard) as application is submitted
    resetNavigationType()
  }

  componentDidMount() {
    const {
      actions: { setActiveEnquiryId, getPayloadForEnquiryId, listAllEnquiryIds, getMasterList },
      quoteData,
    } = this.props
    if (
      get(getActiveQuote(quoteData), 'underwritingDetails.calculatedUREStatus') ===
      CALCULATED_URE_TERM
    ) {
      const enquiryId = get(getActiveQuote(quoteData), 'underwritingDetails.enquiryId')
      getMasterList()
      setActiveEnquiryId(enquiryId)
      getPayloadForEnquiryId(enquiryId)
      listAllEnquiryIds()
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Application landing',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Application landing - View',
        },
      },
    })
    tagEvent.end()
  }

  editHandler = () => {
    const {
      actions: { updateQuoteStatus },
    } = this.props
    const quoteStatusPayload = {
      actionName: SAVE_DRAFT_APPLICATION,
      quoteType: QUOTE_STATUS_APPLICATION_STAGE,
      underwritingMethod: ADV_F2F,
    }
    updateQuoteStatus(quoteStatusPayload)
    this.setState({ applicationEditConfirmModalIsOpen: false })
    this.editApplication()
  }

  editApplication = () => {
    const {
      actions: { saveQuote },
    } = this.props
    saveQuote(() => {
      const tagEvent = createEvent({
        GA: {
          category: 'Application landing',
          action: 'Edit',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Application landing - Edit',
          },
        },
      })
      tagEvent.end()
      history.push(`${APPLICATION_PATH}?fromApplicationSummary=true`)
    })
  }

  downloadHandler = () => {
    const {
      quoteData: { quoteCollectionId },
      actions: { getCorrespondenceHistory },
      config,
    } = this.props

    if (quoteCollectionId) {
      this.setState({ applicationDownloadErrorModal: false })
      getCorrespondenceHistory(
        {
          agreementNumber: quoteCollectionId,
          channel: 'Outbound',
        },
        (error, data) => {
          const documents = data && data.documents ? data.documents : []
          if (documents.length) {
            const recordOfAppsId = documents.find(doc => doc.docTypeCode === REC_OF_APPS_DOC_CODE)
            const externalId = recordOfAppsId && recordOfAppsId.externalId
            if (externalId) {
              downloadDocument({}, 'GET', `/documents/${externalId}`, config)
            } else {
              this.setState({ applicationDownloadErrorModal: true })
            }
          } else {
            this.setState({ applicationDownloadErrorModal: true })
          }
        }
      )
    } else {
      toast(TOAST_ROA_DOWNLOAD_ERROR_POLICY_MSG, {
        toastId: TOAST_ROA_DOWNLOAD_ERROR_POLICY_ID,
        type: toast.TYPE.ERROR,
      })
    }
  }

  handleTaskActionButton = () => {
    history.push(RESULTS_PAGE)
  }

  isPersonalStatementDone = () => {
    const { quoteData } = this.props
    const underwritingDetails = get(getActiveQuote(quoteData), 'underwritingDetails', {})

    // checking personal statement completed
    if (underwritingDetails && underwritingDetails.isSatisfied !== 'undefined') {
      return underwritingDetails.isSatisfied
    }
    return false
  }

  isShowTaskCard = () => {
    const { quoteData } = this.props
    const underwritingDetails = get(getActiveQuote(quoteData), 'underwritingDetails', {})
    const calculatedUREStatus = get(underwritingDetails, 'calculatedUREStatus', '')
    const underwritingMethod = get(underwritingDetails, 'underwritingMethod', '')
    if (this.isPersonalStatementDone() && calculatedUREStatus === TERM) {
      if (
        (underwritingMethod === MYLINK && quoteData.action === COMPLETE_MYLINK) ||
        (underwritingMethod === TELE && quoteData.action === COMPLETE_TELE)
      )
        return true
    }

    return false
  }

  handleFilterNotification = () => {
    const { quoteData } = this.props
    return get(quoteData, 'outstandingRequirements', [])
  }

  renderNotifications = () =>
    this.handleFilterNotification().map(notification => {
      const { ID, dateRaised, status, type, description, comment, category } = notification
      if (category === NOTIFICATION_UW || category === NOTIFICATION_NB) {
        return (
          <Notification
            key={idMaker(ID)}
            date={dateRaised}
            status={status}
            title={type}
            description={description}
            comment={comment}
            hideActionButton
            hideStatus
            showDescription
            isInvertColorTheme
            isPaddingBottom
          />
        )
      }
      return null
    })

  applicationEditConfirmModal = () => {
    const { applicationEditConfirmModalIsOpen } = this.state
    const { fields } = this.props
    const {
      applicationEditConfirmModalTitleCopy,
      applicationEditConfirmModalBodyCopy,
      applicationEditConfirmModalButtonCopy,
    } = reduceAuthorableFields(fields)
    return (
      <Modal
        isOpen={applicationEditConfirmModalIsOpen}
        onClose={() => this.setState({ applicationEditConfirmModalIsOpen: false })}
      >
        <ApplicationEditConfirmTitle>
          {applicationEditConfirmModalTitleCopy}
        </ApplicationEditConfirmTitle>
        <ApplicationEditConfirmBody>
          {applicationEditConfirmModalBodyCopy}
        </ApplicationEditConfirmBody>
        <Button type="secondary" onClick={this.editHandler}>
          {applicationEditConfirmModalButtonCopy}
        </Button>
      </Modal>
    )
  }

  applicationDownloadErrorModal = () => {
    const { applicationDownloadErrorModal } = this.state
    const { fields } = this.props
    const { applicationDownloadErrorModalBodyCopy, applicationEditConfirmModalButtonCopy } =
      reduceAuthorableFields(fields)
    return (
      <Modal
        isOpen={applicationDownloadErrorModal}
        onClose={() => this.setState({ applicationDownloadErrorModal: false })}
      >
        <ApplicationEditConfirmBody>
          {applicationDownloadErrorModalBodyCopy}
        </ApplicationEditConfirmBody>
        <Button type="secondary" onClick={this.downloadHandler}>
          {applicationEditConfirmModalButtonCopy}
        </Button>
      </Modal>
    )
  }

  render() {
    const {
      fields,
      lifeInsuredDetails,
      quoteData,
      correspondenceHistory,
      productRules,
      masterData,
    } = this.props
    const firstName = get(lifeInsuredDetails, 'relatedParty.firstName', '')
    const lastName = get(lifeInsuredDetails, 'relatedParty.lastName', '')
    const isOutstandingRequirementAvailable =
      get(quoteData, 'outstandingRequirements', []).length >= 1
    const {
      taskDashboardTitle,
      taskDashboardSubHeading,
      taskCardButtonText,
      taskCardTitle,
      notificationTitle,
      notificationSubHeading,
      submittedApplicationTitle,
      outstandingRequirementModalHeading,
      outstandingRequirementModalConfirmButton,
      outstandingRequirementModalSubHeading,
      outstandingRequirementMaxFileSizeError,
      outstandingRequirementMaxFileSize,
      outstandingRequirementMaxFileCount,
      outstandingRequirementWrongMimeType,
      outstandingRequirementUploading,
      outstandingRequirementUploadSuccess,
      outstandingRequirementUploadFailure,
      outstandingRequirementFileRemoveLabel,
      outstandingRequirementFormWorkItemFailure,
      outstandingRequirementFormWorkItemSuccess,
      outstandingRequirementNoFileError,
      taskDashboardActionButtonText,
      submittedApplicationDigitalApplication,
      infoBoxHeader,
      infoBoxContent,
    } = reduceAuthorableFields(fields)

    const { editApplication, downloadApplication, submittedApplicationSubTitle } = fields

    const isManualQuote = get(quoteData, 'manualQuoted', false)

    const { quotes, activeIndex, isFetchingData, quoteCollectionId, quoteCollectionName, action } =
      quoteData

    const quote = quotes[activeIndex]
    const products = productRules.data
    const { memberMandatories } = quotes[activeIndex]

    const underwritingDetails = getActiveQuote(quoteData)?.underwritingDetails ?? {}
    const underwritingMethod = underwritingDetails?.underwritingMethod ?? ''
    let changeInSeriesCode = false

    const filterSeriesChangeModalForTelMylink = () =>
      !(action === COMPLETE_TELE || action === COMPLETE_MYLINK) &&
      quote?.underwritingDetails?.calculatedUREStatus !== TERM

    if (
      !isFetchingData &&
      !productRules.isFetching &&
      this.isShowTaskCard() &&
      isNotSeriesSpecified(5, quote) &&
      hasSeriesCodeExpired(quote, products) &&
      filterSeriesChangeModalForTelMylink()
    ) {
      changeInSeriesCode = true
    }

    const fieldsForTaskList = {
      modalHeading: outstandingRequirementModalHeading,
      modalConfirmButton: outstandingRequirementModalConfirmButton,
      modalSubHeading: outstandingRequirementModalSubHeading,
      maxFileSizeError: outstandingRequirementMaxFileSizeError,
      maxFileSize: outstandingRequirementMaxFileSize,
      maxFileNumber: outstandingRequirementMaxFileCount,
      wrongFileType: outstandingRequirementWrongMimeType,
      fileUploading: outstandingRequirementUploading,
      fileUploadSuccess: outstandingRequirementUploadSuccess,
      fileUploadFailure: outstandingRequirementUploadFailure,
      fileRemoveButtonLabel: outstandingRequirementFileRemoveLabel,
      createWorkItemError: outstandingRequirementFormWorkItemFailure,
      createWorkItemSuccess: outstandingRequirementFormWorkItemSuccess,
      noFileUploadedError: outstandingRequirementNoFileError,
      wrongFileNameError: '',
      actionButtonText: taskDashboardActionButtonText,
      freeTextPlaceholder: 'Type your response here',
    }

    return (
      <Fragment>
        <PageHeader
          heading={{ value: `${submittedApplicationTitle}: ${quoteCollectionId}` }}
          subHeading={submittedApplicationSubTitle}
          {...(!isManualQuote && {
            subComponent: (
              <span>
                <TagComponent type="primary">
                  {renderTextField(submittedApplicationDigitalApplication)}
                </TagComponent>
              </span>
            ),
            isTag: true,
          })}
        >
          {!isManualQuote ? (
            <div>
              {quoteData &&
              quoteData.action &&
              getProcessingStatusFromAction(quoteData.action) === PROCESSING &&
              !quoteData.isNonStandard ? (
                <span className="display-none lg-display-block">
                  <Button
                    onClick={this.downloadHandler}
                    type="whiteWithBorder"
                    disabled={correspondenceHistory.isSending}
                    isLoading={correspondenceHistory.isSending}
                  >
                    {renderTextField(downloadApplication)}
                  </Button>
                </span>
              ) : (
                <span className="display-none lg-display-block">
                  <Button
                    onClick={() => this.setState({ applicationEditConfirmModalIsOpen: true })}
                    type="whiteWithBorder"
                  >
                    {renderTextField(editApplication)}
                  </Button>
                </span>
              )}
            </div>
          ) : null}
        </PageHeader>
        {quoteData?.action === TELE && underwritingMethod === TELE ? (
          <InfoWrap>
            <InfoIcon iconName="far fa-phone" />
            <InfoContentWrap>
              <InfoBox>
                <InfoBoxHeader>{infoBoxHeader}</InfoBoxHeader>
                <InfoBoxContent>{<RichText field={{ value: infoBoxContent }} />}</InfoBoxContent>
              </InfoBox>
            </InfoContentWrap>
          </InfoWrap>
        ) : null}

        <Wrapper>
          <Container>
            <Wrap>
              <ApplicationStatusComponent
                fields={reduceAuthorableFields(fields)}
                fromApplicationSummary
              />
              {this.isShowTaskCard() ? (
                <TaskCard
                  title={`${firstName} ${lastName} ${taskCardTitle}`}
                  buttonlabel={taskCardButtonText}
                  handleTaskActionButton={this.handleTaskActionButton}
                />
              ) : null}
              <ApplicationDetails fields={fields} quoteData={quoteData} />
              {lifeInsuredDetails && shouldShowLifeInsuredDetails(quoteCollectionName) && (
                <ClientDetailsWrapper>
                  <ClientDetails
                    masterData={masterData}
                    fields={fields}
                    lifeInsuredDetails={lifeInsuredDetails}
                    memberMandatories={memberMandatories}
                  />
                </ClientDetailsWrapper>
              )}
            </Wrap>
            <ActivityComponent
              fields={reduceAuthorableFields(fields)}
              assignBackToAdviser={() => this.setState({ applicationEditConfirmModalIsOpen: true })}
              fromApplicationSummary
            />
          </Container>

          {get(quoteData, 'quotes.length', 0) && (
            <PolicyDetailsComponent
              fields={reduceAuthorableFields(fields)}
              fromApplicationSummary
            />
          )}
          {isOutstandingRequirementAvailable && (
            <Fragment>
              <SectionHeader heading={taskDashboardTitle} subHeading={taskDashboardSubHeading} />
              <TasksListComponent fields={fieldsForTaskList} isSingleApplication />
            </Fragment>
          )}
          {this.handleFilterNotification().length > 0 && (
            <>
              <SectionHeader heading={notificationTitle} subHeading={notificationSubHeading} />
              <NotificationRowWrap>{this.renderNotifications()}</NotificationRowWrap>
            </>
          )}
        </Wrapper>
        {this.applicationEditConfirmModal()}
        {this.applicationDownloadErrorModal()}
        <SeriesCodeChangedAlertComponent
          fields={fields}
          isOpen={changeInSeriesCode}
          shouldRedirect
        />
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  advisor,
  applicationSummary,
  ure,
  config,
  correspondenceHistory,
  productRules,
  masterList,
}) => {
  let lifeInsuredDetails = get(applicationSummary, 'lifeInsuredDetails', undefined)
  if (lifeInsuredDetails) {
    lifeInsuredDetails = { relatedParty: lifeInsuredDetails }
  }
  if (lifeInsuredDetails === undefined) {
    // TODO: Revisit this for the quote specific identifier
    let targetedQuoteIndex = applicationSummary.quotes.findIndex(
      quote =>
        quote.type === QUOTE_STATUS_APPLICATION_STAGE ||
        quote.type === QUOTE_STATUS_SUBMITTED_TO_BANCS_STAGE
    )
    targetedQuoteIndex =
      targetedQuoteIndex === -1 ? applicationSummary.activeIndex : targetedQuoteIndex
    const relationships = pathOr(
      [],
      `quotes[${targetedQuoteIndex}].policyStructure[0].relationships`,
      applicationSummary
    )
    lifeInsuredDetails = relationships.filter(
      relationship => relationship.role.indexOf(POLICY_RELATIONSHIPS_LIFEASSURED) > -1
    )[0]
  }
  return {
    lifeInsuredDetails: lifeInsuredDetails || undefined,
    advisor,
    quoteData: applicationSummary,
    ure,
    config,
    correspondenceHistory,
    productRules,
    masterData: get(masterList, 'data', []),
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedApplicationComponent)
