// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'

// components.
import { Input } from '@mlcl-digital/mlcl-design'
import ForgotPasswordCta from '../../../molecules/FieldCaptionCta'

// schemas.
import { PASSWORD_FIELD_KEY, USERNAME_FIELD_KEY } from '../Schema/login.schema'

// styles
import styles from './passwordForm.styles'

const Wrapper = styled('div')(styles.wrapper)

type PasswordFormProps = {
  // sitecore fields.
  fields: Object<Object>,
  // handle input change
  handleChange: Function,
  // redux form
  formFields: Object<Object>,
  // component schema
  components: Object,
}

class PasswordForm extends PureComponent<PasswordFormProps> {
  forgetPasswordCtaHandler = (event: Object) => {
    event.preventDefault()
    const {
      components: { handleForgotPassword },
    } = this.props
    handleForgotPassword()
  }

  render() {
    const {
      fields: {
        passwordPlaceholder: { value: passwordPlaceholder },
        passwordTooltip: { value: passwordTooltip },
      },
      handleChange,
      formFields,
    } = this.props

    const forgotPasswordCta = (
      <ForgotPasswordCta type="link" onClick={this.forgetPasswordCtaHandler}>
        {passwordTooltip}
      </ForgotPasswordCta>
    )

    return (
      <Fragment>
        <Wrapper>
          <Input
            name={USERNAME_FIELD_KEY}
            type="text"
            value={(formFields[USERNAME_FIELD_KEY] && formFields[USERNAME_FIELD_KEY].value) || ''}
            autoComplete="on"
            disabled
          />
        </Wrapper>
        <Input
          htmlFor={PASSWORD_FIELD_KEY}
          name={PASSWORD_FIELD_KEY}
          type="password"
          changeHandler={handleChange}
          placeholder={passwordPlaceholder}
          caption={forgotPasswordCta}
          value={(formFields[PASSWORD_FIELD_KEY] && formFields[PASSWORD_FIELD_KEY].value) || ''}
          autoComplete="password"
          required
        />
      </Fragment>
    )
  }
}

export default PasswordForm
