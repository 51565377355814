// @flow
import React from 'react'
// components
import WelcomeJourneySlider from '../../../molecules/WelcomeJourneySlider'

const WelcomeJourney = (props: WelcomeJourneyProps) => {
  const {
    authentication: { hasCompletedWelcomeJourney },
  } = props
  return !hasCompletedWelcomeJourney ? <WelcomeJourneySlider {...props} /> : null
}

export default WelcomeJourney
