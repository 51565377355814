import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

// components
import { PageWrap, Heading } from '@mlcl-digital/mlcl-design'
import ActionLink from '@mlcl-digital/mlcl-design/lib/base/ActionLink'

// utils
// @ts-expect-error file not in typescript
import history from '../../../../utils/browserHistory'
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'

// types
import { AltsInflationProofingProps } from '../../../../types/components/InflationProofing'

// selectors
import { getTimeline } from '../../../../selectors/common.selectors'

// styles
import styles from './altsInflationProofing.styles'

// constants
import {
  INFLATION_PROOFING_CONTACT_DETAILS_PAGE,
  INFLATION_PROOFING_DECLARATION_PAGE,
  INFLATION_PROOFING_QUOTE_PAGE,
} from '../../../../constants/alterations'

const ComponentWrapper = styled('div')(styles.componentWrapper)
const BackToHomeCTA = styled(ActionLink)(styles.backToHomeCTA)
const StyledHeading = styled(Heading)(styles.heading)

const AltsInflationProofing = ({ fields, rendering }: AltsInflationProofingProps) => {
  const timelineWithComponentsState = useSelector(getTimeline)
  const handleBackToHome = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.push(fields.BackToHomeLink?.value?.href)
  }, [])

  const componentHeading: Record<string, string> = {
    [INFLATION_PROOFING_QUOTE_PAGE]: fields?.QuoteStepHeading.value,
    [INFLATION_PROOFING_CONTACT_DETAILS_PAGE]: fields?.ContactDetailsStepHeading.value,
    [INFLATION_PROOFING_DECLARATION_PAGE]: fields?.DeclarationStepHeading.value,
  }

  return (
    <>
      <Placeholder name="page-header" rendering={rendering} />
      <ComponentWrapper>
        <PageWrap>
          <BackToHomeCTA
            onClick={handleBackToHome}
            label={fields.BackToHomeLink?.value?.text}
            iconName={['far', 'chevron-left']}
          />
          <StyledHeading variant="h2" size="large">
            {renderTextField(componentHeading[timelineWithComponentsState.activeComponent])}
          </StyledHeading>
        </PageWrap>
        <Placeholder name="header-bar" rendering={rendering} variant="horizontal" />
        <PageWrap>
          <Placeholder name={timelineWithComponentsState.activeComponent} rendering={rendering} />
        </PageWrap>
      </ComponentWrapper>
    </>
  )
}

export default AltsInflationProofing
