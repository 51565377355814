// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { pathOr } from 'lodash/fp'
import get from 'lodash/get'
import { renderTextField, reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'

// Components
import { TwoColumnLayout } from '../ExistingPolicy'
import ButtonComponent from '../../../../../atoms/Button'
import Accordion from '../../../../../molecules/Accordion'
import Select from '../../../../../atoms/Select'
// eslint-disable-next-line import/no-named-as-default
import Input from '../../../../../atoms/Input'
import { IconChevronDown16, IconChevronUp16 } from '../../../../../atoms/Icons'
import RadioGroup from '../../../../../molecules/RadioGroup'

// Constants
import { MLC_INSURANCE, yearPast } from '../existingCover.schema'

// Utils
import { checkCondition } from '../../../../../../utils/formUtils'
import { isIPCover } from '../../../../../../utils/quoteUtils'

// Styles
import styles from '../existingCover.styles'

const BenefitAccordion = styled(Accordion)(styles.benefitAccordion)
const SmallButton = styled(ButtonComponent)(styles.smallButton)
const IconChevronDownBlack16 = styled(IconChevronDown16)(styles.icons)
const IconChevronUpBlack16 = styled(IconChevronUp16)(styles.icons)
const FeaturesContainer = styled('div')(styles.featuresContainer)
const FeatureTitle = styled('div')(styles.featureTitle)
const CoverDetailsContainer = styled('div')(styles.coverDetailsContainer)
const StartDateContainer = styled('div')(styles.startDateContainer)
const WaitingPeriodContainer = styled('div')(styles.waitingPeriodContainer)
const BenefitPeriodContainer = styled('div')(styles.benefitPeriodContainer)
const SumInsuredLabel = styled('div')(styles.sumInsuredLabel)

const getCurrentBenefit = (benefits, benefitCode) =>
  benefits.filter(benefit => benefit.benefitCode === benefitCode)[0]

type ExistingBenefitsProps = {
  policyIndex: Number,
  fields: Object,
  renderErrorMessage: Function,
  benefitsData: Array,
  benefit: Object,
  benefitIndex: number,
  currentBenefits: Array,
  updateBenefits: Function,
}

export class Benefits extends Component<ExistingBenefitsProps> {
  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleExistingCoverChange = this.handleExistingCoverChange.bind(this)
    this.handleSelectFeatures = this.handleSelectFeatures.bind(this)
    this.handleFeatureOptions = this.handleFeatureOptions.bind(this)
    this.removeBenefit = this.removeBenefit.bind(this)
    const {
      sumInsured: { value: sumInsured },
    } = props.benefit
    this.state = {
      isOpen: !sumInsured,
      datePastError: {},
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { benefit, benefitIndex, benefitsData, insurer, updateBenefits } = props
    const { currentInsurer } = state
    if (insurer !== currentInsurer) {
      if (insurer !== MLC_INSURANCE) {
        delete benefit.allowableFeatures
        updateBenefits(benefit, benefitIndex)
        return { currentInsurer: insurer }
      }
      if (!benefit.allowableFeatures) {
        const currentBenefit = getCurrentBenefit(benefitsData, benefit.benefitCode.value)
        benefit.allowableFeatures = { value: currentBenefit.allowableFeatures, error: false }
        updateBenefits(benefit, benefitIndex)
      }

      return { currentInsurer: insurer }
    }
    return {}
  }

  settingDropdownOptions = dropDownValues =>
    dropDownValues &&
    dropDownValues
      .map(dropDownValue => ({ ...dropDownValue, label: dropDownValue.displayValue }))
      .filter(dropDownValue => dropDownValue.displayValue !== '')

  toggleListener = state => {
    this.setState({ isOpen: state })
  }

  handleSelectFeatures(event) {
    const { name, value } = event
    const { benefit, benefitIndex, updateBenefits } = this.props
    value.selected = true
    benefit[name].value = value
    updateBenefits(benefit, benefitIndex)
  }

  removeBenefit() {
    const { benefitIndex, updateBenefits, currentBenefits } = this.props
    const updatedBenefits = currentBenefits.filter((benefit, index) => index !== benefitIndex)
    updateBenefits(updatedBenefits, benefitIndex, true)
  }

  handleFeatureOptions(feature, index, value) {
    const { benefit, benefitIndex, updateBenefits } = this.props
    benefit.allowableFeatures.value[index] = { ...feature, selected: value }
    updateBenefits(benefit, benefitIndex)
  }

  handleInputChange(value) {
    const { name } = value
    const {
      benefit,
      benefitIndex,
      fields: { existingCoverStartDateIncorrectError },
      updateBenefits,
    } = this.props
    const { existingCoverStartDateIncorrectError: existingCoverStartdateIncorrectError } =
      reduceAuthorableFields({
        existingCoverStartDateIncorrectError,
      })
    if (name === 'startdate') {
      this.setState({
        datePastError: checkCondition(value.value, yearPast, existingCoverStartdateIncorrectError),
      })
    }
    benefit[name] = {
      value: name === 'startdate' ? value.value : value.value && Number(value.value).toFixed(),
      error: '',
    }
    updateBenefits(benefit, benefitIndex)
  }

  handleExistingCoverChange(name, value) {
    const { benefit, benefitIndex, updateBenefits } = this.props
    benefit.replacingThisCover = {
      value,
      error: '',
    }
    updateBenefits(benefit, benefitIndex)
  }

  renderSumInsuredFields = () => {
    const {
      fields: {
        existingCoverSumInsuredLabel,
        sumInsuredMandatoryError,
        SumInsuredExcludingSuper,
        SumInsuredSuper,
      },
      benefit: { sumInsured = {}, superGuaranteeSumInsured = {}, benefitCode },
    } = this.props
    const isSumInsuredValid = pathOr(false, 'error.error', sumInsured)
    const isSumInsuredSuperValid = pathOr(false, 'error.error', superGuaranteeSumInsured)
    const isIncomeAssureBenefit = isIPCover({ type: benefitCode.value })
    return (
      <>
        {isIncomeAssureBenefit && (
          <SumInsuredLabel>{renderTextField(existingCoverSumInsuredLabel)}</SumInsuredLabel>
        )}
        <TwoColumnLayout>
          <Input
            htmlFor="sumInsured"
            label={renderTextField(
              isIncomeAssureBenefit ? SumInsuredExcludingSuper : existingCoverSumInsuredLabel
            )}
            type="text"
            name="sumInsured"
            changeHandler={value => {
              this.handleInputChange(value)
            }}
            value={sumInsured.value}
            error={isSumInsuredValid}
            prefix="$"
            options={{
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
              numeralPositiveOnly: true,
            }}
            caption={isSumInsuredValid && renderTextField(sumInsuredMandatoryError)}
          />
          {isIncomeAssureBenefit && (
            <Input
              htmlFor="superGuaranteeSumInsured"
              label={renderTextField(SumInsuredSuper)}
              type="text"
              name="superGuaranteeSumInsured"
              changeHandler={value => {
                this.handleInputChange(value)
              }}
              value={superGuaranteeSumInsured.value}
              error={isSumInsuredSuperValid}
              prefix="$"
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralPositiveOnly: true,
              }}
              caption={isSumInsuredSuperValid && renderTextField(sumInsuredMandatoryError)}
            />
          )}
        </TwoColumnLayout>
      </>
    )
  }

  renderCoverDetailsField = () => {
    const {
      fields: {
        existingCoverStartDateLabel,
        existingCoverStartDatePlaceholder,
        existingCoverWaitingPeriodLabel,
        existingCoverBenefitPeriodLabel,
      },
      benefit: { benefitCode, startdate = {}, benefitPeriod, waitingPeriod },
      benefitsData,
      policyIndex,
      benefitIndex,
    } = this.props
    const { datePastError } = this.state
    const { existingCoverStartDatePlaceholder: existingCoverStartDatePlaceHolder } =
      reduceAuthorableFields({
        existingCoverStartDatePlaceholder,
      })
    const isStartDateValid = pathOr(false, 'error.error', startdate)
    const startDateErrorMsg = pathOr(null, 'error.errorMsg', startdate)
    const currentBenefit = getCurrentBenefit(benefitsData, benefitCode.value)
    const isOtherFields = pathOr(0, 'allowableDefermentPeriod.length', currentBenefit) > 0
    return (
      <CoverDetailsContainer>
        <StartDateContainer isOtherFields={isOtherFields}>
          <Input
            htmlFor="startdate"
            name="startdate"
            label={renderTextField(existingCoverStartDateLabel)}
            changeHandler={value => {
              this.handleInputChange(value)
            }}
            placeholder={existingCoverStartDatePlaceHolder}
            options={{ date: true, pattern: 'MM/YY' }}
            value={startdate.value}
            error={isStartDateValid || datePastError.error}
            caption={
              (isStartDateValid && renderTextField(startDateErrorMsg)) ||
              (datePastError.error && datePastError.errorMsg)
            }
          />
        </StartDateContainer>
        {currentBenefit &&
          currentBenefit.allowableDefermentPeriod &&
          currentBenefit.allowableDefermentPeriod.length > 0 && (
            <WaitingPeriodContainer>
              {currentBenefit.allowableDefermentPeriod && (
                <Select
                  label={renderTextField(existingCoverWaitingPeriodLabel)}
                  value={waitingPeriod.value.value || ''}
                  name="waitingPeriod"
                  id={`waitingPeriod${benefitCode.value}${policyIndex}${benefitIndex}`}
                  options={this.settingDropdownOptions(currentBenefit.allowableDefermentPeriod)}
                  changeHandler={event => this.handleSelectFeatures(event)}
                />
              )}
            </WaitingPeriodContainer>
          )}
        {currentBenefit &&
          currentBenefit.allowableBenefitPeriod &&
          currentBenefit.allowableBenefitPeriod.length > 0 && (
            <BenefitPeriodContainer>
              {currentBenefit.allowableBenefitPeriod && (
                <Select
                  label={renderTextField(existingCoverBenefitPeriodLabel)}
                  value={benefitPeriod.value.value || ''}
                  name="benefitPeriod"
                  id={`benefitPeriod${benefitCode.value}${policyIndex}${benefitIndex}`}
                  options={this.settingDropdownOptions(currentBenefit.allowableBenefitPeriod)}
                  changeHandler={event => this.handleSelectFeatures(event)}
                />
              )}
            </BenefitPeriodContainer>
          )}
      </CoverDetailsContainer>
    )
  }

  render() {
    const {
      benefit,
      benefit: { benefitCode, replacingThisCover },
      benefitIndex,
      fields,
      policyIndex,
      renderErrorMessage,
    } = this.props
    const { isOpen } = this.state
    const {
      personalStatementReplaceCoverDisagreeLabel,
      personalStatementReplaceCoverAgreeLabel,
      existingCoverReplacePolicyLabel,
      existingCoverRemoveButtonLabel,
      replacingThisCoverMandatoryError,
    } = fields
    const radioGroupExitingCoverReplaceOptions = [
      {
        key: 'no',
        text: renderTextField(personalStatementReplaceCoverDisagreeLabel),
        value: 'no',
      },
      {
        key: 'yes',
        text: renderTextField(personalStatementReplaceCoverAgreeLabel),
        value: 'yes',
      },
    ]
    const isReplacingThisCoverValid = pathOr(false, 'error.error', replacingThisCover)
    return (
      <BenefitAccordion
        label={
          <Fragment>
            <div>
              {/* Displaying Income protection in case of new IP benefits */}
              {benefit.benefitType && get(benefit, 'benefitType.value.code', '') === 'IP'
                ? get(benefit, 'benefitType.value.value', '')
                : get(benefit, 'benefitName.value', '')}
            </div>
          </Fragment>
        }
        fields={fields}
        isOpen={isOpen}
        labelKey={benefit.benefitName.value}
        toggleListener={this.toggleListener}
        openIcon={<IconChevronDownBlack16 data-testid="open-btn" />}
        closeIcon={<IconChevronUpBlack16 data-testid="close-btn" />}
      >
        <Fragment>
          <SmallButton type="tertiary" onClick={this.removeBenefit}>
            <Text field={existingCoverRemoveButtonLabel} />
          </SmallButton>
          {this.renderSumInsuredFields()}
          {this.renderCoverDetailsField()}
          <FeaturesContainer>
            <FeatureTitle>
              <Text field={existingCoverReplacePolicyLabel} />
            </FeatureTitle>

            <RadioGroup
              options={radioGroupExitingCoverReplaceOptions}
              handleChange={value => this.handleExistingCoverChange('replacePolicy', value)}
              selectedItem={benefit.replacingThisCover.value}
              name={`replacePolicy${benefitCode.value}${policyIndex}${benefitIndex}`}
            />
          </FeaturesContainer>
          {isReplacingThisCoverValid &&
            renderErrorMessage(renderTextField(replacingThisCoverMandatoryError))}
          {benefit.allowableFeatures &&
            benefit.allowableFeatures.value &&
            benefit.allowableFeatures.value.map((feature, index) => (
              <FeaturesContainer>
                <FeatureTitle>{feature.value}</FeatureTitle>
                <div>
                  <RadioGroup
                    options={radioGroupExitingCoverReplaceOptions}
                    handleChange={value => this.handleFeatureOptions(feature, index, value)}
                    selectedItem={feature.selected}
                    name={`${benefitCode.value}${policyIndex}${benefitIndex}${index}`}
                  />
                </div>
              </FeaturesContainer>
            ))}
        </Fragment>
      </BenefitAccordion>
    )
  }
}

export default Benefits
