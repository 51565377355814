// @flow
import React, { Fragment, Component } from 'react'
import { Route } from 'react-router-dom'
import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from './actions'

// components.
import ToastNotification from './components/atoms/ToastNotification'
import GlobalStyles from './styles/global'
import { SitecoreContextProvider as SitecoreContext } from './SitecoreContext'
import componentFactory from './temp/componentFactory'
import RouteHandler from './RouteHandler'

// styles.
import 'react-toastify/dist/ReactToastify.css'
import GenericFallback from './components/molecules/ErrorBoundary/GenericFallback'
import { setBancsCustomerNo } from './utils/cookieUtils'

// this is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// by default the app's normal rendering is delegated to <RouteHandler> that handles the loading of
//   jss route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
]

type AppRootProps = {
  // An object containing action creator functions.
  actions: Object<Function>,
  // The current route location.
  path: string,
  // Browser router.
  Router: Function,
  // Redux authentication
  authentication: Object<Object>,
  // Redux navigation
  navigation: Object,
  // Redux okta
  okta: Object,
  // Redux env constants config
  config: Object,
  // Redux myLink
  myLink: Object,
}

Component.prototype.componentDidCatch = function catchError(error, errorInfo) {
  // this console will log the component error and its messages
  console.error(error, errorInfo)

  Object.getPrototypeOf(this).render = function renderFallBack() {
    return <GenericFallback />
  }
}

function checkBancsCustomerNo(bancsCustomerNo: string) {
  if (typeof bancsCustomerNo === 'string' && bancsCustomerNo !== '')
    setBancsCustomerNo(bancsCustomerNo)
  return null
}
// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for
//   language URL prefixes.
const AppRoot = (props: AppRootProps) => {
  const { actions, path, Router, authentication, navigation, okta, config, myLink } = props
  const bancsCustomerNumber = get(okta, 'bancsCustomerNo', '')
  checkBancsCustomerNo(bancsCustomerNumber)
  const routeRenderFunction = renderProps => (
    <RouteHandler
      route={renderProps}
      actions={actions}
      authentication={authentication}
      navigation={navigation}
      okta={okta}
      config={config}
      myLink={myLink}
    />
  )
  return (
    <Fragment>
      <GlobalStyles />
      <SitecoreContext
        componentFactory={componentFactory}
        locationPath={path}
        Router={Router}
        routePatterns={routePatterns.map(routePattern => (
          <Route key={routePattern} path={routePattern} render={routeRenderFunction} />
        ))}
      />
      <ToastNotification />
    </Fragment>
  )
}

const mapStateToProps = ({ authentication, navigation, okta, config, myLink }) => ({
  // loading: state.app.loading,
  // rendering: state.app.route,
  // lang: state.app.currentLang,
  // sitecoreContext: state.app.context,
  // metaTitle: state.app.route && state.app.route.fields && state.app.route.fields.metaTitle,
  // routeFields: state.app.route && state.app.route.fields,
  authentication,
  navigation,
  config,
  okta,
  myLink,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppRoot)
