// @flow
// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  CORRESPONDENCEHISTORY_FETCH,
  CORRESPONDENCEHISTORY_CLEAR,
} from '../types/correspondenceHistory'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const getCorrespondenceHistory =
  (data?: Object, callback?: Function) => (dispatch: Function, getState: Function) => {
    const { config } = getState()
    return dispatch({
      type: EXPERIENCE_API,
      name: CORRESPONDENCEHISTORY_FETCH,
      verb: 'POST',
      data,
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/documents/correspondenceHistory`,
      additionalHeaders: {
        'X-InitialRole': config.MLCL_UPLOAD_FILE_API_INITIAL_ROLE,
      },
      callback: (error, dataRecieved) => {
        if (callback) {
          callback(error, dataRecieved.businessData)
        }
        return dataRecieved
      },
    })
  }

export const clearCorrespondenceHistory = () => ({
  type: CORRESPONDENCEHISTORY_CLEAR,
})
