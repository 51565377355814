// @flow
import * as React from 'react'
import { Switch } from 'react-router-dom'
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react'

// styles.
import ThemeProviderLifeStyle from '@mlcl-digital/mlcl-design/lib/base/ThemeProvider'
import { ThemeProvider } from './styles/ThemeContext'

// utils.
import history from './utils/browserHistory'
import SitecoreContextFactory from './lib/SitecoreContextFactory'

type SitecoreContextProviderProps = {
  componentFactory: Object,
  locationPath: Object,
  routePatterns: Array,
  Router: Object,
}

export const SitecoreContextProvider = (props: SitecoreContextProviderProps) => {
  const { componentFactory, routePatterns, locationPath, Router } = props
  return (
    <ThemeProvider theme={process.env.SITE}>
      <ThemeProviderLifeStyle theme={process.env.SITE}>
        <SitecoreContext
          componentFactory={componentFactory}
          contextFactory={SitecoreContextFactory}
        >
          <Router location={locationPath} context={{}} history={history}>
            <Switch>{routePatterns}</Switch>
          </Router>
        </SitecoreContext>
      </ThemeProviderLifeStyle>
    </ThemeProvider>
  )
}
