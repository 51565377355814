// @flow
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { Button, Modal } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../utils/telemetry'

// actions
import { actionCreators } from '../../../actions'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'

// selectors
import {
  getConfig,
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
} from '../../../selectors/common.selectors'
import { getAltsProductRules } from '../../../selectors/alterations'

// styles
import styles from './altsTryAgainModal.styles'

// constants
import {
  SAVE_QUOTE_ACTION_TYPE,
  TIMELINE_COMPONENENT,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
  DECREASE_COVER_QUOTE_COMPONENT_NAME,
} from '../../../constants/alterations'
import { downloadDocument } from '../../../utils/downloadDocumentUtils'
import { DOC_TYPE_ALTERATION_QUOTE } from '../../../constants/documentTypes'
import { generateCorrelationID } from '../../../utils/commonUtils'
import { ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL } from '../../../constants/modal'

const ModalDescription = styled('p')(styles.modalDescription)

type AltsErrorModalPropTypes = {
  fields: Object,
}

const AltsTryAgainModal = ({ fields }: AltsErrorModalPropTypes) => {
  const { isSaveQuoteErrorModal, saveQuoteError } = useSelector(getCreateQuote)
  // Identifier is used to perform action which will ocucur on sucecess of save
  const identifier = get(saveQuoteError, 'identifier')
  const dispatch = useDispatch()
  // Product rules required for digiAlts
  const productRules = useSelector(getAltsProductRules)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const quoteCollectionName = `${firstName} ${lastName}`
  // Timeline componet is used for digialts for GA tags
  const timelineState = useSelector(state => state.timelineWithComponents)
  const activeComponent =
    (timelineState && TIMELINE_COMPONENENT[timelineState.activeComponent]) || ''
  const { activeIndex, quotes } = useSelector(getCreateQuote)
  const config = useSelector(getConfig)

  const onClickTryAgain = () => {
    if (activeComponent) {
      if (activeComponent === TIMELINE_COMPONENENT[DECREASE_COVER_QUOTE_COMPONENT_NAME]) {
        const tagEvent = createEvent({
          GA: {
            category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
            action: 'Try again modal',
          },
          Splunk: {
            attributes: {
              'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} Try again modal`,
            },
          },
        })
        tagEvent.end()
      }
      const tagEvent = createEvent({
        GA: {
          category: 'Decrease cover',
          action: `Try again - decrease cover ${activeComponent} page modal`,
        },
        Splunk: {
          attributes: {
            'workflow.name': `Try again - decrease cover ${activeComponent} page modal`,
          },
        },
      })
      tagEvent.end()
    }
    dispatch(
      actionCreators.saveQuote(
        err => {
          if (!err) {
            switch (identifier) {
              case SAVE_QUOTE_ACTION_TYPE.CONTINUE_CLICK: {
                dispatch(actionCreators.timelineNextState())
                break
              }
              case SAVE_QUOTE_ACTION_TYPE.DOWNLOAD_QUOTE_CLICK: {
                dispatch(actionCreators.updateDownloadQuoteProgress(true))
                downloadDocument(
                  {
                    docType: DOC_TYPE_ALTERATION_QUOTE,
                    quote: quotes[activeIndex],
                    correlationID: generateCorrelationID(),
                    lifeInsuredFirstName: firstName,
                    lifeInsuredLastName: lastName,
                  },
                  null,
                  null,
                  config,
                  error => {
                    dispatch(actionCreators.updateDownloadQuoteProgress(false))
                    if (error)
                      dispatch(actionCreators.showModal(ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL))
                  }
                )
                break
              }
              default: {
                break
              }
            }
          }
        },
        identifier,
        quoteCollectionName,
        firstName,
        lastName,
        productRules
      )
    )

    dispatch(actionCreators.showSaveQuoteErrorModal(false))
  }

  const handleSaveClose = () => {
    if (timelineState.activeComponent === DECREASE_COVER_QUOTE_COMPONENT_NAME) {
      const tagEvent = createEvent({
        GA: {
          category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
          action: 'Close modal',
        },
        Splunk: {
          attributes: {
            'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Close modal`,
          },
        },
      })
      tagEvent.end()
    }
    dispatch(actionCreators.showSaveQuoteErrorModal(false))
  }

  return (
    <Modal
      isOpen={isSaveQuoteErrorModal}
      title={get(fields, 'ModalTitle', '')}
      onClose={handleSaveClose}
      footer={
        <Button type="secondary" onClick={onClickTryAgain}>
          {renderTextField(fields.ModalCTA)}
        </Button>
      }
    >
      <ModalDescription>{renderTextField(fields.ModalDesc, true)}</ModalDescription>
    </Modal>
  )
}

export default AltsTryAgainModal
