import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, colours } = Variables

const style = {
  headingWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',

    [mediaQueries.md]: {
      justifyContent: 'center',
    },
  },
  tagWrapper: {
    marginTop: space(0.5),
    marginLeft: space(1),
  },
  heading: {
    color: colours.mlcAquaDark,
    marginBottom: 0,
  },
  table: {
    background: 'red',
    '& th,td': {
      minWidth: 'initial',
      flex: 2,
      '&:nth-of-type(2)': {
        flex: 7,
      },
      '&:first-of-type': {
        flex: 1,
        justifyContent: 'center',
        div: {
          textTransform: 'capitalize',
        },
      },
    },
  },
  clientDetailsWrap: {
    marginTop: space(5),
  },
  label: {
    marginBottom: space(5, true),
  },
  sectionHeader: ({ SectionMarginBottom = true }) => ({
    marginBottom: space(SectionMarginBottom ? 5 : 1),
  }),
}

export default style
