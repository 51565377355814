import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space } = Variables as VariablesType
const styles = {
  styleOverrides: (shouldClipLast: boolean, policiesNo: number) => ({
    base: {
      width: '100%',
    },
    table: {
      overflow: 'hidden',
    },
    cell: {
      minWidth: 'unset',
      justifyContent: 'center',
      '&:first-of-type': {
        justifyContent: 'flex-start',
      },
    },
    headerCell: {
      minWidth: 'unset',
      alignItems: 'center',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      paddingLeft: space(2, true),
      justifyContent: 'flex-start',
    },
    row: {
      '&:nth-of-type(even)': {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        background: colours.tertiarySix,
      },
    },
    tableBody: {
      tr: {
        ...(shouldClipLast && {
          '&:last-of-type': {
            height: space(4.5, true),
            overflow: 'hidden',
          },
        }),
        ...(policiesNo > 0 && {
          cursor: 'pointer',
          '&:hover': {
            background: colours.tertiaryThree,
          },
        }),
      },
    },
    footer: {
      borderTop: '1px solid #BDBDBD',
      padding: space(2, true),
    },
  }),
}

export default styles
