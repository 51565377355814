// @flow
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button, Modal } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../utils/telemetry'

// actions
import { actionCreators } from '../../../actions'

// selectors
import {
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
} from '../../../selectors/common.selectors'
import { getAltsProductRules } from '../../../selectors/alterations'
import { getIsAnyPolicyAltered } from '../../../selectors/createQuote'

// styles
import styles from './altsAlterationSaveModal.styles'

// constants
import {
  SAVE_QUOTE_ACTION_TYPE,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
} from '../../../constants/alterations'

// utils.
import history from '../../../utils/browserHistory'
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'

const ModalDescription = styled('p')(styles.modalDescription)
const ModalButtonContainer = styled('div')(styles.modalButtonContainer)
const ButtonComponent = styled(Button)(styles.buttonComponent)

type AltsErrorModalPropTypes = {
  fields: Object,
}

const AltsAlterationSaveModal = ({ fields }: AltsErrorModalPropTypes) => {
  const {
    ModalCTA = '',
    ModalDesc = '',
    ModalSaveCTA = '',
    ModalTitle = '',
  } = reduceAuthorableFields(fields)
  const { isSaveAndExitModal } = useSelector(getCreateQuote)
  const dispatch = useDispatch()
  // Product rules required for digiAlts
  const productRules = useSelector(getAltsProductRules)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const quoteCollectionName = `${firstName} ${lastName}`
  const isAnyPolicyAltered = useSelector(getIsAnyPolicyAltered)

  const handleSaveAndExitClose = () => {
    dispatch(actionCreators.showSaveAndExitModal(false))
  }

  const handleSaveClick = (type = '') => {
    if (isAnyPolicyAltered) {
      dispatch(
        actionCreators.saveQuote(
          err => {
            if (!err) {
              const tagEvent = createEvent({
                GA: {
                  category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                  action: 'Exit - decrease cover quote page',
                },
                Splunk: {
                  attributes: {
                    'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Exit - decrease cover quote page`,
                  },
                },
              })
              tagEvent.end()
              history.push('/dashboard')
            }
            if (err) {
              actionCreators.showSaveQuoteErrorModal(true)
            }
          },
          type,
          quoteCollectionName,
          firstName,
          lastName,
          productRules
        )
      )
    }
  }

  return (
    <Modal
      isOpen={isSaveAndExitModal && isAnyPolicyAltered}
      title={ModalTitle}
      onClose={handleSaveAndExitClose}
      footer={
        <ModalButtonContainer>
          <ButtonComponent
            variant="secondary"
            onClick={() => {
              handleSaveAndExitClose()
              handleSaveClick(SAVE_QUOTE_ACTION_TYPE.SAVE_CLICK)
            }}
          >
            {ModalSaveCTA}
          </ButtonComponent>
          <ButtonComponent
            variant="tertiary"
            onClick={() => {
              const tagEvent = createEvent({
                GA: {
                  category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                  action: 'Exit - decrease cover quote page',
                },
                Splunk: {
                  attributes: {
                    'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Exit - decrease cover quote page`,
                  },
                },
              })
              tagEvent.end()
              history.push('/dashboard')
            }}
          >
            {ModalCTA}
          </ButtonComponent>
        </ModalButtonContainer>
      }
    >
      <ModalDescription>{ModalDesc}</ModalDescription>
    </Modal>
  )
}

export default AltsAlterationSaveModal
