// @flow
import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

// components.
import { ProgressTimeline, Button, Heading, PageWrap } from '@mlcl-digital/mlcl-design'

// utils.
import { getProcessingStatusFromAction } from '../../../utils/commonUtils'

// constants.
import {
  COMPLETE,
  ALTERATION_TYPE_REJECT_CPI,
  APPLICATION_TYPES,
} from '../../../constants/application'
import { ALTERATION_TYPES } from '../../../constants/alterations'

// styles.
import styles from './progressTimelineWithComponents.styles'

import { constructProgressTimelineData } from '../../../utils/timelineWithComponentUtils'

type ProgressTimelineWithComponentsProps = {
  // Sitecore fields
  fields: Object,
  // display download application button
  isDownloadCTA: boolean,
}

// styled components.
const HeadingWrap = styled('div')(styles.headingWrap)
const ProgressTimelineWrap = styled('div')(styles.progressTimelineWrap)
const PageWrapper = styled(PageWrap)(styles.pageWrapper)

const ProgressTimelineWithComponents = ({
  fields,
  isDownloadCTA = true,
}: ProgressTimelineWithComponentsProps) => {
  /** TODO: update the state of the application */
  const { action, applicationStage, alterationType, applicationType } = useSelector(
    state => state.createQuote
  )
  const { data: { applicationStates } = [] } = useSelector(state => state.masterList)
  const appLevels = fields.AppLevels
  const activeIndex =
    getProcessingStatusFromAction(action, applicationStage) === COMPLETE ? appLevels.length : 1
  const progressTimeLineData = constructProgressTimelineData(
    appLevels,
    activeIndex,
    applicationStates
  )

  return (
    <PageWrapper>
      <HeadingWrap>
        <div>
          <Heading variant="h2">
            <Text field={appLevels[activeIndex - 1].fields.text} />
          </Heading>
          <p>
            <Text field={appLevels[activeIndex - 1].fields.RichText} />
          </p>
        </div>
        {isDownloadCTA &&
          !(
            APPLICATION_TYPES.DigiAlt === applicationType &&
            (alterationType === ALTERATION_TYPE_REJECT_CPI ||
              alterationType === ALTERATION_TYPES.DECREASE_RISK)
          ) && (
            <div>
              <Button size="medium" variant="secondary">
                <Text field={fields.DownloadCTA} />
              </Button>
            </div>
          )}
      </HeadingWrap>
      <ProgressTimelineWrap>
        <ProgressTimeline data={progressTimeLineData} activeIndex={activeIndex} />
      </ProgressTimelineWrap>
    </PageWrapper>
  )
}

export default ProgressTimelineWithComponents
