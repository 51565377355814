// @flow
import React, { type Node } from 'react'
import { ToastContainer } from 'react-toastify'
import styled from '@emotion/styled'

// components.
import Button from '../Button'
import { IconClose16 } from '../Icons'

// constants.
import { TOAST_AUTOCLOSE_DELAY } from '../../../constants/toast'

// styles.
import styles from './toastNotification.styles'
import { themeConsumer } from '../../../styles/ThemeContext'

type ToastNotificationProps = {
  /* position on the screen that the toast notification appears. */
  position?: string,
  /* duration to wait before auto closing the notification. */
  autoClose?: boolean,
  /* visibility of the progress bar. */
  hideProgressBar?: boolean,
  /* displays the newest notifications above old ones. */
  newestOnTop?: boolean,
  /* if each notification should close when clicked. */
  closeOnClick?: boolean,
  /* text direction. */
  rtl?: boolean,
  /* if timers should be paused when the window isn't active. */
  pauseOnVisibilityChange?: boolean,
  /* if each notification is draggable from it's controlled location. */
  draggable?: boolean,
  /* if timers should be paused when mouse cursor hovers over it. */
  pauseOnHover?: boolean,
}

const ToastElement = styled(ToastContainer)(styles.wrapper)
const CloseIcon = styled(IconClose16)(styles.closeIcon)

const CloseButton = ({ closeToast }: { closeToast: Function }): Node => (
  <Button onClick={closeToast} type="icon">
    <CloseIcon />
  </Button>
)

const ToastNotification = (props: ToastNotificationProps): Node => {
  const {
    position,
    autoClose,
    hideProgressBar,
    newestOnTop,
    closeOnClick,
    rtl,
    pauseOnVisibilityChange,
    draggable,
    pauseOnHover,
  } = props

  return (
    <ToastElement
      position={position}
      autoClose={autoClose}
      hideProgressBar={hideProgressBar}
      newestOnTop={newestOnTop}
      closeOnClick={closeOnClick}
      rtl={rtl}
      pauseOnVisibilityChange={pauseOnVisibilityChange}
      draggable={draggable}
      pauseOnHover={pauseOnHover}
      closeButton={<CloseButton />}
    />
  )
}

ToastNotification.defaultProps = {
  position: 'top-right',
  autoClose: TOAST_AUTOCLOSE_DELAY,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnVisibilityChange: true,
  draggable: false,
  pauseOnHover: true,
}

export default themeConsumer(ToastNotification, 'toast')
