import { colours, space } from '../../../styles'

const styles = {
  subHeading: {
    color: colours.darkGrey,
    fontWeight: 'normal',
  },

  confirmBtn: {
    margin: `${space(3, true)} 0`,
  },
}

export default styles
