// @flow
import {
  SET_TIMELINE_DATA,
  TIMELINE_NEXT_STAGE,
  TIMELINE_PREV_STAGE,
  RESET_TIMELINE_DATA,
} from '../types/timelineWithComponents'

export const setTimelineData = (payload: Array<Object>) => ({
  type: SET_TIMELINE_DATA,
  payload,
})

export const resetTimelineData = () => ({
  type: RESET_TIMELINE_DATA,
})

export const timelineNextState = () => ({
  type: TIMELINE_NEXT_STAGE,
})

export const timelinePrevState = () => ({
  type: TIMELINE_PREV_STAGE,
})
