import { space, colours, fontSize, fontWeights, shadows } from '../../../../../styles'
import { SOURCE_SANS_PRO } from '../../../../../constants/fonts'

const style = {
  wrap: {
    fontFamily: SOURCE_SANS_PRO,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  directorDetailsCard: {
    backgroundColor: 'white',
    width: '49%',
    marginBottom: space(3, true),
    boxShadow: shadows.high,
  },
  directorDetailsHeader: {
    fontStyle: 'normal',
    fontWeight: fontWeights.semiBold,
    backgroundColor: colours.lightBlue,
    paddingTop: space(3, true),
    paddingRight: space(5, true),
    paddingBottom: space(3, true),
    paddingLeft: space(5, true),
  },
  directorName: {
    fontSize: fontSize.lg,
    lineHeight: space(5, true),
  },
  role: {
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    marginTop: space(2, true),
    textTransform: 'uppercase',
  },
  directorDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: space(5, true),
    paddingBottom: space(5, true),
    paddingRight: space(5, true),
  },
  wrapper: {
    color: colours.mediumDarkGrey,
    width: '100%',
  },
  keyValueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  key: {
    width: '40%',
    fontStyle: 'normal',
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    marginTop: space(3, true),
    opacity: '0.4',
  },
  value: {
    fontStyle: 'normal',
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    marginTop: space(3, true),
    textAlign: 'right',
  },
}

export default style
