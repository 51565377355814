import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours } = Variables

const styles = {
  linkButton: {
    color: colours.mlcAquaDark,
    padding: 0,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: `${space(2.5)}px 1fr`,
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  listDesc: {
    marginBottom: space(2),
  },
}

export default styles
