/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/aria-role */
// @flow
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import moment from 'moment'
import {
  Checkbox,
  Select,
  Button,
  Heading,
  Input,
  ToggleGroup,
  Modal,
  RadioGroup,
  Radio,
} from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'
import { getExistingClientBancsCustomerNo } from '../../../selectors/createQuote'

// components.
import MenuList from '../../molecules/MenuList'
import AddressLookUp from '../../molecules/AddressLookUp'
import InputPhone from '../../molecules/InputPhone'
import Occupation from '../Occupation'

// schema.
import SCHEMA, {
  FORM_ID,
  RESIDENTIAL_ADDRESS_FIELD,
  RESIDENTIAL_STATE,
  EARNINGS,
  EARNINGS_EXCLUDING_SUPER,
  PRIMARY_OCCUPATION,
  DATE_OF_BIRTH,
  CONFIRM_MODAL,
  SECONDARY_OCCUPATION,
  SECONDARY_EARNINGS_PA,
} from './lifeInsuredDetails.schema'

// utils
import history from '../../../utils/browserHistory'
import { ignoreColumns } from '../../../utils/stylesUtils'
import {
  errorCheck,
  generateFieldsFromData,
  getValue,
  getSerializedFormState,
  scrollToTop,
} from '../../../utils/formUtils'
import {
  getRelativeTimeDifference,
  INVALID_DATE,
  isValidBenefit,
  ALLOWED_DATE_FORMAT,
  DATE_FORMAT,
  isOwnTPDDisabled,
  isValidPremiumStyle,
  postProcessInitialPolicyStructure,
  getUREAssignedParty,
} from '../../../utils/quoteUtils'
import {
  getLifeInsuredData,
  getLAFromRelationships,
  isEmployee,
} from '../../../utils/extendedQuoteUtils'
import { getEmail, getPhoneNumber } from '../../../utils/contactUtils'
import { isContainsNumber, transformToSelectOptions } from '../../../utils/commonUtils'
import { renderTextField } from '../../../utils/sitecoreUtils'

// types
import { occupationsStateType } from '../../../reducers/occupation'
import { createQuoteType } from '../../../reducers/createQuote'

// constants
import { CONTACT_TYPES, YES_NO_RADIO } from '../../../constants/forms'
import {
  POLICY_RELATIONSHIPS_LIFEASSURED,
  UPDATE_FROM_LIFE_INSURED,
  POLICY_BENEFIT_PTD,
  POLICY_CHILD_COVER_BENEFIT_CODE,
} from '../../../constants/policies'
import {
  CONVERT_TO_DRAFT_APPLICATION,
  SAVE_DRAFT_APPLICATION,
  REGISTER_MY_LINK,
  WIP_MYLINK,
  URE_BY_ADVISER,
  APPLICATION_STAGE_STEP,
} from '../../../constants/application'
import { OCCUPATION_LIST } from '../../../constants/occupation'
import { CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE } from '../../../constants/personalStatement'

// styles
import styles from './lifeInsuredDetails.styles'
import { BENEFIT_NATURE_TYPE_RIDER_OPTIMISER } from '../../../constants/benefit'

type LifeInsuredDetailsProps = {
  // To render all labels from sitecore content editor
  fields: Object,
  existingClientBancsCustomerNo: String,
  // Redux actions available to the component.
  actions: Object<Function>,
  // Redux form data of the component.
  form: Object,
  // Occupation which user has selected
  occupation: occupationsStateType,
  // createQuote which user has selected
  createQuote: createQuoteType,
  // Address lookup manual entry redux state mapped to props.
  advisor: {
    details: {
      contactMethods: Object,
    },
  },
  isManualPostal: boolean,
  isManualResidential: boolean,
  application: Object,
  isCustomer?: boolean,
  afterSubmission: Function,
  // handle scroll to top page
  handleScrollToTop?: Function,
  productRules: Object,
  // Master data from Sitecore
  masterData: Object,
}
type LifeInsuredDetailsState = {
  // show or hide occupation
  showOccupationDropDown: boolean,
  // show occupation modal
  showOccupation: boolean,
  // show Additional Contact
  showAdditionalContact: boolean,
  // show Secondary Occupation
  showSecondaryOccupation: boolean,
  // show Modal Changed ClientMendatories
  showModalChangedClientMandatories: boolean,
  // disable primary occupation dropdown based on employment status
  disablePrimaryOccupationDropDown: boolean,
}

const FullWidthToggleGroup = ignoreColumns(ToggleGroup)
const LifeInsuredWrapper = styled('section')(styles.lifeInsuredWrapper)
const LifeInsuredContainer = styled('section')(({ isCustomer }) =>
  styles.lifeInsuredContainer(isCustomer)
)
const Footer = styled('footer')(styles.footer)
export const Fieldset = styled('div')(({ withoutFlex }) => styles.fieldset(withoutFlex)) // div because flex doesnot work with fieldset in Chorme
export const InputXsm = styled(Input)(({ withoutMargin, transparent }) =>
  styles.sectionXsm(withoutMargin, transparent)
)
export const SelectXsm = styled(Select)(({ withoutMargin }) => styles.sectionXsm(withoutMargin))
export const InputXl = styled(Input)(({ withoutMargin }) => styles.sectionXl(withoutMargin))
const InputFullWidth = styled(Input)(styles.fullWidth)
const InputSm = styled(Input)(styles.sectionSm)
const InputMd = styled(Input)(({ withoutMargin }) => styles.sectionMd(withoutMargin))
const SelectLg = styled(Select)(styles.sectionLg)
export const ButtonContainer = styled('div')(({ secondary }) => styles.buttonContainer(secondary))
const MoreOptionButton = styled('button')(styles.button)
const ModalButton = styled(Button)(styles.modalButton)
const NoResults = styled('div')(styles.noResults)
const RadioGroupWrapper = styled('div')(styles.radioWrapper)
const Description = styled('div')(styles.declarationDescriptions)

export const emptyList = () => ''
export const emptyIndicator = () => null

export const RESIDENTIAL_ADDRESS = [
  'residentialAddress',
  'residentialStreet',
  'residentialHouseNo',
  'residentialLocality',
  'residentialState',
  'residentialPostCode',
]
export const POSTAL_ADDRESS = [
  'postalAddress',
  'postalStreet',
  'postalHouseNo',
  'postalLocality',
  'postalState',
  'postalPostCode',
]

const SET_OF_CLIENT_MANDATORY_FIELDS = new Set([
  'gender',
  'isSmoker',
  'dateOfBirth',
  'primaryOccupation',
  'earningsPA',
  'earningsPAExcludingSuper',
  'employmentStatus',
  'secondaryOccupation',
  'secondaryEarningsPA',
])

const SHOW_ADDITIONAL_CONTACT = 'showAdditionalContact'
const SHOW_SECONDARY_OCCUPATION = 'showSecondaryOccupation'

export class LifeInsuredDetails extends Component<
  LifeInsuredDetailsProps,
  LifeInsuredDetailsState
> {
  constructor(props) {
    super(props)
    const {
      createQuote: { quotes, activeIndex },
    } = this.props
    const { policyStructure } = quotes[activeIndex]
    const relatedMember = policyStructure.length && getLAFromRelationships(policyStructure[0])
    const relatedParty = get(relatedMember, 'relatedParty.contactMethods.phones', [])
    this.state = {
      showOccupation: false,
      activeManualOccupationField: '',
      showOccupationDropDown: false,
      [SHOW_ADDITIONAL_CONTACT]: relatedParty ? relatedParty.length > 1 : false,
      [SHOW_SECONDARY_OCCUPATION]: false,
      showModalChangedClientMandatories: false,
      showModalBenefitFailure: false,
    }
    const {
      actions: { getListOfOccupations },
    } = this.props

    this.debounceListOfOccupation = debounce(getListOfOccupations, 200)
    this.updateOccupationRatingOfField = ''
    const tagEvent = createEvent({
      GA: {
        category: 'Life insured details',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Life insured details - View',
        },
      },
    })
    tagEvent.end()
  }

  componentWillMount() {
    this.lifeInsuredFormInit()
    this.clientMandatoriesChangesFlag = false
  }

  componentDidUpdate(prevProps) {
    const {
      application,
      occupation: { occupationRating },
    } = this.props
    const {
      application: applicationPrevProps,
      occupation: { occupationRating: occupationRatingPrev },
    } = prevProps
    if (application.stage === 1 && application.stage !== applicationPrevProps.stage) {
      this.lifeInsuredFormInit()
    }
    if (
      this.updateOccupationRatingOfField &&
      occupationRating !== occupationRatingPrev &&
      occupationRating.attributes
    ) {
      const { OCCP_CODE } = occupationRating.attributes
      this.handleChange({
        value: OCCP_CODE,
        name: this.updateOccupationRatingOfField,
      })
    }
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      form,
    } = this.props
    const schema = this.getSchema()
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg
      )
      if (charactersCheck.error) return
    }
    const data = {
      error: errorCheck(
        value,
        schema[name].condition,
        null,
        ['contactNumber', 'secondaryNumber'].includes(name) ? form.fields : {}
      ),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
    this.clientMandatoriesChangesFlag = true
  }

  handleRadioChange = value => {
    const {
      actions: { formResetField, resetSecondaryOccupation },
    } = this.props
    this.setState({ [SHOW_SECONDARY_OCCUPATION]: value === 'Y' })
    if (value === 'N') {
      resetSecondaryOccupation()
      formResetField(FORM_ID, ['secondaryEarningsPA', 'secondaryOccupation'])
    }
  }

  handleEmploymentStatusChange = ({ value, name }) => {
    const relatedOccupation = get(value, 'relatedOccupation', '')
    if (relatedOccupation) {
      this.onSelectOfOccupation(
        'primaryOccupationCode',
        false
      )({
        name: 'primaryOccupation',
        value: {
          value: relatedOccupation,
          label: relatedOccupation,
        },
      })
      this.setState({ disablePrimaryOccupationDropDown: true })
    } else this.setState({ disablePrimaryOccupationDropDown: false })
    this.handleChange({ value, name })
  }

  togglePostalAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate, setAdddressToManual },
    } = this.props

    // Set postal manual state to false if hiding postal details
    if (value && name === 'isPostalResidentialAddressSame') {
      setAdddressToManual('lifeInsuranceDetailsPostalAddress', false)
    }
    const schema = this.getSchema()
    const data = {
      error: errorCheck(value, schema[name].condition),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  handleSubmit = () => {
    const {
      isCustomer,
      actions: { checkQuoteActionStatus },
      createQuote: { quoteCollectionId },
    } = this.props
    if (isCustomer) {
      checkQuoteActionStatus(quoteCollectionId, (error, data) => {
        if (!(getUREAssignedParty(data.businessData) === URE_BY_ADVISER)) {
          this.onSubmit()
        } else {
          history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
        }
      })
    } else {
      this.onSubmit()
    }
  }

  onSubmit = (confirmModal): void => {
    const {
      actions: {
        submitMemberDetails,
        createQuoteCalculate,
        updateQuoteStatus,
        initiateSaveQuote,
        formUpdateField,
        formSubmit,
        updateQuoteEntityMyLinkDetails,
        createQuoteSetPolicyStructure,
        setIPSumInsured,
        createQuoteSetIsSatisfied,
        updateApplicationStage,
      },
      form,
      form: { fields },
      afterSubmission,
      createQuote: { quotes, activeIndex, action },
      handleScrollToTop,
      productRules: { data },
      masterData,
      existingClientBancsCustomerNo,
    } = this.props
    let formValid = true
    const { memberMandatories, underwritingDetails, policyStructure } = quotes[activeIndex]
    const relatedMember = getLAFromRelationships(policyStructure[0])
    const relatedParty = relatedMember && relatedMember.relatedParty
    this.clientMandatoriesChanges = false
    const quoteStatus = {
      actionName: action,
      quoteType: null,
      underwritingMethod: null,
    }

    if (action === REGISTER_MY_LINK) {
      quoteStatus.actionName = WIP_MYLINK
    } else if (action === CONVERT_TO_DRAFT_APPLICATION) {
      quoteStatus.actionName = SAVE_DRAFT_APPLICATION
    }

    Object.keys(fields).forEach(key => {
      if ((fields[key].error && fields[key].error.error) || fields[key].error === true)
        formValid = false
      let memberMandatoryValue = ''
      if (confirmModal !== CONFIRM_MODAL && this.clientMandatoriesChangesFlag) {
        switch (key) {
          case RESIDENTIAL_STATE:
            memberMandatoryValue = memberMandatories.stateOfResidence
              ? memberMandatories.stateOfResidence.toLowerCase()
              : null
            break
          case EARNINGS:
            memberMandatoryValue = memberMandatories.earning
            break
          case EARNINGS_EXCLUDING_SUPER:
            memberMandatoryValue = memberMandatories.earningExcludingSuper
            break
          case PRIMARY_OCCUPATION:
            memberMandatoryValue = memberMandatories.occupation
            break
          case SECONDARY_OCCUPATION:
            memberMandatoryValue = get(relatedParty, 'secondaryEmployment.occupation', '')
            break
          case SECONDARY_EARNINGS_PA:
            memberMandatoryValue = get(relatedParty, 'secondaryEmployment.totalGrossYrlyIncome', '')
            break
          case DATE_OF_BIRTH:
            memberMandatoryValue = memberMandatories.dateOfBirth
              ? moment(memberMandatories.dateOfBirth).format('DD/MM/YYYY')
              : null
            break
          default:
            memberMandatoryValue = memberMandatories[key]
        }
        if (
          fields[key].value !== undefined &&
          SET_OF_CLIENT_MANDATORY_FIELDS.has(key) &&
          (typeof fields[key].value === 'object'
            ? memberMandatoryValue !== fields[key].value.value
            : memberMandatoryValue !== fields[key].value)
        ) {
          this.clientMandatoriesChanges = true
        } else if (
          key === RESIDENTIAL_STATE &&
          memberMandatoryValue !== fields[key].value.toLowerCase()
        ) {
          this.clientMandatoriesChanges = true
        }
      }
      handleScrollToTop()
    })

    if (formValid) {
      handleScrollToTop()
      const isValidCover = this.checkValidCovers()

      if (isValidCover) {
        formUpdateField(FORM_ID, 'coverRulesPassed', { error: false, value: true })
        fields.coverRulesPassed = { error: false, value: true }
        if (this.clientMandatoriesChanges) {
          createQuoteSetIsSatisfied(false)
          if (underwritingDetails) {
            updateApplicationStage(2, {
              stage2: APPLICATION_STAGE_STEP.partiallyComplete,
            })
          }
          this.closeModalChangedClientMendatories(true)()
        } else {
          // update quote status
          updateQuoteStatus(quoteStatus)
          const schema = this.getSchema()
          formSubmit(FORM_ID, schema, () => {
            submitMemberDetails(
              {
                ...getSerializedFormState(fields, true),
                contactNumberCountryCode: get(
                  fields,
                  'contactNumberPhoneCode.value.countryCode',
                  ''
                ),
                secondaryNumberCountryCode: get(
                  fields,
                  'secondaryNumberPhoneCode.value.countryCode',
                  ''
                ),
                ...(existingClientBancsCustomerNo && {
                  bancsCustomerNo: existingClientBancsCustomerNo,
                }),
              },
              null,
              [POLICY_RELATIONSHIPS_LIFEASSURED],
              form.identifier,
              FORM_ID,
              activeQuote => {
                const {
                  memberMandatories: { earning },
                } = activeQuote
                if (earning !== memberMandatories.earning) {
                  const { updatedPolicyStructure, calculatedsumInsured } =
                    postProcessInitialPolicyStructure(
                      data,
                      activeQuote.memberMandatories,
                      activeQuote.policyStructure,
                      masterData,
                      false
                    )
                  setIPSumInsured(calculatedsumInsured)
                  createQuoteSetPolicyStructure(updatedPolicyStructure)
                }
              }
            )
            // If personal statement done with life insured completes, also update
            // life insured details there
            if (get(quotes, `[${activeIndex}].myLinkDetails.application.email`)) {
              const myLinkDetails = {
                email: get(fields, 'email.value', ''),
                phone: `+${getValue(fields.contactNumberPhoneCode.value)}${
                  fields.contactNumber.value
                }`,
              }
              updateQuoteEntityMyLinkDetails(myLinkDetails, {}, activeIndex)
            }
            if (confirmModal === CONFIRM_MODAL) {
              createQuoteCalculate({ initiateSaveQuote: true })
            } else {
              initiateSaveQuote()
            }
          })

          if (typeof afterSubmission === 'function') afterSubmission()
        }
      } else {
        formUpdateField(FORM_ID, 'coverRulesPassed', { error: false, value: true })
        fields.coverRulesPassed = { error: false, value: true }
        this.closeModalChangedBenefitFailure(true)()
      }
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Life insured details',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Life insured details - Edit',
        },
      },
    })
    tagEvent.end()
  }

  checkValidCovers = isDefault => {
    const {
      createQuote: { quotes, activeIndex },
      productRules: { data } = {},
      form: { fields } = {},
      occupation: { occupationRating, secondaryOccupationRating } = {},
      masterData = {},
    } = this.props
    const { allowedTPDOccupationTypeGroup, excludedTPDOccupationTypeCode } = masterData
    const { policyStructure, memberMandatories } = quotes[activeIndex]
    const occupations =
      occupationRating && occupationRating.attributes
        ? OCCUPATION_LIST.filter(occ => occupationRating && occupationRating.attributes[occ])
        : []
    const occupationData =
      occupationRating && occupationRating.attributes
        ? {
            occupation: occupationRating.text
              ? occupationRating.text
              : occupationRating.attributes.text,
            occupationalRating: {
              ...occupationRating.attributes,
              secondaryOccupationCode:
                secondaryOccupationRating && secondaryOccupationRating.attributes
                  ? secondaryOccupationRating.attributes.Code
                  : '',
            },
            occupationClassCode: [
              {
                benefit: occupations,
                code: occupations.map(occupation => occupationRating.attributes[occupation]),
              },
            ],
            occupationCode: occupationRating.attributes.OCCP_CODE,
            occupationRating: occupationRating.attributes.OCCP_CODE,
          }
        : {}
    const updatedMemberMandatories = {
      ...memberMandatories,
      ...(isDefault
        ? {}
        : {
            dateOfBirth: moment(
              get(fields, 'dateOfBirth.value', ''),
              ALLOWED_DATE_FORMAT,
              true
            ).format(DATE_FORMAT),
            earning: get(fields, 'earningsPA.value', ''),
            ...(isEmployee(fields.employmentStatus)
              ? { earningExcluding: get(fields, 'earningsPAExcludingSuper.value', '') }
              : null),
            isSmoker: get(fields, 'isSmoker.value', ''),
            occupation: get(fields, 'primaryOccupation.value', ''),
            stateOfResidence: get(fields, 'residentialState.value', ''),
            gender: get(fields, 'gender.value', ''),
            employmentStatus: get(fields, 'employmentStatus.value', ''),
            ...(occupationRating.attributes ? occupationData : {}),
          }),
    }
    const { occupationClassCode, occupationCode } = updatedMemberMandatories
    const isTPDOptDisabled = isOwnTPDDisabled(
      POLICY_BENEFIT_PTD,
      occupationClassCode,
      allowedTPDOccupationTypeGroup,
      excludedTPDOccupationTypeCode,
      occupationCode
    )
    let countOfApplicableCovers = 0
    let originalCountOfCover = 0
    policyStructure.forEach(policy => {
      policy.covers.forEach(cover => {
        const { type, parentBenefitReference, premiumStyle } = cover
        originalCountOfCover += 1
        const benefit = data
          .find(p => p.productId === policy.productId)
          .benefits.find(b => b.benefitCode === type)

        const isChildCover = benefit.benefitCode === POLICY_CHILD_COVER_BENEFIT_CODE
        if (
          benefit &&
          !isValidBenefit(benefit, updatedMemberMandatories) &&
          (isChildCover
            ? isValidPremiumStyle(
                premiumStyle,
                get(cover, 'benefitChildCI.dateOfBirth', ''),
                benefit
              )
            : isValidPremiumStyle(
                premiumStyle,
                get(updatedMemberMandatories, 'dateOfBirth', ''),
                benefit
              ))
        ) {
          countOfApplicableCovers += 1
        }
        if (
          isTPDOptDisabled &&
          type === POLICY_BENEFIT_PTD &&
          parentBenefitReference &&
          parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        ) {
          countOfApplicableCovers -= 1
        }
      })
    })

    return countOfApplicableCovers === originalCountOfCover
  }

  getSchema = () => {
    const {
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
          employmentStatus: { value: employmentStatusValue },
        },
      },
      fields,
      isManualPostal,
      isManualResidential,
      advisor,
    } = this.props
    const { showSecondaryOccupation } = this.state
    const isSecondaryOccupationReq = showSecondaryOccupation
    const contactMethods = get(advisor, 'details.contactMethods', {})
    const emailAddress = contactMethods ? getEmail(get(contactMethods, 'emails', [])) : ''
    const phoneNumber = contactMethods
      ? getPhoneNumber(get(contactMethods, 'phones', []), CONTACT_TYPES.TEL)
      : ''
    const isLAEmployee = isEmployee(employmentStatusValue)
    return SCHEMA(
      isManualPostal,
      isManualResidential,
      postalHidden,
      isSecondaryOccupationReq,
      fields,
      emailAddress,
      phoneNumber,
      isLAEmployee
    )
  }

  onSelectInputChange = value => {
    const { showOccupationDropDown } = this.state
    if (isContainsNumber(value)) {
      this.setState({ showOccupationDropDown: false })
    } else if (value && value.length >= 3) {
      this.userTypedOccupationValue = value
      !showOccupationDropDown && this.setState({ showOccupationDropDown: true })
      this.debounceListOfOccupation(encodeURIComponent(this.userTypedOccupationValue))
    } else {
      this.userTypedOccupationValue = ''
      showOccupationDropDown && this.setState({ showOccupationDropDown: false })
    }
  }

  // open modal of occupation
  toggleOccupation = (modalState, activeManualOccupationField) => () => {
    this.setState({ showOccupation: modalState, activeManualOccupationField })
  }

  // genrate option for select box
  createOptions = (values = []): Array<Object> => values.map(value => ({ label: value, value }))

  // on occupation change
  onSelectOfOccupation =
    (occupationCode, isSecondaryOccupation) =>
    ({ value, name }) => {
      const {
        actions: { getRatingOfOccupation, resetOccupationNames },
      } = this.props
      getRatingOfOccupation(encodeURIComponent(value.value), isSecondaryOccupation)
      resetOccupationNames()
      this.handleChange({ value, name })
      this.updateOccupationRatingOfField = occupationCode
    }

  // additional contact show hide
  toggleAdditionalContact = (): void => {
    const { showAdditionalContact } = this.state
    this.setState({ showAdditionalContact: !showAdditionalContact })
  }

  toggleViews = value => {
    const {
      actions: { formResetField },
    } = this.props
    return val => {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const selectedValue = typeof val === 'boolean' ? val : !this.state[value]
      this.setState({ [value]: selectedValue })
      if (value === SHOW_ADDITIONAL_CONTACT && !selectedValue) {
        formResetField(FORM_ID, ['secondaryNumber'])
      }
    }
  }

  customMenu = (showOccupationDropDown: boolean, isLoading: boolean): Object => {
    const { fields } = this.props
    return {
      MenuList: showOccupationDropDown ? MenuList : emptyList,
      IndicatorsContainer: emptyList,
      DropdownIndicator: emptyIndicator,
      IndicatorSeparator: emptyIndicator,
      NoOptionsMessage: () =>
        isLoading ? (
          <NoResults>Loading...</NoResults>
        ) : (
          <NoResults>{fields.lifeInsuranceOccupationNoResults}</NoResults>
        ),
    }
  }

  addressChange = address => {
    const { name, data, value } = address
    const {
      actions: { formUpdateField, formUpdate, formValidate },
      createQuote: { quotes, activeIndex },
    } = this.props

    const schema = this.getSchema()
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    const { memberMandatories } = quotes[activeIndex]
    if (
      !this.clientMandatoriesChanges &&
      name === RESIDENTIAL_ADDRESS_FIELD &&
      memberMandatories.stateOfResidence !== data.residentialState
    ) {
      this.clientMandatoriesChanges = true
      this.clientMandatoriesChangesFlag = true
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  addressToggleHandler =
    key =>
    (isManual: boolean): void => {
      const {
        actions: { formResetField },
      } = this.props
      // We clear address fields when entering manual mode
      if (isManual) {
        formResetField(FORM_ID, key === 'isManualPostal' ? POSTAL_ADDRESS : RESIDENTIAL_ADDRESS)
      }
      this.setState({
        [key]: isManual,
      })
    }

  // Toggle client mendatories modal
  closeModalChangedClientMendatories = value => () => {
    this.clientMandatoriesChanges = false
    this.setState({ showModalChangedClientMandatories: value })
  }

  closeModalChangedBenefitFailure = value => () => {
    this.clientMandatoriesChanges = false
    this.setState({ showModalBenefitFailure: value })
  }

  renderDetailsByExistingBusiness = schema => {
    const { form, existingClientBancsCustomerNo, fields } = this.props

    const { title, firstName, lastName, middleName, gender } = form.fields

    if (existingClientBancsCustomerNo) {
      return (
        <Fragment>
          <Fieldset role="fieldset">
            <InputXsm
              transparent
              htmlFor="Title"
              name="title"
              label={fields.lifeInsuranceTitle}
              changeHandler={this.handleChange}
              disabled
              value={title.value}
            />
          </Fieldset>
          <Fieldset role="fieldset">
            <InputXsm
              transparent
              htmlFor="firstName"
              name="firstName"
              label={fields.lifeInsuranceFirstName}
              changeHandler={this.handleChange}
              disabled
              value={firstName.value}
            />
            <InputXsm
              transparent
              htmlFor="middleName"
              name="middleName"
              label={fields.LifeInsuranceMiddleName}
              changeHandler={this.handleChange}
              disabled
              value={middleName.value}
            />
          </Fieldset>
          <Fieldset role="fieldset">
            <InputXsm
              transparent
              htmlFor="lastName"
              name="lastName"
              label={fields.lifeInsuranceLastName}
              changeHandler={this.handleChange}
              disabled
              value={lastName.value}
            />
          </Fieldset>
          <Fieldset role="fieldset">
            <InputXsm
              transparent
              htmlFor="gender"
              name="gender"
              label={fields.lifeInsuranceGender}
              changeHandler={this.handleChange}
              disabled
              value={gender.value}
            />
          </Fieldset>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Fieldset role="fieldset">
          <SelectXsm
            withoutMargin
            label={fields.lifeInsuranceTitle}
            name="title"
            id="Title"
            changeHandler={this.handleChange}
            options={schema.title.options}
            value={title.value}
            error={title.error.error}
            caption={title.error.error && fields.lifeInsuranceTitleError}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <InputMd
            htmlFor="firstName"
            name="firstName"
            label={fields.lifeInsuranceFirstName}
            changeHandler={this.handleChange}
            value={firstName.value}
            error={firstName.error.error}
            caption={firstName.error.error && firstName.error.errorMsg}
          />
          <InputMd
            withoutMargin
            htmlFor="middleName"
            name="middleName"
            label={fields.LifeInsuranceMiddleName}
            placeholder={fields.LifeInsuranceMiddleName}
            changeHandler={this.handleChange}
            value={middleName.value}
            error={middleName.error.error}
            caption={middleName.error.error && middleName.error.errorMsg}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <InputFullWidth
            htmlFor="lastName"
            name="lastName"
            label={fields.lifeInsuranceLastName}
            changeHandler={this.handleChange}
            value={lastName.value}
            error={lastName.error.error}
            caption={lastName.error.error && lastName.error.errorMsg}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <FullWidthToggleGroup
            labelSize="sm"
            htmlFor="gender"
            variant="tab"
            label={fields.lifeInsuranceGender}
            name="gender"
            labelledby="client-label"
            handleChange={this.handleChange}
            value={gender.value}
            options={schema.gender.options}
            error={gender.error.error}
            caption={gender.error.error && fields.lifeInsuranceGenderError}
          />
        </Fieldset>
      </Fragment>
    )
  }

  // click on change client mendatories
  onConfirmModal = () => {
    this.clientMandatoriesChanges = false
    this.clientMandatoriesChangesFlag = false
    this.setState({ showModalChangedClientMandatories: false }, () => {
      this.onSubmit(CONFIRM_MODAL)
    })
  }

  onBenefitFailConfirmButton = () => {
    const {
      form: { fields },
      form,
      actions: { submitMemberDetails, filterPolicyCovers, formSubmit },
    } = this.props

    const schema = this.getSchema()
    formSubmit(FORM_ID, schema, () => {
      submitMemberDetails(
        getSerializedFormState(fields, true),
        null,
        [POLICY_RELATIONSHIPS_LIFEASSURED],
        form.identifier,
        FORM_ID
      )
      filterPolicyCovers(UPDATE_FROM_LIFE_INSURED, true)
    })

    this.setState({ showModalBenefitFailure: false })
  }

  lifeInsuredFormInit() {
    const {
      isManualPostal,
      isManualResidential,
      fields,
      actions: { formInit, formValidate },
      advisor,
      masterData,
    } = this.props
    const contactMethods = get(advisor, 'details.contactMethods', {})
    const emailAddress = contactMethods ? getEmail(get(contactMethods, 'emails', [])) : ''
    const phoneNumber = contactMethods
      ? getPhoneNumber(get(contactMethods, 'phones', []), CONTACT_TYPES.TEL)
      : ''
    const data = getLifeInsuredData(this.props)
    const isLAEmployee = isEmployee(data.employmentStatus)
    const schema = SCHEMA(
      isManualPostal,
      isManualResidential,
      true,
      '',
      fields,
      emailAddress,
      phoneNumber,
      isLAEmployee
    )
    formInit(FORM_ID, schema, data)
    formValidate(FORM_ID, schema)
    this.setState({
      [SHOW_SECONDARY_OCCUPATION]: !!(data.secondaryOccupation || data.secondaryEarningsPA),
    })
    this.formPopulatedData = data
    if (
      data.employmentStatus &&
      get(masterData, 'employmentStatus', []).findIndex(
        status => status.code === data.employmentStatus && status.relatedOccupation
      ) > 0
    ) {
      this.setState({ disablePrimaryOccupationDropDown: true })
    }
  }

  conditionalClass() {
    const { isCustomer } = this.props
    return isCustomer ? 'hideItem' : ''
  }

  render() {
    const {
      form,
      occupation: { occupationNames, isLoading, isLoadingOccupationRating },
      actions: { updateGeneralOccupations },
      isCustomer,
      existingClientBancsCustomerNo,
      advisor,
      masterData,
    } = this.props
    const contactMethods = get(advisor, 'details.contactMethods', {})

    if (!form) return ''
    const {
      fields: {
        isPostalResidentialAddressSame: { value: postalHidden },
      },
    } = form
    const emailAddress = contactMethods ? getEmail(get(contactMethods, 'emails', [])) : ''
    const phoneNumber = contactMethods
      ? getPhoneNumber(get(contactMethods, 'phones', []), CONTACT_TYPES.TEL)
      : ''
    const {
      showOccupation,
      showOccupationDropDown,
      showAdditionalContact,
      showSecondaryOccupation,
      showModalChangedClientMandatories,
      showModalBenefitFailure,
      activeManualOccupationField,
      disablePrimaryOccupationDropDown,
    } = this.state
    const {
      isSmoker,
      dateOfBirth,
      email,
      employmentStatus,
      residentialAddress,
      isPostalResidentialAddressSame,
      postalAddress,
      contactNumber,
      secondaryNumber,
      primaryOccupation,
      secondaryOccupation,
      earningsPA,
      earningsPAExcludingSuper,
      secondaryEarningsPA,
      residentialStreet,
      residentialHouseNo,
      residentialLocality,
      residentialState,
      residentialCountry,
      residentialPostCode,
      postalStreet,
      postalHouseNo,
      postalLocality,
      postalState,
      postalCountry,
      postalPostCode,
      contactNumberPhoneCode,
      secondaryNumberPhoneCode,
    } = form.fields
    const { fields, isManualPostal, isManualResidential } = this.props
    const isLAEmployee = isEmployee(getValue(employmentStatus.value))
    const schema = SCHEMA(
      isManualPostal,
      isManualResidential,
      postalHidden,
      '',
      fields,
      emailAddress,
      phoneNumber,
      isLAEmployee
    )
    const ageNextBirthday = getRelativeTimeDifference(dateOfBirth.value)
    const selectPrimaryPhoneCode = {
      label: fields.lifeInsuranceCountryPhoneCode,
      value: contactNumberPhoneCode.value,
      name: 'contactNumberPhoneCode',
      id: 'contactNumberPhoneCode',
      changeHandler: this.handleChange,
    }
    const inputPrimaryPhoneNumber = {
      htmlFor: 'contactNumber',
      name: 'contactNumber',
      label: fields.lifeInsuranceContactNumber,
      changeHandler: this.handleChange,
      value: contactNumber.value,
      error: contactNumber.error.error,
      caption: contactNumber.error.error && contactNumber.error.errorMsg,
    }
    const selectSecondaryPhoneCode = {
      label: fields.lifeInsuranceCountryPhoneCode,
      value: secondaryNumberPhoneCode.value,
      name: 'secondaryNumberPhoneCode',
      id: 'secondaryNumberPhoneCode',
      changeHandler: this.handleChange,
    }
    const inputSecondaryPhoneNumber = {
      name: 'secondaryNumber',
      label: fields.lifeInsuranceSecondaryNumber,
      changeHandler: this.handleChange,
      value: secondaryNumber.value,
      error: secondaryNumber.error.error,
      caption: secondaryNumber.error.error && secondaryNumber.error.errorMsg,
    }
    return (
      <LifeInsuredContainer isCustomer={isCustomer}>
        <LifeInsuredWrapper role="form">
          {!isCustomer && (
            <Heading variant="h2" size="medium" aria-label={fields.lifeInsuranceFieldSetAreaLabel}>
              {fields.lifeInsuranceCaptionForm}
            </Heading>
          )}
          {this.renderDetailsByExistingBusiness(schema)}
          <Fieldset role="fieldset" className={this.conditionalClass()}>
            <FullWidthToggleGroup
              labelSize="sm"
              htmlFor="isSmoker"
              variant="tab"
              label={fields.lifeInsuranceSmokerStatus}
              name="isSmoker"
              labelledby="client-label"
              handleChange={this.handleChange}
              value={isSmoker.value}
              options={schema.isSmoker.options}
              error={isSmoker.error.error}
              caption={isSmoker.error.error && fields.lifeInsuranceSmokerStatusError}
            />
          </Fieldset>
          <Fieldset role="fieldset">
            {!existingClientBancsCustomerNo ? (
              <InputXl
                withoutMargin
                htmlFor="dateOfBirth"
                name="dateOfBirth"
                options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                label={fields.lifeInsuranceDateOfBirth}
                changeHandler={this.handleChange}
                placeholder={fields.lifeInsuranceDateOfBirthPlaceHolder}
                value={dateOfBirth.value}
                error={dateOfBirth.error.error}
                caption={dateOfBirth.error.error && fields.lifeInsuranceDateOfBirthError}
              />
            ) : (
              <InputXsm
                transparent
                htmlFor="dateOfBirth"
                name="dateOfBirth"
                label={fields.lifeInsuranceDateOfBirth}
                changeHandler={this.handleChange}
                disabled
                value={dateOfBirth.value}
              />
            )}
            <InputXsm
              transparent
              htmlFor="ageNextBirthday"
              name="ageNextBirthday"
              label={fields.lifeInsuranceAgeNextBirthday}
              changeHandler={this.handleChange}
              disabled
              value={
                ageNextBirthday === INVALID_DATE || dateOfBirth.error.error ? '' : ageNextBirthday
              }
            />
          </Fieldset>

          <Fieldset role="fieldset">
            <InputFullWidth
              htmlFor="email"
              name="email"
              label={fields.lifeInsuranceEmail}
              changeHandler={this.handleChange}
              value={email.value}
              error={email.error.error}
              caption={email.error.error && email.error.errorMsg}
            />
          </Fieldset>
          <Fieldset withoutFlex role="fieldset">
            <AddressLookUp
              toggleHandler={this.addressToggleHandler('isManualResidential')}
              name="lifeInsuranceDetailsResidentialAddress"
              auto={{
                label: fields.lifeInsuranceResidentialAddress,
                placeholder: fields.addressPlaceholder,
                toggleLabel: fields.cantFindAddressLabel,
                value: residentialAddress.value,
                selectChangeHandler: this.addressChange,
                addressError: residentialAddress.error.error,
                addressName: 'residentialAddress',
                addressErrorMessage:
                  residentialAddress.error.error && fields.addressRequiredErrorMessage,
              }}
              manual={{
                streetPlaceholder: fields.streetPlaceholder,
                streetValue: residentialStreet.value,
                streetRequiredErrorMessage: residentialStreet.error.errorMsg,
                streetError: residentialStreet.error.error,
                streetName: 'residentialStreet',
                houseNoPlaceholder: fields.houseNoPlaceholder,
                houseNoValue: residentialHouseNo.value,
                houseNoError: residentialHouseNo.error.error,
                houseNoRequiredErrorMessage: residentialHouseNo.error.errorMsg,
                houseNoName: 'residentialHouseNo',
                localityPlaceholder: fields.localityPlaceholder,
                localityLabelValue: residentialLocality.value,
                localityError: residentialLocality.error.error,
                localityRequiredErrorMessage: residentialLocality.error.errorMsg,
                localityName: 'residentialLocality',
                statePlaceholder: fields.statePlaceholder,
                stateOptions: schema.residentialState.options,
                stateValue: residentialState.value,
                stateError: residentialState.error.error,
                stateRequiredErrorMessage: residentialState.error.errorMsg,
                stateName: 'residentialState',
                countryPlaceholder: fields.countryPlaceholder,
                countryValue: residentialCountry.value,
                countryError: residentialCountry.error.error,
                countryRequiredErrorMessage: fields.countryRequiredErrorMessage,
                countryName: 'residentialCountry',
                postCodePlaceholder: fields.postCodePlaceholder,
                postCodeValue: residentialPostCode.value,
                postCodeError: residentialPostCode.error.error,
                postCodeRequiredErrorMessage: residentialPostCode.error.errorMsg,
                postCodeName: 'residentialPostCode',
                manualToggleLabel: fields.manualToggleLabel,
                inputEntryHandler: this.handleChange,
              }}
            />
          </Fieldset>
          <Fieldset role="fieldset">
            <Checkbox
              text={fields.lifeInsuranceUsePostalAddress}
              name="isPostalResidentialAddressSame"
              htmlFor="isPostalResidentialAddressSame"
              onChangeHandler={this.togglePostalAddress}
              checked={isPostalResidentialAddressSame.value}
              error={isPostalResidentialAddressSame.error.error}
            />
          </Fieldset>
          {!isPostalResidentialAddressSame.value && (
            <Fieldset withoutFlex role="fieldset">
              <AddressLookUp
                toggleHandler={this.addressToggleHandler('isManualPostal')}
                name="lifeInsuranceDetailsPostalAddress"
                auto={{
                  label: fields.lifeInsurancePostalAddress,
                  placeholder: fields.addressPlaceholder,
                  toggleLabel: fields.cantFindAddressLabel,
                  value: postalAddress.value,
                  selectChangeHandler: this.addressChange,
                  addressError: postalAddress.error.error,
                  addressName: 'postalAddress',
                  addressErrorMessage:
                    postalAddress.error.error && fields.postalAddressRequiredErrorMessage,
                }}
                manual={{
                  streetPlaceholder: fields.streetPlaceholder,
                  streetValue: postalStreet.value,
                  streetRequiredErrorMessage: postalStreet.error.errorMsg,
                  streetError: postalStreet.error.error,
                  streetName: 'postalStreet',
                  houseNoPlaceholder: fields.houseNoPlaceholder,
                  houseNoValue: postalHouseNo.value,
                  houseNoError: postalHouseNo.error.error,
                  houseNoRequiredErrorMessage: postalHouseNo.error.errorMsg,
                  houseNoName: 'postalHouseNo',
                  localityPlaceholder: fields.localityPlaceholder,
                  localityLabelValue: postalLocality.value,
                  localityError: postalLocality.error.error,
                  localityRequiredErrorMessage: postalLocality.error.errorMsg,
                  localityName: 'postalLocality',
                  statePlaceholder: fields.statePlaceholder,
                  stateOptions: schema.postalState.options,
                  stateValue: postalState.value,
                  stateError: postalState.error.error,
                  stateRequiredErrorMessage: postalState.error.errorMsg,
                  stateName: 'postalState',
                  countryPlaceholder: fields.countryPlaceholder,
                  countryValue: postalCountry.value,
                  countryError: postalCountry.error.error,
                  countryRequiredErrorMessage: fields.countryRequiredErrorMessage,
                  countryName: 'postalCountry',
                  postCodePlaceholder: fields.postCodePlaceholder,
                  postCodeValue: postalPostCode.value,
                  postCodeError: postalPostCode.error.error,
                  postCodeRequiredErrorMessage: postalPostCode.error.errorMsg,
                  postCodeName: 'postalPostCode',
                  manualToggleLabel: fields.manualToggleLabel,
                  inputEntryHandler: this.handleChange,
                }}
              />
            </Fieldset>
          )}

          <Fieldset role="fieldset">
            <InputPhone
              selectPhoneCode={selectPrimaryPhoneCode}
              inputPhoneNumber={inputPrimaryPhoneNumber}
            />
            {!isCustomer && !showAdditionalContact && (
              <ButtonContainer>
                <MoreOptionButton onClick={this.toggleViews(SHOW_ADDITIONAL_CONTACT)}>
                  {fields.lifeInsuranceAddAdditionalContact}
                </MoreOptionButton>
              </ButtonContainer>
            )}
          </Fieldset>

          {!isCustomer && showAdditionalContact && (
            <Fieldset withoutFlex role="fieldset">
              <InputPhone
                selectPhoneCode={selectSecondaryPhoneCode}
                inputPhoneNumber={inputSecondaryPhoneNumber}
              />
              <ButtonContainer secondary>
                <MoreOptionButton onClick={this.toggleViews(SHOW_ADDITIONAL_CONTACT)}>
                  {fields.lifeInsuranceRemoveAdditionalContact}
                </MoreOptionButton>
              </ButtonContainer>
            </Fieldset>
          )}
          <Fieldset withoutFlex role="fieldset" className={this.conditionalClass()}>
            <Select
              label={fields.lifeInsuranceEmploymentStatus}
              value={employmentStatus.value}
              name="employmentStatus"
              id="employmentStatus"
              changeHandler={this.handleEmploymentStatusChange}
              options={transformToSelectOptions(get(masterData, 'employmentStatus', []))}
              error={employmentStatus.error.error}
              caption={employmentStatus.error.error && fields.lifeInsuranceEmploymentStatusError}
            />
          </Fieldset>
          <Fieldset withoutFlex role="fieldset" className={this.conditionalClass()}>
            <Select
              htmlFor="primaryOccupation"
              name="primaryOccupation"
              label={fields.lifeInsurancePrimaryOccupation}
              value={primaryOccupation.value}
              disabled={disablePrimaryOccupationDropDown}
              error={primaryOccupation.error.error}
              caption={primaryOccupation.error.error && fields.lifeInsurancePrimaryOccupationError}
              handleInputChange={this.onSelectInputChange}
              options={!disablePrimaryOccupationDropDown ? this.createOptions(occupationNames) : []}
              changeHandler={this.onSelectOfOccupation('primaryOccupationCode')}
              isSearchable
              customComponents={this.customMenu(showOccupationDropDown, isLoading)}
              onClickOfCustomMenuOption={this.toggleOccupation(true, 'primaryOccupation')}
            />
            <InputFullWidth
              htmlFor="earningsPA"
              name="earningsPA"
              label={`${fields.lifeInsuranceEarningsPA} ${
                isLAEmployee ? fields.lifeInsuranceEarningsPAIncludingSuper : ''
              }`}
              changeHandler={this.handleChange}
              readonly
              value={earningsPA.value}
              error={earningsPA.error.error}
              caption={earningsPA.error.error && fields.lifeInsuranceEarningsPAError}
              options={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
              }}
              prefix="$"
            />
            {isLAEmployee && (
              <InputFullWidth
                htmlFor="earningsPAExcludingSuper"
                name="earningsPAExcludingSuper"
                label={`${fields.lifeInsuranceEarningsPA} ${fields.lifeInsuranceEarningsPAExcludingSuper}`}
                changeHandler={this.handleChange}
                readonly
                value={earningsPAExcludingSuper.value}
                error={earningsPAExcludingSuper.error.error}
                caption={
                  earningsPAExcludingSuper.error.error && fields.lifeInsuranceEarningsPAError
                }
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                }}
                prefix="$"
              />
            )}
          </Fieldset>
          <Fieldset role="fieldset" className={this.conditionalClass()}>
            <RadioGroupWrapper>
              <Description>{fields.lifeInsuranceAddSecondaryOccupation}</Description>
              <RadioGroup>
                {YES_NO_RADIO.map(option => (
                  <Radio
                    text={option.text}
                    checked={
                      (option.value === 'Y' && showSecondaryOccupation) ||
                      (option.value === 'N' && !showSecondaryOccupation)
                    }
                    htmlFor={option.key}
                    name={option.key}
                    value={option.value}
                    handleOnChange={value => this.handleRadioChange(value)}
                    key={option.key}
                  />
                ))}
              </RadioGroup>
            </RadioGroupWrapper>
          </Fieldset>
          {!isCustomer && showSecondaryOccupation && (
            <Fieldset role="fieldset">
              <SelectLg
                htmlFor="secondaryOccupation"
                name="secondaryOccupation"
                label={fields.lifeInsuranceSecondaryOccupation}
                value={secondaryOccupation.value}
                error={secondaryOccupation.error.error}
                caption={
                  secondaryOccupation.error.error && fields.lifeInsuranceSecondaryOccupationError
                }
                handleInputChange={this.onSelectInputChange}
                options={this.createOptions(occupationNames)}
                changeHandler={this.onSelectOfOccupation('secondaryOccupationCode', true)}
                isSearchable
                customComponents={this.customMenu(showOccupationDropDown, isLoading)}
                onClickOfCustomMenuOption={this.toggleOccupation(true, 'secondaryOccupation')}
              />
              <InputSm
                htmlFor="secondaryEarningsPA"
                name="secondaryEarningsPA"
                label={fields.lifeInsuranceSecondaryEarningsPA}
                changeHandler={this.handleChange}
                readonly
                value={secondaryEarningsPA.value}
                error={secondaryEarningsPA.error.error}
                caption={
                  secondaryEarningsPA.error.error && fields.lifeInsuranceSecondaryEarningsPAError
                }
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                }}
                prefix="$"
              />
            </Fieldset>
          )}
          <Footer>
            <Button
              type="secondary"
              onClick={this.handleSubmit}
              isLoading={isLoadingOccupationRating}
            >
              {fields.lifeInsuranceSubmit}
            </Button>
          </Footer>
          <Occupation
            fields={{
              captureClientGeneralOccupation: fields.lifeInsuranceGeneralOccupation,
              captureClientGeneralOccupationSelect: fields.lifeInsuranceGeneralOccupationSelect,
              captureClientGeneralOccupationName: fields.lifeInsuranceGeneralOccupationName,
              captureClientGeneralOccupationLife: fields.lifeInsuranceGeneralOccupationLife,
              captureClientGeneralOccupationTPD: fields.lifeInsuranceGeneralOccupationTPD,
              captureClientGeneralOccupationCI: fields.lifeInsuranceGeneralOccupationCI,
              captureClientGeneralOccupationIP: fields.lifeInsuranceGeneralOccupationIP,
              captureClientGeneralOccupationBE: fields.lifeInsuranceGeneralOccupationBE,
              captureClientGeneralOccupationRatings: fields.lifeInsuranceGeneralOccupationRatings,
            }}
            onClose={this.toggleOccupation(false, activeManualOccupationField)}
            openOccupation={showOccupation}
            updateMandatoryDetails={updateGeneralOccupations}
            handleChange={this.handleChange}
            occupationFieldName={activeManualOccupationField}
            isSecondaryOccupation={activeManualOccupationField === 'secondaryOccupation'}
          />
          <Modal
            isOpen={showModalChangedClientMandatories}
            onClose={this.closeModalChangedClientMendatories(false)}
            title={fields.lifeInsuranceModalChangedClientMendatoriesTitle}
          >
            <p>{fields.lifeInsuranceModalChangedClientMendatoriesdesc}</p>
            <ModalButton type="secondary" onClick={this.onConfirmModal}>
              {fields.lifeInsuranceModalChangedClientMendatoriesConfirm}
            </ModalButton>
          </Modal>
          <Modal
            isOpen={showModalBenefitFailure}
            onClose={this.closeModalChangedBenefitFailure(false)}
            title={fields.lifeInsuranceModalChangedBenefitFailureTitle}
            showCloseButton={false}
          >
            <p>{renderTextField(fields.lifeInsuranceModalChangedBenefitFailuredesc, true)}</p>
            <ModalButton type="secondary" onClick={this.onBenefitFailConfirmButton}>
              {fields.lifeInsuranceModalChangedBenefitFailureConfirm}
            </ModalButton>
          </Modal>
        </LifeInsuredWrapper>
      </LifeInsuredContainer>
    )
  }
}

LifeInsuredDetails.defaultProps = {
  isCustomer: false,
  handleScrollToTop() {
    scrollToTop(0)
  },
}

export const mapStateToProps = state => {
  const {
    application,
    forms,
    createQuote,
    occupation,
    addressLookup,
    advisor,
    productRules,
    client,
    masterList,
    existingClient,
  } = state
  return {
    form: forms[FORM_ID],
    advisor,
    application,
    createQuote,
    occupation,
    productRules,
    client,
    existingClientBancsCustomerNo: getExistingClientBancsCustomerNo(state),
    isManualPostal:
      addressLookup.lifeInsuranceDetailsPostalAddress &&
      addressLookup.lifeInsuranceDetailsPostalAddress.isManual,
    isManualResidential:
      addressLookup.lifeInsuranceDetailsResidentialAddress &&
      addressLookup.lifeInsuranceDetailsResidentialAddress.isManual,
    masterData: masterList.data,
    existingClient,
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LifeInsuredDetails)
