import get from 'lodash/get'
import { required, numeric } from '../../../../../../../../utils/formUtils'

export const FORM_ID = 'rolloverPaymentMethod'

const schema = ({ fields }) => ({
  usi: {
    condition: required,
    errorMsg: get(fields, 'cpEditPolicyPaymentDetailRolloverUSIErrorMsg.value', ''),
    name: 'usi',
  },
  abn: {
    condition: [{ condition: required }, { condition: numeric }],
    errorMsg: get(fields, 'cpEditPolicyPaymentDetailRolloverABNErrorMsg.value', ''),
    name: 'abn',
  },
  fundName: {
    condition: required,
    errorMsg: get(fields, 'cpEditPolicyPaymentDetailRolloverFundNameErrorMsg.value', ''),
    name: 'fundName',
    value: 'fundName',
  },
  productName: {
    condition: required,
    errorMsg: get(fields, 'cpEditPolicyPaymentDetailRolloverProductNameErrorMsg.value', ''),
    name: 'productName',
  },
  membershipNumber: {
    condition: required,
    value: 'membershipNumber',
    errorMsg: get(fields, 'cpEditPolicyPaymentDetailRollovermMembershipNumberErrorMsg.value', ''),
    name: 'membershipNumber',
  },
})

export default schema
