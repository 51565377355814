// helpers.
import {
  required,
  date,
  email,
  phone,
  phoneInputs,
  optional,
  postcode,
  numeric,
  isEqualAsPrevious,
  ageRangeCondition,
  firstNameLastName,
  manualAddressRegex,
  manualAddressPostalCode,
  mobilePhone,
  regexTest,
  getFieldValueFromForm,
  ausLandline,
} from '../../../utils/formUtils'
import { GENDER, SMOKING_STATUS, TITLES, STATES } from '../../../constants/forms'
import { DEFAULT_PHONE_CODE } from '../../../constants/contactDetails'

export const FORM_ID = 'lifeInsuredDetails'
export const RESIDENTIAL_ADDRESS_FIELD = 'residentialAddress'
export const RESIDENTIAL_STATE = 'residentialState'
export const EARNINGS = 'earningsPA'
export const EARNINGS_EXCLUDING_SUPER = 'earningsPAExcludingSuper'
export const PRIMARY_OCCUPATION = 'primaryOccupation'
export const SECONDARY_OCCUPATION = 'secondaryOccupation'
export const SECONDARY_EARNINGS_PA = 'secondaryEarningsPA'
export const DATE_OF_BIRTH = 'dateOfBirth'
export const CONFIRM_MODAL = 'confirmModal'

const schema = (
  isManualPostal,
  isManualResidential,
  postalHidden,
  secondaryOccupationMandtory,
  fields,
  emailAddress,
  phoneNumber,
  isEmployee
) => ({
  title: {
    condition: required,
    options: TITLES,
  },
  firstName: {
    condition: [
      {
        condition: required,
        errorMsg: fields.lifeInsuredFirstNameRequiredError,
      },
      {
        condition: firstNameLastName,
        errorMsg: fields.lifeInsuredFirstNameInvalidError,
      },
    ],
  },
  middleName: {
    condition: [{ condition: optional }],
  },
  lastName: {
    condition: [
      {
        condition: required,
        errorMsg: fields.lifeInsuredLastNameRequiredError,
      },
      {
        condition: firstNameLastName,
        errorMsg: fields.lifeInsuredLastNameInvalidError,
      },
    ],
  },
  dateOfBirth: {
    condition: [
      { condition: required },
      { condition: date },
      { condition: ageRangeCondition(11, 70) },
    ],
  },
  email: {
    condition: [
      { condition: required, errorMsg: fields.lifeInsuranceEmailError },
      { condition: email, errorMsg: fields.lifeInsuranceEmailError },
      {
        condition: isEqualAsPrevious(emailAddress),
        errorMsg: fields.lifeInsuredEmailNotSameAsLifeInsuredValidationError,
      },
    ],
  },
  residentialAddress: {
    condition: isManualResidential ? optional : required,
  },
  isPostalResidentialAddressSame: { condition: optional },
  postalAddress: { condition: isManualPostal || postalHidden ? optional : required },
  contactNumber: {
    condition: [
      { condition: required, errorMsg: fields.lifeInsuranceContactNumberRequiredError },
      {
        condition: (value, formFields) => {
          const phoneCodeValue = getFieldValueFromForm('contactNumberPhoneCode', {
            fields: formFields,
          })
          const phoneRegex = phoneCodeValue === DEFAULT_PHONE_CODE ? mobilePhone : phone
          return !regexTest(phoneRegex, value)
        },
        errorMsg: fields.lifeInsuranceContactNumberInvalidError,
      },
      {
        condition: isEqualAsPrevious(phoneNumber),
        errorMsg: fields.lifeInsuredPhoneNotSameAsLifeInsuredValidationError,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields.lifeInsuranceContactNumberError,
  },
  contactNumberPhoneCode: {
    condition: optional,
  },
  secondaryNumber: {
    condition: [
      optional,
      {
        condition: (value, formFields) => {
          const phoneCodeValue = getFieldValueFromForm('secondaryNumberPhoneCode', {
            fields: formFields,
          })
          const phoneRegex = phoneCodeValue === DEFAULT_PHONE_CODE ? ausLandline : phone
          return !regexTest(phoneRegex, value)
        },
        errorMsg: fields.lifeInsuranceSecondaryNumberError,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields.lifeInsuranceSecondaryNumberError,
  },
  secondaryNumberPhoneCode: {
    condition: optional,
  },
  employmentStatus: {
    condition: required,
  },
  primaryOccupation: {
    condition: required,
  },
  primaryOccupationCode: {
    condition: optional,
  },
  earningsPA: {
    condition: [{ condition: required }, { condition: numeric }],
  },
  ...(isEmployee
    ? {
        earningsPAExcludingSuper: {
          condition: [{ condition: required }, { condition: numeric }],
        },
      }
    : null),
  secondaryOccupation: { condition: secondaryOccupationMandtory ? required : optional },
  secondaryOccupationCode: { condition: optional },
  secondaryEarningsPA: { condition: [secondaryOccupationMandtory ? required : optional, numeric] },
  residentialStreet: {
    condition: [
      isManualResidential
        ? {
            condition: required,
            errorMsg: fields.lifeInsuranceResidentialStreetAddressRequiredError,
          }
        : optional,
      isManualResidential
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.lifeInsurancePostalStreetAddressInvalidError,
          }
        : {},
    ],
  },
  residentialHouseNo: {
    condition: [
      optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.lifeInsuranceResidentialHouseNoAddressInvalidError,
      },
    ],
  },
  residentialLocality: {
    condition: [
      isManualResidential
        ? {
            condition: required,
            errorMsg: fields.lifeInsuranceResidentialLocalityRequiredError,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.lifeInsuranceResidentialLocalityInvalidError,
      },
    ],
  },
  residentialState: {
    condition: [
      isManualResidential
        ? {
            condition: required,
            errorMsg: fields.lifeInsuranceResidentialStateRequiredError,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.lifeInsuranceResidentialStateInvalidError,
      },
    ],
    options: STATES,
  },
  residentialCountry: {
    condition: [
      isManualResidential
        ? {
            condition: required,
            errorMsg: fields.lifeInsuranceResidentialCountryAddressRequiredError,
          }
        : optional,
    ],
  },
  residentialPostCode: {
    condition: [
      isManualResidential
        ? {
            condition: required,
            errorMsg: fields.lifeInsuranceResidentialPostCodeRequiredError,
          }
        : optional,
      {
        condition: manualAddressPostalCode,
        errorMsg: fields.lifeInsuranceResidentialPostCodeInvalidError,
      },
      postcode,
    ],
  },
  postalStreet: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.lifeInsurancePostalStreetAddressRequiredError,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.lifeInsurancePostalStreetAddressInvalidError,
      },
    ],
  },
  postalHouseNo: {
    condition: [
      optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.lifeInsurancePostalHouseNoAddressInvalidError,
      },
    ],
  },
  postalLocality: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.lifeInsurancePostalLocalityRequiredError,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.lifeInsurancePostalLocalityInvalidError,
      },
    ],
  },
  postalState: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.lifeInsurancePostalStateRequiredError,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.lifeInsurancePostalStateInvalidError,
      },
    ],
    options: STATES,
  },
  postalCountry: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.lifeInsurancePostalCountryAddressRequiredError,
          }
        : optional,
    ],
  },
  postalPostCode: {
    condition: [
      isManualPostal
        ? {
            condition: required,
            errorMsg: fields.lifeInsurancePostalPostCodeRequiredError,
          }
        : optional,
      {
        condition: manualAddressPostalCode,
        errorMsg: fields.lifeInsurancePostalPostCodeInvalidError,
      },
      postcode,
    ],
  },
  gender: {
    condition: { condition: required },
    options: GENDER,
  },
  isSmoker: {
    condition: { condition: required },
    options: SMOKING_STATUS,
  },
  identifier: {
    condition: optional,
  },
  coverRulesPassed: {
    condition: [required, value => !value],
  },
})

export default schema
