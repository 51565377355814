// @flow
import React from 'react'

// components.
import RetentionDashboardComponent from '../../../organisms/RetentionDashboard'

type RetentionDashboardProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const RetentionDashboard = ({ fields }: RetentionDashboardProps) => (
  <RetentionDashboardComponent fields={fields} />
)

export default RetentionDashboard
