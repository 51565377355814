// @flow
import React, { type Node, Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'
import BurgerMenu from '@mlcl-digital/mlcl-design/lib/base/BurgerMenu'
import get from 'lodash/get'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import debounce from 'lodash/debounce'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'
import { OKTA_CHANGE_PASSWORD_MODAL } from '../../../../../actions/types/okta'

// components.
import { IconUser16 } from '../../../../atoms/Icons'
import Logo from '../../../../atoms/Logo'
import NavDropdown from '../../../../molecules/NavDropdown'
import NavItem from '../../../../atoms/NavItem'
import ChangePassword from '../../../ChangePassword'

// styles.
import styles from './mainNavigation.styles'
import { themeConsumer } from '../../../../../styles/ThemeContext'
import {
  reduceAuthorableFields,
  renderTextField,
  reduceContentListFields,
} from '../../../../../utils/sitecoreUtils'
import { logout } from '../../../../../utils/logoutUtils'
import { getProfileName } from '../../../../../utils/profileUtils'
import { getAdviserNo } from '../../../../../utils/cookieUtils'

// constants
import { NAV_LOGOUT_ITEM_ID, NAV_CHANGE_PASSWORD_ITEM_ID } from '../../../../../constants/sitecore'
import { ADVISOR_PORTAL } from '../../../../../constants/site'
import { DASHBOARD_ROUTE } from '../../../../../constants/routes'
import {
  CP_PRIMARY_MAKE_A_PAYMENT_NAV_ID,
  DOCUMENTS_NAV_ID,
  MY_ADVISER_NAV_ID,
  TASK_DASHBOARD_NAV_ID,
} from '../../../../../constants/navigation'

// selectors
// @ts-expect-error file not in typescript
import { getIsAnyPolicyAltered } from '../../../../../selectors/createQuote'

type MainNavigationProps = {
  // redux actions
  actions: Object<Object>,
  // sitecore fields
  fields: Object,
  // sitecore rendering
  rendering: Object,
  // redux modal state
  modal: Object<Object>,
  hideNavigationItems: boolean,
  excludeSecondaryNavItems: Array<Object>,
  advisor: Object,
  customerPersonalDetails: Object,
  navigation: Object,
  history: Object,
  activeSupportStaff: Object,
  sidebar: Object,
  params: Object,
  isAnyPolicyAltered: boolean,
  isAltsQuoteDownloaded: boolean,
}

type MainNavigationState = {
  // Sets the dropdown compoment toggle to open
  dropdown: Object,
  // Sets scrolling state for the header
  header: {
    isScrolling: boolean,
  },
}

const Header = styled('header')(styles.header)
const LogoContainer = styled('div')(styles.logoContainer)

const Nav = styled('nav')(styles.nav)
const Dropdown = styled(NavDropdown)()

const List = styled('ul')(({ theme }) => styles.list(theme, NavItem))
const Item = styled('li')(({ theme }) => styles.item(theme))
const ItemLink = styled(Item.withComponent(NavItem))(styles.itemLink)
const CustomerNavLinks = styled('div')(styles.helpLink)

const NavSubSection = styled('div')(styles.navSubSection)
const NavTabletSection = styled('div')(styles.navTabletSection)
const ProfileDropdown = styled(Dropdown)(styles.profileDropdpwn)
const PrimaryDropdown = styled(Dropdown)(styles.primaryDropdown)
const Overlay = styled('div')(styles.overlay)

const QuickAction = styled(Item.withComponent('li'))(styles.quickAction)

const ProfileIcon = styled(IconUser16)(styles.profileIcon)
const ProfileNameText = styled('span')(styles.profileNameText)

const QuickActionButton = styled(Button)(styles.quickActionButton)
class MainNavigation extends Component<MainNavigationProps, MainNavigationState> {
  constructor() {
    super()

    this.state = {
      dropdown: {},
      header: {
        isScrolling: false,
      },
      showChangePassword: false,
      hasDOMLoaded: false,
    }

    this.handleScroll = debounce(this.handleScroll.bind(this), 5)
    this.handleOnClickMenu = this.handleOnClickMenu.bind(this)
    this.dropdownClose = this.dropdownClose.bind(this)
  }

  componentDidMount() {
    const {
      actions: { initModal },
    } = this.props
    initModal(OKTA_CHANGE_PASSWORD_MODAL)
    window.addEventListener('scroll', this.handleScroll)
    this.setState(() => ({
      hasDOMLoaded: true,
    }))
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.handleScroll.cancel()
  }

  setScrollingState(isScrolling) {
    this.setState(prevState => ({
      header: {
        ...prevState.header,
        isScrolling,
      },
    }))
  }

  handlePrimaryItemClick = (nav: Object, e: Object) => {
    const eventMap = {
      [TASK_DASHBOARD_NAV_ID]: {
        category: 'Tasks from dashboard',
        action: 'Tasks',
      },
      [MY_ADVISER_NAV_ID]: {
        category: 'Customer selects Your adviser',
        action: 'CP - select adviser',
      },
      [DOCUMENTS_NAV_ID]: { category: 'Customer selects Documents', action: 'Select' },
      [CP_PRIMARY_MAKE_A_PAYMENT_NAV_ID]: {
        category: 'Customer selects Make a payment',
        action: 'CP - make a payment',
      },
    }
    const item = nav?.fields?.id?.value
    if (item) {
      const tagEvent = createEvent({
        GA: eventMap[item],
        Splunk: {
          attributes: {
            'workflow.name': `${eventMap[item].category} - ${eventMap[item].action}`,
          },
        },
      })
      tagEvent.end()
    }
    this.handlePageLeave(e, nav.fields?.href?.value)
  }

  handlePageLeave = (event, route) => {
    const { params, isAnyPolicyAltered, actions, isAltsQuoteDownloaded } = this.props
    const { setIsConfirmLeaveModal } = actions
    if (params.Identifier === 'premiumCalculator' && isAnyPolicyAltered && !isAltsQuoteDownloaded) {
      event.preventDefault()
      setIsConfirmLeaveModal(true, route)
    }
  }

  filterSecondaryNavItems = (navItemsList: Array<Object>): Array<Object> => {
    const { excludeSecondaryNavItems } = this.props
    if (!excludeSecondaryNavItems) {
      return navItemsList
    }
    return navItemsList.filter(item => excludeSecondaryNavItems.indexOf(item.id) === -1)
  }

  menuItemClickHandler = (e: Object, item: Object) => {
    const {
      actions: { showModal, signOut, setIsConfirmLeaveModal },
      history,
      params,
      isAnyPolicyAltered,
      isAltsQuoteDownloaded,
    } = this.props

    if (
      params?.Identifier === 'premiumCalculator' &&
      isAnyPolicyAltered &&
      !isAltsQuoteDownloaded
    ) {
      e.preventDefault()
      const href = [NAV_CHANGE_PASSWORD_ITEM_ID, NAV_LOGOUT_ITEM_ID].includes(item.id)
        ? ''
        : item.href
      setIsConfirmLeaveModal(true, href, item.id)
      // NOTE: If you add condition based on item.id below, you might also need to
      // add in src/components/organisms/PremiumCalculator/AltsPremiumCalculator/index.tsx
    } else if (item.id === NAV_CHANGE_PASSWORD_ITEM_ID) {
      e.preventDefault()
      showModal(OKTA_CHANGE_PASSWORD_MODAL)
    } else if (item.id === NAV_LOGOUT_ITEM_ID) {
      e.preventDefault()
      logout({
        oktaSignOutAction: signOut,
        currentRoute: get(history, 'location.pathname', '/'),
      })
    }
  }

  dropdownClose() {
    this.setState(prevState => ({
      ...prevState,
      dropdown: {},
    }))
  }

  handleOnClickMenu() {
    const {
      actions: { toggleBurgerMenu },
      navigation: { isOpen: menuIsOpen },
    } = this.props

    toggleBurgerMenu(!menuIsOpen)
  }

  handleScroll() {
    const {
      header: { isScrolling },
    } = this.state

    if (window.scrollY === 0 && isScrolling === true) {
      this.setScrollingState(false)
    } else if (window.scrollY !== 0 && isScrolling !== true) {
      this.setScrollingState(true)
    }
  }

  render() {
    const {
      header: { isScrolling },
      dropdown,
      hasDOMLoaded,
    } = this.state

    const {
      actions,
      hideNavigationItems,
      fields,
      rendering,
      modal,
      advisor,
      customerPersonalDetails,
      navigation: { isOpen: menuIsOpen, showNotificationBanner },
      sidebar,
      activeSupportStaff,
    } = this.props
    const dropdownOpen = Object.keys(dropdown).length !== 0
    const { openSidebar } = actions

    const { logoHorizontal, logoVertical, profile, primary, help, quicklinkAction } = fields

    const isChangePasswordModalVisible =
      modal && modal[OKTA_CHANGE_PASSWORD_MODAL] && modal[OKTA_CHANGE_PASSWORD_MODAL].isModalVisible

    let profileDisplayName
    if (getAdviserNo()) {
      // support staff
      profileDisplayName =
        activeSupportStaff.firstName || get(profile, 'fields.text.value', 'Account')
    } else {
      profileDisplayName = getProfileName(
        advisor,
        customerPersonalDetails,
        get(profile, 'fields.text.value', 'Account')
      )
    }

    const ProfileName = (
      <ProfileNameText>
        <ProfileIcon /> {profileDisplayName}
      </ProfileNameText>
    )

    const isAdviserPortal = process.env.SITE === ADVISOR_PORTAL

    const CreateQuoteButton = () => {
      if (isAdviserPortal && quicklinkAction && quicklinkAction.fields) {
        return (
          <QuickAction>
            <QuickActionButton
              variant="primary"
              onClick={() => {
                openSidebar('createQuote')
                const tagEvent = createEvent({
                  GA: {
                    category: 'Create quote',
                    action: 'Select',
                  },
                  Splunk: {
                    attributes: {
                      'workflow.name': 'Create quote - Select',
                    },
                  },
                })
                tagEvent.end()
              }}
            >
              {renderTextField(get(quicklinkAction, 'fields.text', ''))}
            </QuickActionButton>
          </QuickAction>
        )
      }
      return null
    }

    return (
      <Fragment>
        {dropdownOpen && <Overlay onClick={this.dropdownClose} />}
        {rendering && rendering.placeholders && (
          <Placeholder name="notification-banner" rendering={rendering} />
        )}
        <Header
          data-testid="main-navigation"
          isScrolling={isScrolling}
          isSidebarOpen={sidebar && sidebar.open}
          isNotificationBannerVisible={showNotificationBanner}
        >
          <LogoContainer>
            <Logo
              href={hideNavigationItems || !hasDOMLoaded ? '' : DASHBOARD_ROUTE}
              horizontalSrc={get(logoHorizontal, 'value', '')}
              verticalSrc={get(logoVertical, 'value', '')}
              alt="MLC"
              onClick={event => this.handlePageLeave(event, DASHBOARD_ROUTE)}
            />
            {!hideNavigationItems && hasDOMLoaded && (
              <>
                <NavTabletSection>
                  {/* show Create Quote on tablet */}
                  <CreateQuoteButton />
                </NavTabletSection>
                <BurgerMenu isOpen={menuIsOpen} onClick={this.handleOnClickMenu} />
              </>
            )}
          </LogoContainer>
          {!hideNavigationItems && hasDOMLoaded && (
            <Fragment>
              <Nav isOpen={menuIsOpen}>
                <List>
                  {profile && profile.fields && !!Object.keys(profile.fields).length && (
                    <Item showOnSmall>
                      <Dropdown
                        buttonText={profileDisplayName}
                        items={reduceContentListFields(get(profile, 'fields.secondary', []))}
                        menuItemClickHandler={this.menuItemClickHandler}
                      />
                    </Item>
                  )}
                  {primary &&
                    primary.map((nav: Object): Node =>
                      nav.fields && nav.fields.secondary && nav.fields.secondary.length ? (
                        <Item key={get(nav, 'fields.text.value', '')}>
                          <PrimaryDropdown
                            buttonText={renderTextField(get(nav, 'fields.text', ''))}
                            items={this.filterSecondaryNavItems(
                              reduceContentListFields(get(nav, 'fields.secondary', []))
                            )}
                            menuItemClickHandler={this.menuItemClickHandler}
                          />
                        </Item>
                      ) : (
                        <ItemLink
                          key={get(nav, 'fields.text.value', '')}
                          href={get(nav, 'fields.href.value', '')}
                          onClick={e => this.handlePrimaryItemClick(nav, e)}
                        >
                          {renderTextField(get(nav, 'fields.text', ''))}
                        </ItemLink>
                      )
                    )}
                </List>
              </Nav>
              <NavSubSection>
                <List>
                  <CreateQuoteButton />
                  {help && help.fields && !!Object.keys(help.fields).length && (
                    <ItemLink
                      href={get(help, 'fields.href.value', '')}
                      onClick={e => this.handlePrimaryItemClick(help, e)}
                    >
                      <CustomerNavLinks>
                        {renderTextField(get(help, 'fields.text', ''))}
                      </CustomerNavLinks>
                    </ItemLink>
                  )}
                </List>

                {profile && profile.fields && !!Object.keys(profile.fields).length && (
                  <ProfileDropdown
                    buttonText={ProfileName}
                    items={reduceContentListFields(get(profile, 'fields.secondary', []))}
                    variant="secondary"
                    menuItemClickHandler={this.menuItemClickHandler}
                  />
                )}
              </NavSubSection>
            </Fragment>
          )}
        </Header>
        {isChangePasswordModalVisible && (
          <ChangePassword
            isOpen={isChangePasswordModalVisible || false}
            fields={reduceAuthorableFields(fields)}
          />
        )}
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = ({
  modal,
  advisor,
  customerPersonalDetails,
  navigation,
  sidebar,
  supportStaff,
  createQuote,
  alterations,
}) => ({
  modal,
  advisor,
  customerPersonalDetails,
  navigation,
  sidebar,
  activeSupportStaff: get(supportStaff, 'activeSupportStaff', {}),
  isAnyPolicyAltered: getIsAnyPolicyAltered({ createQuote }),
  isAltsQuoteDownloaded: alterations.isAltsQuoteDownloaded,
})

export const Navigation = MainNavigation // Exported for testing.

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(themeConsumer(withRouter(MainNavigation), 'navigation'))
