import { Variables } from '@mlcl-digital/mlcl-design'
import { THEME_LIGHT, THEME_GREY } from '../../../constants/themes'

const { space, colours, mediaQueries } = Variables
const styles = {
  topBackground(theme) {
    if (theme === THEME_LIGHT) {
      return {
        position: 'relative',
        backgroundColor: colours.mlcAquaCustomer,
        color: colours.mlcAquaDark,
      }
    }
    if (theme === THEME_GREY) {
      return {
        position: 'relative',
        backgroundColor: colours.tertiaryThree,
        color: colours.tertiaryOne,
      }
    }
    return {
      backgroundColor: colours.mlcAquaDark,
      color: colours.tertiarySix,
    }
  },
  headerPageWrap(theme) {
    if (theme === THEME_LIGHT || theme === THEME_GREY) {
      return {
        position: 'relative',
      }
    }
    return {
      position: 'static',
      overflow: 'hidden',
    }
  },
  infoContainer: ({ nowrap, slim }) => ({
    display: 'flex',
    flexWrap: nowrap ? 'nowrap' : 'wrap',
    minHeight: !slim ? 280 : 'auto',
    padding: slim ? `${space(10, true)} 0` : `${space(3, true)} 0 ${space(4, true)} 0`,
    [mediaQueries.md]: {
      minHeight: !slim ? 344 : 'auto',
    },
  }),
  contentContainer: ({ slim }) => ({
    alignSelf: 'center',
    [mediaQueries.md]: {
      width: !slim ? '50%' : 'auto',
    },
  }),
  backButton: theme => ({
    margin: space(0),
    padding: space(0),
    background: 'transparent',
    border: space(0),
    color: theme === THEME_LIGHT ? colours.mlcAquaDark : colours.tertiarySix,
    textAlign: 'left',
    marginBottom: space(4),
    outline: 'none',
    '& svg': {
      color: theme === THEME_LIGHT ? colours.mlcAquaDark : colours.tertiarySix,
    },
    [mediaQueries.md]: {
      marginBottom: space(4),
    },
  }),
  childrenContainer: {
    alignSelf: 'center',
    marginLeft: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    [mediaQueries.md]: {
      marginLeft: 'auto',
    },
  },
  rightImage: (theme, bigImage) => ({
    display: 'none',
    marginBottom: 0,
    [mediaQueries.md]: {
      display: theme === THEME_LIGHT || theme === THEME_GREY ? 'block' : 'none',
      position: 'absolute',
      width: 'auto',
      maxWidth: '50%',
      height: bigImage ? '70%' : '60%',
      top: '35px',
      right: '24px',
      marginTop: space(2),
      zIndex: 1,
    },
    [mediaQueries.tb]: {
      marginTop: 0,
      height: bigImage ? '85%' : '70%',
    },
  }),
}

export default styles
