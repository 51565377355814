import { Variables } from '@mlcl-digital/mlcl-design'
import { space, fontSize, fontWeights } from '../../../styles'

const { colours } = Variables
const styles = {
  wrap: {
    marginBottom: space(24),
  },
  headerCell: {
    '&:last-of-type': {
      minWidth: '32%',
    },
  },
  tableBody: {
    tr: {
      cursor: 'pointer',
      '&:hover': {
        background: colours.tertiaryThree,
      },
      'td:last-of-type': {
        minWidth: '32%',
      },
    },
  },
  tableBase: {
    marginTop: space(9),
  },
  card: {
    background: colours.white,
    padding: `${space(5, true)} ${space(4, true)} ${space(3, true)} ${space(5, true)}`,
    display: 'flex',
    flexDirection: 'row',
  },
  buttonWrapper: {
    margin: '0',
  },
  disabled: {
    color: colours.mlcAquaAccessible,
    fontSize: fontSize.md,
    fontWeight: fontWeights.bold,
    opacity: '0.4',
    cursor: 'not-allowed',
  },
  iconDisabled: {
    opacity: '0.4',
    marginRight: space(1),
    cursor: 'not-allowed',
  },
  noResultFound: {
    padding: space(2, true),
    color: colours.red,
  },
  noResultFoundText: {
    display: 'block',
    marginBottom: space(2, true),
  },
  icon: {
    color: colours.mlcAquaAccessible,
    marginRight: space(1),
  },
  base: icon => ({
    fontSize: fontSize.md,
    color: colours.mlcAquaAccessible,
    fontWeight: fontWeights.bold,
    textDecoration: 'none',
    '&:hover, &:focus': {
      outline: 'none',
      color: colours.mlcAquaDark,
      [icon]: {
        color: colours.mlcAquaDark,
      },
    },
    '&:active': {
      color: colours.darkestGrey,
      [icon]: {
        color: colours.darkestGrey,
      },
    },
  }),
  iconExclaimation: {
    color: colours.red,
    marginTop: space(0.5),
    marginRight: space(1),
  },
  errorCard: {
    color: colours.red,
    padding: `0 ${space(4)}`,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: space(3),
  },
}

export default styles
