// @flow
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { actionCreators } from '../../../actions'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'
import { logout } from '../../../utils/logoutUtils'

// constants
import { DASHBOARD_ROUTE, SUPPORT_STAFF_DECLARATION_ROUTE } from '../../../constants/routes'
import { LOGO_HORIZONTAL, LOGO_VERTICAL } from '../../../constants/login'

// styles
import styles from './SelectAdviser.styles'
import { themeConsumer } from '../../../styles/ThemeContext'

import Card from '../../atoms/Card'
import Logo from '../../atoms/Logo'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import Select from '../../atoms/Select'
import {
  transformAgenciesDataToDropdownOption,
  getActiveAdviser,
} from '../../../utils/supportStaffUtils'
import { getBancsCustomerNumber, setAdviserNo, removeAdviserNo } from '../../../utils/cookieUtils'

const Body = styled('div')(({ theme }) => styles.body(theme))
const Main = styled(Card)(styles.main)
const Header = styled('div')(styles.header)
const HeaderLogo = styled(Logo)(styles.logo)
const Description = styled('div')(styles.description)
const Form = styled('form')(styles.form)
const Footer = styled('div')(styles.footer)
export const FormSubmitButton = styled(Button)(styles.formSubmitButton)
const FormHeader = styled('div')(styles.formHeader)
const Spinner = styled(Loader)(styles.spinner)

type SelectAdviserProps = {
  fields: {
    SelectAdviserHeading: Object,
    SelectAdviserInputLabel: Object,
    SelectAdviserSubmitButton: Object,
    SelectAdviserInputError: Object,
    SelectAdviserInputPlaceholder: Object,
    SelectAdviserNoResult: Object,
    SelectAdviserNoResultDescription: Object,
    SelectAdviserLogoutButton: Object,
  },
  theme: String,
  actions: {
    retrieveAdviserForSupportStaff: Function,
    getAdvisorDetails: Function,
    saveActiveSupportStaffData: Function,
    saveActiveAdviserData: Function,
    getSupportStaffDetails: Function,
    resetAdvisorSupportStaff: Function,
    signOut: Function,
    getRetentionDashboard: Function,
  },
  advisorList: {
    data: Object,
    isFetching: Boolean,
  },
  activeAdviser: Object,
  hasCompletedWelcomeJourney: Boolean,
  requiresPasswordReset: Boolean,
  isLoggingOut: Boolean,
}

export const SelectAdviser = (props: SelectAdviserProps) => {
  const {
    theme,
    fields,
    actions,
    advisorList,
    activeAdviser,
    hasCompletedWelcomeJourney,
    requiresPasswordReset,
    isLoggingOut,
  } = props
  const [selectedAdviser, changeAdviser] = useState('')
  const [showError, toggleError] = useState(false)
  const {
    isFetching,
    data: { totalRecords, agencies },
  } = advisorList

  const {
    SelectAdviserHeading,
    SelectAdviserInputLabel,
    SelectAdviserSubmitButton,
    SelectAdviserInputError,
    SelectAdviserInputPlaceholder,
    SelectAdviserNoResult,
    SelectAdviserNoResultDescription,
    SelectAdviserLogoutButton,
  } = fields

  const {
    retrieveAdviserForSupportStaff,
    getAdvisorDetails,
    saveActiveSupportStaffData,
    saveActiveAdviserData,
    getSupportStaffDetails,
    resetAdvisorSupportStaff,
    getRetentionDashboard,
    signOut,
  } = actions

  const selectedDropdownItem = selectedAdviser || get(activeAdviser, 'bancsCustomerNo', '')

  const bancsCustomerNo = getBancsCustomerNumber()

  useEffect(() => {
    resetAdvisorSupportStaff()
    if (bancsCustomerNo) {
      retrieveAdviserForSupportStaff({ bancsCustomerNo })
    }
  }, [])

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      if (!selectedDropdownItem) {
        toggleError(true)
      } else {
        toggleError(false)
        setAdviserNo(selectedDropdownItem)
        getSupportStaffDetails({ bancsCustomerNo }, (err, supportStaffData) => {
          if (!err) {
            saveActiveSupportStaffData(supportStaffData)
            getAdvisorDetails(data => {
              if (data) {
                saveActiveAdviserData(getActiveAdviser(agencies, selectedDropdownItem))
                const bancsAgencyCodes = data.adviserDetails.reduce((agencyCodes, item) => {
                  agencyCodes.push(item.bancsAgencyCode)
                  return agencyCodes
                }, [])
                getRetentionDashboard({ bancsAgencyCodes })
                if (!hasCompletedWelcomeJourney && requiresPasswordReset) {
                  history.push(SUPPORT_STAFF_DECLARATION_ROUTE)
                } else if (history.location.state?.deeplinkUrl) {
                  history.push(history.location.state?.deeplinkUrl)
                } else {
                  history.push(DASHBOARD_ROUTE)
                }
              }
            }, selectedDropdownItem)
          } else {
            removeAdviserNo()
          }
        })
      }
    },
    [selectedAdviser, showError, selectedDropdownItem]
  )

  const handInputChange = useCallback(
    selectedOption => {
      const {
        value: { value },
      } = selectedOption

      changeAdviser(value)
      toggleError(false)
    },
    [selectedAdviser, showError, selectedDropdownItem]
  )

  const handleLogout = () => {
    logout({
      oktaSignOutAction: signOut,
      currentRoute: get(history, 'location.pathname', '/'),
    })
  }

  const formId = 'selectAdvisor'

  const options = transformAgenciesDataToDropdownOption(agencies)

  const renderLogo = () => (
    <HeaderLogo href="/" horizontalSrc={LOGO_HORIZONTAL} verticalSrc={LOGO_VERTICAL} />
  )

  const renderForm = () => {
    if (isFetching) {
      return <Loader spinnerSize={60} borderSize={5} />
    }

    if (!totalRecords || totalRecords < 1) {
      return (
        <Fragment>
          <FormHeader>
            <Heading size="2">{renderTextField(SelectAdviserNoResult)}</Heading>
            <Description>{renderTextField(SelectAdviserNoResultDescription)}</Description>
          </FormHeader>
          <Footer>
            <FormSubmitButton type="primary" data-testid="logoutBtn" onClick={handleLogout}>
              {isLoggingOut ? (
                <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
              ) : (
                renderTextField(SelectAdviserLogoutButton)
              )}
            </FormSubmitButton>
          </Footer>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <FormHeader>
          <Heading size="2">{renderTextField(SelectAdviserHeading)}</Heading>
        </FormHeader>
        <Form onSubmit={handleSubmit} id={formId} aria-labelledby={`${formId}-label`}>
          <Select
            placeholder={renderTextField(SelectAdviserInputPlaceholder)}
            value={selectedDropdownItem}
            name="adviserSelectInput"
            id="adviserSelectInput"
            error={showError && renderTextField(SelectAdviserInputError)}
            caption={showError && renderTextField(SelectAdviserInputError)}
            changeHandler={handInputChange}
            options={options}
            size="lg"
          />
          <Description>{renderTextField(SelectAdviserInputLabel)}</Description>
          <Footer>
            <FormSubmitButton btnActionType="submit" type="primary" data-testid="submitBtn">
              {renderTextField(SelectAdviserSubmitButton)}
            </FormSubmitButton>
          </Footer>
        </Form>
      </Fragment>
    )
  }

  const render = () => (
    <Fragment>
      <Header>{renderLogo()}</Header>
      {renderForm()}
    </Fragment>
  )

  return (
    <Body theme={theme}>
      <Main>{render()}</Main>
    </Body>
  )
}

export const mapStateToProps = ({
  supportStaff: { advisorList, activeAdviser },
  authentication: { hasCompletedWelcomeJourney, requiresPasswordReset, isLoggingOut },
}) => ({
  advisorList,
  activeAdviser,
  hasCompletedWelcomeJourney,
  requiresPasswordReset,
  isLoggingOut,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(themeConsumer(SelectAdviser, 'banner'))
