// @flow
import React from 'react'
// Organism
import AltsDecreaseQuoteOrganism from '../../../organisms/Alteration/AltsDecreaseQuote'

type AltsDecreaseQuoteProps = {
  // Sitecore fields.
  fields: Object,
  // Params
  params: Object,
}

const AltsDecreaseQuote = ({ fields, params }: AltsDecreaseQuoteProps) => (
  <AltsDecreaseQuoteOrganism fields={fields} params={params} />
)

export default AltsDecreaseQuote
