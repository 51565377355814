import { createSelector } from 'reselect'
import moment from 'moment'

// types
import { Store } from '../types/store'
import { RetentionDashboardState } from '../types/retentionDashboard'

const DATE_FORMAT = 'DD/MM/YYYY'

export const getDashboardDetail = (state: Store) => state.retentionDashboard?.dashboardDetail

// Get riskOfLapse policies with scheduledLapseDate within today and today+15th day
export const getMissedPaymentPoliciesWithinNext15days = createSelector(
  [getDashboardDetail],
  (dashboardDetail: RetentionDashboardState) =>
    (dashboardDetail?.riskOfLapse?.policies ?? []).filter(
      p =>
        p.scheduledLapseDate &&
        moment(p.scheduledLapseDate, DATE_FORMAT).isBetween(
          moment().subtract(1, 'days'),
          moment().add(15, 'days')
        )
    ) || []
)

// Get lapsed policies with lapsedDate within last 30 days
export const getLapsedPoliciesWithinLast30Days = createSelector(
  [getDashboardDetail],
  (dashboardDetail: RetentionDashboardState) =>
    (dashboardDetail?.lapsed?.policies ?? []).filter(
      p => p.lapseDate && moment(p.lapseDate, DATE_FORMAT).isAfter(moment().subtract(31, 'days'))
    ) || []
)
