import { space, colours, fontSize } from '../../../../../../../styles'

const styles = {
  paymentBreakupRowRoot: {
    borderBottom: `1px solid ${colours.lightestGrey}`,
    padding: `${space(2, true)} ${space(4, true)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colours.black,
  },
  paymentBreakupRowAmount: {
    fontSize: fontSize.xs,
    lineHeight: fontSize.sm,
  },
  infoIcon: {
    color: colours.lightBlack,
  },
  infoIconContainer: {
    display: 'inline-flex',
    paddingLeft: space(1),
  },
  tooltipAmount: {
    width: space(28, true),
  },
}

export default styles
