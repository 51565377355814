// @flow
// types.

import { EXPERIENCE_API } from '../types/experienceApi'
import { SEARCH_POLICY } from '../types/searchPolicy'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const searchPolicies =
  (policyNo: string, callback: Function = () => {}) =>
  (dispatch: Function): Object => {
    // set the isLoading key in the store.
    dispatch({
      type: SEARCH_POLICY,
    })
    // dispatch the action to call the api.
    dispatch({
      type: EXPERIENCE_API,
      name: SEARCH_POLICY,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/policies/search`,
      data: {
        policyNo: policyNo && policyNo.trim().toUpperCase(),
      },
      callback: (err, dataReceived) => {
        callback(err, dataReceived)
        return dataReceived
      },
    })
  }
