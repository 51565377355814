// @flow
import React from 'react'
// Organism
import AltsLandingPageOrganism from '../../../organisms/AltsLandingPage'
import { isBrowser } from '../../../../utils/browserUtils'

type AltsLandingPageProps = {
  // Sitecore fields.
  fields: Object<Object>,
  // Params
  params: Object,
}

const AltsLandingPage = ({ fields, params }: AltsLandingPageProps) =>
  isBrowser() && <AltsLandingPageOrganism fields={fields} params={params} />

export default AltsLandingPage
