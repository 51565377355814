// @flow
import React from 'react'
import styled from '@emotion/styled'

import styles from './toogleLabel.styles'

const Label = styled('span')(styles.label)
const Icon = styled('span')(styles.icon)
const Title = styled('span')(styles.title)
const SubTitle = styled('span')(styles.subTitle)

type toogleLabelProps = {
  icon: any,
  title: string,
  subTitle: string,
}

const ToogleLabel = ({ icon: IconComponent, title, subTitle }: toogleLabelProps) => (
  <Label>
    <Icon>{<IconComponent />}</Icon>
    <Title size={4}>{title}</Title>
    <SubTitle>{subTitle}</SubTitle>
  </Label>
)

export default ToogleLabel
