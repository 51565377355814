// @flow
/* eslint-disable no-underscore-dangle */
import 'react-app-polyfill/ie9'
// @FIXME: need to determine how this gets added
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime'
import 'core-js/es6/string'
import 'core-js/fn/object/keys'
import 'core-js/fn/array/find-index'
import 'core-js/fn/object/values'
import 'core-js/fn/array/find'
import 'core-js/fn/array/flat-map'
import 'core-js/fn/array/includes'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/weak-map'
import 'core-js/fn/reflect'

import 'isomorphic-fetch'

import 'sanitize.css'
import React from 'react'
import { render, hydrate } from 'react-dom'
import { preloadReady } from 'react-loadable'
import { Router } from 'react-router-dom'
import WebFont from 'webfontloader'
import ReactGA4 from 'react-ga4'
import SplunkOtelWeb from '@splunk/otel-web'
import Hotjar from '@hotjar/browser'

import setupIcons from '@mlcl-digital/mlcl-design/lib/base/setupIcons'
import setupProIcons from '@mlcl-digital/mlcl-design/lib/base/setupProIcons'

// redux.
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { init as initStore, getPersistor } from './store'

// Cobrowse script
import './scripts/cobrowse'

import AppRoot from './AppRoot'
import { setServerSideRenderingState } from './RouteHandler'
import { isBrowser } from './utils/browserUtils'
import { configurePrimaryAuthClient } from './utils/oktaUtils'

// InMoment script
import './scripts/inMoment'
import { ADVISOR_PORTAL, CUSTOMER_PORTAL } from './constants/site'

let isRehydration = false

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.
  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.
  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null
const jssRawJson = document.getElementById('__JSS_STATE__')
if (jssRawJson) {
  __JSS_STATE__ = JSON.parse(jssRawJson.innerHTML)
}
if (__JSS_STATE__) {
  // push the initial SSR state into the route handler, where it will be used.
  setServerSideRenderingState(__JSS_STATE__)

  // when React initializes from a SSR-based initial state, you need to render with `hydrate`
  //   instead of `render`.
  isRehydration = true
}

let __PRELOADED_STATE__ = null
const reduxRawJson = document.getElementById('__PRELOADED_STATE__')
if (reduxRawJson) {
  __PRELOADED_STATE__ = JSON.parse(reduxRawJson.innerHTML)
}
const getStore = __PRELOADED_STATE__ ? initStore(__PRELOADED_STATE__) : initStore()

WebFont.load({
  google: {
    families: ['Source Sans Pro:400,400i,600', 'sans-serif'],
  },
})

/** Adding font awesome icons */
setupIcons()
setupProIcons()

const renderWithPersistGate = persistor => (
  <PersistGate loading={null} persistor={persistor}>
    <AppRoot path={window.location.pathname} Router={Router} />
  </PersistGate>
)

// render application.
getStore.then(getStore).then(async store => {
  let app = null
  const { config } = store.getState()
  const rootElement = document.getElementById('root')

  if (config.MLCL_GA4_MEASUREMENT_ID) {
    ReactGA4.initialize(config.MLCL_GA4_MEASUREMENT_ID)
  }

  if (config.SPLUNK_APP) {
    SplunkOtelWeb.init({
      realm: config.SPLUNK_REALM,
      rumAccessToken: config.SPLUNK_TOKEN,
      applicationName: config.SPLUNK_APP,
      deploymentEnvironment: config.SPLUNK_ENV,
    })
  }

  if (config.HOTJAR_SITE_ID) {
    // nonce used for content security policy
    Hotjar.init(config.HOTJAR_SITE_ID, config.HOTJAR_VERSION, {
      nonce: config.HOTJAR_NONCE,
    })
  }

  if (isBrowser()) {
    const persistor = getPersistor()
    if ([ADVISOR_PORTAL, CUSTOMER_PORTAL].includes(config.SITE)) {
      await configurePrimaryAuthClient({}, config)
    }
    app = <Provider store={store}>{renderWithPersistGate(persistor)}</Provider>
  } else {
    app = (
      <Provider store={store}>
        <AppRoot path={window.location.pathname} Router={Router} />
      </Provider>
    )
  }

  if (isRehydration) {
    preloadReady().then(() => {
      // after code split components are loaded, rehydrate application.
      hydrate(app, rootElement)
    })
  } else {
    render(app, rootElement)
  }
})
