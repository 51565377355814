// @flow
import React from 'react'
import styled from '@emotion/styled'
// components.
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { IconDocument16, IconArrowUp16 } from '../../../../atoms/Icons'
import Button from '../../../../atoms/Button'
// utils.
import { trimFileNameByChar } from '../../../../../utils/fileUpload'
// styles.
import styles from './fileUploadingProgress.styles'

const Container = styled('div')(({ percentage, hasError, hideBorderColor }) =>
  styles.container(percentage, hasError, hideBorderColor)
)
const Wrapper = styled('div')(styles.wrapper)
const IconDocument = styled(IconDocument16)(styles.documentIcon)
const IconArrowUp = styled(IconArrowUp16)(styles.documentIcon)
const FileErrorText = styled('span')(styles.fileErrorText)
const ErrorMessage = styled('div')(styles.errorMessage)
const RemoveFileButton = styled(Button)(styles.removeFileButton)
const WarningIcon = styled(Icon)(styles.warningIcon)

type FileUploadingProgressProps = {
  fileData: Object<Object>,
  // remove file handler
  handleFileRemove: Function,
  // file uploading meta fields
  fileUploadMeta: {
    fileUploading: string,
    fileRemoveButtonLabel: string,
  },
  showFullFileName: boolean,
}

const FileUploadingProgress = ({
  fileData,
  fileUploadMeta: { fileUploading, fileRemoveButtonLabel, fileUploadSuccess, fileUploadFailure },
  handleFileRemove = false,
  showFullFileName,
}: FileUploadingProgressProps) => {
  const {
    fileId,
    file: { name },
    hasValidationError,
    loading,
    fileValidationErrorLabel,
    uploading,
    hasNetworkError,
  } = fileData

  const isUploading = loading < 100 && uploading
  const isUploaded = loading === 100 && !uploading

  const fileName = showFullFileName ? name : trimFileNameByChar(name, 20)
  return (
    <>
      <Container
        percentage={loading}
        hasError={hasValidationError || hasNetworkError}
        hideBorderColor={Array.isArray(fileValidationErrorLabel)}
      >
        <Wrapper>
          <span>
            {!isUploading && !isUploaded ? <IconDocument /> : <IconArrowUp />}
            {fileName}
          </span>

          {hasValidationError && !Array.isArray(fileValidationErrorLabel) ? (
            <FileErrorText>{fileValidationErrorLabel}</FileErrorText>
          ) : null}

          {isUploading && !hasNetworkError && <span>{fileUploading}</span>}
          {isUploaded && !hasNetworkError && <span>{fileUploadSuccess}</span>}
          {hasNetworkError && <span>{fileUploadFailure}</span>}
          {!isUploading && !isUploaded && (
            <RemoveFileButton type="tertiary" onClick={() => handleFileRemove(fileId)}>
              {fileRemoveButtonLabel}
            </RemoveFileButton>
          )}
        </Wrapper>
      </Container>
      {Array.isArray(fileValidationErrorLabel) &&
        fileValidationErrorLabel.map(errorLabel => (
          <ErrorMessage>
            <WarningIcon iconName={['far', 'fa-triangle-exclamation']} />
            {errorLabel}
          </ErrorMessage>
        ))}
    </>
  )
}

export default FileUploadingProgress
