import { space, mediaQueries, setColumns, fontSize } from '../../../styles'

const styles = {
  offset: {
    position: 'relative',
    marginTop: -space(7),
  },
  base: {
    padding: `${space(3, true)} ${space(2, true)} ${space(8, true)}`,

    '& label': {
      marginBottom: 0,
    },

    [mediaQueries.md]: {
      ...setColumns(1.25),
      display: 'flex',
      paddingTop: space(7),
      paddingLeft: space(13),
      paddingRight: space(13),
    },
  },
  body: {
    maxWidth: '450px',
    [mediaQueries.lg]: {
      ...setColumns(1.25),
    },
  },
  headingWrap: {
    marginBottom: space(2, true),
    fontSize: fontSize.lg,
  },
  section: {
    marginBottom: space(1),
  },
}
export default styles
