import React from 'react'
import { objectOf, object, shape, string } from 'prop-types'

import ConciergeFooterComponent from '../../../organisms/ConciergeFooter'

import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

const ConciergeFooter = ({ fields, params }) => (
  <ConciergeFooterComponent fields={reduceAuthorableFields(fields)} params={params} />
)
ConciergeFooter.propTypes = {
  fields: objectOf(object).isRequired,
  params: shape({
    Identifier: string,
  }).isRequired,
}
export default ConciergeFooter
