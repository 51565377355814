import { fontSize, colours } from '../../../styles'

const dimensions = {
  outer: 14,
  inner: 6,
}

const styles = {
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
  },
  // eslint-disable-next-line no-unused-vars
  label: theme => ({
    cursor: 'pointer',
    fontSize: fontSize.xs,
    lineHeight: fontSize.sm,
    paddingLeft: 20,
    position: 'relative',
    display: 'block',

    '&:before, &::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: '50%',
    },

    '&:before': {
      backgroundColor: colours.white,
      boxShadow: `inset 0 0 0 2px ${colours.darkestGrey}`,
      height: `${dimensions.outer}px`,
      width: `${dimensions.outer}px`,
    },

    '&:after': {
      width: `${dimensions.inner}px`,
      height: `${dimensions.inner}px`,
      left: `${(dimensions.outer - dimensions.inner) / 2}px`,
    },
  }),
  // eslint-disable-next-line no-unused-vars
  input: theme => ({
    opacity: '0',
    position: 'absolute',
    zIndex: '1',
    '&:checked + label': {
      '&::after': {
        backgroundColor: colours.darkestGrey,
      },
    },
  }),
}

export default styles
