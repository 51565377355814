// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

// components.
import Heading from '../../../../../atoms/Heading'
import QuoteSummaryPolicyBenefitsInfo from './PolicyBenefitsInfo'
import QuoteSummaryPaymentBreakupRow from './PaymentBreakupRow'

// constants.
import { PAYMENT_FREQUENCY_OPTIONS } from '../../../../../../constants/forms'
import { MULTI_COVER_DISCOUNT } from '../../../../../../constants/policies'

// styles.
import styles from './policy.styles'

// utils.
import { dollarAmountWithCommasAndTwoDecimal } from '../../../../../../utils/formUtils'
import {
  healthyLivingDiscountVal,
  getPolicyHasDiscountAndAmount,
} from '../../../../../../utils/extendedQuoteUtils'
import { renamePolicy } from '../../../../../../utils/quoteUtils'
import { islegacySystemProductCodeGreatorThanFive } from '../../../../../../utils/commonUtils'

type QuoteSummaryPolicyProps = {
  // Fields object containing authorable fields.
  fields: Object,
  // AppliedPolicy object containing particular policy information.
  appliedPolicy: Object,
  // ProductRules object containing product rules information.
  productRules: Object,
  // all policies in quote.
  policyStructure: Array<Object>,
  // options for projections and healthy living discount
  options: Object,
}

const QuoteSummaryPolicyRoot = styled('div')(styles.quoteSummaryPolicyRoot)
const QuoteSummaryPolicyHeader = styled('div')(styles.quoteSummaryPolicyHeader)
const QuoteSummaryPolicyName = styled(Heading)(styles.quoteSummaryPolicyName)
const QuoteSummaryPolicyFreqWrapper = styled('div')(styles.quoteSummaryPolicyFreqWrapper)
const QuoteSummaryPolicyFreqLabel = styled('span')(styles.quoteSummaryPolicyFreqLabel)
const QuoteSummaryPolicyFreqValue = styled('span')(styles.quoteSummaryPolicyFreqValue)
const QuoteSummaryPolicyTable = styled('div')(styles.quoteSummaryPolicyTable)
const QuoteSummaryPolicyTableHeadWrapper = styled('div')(styles.quoteSummaryPolicyTableHeadWrapper)
const QuoteSummaryPolicyTableHead = styled(Heading)(styles.quoteSummaryPolicyTableHead)

/**
 * QuoteSummaryPolicy
 * This component will render the complete information of a particular premium benefit
 *
 * @param {object} fields
 * @param {object} appliedpolicy
 * @param {object} productRules
 * @param {Object[]} policyStructure
 */
const QuoteSummaryPolicy = ({
  fields,
  appliedPolicy,
  productRules,
  policyStructure,
  options,
}: QuoteSummaryPolicyProps) => {
  const {
    quoteSummaryPaymentFrequency,
    quoteSummaryPolicyBenefit,
    quoteSummaryPaymentStruct,
    quoteSummarySumInsured,
    quoteSummaryPremium,
    quoteSummaryInsidePolicyNamePrefix,
    quoteSummaryOutsidePolicyNamePrefix,
    quoteSummaryWrapPolicySuffix,
    quoteSummarySuperPolicySuffix,
    quoteSummaryOutsidePolicySuffix,
    QuoteSummaryStampDuty,
    QuoteSummaryPolicyPayment,
    QuoteSummaryHealthyLivingDiscount,
    QuoteSummaryHealthyLivingToolTip,
    MultiCoverDiscountLabel,
    MultiCoverDiscountTooltip,
  } = fields

  const paymentFreqVal = PAYMENT_FREQUENCY_OPTIONS.find(
    translationObject => translationObject.value === appliedPolicy.paymentFrequency
  ).label

  return (
    <Fragment>
      <QuoteSummaryPolicyRoot>
        <QuoteSummaryPolicyHeader>
          <QuoteSummaryPolicyName size="3">
            {renamePolicy(
              appliedPolicy,
              quoteSummaryInsidePolicyNamePrefix,
              quoteSummaryOutsidePolicyNamePrefix,
              quoteSummaryWrapPolicySuffix,
              quoteSummarySuperPolicySuffix,
              quoteSummaryOutsidePolicySuffix
            )}
          </QuoteSummaryPolicyName>
          <QuoteSummaryPolicyFreqWrapper>
            <QuoteSummaryPolicyFreqLabel>
              {quoteSummaryPaymentFrequency}
            </QuoteSummaryPolicyFreqLabel>
            <QuoteSummaryPolicyFreqValue>{paymentFreqVal}</QuoteSummaryPolicyFreqValue>
          </QuoteSummaryPolicyFreqWrapper>
        </QuoteSummaryPolicyHeader>
        <QuoteSummaryPolicyTable>
          <QuoteSummaryPolicyTableHeadWrapper>
            <QuoteSummaryPolicyTableHead size="4">
              {quoteSummaryPolicyBenefit}
            </QuoteSummaryPolicyTableHead>
            <QuoteSummaryPolicyTableHead size="4">
              {quoteSummaryPaymentStruct}
            </QuoteSummaryPolicyTableHead>
            <QuoteSummaryPolicyTableHead size="4">
              {quoteSummarySumInsured}
            </QuoteSummaryPolicyTableHead>
            <QuoteSummaryPolicyTableHead size="4">
              {quoteSummaryPremium}
            </QuoteSummaryPolicyTableHead>
          </QuoteSummaryPolicyTableHeadWrapper>
          {appliedPolicy.covers && appliedPolicy.covers.length > 0 ? (
            <QuoteSummaryPolicyBenefitsInfo
              fields={fields}
              appliedPolicy={appliedPolicy}
              productRules={productRules}
              policyStructure={policyStructure}
            />
          ) : null}
        </QuoteSummaryPolicyTable>
        {appliedPolicy.stampDuty ? (
          <QuoteSummaryPaymentBreakupRow
            name={QuoteSummaryStampDuty}
            amount={dollarAmountWithCommasAndTwoDecimal(appliedPolicy.stampDuty)}
          />
        ) : null}
        {islegacySystemProductCodeGreatorThanFive(appliedPolicy?.legacySystemProductCode) &&
          getPolicyHasDiscountAndAmount(MULTI_COVER_DISCOUNT, appliedPolicy)
            .isPolicyHasDiscount && (
            <QuoteSummaryPaymentBreakupRow
              toolTip={MultiCoverDiscountTooltip}
              name={MultiCoverDiscountLabel}
              amount={`${
                getPolicyHasDiscountAndAmount(MULTI_COVER_DISCOUNT, appliedPolicy).discountAmount
              }%`}
              uniqueId="MCD"
            />
          )}
        {options && options.healthyLivingDiscount && (
          <QuoteSummaryPaymentBreakupRow
            toolTip={QuoteSummaryHealthyLivingToolTip}
            name={QuoteSummaryHealthyLivingDiscount}
            amount={`${healthyLivingDiscountVal(appliedPolicy.covers)}%`}
          />
        )}
        {appliedPolicy.totalPremiumAmount && (
          <QuoteSummaryPaymentBreakupRow
            name={QuoteSummaryPolicyPayment.replace('{0}', paymentFreqVal)}
            amount={dollarAmountWithCommasAndTwoDecimal(appliedPolicy.totalPremiumAmount)}
          />
        )}
        {appliedPolicy.paymentInstruction && appliedPolicy.paymentInstruction.paymentAmount ? (
          <QuoteSummaryPaymentBreakupRow
            name=""
            amount={dollarAmountWithCommasAndTwoDecimal(
              appliedPolicy.paymentInstruction.paymentAmount
            )}
          />
        ) : null}
      </QuoteSummaryPolicyRoot>
    </Fragment>
  )
}

export default QuoteSummaryPolicy
