// @flow
import React from 'react'
import styled from '@emotion/styled'
import { renderTextField } from '../../../../../utils/sitecoreUtils'

import styles from './updateMethod.styles'

import { IconEdit16 } from '../../../../atoms/Icons'

const Wrapper = styled('div')(styles.wrapper)
export const BackButton = styled('button')(styles.updateButton)

type UpdateMethodProps = {
  fields: Object<Object>,
  isLifeInsured: boolean,
  handlePersonalStatementOpenModal: Function,
}

const UpdateMethod = ({
  fields,
  isLifeInsured,
  handlePersonalStatementOpenModal,
}: UpdateMethodProps) => {
  const { personalStatementPageUpdateLink, personalStatementPageNeedHelpLink } = fields
  return (
    <Wrapper>
      <BackButton onClick={handlePersonalStatementOpenModal} type="button">
        <IconEdit16 />
        {!isLifeInsured
          ? renderTextField(personalStatementPageUpdateLink)
          : renderTextField(personalStatementPageNeedHelpLink)}
      </BackButton>
    </Wrapper>
  )
}

export default UpdateMethod
