import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, fontSize, space, fontFamily, mediaQueries, fontWeights } = Variables

const styles = {
  wrapper: {
    width: '100%',
    marginBottom: space(4, true),
    [mediaQueries.md]: {
      width: '90%',
      marginBottom: 0,
    },
    [mediaQueries.lg]: {
      width: '100%',
    },
    backgroundColor: colours.tertiarySix,
    padding: `${space(5, true)} ${space(3, true)} ${space(3, true)}`,
    border: `solid 1px ${colours.tertiaryThree}`,
  },
  itemContainer: {
    color: colours.mediumDarkGrey,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  valueContainer: {
    flex: 1,
  },
  container: {
    width: '100%',
  },
  title: ({ applicationView }) => ({
    fontSize: fontSize.xs,
    fontFamily: fontFamily.sourceSans,
    lineHeight: fontSize.md,
    color: colours.darkGrey,
    marginBottom: space(4, true),
    fontWeight: applicationView ? 'bold' : 'normal',
    borderBottom: applicationView ? `1px solid ${colours.lightestGrey}` : 'none',
    paddingBottom: applicationView ? space(4, true) : 0,
  }),
  label: ({ applicationView }) => ({
    opacity: 1,
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    width: applicationView ? space(23) : space(18),
  }),
  column: {
    width: '100%',
    display: 'inline-block',
    [mediaQueries.md]: {
      verticalAlign: 'top',
    },
  },
  valueWapper: {
    display: 'block',
    padding: `0 ${space(3, true)} ${space(4, true)}`,
    wordBreak: 'keep-all',
  },
}

export default styles
