// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Hero, PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// components.
import TabBar from '../../molecules/TabBar'

// constants.
import { PARTY_NO } from '../../../constants/adviser'

// schema.
import SCHEMA from './advisorAccountSettingsTabs.schema'

// styles.
import styles from './advisorAccountSettings.styles'

// utils.
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { idMaker } from '../../../utils/formUtils'

type AdvisorAccountSettingsProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // advisorCode mapped to prop from memberdetails in redux
  advisorCode: string,
  // action creator to set default navigation.
  resetNavigationType: Function,
  // action creator to set default footer.
  resetFooterType: Function,
}

const Wrap = styled(PageWrap)(styles.offset)

class AdvisorAccountSettings extends Component<AdvisorAccountSettingsProps> {
  constructor(props) {
    super(props)
    // set default navigation & footer
    const { resetNavigationType, resetFooterType } = this.props
    this.state = {
      currentTab: 0,
    }
    this.setActiveTab = this.setActiveTab.bind(this)
    resetNavigationType()
    resetFooterType()
  }

  componentDidMount() {
    const event = createEvent({
      GA: {
        category: 'Account settings',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Account settings - View',
        },
      },
    })
    event.end()
  }

  setActiveTab(index, id) {
    this.setState({ currentTab: index || 0 })
    const {
      fields: {
        supportStaffTabTitle: { value },
      },
    } = this.props
    if (id === idMaker(value)) {
      const event = createEvent({
        GA: {
          category: 'Adviser Support staff',
          action: 'View',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Adviser Support staff - View',
          },
        },
      })
      event.end()
    }
  }

  render() {
    const { fields, advisorCode } = this.props
    const { currentTab } = this.state

    const heroAdvisorAccountFields = {
      heading: get(fields, 'heading.value', ''),
      content: get(fields, 'content.value', ''),
    }
    return (
      <Fragment>
        <Hero fields={heroAdvisorAccountFields} contentSuffix={advisorCode} />
        <Wrap>
          <TabBar
            currentTab={currentTab}
            onTabChange={this.setActiveTab}
            schema={SCHEMA({ fields: reduceAuthorableFields(fields), originalFields: fields })}
          />
        </Wrap>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ advisor: { details, hasFetchError, hasUpdateError } }) => {
  const identifiers = get(details, 'identifiers', [])
  const advisor = identifiers.length ? identifiers.find(item => item.type === PARTY_NO) : ''
  return {
    advisorCode: advisor ? advisor.value : '',
    hasFetchError,
    hasUpdateError,
  }
}

const { resetNavigationType, resetFooterType } = actionCreators
const mapDispatchToProps = { resetNavigationType, resetFooterType }

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorAccountSettings)
