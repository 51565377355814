// @flow
import React, { Fragment, type Node } from 'react'
import styled from '@emotion/styled'

import styles from './accordionItem.styles'

type AccordionItemProps = {
  // accordion boolean state
  isOpen: boolean,
  // accordion item label
  label: Node,
  // accordion child item
  children: Node,
  // open icon
  openIcon: Node,
  // close icon
  closeIcon: Node,
  // toggle accordion state
  onToggle: Function,
  // item key
  labelKey: string,
  // number of tasks against requirement
  noOfTask?: string,
}

const AccordItemRoot = styled('div')(({ isPaddingNeeded }) =>
  styles.accordItemRoot(isPaddingNeeded)
)
const AccordItemLabel = styled('div')(styles.accordItemLabel)
const AccordItemIcon = styled('div')(styles.accordItemIcon)
const NoOfTasks = styled('p')(styles.noOfTasks)

const AccordionItem = (props: AccordionItemProps) => {
  const {
    isOpen,
    label,
    children,
    openIcon,
    closeIcon,
    onToggle,
    labelKey,
    noOfTask,
    removeIcon,
    dataTestId,
    ...rest
  } = props
  return (
    <Fragment>
      <AccordItemRoot
        data-testid={dataTestId || `accordian-item--${labelKey}`}
        onClick={() => onToggle(labelKey)}
        {...rest}
      >
        <AccordItemLabel>{label}</AccordItemLabel>
        {!removeIcon ? (
          <AccordItemIcon>
            {!isOpen && openIcon}
            {isOpen && closeIcon}
          </AccordItemIcon>
        ) : null}
        {noOfTask !== '' && <NoOfTasks>{noOfTask}</NoOfTasks>}
      </AccordItemRoot>
      {isOpen && children}
    </Fragment>
  )
}

AccordionItem.defaultProps = {
  noOfTask: '',
}

export default AccordionItem
