import { mediaQueries, colours, fontSize, space, fontWeights, shadows } from '../../../../../styles'
import { APPLICATION_STAGE_STEP } from '../../../../../constants/application'

const colorMap = {
  [APPLICATION_STAGE_STEP.completed]: {
    background: colours.lightGreen,
    foreground: colours.white,
  },
  [APPLICATION_STAGE_STEP.partiallyComplete]: {
    background: colours.lighterOrange,
    foreground: colours.white,
  },
  [APPLICATION_STAGE_STEP.incomplete]: {
    background: colours.offWhite,
    foreground: colours.mediumGrey,
  },
  [APPLICATION_STAGE_STEP.lifeAssuredCompleted]: {
    background: colours.lightGreen,
    foreground: colours.white,
  },
}

const styles = {
  wrapper: {
    flex: '0 0 100%',
    textAlign: 'center',
    backgroundColor: colours.white,
    paddingTop: space(2, true),
    boxShadow: shadows.mid,
    marginBottom: space(4, true),

    [mediaQueries.sm]: {
      flexBasis: 'calc(50% - 12px)',
    },
    [mediaQueries.lg]: {
      flexBasis: 'calc(25% - 12px)',
      marginBottom: 0,
    },
  },
  title: {
    fontSize: fontSize.sm,
    lineHeight: space(3, true),
    color: colours.black,
    fontWeight: fontWeights.bold,
    margin: 0,
  },
  iconContainer(status) {
    return {
      width: space(10, true),
      height: space(10, true),
      borderRadius: '50%',
      backgroundColor: colorMap[status] ? colorMap[status].background : colours.offWhite,
      display: 'flex',
      color: colorMap[status] ? colorMap[status].foreground : colours.mediumGrey,
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  icon: {
    color: 'inherit',
  },
  progress(done = false) {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: space(29, true),
      justifyContent: 'center',
      color: done ? colours.mediumGreen : colours.darkestGrey,
    }
  },
  progressMessage: {
    margin: `${space(2, true)} 0 0`,
    fontWeight: fontWeights.bold,
    opacity: 1,
  },
  buttonContainer(done = false) {
    return {
      backgroundColor: !done ? colours.lightestGreen : colours.lightestGrey,
      padding: `${space(1, true)} 0`,
    }
  },
}

export default styles
