// @flow
import get from 'lodash/get'
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Loader, Textarea, FormBlock } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// atoms.
import Select from '../../atoms/Select'
import Button from '../../atoms/Button'
import { IconThumbsUp32 } from '../../atoms/Icons'

// molecules
import PersonalStatementLayout from '../../molecules/PersonalStatementLayout'

// helpers.
import { errorCheck } from '../../../utils/formUtils'
import { renderTextField } from '../../../utils/sitecoreUtils'

// constants
import { PREFERRED_YES } from '../../../constants/policies'
import { ADVISOR_PORTAL } from '../../../constants/site'
import {
  COMPLAINT_CONTACT_TYPE,
  DISABLE_COMPLAINTS_FEATURE_SWITCH,
} from '../../../constants/contactMLC'

// schema.
import SCHEMA, { FORM_ID } from './contactMLC.schema'

// styles.
import styles from './contactMLC.styles'
import { isFeatureEnabledForAP, isFeatureEnabledForCP } from '../../../utils/commonUtils'

// styled elements
export const SelectWrap = styled(Select)(styles.select)
export const Footer = styled('footer')(styles.footer)
export const TextareaWrap = styled(Textarea)(styles.textarea)
const FormBlockWrap = styled(FormBlock)(styles.formBlock)
const Label = styled('label')(styles.label)
const ContentWrap = styled('div')(styles.contentWrap)
const Anchor = styled('a')(styles.anchor)
const ContentWrapPrimary = styled('div')(styles.contentWrapPimary)
const HeadContentWrap = styled('div')(styles.contentWrapHeadPimary)
const BlockWrap = styled('div')(styles.blockWrap)
const LoaderWrap = styled(Loader)(styles.loader)
export const EmailMLCWrap = styled('div')(styles.contentFormBodyWrap)

// @flow
type ContactMLCProps = {
  // To render all labels from sitecore content editor
  fields: {},
  // Redux actions available to the component.
  actions: Object,
  // Redux form data of the component.
  form: Object,
  // MasterList to get category data.
  masterList: Object,
  // advior details.
  advisor: Object,
}

type ContactMLCState = {
  schema?: Object,
}
// Component for contact MLC.
export class ContactMLC extends Component<ContactMLCProps, ContactMLCState> {
  constructor(props: Object) {
    super(props)
    const {
      actions: { formInit, getMasterList, initailalizeContactMLC },
      fields,
      masterList: { data },
    } = props
    const schema = SCHEMA(fields)
    formInit(FORM_ID, schema)
    if (!data) getMasterList()

    this.state = {
      schema,
    }
    this.submitButtonClicked = false
    initailalizeContactMLC()
  }

  componentDidUpdate() {
    if (this.submitButtonClicked) {
      this.submitButtonClicked = false
      this.onSubmit()
    }
  }

  // handle changes on form elements.
  handleFieldChange = (value, name): void => {
    const { actions } = this.props
    const { schema } = this.state
    const { formUpdateField, formValidate } = actions

    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  // handle blur on comments or question field
  handleFieldBlur = (event, name): void => {
    const { value } = event.target
    this.handleFieldChange(value, name)
  }

  // creates category dropdown options
  createOptions = (options = []) =>
    options.map(option => ({ label: option.value, value: option.code }))

  // handles category select change
  onSelectOfCategory = (option): void => {
    const { actions } = this.props
    const { formUpdateField } = actions
    formUpdateField(FORM_ID, option.name, { error: { error: true }, value: '' })
    this.updateMaxLengthOfCommentsOrQuestions(option)
    this.handleFieldChange(option.value.value, option.name)
  }

  // updates max length of input characters for comments or questions
  updateMaxLengthOfCommentsOrQuestions = option => {
    const { fields } = this.props
    this.setState({
      schema: SCHEMA(fields, option.maxlength),
    })
  }

  // gets called on click of submit form
  onSubmit = () => {
    this.submitButtonClicked = false
    const {
      form: { fields },
      actions: { contactMLC },
      advisor,
      customerPersonalDetails,
    } = this.props

    const { category, commentsOrQuestions } = fields

    const isCustomer = !!get(customerPersonalDetails, 'details.contactMethods', false)

    const userEmail = !isCustomer
      ? get(advisor, 'details.contactMethods.emails', []).find(
          entry => entry.preferred === PREFERRED_YES
        )
      : get(customerPersonalDetails, 'details.contactMethods.emails', []).find(
          entry => entry.preferred === PREFERRED_YES
        )

    if (this.validateFormFields()) {
      const data = {
        code: category.value,
        body: commentsOrQuestions.value,
        firstName: isCustomer
          ? get(
              customerPersonalDetails,
              'details.firstName',
              get(customerPersonalDetails, 'details.businessName', '')
            )
          : get(advisor, 'details.firstName', get(advisor, 'details.businessName', '')),
        lastName: isCustomer
          ? get(customerPersonalDetails, 'details.lastName', '')
          : get(advisor, 'details.lastName', ''),
        emailfrom: get(userEmail, 'email', ''),
      }
      contactMLC(data)
    }
  }

  // validate form fields
  validateFormFields = () => {
    const { schema } = this.state
    const {
      form: { fields },
      actions: { formValidate, formUpdateField },
    } = this.props
    let formValid = true
    Object.keys(fields).forEach(key => {
      const data = {
        error: errorCheck(fields[key].value, schema[key].condition, schema[key].errorMsg),
        value: fields[key].value,
      }
      formUpdateField(FORM_ID, key, data)
      formValidate(FORM_ID, schema)
      if (formValid && data.error && data.error.error) formValid = false
    })
    return formValid
  }

  site = () => {
    const { config } = this.props
    return config && config.SITE === ADVISOR_PORTAL ? 'adviser' : 'customer'
  }

  filterEmailCategories = emailCategories => {
    const filteredEmailCategories = emailCategories.filter(emailCategory =>
      emailCategory[this.site()] ? Boolean(emailCategory) : false
    )
    return filteredEmailCategories
  }

  hideCommentsFieldForComplaints = category => {
    const {
      masterList: { data },
    } = this.props
    const { featureControlSwitch } = data
    return (
      category === COMPLAINT_CONTACT_TYPE &&
      ((this.site() === 'adviser' &&
        isFeatureEnabledForAP(featureControlSwitch, DISABLE_COMPLAINTS_FEATURE_SWITCH)) ||
        (this.site() === 'customer' &&
          isFeatureEnabledForCP(featureControlSwitch, DISABLE_COMPLAINTS_FEATURE_SWITCH)))
    )
  }

  render() {
    const {
      form,
      fields: {
        ContactMLCPageTitle,
        ContactMLCPageSubTitle,
        ContactMLCSelectCategoryText,
        ContactMLCCommentsAndQuestionsText,
        ContactMLCCallUsText,
        ContactMLCCallUsSubText,
        ContactMLCOusideAustraliaText,
        ContactMLCSendCommentAndQuestionText,
        ContactMLCCallUsNumber,
        ContactMLCOusideAustraliaNumber,
        ContactMLCSelectCategoryPlaceholder,
        ContactMLCSubmittedFormText,
        ContactMLCSubmittedFormThankText,
        ContactMLCEmailAddressLabel,
        ContactMLCEmailAddressLink,
        ComplaintsSelectionMessage,
      },
      masterList: { data },
      config,
    } = this.props

    if (!form || !data) return <LoaderWrap type="component" />

    const emailCategories = this.filterEmailCategories(get(data, 'emailCategories', []))
    const {
      fields: { category, commentsOrQuestions },
    } = form

    const showMLCEmailDetails =
      ContactMLCEmailAddressLabel.length > 0 &&
      ContactMLCEmailAddressLink.url.length > 0 &&
      ContactMLCEmailAddressLink.text.length > 0

    const layoutProps = {
      pageTitle: { value: ContactMLCPageTitle },
      pageSubTitle: { value: ContactMLCPageSubTitle },
      fields: {},
      theme: 'default',
    }
    const { contactMLC } = this.props
    return (
      <Fragment>
        <PersonalStatementLayout {...layoutProps} config={config}>
          {!contactMLC || (contactMLC && !contactMLC.isSendingSuccess) ? (
            <FormBlockWrap>
              <ContentWrapPrimary>
                <Label>{ContactMLCCallUsText}</Label>
                <Label>
                  <Anchor href={`tel:${ContactMLCCallUsNumber}`}>{ContactMLCCallUsNumber}</Anchor>
                </Label>
              </ContentWrapPrimary>
              <ContentWrap>{ContactMLCCallUsSubText}</ContentWrap>
              <ContentWrap>
                <Label>{ContactMLCOusideAustraliaText}</Label>
                <Label>
                  <Anchor href={`tel:${ContactMLCOusideAustraliaNumber}`}>
                    {ContactMLCOusideAustraliaNumber}
                  </Anchor>
                </Label>
              </ContentWrap>
              <HeadContentWrap>{ContactMLCSendCommentAndQuestionText}</HeadContentWrap>
              <form aria-labelledby="contactMLCForm">
                <SelectWrap
                  label={ContactMLCSelectCategoryText}
                  name="category"
                  id="category"
                  placeholder={ContactMLCSelectCategoryPlaceholder}
                  error={category.error.error}
                  value={category.value}
                  changeHandler={this.onSelectOfCategory}
                  options={this.createOptions(emailCategories)}
                  caption={category.error.error ? category.error.errorMsg : null}
                />
                {this.hideCommentsFieldForComplaints(category.value) ? (
                  renderTextField(ComplaintsSelectionMessage, true)
                ) : (
                  <>
                    <TextareaWrap
                      label={ContactMLCCommentsAndQuestionsText}
                      value={commentsOrQuestions.value}
                      name="commentsOrQuestions"
                      id="commentsOrQuestions"
                      error={commentsOrQuestions.error.error}
                      changeHandler={this.handleFieldChange}
                      blurHandler={this.handleFieldBlur}
                      caption={
                        commentsOrQuestions.error.error ? commentsOrQuestions.error.errorMsg : null
                      }
                      focusOnMount
                    />
                    {this.site() === 'adviser' && showMLCEmailDetails && (
                      <EmailMLCWrap>
                        <Label>{ContactMLCEmailAddressLabel}</Label>
                        <Anchor
                          onMouseDown={() => {
                            this.emailLink.click()
                          }}
                          href={ContactMLCEmailAddressLink.url}
                          ref={el => (this.emailLink = el)}
                        >
                          {ContactMLCEmailAddressLink.text}
                        </Anchor>
                      </EmailMLCWrap>
                    )}
                    <Footer>
                      <Button
                        type="secondary"
                        onClick={this.onSubmit}
                        onMouseDown={() => {
                          this.submitButtonClicked = true
                        }}
                      >
                        Submit
                      </Button>
                    </Footer>
                  </>
                )}
              </form>
            </FormBlockWrap>
          ) : (
            <BlockWrap>
              <IconThumbsUp32 />
              <ContentWrapPrimary>{ContactMLCSubmittedFormText}</ContentWrapPrimary>
              <ContentWrap>{ContactMLCSubmittedFormThankText}</ContentWrap>
            </BlockWrap>
          )}
        </PersonalStatementLayout>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  masterList,
  advisor,
  customerPersonalDetails,
  contactMLC,
  config,
}) => ({
  form: forms[FORM_ID],
  masterList,
  advisor,
  customerPersonalDetails,
  contactMLC,
  config,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactMLC)
