import { fontSize, colours, fontWeights, space } from '../../../../../styles'

const styles = {
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  icon: {
    color: colours.black,
    '& svg': {
      color: colours.black,
    },
    marginBottom: space(1),
  },
  title: {
    fontWeight: fontWeights.bold,
    fontSize: fontSize.sm,
    color: colours.black,
  },
  subTitle: {
    fontSize: fontSize.xxs,
    lineHeight: 1.4,
    marginTop: space(1),
    textAlign: 'center',
  },
}

export default styles
