import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, fontSize } = Variables

const styles = {
  marginBottom: space(9),
  '& label': {
    fontSize: fontSize.xs,
  },
  title: {
    marginRight: `calc(50% + ${space(1, true)})`,
    width: '100%',
    [mediaQueries.md]: {
      width: space(74, true),
    },
  },
  input: {
    width: '100%',
    [mediaQueries.md]: {
      width: space(74, true),
    },
  },
  inputPhone: {
    [mediaQueries.md]: {
      width: space(74, true),
    },
    input: {
      width: '100%',
    },
  },
  buttonsContainer: {
    width: '100%',
    [mediaQueries.md]: {
      width: space(74, true),
    },
  },
  spinner: {
    minHeight: 0,
  },
  postalAddressCheckbox: {
    marginBottom: space(3),
  },
}

export default styles
