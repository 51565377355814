import { SIDEBAR_DEFAULT_WIDTH, mediaQueries } from '../../../../../styles'

const styles = {
  base: (move, left, sidebarWidth = SIDEBAR_DEFAULT_WIDTH) => {
    // tablet needs sliding over less
    const transform = move
      ? {
          transform: `translate(${!left ? '-' : ''}${
            sidebarWidth - (sidebarWidth === SIDEBAR_DEFAULT_WIDTH ? 8 : 0)
          }px, 0)`,
        }
      : null

    const transformDesktop = move
      ? {
          transform: `translate(${!left ? '-' : ''}${sidebarWidth}px, 0)`,
        }
      : null

    return {
      transitionTimingFunction: 'ease',
      transitionDuration: '.2s',
      transitionProperty: 'transform',
      transitionDelay: move ? '.05s' : '0',
      ...transform,
      zIndex: 10,
      position: 'relative',

      [mediaQueries.tb]: {
        ...transformDesktop,
      },
    }
  },
  overlay: {
    zIndex: 1000,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,.2)',
  },
}

export default styles
