import { required, percentage, name } from '../../../../../utils/formUtils'

export const FORM_ID = 'remunerationOptions'

const schema = (fields = {}) => ({
  adviserOutlet: {
    condition: [{ condition: required, errorMsg: fields.adviserOutletMandatoryValidationError }],
    validate: true,
  },
  splitRenumeration: {
    condition: required,
    errorMsg: fields.prodPercentageSplitMandatoryError,
    validate: true,
  },
  otherAdviserCode: {
    condition: [{ condition: required, errorMsg: fields.otherAdviserCodeMandatoryValidationError }],
  },
  otherAdviserName: {
    condition: [
      { condition: required, errorMsg: fields.otherAdviserNameMandatoryValidationError },
      {
        condition: name,
        errorMsg: fields.otherAdviserNameValidNameError,
      },
    ],
  },
  productionPercentageSplit: {
    condition: [
      { condition: required, errorMsg: fields.prodPercentageSplitMandatoryError },
      {
        condition: percentage,
        errorMsg: fields.prodPercentageSplitRangeError,
      },
    ],
  },
  renewalPercentageSplit: {
    condition: [
      { condition: required, errorMsg: fields.renewalPercentageSplitMandatoryError },
      { condition: percentage, errorMsg: fields.renewalPercentageSplitRangeError },
    ],
  },
})

export default schema
