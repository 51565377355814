import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { space, colours, fontWeights } = Variables as VariablesType

const styles = {
  policyList: {
    listStyle: 'none',
    border: `1px solid ${colours.tertiaryFour}`,
    background: colours.tertiarySix,
    padding: space(3),
    margin: `${space(2.5, true)} 0 ${space(5, true)}`,
    li: {
      marginBottom: space(2.5),
      display: 'flex',
      justifyContent: 'space-between',
      p: {
        flex: 1,
        marginBottom: 0,
        paddingRight: space(2),
        '&:not(first-of-type)': {
          'span:first-of-type': {
            display: 'block',
            fontWeight: fontWeights.semiBold,
          },
        },
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
}

export default styles
