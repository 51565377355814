import {
  INIT_BSB_LOOKUP,
  VALIDATE_BSB,
  VALIDATE_BSB_SUCCESS,
  VALIDATE_BSB_FAILURE,
  VALIDATE_BSB_ERROR,
} from '../actions/types/bsbLookup'

export const initialFieldState = {
  isFetching: false,
  data: null,
  isError: false,
}

const bsbLookup = (state = {}, action) => {
  switch (action.type) {
    case INIT_BSB_LOOKUP: {
      const { fieldName } = action.payload
      return {
        ...state,
        [fieldName]: initialFieldState,
      }
    }
    case VALIDATE_BSB: {
      const {
        field: { name },
      } = action.payload
      return {
        ...state,
        [name]: {
          isFetching: true,
          data: null,
          isError: false,
        },
      }
    }
    case VALIDATE_BSB_SUCCESS: {
      const {
        field: { name },
        dataReceived,
      } = action.payload
      return {
        ...state,
        [name]: {
          isFetching: false,
          data: dataReceived,
          isError: false,
        },
      }
    }
    case VALIDATE_BSB_ERROR:
    case VALIDATE_BSB_FAILURE: {
      const {
        field: { name },
        dataReceived,
      } = action.payload
      return {
        ...state,
        [name]: {
          isFetching: false,
          isError: true,
          data: dataReceived,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default bsbLookup
