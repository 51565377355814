import React, { useEffect, useState, useMemo } from 'react'
import PropTypes, { shape } from 'prop-types'
import { Icons } from '@mlcl-digital/mlcl-design'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { createEvent } from '../../../utils/telemetry'

import styles from './altsDecreasePolicyCard.styles'

// utils
import { formatCurrency } from '../../../utils/quoteUtils'
import { renderTextField } from '../../../utils/sitecoreUtils'
import { getTotalSumInsuredAndTotalPremiums } from '../../../utils/alteration'

// components
import { AltsDecreaseCoverRow } from './AltsDecreaseCoverRow'
import WithLoader from '../WithLoader'

// constants
import { BENEFIT_NATURE_TYPE_RIDER_OPTIMISER } from '../../../constants/benefit'
import {
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
  SYSTEM_GENERATED_REASON,
  UNCHANGED,
} from '../../../constants/alterations'

const CoverGroupContainer = styled('div')(styles.coverGroupContainer)
const RowContainer = styled('div')(styles.rowContainer)
const GroupRow = styled('div')(({ isNewPremium }) => styles.groupRow({ isNewPremium }))
const StyledCardCell = styled('div')(styles.cardCell)
const GroupRowFirstCell = styled('div')(styles.groupRowFirstCell)
const EditButton = styled('button')(({ open }) => styles.editCoverGroupBtn({ open }))
const TotalLabel = styled('span')(styles.totalLabel)
const CoverDivider = styled('hr')(styles.coverDivider)

const { IconChevronDown16 } = Icons

const asciiBullet = String.fromCharCode(8226)
const WHITESPACE = ' '

const AltsDecreaseCoverRowGroup = ({
  coverGroup,
  fields,
  activeTabValue,
  policyType,
  isNewPremium,
  bancsPolicyNo,
  alteration,
  lifeInsured,
  listOfBenefitRowIds,
  setBenefitRowIdsList,
  policyInstanceNo,
  isFetchingCalculateQuote,
  isConfirmationPage,
  productId,
  isPolicyChangeDisabled,
}) => {
  const [coversExpanded, setCoversExpanded] = useState(false)

  const [totalSumInsured, setTotalSumInsured] = useState(0)
  const [totalPremiums, setTotalPremiums] = useState(0)

  const coverGroupId = `${policyInstanceNo}-${coverGroup && coverGroup[0].type}`

  useEffect(() => {
    setCoversExpanded(
      listOfBenefitRowIds.find(eachBenefitRow => eachBenefitRow.includes(coverGroupId))
    )
  }, [listOfBenefitRowIds])

  useEffect(() => {
    const totalValues = getTotalSumInsuredAndTotalPremiums(
      coverGroup,
      alteration,
      isNewPremium || isConfirmationPage
    )
    setTotalSumInsured(totalValues.sumInsured)
    setTotalPremiums(totalValues.premiums)
  }, [alteration, coverGroup, isNewPremium, isConfirmationPage])

  const isEditedIcon = useMemo(
    () =>
      isNewPremium &&
      get(alteration, 'alteredBenefits', []).find(
        ({ benefitName, isSystemGenerated, benefitAlterationType, systemGeneratedReason }) => {
          const isAltered =
            benefitAlterationType !== UNCHANGED ||
            (benefitAlterationType === UNCHANGED &&
              systemGeneratedReason !== SYSTEM_GENERATED_REASON.NONE)
          return isSystemGenerated
            ? benefitName === coverGroup[0].benefitName && isAltered
            : benefitName === coverGroup[0].benefitName
        }
      ),
    [alteration, isNewPremium]
  )

  const toggleShowCovers = () => {
    const gaEvents = {
      oldPremium: {
        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
        action: coversExpanded ? 'Existing premium - Hide cover' : 'Existing premium - View cover',
      },
      newPremium: {
        category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
        action: 'Decrease cover edit cover in quote screen',
      },
    }

    const gaEvent = isNewPremium ? 'newPremium' : 'oldPremium'
    const event = createEvent({
      GA: gaEvents[gaEvent],
      Splunk: {
        attributes: {
          'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - ${gaEvents[gaEvent].category}`,
        },
      },
    })
    event.end()

    setCoversExpanded(prev => !prev)
  }

  const showedBenefitName = `${coverGroup[0] && coverGroup[0].benefitName} ${
    get(coverGroup[0], 'tpdDefinition') ? `(${coverGroup[0].tpdDefinition})` : ''
  } ${
    get(coverGroup[0], 'optimiserParentBenefitReference.benefitNature', '') ===
    BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
      ? get(fields, 'OptimiserLbl.value', '')
      : ''
  }`

  return (
    <CoverGroupContainer>
      <RowContainer>
        <GroupRow isNewPremium={isNewPremium} id={coverGroupId}>
          <GroupRowFirstCell>
            {coverGroup[0] && coverGroup[0].benefitName}
            {asciiBullet}
            {WHITESPACE}
            <span>
              {coverGroup.length.toString()}
              {WHITESPACE}
              {coverGroup.length === 1
                ? renderTextField(fields.BenefitInstanceLabel)
                : renderTextField(fields.BenefitInstancesLabel)}
            </span>
          </GroupRowFirstCell>
          <StyledCardCell>
            {!coverGroup[0].hideSumInsured && (
              <span>
                <TotalLabel>Total</TotalLabel>
                <br />
                {formatCurrency(totalSumInsured, '$', 0)}
              </span>
            )}
          </StyledCardCell>
          <StyledCardCell>
            <WithLoader
              isLoading={isFetchingCalculateQuote && isNewPremium}
              overlay
              isOverlayComponent
              loaderProps={{
                spinnerSize: 25,
              }}
            >
              <span>
                <TotalLabel>Total</TotalLabel>
                <br />
                {formatCurrency(totalPremiums)}
              </span>
            </WithLoader>
          </StyledCardCell>
          <StyledCardCell isHeader />
          <StyledCardCell isHeader isCTA isEditedIcon={isEditedIcon}>
            <EditButton open={coversExpanded} type="button" onClick={toggleShowCovers}>
              {isNewPremium && renderTextField(fields.EditCoverDropdownLabel)}
              {(!isNewPremium || isConfirmationPage) &&
                renderTextField(
                  coversExpanded ? fields.HideCoverDropdownLabel : fields.ViewCoverDropdownLabel
                )}
              <IconChevronDown16 />
            </EditButton>
          </StyledCardCell>
        </GroupRow>
      </RowContainer>
      {coversExpanded && <CoverDivider />}
      {coversExpanded &&
        coverGroup.map(cover => (
          <AltsDecreaseCoverRow
            isNewPremium={isNewPremium}
            key={`${cover.type}-${cover.benefitInstanceNo}`}
            policyType={policyType}
            activeTabValue={activeTabValue}
            fields={fields}
            bancsPolicyNo={bancsPolicyNo}
            alteration={alteration}
            lifeInsured={lifeInsured}
            listOfBenefitRowIds={listOfBenefitRowIds}
            setBenefitRowIdsList={setBenefitRowIdsList}
            policyInstanceNo={policyInstanceNo}
            isFetchingCalculateQuote={isFetchingCalculateQuote}
            cover={cover}
            isConfirmationPage={isConfirmationPage}
            showedBenefitName={showedBenefitName}
            productId={productId}
            isPolicyChangeDisabled={isPolicyChangeDisabled}
          />
        ))}
    </CoverGroupContainer>
  )
}

AltsDecreaseCoverRowGroup.propTypes = {
  coverGroup: PropTypes.arrayOf(
    PropTypes.shape({
      isRemoved: PropTypes.bool,
      premiumStyle: PropTypes.string,
      benefitName: PropTypes.string,
      benefitCommencementDate: PropTypes.string,
      hideSumInsured: PropTypes.bool,
      coverAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disableSumInsured: PropTypes.bool,
      premiumAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isIPorBEBenefit: PropTypes.bool,
      periods: PropTypes.shape({
        coverPeriod: PropTypes.string,
        waitingPeriod: PropTypes.string,
      }),
      isOptionsVisible: PropTypes.bool,
      tpdDefinition: PropTypes.string,
      applicableFeatures: PropTypes.arrayOf(
        PropTypes.shape({
          featureApplicable: PropTypes.string,
          featureName: PropTypes.string,
          premium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
      optimiserParentBenefit: PropTypes.shape({
        name: PropTypes.string,
      }),
      parentBenefit: PropTypes.shape({
        name: PropTypes.string,
        commencementDate: PropTypes.string,
        policyNo: PropTypes.string,
        parentPolicyReferenceNo: PropTypes.string,
        parentType: PropTypes.string.isRequired,
        parentBenefitInstanceNo: PropTypes.string.isRequired,
      }),
      loading: PropTypes.arrayOf(
        PropTypes.shape({
          endDate: PropTypes.string,
          loadingType: PropTypes.string,
          loadingValue: PropTypes.number,
          loadingValueType: PropTypes.string,
          startDate: PropTypes.string,
          reason: PropTypes.arrayOf(
            PropTypes.shape({
              reasonCode: PropTypes.string,
              reasonDescription: PropTypes.string,
            })
          ),
        })
      ),
      exclusion: PropTypes.arrayOf(
        PropTypes.shape({
          endDate: PropTypes.string,
          referenceNo: PropTypes.string,
          exclusionCode: PropTypes.string,
          startDate: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      apEnabledFeatures: PropTypes.shape({
        apDecreaseBenefitPeriod: PropTypes.bool,
        apDecreaseSumInsured: PropTypes.bool,
        apIncreaseWaitingPeriod: PropTypes.bool,
        apRemoveBenefit: PropTypes.bool,
        apRemoveBenefitOption: PropTypes.bool,
      }),
    })
  ),
  activeTabValue: PropTypes.string,
  policyType: PropTypes.string,
  isNewPremium: PropTypes.bool,
  bancsPolicyNo: PropTypes.string.isRequired,
  policyInstanceNo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  alteration: PropTypes.shape({
    alteredBenefits: PropTypes.arrayOf(
      PropTypes.shape({
        benefitCode: PropTypes.string,
        benefitInstanceNo: PropTypes.string,
        newSumInsured: PropTypes.number,
      })
    ),
  }),
  lifeInsured: PropTypes.string.isRequired,
  listOfBenefitRowIds: PropTypes.arrayOf(PropTypes.string),
  setBenefitRowIdsList: PropTypes.func,
  isFetchingCalculateQuote: PropTypes.bool.isRequired,
  isConfirmationPage: PropTypes.bool,
  productId: PropTypes.string,
  isPolicyChangeDisabled: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    OptimiserLbl: shape({
      value: PropTypes.string,
    }),
    CancelCTA: shape({
      value: PropTypes.string,
    }),
    RemoveCTA: shape({
      value: PropTypes.string,
    }),
    Reduced: shape({
      value: PropTypes.string,
    }),
    ResetRowCTA: shape({
      value: PropTypes.string,
    }),
    BenefitPeriod: shape({
      value: PropTypes.string,
    }),
    WaitingPeriod: shape({
      value: PropTypes.string,
    }),
    BenefitOptions: shape({
      value: PropTypes.string,
    }),
    BenefitViewOptions: shape({
      value: PropTypes.string,
    }),
    LoadingAndExclusionsRemovedModalButton: shape({
      value: PropTypes.string,
    }),
    ViewLoadingsCTALabel: shape({
      value: PropTypes.string,
    }),
    ViewExclusionsCTALabel: shape({
      value: PropTypes.string,
    }),
    ViewLoadingsExclusionsCTALabel: shape({
      value: PropTypes.string,
    }),
    LoadingsModalHeader: shape({
      value: PropTypes.string,
    }),
    ExclusionsModalHeader: shape({
      value: PropTypes.string,
    }),
    LoadingsExclusionsModalHeader: shape({
      value: PropTypes.string,
    }),
    LoadingModalDescForRPM: shape({
      value: PropTypes.string,
    }),
    PercentageAppliedLabel: shape({
      value: PropTypes.string,
    }),
    LoadingsHeadingLabel: shape({
      value: PropTypes.string,
    }),
    ExclusionsHeadingLabel: shape({
      value: PropTypes.string,
    }),
    EditCoverDropdownLabel: shape({
      value: PropTypes.string,
    }),
    ViewCoverDropdownLabel: shape({
      value: PropTypes.string,
    }),
    HideCoverDropdownLabel: shape({
      value: PropTypes.string,
    }),
    BenefitInstanceLabel: shape({
      value: PropTypes.string,
    }),
    BenefitInstancesLabel: shape({
      value: PropTypes.string,
    }),
    BlockRemovalOption: shape({
      value: PropTypes.string,
    }),
    SumInsuredErrorMsg: shape({
      value: PropTypes.string,
    }),
    SumInsuredBlankErrorMsg: shape({
      value: PropTypes.string,
    }),
  }),
}

AltsDecreaseCoverRowGroup.defaultProps = {
  coverGroup: [],
  activeTabValue: '',
  isConfirmationPage: false,
  productId: '',
  policyType: '',
  isNewPremium: false,
  listOfBenefitRowIds: [''],
  setBenefitRowIdsList: () => {},
  fields: {
    CancelCTA: { value: 'Cancel' },
    RemoveCTA: { value: 'Remove' },
    Reduced: { value: 'Reduced' },
    ResetRowCTA: { value: 'Reset' },
    BenefitPeriod: { value: '' },
    OptimiserLbl: { value: '' },
    WaitingPeriod: { value: '' },
    BenefitOptions: { value: '' },
    BenefitViewOptions: { value: '' },
    LoadingAndExclusionsRemovedModalButton: { value: '' },
    ViewLoadingsCTALabel: { value: '' },
    ViewExclusionsCTALabel: { value: '' },
    ViewLoadingsExclusionsCTALabel: { value: '' },
    LoadingsModalHeader: { value: '' },
    ExclusionsModalHeader: { value: '' },
    LoadingsExclusionsModalHeader: { value: '' },
    LoadingModalDescForRPM: { value: '' },
    PercentageAppliedLabel: { value: '' },
    LoadingsHeadingLabel: { value: '' },
    ExclusionsHeadingLabel: { value: '' },
    EditCoverDropdownLabel: { value: '' },
    ViewCoverDropdownLabel: { value: '' },
    HideCoverDropdownLabel: { value: '' },
    BenefitInstanceLabel: { value: '' },
    BenefitInstancesLabel: { value: '' },
    SumInsuredBlankErrorMsg: { value: '' },
    SumInsuredErrorMsg: { value: '' },
    BlockRemovalOption: { value: '' },
  },
  alteration: null,
}

export { AltsDecreaseCoverRowGroup }
