// @flow
import React from 'react'
import Overview from '../../../organisms/ApplicationOverview'

import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

type ApplicationProps = {
  fields: Object<Object>,
}

const Application = ({ fields }: ApplicationProps) => (
  <Overview fields={reduceAuthorableFields(fields)} rawFields={fields} />
)

export default Application
