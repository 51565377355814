import {
  space,
  colours,
  fontSize,
  fontWeights,
  fontFamily,
  iconWidth,
  QUOTE_SUMMARY_POLICY_CARD_DEFAULT_WIDTH,
} from '../../../../../../styles'

const styles = {
  quoteSummaryHeaderRoot: {
    color: colours.black,
    marginBottom: space(2),
    position: 'relative',
    maxWidth: QUOTE_SUMMARY_POLICY_CARD_DEFAULT_WIDTH,
  },

  quoteSummaryContent: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  quoteSummaryBackLink: {
    fontSize: fontSize.xxs,
    lineHeight: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    marginBottom: space(1, true),
    color: colours.black,
    textDecoration: 'none',
    display: 'inline-block',
  },

  quoteSummaryBackLinkIcon: {
    color: colours.black,
    width: iconWidth.sm,
    marginRight: space(0.5, true),
  },

  quoteSummaryTitle: {
    fontFamily: fontFamily.charlie,
    fontSize: fontSize.lg,
    fontWeight: fontWeights.semiBold,
    marginBottom: space(1, true),
  },

  quoteSummarySubTitle: {},

  quoteSummaryDownloadLinkWrapper: {
    position: 'absolute',
    right: 0,
  },

  quoteSummaryDownloadLinkButton: {
    padding: 0,
    color: colours.mediumGreen,
    '&:hover': {
      label: {
        color: colours.darkGreen,
      },
      svg: {
        color: colours.darkGreen,
      },
    },
    cursor: 'pointer',
  },

  quoteDownloadIcon: {
    color: colours.mediumGreen,
    margin: space(1, true),
    width: space(2),
    height: space(2),
  },

  noPadding: { padding: 0 },
}

export default styles
