// @flow
import React from 'react'
// Organism
import ProgressTimelineWithComponents from '../../../organisms/ProgressTimelineWithComponents'

type AltsTimelineBarProps = {
  fields: Object,
}

const AltsTimelineBar = ({ fields }: AltsTimelineBarProps) => (
  <ProgressTimelineWithComponents fields={fields} />
)

export default AltsTimelineBar
