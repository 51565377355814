// @flow
import React, { useCallback, useState, Fragment, useEffect } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { Loader, Modal, Button } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'
import { createEvent } from '../../../../../utils/telemetry'

// styles
import styles from './SupportStaff.styles'

// components
import { STAFF_STATUS_BUCKET } from '../../../../../constants/adviser'
import SupportStaffRegisterForm from './SupportStaffRegisterForm'
import SupportStaffBulkUpload from './SupportStaffBulkUpload'

import {
  TOAST_ID_REMOVE_SUPPORT_STAFF,
  TOAST_TITLE_REMOVE_SUPPORT_STAFF_SUCCESS,
  TOAST_TITLE_REMOVE_SUPPORT_STAFF_ERROR,
} from '../../../../../constants/toast'
import Card from '../../../../atoms/Card'

// styled components
const Container = styled(Card)(styles.base)

// styled components
const SupportStaffListSection = styled('section')(styles.main)
const Title = styled('h3')(styles.title)
const SubTitle = styled('h3')(styles.subtitle)
const List = styled('div')(styles.list)
const ListRow = styled('div')(({ header, colored }) => styles.listRow(header, colored))
const ListItem = styled('div')(({ lastItem }) => styles.listItem(lastItem))
const Status = styled('button')(styles.status)
const DeleteButton = styled(Button)(styles.button)
const ModalBody = styled('div')(styles.modalBody)
const ModalFooter = styled('div')(styles.modalFooter)
const OffboardingModal = styled(Modal)(styles.offboardingModal)
const CancelButton = styled(Button)(styles.cancelButton)
const RegisterSupportStaffButton = styled(Button)(styles.registerSupportStaffButton)
const BulkUploadSupportStaffButton = styled(Button)(styles.bulkUploadSupportStaffButton)
const RegisterSupportStaffButtonsContainer = styled('div')(
  styles.registerSupportStaffButtonsContainer
)

type PropTypes = {
  fields: {
    supportStaffPageTitle: String,
    supportStaffPageSubTitle: String,
    supportStaffListStatusHeading: String,
    supportStaffListFirstName: String,
    supportStaffListLastName: String,
    supportStaffListAccountNumber: String,
    supportStaffRemoveModalTitle: String,
    supportStaffRemoveModalContent: String,
    supportStaffRemoveModalCancelButton: String,
    supportStaffRemoveModalDenyButton: String,
    supportStaffNoSupportStaffText: String,
    supportStaffRemoveSuccessModalContent: String,
    supportStaffRemoveSuccessModalConfirmButton: String,
  },
  originalFields: Object,
  actions: {
    retrieveAdviserSupportStaff: Function,
    removeAdviserSupportStaff: Function,
  },
  staffData: {
    totalRecords: Number,
    staffs: Array<Object>,
  },
  advisorDetails: Array<Object>,
  isFetching: Boolean,
  supportStaffBulkUploadAdviserCodes: Array<Object>,
}

export const SupportStafList = (props: PropTypes) => {
  const {
    fields,
    actions,
    staffData,
    advisorDetails,
    isFetching,
    supportStaffBulkUploadAdviserCodes,
    originalFields,
  } = props
  const [showModal, changeModalView] = useState(false)
  const [toBeRemovedItem, setToBeRemovedItem] = useState({})
  const [componentToRender, setComponentToRender] = useState('SupportStaffListSection')
  const {
    supportStaffPageTitle,
    supportStaffPageSubTitle,
    RegistrationButtonLabel,
    AddMultipleStaffButtonLabel,
    supportStaffListStatusHeading,
    supportStaffListFirstName,
    supportStaffListLastName,
    supportStaffListAccountNumber,
    supportStaffRemoveModalTitle,
    supportStaffRemoveModalContent,
    supportStaffRemoveModalCancelButton,
    supportStaffRemoveModalDenyButton,
    supportStaffNoSupportStaffText,
  } = fields

  const staffCount = get(staffData, 'totalRecords', 0)
  const staffList = get(staffData, 'staffs', [])

  const agencyCode = get(advisorDetails, '[0].agencyCode', '')
  const agencyBancsCustomerNo = get(advisorDetails, '[0].customerDetails.bancsCustomerNo', '')
  const isInSupportStaffBulkUploadGroup = supportStaffBulkUploadAdviserCodes?.find(
    bulkUploadUserInfo =>
      bulkUploadUserInfo.value === advisorDetails?.[0]?.customerDetails?.bancsCustomerNo ||
      bulkUploadUserInfo.value === advisorDetails?.[0]?.customerDetails?.customerNo
  )

  useEffect(() => {
    const { retrieveAdviserSupportStaff } = actions

    if (agencyCode) {
      retrieveAdviserSupportStaff({ agencyCode })
    }
  }, [])

  const removeStaff = useCallback(
    (item: Object): void => {
      changeModalView(true)
      setToBeRemovedItem(item)
    },
    [showModal, toBeRemovedItem, isFetching]
  )

  const handCloseModal = useCallback(() => {
    changeModalView(false)
    setToBeRemovedItem({})
  }, [showModal, toBeRemovedItem, isFetching])
  const handleDenyAccess = useCallback(() => {
    const { removeAdviserSupportStaff, retrieveAdviserSupportStaff } = actions
    const { bancsCustomerNo } = toBeRemovedItem

    const payload = { bancsCustomerNo, agencyCode, agencyBancsCustomerNo }
    removeAdviserSupportStaff(payload, error => {
      toast.dismiss(TOAST_ID_REMOVE_SUPPORT_STAFF)
      if (!error) {
        toast(
          TOAST_TITLE_REMOVE_SUPPORT_STAFF_SUCCESS.replace(
            '$0',
            `${toBeRemovedItem.firstName} ${toBeRemovedItem.surname}`
          ),
          {
            autoClose: 3000,
            toastId: TOAST_ID_REMOVE_SUPPORT_STAFF,
            type: toast.TYPE.SUCCESS,
          }
        )
        retrieveAdviserSupportStaff({ agencyCode })
        // GA tags splunk observability
        const event = createEvent({
          GA: {
            category: 'Support staff',
            action: 'AP - Delete support staff',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'AP - Delete support staff',
            },
          },
        })
        event.end()
      } else {
        toast(
          TOAST_TITLE_REMOVE_SUPPORT_STAFF_ERROR.replace(
            '$0',
            `${toBeRemovedItem.firstName} ${toBeRemovedItem.surname}`
          ),
          {
            autoClose: 3000,
            toastId: TOAST_ID_REMOVE_SUPPORT_STAFF,
            type: toast.TYPE.ERROR,
          }
        )
      }
    })
    changeModalView(false)
    setToBeRemovedItem({})
  }, [showModal, toBeRemovedItem])

  const renderListHeader = () => (
    <ListRow header>
      <ListItem>{supportStaffListStatusHeading}</ListItem>
      <ListItem>{supportStaffListFirstName}</ListItem>
      <ListItem>{supportStaffListLastName}</ListItem>
      <ListItem>{supportStaffListAccountNumber}</ListItem>
      <ListItem lastItem />
    </ListRow>
  )

  const renderListItems = () =>
    staffList.map((item, index) => {
      const { status, firstName, surname, customerNo } = item
      return (
        <ListRow colored={index % 2 !== 0} key={`${customerNo}${Math.random()}`}>
          <ListItem>
            <Status type="button">{STAFF_STATUS_BUCKET[status]}</Status>
          </ListItem>
          <ListItem>{firstName}</ListItem>
          <ListItem>{surname}</ListItem>
          <ListItem>{customerNo}</ListItem>
          <ListItem lastItem>
            <DeleteButton
              variant="danger"
              size="small"
              data-testid="remove"
              onClick={() => removeStaff(item)}
            >
              Delete
            </DeleteButton>
          </ListItem>
        </ListRow>
      )
    })

  const renderList = () => {
    if (isFetching) {
      return <Loader spinnerSize={60} borderSize={5} />
    }
    if (staffCount < 1) {
      return <Fragment>{supportStaffNoSupportStaffText}</Fragment>
    }
    return (
      <Fragment>
        {renderListHeader()}
        {renderListItems()}
      </Fragment>
    )
  }

  const renderRemoveUserModal = () => (
    <OffboardingModal
      isOpen={showModal}
      title={supportStaffRemoveModalTitle}
      onClose={handCloseModal}
      footer={
        <ModalFooter>
          <Button type="button" onClick={() => handleDenyAccess(toBeRemovedItem)}>
            {supportStaffRemoveModalDenyButton}
          </Button>
          <CancelButton type="button" variant="linkUnderlineOnHover" onClick={handCloseModal}>
            {supportStaffRemoveModalCancelButton}
          </CancelButton>
        </ModalFooter>
      }
    >
      <ModalBody>
        {supportStaffRemoveModalContent
          ? supportStaffRemoveModalContent.replace(
              '$0',
              `${toBeRemovedItem.firstName} ${toBeRemovedItem.surname}`
            )
          : null}
      </ModalBody>
    </OffboardingModal>
  )

  const renderMainComponent = (componentNameToRender: string) => {
    const componentMap = {
      SupportStaffRegisterForm: (
        <SupportStaffRegisterForm fields={fields} handleBack={setComponentToRender} />
      ),
      SupportStaffBulkUpload: (
        <SupportStaffBulkUpload
          fields={fields}
          originalFields={originalFields}
          handleBack={setComponentToRender}
        />
      ),
      SupportStaffListSection: (
        <SupportStaffListSection>
          <Title>{supportStaffPageTitle}</Title>
          <SubTitle>{supportStaffPageSubTitle}</SubTitle>
          <RegisterSupportStaffButtonsContainer>
            <RegisterSupportStaffButton
              type="button"
              variant="primary"
              onClick={() => {
                setComponentToRender('SupportStaffRegisterForm')
                // GA tags splunk observability
                const event = createEvent({
                  GA: {
                    category: 'Support staff',
                    action: 'AP - Add a support staff',
                  },
                  Splunk: {
                    attributes: {
                      'workflow.name': 'AP - Add a support staff',
                    },
                  },
                })
                event.end()
              }}
            >
              {RegistrationButtonLabel}
            </RegisterSupportStaffButton>
            {isInSupportStaffBulkUploadGroup && (
              <BulkUploadSupportStaffButton
                type="button"
                variant="secondaryWithTheme"
                onClick={() => {
                  setComponentToRender('SupportStaffBulkUpload')
                }}
              >
                {AddMultipleStaffButtonLabel}
              </BulkUploadSupportStaffButton>
            )}
          </RegisterSupportStaffButtonsContainer>
          <List>
            {renderList()}
            {renderRemoveUserModal()}
          </List>
        </SupportStaffListSection>
      ),
    }

    return componentMap[componentNameToRender]
  }

  return <Container>{renderMainComponent(componentToRender)}</Container>
}

export const mapStateToProps = ({
  advisor: {
    supportStaff: { isFetching, data },
    advisorDetails,
  },
  masterList: {
    data: { supportStaffBulkUploadAdviserCodes },
  },
}) => ({
  staffData: data,
  advisorDetails,
  isFetching,
  // these codes are open for beta testing group for support staff bulk upload feature
  supportStaffBulkUploadAdviserCodes,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportStafList)
