import { fieldsType } from '../../../../../../types/components/SupportStaffRegisterForm'
import {
  datePast,
  isNotFormattedDate,
  date,
  email,
  firstNameLastName,
  required,
  maxLength,
  mobilePhone,
  regexTest,
  phoneInputs,
  // @ts-expect-error file not in typescript
} from '../../../../../../utils/formUtils'
import { CountryCodeList } from '../../../../../../types/masterData'

export const FORM_ID = 'supportStaffBulkUploadForm'
export type SchemaNameType = keyof ReturnType<typeof schema>

const schema = (
  fields: fieldsType,
  countriesPhoneCode: CountryCodeList[]
): Record<
  string,
  { condition: unknown[] | string; onChangeCondition?: RegExp; errorMsg?: string }
> => ({
  firstName: {
    condition: [
      {
        condition: required,
        errorMsg: fields.NameRequiredMessage,
      },
      {
        condition: firstNameLastName,
        errorMsg: fields.NameInvalidMessage,
      },
      {
        condition: maxLength(50),
        errorMsg: fields.NameMaxLengthMessage,
      },
    ],
  },
  lastName: {
    condition: [
      {
        condition: required,
        errorMsg: fields.NameRequiredMessage,
      },
      {
        condition: firstNameLastName,
        errorMsg: fields.NameInvalidMessage,
      },
      {
        condition: maxLength(50),
        errorMsg: fields.NameMaxLengthMessage,
      },
    ],
  },
  dateOfBirth: {
    condition: [
      { condition: required, errorMsg: fields.supportStaffRegisterDateOfBirthRequiredError },
      {
        condition: isNotFormattedDate,
        errorMsg: fields.DOBInvalidFormatMessage,
      },
      { condition: date, errorMsg: fields.DOBInvalidFormatMessage },
      {
        condition: datePast,
        errorMsg: fields.supportStaffRegisterDateOfBirthFutureDateError,
      },
    ],
  },
  emailAddress: {
    condition: [
      {
        condition: required,
        errorMsg: fields.supportStaffRegisterEmailRequiredError,
      },
      {
        condition: email,
        errorMsg: fields.supportStaffRegisterEmailInvalidError,
      },
    ],
  },
  countryCode: {
    condition: [
      { condition: required, errorMsg: fields.CountryCodeRequiredMessage },
      {
        condition: (value: string) => !countriesPhoneCode.some(codeObj => value === codeObj.value),
        errorMsg: fields.CountryCodeInvalidFormatMessage,
      },
    ],
  },
  mobileNumber: {
    condition: [
      { condition: required, errorMsg: fields.supportStaffRegisterContactNumberRequiredError },
      {
        condition: (value: string) => !regexTest(mobilePhone, value),
        errorMsg: fields.ValidMobileNumberMessage,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields.supportStaffRegisterContactNumberError,
  },
})

export default schema
