// @flow
import {
  FUND_LOOKUP_INIT,
  FUND_LOOKUP_SUCCESS,
  FUND_LOOKUP_ERROR,
  FUND_LOOKUP_FAILURE,
  FUND_LOOKUP_RESET,
} from '../actions/types/abnLookup'

export const initialFieldState = {
  isLoading: false,
  isNoRecordsAvailable: false,
  error: false,
  response: '',
}

const fundsLookup = (
  state = initialFieldState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case FUND_LOOKUP_INIT: {
      return {
        ...state,
        isLoading: true,
        error: false,
        response: '',
        isNoRecordsAvailable: false,
      }
    }
    case FUND_LOOKUP_SUCCESS: {
      return {
        ...state,
        error: false,
        isLoading: false,
        response: action.payload.businessData.results,
        isNoRecordsAvailable: action.payload.businessData.results.length === 0,
      }
    }
    case FUND_LOOKUP_FAILURE:
    case FUND_LOOKUP_ERROR: {
      return {
        ...state,
        error: true,
        isLoading: false,
        response: '',
        isNoRecordsAvailable: false,
      }
    }
    case FUND_LOOKUP_RESET: {
      return { initialFieldState }
    }
    default: {
      return state
    }
  }
}

export default fundsLookup
