// @flow
import React from 'react'
// Organism
import AltsErrorModalOrgs from '../../../organisms/AltsErrorModal'

type AltsErrorModalProps = {
  fields: Object,
}

const AltsErrorModal = ({ fields }: AltsErrorModalProps) => <AltsErrorModalOrgs fields={fields} />

export default AltsErrorModal
