// @flow
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from '@mlcl-digital/mlcl-design/lib/base/Tabs'
import Tab from '@mlcl-digital/mlcl-design/lib/base/Tab'
import { Button, Heading, PageWrap } from '@mlcl-digital/mlcl-design'
import InlineMessage from '@mlcl-digital/mlcl-design/lib/base/InlineMessage'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { createEvent } from '../../../../utils/telemetry'

// utilities
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { getCollectionFrequency } from '../../../../utils/paymentUtils'
import { dollarAmountWithCommasAndTwoDecimal } from '../../../../utils/formUtils'
import {
  addOptimiserSuffixToBenefitName,
  getConnectedBenefits,
} from '../../../../utils/policyUtils'
import { policyInvalidMessage } from '../../../../utils/alterationRules'
import { getRejectCPISavings, getLinkedPolicyList } from '../../../../utils/alteration'
import history from '../../../../utils/browserHistory'

// actions
import { actionCreators } from '../../../../actions'

// components
import { CardContainer } from '../../../molecules/CardChildren'
import { AltsPolicyCard } from '../../../molecules/AltsPolicyCard'
import WithLoader from '../../../molecules/WithLoader'
import BackCTA from '../BackCTA'
import Modal from '../../../molecules/Modal'

// constants
import {
  POLICY_IDENTIFIER_POLICY_ID,
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  POLICY_STATE_INFORCE,
  INSIDE_SUPER,
} from '../../../../constants/policies'
import { BENEFIT_STATE_INFORCE } from '../../../../constants/benefit'
import { getProductIdConfig } from '../../../../constants/forms'

// selectors
import { getIsAdviserPortal, getMasterData } from '../../../../selectors/common.selectors'
import {
  getAlterationType,
  getAlterationTypeForAlterationsRoute,
  getEligiblePolicies,
  getIsAltsPolicySelected,
} from '../../../../selectors/alterations'

// styles
import styles from './altsQuote.styles'
import ScrollToTop from '../../../atoms/ScrollToTop'
import { DASHBOARD_ROUTE } from '../../../../constants/routes'
import CardSelectableWrapper from './components/CardSelectableWrapper/index'

const SectionHeading = styled(Heading)(styles.heading)
const Description = styled('p')(styles.description)
const TabsComponent = styled(Tabs)(styles.tabs)
const PageWrapper = styled(PageWrap)(styles.pageWrap)
const ButtonsWrapper = styled('div')(styles.buttonsWrapper)
const CardContainerComponent = styled(CardContainer)(styles.cardContainer)
const MCDDiscountInfoWrapper = styled('div')(styles.mcdDiscountInfo)
const ModalDescription = styled('p')(styles.modalDescription)
const SelectAllPoliciesBtn = styled(Button)(styles.selectAllPoliciesBtn)
const BackCTAButton = styled(BackCTA)(styles.backCTAButton)

type AltsQuotePropTypes = {
  fields: Object,
  params: Object,
  location: {
    hash: string,
  },
}
const redirectToDashboard = () => history.push(DASHBOARD_ROUTE)

export const AltsQuote = ({ fields, params, location: { hash } }: AltsQuotePropTypes) => {
  const dispatch = useDispatch()
  const timelineState = useSelector(state => state.timelineWithComponents)
  const alterationsState = useSelector(state => state.alterations)
  const masterData = useSelector(getMasterData)
  const isAdviserPortal = useSelector(getIsAdviserPortal)
  const { policies, rules, policySelectionMap } = alterationsState
  const activeTabValue = hash ? decodeURIComponent(hash.substring(1)) : 'newPremium'
  const [activeTab, setActiveTab] = useState(activeTabValue)
  const [isDiscountRemovedModal, setIsDiscountRemovedModal] = useState(false)
  const alterationType = useSelector(getAlterationType())
  const alterationTypeForAlterationsRoute = useSelector(getAlterationTypeForAlterationsRoute)
  const eligiblePolicies = useSelector(getEligiblePolicies())
  const isPolicySelected = useSelector(getIsAltsPolicySelected)
  const inforceIneligiblePolices = policies
    .filter(policyData => get(policyData, 'policy.status') === POLICY_STATE_INFORCE)
    .filter(policy => !eligiblePolicies.some(p => p.bancsPolicyNo === policy.bancsPolicyNo))
  const [policyShowBenefitDetails, setPolicyShowBenefitDetails] = useState(
    eligiblePolicies.reduce((acc, i) => ({ ...acc, [i.bancsPolicyNo]: false }), {})
  )

  const handleClick = () => {
    dispatch(actionCreators.timelineNextState())
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Continue through to application - quote page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - Continue through to application - quote page`,
        },
      },
    })
    tagEvent.end()
  }
  const inforceEligiblePolicies = eligiblePolicies.filter(
    policyData => get(policyData, 'policy.status') === POLICY_STATE_INFORCE
  )

  // Need to memoize only once, value will not change
  const inforceEligiblePolicyIds = useMemo(
    () => inforceEligiblePolicies.map(policy => policy.bancsPolicyNo),
    []
  )

  useEffect(() => {
    if (params.AlterationType !== alterationTypeForAlterationsRoute) {
      redirectToDashboard()
    }
  }, [alterationTypeForAlterationsRoute])

  useEffect(() => {
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Reject CPI - quote page',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - Reject CPI - quote page`,
        },
      },
    })
    tagEvent.end()
  }, [])

  // Show discount removed modal in case of negative savings
  useEffect(() => {
    const showDiscountRemovedModal = inforceEligiblePolicies.some(
      policy => getRejectCPISavings(policy) < 0
    )
    setIsDiscountRemovedModal(showDiscountRemovedModal)
  }, [])

  useEffect(
    () => () => {
      // Clear the flag on unmount to avoid accessing the link directly
      dispatch(actionCreators.setAlterationTypeForAlterationsRoute(''))
    },
    []
  )

  const tabs = [
    {
      id: 'newPremium',
      title: fields.NewQuotesHeader,
    },
    {
      id: 'existingPremium',
      title: fields.ExistingQuotesHeader,
    },
  ]

  const toggleShouldDisplay = key => {
    key &&
      setPolicyShowBenefitDetails({
        ...policyShowBenefitDetails,
        [key]: !policyShowBenefitDetails[key],
      })
  }

  const renderPolicyItem = (policy, isEligible = false) => {
    const {
      policy: {
        policyName,
        productId,
        identifiers,
        policyPremiumFrequency,
        paymentDetails = {},
        benefits,
      } = {},
      bancsPolicyNo,
    } = policy

    const policyId = identifiers
      ? identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID).value
      : bancsPolicyNo

    const { policyDetailsSubHeading, PremiumFrequency, PolicySavingsLabel } = fields

    const mainHeadingText = policyName || '-'
    const subHeadingText = `${get(policyDetailsSubHeading, 'value', '')}${policyId}`
    const collectionFrequency = getCollectionFrequency(
      get(paymentDetails, 'collectionFrequency', policyPremiumFrequency)
    )
    const policyData =
      get(policy, 'escalation.policyChangeDetails', []).find(
        item => item.changeType === (activeTab === 'newPremium' ? 'Re-Rate' : 'Escalation')
      ) || {}

    /**
     * Display policy premium savings for new premium screen
     * Savings = newNetPremium from Escalation object - newNetPremium from Re-Rate object in
     * escalations data
     */
    let policySavings = null
    if (activeTab === 'newPremium') {
      policySavings = dollarAmountWithCommasAndTwoDecimal(getRejectCPISavings(policy))
    }

    // filtering inforce benefits from escalation data and using
    // benefit name from policy benefits data and
    // creating policyBenefitAmounts to be sent to AltsPolicyCard component
    const policyBenefitAmounts = get(policyData, 'revisedBenefitDetails', []).reduce(
      (acc, item, i) => {
        const selectedBenefit = benefits.find(
          benefit =>
            benefit.type === item.benefitCode && benefit.benefitInstanceNo === item.benefitInstance
        )
        if (selectedBenefit && selectedBenefit.benefitStatus === BENEFIT_STATE_INFORCE) {
          return [
            ...acc,
            {
              benefitName: addOptimiserSuffixToBenefitName(
                {
                  ...item,
                  name: selectedBenefit.name,
                  optimiserParentBenefitReference: selectedBenefit.optimiserParentBenefitReference,
                },
                false
              ),
              benefitAmount: `${dollarAmountWithCommasAndTwoDecimal(item.newSumassured)} ${
                INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(item.benefitCode) ? 'p/m' : ''
              }`.trim(),
              index: i,
              benefitPremium: dollarAmountWithCommasAndTwoDecimal(item.newGrossBenefitPremium),
            },
          ]
        }
        return acc
      },
      []
    )

    const { policyType } = getProductIdConfig(productId)
    const props = {
      policyType,
      subHeadingText,
      mainHeadingText,
      policyUnavailableText: policyInvalidMessage(
        get(rules, 'businessData.policies', []),
        bancsPolicyNo,
        masterData.alterationsModalMessages.concat(masterData.rejectCPICardMessages),
        isAdviserPortal
      ),
      policyFee: dollarAmountWithCommasAndTwoDecimal(policyData.newPolicyFee),
      policyPremiumText: get(
        fields,
        activeTab === 'newPremium' ? 'PremiumSummary.value' : 'PremiumSummaryWithoutCPI.value',
        ''
      ).replace('##', collectionFrequency.toLowerCase()),
      collectionFrequency: get(PremiumFrequency, 'value', '').replace('##', collectionFrequency),
      policyPremiumValue: dollarAmountWithCommasAndTwoDecimal(policyData.newNetPremium),
      policyBenefitAmounts,
      stampDuty: dollarAmountWithCommasAndTwoDecimal(policyData.newStampDuty),
      taxRebate: `- ${dollarAmountWithCommasAndTwoDecimal(policyData.newTaxRebate)}`,
      policySavings,
      showTaxRebate: policyType === INSIDE_SUPER,
      policySavingsLabel: get(PolicySavingsLabel, 'value', '').replace('##', collectionFrequency),
      isHighlightTotalPremium: activeTab === 'newPremium',
      linkedBenefits: [
        ...get(getConnectedBenefits(policies, policy), 'connectedBenefitsInfo', []),
      ].filter(
        i =>
          // to exlude the one of the same policy RET-18864
          !i.includes(policyId)
      ),
    }

    return (
      <CardSelectableWrapper
        key={policy.bancsPolicyNo}
        shouldDisplayWrapper={isEligible}
        isSelected={policySelectionMap && !!policySelectionMap[policy.bancsPolicyNo]}
        onClick={() => {
          dispatch(
            actionCreators.setOrTogglePolicySelectionKeys(getLinkedPolicyList(policies, policy))
          )
        }}
      >
        <AltsPolicyCard
          {...props}
          cardKeyPolicyNumber={policy.bancsPolicyNo}
          fields={fields}
          shouldDisplay={policyShowBenefitDetails[policy.bancsPolicyNo]}
          toggleShouldDisplay={toggleShouldDisplay}
          flexGrow
        />
      </CardSelectableWrapper>
    )
  }

  const handleModalClose = () => {
    setIsDiscountRemovedModal(false)
  }
  // inforceEligiblePolicies data will remain same. Hence it is okay to not add in dependency array.
  // Moreover inforceEligiblePolicies will be recalculated in each rerender,
  // so memoization with not work with adding that.
  const isAllPoliciesSelected = useMemo(
    () =>
      inforceEligiblePolicies.every(inforcePolicy =>
        policySelectionMap ? policySelectionMap[inforcePolicy.bancsPolicyNo] : false
      ),
    [policySelectionMap]
  )
  const handleMultiPolicySelection = useCallback(() => {
    if (isAllPoliciesSelected) {
      dispatch(actionCreators.deselectAllPolicies())
    } else {
      dispatch(actionCreators.selectListOfPolicies(inforceEligiblePolicyIds))
    }
  }, [isAllPoliciesSelected])

  return (
    timelineState.activeComponent === 'AltsQuote' && (
      <WithLoader isLoading={!!alterationsState.escalationAPICount} loaderProps={{ type: 'tab' }}>
        <PageWrapper>
          <ScrollToTop>
            <ButtonsWrapper>
              <BackCTAButton
                fields={fields}
                alterationType={alterationType}
                onClick={() => {
                  // TODO:: BackCTA needs to support onClick event
                  dispatch(actionCreators.setAlterationTypeSelectedByUser(params.AlterationType))
                }}
              />
              {activeTab === 'newPremium' && (
                <Button onClick={handleClick} disabled={!isPolicySelected}>
                  {renderTextField(fields.ContinueCTA)}
                </Button>
              )}
            </ButtonsWrapper>
            <SectionHeading size="large" variant="h2">
              {renderTextField(fields.policySectionHeaderText)}
            </SectionHeading>
            <Description>{renderTextField(fields.policySectionSubHeaderText)}</Description>
            <SectionHeading mb="5" size="medium" variant="h3">
              {renderTextField(fields.policySectionDescriptionText)}
              <SelectAllPoliciesBtn variant="link" onClick={handleMultiPolicySelection}>
                {renderTextField(
                  isAllPoliciesSelected ? fields.DeSelectAllCTA : fields.SelectAllCTA
                )}
              </SelectAllPoliciesBtn>
            </SectionHeading>
            <TabsComponent variant="center-with-background">
              {tabs.map((tab, index) => (
                <li key={`tab-${tab.id}`}>
                  <Tab
                    data-testid={`application-listing-tabs-${tab.id}`}
                    id={tab.id}
                    hash={tab.id}
                    dataLocator={tab.id}
                    key={tab.id}
                    index={index}
                    selected={activeTab === tab.id}
                    clickHandler={() => {
                      setActiveTab(tab.id)
                      const tagEvent = createEvent({
                        GA: {
                          category: alterationType,
                          action:
                            tab.id === 'newPremium'
                              ? 'New premium price and benefits'
                              : 'Existing premium price and benefits',
                        },
                        Splunk: {
                          attributes: {
                            'workflow.name': `${alterationType} - ${
                              tab.id === 'newPremium'
                                ? 'New premium price and benefits'
                                : 'Existing premium price and benefits'
                            }`,
                          },
                        },
                      })
                      tagEvent.end()
                    }}
                    variant="center-with-background"
                  >
                    {renderTextField(tab.title)}
                  </Tab>
                </li>
              ))}
            </TabsComponent>
            <CardContainerComponent data-testid="eligiblePoliciesSection">
              {inforceEligiblePolicies.map(dataItem => renderPolicyItem(dataItem, true))}
            </CardContainerComponent>
            <MCDDiscountInfoWrapper>
              <InlineMessage
                iconName={['far', 'fa-circle-info']}
                size="md"
                message={renderTextField(fields.MCDInfo)}
              />
            </MCDDiscountInfoWrapper>
            <ButtonsWrapper isBottomWrapper>
              <BackCTAButton
                fields={fields}
                alterationType={alterationType}
                onClick={() => {
                  // TODO:: BackCTA needs to support onClick event
                  dispatch(actionCreators.setAlterationTypeSelectedByUser(params.AlterationType))
                }}
              />
              {activeTab === 'newPremium' && (
                <Button onClick={handleClick} disabled={!isPolicySelected}>
                  {renderTextField(fields.ContinueCTA)}
                </Button>
              )}
            </ButtonsWrapper>
            {inforceIneligiblePolices.length > 0 && (
              <>
                <SectionHeading mb="5" size="medium" variant="h3">
                  {renderTextField(fields.policySectionIneligibleHeaderText)}
                </SectionHeading>
                <CardContainerComponent>
                  {inforceIneligiblePolices.map(dataItem => renderPolicyItem(dataItem))}
                </CardContainerComponent>
              </>
            )}
          </ScrollToTop>
        </PageWrapper>
        <Modal
          isOpen={isDiscountRemovedModal}
          title={get(fields, 'DiscountRemovedModalHeading.value')}
          onClose={handleModalClose}
        >
          <ModalDescription>
            {renderTextField(fields.DiscountRemovedModalDescription)}
          </ModalDescription>
          <Button type="secondary" onClick={handleModalClose}>
            {renderTextField(fields.DiscountRemovedModalButton)}
          </Button>
        </Modal>
      </WithLoader>
    )
  )
}

export default withRouter(AltsQuote)
