import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { space, colours, typography } = Variables as VariablesType
export default {
  container: {
    ' ul': {
      marginLeft: '20px',
    },
    marginBottom: space(5),
  },
  divider: {
    background: colours.tertiaryThree,
    marginBottom: space(5),
    marginTop: space(5),
    height: '1px',
  },
  checkbox: {
    '& label:before': {
      top: '13px' as const,
    },
    '& label>svg': {
      top: '13px' as const,
    },
    '& label': {
      ...typography.paragraphs.XSMSemiBold,
    },
  },
}
