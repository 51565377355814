export const MAIN = {
  name: 'jss-main',
  placeholder: 'jss-name',
  prop: 'mainPlaceholder',
}

export const PAGE_CONTENT = {
  name: 'page-content',
  placeholder: 'page-content',
  prop: 'pageContentPlaceholder',
}

export const SIDEBAR = {
  name: 'jss-sidebar',
  placeholder: 'jss-sidebar',
  prop: 'sidebarPlaceholder',
}

export const NAVIGATION = {
  name: 'jss-navigation',
  placeholder: 'jss-navigation',
  prop: 'navigationPlaceholder',
}

export const FOOTER = {
  name: 'jss-footer',
  placeholder: 'jss-footer',
  prop: 'footerPlaceholder',
}

export const CONCIERGE_FOOTER = {
  name: 'jss-footer-concierge',
  placeholder: 'jss-footer-concierge',
  prop: 'conciergeFooterPlaceholder',
}

export const PAGE_CONTENT_HEADER = {
  name: 'page-content-header',
  placeholder: 'page-content-header',
  prop: 'pageContentPlaceholder',
}
