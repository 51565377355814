// @flow
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../utils/telemetry'

// actions
import { actionCreators } from '../../../actions'

// utils
import { getCPINotEligibleDesc, getAltsNotEligibleGenericMsg } from '../../../utils/alterationRules'
import { renderTextField } from '../../../utils/sitecoreUtils'

// components
import Modal from '../../molecules/Modal'

// selectors
import { getIsAdviserPortal, getMasterData } from '../../../selectors/common.selectors'
import { getAlterationType } from '../../../selectors/alterations'

// styles
import styles from './altsErrorModal.styles'
import history from '../../../utils/browserHistory'

const ModalDescription = styled('p')(styles.modalDescription)

type AltsErrorModalPropTypes = {
  fields: Object,
}
const AltsErrorModal = ({ fields }: AltsErrorModalPropTypes) => {
  const [message, setMessage] = useState('')
  const altsErrorModalState = useSelector(state => get(state, 'modal.altsErrorModal', {}))
  const alterationsState = useSelector(state => state.alterations)
  const isAdviserPortal = useSelector(getIsAdviserPortal)
  const masterData = useSelector(getMasterData)
  const dispatch = useDispatch()
  const portfolioRules = get(alterationsState, 'rules.businessData.assesment', {})
  const policiesInRules = get(alterationsState, 'rules.businessData.policies', [])
  const { description } = altsErrorModalState
  const alterationType = useSelector(
    getAlterationType(description === 'rejectCPI' ? 'Reject CPI' : 'Decrease cover')
  )
  useEffect(() => {
    switch (description) {
      case 'rejectCPI': {
        setMessage(
          getCPINotEligibleDesc({
            alterationsModalMessages: masterData.alterationsModalMessages,
            portfolioRules,
            isAdviserPortal,
            policiesInRules,
          })
        )
        break
      }
      case 'decreaseCover': {
        setMessage(
          getAltsNotEligibleGenericMsg({
            alterationsModalMessages: masterData.alterationsModalMessages,
            portfolioRules,
            isAdviserPortal,
            eligibilityFlagAdviser: 'decrease_EligibleForAdviser',
            policiesInRules,
          })
        )
        break
      }
      case 'decreaseCoverReEntry': {
        setMessage(
          getAltsNotEligibleGenericMsg({
            alterationsModalMessages: masterData.invalidAltsQuoteModalMessages,
            portfolioRules,
            isAdviserPortal,
            eligibilityFlagAdviser: 'decrease_EligibleForAdviser',
          })
        )
        break
      }
      case 'altsParentSumInsuredCantBeLessThanChildren': {
        const messageData = masterData.alterationsModalMessages.find(
          item => item.code === description
        )
        setMessage({
          heading: messageData.title,
          description: messageData.message,
        })
        break
      }
      case 'altsCantCancelParentBenefitBeforeChild': {
        const messageData = masterData.alterationsModalMessages.find(
          item => item.code === description
        )
        setMessage({
          heading: messageData.title,
          description: messageData.message,
        })
        break
      }
      default:
    }
  }, [description])
  const handleClose = (closeButton: boolean = false) => {
    const tagEvent = createEvent({
      GA: {
        category: `${alterationType} option - issue modal`,
        action: closeButton ? 'Close Button' : 'Close X',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - ${closeButton ? 'Close Button' : 'Close X'}`,
        },
      },
    })
    tagEvent.end()
    dispatch(actionCreators.hideModal('altsErrorModal'))
    if (description === 'decreaseCoverReEntry') {
      history.goBack()
    }
  }
  return (
    <Modal
      isOpen={altsErrorModalState.isModalVisible}
      title={message.heading}
      onClose={handleClose}
      showCloseButton={description !== 'decreaseCoverReEntry'}
    >
      <ModalDescription>{renderTextField(message.description)}</ModalDescription>
      <Button
        type="secondary"
        onClick={() => {
          handleClose(true)
        }}
      >
        {renderTextField(fields.ModalCTA)}
      </Button>
    </Modal>
  )
}

export default AltsErrorModal
