// @flow
import get from 'lodash/get'
// types.
import { toast } from 'react-toastify'
import { EXPERIENCE_API } from '../types/experienceApi'
import { QUOTE_LISTING_FETCH, QUOTE_DELETION, INIT_EMPTY_ROWS } from '../types/quotes'

// utils.
import { getBancsCustomerNumber, getAdviserNo } from '../../utils/cookieUtils'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'
import { TOAST_ID_SEARCH_QUOTE } from '../../constants/toast'
import {
  DIGITAL_QUOTE,
  DIGITAL_ALTERATION,
  PENDING_APPLICATION,
  SUBMITTED_APPLICATION,
  PENDING_APPLICATION_ALTS,
  SUBMITTED_APPLICATION_ALTS,
} from '../../constants/application'

/**
 * Helper function for quote and app listing screens to build the search type based
 * on the App/Quote list screen and whether its NewBusiness/ExistingBusiness
 * @param {*} isNewBusiness : true if New Business tab is clicked, else false
 * @param {*} isApplicationOrQuote : true if called from Application screen, else false
 * @returns the search type for quote Search API
 */
const getSearchType = (isNewBusiness: boolean, isApplicationOrQuote: boolean) => {
  let searchTypeForQuote = [PENDING_APPLICATION, SUBMITTED_APPLICATION]

  if (isApplicationOrQuote) {
    searchTypeForQuote = isNewBusiness
      ? [PENDING_APPLICATION, SUBMITTED_APPLICATION]
      : [PENDING_APPLICATION_ALTS, SUBMITTED_APPLICATION_ALTS]
  } else {
    searchTypeForQuote = isNewBusiness ? [DIGITAL_QUOTE] : [DIGITAL_ALTERATION]
  }
  return searchTypeForQuote
}

const getQuoteSearch =
  (data?: Object, callback?: Function = () => {}) =>
  dispatch => {
    const {
      sortBy,
      orderBy,
      offset,
      limit,
      quoteCollectionName,
      filterParam,
      isApplication,
      isNewBusiness,
    } = data

    dispatch({
      type: EXPERIENCE_API,
      name: QUOTE_LISTING_FETCH,
      verb: 'POST',
      data: {
        bancsAdviserCustomerNo: getAdviserNo() || getBancsCustomerNumber(),
        quoteCollectionName,
        searchType: filterParam || getSearchType(isNewBusiness, isApplication),
      },
      callback: (err, dataReceived) => {
        if (err) {
          toast('Error in Data Fetching', {
            toastId: TOAST_ID_SEARCH_QUOTE,
            type: toast.TYPE.ERROR,
          })
        }
        if (callback) {
          const quotes = get(dataReceived, 'businessData', {})
          callback(err, quotes)
        }

        return {
          ...dataReceived,
        }
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/search?sortBy=${sortBy}&sort=${orderBy}&offset=${offset}&limit=${limit}`,
    })
  }

const getAllQuoteRecords = (data?: Object, callback?: Function) => (dispatch: Function) => {
  const {
    sortBy,
    orderBy,
    offset,
    limit,
    quoteCollectionName,
    filterParam,
    isApplication,
    isNewBusiness,
  } = data
  const memberSearch = {
    type: EXPERIENCE_API,
    name: 'QUOTE_LISTING_DOWNLOAD_FETCH',
    verb: 'POST',
    data: {
      bancsAdviserCustomerNo: getAdviserNo() || getBancsCustomerNumber(),
      quoteCollectionName,
      searchType: filterParam || getSearchType(isNewBusiness, isApplication),
    },
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/search?sortBy=${sortBy}&sort=${orderBy}&offset=${offset}&limit=${limit}`,
    callback: (err, dataReceived) => {
      if (callback) {
        const quotes = get(dataReceived, 'businessData', {})
        callback(err, quotes)
      }
      return dataReceived
    },
  }

  return dispatch(memberSearch)
}

const removeQuote = (data?: Object, callback?: Function = () => {}) => {
  const { quoteId } = data
  return {
    type: EXPERIENCE_API,
    name: QUOTE_DELETION,
    verb: 'DELETE',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/${quoteId}`,
    callback: (err, dataReceived) => {
      if (callback) {
        callback(err, dataReceived)
      }
      return dataReceived
    },
  }
}

const initEmptyRows = () => dispatch => {
  dispatch({
    type: INIT_EMPTY_ROWS,
  })
}

export { getQuoteSearch, removeQuote, initEmptyRows, getAllQuoteRecords }
