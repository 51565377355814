// @flow
import React from 'react'
import styled from '@emotion/styled'

// atoms
import ButtonComponent from '../../../../atoms/Button'

// styles.
import styles from './TaskCard.styles'

type TaskCardProps = {
  // sitecore Fields
  title: String,
  buttonlabel: String,
  handleTaskActionButton: Function,
}

const FullWidth = styled('div')(styles.container)
const Title = styled('p')(styles.textBox)
const Button = styled(ButtonComponent)(styles.button)
const CtaButton = styled(Button)(styles.ctaButton)

const TaskCard = (props: TaskCardProps) => {
  const { title, buttonlabel, handleTaskActionButton } = props

  return (
    <FullWidth>
      <Title type="primary">{title}</Title>
      <CtaButton type="primary" onClick={handleTaskActionButton}>
        {buttonlabel}
      </CtaButton>
    </FullWidth>
  )
}

export default TaskCard
