/* eslint-disable no-param-reassign */
import React from 'react'
import get from 'lodash/get'
import { IconPhone32, IconEmail32 } from '../../../atoms/Icons'
import { required } from '../../../../utils/formUtils'

import { BIG_T_BOOK, BIG_T_INTERVIEW } from '../../../../constants/workItems'
import ToogleLabel from '../../../organisms/CompletionMethod/components/ToggleLabel'

export const FORM_ID = 'completionMethod'
export const FORM_FIELD_COMPLETION_TYPE = 'type'
export const FORM_FIELD_INITIAL_CONTACT = 'initialContact'

export const completionMethodOptions = fields => [
  {
    label: (
      <ToogleLabel
        title={get(fields, 'onlineMethodDisplayText.value')}
        icon={IconEmail32}
        subTitle={get(fields, 'onlineMethodDescription.value')}
      />
    ),
    value: BIG_T_INTERVIEW,
  },
  {
    label: (
      <ToogleLabel
        title={get(fields, 'telMethodDisplayText.value')}
        icon={IconPhone32}
        subTitle={get(fields, 'teleMethodDescription.value')}
      />
    ),
    value: BIG_T_BOOK,
  },
]

export const initialContactOptions = [
  {
    label: 'Monday',
    name: 'monday',
    variant: 'box',
    checked: false,
  },
  {
    label: 'Tuesday',
    name: 'tuesday',
    variant: 'box',
    checked: false,
  },
  {
    label: 'Wednesday',
    name: 'wednesday',
    variant: 'box',
    checked: false,
  },
  {
    label: 'Thursday',
    name: 'thursday',
    variant: 'box',
    checked: false,
  },
  {
    label: 'Friday',
    name: 'friday',
    variant: 'box',
    checked: false,
  },
]

export const selectTimeOptions = [
  {
    label: 'Between 8:30am and 5:30pm',
    value: 'Between 8:30am and 5:30pm',
    callAfterTime: '08:30',
    callBeforeTime: '17:30',
  },
  {
    label: 'Between 8:30am and 12 noon',
    value: 'Between 8:30am and 12 noon',
    callAfterTime: '08:30',
    callBeforeTime: '12:00',
  },
  {
    label: 'Between 12 noon and 2pm',
    value: 'Between 12 noon and 2pm',
    callAfterTime: '12:00',
    callBeforeTime: '14:00',
  },
  {
    label: 'Between 2pm and 5:30pm',
    value: 'Between 2pm and 5:30pm',
    callAfterTime: '14:00',
    callBeforeTime: '17:30',
  },
]

const SCHEMA = fields => ({
  [FORM_FIELD_COMPLETION_TYPE]: {
    condition: required,
    errorMsg: 'Please select method',
    options: completionMethodOptions(fields),
  },
  [FORM_FIELD_INITIAL_CONTACT]: {
    condition: required,
    errorMsg: 'Please select date & time',
    options: initialContactOptions,
  },
})

export default SCHEMA
