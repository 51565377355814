export const trackPageView = (page, metadata) => {
  if (window.inMoment && window.inMoment.trackPageView) {
    window.inMoment.trackPageView({
      page,
      // Additional metadata if needed
      // customerID: 'customer-id', // Replace with actual customer ID
      metadata,
    })
  }

  if (window.IMI && typeof window.IMI.customEvent === 'function') {
    window.IMI.customEvent('pageView', {
      page,
      timestamp: new Date().toISOString(),
    })
  }
}
