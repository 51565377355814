// @flow
import { SHOW_ENQUIRY_ID_EXPIRED_MODAL } from '../actions/types/enquiryIdExpiredModal'

const initialState = {
  showModal: false,
}

const enquireIdExpiredModal = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case SHOW_ENQUIRY_ID_EXPIRED_MODAL: {
      const { showModal } = action.payload
      return {
        ...state,
        showModal,
      }
    }
    default: {
      return state
    }
  }
}

export default enquireIdExpiredModal
