// @flow
import React from 'react'
import FullUREResultsComponent from '../../../organisms/FullUREResults'

type ApplicationProps = {
  fields: Object<Object>,
}

const Application = ({ fields }: ApplicationProps) => <FullUREResultsComponent fields={fields} />

export default Application
