import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontSize } = Variables

const styles = {
  container: {
    minHeight: space(54, true),
    margin: `0 0 ${space(24, true)} 0`,
  },
  section: {
    marginTop: space(6),
  },
  sectionHeader: {
    height: space(10.75),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: space(2),
  },
  sectionDescription: {
    fontSize: fontSize.m, // FIXME: Need paragraph atom..
  },
  viewAllButton: ({ isActive }) => ({
    cursor: isActive ? 'pointer' : 'not-allowed',
  }),
  accordionWrapper: {},
  card: {
    color: colours.functionalDanger,
    padding: `0 ${space(4)}`,
    display: 'flex',
    flexDirection: 'row',
  },
  iconExclaimation: {
    color: colours.functionalDanger,
    marginTop: space(0.5),
    marginRight: space(1),
  },
  accordionHeader: {
    borderBottom: `solid 1px ${colours.tertiaryThree}`,
    paddingLeft: '77px',
    background: colours.tertiarySix,
    paddingTop: space(2.75),
    paddingBottom: space(2),
    color: colours.tertiaryOne,
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}

export default styles
