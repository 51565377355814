// @flow
// helpers.
import { required, phone, email, isEqualAsPrevious, phoneInputs } from '../../../utils/formUtils'

export const FORM_ID = 'customerCompletes'
const schema = ({ fields, advisorEmailAddress, advisorPhoneNumber }: Object) => ({
  emailAddress: {
    condition: [
      { condition: required, errorMsg: fields.customerCompletesPageEmailErrorMessage },
      { condition: email, errorMsg: fields.customerCompletesPageEmailErrorMessage },
      {
        condition: isEqualAsPrevious(advisorEmailAddress),
        errorMsg: fields.customerCompletesEmailSameAsAdvisorError,
      },
    ],
  },
  contactNumber: {
    condition: [
      { condition: required, errorMsg: fields.customerCompletesPagePhoneErrorMessage },
      { condition: phone, errorMsg: fields.customerCompletesPagePhoneErrorMessage },
      {
        condition: isEqualAsPrevious(advisorPhoneNumber),
        errorMsg: fields.customerCompletesPhoneSameAsAdvisorError,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields.customerCompletesPagePhoneErrorMessage,
  },
  contactNumberPhoneCode: {
    condition: required,
  },
})
export default schema
