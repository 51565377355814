// @flow
import React from 'react'
// Organism
import AltsQuoteOrganism from '../../../organisms/Alteration/AltsQuote'

type AltsQuoteProps = {
  fields: Object,
  // Params
  params: Object,
}

const AltsQuote = ({ fields, params }: AltsQuoteProps) => (
  <AltsQuoteOrganism fields={fields} params={params} />
)

export default AltsQuote
