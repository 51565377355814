// @flow
import {
  ADVISER_REGISTRATION_INIT,
  ADVISER_REGISTRATION_SUCCESS,
  ADVISER_REGISTRATION_ERROR,
  ADVISER_REGISTRATION_FAILURE,
  ADVISER_REGISTRATION_RECAPTCHA_TOKEN,
} from '../actions/types/adviserRegistration'

export const initialState = {
  isLoading: false,
  hasFetchError: false,
  isAdviserRegistrationSuccessful: false,
}

const adviserRegistration = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case ADVISER_REGISTRATION_INIT: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case ADVISER_REGISTRATION_SUCCESS: {
      const {
        payload: { businessData, status },
      } = action
      return {
        ...state,
        isLoading: false,
        hasFetchError: false,
        isAdviserRegistrationSuccessful: true,
        workItemReferenceNo: status?.type === 'success' ? businessData?.workItemReferenceNo : '',
      }
    }

    case ADVISER_REGISTRATION_ERROR:
    case ADVISER_REGISTRATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasFetchError: true,
        isAdviserRegistrationSuccessful: false,
      }
    }

    case ADVISER_REGISTRATION_RECAPTCHA_TOKEN: {
      const { recaptchaToken } = action.payload
      return {
        ...state,
        recaptchaToken,
      }
    }

    default: {
      return state
    }
  }
}

export default adviserRegistration
