// @flow
// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import { POLICIES_FETCH, POLICIES_FOR_LA_FETCH, POLICIES_RESET } from '../types/policies'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const getPolicies =
  (bancsCustomerNumber: string, isSMSF?: boolean = false, callback: Function = () => {}) =>
  (dispatch: Function): Object => {
    // dispatch the action to call the api.
    dispatch({
      type: EXPERIENCE_API,
      name: POLICIES_FETCH,
      verb: 'GET',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/policies/${bancsCustomerNumber}?isSMSF=${isSMSF}&returnBalance=Y`,
      callback: (err, dataReceived) => {
        callback(dataReceived)
        const { policies, bancsCustomerNo } = dataReceived.businessData

        // pass subset of response, or during error pass whole payload down.
        return {
          bancsCustomerNo,
          policies: policies || dataReceived,
          clientId: bancsCustomerNumber,
        }
      },
    })
  }

export const getPoliciesForLifeAssured =
  (bancsCustomerNumber: string, callback: Function = () => {}) =>
  (dispatch: Function): Object => {
    // dispatch the action to call the api.
    dispatch({
      type: EXPERIENCE_API,
      name: POLICIES_FOR_LA_FETCH,
      verb: 'GET',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/policies/${bancsCustomerNumber}?removeNuLISCheck=true`,
      callback: (err, dataReceived) => {
        callback(err, dataReceived)
        return dataReceived
      },
    })
  }

export const setPoliciesReset =
  () =>
  (dispatch: Function): Object => {
    // dispatch the action to call the api.
    dispatch({
      type: POLICIES_RESET,
    })
  }
