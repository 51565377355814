import { createSelector } from 'reselect'
import { Store } from '../types/store'

// Root selectors
const getCreateQuote = (state: Store) => state.createQuote

export const checkIfApplicationPageShowLoader = createSelector(
  [getCreateQuote],
  quoteCollection => quoteCollection.isFetchingData
)
