import get from 'lodash/get'
// helpers.
import { required, numeric, firstNameLastName } from '../../../utils/formUtils'
import { TITLES } from '../../../constants/forms'
import { POLICY_PARTY_TYPE_BUSINESS } from '../../../constants/policies'

export const FORM_ID = 'adviserRegistration'
export const RESIDENTIAL_ADDRESS_FIELD = 'residentialAddress'
export const RESIDENTIAL_STATE = 'residentialState'
export const EARNINGS = 'earningsPA'
export const PRIMARY_OCCUPATION = 'primaryOccupation'
export const DATE_OF_BIRTH = 'dateOfBirth'
export const CONFIRM_MODAL = 'confirmModal'

const schema = messagefields => ({
  representativeTitle: {
    condition: [
      {
        condition: POLICY_PARTY_TYPE_BUSINESS && required,
        errorMsg: get(messagefields, 'adviserRegistrationTitleError.value'),
      },
    ],
    options: TITLES,
  },
  representativeFirstName: {
    condition: [
      {
        condition: POLICY_PARTY_TYPE_BUSINESS && required,
        errorMsg: get(messagefields, 'adviserRegistrationFirstNameError.value'),
      },
      {
        condition: firstNameLastName,
        errorMsg: get(messagefields, 'adviserRegistrationFirstNameError.value'),
      },
    ],
  },
  representativeLastName: {
    condition: [
      {
        condition: POLICY_PARTY_TYPE_BUSINESS && required,
        errorMsg: get(messagefields, 'adviserRegistrationLastNameError.value'),
      },
      {
        condition: firstNameLastName,
        errorMsg: get(messagefields, 'adviserRegistrationLastNameError.value'),
      },
    ],
  },
  representativeAsicRepNo: {
    condition: [
      {
        condition: POLICY_PARTY_TYPE_BUSINESS && required,
        errorMsg: get(messagefields, 'adviserRegistrationAsicRepNoError.value'),
      },
      {
        condition: numeric,
        errorMsg: get(messagefields, 'adviserRegistrationAsicRepNoError.value'),
      },
    ],
  },
})

export default schema
