const useMultiRefs = <T>(initialValue?: T) => {
  const refs = new Set<T>([])

  const getRefs = () => {
    if (initialValue && refs.size === 0) {
      return initialValue
    }
    // @ts-expect-error suppress error
    return Array.from(refs).filter(ref => document.contains(ref))
  }

  const addRef = (ref: T) => {
    if (ref !== null) {
      refs.add(ref)
    }
  }

  return [getRefs, addRef]
}

export default useMultiRefs
