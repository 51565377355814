import { toast } from 'react-toastify'
import {
  CONTACT_MLC_SEND_EMAIL_SUCCESS,
  CONTACT_MLC_SEND_EMAIL_ERROR,
  CONTACT_MLC_SEND_EMAIL_FAILURE,
  CONTACT_MLC_SEND_EMAIL_INIT,
} from '../actions/types/contactMLC'

import { TOAST_TITLE_CONTACT_MLC_ERROR, TOAST_ID_CONTACT_MLC } from '../constants/toast'

export const initialFieldState = {
  isSending: false,
  isSendingSuccess: false,
  isError: false,
}

const contactMLC = (state = initialFieldState, action) => {
  switch (action.type) {
    case CONTACT_MLC_SEND_EMAIL_SUCCESS: {
      return {
        ...state,
        isSending: false,
        isSendingSuccess: true,
        isError: false,
      }
    }
    case CONTACT_MLC_SEND_EMAIL_INIT:
      return {
        ...state,
        isSending: false,
        isSendingSuccess: false,
        isError: false,
      }
    case CONTACT_MLC_SEND_EMAIL_ERROR:
    case CONTACT_MLC_SEND_EMAIL_FAILURE: {
      toast(TOAST_TITLE_CONTACT_MLC_ERROR, {
        toastId: TOAST_ID_CONTACT_MLC,
        type: toast.TYPE.ERROR,
      })
      return {
        ...state,
        isSending: false,
        isSendingSuccess: false,
        isError: true,
      }
    }
    default: {
      return state
    }
  }
}

export default contactMLC
