// @flow
import React, { Fragment } from 'react'
import IdleTimer from 'react-idle-timer'
import styled from '@emotion/styled'

// atoms
import Button from '../../atoms/Button'

// molecules
import Modal from '../Modal'

// utils
import { isSessionTimeoutDisabled } from '../../../utils/commonUtils'
import { isBrowser, isLocalStorage } from '../../../utils/browserUtils'
import { validateTokenExpiry } from '../../../utils/oktaUtils'

// styles.
import styles from './sessionTimeout.styles'
import {
  CUSTOMER_PERSONAL_STATEMENT,
  CUSTOMER_TELE_UNDERWRITER_HOME_PAGE,
} from '../../../constants/personalStatement'

type SessionTimeoutProps = {
  // An object containing action creator functions.
  actions: Object,
  // Redux authentication
  authentication: Object<Object>,
  // History object
  history: Object<Object>,
  // Env constans
  config: Object<Object>,
  // Message to display on modal
  message: String,
  // Confirm button modal label
  confirmBtnLabel: String,
  okta: Object,
  myLink: Object,
  // to hide confirm button
  hideCTAButton: Boolean,
}

const Message = styled('p')(styles.message)
const ConfirmButton = styled(Button)(styles.confirmButton)

let idleTimer = null
const isMountedOnClient = isBrowser()

class SessionTimeout extends React.PureComponent<SessionTimeoutProps> {
  state = {
    isTimedOut: false,
    showModal: false,
    modalShowedAlready: false,
  }

  onIdle = () => {
    const { authentication, history } = this.props
    const { isTimedOut } = this.state
    const isWhiteListedRoute = isSessionTimeoutDisabled(history.location.pathname)
    if (!isWhiteListedRoute && !isTimedOut && authentication.authenticated) {
      this.setState({ showModal: true })
      idleTimer.reset()
      this.setState({ isTimedOut: true })
    }
  }

  onAction = () => {
    const { authentication, okta, actions, config, myLink, history } = this.props
    const { tokenExpiresIn, token, isLoading } = okta
    const { isTimedOut, modalShowedAlready } = this.state
    const { reAuthorizeUser } = actions
    if (
      !isTimedOut &&
      (authentication.authenticated || myLink.hasAuthorised) &&
      !isLoading &&
      !authentication.isLoading
    ) {
      // Check if token is will expire within the next x configured mins
      const isTokenExpired = validateTokenExpiry(
        tokenExpiresIn,
        config.MLCL_OKTA_ACCESS_TOKEN_UPDATE_TIME
      )
      // reauthorize user
      if (token && isTokenExpired) {
        // this condition is for when token is expired and the session is for mylink and telewriter
        // then we have to show session timeout modal

        // modalShowedAlready state is taken for if model is showed already and user click
        // on confirm then the model is opening again that's why used varible to not
        // open model again
        if (
          (history.location.pathname.includes(CUSTOMER_PERSONAL_STATEMENT) ||
            history.location.pathname.includes(CUSTOMER_TELE_UNDERWRITER_HOME_PAGE)) &&
          myLink.hasAuthorised
        ) {
          !modalShowedAlready && this.setState({ showModal: true, modalShowedAlready: true })
        } else {
          reAuthorizeUser()
        }
      }
    }
  }

  onActive = () => this.setState({ isTimedOut: false })

  handleUserLogout = () => {
    const {
      actions,
      history,
      myLink: { quoteCollectionId, hasAuthorised },
    } = this.props
    // if user click on confirm button and current page is mylink personal detail
    // then we have to redirect to otp page
    const redirectURL =
      history &&
      history.location &&
      history.location.pathname.includes(CUSTOMER_PERSONAL_STATEMENT) &&
      hasAuthorised
        ? `/personalstatement?quoteCollectionId=${quoteCollectionId}`
        : '/'

    if (isLocalStorage()) {
      window.localStorage.removeItem('NotificationBarVisible')
      window.localStorage.removeItem('userId')
    }
    this.setState({ showModal: false })
    actions.signOut(() => {}, false, redirectURL)
  }

  render() {
    const { message, confirmBtnLabel, config, hideCTAButton } = this.props
    const { showModal } = this.state
    return (
      <Fragment>
        <IdleTimer
          ref={ref => {
            idleTimer = ref
          }}
          element={(isMountedOnClient && document) || {}}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={1500}
          timeout={1000 * 60 * config.MLCL_USER_SESSION_TIMEOUT}
        />
        <Modal showCloseButton={false} isOpen={showModal} title="">
          <Message>{message}</Message>
          {!hideCTAButton && (
            <ConfirmButton type="secondary" onClick={this.handleUserLogout}>
              {confirmBtnLabel}
            </ConfirmButton>
          )}
        </Modal>
      </Fragment>
    )
  }
}

export default SessionTimeout
