// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pathOr } from 'lodash/fp'
import { actionCreators } from '../../../../../actions'
import { renderTextField } from '../../../../../utils/sitecoreUtils'

// components
import Accordion from '../../../../molecules/Accordion'
import Heading from '../../../../atoms/Heading'
import Input from '../../../../atoms/Input'
import Button from '../../../../atoms/Button'
import { getEmail } from '../../../../../utils/contactUtils'

// style
import styles from '../../applicationReview.styles'

// schema
import schema, { FORM_ID } from './secondary.schema'

// icons
import { IconEmail32, IconChevronDown16, IconChevronUp16 } from '../../../../atoms/Icons'

const AccordionContainer = styled('section')(styles.accordion)
const AccordionHeading = styled(Heading)(styles.accordionHeading)
const AccordionFieldset = styled('fieldset')(styles.accordionFieldset)
const AccordionOpenIcon = styled(IconChevronDown16)(styles.accordionIcon)
const AccordionCloseIcon = styled(IconChevronUp16)(styles.accordionIcon)
const AccordionMailIcon = styled(IconEmail32)(styles.accordionMailIcon)
const AccordionInput = styled(Input)(styles.accordionInput)
const ConfirmButton = styled(Button)(styles.confirmButton)

type ApplicationReviewProps = {
  // sitecore fields
  fields: Object,
  // redux actions
  actions: Object,
  // secondary email form object
  form: Object,
  // advisor details
  advisor: Object,
  // alternate email from quote object
  alternateEmail: Object,
}
export class SecondaryEmail extends Component<ApplicationReviewProps> {
  constructor(props) {
    super(props)
    this.state = {
      SCHEMA: schema(props.fields),
      openAccordion: false,
      showAdvisorEmailDuplicate: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount() {
    const { actions, alternateEmail } = this.props
    const { SCHEMA } = this.state
    const { formInit } = actions
    formInit(FORM_ID, SCHEMA, alternateEmail)
  }

  handleAccordionToggle = isOpen => {
    this.setState({ openAccordion: isOpen }, () => {
      if (!isOpen) {
        const { actions, alternateEmail } = this.props
        const { SCHEMA } = this.state
        const { formReset } = actions
        formReset(FORM_ID, SCHEMA, alternateEmail)
      }
    })
  }

  handleChange({ value, name }) {
    const {
      actions: { formUpdateField },
      form,
      fields,
    } = this.props
    const SCHEMA = schema(fields)
    const { showAdvisorEmailDuplicate } = this.state
    if (name === SCHEMA.email.name && value === '') {
      const { fields: { firstName } = {} } = form
      firstName.value = ''
      formUpdateField(FORM_ID, SCHEMA.firstName.name, firstName)
    }
    const data = {
      value,
    }
    if (showAdvisorEmailDuplicate) {
      this.setState({ showAdvisorEmailDuplicate: false })
    }

    formUpdateField(FORM_ID, name, data)
  }

  handleSubmit() {
    const {
      actions: { updateSecondaryEmail, formSubmit },
      fields,
      advisor,
      form,
    } = this.props
    const secondaryEmail = pathOr('', 'fields.email.value', form)
    const advisorEmailList = pathOr([], 'details.contactMethods.emails', advisor)
    const advisorEmail = getEmail(advisorEmailList)

    const SCHEMA = schema(fields, advisorEmail.toLowerCase(), secondaryEmail.toLowerCase())
    formSubmit(FORM_ID, SCHEMA, data => {
      updateSecondaryEmail(data)
      this.setState({ openAccordion: false })
    })
  }

  render() {
    const {
      fields: {
        alternateEmailLabel,
        emailConfirmButtonLabel,
        secondaryNotificationEmailLabel,
        alternateFirstNameLabel,
      } = {},
      form,
      fields,
    } = this.props
    if (!form) return null
    const { fields: { email, firstName } = {} } = form
    const { openAccordion, showAdvisorEmailDuplicate } = this.state
    const SCHEMA = schema(fields)
    return (
      <AccordionContainer>
        <Accordion
          label={
            <AccordionHeading size="5">
              <AccordionMailIcon />
              {renderTextField(secondaryNotificationEmailLabel)}
            </AccordionHeading>
          }
          isOpen={openAccordion}
          openIcon={<AccordionOpenIcon />}
          closeIcon={<AccordionCloseIcon />}
          toggleListener={this.handleAccordionToggle}
        >
          <AccordionFieldset>
            <form>
              <AccordionInput
                htmlFor="secondaryNotificationEmail"
                label={renderTextField(alternateEmailLabel)}
                type="text"
                name={SCHEMA.email.name}
                changeHandler={this.handleChange}
                value={email.value}
                error={email.error.error || showAdvisorEmailDuplicate}
                caption={email.error.error && renderTextField(email.error.errorMsg)}
              />
              <AccordionInput
                htmlFor="secondaryNotificationFirstName"
                label={renderTextField(alternateFirstNameLabel)}
                type="text"
                name={SCHEMA.firstName.name}
                changeHandler={this.handleChange}
                value={firstName.value}
                error={firstName.error.error}
                caption={firstName.error.error && renderTextField(firstName.error.errorMsg)}
              />
              <ConfirmButton
                type="secondary"
                onClick={this.handleSubmit}
                data-testid="confirmOptionsButton"
              >
                {renderTextField(emailConfirmButtonLabel)}
              </ConfirmButton>
            </form>
          </AccordionFieldset>
        </Accordion>
      </AccordionContainer>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ forms, createQuote: { quotes, activeIndex } }) => ({
  form: forms[FORM_ID],
  alternateEmail: quotes[activeIndex].alternateEmailDetails,
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryEmail)
