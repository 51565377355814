/* eslint-disable array-callback-return */
// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { toast } from 'react-toastify'
import { Tooltip, Checkbox } from '@mlcl-digital/mlcl-design'
import { get } from 'lodash'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../../utils/telemetry'
import { actionCreators } from '../../../../../../actions'

// components.
import ButtonComponent from '../../../../../atoms/Button'

import Input from '../../../../../atoms/Input'
import Modal from '../../../../../molecules/Modal'
import QuoteName from './QuoteName'
import { IconInfo16 } from '../../../../../atoms/Icons'
import PolicyStructure from '../../../../../molecules/PolicyStructure'
import EditQuoteName from './QuoteName/EditQuoteName'

// helpers.
import {
  isNonSuperProduct,
  isPolicyFeeDisabled,
  getTemplateSavePolicyStructure,
  checkCreateQuoteWithValidPolicies,
} from '../../../../../../utils/quoteUtils'
import { getDisplayPolicyStructure } from '../../../../../../utils/policyStructureUtils'

// styles.
import styles from './quoteSettings.styles'

// utilities.
import { jsonEscape } from '../../../../../../utils/commonUtils'

// types.
import { type quoteCollectionType } from '../../../../../../reducers/createQuote'
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// constants
import {
  TOAST_DELETE_QUOTE_ERROR,
  TOAST_ID_DELETE_QUOTE_ERROR,
} from '../../../../../../constants/toast'
import { getHealthyEligibleCovers } from '../../../../../../selectors/common.selectors'

// selectors
import { getIsHealthyLivingDisabled } from '../../../../../../selectors/createQuote'

type QuoteSettingsProps = {
  // an object containing action creator functions.
  actions: {
    // calculates the active quote against the mule api.
    createQuoteCalculate: Function,
    // modifies the mlc on track boolean within the quote.
    createQuoteSetPolicyStructureOnTrack: Function,
    // modifies policy fee boolean within quote
    createQuoteSetPolicyStructureOnPolicyFee: Function,
    // updates the quote name of active quote
    setQuoteName: Function,
    // Save Adviser Template Action
    saveAdviserTemplate: Function,
    // Duplicate Quote Template Action
    duplicateQuoteTemplate: Function,
    initiateSaveQuote: Function,
    // action to update healthy living toggle value
    createQuoteUpdateOption: Function,
  },
  // Sitecore fields
  fields: {
    // mlc on track label.
    quoteEntityMLCONTrack: string,
    // policy structure label.
    quoteEntityPolicyStruct: string,
    // remuneration label.
    quoteEntityRemuneration: string,
    // options label
    quoteEntityOptions: string,
    // modal labels.
    quoteEntityOnTrackModal: string,
    quoteEntityOnTrackModalTitle: string,
    quoteEntityOnTrackConfirm: string,
    QuoteEntityQuoteNameInvalidErrorMsg: string,
    QuoteEntityQuoteNameDuplicationErrorMsg: string,
    // modal policy fee labels
    quoteEntityPolicyFeeModal: string,
    quoteEntityPolicyFeeModalTitle: string,
    quoteEntityPolicyFeeConfirm: string,
    // modal delete quote from dropdown
    deleteQuoteModal: string,
    deleteQuoteModalTitle: string,
    deleteQuoteConfirm: string,
    // Save Adviser From Modal Title
    SaveAdviserFromModalTitle: string,
    // Save Adviser From Modal Desc
    SaveAdviserFromModalDesc: string,
    // Save Adviser From Modal label
    SaveAdviserFromLabel: string,
    // Save Adviser From Modal input placeholder
    SaveAdviserFromInputPlaceholder: string,
    // Save Adviser From Modal button label
    SaveAdviserFromButtonLabel: string,
    // Save Adviser From Invalid Input Message
    SaveAdviserInvalidInput: string,
    // Save Adviser From blank Input Message
    SaveAdviserBlankInput: string,
    // Save Adviser From Conflict Error Message
    SaveAdviserConflictError: string,
    // Save Adviser From Internal Server Error Message
    SaveAdviserInternalServerError: string,
    // Save Adviser Success Title
    SaveAdviserSuccessTitle: string,
    // Save Adviser Success Desc
    SaveAdviserSuccessDesc: string,
    // Save Adviser Success button Label
    SaveAdviserSuccessButtonLabel: string,
    // Save quote as template no policy or benefit error
    saveQuoteTemplatePolicyOrBenefitEmpty: string,
    QuoteHealthyLivingProgram: string,
  },
  // a quote entity from within the quote collection.
  quote: quoteCollectionType,
  // integer of the index within the quote collection that is being rendered.
  quoteIndex: number,
  // Save Adviser Policy Structure
  policyStructureData: Object,
  // Adviser template List
  data: Object,
  // Error status code
  statusCode: number,
  // quote collection object
  quotes: Object,
  // identifier for save quote initiator
  saveQuoteInitiator: string,
  showSaveQuoteModal: boolean,
  quoteCollectionId: Object,
  activeIndex: Number,
  eligibleHealthyCovers: Array<string>,
  // if healthy living discount option should be disabled
  isHealthyLivingDisabled: boolean,
}

const Wrap = styled('div')(styles.wrap)
const Error = styled('p')(styles.error)
const Button = styled(ButtonComponent)(styles.button)
const InputElement = styled(Input)(styles.inputElement)
const TooltipContent = styled(Tooltip)(styles.tooltipContent)
const InfoIcon = styled(IconInfo16)(styles.infoIcon)
const LabelText = styled('div')(styles.labelText)
// const Container = styled('div')
const SubContainer = styled('div')({ ...styles.flex, ...styles.justifySpaceBetween })
const Label = styled('div')({ ...styles.flex, ...styles.alignCenter })

export class QuoteSettingsComponent extends Component<QuoteSettingsProps> {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showPolicyFeeModal: false,
      showDeleteQuoteModal: false,
      showFromModal: false,
      showSuccessModal: false,
      value: '',
      showPolicyStructure: false,
      isQuotePolicyOrBenfitEmpty: false,
    }

    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentWillMount() {
    const {
      data,
      actions: { getTemplates },
    } = this.props
    if (!data) {
      getTemplates()
    }
  }

  componentDidUpdate(prevProps) {
    const { data, statusCode } = this.props

    // openSuccessModal : if adviser template is saved
    if (data && prevProps.data && data.length > prevProps.data.length) {
      this.openSuccessModal()
    }
    // Display error message as per status code
    if (statusCode && statusCode !== prevProps.statusCode) {
      this.checkErrorCode(statusCode)
    }
  }

  componentWillReceiveProps = (nextProps: Object) => {
    const {
      actions: { duplicateQuoteTemplate },
      quote,
    } = nextProps
    const { saveQuoteInitiator } = this.props
    // Condition to duplicate quote when updated quote collection name present and save quote
    // is successful or modal opens to update quote collection name and checks the save
    // quote action is complete
    if (
      (saveQuoteInitiator !== nextProps.saveQuoteInitiator &&
        nextProps.saveQuoteInitiator === 'duplicateQuote' &&
        !nextProps.showSaveQuoteModal) ||
      (nextProps.saveQuoteInitiator === 'duplicateQuoteModal' && nextProps.quoteCollectionId !== '')
    ) {
      // New item will be on 0 index So active index will be 0
      const quoteIndex = 0
      const quoteItem = { ...quote, quoteId: '' }
      duplicateQuoteTemplate(quoteItem, quoteIndex)
    }
  }

  openMlcOnTrackModal = (): void => {
    this.setState({ showModal: true })
  }

  openPolicyFeeModal = (): void => {
    this.setState({ showPolicyFeeModal: true })
  }

  openDeleteQuoteModal = (): void => {
    this.setState({ showDeleteQuoteModal: true })
  }

  // updates quote name of active quote in the store
  handleUpdate = quoteName => {
    const {
      actions: { setQuoteName },
    } = this.props
    setQuoteName(quoteName)
  }

  closeMlcOnTrackModal = () => {
    this.setState({ showModal: false })
  }

  closePolicyFeeModal = () => {
    this.setState({ showPolicyFeeModal: false })
  }

  closeDeleteQuoteModal = () => {
    this.setState({ showDeleteQuoteModal: false })
  }

  handleSaveAdviserSubmit = event => {
    event.preventDefault()
    const { value } = this.state
    const {
      actions: { saveAdviserTemplate },
      policyStructureData,
      data,
      fields,
    } = this.props

    const updatedPolicyStructure = policyStructureData.map(policy => ({
      ...policy,
      totalPremiumAmount: 0,
      grossPremiumAmount: 0,
      covers:
        policy.covers &&
        policy.covers.map(cover => ({
          ...cover,
          premiumAmount: 0,
          stampDuty: 0,
          features:
            cover.features &&
            cover.features.map(feature => ({
              ...feature,
              premium: 0,
            })),
        })),
    }))
    const policyStructure = jsonEscape(getTemplateSavePolicyStructure(updatedPolicyStructure))
    const templateName = value
    if (templateName !== '') {
      const uniqueStatus = data ? this.isUniqueTemplateName(data, templateName) : true
      if (uniqueStatus) {
        this.setState({ errorMessage: '' })
        saveAdviserTemplate({
          templateName,
          policyStructure,
        })
      } else {
        this.setState({ errorMessage: fields.SaveAdviserConflictError.value })
      }
    } else {
      this.setState({ errorMessage: fields.SaveAdviserBlankInput.value })
    }
  }

  /**
   * Check the Template Name is unique
   *
   * @param {string} data - Adviser Template
   * @param {number} value - Template Name
   */
  isUniqueTemplateName = (data, value) => {
    const templateList = data.filter(template => template.templateName === value)
    if (!!templateList.length > 0) {
      return false
    }
    return true
  }

  duplicateQuoteHandler = () => {
    const {
      actions: { initiateSaveQuote },
    } = this.props
    // initiate Save Quote - input param -  callback and save identifier
    initiateSaveQuote('', 'duplicateQuote')
  }

  saveQuoteHandler = () => {
    const { policyStructureData } = this.props

    const isCoverAvailable =
      (policyStructureData.length &&
        policyStructureData.some(policy => policy.covers && policy.covers.length)) ||
      false

    if (!isCoverAvailable) {
      this.setState({ isQuotePolicyOrBenfitEmpty: true })
    } else {
      this.setState({ showFromModal: true, isQuotePolicyOrBenfitEmpty: false })
    }
  }

  deleteQuoteHandler = () => {
    const {
      activeIndex,
      actions: { deleteQuoteFromCollection, deleteQuoteCollection },
      quotes,
    } = this.props

    if (quotes[activeIndex].quoteId === '') {
      deleteQuoteFromCollection(activeIndex)
    } else {
      deleteQuoteCollection(err => {
        if (err) {
          toast(TOAST_DELETE_QUOTE_ERROR, {
            autoClose: 3000,
            toastId: TOAST_ID_DELETE_QUOTE_ERROR,
            type: toast.TYPE.ERROR,
          })
        }
      })
    }
    this.setState({ showDeleteQuoteModal: false })
  }

  // on modal close
  closeFromModal = () => {
    this.setState({ showFromModal: false, value: '', errorMessage: '' })
  }

  openSuccessModal = () => {
    this.setState({ showFromModal: false, showSuccessModal: true })
  }

  closeSuccessModal = () => {
    this.setState({ showSuccessModal: false, value: '' })
  }

  handleChange = ({ value }) => {
    this.setState({ value })
  }

  checkErrorCode = statusCode => {
    const { fields } = this.props
    if (statusCode >= 200 && statusCode < 300) {
      this.setState({ errorMessage: '' })
    }
    if (statusCode === 400) {
      this.setState({ errorMessage: fields.SaveAdviserInvalidInput.value })
    }
    if (statusCode === 409) {
      this.setState({ errorMessage: fields.SaveAdviserConflictError.value })
    }
    if (statusCode === 500) {
      this.setState({ errorMessage: fields.SaveAdviserInternalServerError.value })
    }
    return null
  }

  showPolicyStructureModal = () => {
    const {
      quote,
      actions: { showSaveQuoteErrorModal },
    } = this.props

    if (checkCreateQuoteWithValidPolicies(quote)) {
      this.setState({ showPolicyStructure: true })
    } else {
      showSaveQuoteErrorModal(true)
    }
  }

  closePolicyStructureModal = () => this.setState({ showPolicyStructure: false })

  closePolicyBenefitEmptyModal = () => this.setState({ isQuotePolicyOrBenfitEmpty: false })

  closeModal = () => {
    const { unEditedValue } = this.state
    this.setState({
      value: unEditedValue,
      showSaveQuoteNameModal: false,
      isRenameQuoteName: false,
    })
  }

  showEditModal = () => {
    const { value } = this.state
    this.setState({
      unEditedValue: value,
      showSaveQuoteNameModal: true,
      isRenameQuoteName: true,
    })
  }

  /**
   *
   * @param {value}
   * description :- change handler for healthy toggle button
   */
  changeHealthyLiving = ({ value }) => {
    const {
      actions: { createQuoteUpdateOption, createQuoteCalculate, createQuoteUpdateCoversCampaign },
      eligibleHealthyCovers,
    } = this.props
    // updating option in store based on toggle value
    createQuoteUpdateOption({ healthyLivingDiscount: value })
    // adding/removing campaign in covers based on healthy living toggle
    // and also based on eligiblity
    createQuoteUpdateCoversCampaign({
      healthyLivingDiscount: value,
      eligibleHealthyCovers,
    })
    createQuoteCalculate()
    // GA tag for healthyLivingProgram button
    if (value) {
      const tagEvent = createEvent({
        GA: {
          category: 'Healthy Lives Discount toggle is turned On',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Healthy Lives Discount toggle is turned On',
          },
        },
      })
      tagEvent.end()
    } else {
      const tagEvent = createEvent({
        GA: {
          category: 'Healthy Lives Discount toggle is turned Off',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Healthy Lives Discount toggle is turned Off',
          },
        },
      })
      tagEvent.end()
    }
  }

  checkQuotePolicyFee(quoteIndex) {
    let isPolicyFeeToggleDisabled = false
    const { quote, activeIndex } = this.props

    quote.policyStructure.map(policy => {
      if (policy.productId && !isNonSuperProduct(policy.productId) && quoteIndex === activeIndex) {
        // inside super
        if (policy.fundPaymentMethod && isPolicyFeeDisabled(policy.fundPaymentMethod)) {
          isPolicyFeeToggleDisabled = true
        }
      }
    })
    return isPolicyFeeToggleDisabled
  }

  /**
   * return component array with filteredPolicyStructure
   *
   * @memberof QuoteSettingsComponent
   */
  renderPolicyStructureTree = () => {
    const { policyStructureData } = this.props
    const displayPolicyStructure = getDisplayPolicyStructure(policyStructureData)
    return (
      !!policyStructureData.length &&
      policyStructureData.map((policy, index) => {
        const { policyInstanceNo, productId } = policy
        const policykey = `${productId}_${policyInstanceNo}_${index}`
        return (
          <PolicyStructure
            key={policykey}
            policy={policy}
            displayPolicyStructure={displayPolicyStructure}
          />
        )
      })
    )
  }

  render() {
    const {
      showModal,
      showFromModal,
      showPolicyFeeModal,
      showDeleteQuoteModal,
      showSuccessModal,
      value,
      showPolicyStructure,
      errorMessage,
      isQuotePolicyOrBenfitEmpty,
      isRenameQuoteName,
      showSaveQuoteNameModal,
    } = this.state
    const { quote, quoteIndex, quotes, fields, activeIndex, isHealthyLivingDisabled } = this.props
    const isSingleQuote = quotes.length === 1
    const {
      quoteEntityOptions,
      quoteEntityPolicyStruct,
      quoteEntityOnTrackModal,
      quoteEntityOnTrackModalTitle,
      quoteEntityPolicyFeeModal,
      quoteEntityPolicyFeeModalTitle,
      quoteEntityOnTrackConfirm,
      quoteEntityPolicyFeeConfirm,
      quoteEntityRenameQuoteName,
      quoteEntityRenameButtonLabel,
      QuoteEntityQuoteNameInvalidErrorMsg,
      QuoteEntityQuoteNameDuplicationErrorMsg,
      deleteQuoteModalTitle,
      deleteQuoteModal,
      deleteQuoteConfirm,
      SaveAdviserFromModalTitle,
      SaveAdviserFromModalDesc,
      SaveAdviserFromLabel,
      SaveAdviserFromInputPlaceholder,
      SaveAdviserFromButtonLabel,
      SaveAdviserSuccessTitle,
      SaveAdviserSuccessDesc,
      SaveAdviserSuccessButtonLabel,
      QuoteSettingPolicyStructure,
      QuoteSettingSaveQuote,
      QuoteSettingDuplicateQuote,
      QuoteSettingDeleteQuote,
      saveQuoteTemplatePolicyOrBenefitEmpty,
      QuoteHealthyLivingProgram,
      QuoteHealthyLivingProgramTooltip,
    } = fields

    const popoverItems = [
      {
        name: 'renameQuote',
        label: quoteEntityRenameQuoteName,
        onClick: this.showEditModal,
      },
      {
        name: 'policyStructure',
        label: QuoteSettingPolicyStructure,
        onClick: this.showPolicyStructureModal,
      },
      {
        name: 'saveQuote',
        label: QuoteSettingSaveQuote,
        onClick: this.saveQuoteHandler,
      },
      {
        name: 'duplicateQuote',
        label: QuoteSettingDuplicateQuote,
        onClick: this.duplicateQuoteHandler,
      },
    ]
    const quoteItem = [
      {
        name: 'healthyLivingDiscount',
        label: QuoteHealthyLivingProgram,
        changeHandler: this.changeHealthyLiving,
        tooltip: QuoteHealthyLivingProgramTooltip,
        tooltipOffset: { bottom: 90 },
        state: get(quote, 'option.healthyLivingDiscount', false),
        disabled: isHealthyLivingDisabled,
      },
    ]

    if (!isSingleQuote) {
      const deleteItem = {
        name: 'deleteQuote',
        label: QuoteSettingDeleteQuote,
        onClick: this.openDeleteQuoteModal,
      }
      popoverItems.push(deleteItem)
    }

    return (
      <Wrap>
        <QuoteName
          handleUpdate={this.handleUpdate}
          noMargin
          renameText="Rename"
          quoteEntityRenameQuoteName={quoteEntityRenameQuoteName}
          quoteNameSettingActionsText={quoteEntityOptions.value}
          value={quote.quoteName}
          activeIndex={quoteIndex}
          collection={quotes}
          popoverItems={popoverItems}
        />
        <Modal
          isOpen={showModal}
          onClose={this.closeMlcOnTrackModal}
          title={quoteEntityOnTrackModalTitle.value}
        >
          <Text field={quoteEntityOnTrackModal} />
          <Button type="secondary" onClick={this.closeMlcOnTrackModal}>
            <Text field={quoteEntityOnTrackConfirm} />
          </Button>
        </Modal>

        <Modal
          isOpen={showPolicyFeeModal}
          onClose={this.closePolicyFeeModal}
          title={quoteEntityPolicyFeeModalTitle.value}
        >
          <Text field={quoteEntityPolicyFeeModal} />
          <Button type="secondary" onClick={this.closePolicyFeeModal}>
            <Text field={quoteEntityPolicyFeeConfirm} />
          </Button>
        </Modal>

        <Modal
          isOpen={showDeleteQuoteModal}
          onClose={this.closeDeleteQuoteModal}
          title={deleteQuoteModalTitle.value}
        >
          <Text field={deleteQuoteModal} />
          <Button type="secondary" onClick={this.deleteQuoteHandler}>
            <Text field={deleteQuoteConfirm} />
          </Button>
        </Modal>

        <Modal
          isOpen={showFromModal}
          onClose={this.closeFromModal}
          title={SaveAdviserFromModalTitle.value}
        >
          <p>{SaveAdviserFromModalDesc.value}</p>
          <form>
            <InputElement
              htmlFor="advisorTemplateName"
              name="advisorTemplateName"
              label={SaveAdviserFromLabel.value}
              changeHandler={this.handleChange}
              error={errorMessage}
              value={value}
              placeholder={SaveAdviserFromInputPlaceholder.value}
              focusOnMount
            />
            {errorMessage !== null && <Error>{errorMessage}</Error>}
            <ButtonComponent onClick={this.handleSaveAdviserSubmit} type="secondary">
              {SaveAdviserFromButtonLabel.value}
            </ButtonComponent>
          </form>
        </Modal>

        <Modal
          isOpen={showSuccessModal}
          onClose={this.closeSuccessModal}
          title={SaveAdviserSuccessTitle.value}
        >
          <div>
            <p>{`${value} ${SaveAdviserSuccessDesc.value}`}</p>
            <ButtonComponent type="secondary" onClick={this.closeSuccessModal}>
              {SaveAdviserSuccessButtonLabel.value}
            </ButtonComponent>
          </div>
        </Modal>

        {isRenameQuoteName && (
          <Modal
            isOpen={showSaveQuoteNameModal}
            title={quoteEntityRenameQuoteName}
            onClose={this.closeModal}
          >
            <EditQuoteName
              collection={quotes}
              activeIndex={activeIndex}
              value={quote.quoteName}
              handleUpdate={this.handleUpdate}
              fields={{
                quoteEntityRenameButtonLabel,
                QuoteEntityQuoteNameInvalidErrorMsg,
                QuoteEntityQuoteNameDuplicationErrorMsg,
              }}
            />
          </Modal>
        )}
        {showPolicyStructure && (
          <Modal
            smallMode
            disablePadding
            isOpen={showPolicyStructure}
            onClose={this.closePolicyStructureModal}
            title={quoteEntityPolicyStruct}
          >
            {this.renderPolicyStructureTree()}
          </Modal>
        )}
        {isQuotePolicyOrBenfitEmpty && (
          <Modal
            isOpen={isQuotePolicyOrBenfitEmpty}
            onClose={this.closePolicyBenefitEmptyModal}
            title={SaveAdviserFromModalTitle.value}
          >
            <Text field={saveQuoteTemplatePolicyOrBenefitEmpty} />
          </Modal>
        )}
        <div>
          {quoteItem.map(_ele => (
            <SubContainer key={_ele.name}>
              <Label>
                <LabelText>{renderTextField(_ele.label)}</LabelText>
                <div data-tip data-for={_ele.name}>
                  <InfoIcon />
                </div>
                <TooltipContent id={_ele.name} place="right" offset={_ele.tooltipOffset} multiline>
                  {renderTextField(_ele.tooltip, true)}
                </TooltipContent>
              </Label>
              <Checkbox
                name={_ele.name}
                direction="right"
                htmlFor={`${_ele.name}s`}
                onChangeHandler={_ele.changeHandler}
                checked={_ele.state}
                variant="toggle"
                disabled={_ele.disabled}
              />
            </SubContainer>
          ))}
        </div>
      </Wrap>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = state => ({
  data: state.quoteTemplates.advisorManaged.data,
  statusCode: state.quoteTemplates.statusCode,
  policyStructureData: state.createQuote.quotes[state.createQuote.activeIndex].policyStructure,
  saveQuoteInitiator: state.createQuote.saveQuoteInitiator,
  showSaveQuoteModal: state.createQuote.showSaveQuoteModal,
  quoteCollectionId: state.createQuote.quoteCollectionId,
  quotes: state.createQuote.quotes,
  eligibleHealthyCovers: getHealthyEligibleCovers(state.productRules),
  isHealthyLivingDisabled: getIsHealthyLivingDisabled(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSettingsComponent)
