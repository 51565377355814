/* eslint-disable no-unused-vars */
import {
  colours,
  containerUtils,
  fontFamily,
  fontSize,
  mediaQueries,
  shadows,
  space,
  zIndex,
  fontWeights,
} from '../../../../../styles'

const linkSpacing = {
  paddingTop: space(2),
  paddingBottom: space(2),
  display: 'flex',
  alignItems: 'center',
}

const styles = {
  header: ({ isScrolling }) => ({
    backgroundColor: colours.white,
    paddingTop: space(2),
    position: 'fixed',
    left: 0,
    right: 0,
    boxShadow: isScrolling ? shadows.mid : 'none',
    zIndex: zIndex.mainNavigation,

    '& + *': {
      paddingTop: space(8),

      [mediaQueries.md]: {
        paddingTop: space(11),
      },
    },

    [mediaQueries.md]: {
      padding: 0,
      paddingLeft: space(3),
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
    },
  }),
  logoContainer: {
    ...containerUtils.innerPaddingHorizontal,
    alignSelf: 'flex-start',
    marginBottom: space(2),
    display: 'flex',
    justifyContent: 'space-between',

    [mediaQueries.md]: {
      marginBottom: 0,
      marginRight: space(1),
      padding: 0,
      alignSelf: 'center',

      [mediaQueries.lg]: {
        marginRight: space(4),
      },
    },
  },
  nav: ({ isOpen }) => ({
    color: colours.darkGrey,
    display: isOpen ? 'block' : 'none',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    [mediaQueries.md]: {
      display: 'flex',
    },
  }),
  item: ({ showOnSmall }) => ({
    display: 'block',

    [mediaQueries.md]: {
      border: 0,
      color: colours.darkGreen,
      display: showOnSmall && 'none',
      fontFamily: fontFamily.sourceSans,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),

  list: {
    fontFamily: fontFamily.charlie,

    '> li:last-child': {
      borderBottomWidth: 1,
      borderBottomColor: colours.lightGrey,
      borderBottomStyle: 'solid',

      [mediaQueries.md]: {
        borderBottomWidth: 0,
      },
    },

    [mediaQueries.md]: {
      display: 'flex',
      borderBottomWidth: 0,
    },
  },

  itemLink: {
    fontWeight: fontWeights.bold,

    [mediaQueries.md]: {
      paddingLeft: space(2),
      paddingRight: space(2),
      ...linkSpacing,
    },
    [mediaQueries.lg]: {
      paddingLeft: space(3.5),
      paddingRight: space(3.5),
    },
  },
}

export default styles
