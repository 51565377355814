// @flow
import React from 'react'
import styled from '@emotion/styled'
import styles from './step.styles'
import Button from '../../../../atoms/Button'
import { APPLICATION_STAGE_STEP } from '../../../../../constants/application'

let Icon = null
let Progress = null
let IconContainer = null
let ButtonContainer = null
const Title = styled('p')(styles.title)
const Wrapper = styled('article')(styles.wrapper)
const ProgressMessage = styled('p')(styles.progressMessage)

type StepProps = {
  statusText: string,
  icon: React.Element,
  displayName: string,
  fields: Object<Object>,
  onButtonClickHandler?: Object<Function>,
  status: 'yetToStart' | 'inProgress' | 'completed',
  step: number,
  stage: number,
  stageStatus: string,
}

const renderIcon = (icon, status, statusText) => {
  Icon = styled(icon)(styles.icon)
  Progress = styled('div')(
    styles.progress(
      status === APPLICATION_STAGE_STEP.completed ||
        status === APPLICATION_STAGE_STEP.lifeAssuredCompleted
    )
  )
  IconContainer = styled('div')(styles.iconContainer(status))

  return (
    <Progress>
      <IconContainer>
        <Icon />
      </IconContainer>
      <ProgressMessage>{statusText}</ProgressMessage>
    </Progress>
  )
}

const renderButton = (status, clickHandler, fields, step, stage, stageStatus) => {
  const isDone = status === 'yetToStart'
  // TODO: reconsider the approach to stage status
  // and steps schema, as step 4 is dependent on stage 2.
  const disabled =
    (step === 2 && stageStatus !== APPLICATION_STAGE_STEP.completed) ||
    (step === 3 && stageStatus !== APPLICATION_STAGE_STEP.completed)

  ButtonContainer = styled('div')(styles.buttonContainer(isDone))

  return (
    <ButtonContainer>
      <Button type="tertiary" onClick={clickHandler} disabled={disabled}>
        {status !== APPLICATION_STAGE_STEP.incomplete || step === 0
          ? fields.applicationStepEditText
          : fields.applicationStepStartText}
      </Button>
    </ButtonContainer>
  )
}

const Step = ({
  step,
  stage,
  fields,
  displayName,
  icon,
  status,
  statusText,
  onButtonClickHandler,
  stageStatus,
}: StepProps) => (
  <Wrapper>
    <Title>{displayName}</Title>
    {renderIcon(icon, status, statusText)}
    {renderButton(status, onButtonClickHandler, fields, step, stage, stageStatus)}
  </Wrapper>
)

Step.defaultProps = {
  onButtonClickHandler() {},
}

export default Step
