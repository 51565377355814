// @flow
import React from 'react'
import AltsDeclarationOrganism from '../../../organisms/Alteration/AltsDeclaration'

type AltsDeclarationProps = {
  fields: Object,
}

const AltsDeclaration = ({ fields }: AltsDeclarationProps) => (
  <AltsDeclarationOrganism fields={fields} />
)

export default AltsDeclaration
