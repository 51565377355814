import {
  POLICY_ANNIVERSARY_ID,
  RISK_OF_LAPSE_ID,
  RECENTLY_LAPSE_ID,
  RECENTLY_CANCELLED_ID,
} from '../../../constants/dashboard'

export const getRetentionTableData = (
  dashboardDetail,
  {
    retentionDashboardDownloadAnniversaryFilename,
    retentionDashboardDownloadRiskOfLapseFilename,
    retentionDashboardDownloadLapsedFilename,
    retentionDashboardDownloadCancelledFilename,
  }
) => ({
  [POLICY_ANNIVERSARY_ID]: {
    data: dashboardDetail.anniversary || {},
    columns: [
      'policyOwner',
      'lifeInsured',
      'policyNo',
      'premium',
      'newPremium',
      'anniversaryDate',
      'paymentFrequency',
    ],
    csvFileName: retentionDashboardDownloadAnniversaryFilename,
  },
  [RISK_OF_LAPSE_ID]: {
    data: dashboardDetail.riskOfLapse || {},
    columns: [
      'policyOwner',
      'lifeInsured',
      'policyNo',
      'premium',
      'paymentOutstandingAmount',
      'anniversaryDate',
      'scheduledLapseDate',
      'emailClient',
    ],
    csvFileName: retentionDashboardDownloadRiskOfLapseFilename,
  },
  [RECENTLY_LAPSE_ID]: {
    data: dashboardDetail.lapsed || {},
    columns: [
      'policyOwner',
      'lifeInsured',
      'policyNo',
      'premium',
      'paymentOutstandingAmount',
      'anniversaryDate',
      'lapseDate',
      'emailClient',
    ],
    csvFileName: retentionDashboardDownloadLapsedFilename,
  },
  [RECENTLY_CANCELLED_ID]: {
    data: dashboardDetail.cancelled || {},
    columns: [
      'policyOwner',
      'lifeInsured',
      'policyNo',
      'premium',
      'anniversaryDate',
      'cancellationDate',
    ],
    csvFileName: retentionDashboardDownloadCancelledFilename,
  },
})
