/* eslint-disable jsx-a11y/aria-role */
// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Hero, Loader, PageWrap, Select, Input } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isArray, isEmpty, mapValues } from 'lodash'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'

// atoms.
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import ReCaptcha from '../../atoms/ReCaptcha'
import { IconExclaimation16, IconThumbsUp32 } from '../../atoms/Icons'
import ScrollToTop from '../../atoms/ScrollToTop'

// components
import AddressLookUp from '../../molecules/AddressLookUp'
import RadioGroup from '../../molecules/RadioGroup'
import AbnLookup from '../../molecules/AbnLookup'
import Dropzone from '../../molecules/FileUpload/components/Dropzone'
import FileUploadingProgress from '../../molecules/FileUpload/components/FileUploadingProgress'
import InputPhone from '../../molecules/InputPhone'

// schema.
import SCHEMA, { FORM_ID } from './adviserRegistration.schema'
import AUTHORIZED_REP_SCHEMA from './authorizedRepresentive.schema'

// utils
import {
  errorCheck,
  generateFieldsFromData,
  getValue,
  getSerializedFormState,
} from '../../../utils/formUtils'
import {
  checkMimeType,
  convertByteFileSizeToKB,
  generateFileKey,
  getDocumentTypeDetails,
} from '../../../utils/fileUpload'

// styles
import styles from './adviserRegistration.styles'
import { ignoreColumns } from '../../../utils/stylesUtils'

// constant
import {
  TERMS_AND_CONDITION,
  DECLARATION_YES,
  DECLARATION_NO,
  PARTY_TYPE,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  DATE_OF_BIRTH,
  ABN_NUMBER,
  ASIC_REP_NO,
  AFSL_NAME,
  AFSL_NUMBER,
  ADVISER_REGISTRATION_RESIDENTIAL_ADDRESS,
  ADVISER_REGISTRATION_RESIDENTIAL_STREET,
  ADVISER_REGISTRATION_RESIDENTIAL_HOUSENO,
  ADVISER_REGISTRATION_RESIDENTIAL_LOCALITY,
  ADVISER_REGISTRATION_RESIDENTIAL_STATE,
  ADVISER_REGISTRATION_RESIDENTIAL_COUNTRY,
  ADVISER_REGISTRATION_RESIDENTIAL_POSTCODE,
  ADVISER_REGISTRATION_CONTACT_NUMBER,
  ADVISER_REGISTRATION_CONTACT_NUMBER_CODE,
  ADVISER_REGISTRATION_CONTACT_NUMBER_COUNTRY_CODE,
  EMAIL,
  PARTY_TYPE_PERSON,
  PARTY_TYPE_BUSINESS,
  BUSINESS_NAME,
  AUTHORIZED_REPRESENTATIVES_FIELD_NAME,
  AUTHORIZED_REPRESENTATIVE_MAXIMUM_NUMBER,
} from '../../../constants/adviserRegistration'
import { NAVIGATION_ONLY_LOGO } from '../../../constants/navigation'
import { DEFAULT_PHONE_CODE } from '../../../constants/contactDetails'
import { NULL_BYTE, INVALID_FILE_NAME } from '../../../constants/documentTypes'
import { COUNTRY_CODE } from '../../../constants/policies'
import AuthorizedRep from './components/AuthorizedRep'
import { uuidv4 } from '../../../utils/commonUtils'

type AdviserRegistrationProps = {
  // To render all labels from sitecore content editor
  fields: Object,
  // Redux actions available to the component.
  actions: Object,
  // Redux form data of the component.
  form: Object,
  // file uplaod info
  fileUploadInfo: Object,
  // Address lookup manual entry redux state mapped to props.
  isManualPostal: boolean,
  // adviser registration
  adviserRegistration: Object,
  // is adviser registered successful
  isAdviserRegistrationSuccessful: Boolean,
  // workItemReferenceNo from api response
  workItemReferenceNo: string,
  // abn lookup entity
  abnLookup: Object,
  // adviser registration API call loading prop
  isLoading: boolean,
  config: Object,
  authorizedReps: Object,
}
type AdviserRegistrationState = {
  // selected party type
  selectedPartyType: string,
  // is file uploaded
  isFileUploaded: boolean,
  // Manual residential address
  isManualResidential: boolean,
}

const AdviserRegistrationWrapper = styled('section')(styles.adviserRegistrationWrapper)
const AdviserRegistrationContainer = styled('section')(styles.adviserRegistrationContainer)
const AdviserRegistrationRadioGroupWrapper = styled('section')(
  styles.adviserRegistrationRadioGroupWrapper
)
const Footer = styled('footer')(styles.footer)
export const Fieldset = styled('div')(({ withoutFlex }) => styles.fieldset(withoutFlex))
export const InputXsm = styled(Input)(({ withoutMargin, transparent }) =>
  styles.sectionXsm(withoutMargin, transparent)
)
export const SelectTitle = styled(Select)(({ withoutMargin }) => styles.sectionXsm(withoutMargin))
export const FirstName = styled(Input)(({ withoutMargin }) => styles.sectionXl(withoutMargin))
export const DateOfBirth = styled(Input)(styles.dateOfBirth)
export const ButtonContainer = styled('div')(({ secondary }) => styles.buttonContainer(secondary))

const InputFullWidth = styled(Input)(styles.fullWidth)
const AbnInputFullWidth = ignoreColumns(AbnLookup)
const Wrap = styled(PageWrap)(styles.wrap, styles.offset, styles.tableRow)
const RadioGroupWrapper = styled('div')(styles.radioWrapper)
const Description = styled('div')(styles.declarationDescriptions)
const ExploreWrapper = styled('section')(styles.exploreWrapper)
const ExploreMessageWrapper = styled('div')(styles.exploreMessageWrapper)
const ExploreButton = styled(Button)(styles.exploreButton)
const Submit = styled(Button)(styles.submit)
const Spinner = styled(Loader)(styles.spinner)
const ExploreDescription = styled('p')(styles.exploreDescription)
const ExploreHeading = styled('p')(styles.exploreHeading)
const ExploreDescriptionBold = styled('p')(styles.exploreDescriptionBold)
const ExploreReferenceNumber = styled('p')(styles.exploreReferenceNumber)
const UploadCertificateWrapper = styled('div')(styles.uploadCertificateWrapper)
const FileNotUploadError = styled('h6')(styles.fileNotUploadError)
const ExclaimationIcon = styled(IconExclaimation16)(styles.errorIcon)
const SuccessfulIcon = styled(IconThumbsUp32)(styles.successfulIcon)
const Row = styled('div')(styles.row)
const BusinessNameWrap = styled('div')(styles.businessNameWrap)
const BusinessNameLabel = styled('p')(styles.businessNameLabel)
const BusinessName = styled('p')(styles.businessName)

export const RESIDENTIAL_ADDRESS = [
  'residentialAddress',
  'residentialStreet',
  'residentialHouseNo',
  'residentialLocality',
  'residentialState',
  'residentialPostCode',
]
export const POSTAL_ADDRESS = [
  'postalAddress',
  'postalStreet',
  'postalHouseNo',
  'postalLocality',
  'postalState',
  'postalPostCode',
]

export class AdviserRegistration extends Component<
  AdviserRegistrationProps,
  AdviserRegistrationState
> {
  constructor(props) {
    super(props)
    this.state = {
      selectedPartyType: PARTY_TYPE_PERSON,
      isFileUploaded: true,
      isManualResidential: false,
    }
    // For storing authorized representatives ids
    // Using for rendering the array components
    this.authRepIds = []

    this.adviserRegistrationFormInit()

    // Binding function
    this.addAuthorizedRepToList = this.addAuthorizedRepToList.bind(this)
  }

  componentDidMount() {
    const { actions } = this.props
    const { changeNavigationType } = actions
    changeNavigationType(NAVIGATION_ONLY_LOGO)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const {
      isManualPostal,
      actions: { formUpdateField, formValidate, resetFilesData },
      fields: messagefields,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
      form: { fields },
    } = this.props
    const { isManualResidential } = this.state
    if (name === PARTY_TYPE) {
      this.setState({ selectedPartyType: value.label })
      this.adviserRegistrationFormInit()
      resetFilesData()
    }
    const schema = SCHEMA(isManualPostal, isManualResidential, postalHidden, fields, messagefields)
    const data = {
      error: errorCheck(value, schema[name].condition),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  // handle changes on authorized representatives form elements.
  handleAuthorizedRepsChange = (event, repIndex) => {
    const {
      actions: { formUpdateField },
      fields: messagefields,
      authorizedReps,
    } = this.props
    const schema = AUTHORIZED_REP_SCHEMA(messagefields)
    const { name, value } = event
    const data = {
      error: errorCheck(value, schema[name] && schema[name].condition),
      value,
    }
    // Update the representative
    const theRep = authorizedReps.value[repIndex]
    theRep[name] = data
    // Update the parent form data
    formUpdateField(FORM_ID, AUTHORIZED_REPRESENTATIVES_FIELD_NAME, authorizedReps)
  }

  removeAuthRepId = index => {
    this.authRepIds.splice(index, 1)
  }

  // handle removing authorized Representative
  handleRemoveRepresentative = repIndex => {
    const {
      actions: { formUpdateField },
      authorizedReps,
    } = this.props

    this.removeAuthRepId(repIndex)
    authorizedReps.value.splice(repIndex, 1)
    formUpdateField(FORM_ID, AUTHORIZED_REPRESENTATIVES_FIELD_NAME, authorizedReps)
  }

  // Return true if no authorized reps
  validateAllAuthorizedReps = () => {
    const {
      authorizedReps,
      fields: messagefields,
      actions: { formUpdateField },
    } = this.props
    // Return if no authorizes reps yet
    if (!authorizedReps || !authorizedReps.value) return true

    const schema = AUTHORIZED_REP_SCHEMA(messagefields)
    let isValid = true
    // Loop each rep and update its value by checking error
    const newRepsValues = authorizedReps.value.map(rep => {
      const newRep = mapValues(rep, (repPropObj, repProp) => {
        const { value } = repPropObj
        const newData = {
          error: errorCheck(value, schema[repProp] && schema[repProp].condition),
          value,
        }
        // Invalid if any rep has any field has error
        if (newData.error.error) {
          isValid = false
        }
        return newData
      })
      return newRep
    })
    // Update the authorizedReps
    authorizedReps.error = !isValid
    authorizedReps.value = newRepsValues
    // Update the filed value
    formUpdateField(FORM_ID, AUTHORIZED_REPRESENTATIVES_FIELD_NAME, authorizedReps)
    return isValid
  }

  onSubmit = async () => {
    const {
      isManualPostal,
      actions: { registerAnAdviser, formSubmit },
      fields: messagefields,
      form: { fields },
      fileUploadInfo: { files },
      abnLookup: { isAbnCancelled, isNoBusinessName, companyName },
      adviserRegistration: { recaptchaToken },
      documentTypes,
    } = this.props
    // Validate authorized representatives
    this.validateAllAuthorizedReps()

    // Submit the whole form
    const { adviserRegistrationUploadCertificateTitle } = reduceAuthorableFields(messagefields)
    const { isManualResidential } = this.state
    const file = files && files[0] && files[0].file

    const schema = SCHEMA(isManualPostal, isManualResidential, true, fields, messagefields)

    const partyType = get(fields, 'partyType.value.value', PARTY_TYPE_PERSON)

    formSubmit(FORM_ID, schema, (data, form) => {
      if (isAbnCancelled) return
      if (files.length < 1) {
        this.setIsFileUploaded(false)
        return
      }
      if (form.isValid) {
        let businessName = ''
        if (isNoBusinessName) {
          businessName = get(fields, 'businessName.value', '')
        } else {
          businessName = companyName
        }
        const documentEntity = getDocumentTypeDetails(
          adviserRegistrationUploadCertificateTitle,
          documentTypes
        )
        registerAnAdviser(
          {
            ...getSerializedFormState(fields),
            [ADVISER_REGISTRATION_CONTACT_NUMBER_COUNTRY_CODE]: get(
              fields,
              `${ADVISER_REGISTRATION_CONTACT_NUMBER_CODE}.value.countryCode`,
              ''
            ),
          },
          recaptchaToken,
          file,
          businessName,
          partyType,
          documentEntity
        )
        const event = createEvent({
          GA: {
            category: 'Adviser confirms registration(public web page)',
            action: 'Register',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Adviser confirms registration(public web page)',
            },
          },
        })
        event.end()
      }
    })
  }

  addressChange = address => {
    const { name, data, value } = address
    const {
      isManualPostal,
      fields: messagefields,
      actions: { formUpdateField, formUpdate, formValidate },
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
      form: { fields },
    } = this.props
    const { isManualResidential } = this.state
    const schema = SCHEMA(isManualPostal, isManualResidential, postalHidden, fields, messagefields)
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  addressToggleHandler =
    key =>
    (isManual: boolean): void => {
      const {
        actions: { formResetField },
      } = this.props
      if (key === 'isManualPostal') {
        this.setState({ isManualResidential: true })
      } else {
        this.setState({ isManualResidential: false })
      }
      // We clear address fields when entering manual mode
      if (isManual) {
        formResetField(FORM_ID, key === 'isManualPostal' ? POSTAL_ADDRESS : RESIDENTIAL_ADDRESS)
      }
      this.setState({
        [key]: isManual,
      })
    }

  // click on change client mendatories
  onConfirmation = () => {
    const { config } = this.props
    window.location = config.MLCL_WEBSITE_URL
  }

  handleRecaptchaTokenGeneration = async (e, execute) => {
    e.preventDefault()
    const recaptchaToken = await execute()
    const {
      actions: { setReCaptchaToken },
    } = this.props
    setReCaptchaToken(recaptchaToken)
    this.onSubmit()
  }

  setIsFileUploaded = (val: boolean) => this.setState({ isFileUploaded: val })

  handleFileDrop = file => {
    const {
      actions: { browseFilesToUpload },
    } = this.props
    this.setIsFileUploaded(true)
    browseFilesToUpload(file)
  }

  handleFileRemove = fileId => {
    const {
      actions: { removeFile },
      fileUploadInfo: { files },
    } = this.props
    if (fileId !== '') {
      removeFile(files, fileId)
      this.setIsFileUploaded(false)
    }
  }

  validateFiles = (list: Array) => {
    const { fields } = this.props
    const {
      adviserRegistrationMaxFileUploadCount,
      adviserRegistrationMaxFileSize,
      adviserRegistrationWrongMimeType,
      adviserRegistrationUploadFailure,
    } = reduceAuthorableFields(fields)
    let result = { hasValidationError: false }

    if (list.length > adviserRegistrationMaxFileUploadCount) return []
    let fileSizeInKB = 0
    const data = Object.values(list).map(file => {
      const fileId = generateFileKey(file)
      result = { ...result, file, fileId }
      fileSizeInKB = convertByteFileSizeToKB(file.size)

      if (file.name.includes(NULL_BYTE)) {
        result = {
          ...result,
          hasValidationError: true,
          fileValidationErrorLabel: INVALID_FILE_NAME,
        }
      }
      if (fileSizeInKB > adviserRegistrationMaxFileSize) {
        result = {
          ...result,
          fileValidationErrorLabel: adviserRegistrationUploadFailure,
          hasValidationError: true,
        }
      }

      if (checkMimeType(file)) {
        // eslint-disable-next-line no-undef
        result = {
          ...result,
          hasValidationError: true,
          fileValidationErrorLabel: adviserRegistrationWrongMimeType,
        }
      }

      // eslint-disable-next-line consistent-return
      return result
    })

    // eslint-disable-next-line consistent-return
    return data
  }

  handleCheckDeclarationType = (type: string) => {
    const {
      form: { fields },
      fields: messagefields,
    } = this.props
    const schema = SCHEMA(true, true, true, fields, messagefields)
    switch (type) {
      case TERMS_AND_CONDITION:
        return schema.termsAndCondition.condition
      default:
        return ''
    }
  }

  handleDeclarationToUpdate = (type: string) => {
    switch (type) {
      case TERMS_AND_CONDITION:
        return TERMS_AND_CONDITION
      default:
        return ''
    }
  }

  addAuthRepId = newId => {
    this.authRepIds.push(newId)
  }

  addAuthorizedRepToList = () => {
    const {
      actions: { formUpdateField },
      authorizedReps,
    } = this.props
    const repsObj = authorizedReps || { error: false, value: [] }
    const repsValue = isArray(repsObj.value) ? repsObj.value : []
    const data = {
      representativeTitle: {
        value: '',
        error: false,
      },
      representativeFirstName: {
        value: '',
        error: false,
      },
      representativeLastName: {
        value: '',
        error: false,
      },
      representativeAsicRepNo: {
        value: '',
        error: false,
      },
    }
    this.addAuthRepId(uuidv4())
    repsValue.push(data)
    repsObj.value = repsValue

    formUpdateField(FORM_ID, AUTHORIZED_REPRESENTATIVES_FIELD_NAME, repsObj)
  }

  renderAuthorizedReps = () => {
    const { fields: messagefields, authorizedReps } = this.props
    const schema = AUTHORIZED_REP_SCHEMA(messagefields)
    return !authorizedReps || isEmpty(authorizedReps.value)
      ? null
      : authorizedReps.value.map((representative, index) => {
          const repId = this.authRepIds[index]
          return (
            <AuthorizedRep
              key={repId}
              fields={messagefields}
              schema={schema}
              representative={representative}
              index={index}
              repId={repId}
              handleAuthorizedRepsChange={this.handleAuthorizedRepsChange}
              handleRemoveRepresentative={this.handleRemoveRepresentative}
              isFirstElement={index === 0}
            />
          )
        })
  }

  handleDeclarations(type: string, value: string) {
    const {
      isManualPostal,
      actions: { formUpdateField, formValidate },
      fields: messagefields,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
      form: { fields },
    } = this.props
    const { isManualResidential } = this.state
    const schema = SCHEMA(isManualPostal, isManualResidential, postalHidden, fields, messagefields)

    const checkFieldCondition = this.handleCheckDeclarationType(type)
    const isValid = {
      error: errorCheck(checkFieldCondition, value),
      value,
    }
    const declarationToUpdate = this.handleDeclarationToUpdate(type)
    formUpdateField(FORM_ID, declarationToUpdate, isValid)
    formValidate(FORM_ID, schema)
  }

  adviserRegistrationFormInit() {
    const {
      isManualPostal,
      actions: { formInit },
      fields: messagefields,
    } = this.props
    const { isManualResidential } = this.state
    const data = {
      partyType: PARTY_TYPE_PERSON,
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      isPostalResidentialAddressSame: true,
      residentialAddress: '',
      residentialStreet: '',
      residentialHouseNo: '',
      residentialLocality: '',
      residentialState: '',
      residentialCountry: COUNTRY_CODE,
      residentialPostCode: '',
      termsAndCondition: '',
      [ADVISER_REGISTRATION_CONTACT_NUMBER_CODE]: DEFAULT_PHONE_CODE,
    }
    const schema = SCHEMA(isManualPostal, isManualResidential, true, {}, messagefields)
    formInit(FORM_ID, schema, data)
  }

  renderFileList = () => {
    const { fields } = this.props
    const {
      adviserRegistrationFileUploading,
      adviserRegistrationUploadSuccess,
      adviserRegistrationUploadFailure,
      adviserRegistrationFileRemove,
    } = reduceAuthorableFields(fields)
    const {
      fileUploadInfo: { files },
    } = this.props
    const fileUploadMeta = {
      fileUploading: adviserRegistrationFileUploading,
      fileUploadSuccess: adviserRegistrationUploadSuccess,
      fileUploadFailure: adviserRegistrationUploadFailure,
      fileRemoveButtonLabel: adviserRegistrationFileRemove,
    }

    return files.map(fileData => {
      const fileKey = generateFileKey(fileData.file)
      return (
        <FileUploadingProgress
          key={fileKey}
          handleFileRemove={this.handleFileRemove}
          fileUploadMeta={fileUploadMeta}
          fileData={fileData}
        />
      )
    })
  }

  render() {
    const {
      form,
      fields,
      isManualPostal,
      abnLookup,
      abnLookup: { isAbnCancelled, isNoBusinessName },
      isAdviserRegistrationSuccessful,
      workItemReferenceNo,
      isLoading,
      fileUploadInfo: { files },
      authorizedReps,
    } = this.props
    if (!form) return null
    const {
      fields: {
        isPostalResidentialAddressSame: { value: postalHidden },
      },
    } = form
    const { selectedPartyType, isFileUploaded, isManualResidential } = this.state
    const {
      title,
      firstName,
      lastName,
      dateOfBirth,
      email,
      partyType,
      businessName,
      residentialAddress,
      contactNumber,
      abnNumber,
      afslNumber,
      asicRepNo,
      afslName,
      residentialStreet,
      residentialHouseNo,
      residentialLocality,
      residentialState,
      residentialCountry,
      residentialPostCode,
      termsAndCondition,
      contactNumberCode,
    } = form.fields
    const schema = SCHEMA(isManualPostal, isManualResidential, postalHidden, form.fields, fields)

    const {
      adviserRegistrationPersonalDetailTitle,
      adviserRegistrationPartyType,
      adviserRegistrationPartyTypePlaceholder,
      adviserRegistrationPartyTypeError,
      adviserRegistrationTitle,
      adviserRegistrationTitlePlaceholder,
      adviserRegistrationTitleError,
      adviserRegistrationFirstName,
      adviserRegistrationFirstNamePlaceholder,
      adviserRegistrationFirstNameError,
      adviserRegistrationLastName,
      adviserRegistrationLastNamePlaceholder,
      adviserRegistrationLastNameError,
      AuthorisedRepresentativesSectionHeading,
      AuthorisedRepresentativesSectionButton,
      AuthorisedRepresentativesSectionAddAnotherButton,
      adviserRegistrationDateOfBirth,
      adviserRegistrationDateOfBirthError,
      adviserRegistrationDateOfBirthPlaceHolder,
      adviserRegistrationAfslNumber,
      adviserRegistrationAfslNumberPlaceholder,
      adviserRegistrationAfslNumberError,
      adviserRegistrationABNNumber,
      adviserRegistrationABNNumberPlaceholder,
      adviserRegistrationABNNumberError,
      adviserRegistrationIsABNCancelledError,
      adviserRegistrationAsicRepNo,
      adviserRegistrationAsicRepNoPlaceholder,
      adviserRegistrationAsicRepNoError,
      adviserRegistrationAfslName,
      adviserRegistrationAfslNamePlaceholder,
      adviserRegistrationAfslNameError,
      adviserRegistrationBusinessName,
      adviserRegistrationBusinessNamePlaceholder,
      adviserRegistrationBusinessNameError,
      adviserRegistrationAddressPlaceholder,
      adviserRegistrationPrimaryAddressTitle,
      adviserRegistrationPrimaryAddress,
      adviserRegistrationContactDetailTitle,
      adviserRegistrationMobileNumber,
      adviserRegistrationContactNumber,
      adviserRegistrationContactNumberPlaceholder,
      adviserRegistrationContactNumberError,
      adviserRegistrationSupportingDocumentTitle,
      adviserRegistrationEmail,
      adviserRegistrationEmailPlaceholder,
      adviserRegistrationEmailError,
      adviserRegistrationDeclarationsTitle,
      adviserRegistrationDeclarationsError,
      adviserRegistrationSubmit,
      adviserRegistrationExploreTitle,
      adviserRegistrationExploreDesc,
      adviserRegistrationDescription,
      adviserRegistrationReferenceNumber,
      adviserRegistrationExploreConfirm,
      adviserRegistrationDeclarationLabelYes,
      adviserRegistrationDeclarationLabelNo,
      adviserRegistrationCantFindAddressLabel,
      adviserRegistrationAddressRequiredErrorMessage,
      adviserRegistrationStreetPlaceholder,
      adviserRegistrationHouseNoPlaceholder,
      adviserRegistrationLocalityPlaceholder,
      adviserRegistrationStatePlaceholder,
      adviserRegistrationCountryPlaceholder,
      adviserRegistrationPostCodePlaceholder,
      adviserRegistrationManualToggleLabel,
      adviserRegistrationNoFileError,
      adviserRegistrationPhoneCode,
    } = reduceAuthorableFields(fields)
    const { adviserRegistrationacKnowledgementAcceptanceText } = fields
    const radioGroupForMlcDeclarations = [
      {
        key: DECLARATION_YES,
        text: adviserRegistrationDeclarationLabelYes,
        value: DECLARATION_YES,
      },
      {
        key: DECLARATION_NO,
        text: adviserRegistrationDeclarationLabelNo,
        value: DECLARATION_NO,
      },
    ]
    const isAbnLookupFetching = get(abnLookup, 'isLoading', false)
    const companyName = get(abnLookup, 'companyName', '')

    const selectContactNumberCode = {
      label: adviserRegistrationPhoneCode,
      value: contactNumberCode.value,
      name: ADVISER_REGISTRATION_CONTACT_NUMBER_CODE,
      id: ADVISER_REGISTRATION_CONTACT_NUMBER_CODE,
      changeHandler: this.handleChange,
    }
    const inputContactNumber = {
      htmlFor: ADVISER_REGISTRATION_CONTACT_NUMBER,
      name: ADVISER_REGISTRATION_CONTACT_NUMBER,
      placeholder: adviserRegistrationContactNumberPlaceholder,
      label:
        selectedPartyType === PARTY_TYPE_PERSON
          ? adviserRegistrationMobileNumber
          : adviserRegistrationContactNumber,
      changeHandler: this.handleChange,
      value: contactNumber.value,
      error: contactNumber.error.error,
      caption: contactNumber.error.error && adviserRegistrationContactNumberError,
    }
    const heroAdvisorRegistrationFields = {
      heading: get(fields, 'heading.value', ''),
      content: get(fields, 'content.value', ''),
    }

    const authorizedRepsLength =
      authorizedReps && authorizedReps.value ? authorizedReps.value.length : 0

    return (
      <Fragment>
        <Hero fields={heroAdvisorRegistrationFields} />
        {!isAdviserRegistrationSuccessful ? (
          <Wrap>
            <AdviserRegistrationContainer>
              <Heading size="4">{adviserRegistrationPersonalDetailTitle}</Heading>
              <AdviserRegistrationWrapper role="form">
                <Fieldset withoutFlex role="fieldset">
                  <Select
                    label={adviserRegistrationPartyType}
                    value={partyType.value}
                    placeholder={adviserRegistrationPartyTypePlaceholder}
                    name={PARTY_TYPE}
                    id={PARTY_TYPE}
                    changeHandler={this.handleChange}
                    options={schema.partyType.options}
                    error={partyType.error.error}
                    caption={partyType.error.error && adviserRegistrationPartyTypeError}
                  />
                </Fieldset>
                {selectedPartyType === PARTY_TYPE_PERSON && (
                  <Fragment>
                    <Fieldset role="fieldset">
                      <SelectTitle
                        withoutMargin
                        label={adviserRegistrationTitle}
                        placeholder={adviserRegistrationTitlePlaceholder}
                        name={TITLE}
                        id={TITLE}
                        changeHandler={this.handleChange}
                        options={schema.title.options}
                        value={title.value}
                        error={title.error.error}
                        caption={title.error.error && adviserRegistrationTitleError}
                      />
                      <FirstName
                        htmlFor={FIRST_NAME}
                        name={FIRST_NAME}
                        placeholder={adviserRegistrationFirstNamePlaceholder}
                        label={adviserRegistrationFirstName}
                        changeHandler={this.handleChange}
                        value={firstName.value}
                        error={firstName.error.error}
                        caption={firstName.error.error && adviserRegistrationFirstNameError}
                      />
                    </Fieldset>
                    <Fieldset role="fieldset">
                      <InputFullWidth
                        htmlFor={LAST_NAME}
                        name={LAST_NAME}
                        placeholder={adviserRegistrationLastNamePlaceholder}
                        label={adviserRegistrationLastName}
                        changeHandler={this.handleChange}
                        value={lastName.value}
                        error={lastName.error.error}
                        caption={lastName.error.error && adviserRegistrationLastNameError}
                      />
                    </Fieldset>
                    <Fieldset role="fieldset">
                      <DateOfBirth
                        withoutMargin
                        htmlFor={DATE_OF_BIRTH}
                        name={DATE_OF_BIRTH}
                        options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                        label={adviserRegistrationDateOfBirth}
                        changeHandler={this.handleChange}
                        placeholder={adviserRegistrationDateOfBirthPlaceHolder}
                        value={dateOfBirth.value}
                        error={dateOfBirth.error.error}
                        caption={dateOfBirth.error.error && adviserRegistrationDateOfBirthError}
                      />
                    </Fieldset>
                  </Fragment>
                )}
                {selectedPartyType === PARTY_TYPE_BUSINESS && (
                  <Fragment>
                    <Fieldset role="fieldset">
                      <AbnInputFullWidth
                        htmlFor={ABN_NUMBER}
                        name={ABN_NUMBER}
                        placeholder={adviserRegistrationABNNumberPlaceholder}
                        label={adviserRegistrationABNNumber}
                        changeHandler={this.handleChange}
                        value={abnNumber.value}
                        error={isAbnCancelled || abnNumber.error.error}
                        caption={
                          isAbnCancelled
                            ? adviserRegistrationIsABNCancelledError
                            : abnNumber.error.error && adviserRegistrationABNNumberError
                        }
                      />
                    </Fieldset>
                    <BusinessNameWrap>
                      <BusinessNameLabel>{adviserRegistrationBusinessName}</BusinessNameLabel>
                      {isAbnLookupFetching ? (
                        <Spinner spinnerSize={25} />
                      ) : (
                        companyName === '' &&
                        isNoBusinessName && (
                          <InputFullWidth
                            htmlFor={BUSINESS_NAME}
                            name={BUSINESS_NAME}
                            placeholder={adviserRegistrationBusinessNamePlaceholder}
                            changeHandler={this.handleChange}
                            value={businessName.value}
                            error={businessName.error.error}
                            caption={
                              businessName.error.error && adviserRegistrationBusinessNameError
                            }
                          />
                        )
                      )}
                      <BusinessName>{abnLookup && abnLookup.companyName}</BusinessName>
                    </BusinessNameWrap>
                  </Fragment>
                )}
                <Fieldset role="fieldset">
                  <InputFullWidth
                    htmlFor={ASIC_REP_NO}
                    name={ASIC_REP_NO}
                    label={adviserRegistrationAsicRepNo}
                    placeholder={adviserRegistrationAsicRepNoPlaceholder}
                    changeHandler={this.handleChange}
                    value={asicRepNo.value}
                    error={asicRepNo.error.error}
                    caption={asicRepNo.error.error && adviserRegistrationAsicRepNoError}
                  />
                </Fieldset>
                <Fieldset role="fieldset">
                  <InputFullWidth
                    htmlFor={AFSL_NAME}
                    name={AFSL_NAME}
                    label={adviserRegistrationAfslName}
                    placeholder={adviserRegistrationAfslNamePlaceholder}
                    changeHandler={this.handleChange}
                    value={afslName.value}
                    error={afslName.error.error}
                    caption={afslName.error.error && adviserRegistrationAfslNameError}
                  />
                </Fieldset>
                <Fieldset role="fieldset">
                  <InputFullWidth
                    htmlFor={AFSL_NUMBER}
                    name={AFSL_NUMBER}
                    label={adviserRegistrationAfslNumber}
                    placeholder={adviserRegistrationAfslNumberPlaceholder}
                    changeHandler={this.handleChange}
                    value={afslNumber.value}
                    error={afslNumber.error.error}
                    caption={afslNumber.error.error && adviserRegistrationAfslNumberError}
                  />
                </Fieldset>
                <Heading size="4">{adviserRegistrationPrimaryAddressTitle}</Heading>
                <Fieldset withoutFlex role="fieldset">
                  <AddressLookUp
                    toggleHandler={this.addressToggleHandler('isManualResidential')}
                    name="adviserRegistrationResidentialAddress"
                    auto={{
                      label: adviserRegistrationPrimaryAddress,
                      placeholder: adviserRegistrationAddressPlaceholder,
                      toggleLabel: adviserRegistrationCantFindAddressLabel,
                      value: residentialAddress.value,
                      selectChangeHandler: this.addressChange,
                      addressError: residentialAddress.error.error,
                      addressName: ADVISER_REGISTRATION_RESIDENTIAL_ADDRESS,
                      addressErrorMessage:
                        residentialAddress.error.error &&
                        adviserRegistrationAddressRequiredErrorMessage,
                    }}
                    manual={{
                      streetPlaceholder: adviserRegistrationStreetPlaceholder,
                      streetValue: residentialStreet.value,
                      streetRequiredErrorMessage: residentialStreet.error.errorMsg,
                      streetError: residentialStreet.error.error,
                      streetName: ADVISER_REGISTRATION_RESIDENTIAL_STREET,
                      houseNoPlaceholder: adviserRegistrationHouseNoPlaceholder,
                      houseNoValue: residentialHouseNo.value,
                      houseNoName: ADVISER_REGISTRATION_RESIDENTIAL_HOUSENO,
                      houseNoError: residentialHouseNo.error.error,
                      houseNoRequiredErrorMessage: residentialHouseNo.error.errorMsg,
                      localityPlaceholder: adviserRegistrationLocalityPlaceholder,
                      localityLabelValue: residentialLocality.value,
                      localityError: residentialLocality.error.error,
                      localityRequiredErrorMessage: residentialLocality.error.errorMsg,
                      localityName: ADVISER_REGISTRATION_RESIDENTIAL_LOCALITY,
                      statePlaceholder: adviserRegistrationStatePlaceholder,
                      stateOptions: schema.residentialState.options,
                      stateValue: residentialState.value,
                      stateError: residentialState.error.error,
                      stateRequiredErrorMessage: residentialState.error.errorMsg,
                      stateName: ADVISER_REGISTRATION_RESIDENTIAL_STATE,
                      countryPlaceholder: adviserRegistrationCountryPlaceholder,
                      countryValue: residentialCountry.value,
                      countryError: residentialCountry.error.error,
                      countryRequiredErrorMessage: residentialCountry.error.errorMsg,
                      countryName: ADVISER_REGISTRATION_RESIDENTIAL_COUNTRY,
                      postCodePlaceholder: adviserRegistrationPostCodePlaceholder,
                      postCodeValue: residentialPostCode.value,
                      postCodeError: residentialPostCode.error.error,
                      postCodeRequiredErrorMessage: residentialPostCode.error.errorMsg,
                      postCodeName: ADVISER_REGISTRATION_RESIDENTIAL_POSTCODE,
                      manualToggleLabel: adviserRegistrationManualToggleLabel,
                      inputEntryHandler: this.handleChange,
                    }}
                  />
                </Fieldset>
                <Heading size="4">{adviserRegistrationContactDetailTitle}</Heading>
                <Fieldset withoutFlex role="fieldset">
                  <InputPhone
                    selectPhoneCode={selectContactNumberCode}
                    inputPhoneNumber={inputContactNumber}
                  />
                  <Fieldset role="fieldset">
                    <InputFullWidth
                      htmlFor={EMAIL}
                      name={EMAIL}
                      placeholder={adviserRegistrationEmailPlaceholder}
                      label={adviserRegistrationEmail}
                      changeHandler={this.handleChange}
                      value={email.value}
                      error={email.error.error}
                      caption={email.error.error && adviserRegistrationEmailError}
                    />
                  </Fieldset>
                </Fieldset>
              </AdviserRegistrationWrapper>
              <Heading size="4">{adviserRegistrationSupportingDocumentTitle}</Heading>
              <UploadCertificateWrapper>
                <Dropzone
                  hasMultipleFiles={false}
                  handleFileDrop={this.handleFileDrop}
                  validateFiles={this.validateFiles}
                  isFileUploading={files.length}
                />
                {files && files.length ? this.renderFileList() : null}
              </UploadCertificateWrapper>
              {!isFileUploaded && (
                <Row>
                  <ExclaimationIcon />
                  <FileNotUploadError>{adviserRegistrationNoFileError}</FileNotUploadError>
                </Row>
              )}
              {selectedPartyType === PARTY_TYPE_BUSINESS && (
                <Fragment>
                  <Heading size="4">{AuthorisedRepresentativesSectionHeading}</Heading>
                  {this.renderAuthorizedReps()}
                  <Button
                    type={
                      authorizedRepsLength >= AUTHORIZED_REPRESENTATIVE_MAXIMUM_NUMBER
                        ? 'primary'
                        : 'secondary'
                    }
                    onClick={this.addAuthorizedRepToList}
                    disabled={authorizedRepsLength >= AUTHORIZED_REPRESENTATIVE_MAXIMUM_NUMBER}
                  >
                    {authorizedRepsLength >= 1
                      ? AuthorisedRepresentativesSectionAddAnotherButton
                      : AuthorisedRepresentativesSectionButton}
                  </Button>
                </Fragment>
              )}
              <AdviserRegistrationRadioGroupWrapper>
                <Heading size="4">{adviserRegistrationDeclarationsTitle}</Heading>
                <RadioGroupWrapper>
                  <Description>
                    {renderTextField(adviserRegistrationacKnowledgementAcceptanceText, true)}
                  </Description>
                  <RadioGroup
                    options={radioGroupForMlcDeclarations}
                    handleChange={value => this.handleDeclarations(TERMS_AND_CONDITION, value)}
                    selectedItem={termsAndCondition.value}
                    name={TERMS_AND_CONDITION}
                    error={termsAndCondition.error.error && adviserRegistrationDeclarationsError}
                  />
                </RadioGroupWrapper>
              </AdviserRegistrationRadioGroupWrapper>
              <ReCaptcha action="test">
                {({ execute }) => (
                  <Footer>
                    <Submit
                      type="secondary"
                      disabled={isLoading}
                      onClick={e => this.handleRecaptchaTokenGeneration(e, execute)}
                    >
                      {isLoading ? <Spinner spinnerSize={25} /> : adviserRegistrationSubmit}
                    </Submit>
                  </Footer>
                )}
              </ReCaptcha>
            </AdviserRegistrationContainer>
          </Wrap>
        ) : (
          <ScrollToTop>
            <Wrap>
              <ExploreWrapper>
                <ExploreMessageWrapper>
                  <SuccessfulIcon />
                  <ExploreHeading>{adviserRegistrationExploreTitle}</ExploreHeading>
                  <ExploreReferenceNumber>
                    {adviserRegistrationReferenceNumber}
                    {workItemReferenceNo}
                  </ExploreReferenceNumber>
                  <ExploreDescription>{adviserRegistrationExploreDesc}</ExploreDescription>
                  <ExploreDescriptionBold>{adviserRegistrationDescription}</ExploreDescriptionBold>
                  <ExploreButton type="primary" onClick={this.onConfirmation}>
                    {adviserRegistrationExploreConfirm}
                  </ExploreButton>
                </ExploreMessageWrapper>
              </ExploreWrapper>
            </Wrap>
          </ScrollToTop>
        )}
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  addressLookup,
  abnLookup,
  fileUploadInfo,
  adviserRegistration,
  adviserRegistration: { isAdviserRegistrationSuccessful, isLoading, workItemReferenceNo },
  config,
  masterList,
}) => {
  const form = forms[FORM_ID]
  return {
    form,
    authorizedReps: form?.fields?.authorizedReps,
    isManualPostal:
      addressLookup.adviserRegistrationResidentialAddress &&
      addressLookup.adviserRegistrationResidentialAddress.isManual,
    isManualResidential:
      addressLookup.adviserRegistrationDetailsPostalAddress &&
      addressLookup.adviserRegistrationDetailsPostalAddress.isManual,
    abnLookup,
    fileUploadInfo,
    adviserRegistration,
    isAdviserRegistrationSuccessful,
    workItemReferenceNo,
    isLoading,
    config,
    documentTypes: get(masterList, 'data.documentTypes', []),
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdviserRegistration)
