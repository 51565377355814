// types.
import { Dispatch } from 'redux'
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  COVERMANAGERDOCUMENTS_FETCH,
  COVERMANAGERDOCUMENTS_CLEAR,
} from '../types/coverManagerDocuments'

// Data Types
import { Document } from '../../types/coverManagerDocuments'
import { config as ConfigType } from '../../types/store'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const getCoverManagerDocuments =
  (
    data: { partyNumber: string; policyNumber: string; channel: string },
    callback: (
      error: boolean,
      businessData: { totalRecords: number; documents: Array<Document> }
    ) => void
  ) =>
  (dispatch: Dispatch, getState: () => { config: ConfigType }) => {
    const { config } = getState()
    return dispatch({
      type: EXPERIENCE_API,
      name: COVERMANAGERDOCUMENTS_FETCH,
      verb: 'POST',
      data,
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/documents/coverManager`,
      additionalHeaders: {
        'X-InitialRole': config.MLCL_UPLOAD_FILE_API_INITIAL_ROLE,
      },
      callback: (
        error: boolean,
        dataRecieved: { businessData: { totalRecords: number; documents: Array<Document> } }
      ) => {
        if (callback) {
          callback(error, dataRecieved.businessData)
        }
        return dataRecieved
      },
    })
  }

export const clearCoverManagerDocuments = () => ({
  type: COVERMANAGERDOCUMENTS_CLEAR,
})
