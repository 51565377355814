import React from 'react'

// redux.
import { useSelector } from 'react-redux'

// components.
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-react'
import ClientPolicies from './components/ClientPolicies'

// constants.
import { POLICY_STATE_INFORCE } from '../../../constants/policies'
import { Store } from '../../../types/store'
import { fieldsType } from '../../../types/components/AdvisorClientDetails'

type AdvisorClientDetailsProps = {
  // Sitecore authorable fields.
  fields: fieldsType
  // An object containing action creator functions.
  // file upload info
  rendering: ComponentRendering
}

const AdvisorClientDetails = (props: AdvisorClientDetailsProps) => {
  const { fields, rendering } = props
  const policies = useSelector((state: Store) => state.policies)

  const hasPoliciesInforce = (policies?.data ?? []).some(
    policy => policy?.policy?.status === POLICY_STATE_INFORCE
  )

  return (
    <ClientPolicies hasPoliciesInforce={hasPoliciesInforce} fields={fields} rendering={rendering} />
  )
}

export default AdvisorClientDetails
