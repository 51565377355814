// @flow
import React from 'react'

import BannerComponent from '../../../organisms/Banner'

type BannerProps = {
  // sitecore fields
  fields: Object,
  // rendering parameters from sitecore
  params: Object,
}
const Banner = ({ fields, params }: BannerProps) => (
  <BannerComponent fields={fields} params={params} />
)

export default Banner
