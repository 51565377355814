import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styles from './loginButton.styles'
import { SitecoreFields } from '../../welcomePage.types'
import { createEvent } from '../../../../../utils/telemetry'

const Caption = styled('div')(styles.caption)
const CaptionInfoOne = styled(Button)(styles.captionInfoOne)
const CaptionInfoTwo = styled('a')(styles.captionInfoTwo)

type Props = {
  handleLoadForgottenUsernameForm: () => void
  fields: SitecoreFields
}

const LoginButton = (props: Props) => {
  const {
    handleLoadForgottenUsernameForm,
    fields: { usernameTooltip, usernameTroubleShootLink },
  } = props

  const handleButtonClick = () => {
    const tagEvent = createEvent({
      GA: {
        category: 'Splash Screen',
        action: 'Splash Screen - Proceed to log in',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Splash Screen - Proceed to log in',
        },
      },
    })
    tagEvent.end()
    // @ts-expect-error will resolve this, not sure if attaching this to the window is a good idea
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.authClient.token.getWithRedirect({
      responseType: ['token'],
      state: `${window.location.search}${window.location.hash}` || undefined,
    })
  }

  const handleHavingTroubleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const tagEvent = createEvent({
      GA: {
        category: 'Splash Screen',
        action: 'Splash Screen - Trouble logging in',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Splash Screen - Trouble logging in',
        },
      },
    })
    tagEvent.end()
    window.open(usernameTroubleShootLink?.value?.href, '_blank')
  }

  return (
    <>
      <Caption>
        <CaptionInfoOne onClick={handleLoadForgottenUsernameForm} variant="tertiary" size="xsmall">
          {usernameTooltip?.value}
        </CaptionInfoOne>
        <CaptionInfoTwo
          onClick={handleHavingTroubleLinkClick}
          target="_blank"
          rel="noopener noreferrer"
          href={usernameTroubleShootLink?.value?.href}
        >
          {usernameTroubleShootLink?.value?.text}
        </CaptionInfoTwo>
      </Caption>
      <Button type="button" variant="primary" onClick={handleButtonClick}>
        <Text field={props.fields.submitButtonText} />
      </Button>
    </>
  )
}

export default LoginButton
