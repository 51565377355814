// @flow
import React from 'react'
import styled from '@emotion/styled'

// components.
import { IconChevronDown16 } from '../../../../../atoms/Icons'
import Button from '../../../../../atoms/Button'

// styles.
import styles from './quoteItem.styles'

const QuoteItemContent = styled('div')(styles.QuoteItemContent)
const QuoteItemLabel = styled('span')(styles.quoteConfigLabel)
const AccordionOpenIcon = styled(IconChevronDown16)(styles.quoteSettingsIcon)
const AccordionButton = styled(Button)(styles.QuoteButton)

type QuoteItemProps = {
  quoteEntityName: string,
  // Quote Index
  quoteIndex: number,
  // Update Active index by Quote Index
  updateActiveIndex: Function,
}

const QuoteItem = (props: QuoteItemProps) => {
  const { quoteEntityName, updateActiveIndex, quoteIndex } = props
  const dataLocator = quoteEntityName.replace(/ /g, '_').toLowerCase()

  return (
    <QuoteItemContent data-locator={`${dataLocator}_collapsed`}>
      <AccordionButton data-locator={dataLocator} onClick={() => updateActiveIndex(quoteIndex)}>
        <QuoteItemLabel>{quoteEntityName}</QuoteItemLabel>
        <AccordionOpenIcon />
      </AccordionButton>
    </QuoteItemContent>
  )
}

export default QuoteItem
