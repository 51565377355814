import {
  space,
  iconWidth,
  fontSize,
  fontWeights,
  colours,
  mediaQueries,
} from '../../../../../styles'

const styles = {
  arrow: {
    color: 'inherit',
    position: 'absolute',
    top: `calc(50% - ${iconWidth.sm * 2})`,
    right: space(4),
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
  },
  description: {
    margin: 0,
    marginTop: space(1),
    color: 'inherit',
    fontSize: fontSize.xs,
  },
  disabled: {
    color: colours.lightestGrey,
    borderColor: colours.lightestGrey,
  },
  active: {
    display: 'block',
  },
  icon: {
    float: 'right',
    svg: {
      color: colours.black,
    },
    [mediaQueries.md]: {
      display: 'none',
    },
  },
  tabPanel: {
    width: '100%',
    [mediaQueries.md]: {
      position: 'absolute',
      display: 'inline-flex',
      flexDirection: 'column',
      width: '70%',
      left: '25%',
      top: '0',
    },
    '&.active': {
      display: 'block',
      [mediaQueries.md]: {
        borderTopWidth: 0,
      },
    },
  },
  title: {
    h6: {
      display: 'block',
      fontWeight: fontWeights.bold,
    },
  },
}

export default styles
