import { space, colours, fontSize, fontWeights } from '../../../../../styles'
import { SOURCE_SANS_PRO } from '../../../../../constants/fonts'

const style = {
  contactDetailsCard: {
    fontFamily: SOURCE_SANS_PRO,
    backgroundColor: 'white',
    marginTop: space(8, true),
    paddingTop: space(8, true),
    paddingRight: space(7, true),
    paddingBottom: space(8, true),
    paddingLeft: space(7, true),
  },
  contactDetailsHeader: {
    fontStyle: 'normal',
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
  },
  editDetails: {
    color: colours.mediumGreen,
    cursor: 'pointer',
    paddingRight: space(1, true),
    paddingLeft: space(1, true),
  },
  contactDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapper: {
    width: '45%',
    color: colours.mediumDarkGrey,
  },
  keyValueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  key: {
    width: '30%',
    fontStyle: 'normal',
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    marginTop: space(4, true),
    opacity: '0.4',
  },
  value: {
    width: '65%',
    fontStyle: 'normal',
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    marginTop: space(4, true),
  },
}

export default style
