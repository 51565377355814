import { space, colours, fontWeights } from '../../../../../styles'

const styles = {
  articleCardWrapper: {
    background: colours.white,
    flexGrow: 1,
    padding: `${space(6, true)} ${space(6.25, true)} ${space(10, true)} ${space(4, true)}`,
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  articleTitleWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileIcon: {
    color: colours.lightGreen,
  },
  articleTitle: {
    width: '100%',
    fontSize: space(3),
    fontWeight: fontWeights.semiBold,
    color: colours.lightBlack,
    marginLeft: space(2.5),
  },
  articleBriefDescription: {
    fontSize: space(2),
    fontWeight: fontWeights.normal,
    color: colours.lightBlack,
    marginTop: space(4),
    opacity: 0.8,
  },
  articleFooter: {
    padding: `${space(2, true)} 0`,
    background: colours.lightBlue,
    cursor: 'pointer',
  },
  articleFooterText: {
    fontSize: space(2.5),
    textAlign: 'center',
    fontWeight: fontWeights.semiBold,
    color: colours.mediumGreen,
  },
}

export default styles
