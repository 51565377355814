// @flow
import { toast } from 'react-toastify'
// types
import { EXPERIENCE_API } from '../types/experienceApi'
import { SITECORE_API } from '../types/sitecoreApi'
import {
  DATA_FEED_REGISTRATION,
  DATA_FEED_CANCELLATION,
  DATA_FEED_REGISTRATION_TOAST_ID,
  DATA_FEED_TERMS_AND_CONDITIONS,
} from '../types/dataFeedRegistration'

// constants
import {
  EXPERIENCE_API_BASE,
  EXPERIENCE_API_VERSION_1,
  SITECORE_API_BASE,
} from '../../constants/apis'

export const dataFeedRegistration =
  (data?: Object, callback?: Function = () => {}) =>
  (dispatch: Function) => {
    dispatch({
      type: EXPERIENCE_API,
      name: DATA_FEED_REGISTRATION,
      verb: 'POST',
      data: {
        ...data,
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/advisers/subscription`,
      callback: (err, dataReceived) => {
        if (err) {
          toast('Error in Data Fetching', {
            toastId: DATA_FEED_REGISTRATION_TOAST_ID,
            type: toast.TYPE.ERROR,
          })
        }
        callback(err, dataReceived)
        return dataReceived
      },
    })
  }

export const dataFeedRegistrationCancellation =
  (data?: Object, callback?: Function = () => {}) =>
  (dispatch: Function) => {
    dispatch({
      type: EXPERIENCE_API,
      name: DATA_FEED_CANCELLATION,
      verb: 'POST',
      data: {
        ...data,
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/advisers/subscription`,
      callback: (err, dataReceived) => {
        callback(err, dataReceived)
        return dataReceived
      },
    })
  }

export const dataFeedAcceptTermsAndConditions =
  (requestPayload: Object) => (dispatch: Function) => {
    dispatch({
      type: SITECORE_API,
      name: DATA_FEED_TERMS_AND_CONDITIONS,
      verb: 'POST',
      route: `${SITECORE_API_BASE}/advisertermsandconditions`,
      data: {
        ...requestPayload,
        actionType: 'POST',
      },
    })
  }
