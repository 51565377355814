// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// components.

// utils
import { getBancsCustomerNumber, getAdviserNo } from '../../../../../utils/cookieUtils'

// molecules
import TasksListComponent from '../../../../molecules/TasksList'
// styles.
import styles from './newBusiness.styles'

const Wrap = styled('div')(styles.base)

export class NewBusiness extends Component {
  componentWillMount() {
    const {
      actions: { getOutstandingRequirement, resetNavigationType, resetFooterType, getMasterList },
    } = this.props
    getOutstandingRequirement({
      bancsAdviserCustomerNo: [getAdviserNo() || getBancsCustomerNumber()],
    })
    getMasterList()
    resetNavigationType()
    resetFooterType()
  }

  render() {
    const { fields } = this.props
    const {
      taskDashboardOutstandingRequirementModalHeading,
      taskDashboardOutstandingRequirementModalConfirmButton,
      taskDashboardOutstandingRequirementModalSubHeading,
      taskDashboardOutstandingRequirementMaxFileSizeError,
      taskDashboardOutstandingRequirementMaxFileSize,
      taskDashboardOutstandingRequirementMaxFileCount,
      taskDashboardOutstandingRequirementWrongMimeType,
      taskDashboardOutstandingRequirementUploading,
      taskDashboardOutstandingRequirementUploadSuccess,
      taskDashboardOutstandingRequirementUploadFailure,
      taskDashboardOutstandingRequirementFileRemoveLabel,
      taskDashboardOutstandingRequirementFormWorkItemFailure,
      taskDashboardOutstandingRequirementFormWorkItemSuccess,
      taskDashboardOutstandingRequirementNoFileError,
      taskDashboardOutstandingRequirementWrongFileNameError,
      taskDashboardActionButtonText,
    } = fields

    const fieldsForTaskList = {
      modalHeading: taskDashboardOutstandingRequirementModalHeading,
      modalConfirmButton: taskDashboardOutstandingRequirementModalConfirmButton,
      modalSubHeading: taskDashboardOutstandingRequirementModalSubHeading,
      maxFileSizeError: taskDashboardOutstandingRequirementMaxFileSizeError,
      maxFileSize: taskDashboardOutstandingRequirementMaxFileSize,
      maxFileNumber: taskDashboardOutstandingRequirementMaxFileCount,
      wrongFileType: taskDashboardOutstandingRequirementWrongMimeType,
      fileUploading: taskDashboardOutstandingRequirementUploading,
      fileUploadSuccess: taskDashboardOutstandingRequirementUploadSuccess,
      fileUploadFailure: taskDashboardOutstandingRequirementUploadFailure,
      fileRemoveButtonLabel: taskDashboardOutstandingRequirementFileRemoveLabel,
      createWorkItemError: taskDashboardOutstandingRequirementFormWorkItemFailure,
      createWorkItemSuccess: taskDashboardOutstandingRequirementFormWorkItemSuccess,
      noFileUploadedError: taskDashboardOutstandingRequirementNoFileError,
      wrongFileNameError: taskDashboardOutstandingRequirementWrongFileNameError,
      actionButtonText: taskDashboardActionButtonText,
      freeTextPlaceholder: 'Type your response here',
    }
    return (
      <Wrap>
        <TasksListComponent fields={fieldsForTaskList} />
      </Wrap>
    )
  }
}

NewBusiness.propTypes = {
  // Sitecore authorable fields.
  fields: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  // An object containing action creator functions.
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
}

const mapStateToProps = ({ taskDashboard: { taskDashboard, isFreeTextFetching }, masterList }) => ({
  taskDashboard,
  isFreeTextFetching,
  masterList,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewBusiness)
