import React, { useCallback, useEffect, useState } from 'react'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { CSVLink } from 'react-csv'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import { PageWrap, Heading, Loader } from '@mlcl-digital/mlcl-design'
import Button from '@mlcl-digital/mlcl-design/lib/base/Button'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import LinkWithIcon from '@mlcl-digital/mlcl-design/lib/base/LinkWithIcon'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Placeholder, ComponentRendering } from '@sitecore-jss/sitecore-jss-react'

// styles
import styles from './clientPolicies.styles'

// selectors
import {
  getClientName,
  getIsClientDetailsLoading,
  getIsPoliciesLoading,
  getAllOwnersInPolicies,
} from '../../../../../selectors/clientPolicies'
import { getMasterData, getConfig } from '../../../../../selectors/common.selectors'

// FIXME: ts-expect-error comment can be removed when files are converted to typescript
// components
// @ts-expect-error file not in typescript
import WithLoader from '../../../../molecules/WithLoader'
import PolicyTabs from '../PolicyTabs'

// utils
// @ts-expect-error file not in typescript
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../../utils/telemetry'
import {
  hasPolicyOwnerSuperFund,
  getPoliciesCodesForReporting,
  getBenefitCodesForReporting,
  getExternalPolicyNumbers,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/policyUtils'
import {
  maxBenefitItem,
  getClientReportHeader,
  getFormatClientReportData,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/reportingUtils'
// @ts-expect-error non-ts code
import history from '../../../../../utils/browserHistory'

// @ts-expect-error file not in typescript
import { transformToSelectOptions } from '../../../../../utils/commonUtils'
// @ts-expect-error file not in typescript
import { isFeatureEnabled } from '../../../../../utils/featureToggling'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'

// types
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'
import { Relationship } from '../../../../../types/ClientPolicies'
import { ClientReportPolicy } from '../../../../../types/clientReporting'
import { Store } from '../../../../../types/store'

// constants
import {
  SEARCH_TYPE_POLICY_OWNER,
  SEARCH_TYPE_LIFE_INSURED,
} from '../../../../../constants/reporting'
import { CSV_FILE_EXTENSION } from '../../../../../constants/documentTypes'
import {
  TOAST_ID_DOWNLOAD_POLICIES_REPORT,
  TOAST_DOWNLOAD_POLICIES_ERROR_REPORT,
  TOAST_DOWNLOAD_POLICIES_NO_RECORD,
  TOAST_AUTOCLOSE_DELAY,
} from '../../../../../constants/toast'

type clientPoliciesProps = {
  fields: fieldsType
  hasPoliciesInforce: boolean
  location: { search: string }
  rendering: ComponentRendering
} & RouteComponentProps

type ProductType = {
  value: string
}

type policyOwnerDetailsProps = {
  fields: fieldsType
  rendering: ComponentRendering
  data: Relationship
  policyNumberList: string[]
  index?: number
}

const Wrap = styled(PageWrap)(styles.wrap)
const HeadingButtonsWrapper = styled('div')(styles.headingButtonsWrapper)
const StyledButton = styled(Button)(styles.button)
const StyledHeading = styled(Heading)(styles.heading)
const ScrollToTopBtnWrap = styled('div')(styles.scrollToTopBtnWrap)
const ScrollToTopBtn = styled(Button)(styles.scrollToTopBtn)
const BackBtnText = styled('span')(styles.backBtnText)
const BackNavigationLink = styled(LinkWithIcon)(styles.backCTA, styles.backNavigationLink)
const BackToPolicyCTA = styled(Button)(styles.backCTA)
const BackToPolicyText = styled('span')(styles.backToPolicyText)
const PolicyOwnerWrap = styled('div')(styles.policyOwnerWrap)
const ButtonContainer = styled('div')(styles.buttonContainer)
const DownloadCSVIcon = styled(Icon)(styles.downloadCSVIcon)
const DownloadCSVLink = styled(StyledButton)(styles.downloadCSVLink)
const Spinner = styled(Loader)(styles.spinner)

const PolicyOwnerDetails = ({
  fields,
  rendering,
  data,
  policyNumberList,
  index,
}: policyOwnerDetailsProps) => (
  <PolicyOwnerWrap>
    <HeadingButtonsWrapper>
      <StyledHeading variant="h1" size="large">
        {renderTextField(fields.PolicyOwnerDetailsHeading)} {index || ''}
      </StyledHeading>
    </HeadingButtonsWrapper>
    {rendering?.placeholders?.['personal-and-contact-details'] && (
      <Placeholder
        name="personal-and-contact-details"
        rendering={rendering}
        data={data}
        policyNumberList={policyNumberList}
      />
    )}
  </PolicyOwnerWrap>
)

const ClientPolicies = ({
  fields,
  location: { search: searchParams },
  hasPoliciesInforce,
  rendering,
  location,
}: clientPoliciesProps) => {
  const [isPolicyOwnerView, setIsPolicyOwnerView] = useState(false)
  const [reportDownloadData, setReportDownloadData] = useState<ClientReportPolicy[]>([])
  const dispatch = useDispatch()

  const csvLinkRef = React.useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  const clientName = useSelector(getClientName)
  const isClientDetailsLoading: boolean = useSelector(getIsClientDetailsLoading)
  const isPoliciesLoading: boolean = useSelector(getIsPoliciesLoading)
  const existingClient = useSelector((state: Store) => state.client.existingClient)
  const clientDetails = useSelector((state: Store) => state.client.details)
  const advisorDetails = useSelector((state: Store) => state.advisor.advisorDetails)
  const clientReportLoading = useSelector((state: Store) => state.clientReport.isLoading)
  const policies = useSelector((state: Store) => state.policies)
  const policyOwners = useSelector(getAllOwnersInPolicies)
  const masterData = useSelector(getMasterData)

  useEffect(() => {
    const { fromEmailClient } = (location?.state as { fromEmailClient: boolean } | undefined) ?? {}
    if (fromEmailClient) {
      setIsPolicyOwnerView(true)
    }
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const { clientId, isSMSF, fromSearch, policyId } = queryString.parse(searchParams) as {
      clientId: string
      isSMSF: string
      fromSearch: string
      policyId: string
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.getClientDetails(clientId || existingClient))
    // set default navigation & footer
    dispatch(actionCreators.resetNavigationType())
    dispatch(actionCreators.resetFooterType())
    // reset cover manager documents list
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.clearCoverManagerDocuments())
    dispatch(actionCreators.resetAlterations())
    if (clientId && !fromSearch) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.getPolicies(clientId, isSMSF))
    }
    if (fromSearch) {
      let path = `/clients/details?clientId=${clientId}&isSMSF=${isSMSF}`
      if (policyId) {
        path = `${path}&policyId=${policyId}`
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      history.replace(path)
    }
  }, [])

  useEffect(() => {
    if (reportDownloadData.length) {
      csvLinkRef?.current?.link.click()
      const event = createEvent({
        GA: {
          category: 'Download client report',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Download client report',
          },
        },
      })
      event.end()
      setReportDownloadData([])
    }
  }, [reportDownloadData])

  const handleChangeBenefitClick = useCallback(() => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.openSidebar(
        'requestManualQuote',
        undefined,
        undefined,
        undefined,
        undefined,
        'ChangeCoverSidebar'
      )
    )
  }, [])
  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  const handleOwnerDetailsClick = useCallback(() => {
    const event = createEvent({
      GA: {
        category: 'Client details Editing contact details - client info tab',
        action: 'Client info View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Client info View',
        },
      },
    })
    event.end()
    setIsPolicyOwnerView(true)
  }, [setIsPolicyOwnerView])
  const handleGoBackToPolicy = useCallback(() => {
    setIsPolicyOwnerView(false)
  }, [setIsPolicyOwnerView])

  const handleDownload = () => {
    const productOptions =
      masterData.adviserReportingProductOptions &&
      transformToSelectOptions(masterData.adviserReportingProductOptions)
    const adviserCode = advisorDetails ? advisorDetails.map(item => item.agencyCode) : []
    const isClientCompany = !!clientDetails.businessName
    const clientFirstName = isClientCompany ? '' : clientDetails.firstName
    const clientLastName = isClientCompany ? '' : clientDetails.lastName
    const params = {
      adviserCode,
      searchBy: hasPolicyOwnerSuperFund(policies)
        ? SEARCH_TYPE_LIFE_INSURED
        : SEARCH_TYPE_POLICY_OWNER,
      insuranceType: productOptions.map((product: ProductType) => product.value),
      firstName: clientFirstName,
      lastName: clientLastName,
      companyName: isClientCompany ? clientName : '',
      policyStatus:
        masterData.policyStatus && getPoliciesCodesForReporting(masterData.policyStatus, true),
      benefitStatus:
        masterData.policyStatus && getBenefitCodesForReporting(masterData.policyStatus, true),
    }
    const externalPolicyNumbers = getExternalPolicyNumbers(policies) as string[]
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.downloadPolicies(params, (err: string, response: ClientReportPolicy[]) => {
        if (err) {
          setReportDownloadData([])
          toast(TOAST_DOWNLOAD_POLICIES_ERROR_REPORT, {
            autoClose: TOAST_AUTOCLOSE_DELAY,
            toastId: TOAST_ID_DOWNLOAD_POLICIES_REPORT,
            type: toast.TYPE.ERROR,
          })
        } else if (response && response.length) {
          const filteredResponseData = [...response].filter(policyData =>
            externalPolicyNumbers.includes(policyData.policyNo)
          )
          setReportDownloadData(filteredResponseData)
        } else {
          setReportDownloadData([])
          toast(TOAST_DOWNLOAD_POLICIES_NO_RECORD, {
            autoClose: TOAST_AUTOCLOSE_DELAY,
            toastId: TOAST_ID_DOWNLOAD_POLICIES_REPORT,
            type: toast.TYPE.ERROR,
          })
        }
      })
    )
  }

  const handleDownloadButtonClick = (
    data: ClientReportPolicy[],
    done: (...args: boolean[]) => void
  ) => {
    if (data.length) {
      done()
    } else {
      done(false)
      handleDownload()
    }
  }

  const csvHeadersForDownload =
    reportDownloadData && reportDownloadData.length
      ? getClientReportHeader(maxBenefitItem(reportDownloadData), reduceAuthorableFields(fields))
      : []

  const config = useSelector(getConfig)
  const isCaliRelabel = isFeatureEnabled('CaliRelabel', config?.FEATURES)

  return (
    <Wrap>
      {!isPolicyOwnerView ? (
        <>
          <BackNavigationLink
            href={get(fields, 'BackLink.value.href', '#') as string}
            content={get(fields, 'BackLink.value.text', '') as string}
          />
          <WithLoader
            isLoading={isClientDetailsLoading || isPoliciesLoading}
            loaderProps={{ type: 'tab' }}
          >
            <HeadingButtonsWrapper>
              <StyledHeading variant="h1" size="large">
                {clientName}
              </StyledHeading>
              <ButtonContainer>
                {hasPoliciesInforce && (
                  <CSVLink
                    ref={csvLinkRef}
                    asyncOnClick
                    headers={csvHeadersForDownload}
                    data={getFormatClientReportData(reportDownloadData, isCaliRelabel)}
                    onClick={(event, done) => handleDownloadButtonClick(reportDownloadData, done)}
                    filename={`${
                      fields?.ReportingDownloadFilename?.value || ''
                    }${CSV_FILE_EXTENSION}`}
                  >
                    <DownloadCSVLink variant="secondaryWithTheme">
                      {clientReportLoading ? (
                        <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
                      ) : (
                        <>
                          <DownloadCSVIcon iconName={['far', 'download']} />
                        </>
                      )}
                      {renderTextField(fields.ClientReport)}
                    </DownloadCSVLink>
                  </CSVLink>
                )}
                <StyledButton variant="secondaryWithTheme" onClick={handleOwnerDetailsClick}>
                  {renderTextField(fields.PolicyOwnerDetails)}
                </StyledButton>
                <StyledButton
                  data-testid="client-details-change-cover-action"
                  onClick={handleChangeBenefitClick}
                >
                  {renderTextField(fields.ChangeBenefit)}
                </StyledButton>
              </ButtonContainer>
            </HeadingButtonsWrapper>
            <PolicyTabs fields={fields} />
            <ScrollToTopBtnWrap>
              <ScrollToTopBtn variant="secondaryWithTheme" onClick={handleScrollToTop}>
                <BackBtnText>{renderTextField(fields.BackToTheTop)}</BackBtnText>
                <Icon iconName="chevron-up" />
              </ScrollToTopBtn>
            </ScrollToTopBtnWrap>
          </WithLoader>
        </>
      ) : (
        <>
          <BackToPolicyCTA variant="linkUnderlineOnHover" onClick={handleGoBackToPolicy}>
            <Icon iconName={['far', 'chevron-left']} />
            <BackToPolicyText>{renderTextField(fields.GoBackToPolicy)}</BackToPolicyText>
          </BackToPolicyCTA>
          {policyOwners.length > 1 ? (
            policyOwners.map((policyOwner, index: number) => (
              <PolicyOwnerDetails
                fields={fields}
                rendering={rendering}
                data={policyOwner.relationship}
                policyNumberList={policyOwner.policyNumberList}
                index={index + 1}
              />
            ))
          ) : (
            <PolicyOwnerDetails
              fields={fields}
              rendering={rendering}
              data={get(policyOwners, '[0].relationship') as Relationship}
              policyNumberList={get(policyOwners, '[0].policyNumberList') as string[]}
            />
          )}
        </>
      )}
    </Wrap>
  )
}

export default withRouter(ClientPolicies)
