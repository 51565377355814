import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, mediaQueries } = Variables

const styles = {
  title: {
    marginRight: `calc(50% + ${space(1, true)})`,
    width: '100%',
    [mediaQueries.md]: {
      width: space(56, true),
    },
  },
  input: {
    width: '100%',
    [mediaQueries.md]: {
      width: space(56, true),
    },
  },
  inputPhone: {
    [mediaQueries.md]: {
      width: space(56, true),
    },
    input: {
      width: '100%',
    },
  },
  spinner: {
    minHeight: 0,
  },
  postalAddressCheckbox: {
    marginBottom: space(3),
  },
  styledSubmitButton: {
    marginRight: space(2),
  },
  firstFormElementStyle: {
    height: '1px',
    background: colours.tertiaryThree,
    marginBottom: space(3),
  },
  styleOverrides: {
    marginBottom: space(0.8),
  },
}

export default styles
