// @flow
import React from 'react'

import DeclarationComponent from '../../../organisms/SupportStaffDeclaration'

type DeclarationProps = {
  fields: Object,
}

const SupportStaffDeclaration = ({ fields }: DeclarationProps) =>
  fields ? <DeclarationComponent fields={fields} /> : null

export default SupportStaffDeclaration
