// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'

// styles.
import styles from './panel.styles'

const PanelRoot = styled('aside')(styles.panelRoot)

type PanelProps = {
  children: Node,
}

const Panel = ({ children }: PanelProps) => <PanelRoot>{children}</PanelRoot>

export default Panel
