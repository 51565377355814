import { Variables } from '@mlcl-digital/mlcl-design'

type variables = {
  colours: { tertiaryFive: string; mediumGreen: string; lightestGrey: string }
  fontSize: { xs: string }
  space: (size: number | string) => string
  mediaQueries: {
    md: string
  }
  mediaQueriesMax: {
    sm: string
  }
}
const { colours, space, mediaQueriesMax, mediaQueries } = Variables as variables

export default {
  container: {
    [mediaQueries.md]: {
      borderTop: `1px solid ${colours.lightestGrey}`,
    },
  },
  tableFooter: {
    backgroundColor: colours.tertiaryFive,
    [mediaQueriesMax.sm]: {
      justifyContent: 'center',
    },
    display: 'flex',
  },
  filterContainer: {
    marginTop: space(2.5),
    ' .mlc__value-container': {
      height: '34px',
    },
    '> div': {
      '> div:first-of-type': {
        [mediaQueries.md]: {
          width: space(40),
        },
      },
    },
  },
  cell: {
    '&:nth-child(2)': {
      flex: 2,
    },
  },
  stateContainer: {
    marginTop: space(4),
  },
}
