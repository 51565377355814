// @flow
import { colours, fontWeights, space } from '../../../styles'

const styles = {
  radioSection: {
    display: 'inline-flex',
    marginBottom: space(2),
  },
  errorMessage: {
    color: colours.red,

    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: space(1) / 4,
      fontWeight: fontWeights.bold,
    },
  },
  radioElement: {
    paddingRight: space(4),
  },
}

export default styles
