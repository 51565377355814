/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import HeadingWithIcon from '@mlcl-digital/mlcl-design/lib/base/HeadingWithIcon'
import Table from '@mlcl-digital/mlcl-design/lib/base/Table'
import ActionLink from '@mlcl-digital/mlcl-design/lib/base/ActionLink'
import FilterBar from '@mlcl-digital/mlcl-design/lib/base/FilterBar'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'
import { ErrorState } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import moment from 'moment'
import get from 'lodash/get'
// @ts-ignore
import FileUpload from '../../../../molecules/FileUpload'

// Redux
import { getPoliciesPaymentMeta, getFileUploadOpen } from '../../../../../selectors/clientPayment'
import { getFileUploadDetailsMeta } from '../../../../../selectors/clientPolicies'
// @ts-ignore
import { actionCreators } from '../../../../../actions'
// @ts-ignore
import { getPaymentDetails } from '../../../../../selectors/customerPaymentDetails'

// Types
import { ClientPolicy } from '../../../../../types/ClientPolicies'
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'
import { Document as DocumentEntity } from '../../../../../types/Document'

// Constants
import {
  CREDIT_CARD_ID,
  DIRECT_DEBIT_ID,
  BPAY,
  CHEQUE,
  MLC_ACC,
  ROLL_OVER_ID,
} from '../../../../../constants/policies'

// Utils
// @ts-ignore
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
// @ts-ignore
import { getPaymentCollectionMethod } from '../../../../../utils/paymentMethods'
import { createEvent } from '../../../../../utils/telemetry'

import styles from './payment.styles'

const StyledContainer = styled(Container)(styles.container)
const FilterBarContainer = styled('div')(styles.filterContainer)
const StateContainer = styled('div')(styles.stateContainer)

type props = {
  policyData: ClientPolicy
  fields: fieldsType
}

const columns = ({
  TransactionDate = { value: '' },
  TransactionMethod = { value: '' },
  AmountPaid = { value: '' },
}: fieldsType) => [
  {
    Header: TransactionDate.value,
    accessor: 'date',
  },
  {
    Header: TransactionMethod.value,
    accessor: 'paymentMethod',
    disableSortBy: true,
  },
  {
    Header: AmountPaid.value,
    accessor: 'amount',
    disableSortBy: true,
  },
]

type PaymentDetails = Array<string>

const Payment = ({ policyData, fields }: props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'View policy details tab',
        action: 'Payment details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'View Payment',
        },
      },
    })
    event.end()
  }, [])

  const {
    bancsPolicyNo,
    policy: { premiumPaymentMethod, productId },
  } = policyData

  const COLUMNS = columns(fields)

  const [dateRange, setDateRange] = useState<{ to: Date | null; from: Date | null }>({
    to: null,
    from: null,
  })

  const policyPaymentMetaData = useSelector(getPoliciesPaymentMeta(bancsPolicyNo))
  const fileUploadOpen = useSelector(getFileUploadOpen)
  const fileUploadMetadata = useSelector(state =>
    getFileUploadDetailsMeta(state, { fields, type: 'payment', productId })
  )
  const {
    history,
    hasFetchError,
    bPayPayInReference,
    details,
    isPolicyOutOfForce,
    isIOOForNavigator,
  } = policyPaymentMetaData

  const {
    AccountName,
    CardNumber,
    ExpiryDate,
    AccountNumber,
    BSBNumber,
    FundUSI,
    FundName,
    FundABN,
    FundProductName,
    FundMembershipNumber,
    FundAccountNumber,
    MasterKeyAccountNumber,
  } = fields

  const paymenDetailsLabelMap = {
    [CREDIT_CARD_ID]: [AccountName, CardNumber, ExpiryDate],
    [DIRECT_DEBIT_ID]: [AccountName, AccountNumber, BSBNumber],
    [BPAY]: () => [],
    [CHEQUE]: () => [],
    [ROLL_OVER_ID]: [FundUSI, FundName, FundABN, FundProductName, FundMembershipNumber],
    [MLC_ACC]: isIOOForNavigator ? [FundAccountNumber] : [MasterKeyAccountNumber],
  }
  const paymentDetailsFields = getPaymentDetails(details) as PaymentDetails

  const shouldShowBpay = Boolean(!isIOOForNavigator && bPayPayInReference && !isPolicyOutOfForce)

  const [recordsToShow, setShowingRecords] = useState<
    Array<{
      date: string
      paymentMethod: string
      amount: string
    }>
  >(history)

  const handleFileUploadModal = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.toggleFileUploadModal(true))
    const event = createEvent({
      GA: {
        category: 'Editing payment details',
        action: 'Edit using document upload',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Editing payment details using document upload',
        },
      },
    })
    event.end()
  }

  const renderFileUpload = () => {
    const {
      FileUploadModalConfirm,
      FileUploadMaxFileCount,
      FileUploadMaxFileSize,
      FileUploadMaxFileSizeError,
      FileUploadWrongMimeType,
      FileUploadUploading,
      FileUploadSuccess,
      FileUploadFailure,
      FileUploadWorkItemFailure,
      FileUploadWorkItemSuccess,
      FileUploadFileRemove,
      FileUploadNoFileError,
      FileUploadWrongFileNameError,
    } = reduceAuthorableFields(fields)

    const analyticsForDownload = {
      category: 'Customer selects edit payment details Download',
      action: 'Select',
    }

    const analytics = {
      category: 'Editing payment details',
      action: 'AP - confirm payment detail updates',
    }

    const {
      modalHeading,
      modalSubHeading,
      documentName,
      documentPath,
      docSubTypeCode,
      workType,
    }: DocumentEntity = fileUploadMetadata

    return (
      <FileUpload
        data-testid="policy-details-upload-modal"
        formDownloadMeta={{
          documentName,
          documentPath,
          docSubTypeCode,
        }}
        modalMeta={{
          modalHeading,
          modalSubHeading,
          modalConfirmButton: FileUploadModalConfirm as string,
        }}
        dropzoneMeta={{
          maxFileNumber: FileUploadMaxFileCount as number,
          maxFileSize: FileUploadMaxFileSize as number,
        }}
        fileUploadMeta={{
          maxFileSizeError: FileUploadMaxFileSizeError as string,
          wrongFileType: FileUploadWrongMimeType as string,
          fileUploading: FileUploadUploading as string,
          fileUploadSuccess: FileUploadSuccess as string,
          fileUploadFailure: FileUploadFailure as string,
          createWorkItemError: FileUploadWorkItemFailure as string,
          createWorkItemSuccess: FileUploadWorkItemSuccess as string,
          fileRemoveButtonLabel: FileUploadFileRemove as string,
          noFileUploadedError: FileUploadNoFileError as string,
          wrongFileNameError: FileUploadWrongFileNameError as string,
        }}
        createWorkItemRequest={{
          workTypeCode: workType,
          bancsPolicyNo,
        }}
        uploadDocumentRequest={{
          docSubTypeCode,
          workType,
        }}
        analytics={analytics}
        analyticsForDownload={analyticsForDownload}
      />
    )
  }

  useEffect(() => {
    let filteredRecords = history
    if (dateRange.to || dateRange.from) {
      const event = createEvent({
        GA: {
          category: 'Filter payment transaction',
          action: 'View',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Filter payment transaction',
          },
        },
      })
      event.end()
      const dateString = 'DD MMM YYYY'
      if (dateRange.to && dateRange.from) {
        filteredRecords = filteredRecords.filter(
          item =>
            moment(item.date, dateString).isBetween(dateRange.from, dateRange.to) ||
            moment(item.date, dateString).isSame(dateRange.from) ||
            moment(item.date, dateString).isSame(dateRange.to)
        )
      } else if (dateRange.from) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.date, dateString).isSameOrAfter(dateRange.from)
        )
      } else if (dateRange.to) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.date, dateString).isSameOrBefore(dateRange.to)
        )
      }
    }
    setShowingRecords(filteredRecords)
  }, [dateRange, bancsPolicyNo])

  return (
    <>
      {!isPolicyOutOfForce && (
        <StyledContainer>
          <HeadingWithIcon
            iconName={['far', 'credit-card-front']}
            headingContent={get(fields.PaymentDetails, 'value', '')}
          />
          <GridContainer>
            <LabelValuePair
              label={renderTextField(fields.PaymentMethod)}
              value={getPaymentCollectionMethod(premiumPaymentMethod, details) as string}
            />
            {paymentDetailsFields.map((value: string, index: number) => (
              <LabelValuePair
                label={
                  get(paymenDetailsLabelMap, `${premiumPaymentMethod}[${index}].value`) as string
                }
                value={value}
              />
            ))}
          </GridContainer>
          <ActionLink
            onClick={() => handleFileUploadModal()}
            label={renderTextField(fields.EditPaymentDetails)}
          />
        </StyledContainer>
      )}
      {shouldShowBpay && (
        <StyledContainer>
          <HeadingWithIcon
            iconName={['far', 'credit-card-front']}
            headingContent={get(fields, 'BPAYDetails.value', '') as string}
          />
          <GridContainer>
            <LabelValuePair
              label={renderTextField(fields.BPAYBillerCode)}
              value={get(fields, 'BPAYBillerCodeValue.value', '') as string}
            />
            <LabelValuePair
              label={renderTextField(fields.BPAYReferenceNumber)}
              value={bPayPayInReference}
            />
          </GridContainer>
        </StyledContainer>
      )}
      <StyledContainer>
        <HeadingWithIcon
          iconName={['far', 'square-list']}
          headingContent={get(fields, 'PaymentActivity.value', '') as string}
        />
        {history.length === 0 && !hasFetchError && (
          <StateContainer>
            <ErrorState
              emptyState
              showBackground={false}
              message={renderTextField(fields.NoTransactionMessage)}
            />
          </StateContainer>
        )}
        {hasFetchError && (
          <StateContainer>
            <ErrorState showBackground={false} />
          </StateContainer>
        )}
        {history.length > 0 && (
          <>
            <FilterBarContainer>
              <FilterBar onDateRange={e => setDateRange(e)} showClearAll />
            </FilterBarContainer>
            <Table
              pageSize={20}
              data={recordsToShow}
              columns={COLUMNS}
              styleOverrides={{
                cell: styles.cell,
                footer: styles.tableFooter,
                headerCell: styles.cell,
              }}
            />
          </>
        )}
        {fileUploadOpen && renderFileUpload()}
      </StyledContainer>
    </>
  )
}

export default Payment
