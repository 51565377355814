// @flow
import { quoteName, required } from '../../../../../../../../utils/formUtils'

export const FORM_ID = 'editQuoteName'
const duplicateCheck =
  (collection = [], activeIndex) =>
  name => {
    const isDuplicate = collection.filter(
      (quote, index) => index !== activeIndex && quote.quoteName === name
    )

    return !!isDuplicate.length
  }

// this form schema requires fields be combined with it before it can be retrieved.
const getEditQuoteNameSchema = (fields, collection = [], activeIndex) => ({
  quoteName: {
    condition: [
      required,
      {
        condition: quoteName,
        errorMsg: fields.QuoteEntityQuoteNameInvalidErrorMsg.value,
      },
      {
        condition: duplicateCheck(collection, activeIndex),
        errorMsg: fields.QuoteEntityQuoteNameDuplicationErrorMsg.value,
      },
    ],
    errorMsg: fields.QuoteEntityQuoteNameInvalidErrorMsg.value,
    tooltip: '',
  },
})

export default getEditQuoteNameSchema
