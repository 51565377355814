// @flow
import { space, colours } from '../../../styles'

const styles = {
  image: {
    height: space(8),
    marginBottom: space(6),
  },
  heading: {
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    marginBottom: space(6),
    a: {
      textDecoration: 'none',
      color: colours.mediumGreen,
    },
  },
  header: {
    textAlign: 'center',
    marginBottom: space(2),
  },
  nextBtnWrapper: {
    textAlign: 'center',
    paddingBottom: space(6),
  },
}

export default styles
