import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space } = Variables
const base = {
  color: colours.tertiaryOne,
}
const styles = {
  wrapper: {
    textAlign: 'center',
  },
  detail: isAdvisorPortal => ({
    ...base,
    ...(!isAdvisorPortal && { marginBottom: space(3, true) }),
  }),
  value: {
    ...base,
    fontWeight: 600,
    marginBottom: space(4),
  },
  help: {
    ...base,
    marginBottom: space(4),
    '& a': {
      textDecoration: 'none',
      color: colours.mlcAquaAccessible,
    },
  },
}

export default styles
