import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from '@mlcl-digital/mlcl-design'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import { getIsGenerateQuoteModalVisible } from '../../../selectors/alterations'
import { actionCreators } from '../../../actions'
import { ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL } from '../../../constants/modal'
import {
  getConfig,
  getCreateQuote,
  makeAltsLifeInsuredNameAndPartyNo,
} from '../../../selectors/common.selectors'
import { downloadDocument } from '../../../utils/downloadDocumentUtils'
import { DOC_TYPE_ALTERATION_QUOTE } from '../../../constants/documentTypes'
import { generateCorrelationID } from '../../../utils/commonUtils'

const AltsGenerateQuoteTryAgainModal = ({ fields }) => {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(actionCreators.hideModal(ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL))

  const isModalVisible = useSelector(getIsGenerateQuoteModalVisible)
  const { activeIndex, quotes } = useSelector(getCreateQuote)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const config = useSelector(getConfig)

  const onCTAClick = () => {
    closeModal()
    dispatch(actionCreators.updateDownloadQuoteProgress(true))
    downloadDocument(
      {
        docType: DOC_TYPE_ALTERATION_QUOTE,
        quote: quotes[activeIndex],
        correlationID: generateCorrelationID(),
        lifeInsuredFirstName: firstName,
        lifeInsuredLastName: lastName,
      },
      null,
      null,
      config,
      error => {
        dispatch(actionCreators.updateDownloadQuoteProgress(false))
        if (error) dispatch(actionCreators.showModal(ALTS_GENERATE_QUOTE_TRY_AGAIN_MODAL))
      }
    )
  }

  return (
    <Modal
      isOpen={isModalVisible}
      onClose={closeModal}
      title={renderTextField(fields.ModalTitle)}
      footer={
        <Button type="primary" onClick={onCTAClick}>
          {renderTextField(fields.ModalCTA)}
        </Button>
      }
    >
      <p>{renderTextField(fields.ModalDesc, true)}</p>
    </Modal>
  )
}

export default AltsGenerateQuoteTryAgainModal

AltsGenerateQuoteTryAgainModal.propTypes = {
  fields: PropTypes.shape({
    ModalDesc: PropTypes.shape({ value: PropTypes.string }),
    ModalCTA: PropTypes.shape({ value: PropTypes.string }),
    ModalTitle: PropTypes.shape({ value: PropTypes.string }),
  }).isRequired,
}
