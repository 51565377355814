import { colours, space, mediaQueries } from '../../../styles'

const styles = {
  bar: (active, percentage) => ({
    borderBottom: `8px solid ${colours.mediumGrey}`,
    color: colours.mediumGrey,
    flexBasis: '100%',
    paddingBottom: space(2),
    fontWeight: 'bold',
    ...(active && {
      color: colours.lightGreen,
      borderColor: colours.lightGreen,
    }),
    display: active ? 'block' : 'none',
    [mediaQueries.md]: {
      display: 'block',
      flexBasis: percentage,
    },
  }),
}
export default styles
