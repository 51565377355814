import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../types/ComponentLibrary'

const { space, colours, mediaQueries, fontSize, fontFamily } = Variables as VariablesType

const styles = {
  wrapper: {
    color: colours.tertiaryOne,
    marginTop: space(6),
    fontSize: fontSize.xsm,
  },
  quoteSection: {
    width: '70%',
    background: '#F5FCFC',
    padding: space(5),
    marginBottom: space(5),
    h4: {
      fontFamily: fontFamily.sourceSans,
    },
  },
  aCTACheckbox: {
    display: 'block',
    label: {
      fontSize: fontSize.xsm,
    },
    [mediaQueries.md]: {
      display: 'inline-flex',
    },
  },
  submitButton: {
    marginTop: space(3),
    width: '100%',
  },
}

export default styles
