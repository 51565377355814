import { space, colours, fontWeights } from '../../../../../../../styles'

const styles = {
  benefitWrapper: {
    padding: `${space(3, true)} ${space(4, true)}`,
    borderBottom: `1px solid ${colours.lightestGrey}`,
    '&:nth-child(even)': {
      background: colours.offWhite,
      borderBottom: 'none',
    },
  },
  quoteSummaryPolicyTableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: colours.black,
  },
  sgboRow: {
    marginTop: space(2),
    color: colours.darkGrey,
  },
  quoteSummaryPolicyBenefitNameWrapper: ({ sgboBenefit }) => ({
    width: '35%',
    fontWeight: sgboBenefit ? fontWeights.light : fontWeights.semiBold,
  }),

  quoteSummaryPolicyPaymentStruct: {
    width: '25%',
  },

  quoteSummaryPolicySumInsured: {
    width: '25%',
  },

  quoteSummaryPolicyPremium: {
    width: '15%',
    textAlign: 'right',
  },

  quoteSummaryLinkedTag: {
    verticalAlign: 'text-bottom',
    margin: `0 ${space(0.5, true)}`,
  },
}

export default styles
