// @flow
import React, { Component, Fragment, type Node } from 'react'
import styled from '@emotion/styled'

// components
import Heading from '../../atoms/Heading'
import Tab from './components/Tab'
import Button from '../../atoms/Button'
import { IconLoading16 } from '../../atoms/Icons'

import styles from './verticalTabs.styles'

type VerticalTabsProps = {
  // text for tab list heading
  title: string,
  // content display for tab list and tab content
  children: Node,
  // button for handling additonal features eg. restore default behaviour
  handleClick: Function,
  // reset state
  resetChildState: Function,
}

type VerticalTabsState = {
  // state that holds the active tab
  activeTab: string,
}

const VerticalTabsContainer = styled('div')(styles.verticaltabs)
const RestoreDefault = styled('div')(styles.restoreDefault)
const ListHeading = styled('li')(styles.listHeading)

class VerticalTabs extends Component<VerticalTabsProps, VerticalTabsState> {
  constructor(props) {
    super(props)
    const { children } = this.props
    this.state = {
      // check whethe the content
      activeTab: Array.isArray(children[0]) ? children[0][0].props.id : children[0].props.id,
    }

    this.onTabClick = this.onTabClick.bind(this)
  }

  onTabClick = tab => {
    const { resetChildState } = this.props
    this.setState({ activeTab: tab })
    resetChildState()
  }

  render() {
    const { children, title, restoreLink, handleClick, isPreferenceRestoreLoading } = this.props
    const { activeTab } = this.state
    const TabList = props => {
      const { id, label, children: tabChildren } = props
      return (
        <Fragment>
          <Tab
            id={id}
            activeTab={activeTab}
            label={label}
            onClick={this.onTabClick}
            active={id === activeTab ? 'active' : ''}
          >
            {id === activeTab && tabChildren}
          </Tab>
        </Fragment>
      )
    }

    return (
      <VerticalTabsContainer>
        {
          <ul>
            {children.map((child, index) => {
              if (Array.isArray(child)) {
                return (
                  <Fragment key={child[index].props.id}>
                    {
                      <ListHeading>
                        <Heading size="6">{child[index].props.title}</Heading>
                      </ListHeading>
                    }
                    {child.map(node => node && <TabList {...node.props} key={node.props.id} />)}
                  </Fragment>
                )
              }
              return (
                <Fragment>
                  <Heading size="6">{title}</Heading>
                  <TabList {...child.props} key={child.props.id} />
                </Fragment>
              )
            })}
          </ul>
        }
        {restoreLink && (
          <RestoreDefault>
            <div>
              <IconLoading16 />
              <Button type="tertiary" onClick={handleClick} isLoading={isPreferenceRestoreLoading}>
                {restoreLink}
              </Button>
            </div>
          </RestoreDefault>
        )}
      </VerticalTabsContainer>
    )
  }
}

export default VerticalTabs
