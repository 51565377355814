// @flow
import React from 'react'
import axios from 'axios'
import { Textarea } from '@mlcl-digital/mlcl-design'

// components.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from '../../../atoms/Button'
import Heading from '../../../atoms/Heading'

// redux.
import { actionCreators } from '../../../../actions'

type ThirdPartyTestProps = {
  config: Object,
}

class ThirdPartyTest extends React.Component<ThirdPartyTestProps> {
  constructor(props) {
    super(props)
    this.state = {
      payload: '',
      htmlResp: '',
    }

    this.myRefDiv = React.createRef()
  }

  componentDidUpdate() {
    const { payload } = this.state
    if (this.myRefDiv.current && this.myRefDiv.current.innerHTML) {
      if (this.myRefDiv.current.children && payload !== '') {
        this.myRefDiv.current.children[0].submit()
      }
    }
  }

  handleThirdParty = () => {
    const { payload } = this.state
    const { config } = this.props

    return axios
      .post(`${config.MLCL_NODE_API}/quote-apply`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(res => this.setState({ htmlResp: res.data }))
      .catch(err => {
        console.error('ERROR IS', err)
      })
  }

  handleChange = value => this.setState({ payload: value })

  handleBlur = event => {
    const { value } = event.target
    this.setState({ payload: value })
  }

  render() {
    const { payload, htmlResp } = this.state
    return (
      <div style={{ margin: 200 }}>
        <Heading size="2">Third Party Tool Test</Heading>
        <Textarea
          name="Payload"
          label="Payload"
          placeholder="Enter your payload"
          changeHandler={this.handleChange}
          value={payload}
          blurHandler={this.handleBlur}
        />
        <Button onClick={this.handleThirdParty}>SUBMIT</Button>
        {/* @FIXME: need to determine if this HTML is sanitized */}
        {/* eslint-disable-next-line react/no-danger */}
        <div ref={this.myRefDiv} dangerouslySetInnerHTML={{ __html: htmlResp }} />
      </div>
    )
  }
}

const mapStateToProps = ({ config }) => ({
  config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyTest)
