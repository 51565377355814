// @flow
import { space } from '../../../../../styles'

const styles = {
  renumToggleGroup: {
    '& label': {
      marginBottom: space(1),
    },
  },
}

export default styles
