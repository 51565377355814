// @flow
import React from 'react'
import Review from '../../../organisms/ApplicationReview'

type ApplicationProps = {
  fields: Object<Object>,
}

const Application = ({ fields }: ApplicationProps) => <Review fields={fields} />

export default Application
