import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours } = Variables

const styles = {
  modalDescription: {
    color: colours.black,
    marginBottom: space(4, true),
  },
}

export default styles
