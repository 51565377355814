// @flow
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'

type ScriptProps = {
  src: string,
  includeInHeader?: boolean,
  loadedCallback?: Function,
  scriptId?: string,
  isAsync?: boolean,
}

class Script extends Component<ScriptProps> {
  constructor(props) {
    super(props)

    this.injectScripts = this.injectScripts.bind(this)
  }

  componentDidMount() {
    const { scriptId } = this.props
    const isScriptExist = document.querySelector(`script[id=${scriptId}]`)

    if (!isScriptExist) this.injectScripts()
  }

  getScriptTag() {
    const { src, isAsync, scriptId, loadedCallback } = this.props

    const script = document.createElement('script')
    script.type = 'text/javascript'

    if (scriptId) {
      script.id = scriptId
    }

    if (loadedCallback) {
      script.onload = () => loadedCallback()
    }

    if (isAsync) {
      script.async = isAsync
    }

    script.src = src
    return script
  }

  placeElementInPage() {
    const { includeInHeader } = this.props
    if (includeInHeader) {
      document.head.appendChild(this.scriptElem)
    } else {
      document.body.appendChild(this.scriptElem)
    }
  }

  injectScripts() {
    this.scriptElem = this.getScriptTag()
    this.placeElementInPage()
  }

  render() {
    return null
  }
}

Script.defaultProps = {
  includeInHeader: false,
  loadedCallback: null,
  scriptId: '',
  isAsync: true,
}

export default Script
