import { mediaQueries, fontSize, fontWeights, colours, space } from '../../../../../styles'
import { THEME_LIGHT, THEME_GREY } from '../../../../../constants/themes'

const getHeadingColor = theme => {
  if (theme === THEME_LIGHT) return colours.darkGreen
  if (theme === THEME_GREY) return colours.tertiaryOne
  return colours.white
}

const styles = {
  wrapper: {
    flex: '0 0 100%',

    [mediaQueries.md]: {
      flexBasis: '70%',
    },
  },
  subHeading: {
    marginBottom: space(1),
    fontWeight: 'bold',
    [mediaQueries.xs]: {
      fontSize: fontSize.xs,
    },
    [mediaQueries.sm]: {
      fontSize: fontSize.sm,
    },
    [mediaQueries.md]: {
      fontSize: fontSize.sm,
    },
  },
  heading(theme) {
    return {
      color: getHeadingColor(theme),
      marginBottom: space(2),
      fontWeight: fontWeights.semiBold,
      [mediaQueries.xs]: {
        fontSize: fontSize.lg,
      },
      [mediaQueries.sm]: {
        fontSize: fontSize.xl,
      },
      [mediaQueries.md]: {
        fontSize: fontSize.xl,
      },
    }
  },
  headingSubComponentWrapper: {
    display: 'block',

    '& > h1': {
      marginBottom: space(1, true),
    },

    [mediaQueries.sm]: {
      display: 'flex',
      alignItems: 'center',

      '& > h1': {
        marginBottom: 'auto',
      },
    },
  },
  subComponent: {
    [mediaQueries.sm]: {
      marginLeft: space(1, true),
    },
  },
}

export default styles
