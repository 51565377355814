// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'

// redux
import { connect } from 'react-redux'

// styles
import styles from './applicationStatus.styles'
import ProgressBar from '../../../../molecules/ProgressBar'

// components
import { PENDING, PROCESSING, COMPLETE } from '../../../../../constants/application'

// utils
import { getProcessingStatusFromAction } from '../../../../../utils/commonUtils'

const ProgressContainer = styled('div')(styles.progress)
const ProgressWrapper = styled('div')(styles.progressWrap)

type ApplicationStatusPropTypes = {
  // Sitecore fields
  fields: Object,
  // quote collection data
  quoteData: {
    quoteCollectionId: string,
    applicationStage: string,
  },
}

export class ApplicationStatus extends Component<ApplicationStatusPropTypes> {
  getProcessingStatus = () => {
    const {
      quoteData: { action, applicationStage },
    } = this.props
    return getProcessingStatusFromAction(action, applicationStage)
  }

  render() {
    const { fields, quoteData } = this.props
    const { applicationPending, processingApplication, applicationComplete } = fields
    const applicationProcessingStatus = this.getProcessingStatus()
    const { isNonStandard } = quoteData
    return (
      <ProgressWrapper>
        <ProgressContainer>
          <ProgressBar
            displayText={applicationPending}
            active={
              applicationProcessingStatus === PENDING || applicationProcessingStatus === PROCESSING
            }
            percentage="40%"
          />
          <ProgressBar
            displayText={processingApplication}
            active={
              (applicationProcessingStatus === PROCESSING ||
                applicationProcessingStatus === COMPLETE) &&
              !isNonStandard
            }
            percentage="35%"
          />
          <ProgressBar
            displayText={applicationComplete}
            active={applicationProcessingStatus === COMPLETE}
            percentage="25%"
          />
        </ProgressContainer>
      </ProgressWrapper>
    )
  }
}
export const mapStateToProps = ({ createQuote, businessData, applicationSummary }, ownProps) => ({
  quoteData: ownProps.fromApplicationSummary ? applicationSummary : createQuote,
  businessData,
})

export default connect(mapStateToProps)(ApplicationStatus)
