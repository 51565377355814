import React from 'react'
import { Heading } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import get from 'lodash/get'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'

// components
import Company from './Company'
import Trustee from './Trustee'

// types
import { propTypes } from '../../../../types/components/PersonalAndContactDetails'

// styles
import styles from '../personalAndContactDetails.styles'

const SectionHeading = styled(Heading)(styles.sectionHeading)

const PartyAsPerson = (props: propTypes) => {
  const { fields, data } = props
  const level1 = data?.level1
  return (
    <>
      <SectionHeading variant="h2" size="small">
        {renderTextField(fields.SmsfDetailsTitle)}
      </SectionHeading>
      <Company {...props} isSMSF />

      {level1?.map((trustee, index: number) => {
        const titleField =
          trustee.relatedParty.partyType === 'PER'
            ? 'IndividualTrusteeTitle'
            : 'CorporateTrusteeTitle'
        return (
          <>
            <Heading variant="h3" size="small">
              {`${get(fields, `${titleField}.value`, '') as string} ${
                level1.length > 1 ? index + 1 : ''
              }`}
            </Heading>
            <Trustee {...props} data={trustee} />
          </>
        )
      })}
    </>
  )
}

export default PartyAsPerson
