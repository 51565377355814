// @flow
import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_REQUEST_PROGRESS,
  FILE_UPLOAD_REQUEST_FAILURE,
  FILE_UPLOAD_REQUEST_SUCCESS,
  FILE_UPLOAD_REQUEST_ERROR,
  FILE_REMOVE_REQUEST,
  FILE_BROWSE_INIT,
  FILE_BROWSE_INIT_SUCCESS,
  FILE_UPLOAD_CREATE_WORK_ITEM_INIT,
  FILE_UPLOAD_CREATE_WORK_ITEM_SUCCESS,
  FILE_UPLOAD_CREATE_WORK_ITEM_FAILURE,
  FILE_UPLOAD_CREATE_WORK_ITEM_ERROR,
  FILE_UPLOAD_MODAL_TOGGLE,
  FILE_UPLOAD_MODAL_RESET_DATA,
  UPLOADED_DOCUMENT_LIST_SUCCESS,
  UPLOADED_DOCUMENT_LIST_FAILURE,
  UPLOADED_DOCUMENT_LIST_ERROR,
  SAVE_UPLOADED_DOCUMENTS,
  SHOW_TASK_UPLOAD_CONFIRMATION_MODAL,
} from '../actions/types/fileUpload'

import { updateFileList, checkUploadedFilesError } from '../utils/fileUpload'

export const initialState = {
  fileUploadError: false,
  files: [],
  workItemInfo: [],
  workItemError: false,
  isModalOpen: false,
  disableConfirmButton: false,
  fileListUploaded: false,
  isLoading: false,
  isTaskUploadConfirmationModal: false,
}

const fileUploadInfo = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST: {
      const { files } = state
      const { fileId } = action.payload
      const updatedFileList = updateFileList(files, fileId, action.payload)

      return {
        ...state,
        files: updatedFileList,
        disableConfirmButton: true,
        isModalOpen: true,
      }
    }

    case FILE_UPLOAD_REQUEST_PROGRESS: {
      const { files } = state
      const { fileId } = action.payload
      const updatedFileList = updateFileList(files, fileId, action.payload)

      return {
        ...state,
        files: updatedFileList,
        disableConfirmButton: true,
        isModalOpen: true,
      }
    }

    case FILE_UPLOAD_REQUEST_SUCCESS: {
      const { files } = state
      const { fileId } = action.payload
      const updatedFileList = updateFileList(files, fileId, action.payload)
      const filesUploaded = checkUploadedFilesError(updatedFileList)
      const checkUploadedSuc = updatedFileList.filter(
        file => file.loading === 100 && file.uploading === false
      )

      return {
        ...state,
        files: updatedFileList,
        disableConfirmButton: !filesUploaded.length,
        isModalOpen: true,
        fileListUploaded: checkUploadedSuc.length === files.length,
      }
    }
    case FILE_UPLOAD_REQUEST_ERROR:
    case FILE_UPLOAD_REQUEST_FAILURE: {
      const { files } = state
      const { fileId } = action.payload
      const updatedFileList = updateFileList(files, fileId, action.payload)

      return {
        ...state,
        files: updatedFileList,
        fileUploadError: true,
        isModalOpen: true,
        disableConfirmButton: true,
      }
    }

    case FILE_REMOVE_REQUEST: {
      const { files } = action.payload
      return {
        ...state,
        files,
      }
    }

    case FILE_BROWSE_INIT: {
      return {
        ...state,
        fileUploadError: false,
      }
    }

    case FILE_BROWSE_INIT_SUCCESS: {
      const { files } = action.payload

      return {
        ...state,
        files: [...state.files, ...files],
        disableConfirmButton: false,
      }
    }

    case FILE_UPLOAD_CREATE_WORK_ITEM_INIT: {
      return {
        ...state,
        isLoading: true,
        disableConfirmButton: true,
      }
    }

    case FILE_UPLOAD_CREATE_WORK_ITEM_SUCCESS: {
      const { dataReceived, requestData } = action.payload
      const updateWorkType = state.workItemInfo.filter(
        workItem => workItem.workTypeCode !== requestData.workTypeCode
      )

      return {
        ...state,
        isLoading: false,
        workItemInfo: updateWorkType
          ? [
              ...state.workItemInfo,
              { ...dataReceived.businessData, workTypeCode: requestData.workTypeCode },
            ]
          : state.workItemInfo,
        disableConfirmButton: false,
      }
    }
    case FILE_UPLOAD_CREATE_WORK_ITEM_ERROR:
    case FILE_UPLOAD_CREATE_WORK_ITEM_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        workItemError: error,
        disableConfirmButton: true,
        isLoading: false,
      }
    }

    case FILE_UPLOAD_MODAL_TOGGLE: {
      const { showModal } = action.payload
      return {
        ...state,
        isModalOpen: showModal,
      }
    }

    case FILE_UPLOAD_MODAL_RESET_DATA: {
      return {
        ...state,
        files: [],
        fileUploadError: false,
        isModalOpen: false,
        disableConfirmButton: false,
        fileListUploaded: false,
        workItemError: false,
      }
    }
    case UPLOADED_DOCUMENT_LIST_SUCCESS: {
      const { dataReceived } = action.payload
      return {
        ...state,
        fileMetaData: {
          data: dataReceived,
          error: false,
        },
      }
    }
    case UPLOADED_DOCUMENT_LIST_FAILURE:
    case UPLOADED_DOCUMENT_LIST_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        fileMetaData: {
          data: [],
          error,
        },
      }
    }
    case SAVE_UPLOADED_DOCUMENTS: {
      const { quoteCollectionId, uploadedDocument } = action.payload
      const updatedState = { ...state }
      if (updatedState[quoteCollectionId]) {
        updatedState[quoteCollectionId] = [...updatedState[quoteCollectionId], uploadedDocument]
      } else {
        updatedState[quoteCollectionId] = [uploadedDocument]
      }

      return updatedState
    }

    case SHOW_TASK_UPLOAD_CONFIRMATION_MODAL: {
      const { isTaskUploadConfirmationModal } = action.payload
      return {
        ...state,
        isTaskUploadConfirmationModal,
      }
    }

    default: {
      return state
    }
  }
}

export default fileUploadInfo
