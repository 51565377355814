// helpers.
import { TITLES } from '../../../../../../constants/forms'
import { fieldsType } from '../../../../../../types/components/SupportStaffRegisterForm'
import {
  datePast,
  isNotFormattedDate,
  date,
  email,
  firstNameLastName,
  optional,
  phone,
  required,
  maxLength,
  mobilePhone,
  regexTest,
  phoneInputs,
  getFieldValueFromForm,
  // @ts-expect-error file not in typescript
} from '../../../../../../utils/formUtils'
import { DEFAULT_PHONE_CODE } from '../../../../../../constants/contactDetails'

export const FORM_ID = 'supportStaffRegisterForm'
export type SchemaNameType = keyof ReturnType<typeof schema>

const schema = (fields: fieldsType) => ({
  title: {
    condition: required,
    options: TITLES,
  },
  firstName: {
    condition: [
      {
        condition: required,
        errorMsg: fields.supportStaffRegisterFirstNameRequiredError,
      },
      {
        condition: firstNameLastName,
        errorMsg: fields.supportStaffRegisterFirstNameInvalidError,
      },
      {
        condition: maxLength(50),
        errorMsg: fields.supportStaffRegisterFirstNameLimitError,
      },
    ],
  },
  lastName: {
    condition: [
      {
        condition: required,
        errorMsg: fields.supportStaffRegisterLastNameRequiredError,
      },
      {
        condition: firstNameLastName,
        errorMsg: fields.supportStaffRegisterLastNameInvalidError,
      },
      {
        condition: maxLength(50),
        errorMsg: fields.supportStaffRegisterLastNameLimitError,
      },
    ],
  },
  dateOfBirth: {
    condition: [
      { condition: required, errorMsg: fields.supportStaffRegisterDateOfBirthRequiredError },
      {
        condition: isNotFormattedDate,
        errorMsg: fields && fields.supportStaffRegisterDateOfBirthInvalidError,
      },
      { condition: date, errorMsg: fields.supportStaffRegisterDateOfBirthInvalidError },
      {
        condition: datePast,
        errorMsg: fields.supportStaffRegisterDateOfBirthFutureDateError,
      },
    ],
  },
  email: {
    condition: [
      {
        condition: required,
        errorMsg: fields.supportStaffRegisterEmailRequiredError,
      },
      {
        condition: email,
        errorMsg: fields.supportStaffRegisterEmailInvalidError,
      },
    ],
  },
  contactNumber: {
    condition: [
      { condition: required, errorMsg: fields.supportStaffRegisterContactNumberRequiredError },
      {
        condition: (value: string, formFields: { [key: string]: { value: string } }) => {
          const phoneCodeValue = getFieldValueFromForm('contactNumberPhoneCode', {
            fields: formFields,
          }) as string
          const phoneRegex = phoneCodeValue === DEFAULT_PHONE_CODE ? mobilePhone : phone
          return !regexTest(phoneRegex, value)
        },
        errorMsg: fields.supportStaffRegisterContactNumberError,
      },
    ],
    onChangeCondition: phoneInputs,
    errorMsg: fields.supportStaffRegisterContactNumberError,
  },
  contactNumberPhoneCode: {
    condition: optional,
  },
  isSupportStaffCheckboxSelected: {
    condition: false,
  },
  loginId: {
    condition: optional,
  },
})

export default schema
