// @flow
import {
  space,
  colours,
  mediaQueries,
  shadows,
  setColumns,
  fontSize,
  fontWeights,
} from '../../../styles'

export default {
  body: (theme: { bg: string }) => ({
    position: 'absolute',
    bottom: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
    background: colours.white,
    [mediaQueries.md]: {
      paddingTop: '5%',
      background: [
        theme.bg,
        `linear-gradient(transparent
        60%, ${theme.bg} 40%)`,
      ],
    },
  }),
  main: {
    padding: space(3),
    position: 'relative',
    zIndex: 1,
    maxWidth: 600,
    ...setColumns(1),
    margin: '0 auto',
    boxShadow: 'none',
    flexDirection: 'column',
    [mediaQueries.md]: {
      boxShadow: shadows.mid,
    },
  },
  header: {
    textAlign: 'center',
    marginTop: space(6),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 400,
  },
  logo: {
    marginTop: space(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: space(7),
  },
  description: {
    marginBottom: space(5),
    fontSize: fontSize.xxs,
    color: colours.black,
  },
  form: {
    maxWidth: 300,
    margin: '0 auto',
  },
  footer: {
    textAlign: 'center',
    flex: 1,
    width: '100%',
    marginBottom: space(7),
  },
  formSubmitButton: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 400,
  },
  formHeader: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 400,
    '& > h2': {
      fontWeight: fontWeights.semiBold,
    },
  },
  spinner: {
    minHeight: 0,
  },
}
