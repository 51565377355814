// @flow
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Modal, Heading } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import { createEvent } from '../../../../../utils/telemetry'
import styles from './altsDecreaseLoadingExclusionModal.styles'
import { renderTextField, replaceLabelPlaceholders } from '../../../../../utils/sitecoreUtils'
import { LOADING_TYPE_EMR, LOADING_TYPE_RPM } from '../../../../../constants/benefit'
import {
  LIST_BULLET,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
} from '../../../../../constants/alterations'

const LinkButton = styled(Button)(styles.linkButton)
const ModalFooter = styled('div')(styles.modalFooter)
const List = styled('li')(styles.list)
const ListDesc = styled('div')(styles.listDesc)

function getHeaderTitleData({
  benefitName,
  benefitCommencementDate,
  fields,
  loading,
  exclusion,
  isNewPremium,
}) {
  const {
    ViewLoadingsCTALabel,
    ViewExclusionsCTALabel,
    ViewLoadingsExclusionsCTALabel,
    LoadingsModalHeader,
    ExclusionsModalHeader,
    LoadingsExclusionsModalHeader,
  } = fields

  const modalData = {
    buttonTextValue: '',
    titleTextValue: '',
    gaAction: '',
  }
  const titleData = {
    benefitName,
    benefitCommencementDate: moment(benefitCommencementDate, 'YYYY-MM-DD').format('Do MMMM YYYY'),
  }
  if (loading.length && exclusion.length) {
    modalData.buttonTextValue = ViewLoadingsExclusionsCTALabel
    modalData.titleTextValue = replaceLabelPlaceholders(LoadingsExclusionsModalHeader, titleData)
    modalData.gaAction = isNewPremium
      ? 'New premium tab - View loadings and exclusions'
      : 'Existing premium - View loadings and exclusions'
    return modalData
  }
  if (loading.length) {
    modalData.buttonTextValue = ViewLoadingsCTALabel
    modalData.titleTextValue = replaceLabelPlaceholders(LoadingsModalHeader, titleData)
    modalData.gaAction = isNewPremium
      ? 'New premium tab - View loadings'
      : 'Existing premium - View loadings'
  }
  if (exclusion.length) {
    modalData.buttonTextValue = ViewExclusionsCTALabel
    modalData.titleTextValue = replaceLabelPlaceholders(ExclusionsModalHeader, titleData)
    modalData.gaAction = isNewPremium
      ? 'New premium tab - View exclusions'
      : 'Existing premium - View exclusions'
  }
  return modalData
}

const AltsDecreaseLoadingExclusionModal = ({
  loading,
  exclusion,
  fields,
  benefitName,
  benefitCommencementDate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    LoadingAndExclusionsRemovedModalButton,
    LoadingModalDescForRPM,
    PercentageAppliedLabel,
    LoadingsHeadingLabel,
    ExclusionsHeadingLabel,
  } = fields

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const { buttonTextValue, titleTextValue, gaAction } = React.useMemo(
    () => getHeaderTitleData({ benefitName, benefitCommencementDate, fields, loading, exclusion }),
    [benefitName, benefitCommencementDate, loading, exclusion]
  )

  const modalMessage = React.useMemo(
    () => (
      <>
        {loading.length && exclusion.length ? (
          <Heading variant="h3" size="xsmall">
            {renderTextField(LoadingsHeadingLabel)}
          </Heading>
        ) : null}
        {loading.length
          ? loading.map((element, index) => {
              const loadingDescriptionObj = {
                loadingValue: element.loadingValue,
                reasonDescription: (element.reason || [])
                  .map(({ reasonDescription }) => reasonDescription)
                  .join(', '),
              }
              let loadingType
              if (element.loadingTypeCode === LOADING_TYPE_EMR.code) {
                loadingType = PercentageAppliedLabel
              }
              if (element.loadingTypeCode === LOADING_TYPE_RPM.code) {
                loadingType = LoadingModalDescForRPM
              }
              return (
                <List key={element.loadingValueType}>
                  <div>
                    {index + 1}
                    {LIST_BULLET}
                  </div>
                  <ListDesc>
                    {replaceLabelPlaceholders(loadingType, loadingDescriptionObj)}
                  </ListDesc>
                </List>
              )
            })
          : null}
        {loading.length && exclusion.length ? (
          <Heading variant="h3" size="xsmall">
            {renderTextField(ExclusionsHeadingLabel)}
          </Heading>
        ) : null}

        {exclusion.length
          ? exclusion.map((element, index) => (
              <List key={element.exclusionCode}>
                <div>
                  {index + 1}
                  {LIST_BULLET}
                </div>
                <ListDesc>{element.description}</ListDesc>
              </List>
            ))
          : null}
      </>
    ),
    [loading, exclusion]
  )
  return (
    <>
      <LinkButton
        variant="tertiary"
        size="xsmall"
        onClick={() => {
          setIsModalOpen(true)
          const event = createEvent({
            GA: {
              category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
              action: gaAction,
            },
            Splunk: {
              attributes: {
                'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - ${gaAction}`,
              },
            },
          })
          event.end()
        }}
      >
        {renderTextField(buttonTextValue)}
      </LinkButton>

      <Modal
        isOpen={isModalOpen}
        title={titleTextValue}
        onClose={handleClose}
        showCloseButton={false}
        footer={
          <ModalFooter>
            <Button type="secondary" onClick={handleClose}>
              {renderTextField(LoadingAndExclusionsRemovedModalButton)}
            </Button>
          </ModalFooter>
        }
      >
        <div>{modalMessage}</div>
      </Modal>
    </>
  )
}

AltsDecreaseLoadingExclusionModal.propTypes = {
  loading: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.string,
      loadingType: PropTypes.string,
      loadingValue: PropTypes.number,
      loadingValueType: PropTypes.string,
      startDate: PropTypes.string,
      reason: PropTypes.arrayOf(
        PropTypes.shape({
          reasonCode: PropTypes.string,
          reasonDescription: PropTypes.string,
        })
      ),
    })
  ),
  exclusion: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.string,
      referenceNo: PropTypes.string,
      exclusionCode: PropTypes.string,
      startDate: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  benefitName: PropTypes.string,
  benefitCommencementDate: PropTypes.string,
  isNewPremium: PropTypes.bool,
}

AltsDecreaseLoadingExclusionModal.defaultProps = {
  loading: [],
  exclusion: [],
  benefitName: '',
  benefitCommencementDate: '',
  isNewPremium: false,
  fields: {
    LoadingAndExclusionsRemovedModalButton: { value: '' },
    ViewLoadingsCTALabel: { value: '' },
    ViewExclusionsCTALabel: { value: '' },
    ViewLoadingsExclusionsCTALabel: { value: '' },
    LoadingsModalHeader: { value: '' },
    ExclusionsModalHeader: { value: '' },
    LoadingsExclusionsModalHeader: { value: '' },
    LoadingModalDescForRPM: { value: '' },
    PercentageAppliedLabel: { value: '' },
    LoadingsHeadingLabel: { value: '' },
    ExclusionsHeadingLabel: { value: '' },
  },
}
export default AltsDecreaseLoadingExclusionModal
