import { Variables } from '@mlcl-digital/mlcl-design'
import { colours } from '../../../styles'

const { fontSize } = Variables
export default {
  body: {
    color: colours.darkGrey,
    fontWeight: 'normal',
  },
  seriesChangeModal: {
    '& >div:nth-last-of-type(1)': {
      '& > div > div: nth-of-type(1)': {
        paddingBottom: 0,
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
      },
    },
    h6: {
      fontSize: fontSize.xs,
    },
  },
}
