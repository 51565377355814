// @flow
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Modal } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import { createEvent } from '../../../../../../utils/telemetry'

// utilities
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// actions
import { actionCreators } from '../../../../../../actions'

// styles
import styles from './resetLinkAndModal.styles'

// constants
import { DECREASE_COVER_GA_TAG_QUOTE_SCREEN } from '../../../../../../constants/alterations'

// Styled components
const ResetButton = styled(Button)(styles.resetButton)
const ResetButtonWrapper = styled('div')(styles.resetButtonWrapper)
const ModalFooter = styled('div')(styles.modalFooter)

const ResetLinkAndModal = ({ isDisabled, fields }) => {
  const { ResetAllCTA, ResetModalHdr, ResetModalTxt, ResetCTA, CancelCTA } = fields
  const [isModalOpen, setIsModalOpen] = useState(false)

  const dispatch = useDispatch()

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const handleReset = () => {
    dispatch(actionCreators.deleteAlterationsInQuote())
    setIsModalOpen(false)
  }

  return (
    <Fragment>
      <ResetButtonWrapper>
        <ResetButton
          variant="tertiary"
          disabled={isDisabled}
          onClick={() => {
            setIsModalOpen(true)
            const tagEvent = createEvent({
              GA: {
                category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                action: 'Reset all changes',
              },
              Splunk: {
                attributes: {
                  'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Reset all changes`,
                },
              },
            })
            tagEvent.end()
          }}
        >
          {renderTextField(ResetAllCTA)}
        </ResetButton>
      </ResetButtonWrapper>
      <Modal
        isOpen={isModalOpen}
        title={renderTextField(ResetModalHdr)}
        onClose={handleClose}
        footer={
          <ModalFooter>
            <Button variant="primary" size="small" onClick={handleReset}>
              {renderTextField(ResetCTA)}
            </Button>
            <Button variant="secondary" size="small" onClick={handleClose}>
              {renderTextField(CancelCTA)}
            </Button>
          </ModalFooter>
        }
      >
        <div>{renderTextField(ResetModalTxt)}</div>
      </Modal>
    </Fragment>
  )
}

export default ResetLinkAndModal

ResetLinkAndModal.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    ResetAllCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
    ResetModalHdr: PropTypes.shape({
      value: PropTypes.string,
    }),
    ResetModalTxt: PropTypes.shape({
      value: PropTypes.string,
    }),
    ResetCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
    CancelCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
}
