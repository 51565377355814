import { Variables } from '@mlcl-digital/mlcl-design'
import { shadows, colours, space, fontSize } from '../../../../../styles'

const { colours: libColours } = Variables

const styles = {
  container: (percentage, hasError, hideBorderColor) => {
    const hasPercentage = percentage > 0
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: space(1, true),
      padding: space(2, true),
      position: 'relative',
      flex: 1,
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: 2,
      borderStyle: 'solid',
      transition: 'border .25s ease-in-out',
      boxShadow: shadows.low,
      color: colours.darkestGrey,
      background: hasPercentage ? colours.lightBlue : colours.white,
      borderColor: hasError ? colours.red : colours.lightestGrey,
      ...(hideBorderColor && { borderColor: 'inherit' }),
      ...(hasPercentage && {
        '&::after': {
          position: 'absolute',
          content: '""',
          width: `${percentage}%`,
          height: '100%',
          top: 0,
          left: 0,
          background: colours.lightGreen,
          transition: 'width 5s',
        },
      }),
    }
  },

  documentIcon: {
    marginRight: space(3, true),
    color: colours.darkestGrey,
  },

  fileErrorText: {
    color: colours.red,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
  },

  errorMessage: {
    padding: `${space(1.75)}px ${space(3)}px`,
    fontWeight: '600',
    backgroundColor: libColours.functionalDangerLight,
    color: libColours.functionalDangerDark,
    marginBottom: space(0.75),
  },
  warningIcon: { marginRight: space(1), width: space(3), height: space(3), verticalAlign: 'sub' },

  removeFileButton: {
    padding: 0,
    height: space(3, true),
    lineHeight: space(3, true),
    fontSize: fontSize.xs,
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: '1',
    width: '100%',
  },
}

export default styles
