// @flow
import React from 'react'

// components.
import CustomerCompletesContainer from '../../../organisms/CustomerCompletes'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

type CustomerCompletesProps = {
  fields: Object<Object>,
}

const CustomerCompletes = ({ fields }: CustomerCompletesProps) => (
  <CustomerCompletesContainer fields={reduceAuthorableFields(fields)} />
)

export default CustomerCompletes
