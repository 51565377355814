/* eslint-disable no-unused-vars */
import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { mediaQueries } = Variables as VariablesType

const styles = {
  container: {
    display: 'block',
    width: 110,
    [mediaQueries.md]: {
      width: 186,
      height: 50,
    },
  },
  image: {
    marginBottom: 0,
    height: '100%',
  },
  horizontal: {
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
    },
  },
  vertical: {
    display: 'block',
    [mediaQueries.md]: {
      display: 'none',
    },
  },
}

export default styles
