import {
  optional,
  required,
  email,
  phone,
  mobilePhone,
  regexTest,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '../../../../utils/formUtils'
import { phoneCodeAustralia } from '../../../../constants/customerPersonalDetails'

import { FormFields } from '../../../../types/forms'

import {
  PREFERRED_METHOD_OF_COMMS,
  PREFERRED_METHOD_OF_COMMS_FIELDS,
} from '../../../../constants/forms'

export const FORM_ID = 'ContactDetails'

type ReducedSitecoreFields = {
  CountryRequiredError: string
  LocalityRequiredError: string
  PostCodeRequiredError: string
  StateRequiredError: string
  StreetRequiredError: string
  EmailRequiredError: string
  EmailValidError: string
  PhoneRequiredError: string
  PhoneValidError: string
  PreferredMethodOfCommsRequiredError: string
}

export const addressFields = (fields?: ReducedSitecoreFields) => ({
  address: {
    condition: optional,
  },
  postalNumber: {
    condition: optional,
  },
  postalType: {
    condition: optional,
  },
  country: {
    condition: required,
    errorMsg: fields?.CountryRequiredError,
  },
  houseNo: {
    condition: optional,
  },
  locality: {
    condition: required,
    errorMsg: fields?.LocalityRequiredError,
  },
  postCode: {
    condition: required,
    errorMsg: fields?.PostCodeRequiredError,
  },
  state: {
    condition: required,
    errorMsg: fields?.StateRequiredError,
  },
  street: {
    condition: required,
    errorMsg: fields?.StreetRequiredError,
  },
})

export const secondaryAddressFields = (fields?: ReducedSitecoreFields) => ({
  postalAddress: {
    condition: optional,
  },
  postalPostalNumber: {
    condition: optional,
  },
  postalPostalType: {
    condition: optional,
  },
  postalCountry: {
    condition: required,
    errorMsg: fields?.CountryRequiredError,
  },
  postalHouseNo: {
    condition: optional,
  },
  postalLocality: {
    condition: required,
    errorMsg: fields?.LocalityRequiredError,
  },
  postalPostCode: {
    condition: required,
    errorMsg: fields?.PostCodeRequiredError,
  },
  postalState: {
    condition: required,
    errorMsg: fields?.StateRequiredError,
  },
  postalStreet: {
    condition: required,
    errorMsg: fields?.StreetRequiredError,
  },
})

export const mapAddressToForm = (address: { [key: string]: string | Date }, isPostal: boolean) => {
  const fields = [
    'country',
    'postalNumber',
    'postalType',
    'houseNo',
    'locality',
    'postCode',
    'state',
    'street',
  ]
  const postalFields = [
    'postalCountry',
    'postalPostalNumber',
    'postalPostalType',
    'postalHouseNo',
    'postalLocality',
    'postalPostCode',
    'postalState',
    'postalStreet',
  ]

  const fieldsToUse = isPostal ? postalFields : fields

  return {
    [fieldsToUse[0]]: address.country,
    [fieldsToUse[1]]: address.postalNumber,
    [fieldsToUse[2]]: address.district,
    [fieldsToUse[3]]: address.houseNo,
    [fieldsToUse[4]]: address.locality,
    [fieldsToUse[5]]: address.postCode,
    [fieldsToUse[6]]: address.state,
    [fieldsToUse[7]]: address.street,
  }
}

const schema = (hasSecondaryAddress: boolean, fields: ReducedSitecoreFields) => ({
  email: {
    condition: [
      { condition: required, errorMsg: fields.EmailRequiredError },
      { condition: email, errorMsg: fields.EmailValidError },
    ],
  },
  phoneCode: {
    condition: required,
  },
  mobilePhone: {
    condition: [
      { condition: required, errorMsg: fields.PhoneRequiredError },
      {
        condition: (value: string, formFields: FormFields) => {
          const idc = formFields.phoneCode?.value
          const regex = idc === phoneCodeAustralia ? mobilePhone : phone
          return !regexTest(regex, value)
        },
        errorMsg: fields.PhoneValidError,
      },
    ],
  },
  ...addressFields(fields),
  ...(hasSecondaryAddress && secondaryAddressFields(fields)),
  [PREFERRED_METHOD_OF_COMMS]: {
    options: [
      {
        ...PREFERRED_METHOD_OF_COMMS_FIELDS.digital,
      },
      {
        ...PREFERRED_METHOD_OF_COMMS_FIELDS.paper,
      },
    ],
    condition: [
      {
        condition: required,
        errorMsg: fields.PreferredMethodOfCommsRequiredError,
      },
    ],
  },
})

export default schema
