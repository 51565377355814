import React, { useCallback, useEffect } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'

import { Heading, PageWrap, Button } from '@mlcl-digital/mlcl-design'
import List from '@mlcl-digital/mlcl-design/lib/base/List'
import { createEvent } from '../../../utils/telemetry'
// @ts-expect-error file not in typescript
import Link from '../../atoms/Link'

// @ts-expect-error file not in typescript
import { renderTextField } from '../../../utils/sitecoreUtils'

// @ts-expect-error file not in typescript
import { getAlterationType } from '../../../selectors/alterations'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../actions'

// constatns
import { DASHBOARD_ROUTE } from '../../../constants/routes'
import { NAVIGATION_MAIN, FOOTER_MAIN } from '../../../constants/navigation'

// styles
import styles from './altsSubmissionV2.styles'

// types
import { AltsSubmissionV2Props } from '../../../types/components/AltsSubmission'

// component
import SelectedPolicies from '../Alteration/AltsDeclarationV2/components/SelectedPolicies'

const Container = styled('div')(styles.container)
const HeadingWrap = styled('div')(styles.headingWrap)
const LinkContainer = styled('div')(styles.linkContainer)
const PoliciesContainer = styled('div')(styles.policiesContainer)

const AltsSubmissionV2 = ({ fields, params }: AltsSubmissionV2Props) => {
  const alterationType: string = useSelector(getAlterationType())
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actionCreators.changeNavigationType(NAVIGATION_MAIN))
    dispatch(actionCreators.changeFooterType(FOOTER_MAIN))
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Submitted app page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} alteration Submitted app page load`,
        },
      },
    })
    tagEvent.end()
  }, [])

  const onClick = useCallback(() => {
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Submitted app page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} Back to dashboard`,
        },
      },
    })
    tagEvent.end()
  }, [])

  return (
    <PageWrap>
      <Container>
        <HeadingWrap>
          <Heading variant="h2">
            <Text field={fields.QuoteDetailsHeading} />
          </Heading>
          <p>
            <Text field={fields.QuoteDetailsDesc} />
          </p>
        </HeadingWrap>
        <PoliciesContainer>
          <SelectedPolicies alterationType={params?.AlterationType as string} fields={fields} />
        </PoliciesContainer>
        <List
          variant="number"
          heading={fields.ExpectNextHeading?.value}
          headingProps={{ variant: 'h3' }}
          listItems={fields.ExpectNextSteps?.map(listItem =>
            renderTextField(listItem?.fields?.Description?.value, true)
          )}
        />
        <LinkContainer>
          <Link variant="secondary" to={DASHBOARD_ROUTE} onClick={onClick}>
            {renderTextField(fields.BackCTA)}
          </Link>
          <Button>{renderTextField(fields.ReviewOtherPoliciesCTA)}</Button>
        </LinkContainer>
      </Container>
    </PageWrap>
  )
}

export default AltsSubmissionV2
