// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import {
  Heading,
  Button,
  Modal,
  ErrorState,
  Hero,
  PageWrap,
  Icons,
  Card,
} from '@mlcl-digital/mlcl-design'
import MarketingBanner from '@mlcl-digital/mlcl-design/lib/base/MarketingBanner'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// selectors
import { shouldShowCommsModal, getUserId } from '../../../selectors/common'

// molecules
import SearchBox from '../../molecules/SearchBar'
import TileComponent from '../../molecules/Tile'
import TileComponentUplift from '../../molecules/NewTile'
import WithLoader from '../../molecules/WithLoader'
// components.
import NotificationBar from '../../molecules/NotificationBar'
import TaskList from '../../molecules/TasksList'
import EmailClientErrorModal from '../EmailClientErrorModal'

// utils.
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'
import { isStringContainsNumber } from '../../../utils/formUtils'
import { getPolicyDetailsByMemberId, getPolicyIDBySearchInput } from '../../../utils/policyUtils'
import { isFeatureEnabledForAP } from '../../../utils/commonUtils'
import { isFeatureEnabled } from '../../../utils/featureToggling'
import { retentionCategories } from '../../../utils/dashboardUtils'

// styles.
import styles from './advisorDashboard.styles'

// constant
import { RETENTION_DASHBOARD_URL, TASK_DASHBOARD_URL } from '../../../constants/dashboard'

type AdviserDashboardProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // master data
  masterList: Object<Function>,
  // An object containing action creator functions.
  actions: {
    resetNavigationType: Function,
    resetFooterType: Function,
    getPolicies: Function,
    getMasterList: Function,
    updateBancsCustomerNumber: Function,
    generateFreeText: Function,
    toggleFileUploadModal: Function,
    updateLoading: Function,
  },
  // Array of search results mapped to props from state from member search
  dashboardDetail: Array<{
    anniversary: Object,
    riskOfLapse: Object,
    lapsed: Object,
    cancelled: Object,
    isLoading: Boolean,
  }>,
  // Array of outstanding requirements
  taskDashboard: Array<{
    records: Object,
  }>,
  // userId of logged in adviser to be sent back in user update call
  userId: String,
  // flag to determine whether to show the digital comms modal
  showDigitalComms: Boolean,
  // feature toggle to show uplifted dashboard
  isDashboardUpliftEnabled: Boolean,
}

type AdviserDashboardState = {
  // search text input
  searchInput: String,
  // has error from api
  hasError: Boolean,
  // flag to determine whether policy API is loading
  isSearchingPolicies: Boolean,
  // flag to determine whether to show the digital comms modal
  showCommsModal: Boolean,
  // flag to determine whether to show the email client error modal
  showEmailClientErrorModal: Boolean,
}

const { IconWarning16 } = Icons

const Container = styled('div')(styles.container)
const Wrap = styled(PageWrap)(styles.tableRow)
const Section = styled('div')(styles.section)
const SectionDescription = styled('p')(styles.sectionDescription)
const SectionHeaderContainer = styled('div')(styles.sectionHeader)
const ViewAllButton = styled(Button)(styles.viewAllButton)
const CardContent = styled('div')(styles.card)
const IconExclaimation = styled(IconWarning16)(styles.iconExclaimation)
const ModalContent = styled('div')(styles.modalContent)
const ModalFooter = styled('div')(styles.modalFooter)

export class AdviserDashboard extends Component<AdviserDashboardProps, AdviserDashboardState> {
  constructor(props) {
    super(props)
    this.state = {
      searchInput: '',
      hasError: false,
      isSearchingPolicies: false,
      showCommsModal: false,
      showEmailClientErrorModal: false,
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Adviser lands on dashboard',
        action: 'AP - adviser portal dashboard views',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Adviser lands on dashboard',
        },
      },
    })
    tagEvent.end()
  }

  componentDidMount() {
    const {
      actions: {
        resetNavigationType,
        resetFooterType,
        getMasterList,
        updateBancsCustomerNumber,
        updateLoading,
      },
      showDigitalComms,
    } = this.props
    getMasterList()
    resetNavigationType()
    resetFooterType()
    updateBancsCustomerNumber()
    updateLoading([])
    if (showDigitalComms) {
      this.showDigiCommsModal()
    }
  }

  showDigiCommsModal = () => {
    this.setState({ showCommsModal: true })
  }

  showEmailClientErrorModal = () => {
    this.setState({ showEmailClientErrorModal: true })
  }

  setEmailClientErrorModalRedirectID = (clientId, policyId) => {
    this.clientId = clientId
    this.policyId = policyId
  }

  handleCommsModalActions = () => {
    const {
      actions: { updateUserProfile },
      userId,
    } = this.props
    this.setState({ showCommsModal: false })

    updateUserProfile(
      {
        hasSeenDigiCommsNotification: true,
      },
      userId,
      null
    )
  }

  changeSearchHandler = (value: string): void => {
    this.setState(prevState => ({ ...prevState, searchInput: value, hasError: false }))
  }

  getAdviserPolicy = (bancsPartyNo, isSMSF) => {
    const {
      actions: { getPolicies },
      masterList,
    } = this.props
    const policyStatus = get(masterList, 'data.policyStatus', [])
    const { searchInput } = this.state
    getPolicies(bancsPartyNo, isSMSF, data => {
      const { businessData } = data
      if (!businessData) {
        return this.setState({
          hasError: true,
          isSearchingPolicies: false,
        })
      }
      const { policies } = businessData

      const policyId = getPolicyIDBySearchInput(policies, policyStatus, searchInput)
      if (!policyId) {
        return this.setState({
          hasError: true,
          isSearchingPolicies: false,
        })
      }
      // redirect on policy details
      const path = `/clients/details?clientId=${bancsPartyNo}&policyId=${policyId}&isSMSF=${isSMSF}&fromSearch=true`
      this.setState({ isSearchingPolicies: false })
      return history.push(path)
    })
  }

  clickSearchHandler = () => {
    const {
      actions: { searchPolicies },
    } = this.props
    const { searchInput } = this.state
    // prevent search if searchInput in empty
    if (!searchInput) {
      return history.push('/clients')
    }
    if (isStringContainsNumber(searchInput)) {
      const tagEvent = createEvent({
        GA: {
          category: 'Adviser uses search on dashboard',
          action: 'Search for policy number',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Adviser uses search on dashboard',
          },
        },
      })
      tagEvent.end()
      this.setState({ isSearchingPolicies: true })
      // invoke - search policy api. If searchInput contains number like - policyNo: '93000152'
      return searchPolicies(searchInput, (err, data) => {
        // handle error here
        if (err) {
          return this.setState({
            hasError: true,
            isSearchingPolicies: false,
          })
        }
        return getPolicyDetailsByMemberId(data, this.getAdviserPolicy)
      })
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Adviser uses search on dashboard',
        action: 'Search for life insured',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Adviser uses search on dashboard - Search for life insured',
        },
      },
    })
    tagEvent.end()
    // invoke - Search Member api. If searchInput is not contains number
    const path = `/clients?partyNameLike=${searchInput}`
    return history.push(path)
  }

  handleNavigation = () => {
    history.push(TASK_DASHBOARD_URL)
    const tagEvent = createEvent({
      GA: {
        category: "List of 'Your tasks'",
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'List of Your tasks - View',
        },
      },
    })
    tagEvent.end()
  }

  renderTaskDashboard() {
    const { fields, taskDashboard } = this.props
    const isLoading = taskDashboard?.isLoading
    const {
      dashboardTaskDashboardTitle,
      dashboardTaskDashboardSubHeading,
      dashboardTaskDashboardViewAllText,
      dashboardTaskDashboardNoTaskMessage,
      dashboardTaskActionButtonText,
      dashboardOutstandingRequirementModalConfirmButton,
      dashboardOutstandingRequirementMaxFileSizeError,
      dashboardOutstandingRequirementMaxFileSize,
      dashboardOutstandingRequirementMaxFileCount,
      dashboardOutstandingRequirementWrongMimeType,
      dashboardOutstandingRequirementUploading,
      dashboardOutstandingRequirementUploadSuccess,
      dashboardOutstandingRequirementUploadFailure,
      dashboardOutstandingRequirementFileRemoveLabel,
      dashboardOutstandingRequirementWorkItemFailure,
      dashboardOutstandingRequirementWorkItemSuccess,
      dashboardOutstandingRequirementNoFileError,
      dashboardOutstandingRequirementFreeTextRequirementPlaceholder,
      dashboardOutstandingRequirementFreeTextRequirementModalHeading,
      dashboardOutstandingRequirementFreeTextRequirementModalSubHeading,
      dashboardOutstandingRequirementWrongFileNameError,
    } = reduceAuthorableFields(fields)

    const tasksFields = {
      modalHeading: dashboardOutstandingRequirementFreeTextRequirementModalHeading,
      modalConfirmButton: dashboardOutstandingRequirementModalConfirmButton,
      modalSubHeading: dashboardOutstandingRequirementFreeTextRequirementModalSubHeading,
      maxFileSizeError: dashboardOutstandingRequirementMaxFileSizeError,
      maxFileSize: dashboardOutstandingRequirementMaxFileSize,
      maxFileNumber: dashboardOutstandingRequirementMaxFileCount,
      wrongFileType: dashboardOutstandingRequirementWrongMimeType,
      fileUploading: dashboardOutstandingRequirementUploading,
      fileUploadSuccess: dashboardOutstandingRequirementUploadSuccess,
      fileUploadFailure: dashboardOutstandingRequirementUploadFailure,
      fileRemoveButtonLabel: dashboardOutstandingRequirementFileRemoveLabel,
      createWorkItemError: dashboardOutstandingRequirementWorkItemFailure,
      createWorkItemSuccess: dashboardOutstandingRequirementWorkItemSuccess,
      noFileUploadedError: dashboardOutstandingRequirementNoFileError,
      wrongFileNameError: dashboardOutstandingRequirementWrongFileNameError,
      actionButtonText: dashboardTaskActionButtonText,
      freeTextPlaceholder: dashboardOutstandingRequirementFreeTextRequirementPlaceholder,
      NoTaskMessage: dashboardTaskDashboardNoTaskMessage,
    }
    const isRecordsAvailable = get(taskDashboard, 'records', []).length >= 1
    const tagEvent = createEvent({
      GA: {
        category: 'Digital task',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Digital task',
        },
      },
    })
    tagEvent.end()
    return (
      <Section>
        <SectionHeaderContainer>
          <div>
            <Heading variant="h2">{dashboardTaskDashboardTitle}</Heading>
            <SectionDescription>{dashboardTaskDashboardSubHeading}</SectionDescription>
          </div>
          <ViewAllButton
            data-testid="viewAllBtn"
            variant="secondary"
            size="small"
            onClick={!isRecordsAvailable ? null : this.handleNavigation}
            isActive={isRecordsAvailable}
          >
            {dashboardTaskDashboardViewAllText}
          </ViewAllButton>
        </SectionHeaderContainer>
        <TaskList fields={tasksFields} limited />
        {!isRecordsAvailable && !isLoading && (
          <Card style={{ marginTop: '60px' }}>
            <ErrorState
              message={dashboardTaskDashboardNoTaskMessage}
              emptyState
              showBackground={false}
            />
          </Card>
        )}
      </Section>
    )
  }

  renderRetentionDashboard = () => {
    const { dashboardDetail, fields, isDashboardUpliftEnabled } = this.props
    const { retentionDashboardTitle } = reduceAuthorableFields(fields)
    const isLoading = get(dashboardDetail, 'isLoading', false)
    const dashboardDetailLimit = dashboardDetail?.limit ?? 0
    const TileComp = isDashboardUpliftEnabled ? TileComponentUplift : TileComponent
    const schema = retentionCategories(
      {
        fields: reduceAuthorableFields(fields),
      },
      dashboardDetail
    )
    if (isDashboardUpliftEnabled) {
      schema[0] = schema.splice(2, 1, schema[0])[0]
    }

    return (
      <Section>
        <SectionHeaderContainer>
          <Heading variant="h2">{retentionDashboardTitle}</Heading>
        </SectionHeaderContainer>
        <TileComp
          schema={schema}
          dashboardDetailLimit={dashboardDetailLimit}
          fields={fields}
          href={RETENTION_DASHBOARD_URL}
          allowNavigation
          showLoader
          showLoaderProps={{
            isLoading,
          }}
          setEmailClientErrorModalRedirectID={this.setEmailClientErrorModalRedirectID}
          showEmailClientErrorModal={this.showEmailClientErrorModal}
        />
      </Section>
    )
  }

  render() {
    const { fields, masterList, isDashboardUpliftEnabled } = this.props
    const {
      searchInput,
      hasError,
      isSearchingPolicies,
      showCommsModal,
      showEmailClientErrorModal,
    } = this.state
    const {
      dashboardSearchBoxTitleText,
      dashboardSearchBoxPlaceholderText,
      dashboardSearchBoxErrorMessage,
      dashboardNotificationBarText,
      dashboardNotificationBarButtonLink,
      MarketingBannerHeaderImage,
      MarketingBannerImage,
      MarketingBannerHeading,
      MarketingBannerDescription,
      MarketingBannerActionCTA,
      MarketingBannerSettingsIcon,
    } = reduceAuthorableFields(fields)

    const controlList = get(masterList, 'data.featureControlSwitch', [])
    const showDashboardNotificationBar = isFeatureEnabledForAP(
      controlList,
      'enableDashboardNotification'
    )
    const heroAdvisorDashboardFields = {
      heading: get(fields, 'heading.value', ''),
      content: get(fields, 'content.value', ''),
    }

    return (
      <Container>
        {showDashboardNotificationBar && (
          <NotificationBar
            heading={dashboardNotificationBarText}
            buttonProps={dashboardNotificationBarButtonLink}
          />
        )}
        <Hero fields={heroAdvisorDashboardFields} />
        <Wrap>
          {(MarketingBannerHeading || MarketingBannerDescription) && (
            <MarketingBanner
              imageSourceProps={MarketingBannerSettingsIcon}
              headerImageProps={MarketingBannerHeaderImage}
              heading={MarketingBannerHeading}
              subheading={<RichText field={{ value: MarketingBannerDescription }} />}
              buttonProps={MarketingBannerActionCTA}
              bannerImageProps={MarketingBannerImage}
            />
          )}
          <SearchBox
            title={dashboardSearchBoxTitleText}
            placeholder={dashboardSearchBoxPlaceholderText}
            value={searchInput}
            clickHandler={this.clickSearchHandler}
            changeHandler={this.changeSearchHandler}
          />
          <WithLoader isLoading={isSearchingPolicies} loaderProps={{ type: 'tab' }}>
            {hasError && (
              <CardContent>
                <IconExclaimation />
                <span>{dashboardSearchBoxErrorMessage}</span>
              </CardContent>
            )}
            {isDashboardUpliftEnabled && this.renderRetentionDashboard()}
            {this.renderTaskDashboard()}
            {!isDashboardUpliftEnabled && this.renderRetentionDashboard()}
          </WithLoader>
        </Wrap>
        <Modal
          isOpen={showCommsModal}
          onClose={() => this.handleCommsModalActions()}
          title="Communication preferences"
          footer={
            <ModalFooter>
              <Button onClick={() => this.handleCommsModalActions()} size="small">
                Ok
              </Button>
            </ModalFooter>
          }
        >
          <ModalContent>{renderTextField(fields.digiCommsModalContent)}</ModalContent>
        </Modal>
        <EmailClientErrorModal
          fields={reduceAuthorableFields(fields)}
          isOpen={showEmailClientErrorModal}
          onClose={() => this.setState({ showEmailClientErrorModal: false })}
          handleCheckButtonClick={() => {
            const event = createEvent({
              GA: {
                category: 'Dashboard email no address - check client details button',
                action: 'select',
              },
              Splunk: {
                attributes: {
                  'workflow.name': 'Dashboard email no address - check client details button',
                },
              },
            })
            event.end()
            const path = `/clients/details?clientId=${this.clientId}&policyId=${this.policyId}`
            history.push(path, { fromEmailClient: true })
          }}
        />
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const {
    retentionDashboard: { dashboardDetail },
    taskDashboard: { taskDashboard },
    masterList,
    config,
  } = state
  return {
    dashboardDetail,
    taskDashboard,
    masterList,
    showDigitalComms: shouldShowCommsModal(state),
    userId: getUserId(state),
    isDashboardUpliftEnabled: isFeatureEnabled('AdvisorDashboardUplift', config.FEATURES),
    isSignInRedirectEnabled: isFeatureEnabled('SignInRedirect', config.FEATURES),
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdviserDashboard)
