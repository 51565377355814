import React from 'react'
import styled from '@emotion/styled'
import { shape, String, Object } from 'prop-types'
import { useSelector } from 'react-redux'

import { renderTextField } from '../../../../../utils/sitecoreUtils'
import styles from './ConfirmationComponent.styles'
import { getSite } from '../../../../../selectors/common.selectors'
import { ADVISOR_PORTAL } from '../../../../../constants/site'

const ForgottonUserNameForm = props => {
  const { fields, components } = props
  const { forgotUsernameConfirmationDetail, forgotUsernameConfirmationHelp } = fields
  const { value } = components

  const isAdvisorPortal = useSelector(getSite) === ADVISOR_PORTAL

  const Wrapper = styled('div')(styles.wrapper)
  const Detail = styled('div')(styles.detail(isAdvisorPortal))
  const Help = styled('div')(styles.help)
  const Value = styled('div')(styles.value)

  return (
    <Wrapper>
      {isAdvisorPortal && (
        <>
          <Detail>{renderTextField(forgotUsernameConfirmationDetail)}</Detail>
          <Value>{value}</Value>
        </>
      )}
      <Help>{renderTextField(forgotUsernameConfirmationHelp, true)}</Help>
    </Wrapper>
  )
}

ForgottonUserNameForm.propTypes = {
  fields: shape({
    forgotUsernameConfirmationDetail: Object,
    forgotUsernameConfirmationHelp: Object,
  }).isRequired,
  components: shape({
    value: String,
  }).isRequired,
}

export default ForgottonUserNameForm
