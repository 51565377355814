import get from 'lodash/get'
import { idMaker } from '../../../../../utils/formUtils'

// TODO:: DashbaordUtils has a new implementation to return back a similar schema
// Need to evaluate of that can be used and drop this class at together
const retentionDashboardTabs = ({ fields }, dashboardDetail) => [
  {
    label: fields.dashboardPolicyAnniversaryTabTitleNew,
    value: get(dashboardDetail, 'anniversary.totalNoOfPolicies', '0'),
    id: idMaker(fields.dashboardPolicyAnniversaryTabTitle),
  },
  {
    label: fields.dashboardRiskOfLapseTabTitleNew,
    value: get(dashboardDetail, 'riskOfLapse.totalNoOfPolicies', '0'),
    id: idMaker(fields.dashboardRiskOfLapseTabTitle),
  },
  {
    label: fields.recentlyLapseTabTitleNew,
    value: get(dashboardDetail, 'lapsed.totalNoOfPolicies', '0'),
    id: idMaker(fields.recentlyLapseTabTitle),
  },
  {
    label: fields.recentlyCancelledTabTitleNew,
    value: get(dashboardDetail, 'cancelled.totalNoOfPolicies', '0'),
    id: idMaker(fields.recentlyCancelledTabTitle),
  },
]

export default retentionDashboardTabs
