// @flow
import {
  TASK_DASHBOARD_FETCH_INIT,
  TASK_DASHBOARD_FETCH_SUCCESS,
  TASK_DASHBOARD_FETCH_ERROR,
  TASK_DASHBOARD_FETCH_FAILURE,
  GENERATE_FREE_TEXT_INIT,
  GENERATE_FREE_TEXT_SUCCESS,
  GENERATE_FREE_TEXT_ERROR,
  GENERATE_FREE_TEXT_FAILURE,
  TASK_DASHBOARD_FILTER,
} from '../actions/types/taskDashboard'

import { isRequirementsAvailable } from '../utils/dashboardUtils'

export const initialState = {
  taskDashboard: {
    records: [],
    isLoading: false,
    hasError: false,
    isFreeTextFetching: false,
  },
}

const taskDashboard = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case TASK_DASHBOARD_FETCH_INIT: {
      return {
        ...state,
        taskDashboard: {
          ...state.taskDashboard,
          isLoading: true,
          hasError: false,
        },
      }
    }

    case TASK_DASHBOARD_FILTER: {
      return {
        ...state,
        taskDashboard: {
          ...action.payload,
          isLoading: false,
          hasError: false,
        },
      }
    }
    case TASK_DASHBOARD_FETCH_SUCCESS: {
      const {
        businessData: { records },
      } = action.payload
      return {
        ...state,
        taskDashboard: {
          records: records.filter(record => isRequirementsAvailable(record)),
          isLoading: false,
          hasError: false,
        },
      }
    }

    case TASK_DASHBOARD_FETCH_ERROR:
    case TASK_DASHBOARD_FETCH_FAILURE: {
      return {
        ...state,
        taskDashboard: {
          records: [],
          isLoading: false,
          hasError: true,
        },
      }
    }

    case GENERATE_FREE_TEXT_INIT: {
      return {
        ...state,
        isFreeTextFetching: true,
        hasFetchError: false,
      }
    }

    case GENERATE_FREE_TEXT_SUCCESS: {
      return {
        ...state,
        isFreeTextFetching: false,
        hasFetchError: false,
      }
    }

    case GENERATE_FREE_TEXT_ERROR:
    case GENERATE_FREE_TEXT_FAILURE: {
      return {
        ...state,
        isFreeTextFetching: false,
        hasFetchError: true,
      }
    }

    default: {
      return state
    }
  }
}

export default taskDashboard
