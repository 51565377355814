// @flow
import React from 'react'

import SelectAdviserComponent from '../../../organisms/SelectAdviser'

type SelectAdviserProps = {
  fields: Object,
}

const SelectAdviser = (props: SelectAdviserProps) => <SelectAdviserComponent {...props} />

export default SelectAdviser
