// Note: Some code is commented that might be useful in future
// stories. The code is copied from existing policy details page code.
// Remove commented code once the uplift stories are done.
import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import Benefit from '../Benefit'

// FIXME: ts-expect-error comment can be removed when files are converted to typescript
// utils
// @ts-expect-error file not in typescript
import { orderIPBenefits } from '../../../../../utils/extendedQuoteUtils'
import {
  getAllInForceBenefits,
  sortLifeAssuredByPolicyOwner,
  isBenefitAssuredForLifeInsured,
  formatBenefits,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/policyUtils'

// constants
import {
  POLICY_CHILD_COVER_BENEFIT_CODE,
  POLICY_STATUS_NA,
} from '../../../../../constants/policies'

// selectors
import { getMasterData, getPolicies } from '../../../../../selectors/common.selectors'

// types
import {
  ClientPolicy,
  identifier,
  benefit as benefitDataType,
  benefitAssured as benefitAssuredType,
  benefitWithAssociatedBenefit,
} from '../../../../../types/ClientPolicies'
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'

type policiesStateType = {
  bancsCustomerNo: string
  data: ClientPolicy[]
}

type benefitsProps = {
  fields: fieldsType
  policyData: ClientPolicy
  onPolicyTabChange: (index: number) => void
}

const Benefits = (props: benefitsProps) => {
  const masterList = useSelector(getMasterData)
  const policiesState = useSelector(getPolicies) as policiesStateType

  const {
    fields,
    policyData: { bancsPolicyNo, policy },
    onPolicyTabChange,
  } = props
  const { status, relationships } = policy || {}
  const { bancsCustomerNo } = policiesState
  const policies = get(policiesState, 'data')
  const policyStatus = get(masterList, 'policyStatus', [])
  const currentStatus = get(
    policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
    'value',
    POLICY_STATUS_NA
  )
  const isPolicyOutOfForce = !(currentStatus.toUpperCase() === POLICY_STATUS_NA)
  const policyIdentifierMappings = (policies || [])
    .map(item => get(item, 'policy.identifiers', null) as identifier)
    .filter(Boolean)
  const benefitList = get(masterList, 'benefitList', [])
  const benefits = get(policy, 'benefits', [])
  const extractedBenefits = isPolicyOutOfForce
    ? benefits
    : (getAllInForceBenefits(benefits, masterList.policyStatus) as benefitDataType[])

  const lifeInsured = [...get(policy, 'relationships', [])]
    .sort(lifeAssured => sortLifeAssuredByPolicyOwner(lifeAssured, bancsCustomerNo))
    .filter(({ roleCode }) => roleCode === 'LA')
  const lifeAssuredOptions = lifeInsured
    .filter(({ bancsCustomerNo: bancsCustomerNoOfLA }) =>
      extractedBenefits.some(
        benefit =>
          isBenefitAssuredForLifeInsured(benefit, bancsCustomerNoOfLA) &&
          benefit.type !== POLICY_CHILD_COVER_BENEFIT_CODE
      )
    )
    .map(({ bancsCustomerNo: bancsCustomerNoOfLA, relatedParty: { firstName, lastName } }) => ({
      label: `${firstName} ${lastName}`,
      value: bancsCustomerNoOfLA,
    }))

  /* const { policyFee, policyPremium, paymentDetails, policyPremiumFrequency } = policy
  const collectionFrequency = get(paymentDetails, 'collectionFrequency', policyPremiumFrequency) */

  const hasBenefitsForChild = (benefitType: string, lifeInsuredBancsCustomerNo: string) =>
    benefitType === POLICY_CHILD_COVER_BENEFIT_CODE &&
    Boolean(
      lifeInsured.find(
        ({ bancsCustomerNo: bancsCustomerNoOfLA }) =>
          bancsCustomerNoOfLA === lifeInsuredBancsCustomerNo
      )
    )

  const finalResults = lifeAssuredOptions.map(lifeAssured => {
    const extractedBenefitBySelectedLifeAssured = extractedBenefits.filter(
      benefit =>
        isBenefitAssuredForLifeInsured(benefit, lifeAssured.value) ||
        hasBenefitsForChild(benefit.type, lifeAssured.value)
    )
    const childCoverBenefits = extractedBenefits.filter(
      benefit => benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE
    )
    const childBancsCustomerNos = childCoverBenefits
      .flatMap(benefit => benefit.benefitAssured as benefitAssuredType[])
      .map(benefitAssured => benefitAssured.bancsCustomerNo)

    const childRelatedPartyList = lifeInsured.filter(({ bancsCustomerNo: bancsCustomerNoForLa }) =>
      Boolean(
        childBancsCustomerNos.find(childCustomerNo => childCustomerNo === bancsCustomerNoForLa)
      )
    )
    const results =
      formatBenefits(
        extractedBenefitBySelectedLifeAssured,
        masterList,
        childRelatedPartyList,
        isPolicyOutOfForce,
        policyIdentifierMappings
      ) || []
    return orderIPBenefits(true, results, benefitList) as benefitWithAssociatedBenefit[]
  })

  // Displaying IP Covers together in order - IP Assure/+, Booster & Super Guarantee Benefit

  return finalResults.map(benefitsForLA =>
    benefitsForLA.map((benefitData, index) => (
      <>
        <Benefit
          benefitData={benefitData}
          relationships={relationships}
          fields={fields}
          isFirstChild={index === 0}
          isPolicyOutOfForce={isPolicyOutOfForce}
          isAssociatedBenefit={false}
          onPolicyTabChange={onPolicyTabChange}
          bancsPolicyNo={bancsPolicyNo}
          legacySystemProductCode={policy.legacySystemProductCode}
          productId={policy.productId}
        />
        {benefitData.associatedBenefits &&
          benefitData.associatedBenefits.map(associatedBenefitData => (
            <Benefit
              isAssociatedBenefit={false}
              benefitData={associatedBenefitData}
              relationships={relationships}
              fields={fields}
              isFirstChild={false}
              isPolicyOutOfForce={isPolicyOutOfForce}
              onPolicyTabChange={onPolicyTabChange}
              bancsPolicyNo={bancsPolicyNo}
              productId={policy.productId}
            />
          ))}
      </>
    ))
  )
}

export default Benefits
