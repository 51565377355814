import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontSize, colours } = Variables

const styles = {
  desc: {
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    color: colours.tertiaryOne,
  },

  container: {
    width: space(44, true),
  },

  iconEye: {
    color: '#A19C9C',
    width: space(4),
  },

  footer: {
    flex: 1,
    width: '100%',
    marginBottom: space(7, true),
  },

  nav: {
    display: 'block',
    position: 'absolute',
    fontSize: fontSize.xs,
    padding: 0,
    height: 'auto',
    svg: {
      marginRight: space(0.5, true),
      color: 'inherit',
    },
  },

  inputElement: {
    maxWidth: space(37),
    marginTop: space(6, true),
    marginBottom: space(6, true),
  },

  newPasswordInput: {
    maxWidth: space(37),
    marginTop: space(3, true),
    marginBottom: space(3, true),
  },

  notificationElement: {
    maxWidth: space(37),
  },

  inputGroupContainer: {
    marginBottom: space(6, true),
  },

  spinner: {
    minHeight: 0,
  },
}

export default styles
