// @TODO: Merge this file with ./policyUtils once refactored to TS

import { Policy } from '../types/ClientPolicies'
import { LIFE_RELATED_POLICY_BENEFITS, EXTERNAL_SUPER_PRODUCT_CLASS } from '../constants/policies'
import { BANCS_CUSTOMER_NUMBER_IOOF } from '../constants/bancs'

// Show the adviser's client's
// beneficiaries for LC and ADB, exclude external super and IOOF/IIML policies
export const shouldShowBeneficiaries = (policy: Policy) => {
  const benefits = policy?.benefits ?? []
  const relationships = policy?.relationships ?? []
  return (
    policy.productClass !== EXTERNAL_SUPER_PRODUCT_CLASS &&
    benefits.some(({ type }) => LIFE_RELATED_POLICY_BENEFITS.includes(type)) &&
    !relationships.find(({ bancsCustomerNo }) => bancsCustomerNo === BANCS_CUSTOMER_NUMBER_IOOF)
  )
}
