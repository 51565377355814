// @flow
import React from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// icons
import { IconDocument16 } from '../../../../atoms/Icons'

// styles
import styles from './uploadCard.styles'

const Header = styled('div')(styles.header)
const Title = styled('span')(styles.title)
const IconDocument = styled(IconDocument16)(styles.documentIcon)
const WrapText = styled('div')(styles.wrapText)
const Footer = styled('div')(styles.footer)
const FooterText = styled('p')(styles.footerText)
const Wrapper = styled('article')(styles.wrapper)

type UploadCardProps = {
  // header text for card
  headerText: string,
  // body text for card
  wrapperText: string,
  // footer button text
  footerText: string,
  // actions from redux store
  actions: Object,
  // action for opening upload document modal
  openModal: Function,
  // upload document modal data
  data: Object,
}

export class UploadCard extends React.Component<UploadCardProps> {
  openFileUploadModal = () => {
    const {
      actions: { toggleFileUploadModal },
      openModal,
      data,
    } = this.props
    toggleFileUploadModal(true)
    openModal(data)
  }

  render() {
    const { headerText, wrapperText, footerText } = this.props
    return (
      <Wrapper>
        <Header>
          <IconDocument />
          <Title>{headerText}</Title>
        </Header>
        <WrapText>{wrapperText}</WrapText>
        <Footer>
          <FooterText onClick={this.openFileUploadModal}>{footerText}</FooterText>
        </Footer>
      </Wrapper>
    )
  }
}

const mapStateToProps = ({ fileUploadInfo }) => ({
  fileUploadInfo,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadCard)
