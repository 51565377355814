import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Button, Chip, Loader } from '@mlcl-digital/mlcl-design'

import styled from '@emotion/styled'

import { CardTable, CardCell, CardDivide, Card as NewCard } from '../CardChildren'

const InnerRightWithBorder = styled('span')({
  display: 'grid',
  textAlign: 'right',
})

const StyledCardTable = styled(CardTable)({
  gridTemplateColumns: '1fr 2fr',
})

const PolicyCard = ({
  fields,
  policyType,
  subHeadingText,
  mainHeadingText,
  inforceDate,
  onFooterAction,
  paymentMethod,
  collectionFrequency,
  policyPremiumValue,
  policyBenefitAmounts,
  beneficiaries,
  policyPersonnel,
  fromApplicationReviewOrSummary,
  policyOwnerName,
  policyOwner,
  isLoading,
}) => {
  const {
    policySectionInforceDateLabel,
    policySectionViewPolicyButton,
    policySectionBenefitsHeader,
    policySectionSumInsuredHeader,
    policySectionNoBeneficiaryMessage,
    policySectionLivesInsuredHeader,
    policySectionSubHeading,
    policySectionPaymentMethod,
  } = fields

  return (
    <NewCard
      header={
        <>
          <Chip variant={policyType === 'Inside Super' ? 'default' : 'important'}>
            {policyType}
          </Chip>
          <div>
            <span>
              {policySectionSubHeading} {subHeadingText}
            </span>
          </div>
          <div>{mainHeadingText}</div>
          {inforceDate && (
            <div>{`${policySectionInforceDateLabel}: Not before ${inforceDate}`}</div>
          )}
        </>
      }
      footer={
        !fromApplicationReviewOrSummary ? (
          <>
            <Button
              variant="secondary"
              size="small"
              onClick={onFooterAction}
              disabled={!onFooterAction}
            >
              {policySectionViewPolicyButton}
            </Button>
          </>
        ) : null
      }
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <CardDivide>
            <div>
              {policySectionPaymentMethod}
              <br />
              {paymentMethod}
            </div>
            <div>
              {fromApplicationReviewOrSummary ? (
                <>
                  {policyOwner}
                  <br />
                  {policyOwnerName}
                </>
              ) : (
                <>
                  {`${collectionFrequency}`}
                  <br />
                  {policyPremiumValue}
                </>
              )}
            </div>
          </CardDivide>

          <CardTable>
            <CardCell isHeader>{policySectionBenefitsHeader}</CardCell>
            <CardCell isHeader>{policySectionSumInsuredHeader}</CardCell>
            {policyBenefitAmounts.map(({ benefitAmount, benefitName, index, instanceNo }) => (
              <Fragment key={`${benefitName}${benefitAmount}-${index}`}>
                <CardCell>
                  {`${benefitName}${
                    policyBenefitAmounts.filter(b => b.benefitName === benefitName).length > 1 &&
                    instanceNo
                      ? ` ${instanceNo}`
                      : ''
                  }`}
                </CardCell>
                <CardCell>{benefitAmount}</CardCell>
              </Fragment>
            ))}
          </CardTable>
          {fromApplicationReviewOrSummary ? (
            <CardTable>
              <CardCell>{fields.policySectionBeneficiariesLabel}</CardCell>
              <CardCell>
                {beneficiaries.length === 0 && policySectionNoBeneficiaryMessage}
                {beneficiaries.map(fullName => (
                  <InnerRightWithBorder key={fullName}>{fullName}</InnerRightWithBorder>
                ))}
              </CardCell>
            </CardTable>
          ) : (
            <StyledCardTable>
              <CardCell>{policySectionLivesInsuredHeader}</CardCell>
              <CardCell>
                {policyPersonnel.map(fullName => (
                  <div key={fullName}>{fullName}</div>
                ))}
              </CardCell>
            </StyledCardTable>
          )}
        </>
      )}
    </NewCard>
  )
}

PolicyCard.propTypes = {
  fields: PropTypes.shape({
    policySectionInforceDateLabel: PropTypes.string,
    policySectionViewPolicyButton: PropTypes.string,
    policySectionBenefitsHeader: PropTypes.string,
    policySectionSumInsuredHeader: PropTypes.string,
    policySectionNoBeneficiaryMessage: PropTypes.string,
    policySectionLivesInsuredHeader: PropTypes.string,
    policySectionPaymentMethod: PropTypes.string.isRequired,
  }).isRequired,

  policyBenefitAmounts: PropTypes.arrayOf(
    PropTypes.shape({
      benefitAmount: PropTypes.string,
      benefitName: PropTypes.string,
    })
  ).isRequired,
  paymentMethod: PropTypes.string,
  collectionFrequency: PropTypes.string.isRequired,
  policyType: PropTypes.string.isRequired,
  subHeadingText: PropTypes.string.isRequired,
  mainHeadingText: PropTypes.string.isRequired,
  inforceDate: PropTypes.string,
  onFooterAction: PropTypes.func,
  policyPremiumValue: PropTypes.string.isRequired,
  beneficiaries: PropTypes.arrayOf(PropTypes.string),
  fromApplicationReviewOrSummary: PropTypes.bool,
  policyPersonnel: PropTypes.arrayOf(PropTypes.string),
  policyOwnerName: PropTypes.string,
  policyOwner: PropTypes.string,
  isLoading: PropTypes.bool,
}

PolicyCard.defaultProps = {
  beneficiaries: [],
  policyPersonnel: [],
  inforceDate: null,
  policyOwnerName: null,
  policyOwner: null,
  isLoading: false,
  paymentMethod: '-',
  onFooterAction: null,
  fromApplicationReviewOrSummary: false,
}

export { PolicyCard }
