// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// constants.
import { GENDER, YES_NO } from '../../../../../../constants/forms'

// styles.
import styles from './customerInfo.styles'

// utils
import { getRelativeTimeDifference } from '../../../../../../utils/quoteUtils'

type CustomerInfoProps = {
  // Sitecore fields
  fields: {
    // Age label
    quoteEntityAge: 'string',
    // Gender label
    quoteEntityGender: 'string',
    // Smoker label
    quoteEntitySmoker: 'string',
  },
  // Quote collection storage.
  createQuote: {
    activeIndex: number,
    quotes: Array<{
      memberMandatories: Object,
    }>,
  },
}

const CollectionLabel = styled('span')({ ...styles.collectionLabel, ...styles.customerInfoLabel })
const CustomerInfoValue = styled('span')(styles.customerInfoValue)
const CustomerInfoRoot = styled('div')(styles.customerInfoRoot)
const CustomerInfoItem = styled('div')(styles.customerInfoItem)

const CustomerInfo = ({
  fields: { quoteEntityAge, quoteEntityGender, quoteEntitySmoker },
  createQuote,
}: CustomerInfoProps) => {
  const { activeIndex, quotes } = createQuote
  const { dateOfBirth, gender, isSmoker } = quotes[activeIndex].memberMandatories
  const genderObj = GENDER.find(translationObject => translationObject.value === gender)
  const smokerStatusObj = YES_NO.find(translationObject => translationObject.label === isSmoker)

  return (
    <CustomerInfoRoot>
      <CustomerInfoItem>
        <CustomerInfoValue>{getRelativeTimeDifference(dateOfBirth)}</CustomerInfoValue>
        <CollectionLabel>
          <Text field={quoteEntityAge} />
        </CollectionLabel>
      </CustomerInfoItem>
      <CustomerInfoItem>
        <CustomerInfoValue>{genderObj && genderObj.shortLabel}</CustomerInfoValue>
        <CollectionLabel>
          <Text field={quoteEntityGender} />
        </CollectionLabel>
      </CustomerInfoItem>
      <CustomerInfoItem>
        <CustomerInfoValue>{smokerStatusObj && smokerStatusObj.label}</CustomerInfoValue>
        <CollectionLabel>
          <Text field={quoteEntitySmoker} />
        </CollectionLabel>
      </CustomerInfoItem>
    </CustomerInfoRoot>
  )
}

export default CustomerInfo
