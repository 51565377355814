import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Notification, Loader } from '@mlcl-digital/mlcl-design'
import { Text } from '@sitecore-jss/sitecore-jss-react'
// @ts-expect-error non TS code
import { actionCreators } from '../../../../../actions'
import { SitecoreFields } from '../../welcomePage.types'
import { Store } from '../../../../../types/store'
import { createEvent } from '../../../../../utils/telemetry'

import {
  FORM_ID,
  forgottenUserNameFormSchema,
  FORGOTTEN_USERNAME_FORM_KEY,
} from './forgotUserName.schema'

// @ts-expect-error non TS code
import ReCaptcha from '../../../../atoms/ReCaptcha'

type Execute = () => Promise<string>

type Props = {
  fields: SitecoreFields
  handleConfirmation: () => void
}

type ReCaptchaButtonProps = {
  fields: SitecoreFields
  handleSubmit: (execute: Execute) => void
  isLoading: boolean
}

const ReCaptchaButton = (props: ReCaptchaButtonProps) => {
  const { fields, handleSubmit, isLoading } = props
  return (
    <ReCaptcha>
      {({ execute }: { execute: Execute }) =>
        isLoading ? (
          <Loader spinnerSize={Number(20)} borderSize={Number(2)} />
        ) : (
          <Button type="button" variant="primary" onClick={() => handleSubmit(execute)}>
            <Text field={fields.next} />
          </Button>
        )
      }
    </ReCaptcha>
  )
}

const ForgottenUserNameForm = (props: Props) => {
  const dispatch = useDispatch()

  const schema = forgottenUserNameFormSchema(props.fields)

  const form = useSelector((state: Store) => state.forms[FORM_ID])

  const { errorCode, isLoading } = useSelector((state: Store) => state.authentication)
  const { fields, handleConfirmation } = props

  useEffect(() => {
    dispatch(actionCreators.formInit(FORM_ID, schema))
  }, [])

  const handleChange = ({ value }: { value: string }) => {
    dispatch(
      actionCreators.formUpdateField(FORM_ID, FORGOTTEN_USERNAME_FORM_KEY, {
        error: false,
        value,
      })
    )
  }

  const handleForgotUsernameConfirmation = (isSuccessful: boolean) => {
    if (isSuccessful) {
      handleConfirmation()
    }

    const tagEvent = createEvent({
      GA: {
        category: 'Forgot customer number',
        action: `Next ${isSuccessful ? '(success)' : '(failure)'}`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Forgot customer number - ${isSuccessful ? '(success)' : '(failure)'}`,
        },
      },
    })
    tagEvent.end()
  }

  const handleSubmit = async (execute: Execute) => {
    const recaptchaToken = await execute()
    dispatch(actionCreators.setForgotUserNameReCaptchaToken(recaptchaToken))
    dispatch(actionCreators.formValidate(FORM_ID, schema))
    dispatch(
      actionCreators.formSubmit(FORM_ID, schema, (formFields: { forgottenUserName: string }) => {
        const { forgottenUserName } = formFields
        dispatch(
          actionCreators.oktaForgotUsername(forgottenUserName, (isSuccessful: boolean) => {
            handleForgotUsernameConfirmation(isSuccessful)
          })
        )
      })
    )
  }

  const formFields = form?.fields ?? {}

  const formError = (formFields[FORGOTTEN_USERNAME_FORM_KEY] || {})?.error?.errorMsg
  const error = formError || (errorCode && fields.E0000098?.value)

  return (
    <>
      {error && <Notification variant="error">{error}</Notification>}
      <Input
        type="text"
        htmlFor={FORGOTTEN_USERNAME_FORM_KEY}
        name={FORGOTTEN_USERNAME_FORM_KEY}
        changeHandler={handleChange}
        placeholder={fields.forgotUsernameInputLabel?.value}
        caption={fields.forgotUsernameCaption?.value}
        captionDangerousHTML
        value={
          (formFields[FORGOTTEN_USERNAME_FORM_KEY] &&
            formFields[FORGOTTEN_USERNAME_FORM_KEY].value) ||
          ''
        }
        autoComplete="on"
        required
      />
      <ReCaptchaButton
        fields={props.fields}
        isLoading={isLoading}
        handleSubmit={execute => void handleSubmit(execute)}
      />
    </>
  )
}

export default ForgottenUserNameForm
