// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { PageWrap } from '@mlcl-digital/mlcl-design'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// styles
import styles from './mainFooter.styles'
import { themeConsumer } from '../../../../../styles/ThemeContext'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { shouldRenderNativeLink } from '../../../../../utils/commonUtils'

// selectors
// @ts-expect-error file not in typescript
import { getIsAnyPolicyAltered } from '../../../../../selectors/createQuote'

// actions
import { actionCreators } from '../../../../../actions'

type singleLineTextFieldType = {
  value: string,
}
type linksShape = {
  fields: {
    href: singleLineTextFieldType,
    text: singleLineTextFieldType,
  },
}
type MainFooterProps = {
  fields: {
    footerItems: Array<{
      heading: singleLineTextFieldType,
      links: Array<linksShape>,
    }>,
    disclaimer: singleLineTextFieldType,
  },
  // page id
  pageId: string,
  hideFooterLinks: boolean,
  params: Object,
  isAnyPolicyAltered: boolean,
  actions: Object,
  isAltsQuoteDownloaded: boolean,
}

const FooterWrapper = styled('footer')(styles.wrapper)
const FooterMain = styled('div')(styles.main)
const FooterSecondary = styled('div')(styles.secondary)
const FooterLink = styled(Link)(styles.link)
const NativeLink = styled('a')(styles.link)
const FooterNav = styled('nav')(styles.nav)
const FooterNavHeading = styled('h6')(styles.navHeading)
const FooterNavGroup = styled('div')(styles.navGroup)
const FooterNavList = styled('ul')(styles.navList)
const FooterNavListItem = styled('li')(styles.navListItem)

const MainFooter = (props: MainFooterProps) => {
  const {
    fields = {},
    pageId,
    hideFooterLinks,
    params,
    isAnyPolicyAltered,
    actions,
    isAltsQuoteDownloaded,
  } = props
  const { setIsConfirmLeaveModal } = actions
  const { footerItems, disclaimer } = fields
  const handleClick = (e, href) => {
    if (params.Identifier === 'premiumCalculator' && isAnyPolicyAltered && !isAltsQuoteDownloaded) {
      e.preventDefault()
      setIsConfirmLeaveModal(true, href)
    }
  }
  return (
    <FooterWrapper data-testid="footer" id={`${pageId}-footer`}>
      {!hideFooterLinks && footerItems && footerItems.length > 0 && (
        <PageWrap>
          <FooterMain>
            <FooterNav>
              {footerItems.map(item => (
                <FooterNavGroup key={get(item, 'fields.heading.value', '')}>
                  <FooterNavHeading>
                    {renderTextField(get(item, 'fields.heading', ''))}
                  </FooterNavHeading>
                  <FooterNavList>
                    {get(item, 'fields.links', []).map(link => {
                      const href = get(link, 'fields.href.value', '')
                      const isNativeLink = shouldRenderNativeLink(href)
                      return (
                        <FooterNavListItem
                          key={`${get(item, 'fields.heading.value', '')} - ${get(
                            link,
                            'fields.text.value',
                            ''
                          )}`}
                        >
                          {isNativeLink ? (
                            <NativeLink
                              href={href}
                              target="_blank"
                              onClick={e => handleClick(e, href)}
                            >
                              {renderTextField(get(link, 'fields.text', ''))}
                            </NativeLink>
                          ) : (
                            <FooterLink to={href} onClick={e => handleClick(e, href)}>
                              {renderTextField(get(link, 'fields.text', ''))}
                            </FooterLink>
                          )}
                        </FooterNavListItem>
                      )
                    })}
                  </FooterNavList>
                </FooterNavGroup>
              ))}
            </FooterNav>
          </FooterMain>
        </PageWrap>
      )}
      {disclaimer && disclaimer.value && (
        <FooterSecondary id={`${pageId}-footer-disclaimer`} footerType={!!hideFooterLinks}>
          <PageWrap>{renderTextField(disclaimer, true)}</PageWrap>
        </FooterSecondary>
      )}
    </FooterWrapper>
  )
}

const mapStateToProps = state => ({
  isAnyPolicyAltered: getIsAnyPolicyAltered(state),
  isAltsQuoteDownloaded: state.alterations.isAltsQuoteDownloaded,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(themeConsumer(MainFooter, 'navigation'))
