// @flow
import React from 'react'

// components.
import Sidebar from '../../../organisms/Sidebar'

type SideBarRootProps = {
  // To render all labels from sitecore content editor
  fields: {},
  // Contents that sidebar is aside to.
  children: Node,
}

const SideBarRoot = (props: SideBarRootProps) => {
  const { fields, children } = props
  return <Sidebar fields={fields}>{children}</Sidebar>
}

export default SideBarRoot
