import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space } = Variables as VariablesType
const base = {
  color: colours.tertiaryOne,
}
const styles = {
  wrapper: {
    textAlign: 'center' as const,
  },
  // @ts-expect-error any
  detail: isAdvisorPortal => ({
    ...base,
    ...(!isAdvisorPortal && { marginBottom: space(3, true) }),
  }),
  value: {
    ...base,
    fontWeight: 600,
    marginBottom: space(4),
  },
  help: {
    ...base,
    marginBottom: space(4),
    '& a': {
      textDecoration: 'none',
      color: colours.mlcAquaAccessible,
    },
  },
}

export default styles
