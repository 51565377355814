// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'lodash'
import { FormBlock as Form } from '@mlcl-digital/mlcl-design'
import { actionCreators } from '../../../../../actions'
import { renderTextField } from '../../../../../utils/sitecoreUtils'

// components
import Accordion from '../../../../molecules/Accordion'
import Heading from '../../../../atoms/Heading'
import Input from '../../../../atoms/Input'
import Button from '../../../../atoms/Button'
import Select from '../../../../atoms/Select'
import ToggleGroup from '../../../../molecules/ToggleGroup'

// style
import styles from '../../applicationReview.styles'
import Renumstyles from './remuneration.styles'

// schema
import SCHEMA, { FORM_ID } from './remuneration.schema'

// icons
import { IconEmail32, IconChevronDown16, IconChevronUp16 } from '../../../../atoms/Icons'

// constants
import { YES_NO } from '../../../../../constants/forms'

// regex
import { percentage, regexTest, alphaNumeric, errorCheck } from '../../../../../utils/formUtils'

const AccordionContainer = styled('section')(styles.accordion)
const AccordionHeading = styled(Heading)(styles.accordionHeading)
const AccordionFieldset = styled('fieldset')(styles.accordionFieldset)
const AccordionOpenIcon = styled(IconChevronDown16)(styles.accordionIcon)
const AccordionCloseIcon = styled(IconChevronUp16)(styles.accordionIcon)
const AccordionMailIcon = styled(IconEmail32)(styles.accordionMailIcon)
const AccordionInput = styled(Input)(styles.accordionInput)
const ConfirmButton = styled(Button)(styles.confirmButton)
const FormSection = styled(Form)(styles.formSection)
const RenumToggleGroup = styled(ToggleGroup)(Renumstyles.renumToggleGroup)

const yesOrNo = YES_NO.reverse()

type RemunerationOptionsProps = {
  fields: Object,
  actions: Object,
  form: Object,
  advisor: Object,
}

export class RemunerationOptions extends Component<RemunerationOptionsProps> {
  constructor(props) {
    super(props)
    this.state = {
      schema: SCHEMA(props.fields),
    }
    this.accordionElement = React.createRef()
  }

  componentDidMount() {
    const {
      actions: { formInit },
      advisor: { advisorDetails },
    } = this.props
    const { schema } = this.state

    formInit(
      FORM_ID,
      schema,
      this.initializeFormData(advisorDetails.length > 0 ? advisorDetails[0].agencyCode : null)
    )
  }

  initializeFormData = agencyCode => ({
    adviserOutlet: {
      label: agencyCode,
      value: agencyCode,
    },
    splitRenumeration: 'N',
  })

  /**
   * return array of adviser object with label and value fields
   *
   * @param {object} adviser - login adviser
   */
  createAdviserOutletOptions = advisorDetails =>
    advisorDetails.map(adviser => ({
      label: adviser.agencyCode,
      value: adviser.agencyCode,
    }))

  /**
   * triggers when input fields are changed.
   *
   * @param {string} value - value of the input field
   * @param {number} name - name of the input field
   *
   */
  handleChange = ({ value, name }) => {
    const { actions } = this.props

    const { formUpdateField, formValidate } = actions
    const { schema } = this.state
    const data = {
      value,
      error: errorCheck(value, schema[name].condition, null),
    }

    switch (name) {
      case 'productionPercentageSplit':
      case 'renewalPercentageSplit':
        if (percentage(value) && value !== '') {
          break
        }
      // fall through
      case 'otherAdviserCode':
        if (!regexTest(alphaNumeric, value) && value !== '') {
          break
        }
      // fall through
      default:
        formUpdateField(FORM_ID, name, data)
        formValidate(FORM_ID, schema)
    }
  }

  /**
   * this method is called when the user clicks confirm button
   *
   * @param {string} value - value of the input field
   * @param {number} name - name of the input field
   *
   */
  handleSubmit = e => {
    e.preventDefault()
    const { schema } = this.state
    const {
      actions: { formValidate, formUpdateValidations, addCommissionSplitDetails, formInit },
      form: {
        isValid,
        fields: { splitRenumeration, adviserOutlet },
        fields,
      },
    } = this.props
    if (splitRenumeration.value === 'Y') {
      formUpdateValidations(FORM_ID, schema)
      formValidate(FORM_ID, schema)
      if (isValid) {
        addCommissionSplitDetails(fields)
        this.accordionElement.current.handleToggle('accordionRemunerationOption')
      }
    } else {
      formUpdateValidations(FORM_ID, this.validFields(schema))
      formValidate(FORM_ID, this.validFields(schema))
      this.accordionElement.current.handleToggle('accordionRemunerationOption')
      formInit(FORM_ID, schema, this.initializeFormData(adviserOutlet.value.value))
      addCommissionSplitDetails(fields)
    }
  }

  /**
   * validate only for fields displayed on the page
   *
   * @param {Object} adviser - login adviser
   */
  validFields = schema =>
    Object.keys(schema).reduce((acc, curr) => {
      if (schema[curr].validate) {
        acc[curr] = schema[curr]
      }
      return acc
    }, {})

  /**
   * return array of adviser object with label and value fields
   *
   * @param {Object} adviser - login adviser
   */
  adviserOutletOptions() {
    const {
      advisor: { advisorDetails },
    } = this.props
    return this.createAdviserOutletOptions(advisorDetails)
  }

  render() {
    const {
      fields: {
        remunerationOptionsHeading,
        adviserOutletLabel,
        otherAdviserCodeLabel,
        renumerationOptionsConfirmButtonLabel,
        otherAdviserNameLabel,
        productionPercentageSplitLabel,
        renewalPercentageSplitLabel,
        splitRenumerationLabel,
        adviserOutletPlaceholder,
      },
      form,
    } = this.props

    if (isEmpty(form)) return null

    const {
      fields: {
        otherAdviserCode,
        otherAdviserName,
        productionPercentageSplit,
        splitRenumeration,
        adviserOutlet,
        renewalPercentageSplit,
      },
    } = form

    const adviserOutletOptions = this.adviserOutletOptions()

    return (
      <AccordionContainer>
        <Accordion
          label={
            <AccordionHeading size="5">
              <AccordionMailIcon />
              {renderTextField(remunerationOptionsHeading)}
            </AccordionHeading>
          }
          openIcon={<AccordionOpenIcon />}
          closeIcon={<AccordionCloseIcon />}
          labelKey="accordionRemunerationOption"
          ref={this.accordionElement}
        >
          <AccordionFieldset data-testid="fieldset12">
            <FormSection id="remunerationOptions">
              <Select
                label={renderTextField(adviserOutletLabel)}
                name="adviserOutlet"
                id="adviserOutlet"
                changeHandler={this.handleChange}
                labelledby="adviser-outlet"
                value={adviserOutlet.value}
                placeholder={renderTextField(adviserOutletPlaceholder)}
                error={adviserOutlet.error.error}
                caption={
                  adviserOutlet.error.error ? renderTextField(adviserOutlet.error.errorMsg) : ''
                }
                options={adviserOutletOptions}
              />
              <RenumToggleGroup
                htmlFor="splitRenumeration"
                label={renderTextField(splitRenumerationLabel)}
                name="splitRenumeration"
                labelledby="client-label"
                value={splitRenumeration.value}
                options={yesOrNo}
                handleChange={this.handleChange}
                variant="circle"
              />
              {splitRenumeration.value && splitRenumeration.value.toUpperCase() === 'Y' && (
                <Fragment>
                  <AccordionInput
                    htmlFor="otherAdviserCode"
                    label={renderTextField(otherAdviserCodeLabel)}
                    type="text"
                    name="otherAdviserCode"
                    value={otherAdviserCode.value}
                    changeHandler={this.handleChange}
                    error={otherAdviserCode.error.error}
                    caption={
                      otherAdviserCode.error.error &&
                      renderTextField(otherAdviserCode.error.errorMsg)
                    }
                    data-testid="adviserCode"
                  />
                  <AccordionInput
                    htmlFor="otherAdviserName"
                    label={renderTextField(otherAdviserNameLabel)}
                    type="text"
                    name="otherAdviserName"
                    value={otherAdviserName.value}
                    changeHandler={this.handleChange}
                    error={otherAdviserName.error.error}
                    caption={
                      otherAdviserName.error.error &&
                      renderTextField(otherAdviserName.error.errorMsg)
                    }
                  />
                  <AccordionInput
                    htmlFor="productionPercentageSplit"
                    label={renderTextField(productionPercentageSplitLabel)}
                    type="number"
                    name="productionPercentageSplit"
                    value={productionPercentageSplit.value}
                    changeHandler={this.handleChange}
                    error={productionPercentageSplit.error.error}
                    caption={
                      productionPercentageSplit.error.error &&
                      renderTextField(productionPercentageSplit.error.errorMsg)
                    }
                    suffix="%"
                  />
                  <AccordionInput
                    htmlFor="renewalPercentageSplit"
                    label={renderTextField(renewalPercentageSplitLabel)}
                    type="number"
                    name="renewalPercentageSplit"
                    value={renewalPercentageSplit.value}
                    changeHandler={this.handleChange}
                    error={renewalPercentageSplit.error.error}
                    caption={
                      renewalPercentageSplit.error.error &&
                      renderTextField(renewalPercentageSplit.error.errorMsg)
                    }
                    suffix="%"
                  />
                </Fragment>
              )}
              <ConfirmButton
                type="secondary"
                data-testid="confirmOptionsButton"
                onClick={this.handleSubmit}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                data-testid="confirmButton"
              >
                {renderTextField(renumerationOptionsConfirmButtonLabel)}
              </ConfirmButton>
            </FormSection>
          </AccordionFieldset>
        </Accordion>
      </AccordionContainer>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ forms, advisor }) => ({
  form: forms[FORM_ID],
  advisor,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemunerationOptions)
