import { space, colours } from '../../../../../styles'

const panelHeight = (hasFooter, hasSecondaryAction = false) => {
  let height = space(12)

  if (hasFooter) {
    height = space(24)

    if (hasSecondaryAction) {
      height = space(30)
    }
  }

  return height
}

const styles = {
  base: (index, active, hasFooter, hasSecondaryAction, isDark, styleOverrides) => ({
    top: 0,
    width: `${100 / (index + 2)}%`,
    display: 'inline-block',
    background: isDark ? colours.darkGreen : colours.white,
    height: `calc(100vh - ${panelHeight(hasFooter, hasSecondaryAction)}px)`,
    transitionTimingFunction: 'ease',
    transitionDuration: '.5s',
    transitionProperty: 'opacity',
    opacity: active ? 1 : 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    ...styleOverrides,
  }),
}

export default styles
