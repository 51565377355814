// @flow
import {
  KNOWLEDGE_BASE_LIST_FETCH_INIT,
  KNOWLEDGE_BASE_LIST_FETCH_SUCCESS,
  KNOWLEDGE_BASE_LIST_FETCH_ERROR,
  KNOWLEDGE_BASE_LIST_FETCH_FAILURE,
  KNOWLEDGE_BASE_LOAD_MORE,
  KNOWLEDGE_BASE_FETCH_ARTICLE,
  SET_ACTIVE_ARTICLE_ID,
  SET_ACTIVE_ARTICLE_CATEGOTY_TYPE,
  SET_ACTIVE_ARTICLE_TAG,
} from '../actions/types/knowledgeBase'

export const initialState = {
  knowledgeBase: {
    articles: [],
    isFetchingArticles: false,
    hasFetchError: false,
    isLoadingMoreArticles: false,
  },
}

const knowledgeBase = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case KNOWLEDGE_BASE_LOAD_MORE: {
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          isLoadingMoreArticles: true,
          isFetchingArticles: false,
          hasFetchError: false,
        },
      }
    }

    case KNOWLEDGE_BASE_FETCH_ARTICLE: {
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          isLoadingMoreArticles: false,
          isFetchingArticles: true,
          hasFetchError: false,
        },
      }
    }

    case KNOWLEDGE_BASE_LIST_FETCH_INIT: {
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          hasFetchError: false,
        },
      }
    }

    case KNOWLEDGE_BASE_LIST_FETCH_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        knowledgeBase: {
          ...data,
          isLoadingMoreArticles: false,
          isFetchingArticles: false,
          hasFetchError: false,
        },
      }
    }

    case KNOWLEDGE_BASE_LIST_FETCH_ERROR:
    case KNOWLEDGE_BASE_LIST_FETCH_FAILURE: {
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          isLoadingMoreArticles: false,
          isFetchingArticles: false,
          hasFetchError: true,
        },
      }
    }

    case SET_ACTIVE_ARTICLE_ID: {
      const { activeArticleId } = action.payload
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          activeArticleId,
        },
      }
    }

    case SET_ACTIVE_ARTICLE_CATEGOTY_TYPE: {
      const { activeCategoryType } = action.payload
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          activeCategoryType,
        },
      }
    }

    case SET_ACTIVE_ARTICLE_TAG: {
      const { activeTag } = action.payload
      return {
        ...state,
        knowledgeBase: {
          ...state.knowledgeBase,
          activeTag,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default knowledgeBase
