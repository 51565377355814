// @flow
import { colours, space, fontSize, fontFamily } from '../../../../../styles'

const styles = {
  header: {
    paddingTop: space(8, true),
    paddingBottom: space(20, true),
    backgroundColor: colours.darkGreen,
    color: colours.white,
  },

  title: {
    color: colours.white,
  },

  subtitle: {
    color: colours.white,
  },

  backButton: {
    display: 'block',
    marginBottom: space(2),
    paddingLeft: 0,
    height: 'auto',
    fontSize: fontSize.xs,
    color: colours.white,
    svg: {
      marginRight: space(1),
      marginTop: space(-0.25, true),
      color: colours.white,
    },
    '&:hover': {
      color: colours.lightGreen,
      svg: {
        color: colours.lightGreen,
      },
    },
  },
  policySetupModal: {
    '& > div > div > h2': {
      fontSize: '24px',
    },
    '& > div > div > button > svg': {
      width: '24px',
      height: '24px',
    },
  },
  modalButton: {
    marginRight: space(2),
    fontFamily: fontFamily.sourceSans,
  },
  modalBody: {
    fontSize: '18px',
  },
  content: {
    marginTop: space(-10, true),
    marginBottom: space(5),
  },

  policies: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}

export default styles
