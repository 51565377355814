import React from 'react'
import PropTypes from 'prop-types'
import { Headers, Button } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'

import styles from './styles'

const PolicySelectableWrapper = styled('div')(styles.cardWrapper)
const ButtonCTA = styled(Button)(styles.btnCTA)
const Footer = styled('div')(styles.footer)

const headerSquareVariant = isSelected => (isSelected ? 'SquareChecked' : 'SquareUnchecked')

const CardSelectableWrapper = props => {
  const { shouldDisplayWrapper, isSelected, children, onClick } = props
  return shouldDisplayWrapper ? (
    <PolicySelectableWrapper isSelected={isSelected}>
      <Headers variant={headerSquareVariant(isSelected)} onClick={onClick} cursor="pointer" />
      {children}
      <Footer>
        <ButtonCTA
          btnActionType="button"
          dataLocator="Select policy"
          size="medium"
          variant="secondary"
          onClick={onClick}
        >
          {isSelected ? 'Deselect Policy' : 'Select Policy'}
        </ButtonCTA>
      </Footer>
    </PolicySelectableWrapper>
  ) : (
    <>{children}</>
  )
}

CardSelectableWrapper.propTypes = {
  /** Flag to determine whether to display the wrapper or not */
  shouldDisplayWrapper: PropTypes.bool,
  /** A child react component to embed within this component */
  children: PropTypes.node,
  /** Flag to indicate that the card within the wrapper is selected */
  isSelected: PropTypes.bool,
  /** Hanlder function to invoke on selecting the header or button in footer */
  onClick: PropTypes.func,
}

CardSelectableWrapper.defaultProps = {
  shouldDisplayWrapper: true,
  children: null,
  isSelected: false,
  onClick: () => {},
}

export default CardSelectableWrapper
