import { required, email, optional } from '../../../../../utils/formUtils'

export const FORM_ID = 'secondaryEmail'

const schema = (fields = {}, advisorEmail, secondaryEmail) => ({
  email: {
    condition: [
      { condition: email, errorMsg: fields.alternateEmailValidationError },
      {
        condition: value => advisorEmail && value === advisorEmail,
        errorMsg: fields.advisorEmailDuplicateError,
      },
      !secondaryEmail ? optional : '',
    ],
    disableOnChange: true,
    errorMsg: fields.alternateEmailValidationError,
    name: 'email',
  },
  firstName: {
    condition: [
      secondaryEmail
        ? { condition: required, errorMsg: fields.alternateFirstNameMandatoryValidationError }
        : {},
    ],
    errorMsg: fields.alternateFirstNameValidationError,
    name: 'firstName',
  },
})

export default schema
