// @flow
import moment from 'moment'
import get from 'lodash/get'

import {
  POLICY_STATUS_NA,
  NAVIGATOR,
  MLC_ACC,
  FUND_PAYMENT_METHODS_IOOF,
} from '../constants/policies'
import {
  POLICY_LAPSE_STATUS,
  POLICY_OUT_OF_FORCE,
  POLICY_LAPSE_TOO_LONG,
  POLICY_PAYMENT_INVALID,
} from '../constants/payments'

export const isPolicyLapseDateOverThanCertainDays = (
  benefitStatuses: Array,
  days: Number
): Boolean => {
  if (benefitStatuses) {
    if (benefitStatuses && benefitStatuses.length > 0) {
      let latestLapsedDate = benefitStatuses[0].statusDate
      benefitStatuses.forEach(benefit => {
        if (benefit.status === POLICY_LAPSE_STATUS) {
          if (moment(benefit.statusDate).isAfter(latestLapsedDate)) {
            latestLapsedDate = benefit.statusDate
          }
        }
      })

      const currentDate = moment()
      return currentDate.diff(moment(latestLapsedDate), 'days') > days
    }
  }
  return false
}

export const checkIfPaymentMethodValid = (
  paymentInstrument: Object,
  premiumPaymentMethod: String
): Boolean => {
  const sourceFundingSystem = get(paymentInstrument, 'fundDetails.sourceFundingSystem', '')
  if (
    [...FUND_PAYMENT_METHODS_IOOF, NAVIGATOR].includes(sourceFundingSystem) &&
    premiumPaymentMethod === MLC_ACC
  ) {
    return false
  }
  return true
}

export const getInvalidReason = (
  status: String,
  statuses: Array,
  isPaymentMethodValid: Boolean,
  hasPolicyLapsedOverGracePeriod: Boolean
): String => {
  // generate different invalid condition based on status and lapsed date and payment method
  let policyInvalidReason = ''
  if (status !== POLICY_LAPSE_STATUS) {
    const currentStatus = get(
      statuses.find(p => p.status.toUpperCase() === status.toUpperCase()),
      'value',
      POLICY_STATUS_NA
    )
    if (currentStatus.toUpperCase() !== POLICY_STATUS_NA) {
      policyInvalidReason = POLICY_OUT_OF_FORCE
    }
  } else if (hasPolicyLapsedOverGracePeriod) {
    policyInvalidReason = POLICY_LAPSE_TOO_LONG
  } else {
    policyInvalidReason = POLICY_LAPSE_STATUS
  }
  if (!isPaymentMethodValid) return POLICY_PAYMENT_INVALID
  return policyInvalidReason
}

export const getPaymentStatus = (
  status: String,
  hasPolicyLapsedOverGracePeriod: Boolean
): Boolean => status === POLICY_LAPSE_STATUS && !hasPolicyLapsedOverGracePeriod
