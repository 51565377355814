// @flow
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import queryString from 'query-string'
import { PageWrap, SectionHeader } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../utils/telemetry'
import history from '../../../utils/browserHistory'

// components
import SecondaryEmailNotifier from './components/SecondaryEmail'
import RenumerationOptions from './components/RemunerationOptions'
import DocumentUpload from './components/DocumentUpload'
import PolicyDetailsComponent from '../ApplicationPolicyDetails'
import Header from '../../molecules/PageHeader'
import ClientDetails from '../ApplicationSummary/components/ClientDetails'
import Modal from '../../molecules/Modal'
import WithLoader from '../../molecules/WithLoader'
import {
  POLICY_RELATIONSHIPS_LIFEASSURED,
  QUOTE_STATUS_SUBMITTED_TO_BANCS_STAGE,
  QUOTE_STATUS_APPLICATION_STAGE,
} from '../../../constants/policies'
import {
  REGISTER_MY_LINK,
  MYLINK,
  TELE,
  COMPLETE_APPLICATION,
  ADV_F2F,
} from '../../../constants/application'
import UnderWriting from '../FullUREResults'
import Button from '../../atoms/Button'

// actions.
import { actionCreators } from '../../../actions'

// style
import styles from './applicationReview.styles'
// utils.
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
import { arrayToObject } from '../../../utils/commonUtils'
import { getMissingRequirement } from '../../../utils/policyUtils'

// icons
import { IconArrowLeft16 } from '../../atoms/Icons'

const Container = styled('div')(styles.infoContainer)
const MainWrapper = styled('div')(styles.offset)
const CTAList = styled('ul')(styles.cTAList)
const StickyCTAs = styled('div')(({ isSticky }) => styles.stickyCTAs(isSticky))
const BackIcon = styled(IconArrowLeft16)(styles.iconBack)
const LinkButton = styled(Button)(styles.linkButton)
const IndividualApplicationCTA = styled('li')(styles.individualApplicationCTA)
const ModalContent = styled('div')(styles.modalContent)

type ApplicationReviewProps = {
  fields: Object,
  actions: Object,
  advisor: Object,
  quoteData: Object,
  clientId: String,
  ure: Object,
  fileUploadInfo: Object,
  lifeInsuredDetails: Object,
  // Master data from Sitecore
  masterData: Object,
}

export class ApplicationReview extends Component<ApplicationReviewProps> {
  constructor(props) {
    super(props)
    this.state = {
      stickyCTAs: false,
      showRetryModal: false,
    }
    this.handleScroll = throttle(this.handleScroll.bind(this), 10)
  }

  componentDidMount() {
    const { actions, advisor } = this.props
    const { getClientDetails } = actions
    this.getQuoteCollection()
    if (isEmpty(advisor.details)) {
      /** FIXME: This should be calling getAdvisorDetails instead.
       * Underlying component needs to connect to Advisor Details
       * store instead of Client Details store. DIGI-6159. MM
       */
      getClientDetails()
    }

    this.bodyElement = document.querySelector('body')
    this.footerElement = document.querySelector('footer')

    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.handleScroll.cancel()
  }

  backClickHandler = () => {
    const path = '/application'
    history.replace(path)
  }

  ctaClickHandler = () => {
    const path = '/application'
    history.push(path)
  }

  getQuoteCollection = () => {
    const {
      actions: { fetchQuoteCollection },
    } = this.props
    const { quoteCollectionId } = queryString.parse(history.location.search)
    if (quoteCollectionId) {
      fetchQuoteCollection(quoteCollectionId)
    }
  }

  handlePath = () => {
    const {
      actions: { saveQuote, updateQuoteStatus, updateApplicationStatus },
      quoteData,
      ure,
      fileUploadInfo,
    } = this.props
    const { quotes, activeIndex, quoteCollectionId } = quoteData
    const { policyStructure } = quotes[activeIndex]
    const {
      underwritingDetails: { underwritingMethod },
    } = quotes[activeIndex]
    const action =
      underwritingMethod && underwritingMethod === TELE ? underwritingMethod : COMPLETE_APPLICATION
    const quoteStatus = {
      actionName: underwritingMethod && underwritingMethod === MYLINK ? REGISTER_MY_LINK : action,
      quoteType:
        underwritingMethod && underwritingMethod === ADV_F2F
          ? QUOTE_STATUS_SUBMITTED_TO_BANCS_STAGE
          : QUOTE_STATUS_APPLICATION_STAGE,
      underwritingMethod: null,
    }
    const existingConsents = arrayToObject(quotes[activeIndex].consents, 'name')
    // update Missing Requirement
    const missingRequirement = getMissingRequirement(
      policyStructure,
      ure,
      fileUploadInfo,
      quoteCollectionId,
      existingConsents
    )
    this.setState({
      showRetryModal: false,
    })
    updateApplicationStatus(missingRequirement)
    // update quote status
    updateQuoteStatus(quoteStatus)
    const path = '/application/details'
    const tagEvent = createEvent({
      GA: {
        category: 'Application',
        action: 'Save(submission)',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Application - Save(submission)',
        },
      },
    })
    tagEvent.end()
    saveQuote(err => {
      if (!err) {
        history.push(path)
      } else {
        this.setState({
          showRetryModal: true,
        })
      }
    })
  }

  closeRetryModal = () => {
    this.setState({ showRetryModal: false })
  }

  handleScroll() {
    const { stickyCTAs } = this.state
    if (
      this.footerElement &&
      this.footerElement.offsetTop < window.pageYOffset + window.innerHeight
    ) {
      stickyCTAs &&
        this.setState({
          stickyCTAs: false,
        })
    } else {
      !stickyCTAs &&
        this.setState({
          stickyCTAs: true,
        })
    }
  }

  renderReviewStickyCTAs() {
    const { stickyCTAs } = this.state
    const {
      fields: { backToOverviewLinkText, submitApplicationButtonText },
    } = this.props

    return (
      <StickyCTAs isSticky={stickyCTAs}>
        <PageWrap>
          <CTAList>
            <IndividualApplicationCTA>
              <LinkButton onClick={this.ctaClickHandler} type="link">
                <BackIcon /> {renderTextField(backToOverviewLinkText)}
              </LinkButton>
              <Button onClick={this.handlePath} type="primary">
                {renderTextField(submitApplicationButtonText)}
              </Button>
            </IndividualApplicationCTA>
          </CTAList>
        </PageWrap>
      </StickyCTAs>
    )
  }

  renderRetryModal() {
    const { showRetryModal } = this.state
    const {
      fields: { retrySubmitModalHeading, retrySubmitModalContent, retrySubmitModalButtonText },
    } = this.props
    return (
      <Modal
        isOpen={showRetryModal}
        onClose={this.closeRetryModal}
        title={renderTextField(retrySubmitModalHeading)}
      >
        <ModalContent>{renderTextField(retrySubmitModalContent, true)}</ModalContent>
        <Button type="secondary" onClick={this.handlePath}>
          {renderTextField(retrySubmitModalButtonText)}
        </Button>
      </Modal>
    )
  }

  render() {
    const {
      quoteData: { activeIndex, quotes, isFetchingData },
      fields,
      advisor,
      quoteData,
      clientId,
      lifeInsuredDetails,
      masterData,
    } = this.props
    const { memberMandatories } = quoteData.quotes[activeIndex]
    // fetching underwritingMethod from quote since enquiry Id is created
    const { existingCovers, underwritingDetails: { underwritingMethod } = {} } = quotes[activeIndex]
    const coverInfo = get(existingCovers, 'consent.name')
    const existingConsents =
      quotes[activeIndex].consents && quotes[activeIndex].consents.length
        ? arrayToObject(quotes[activeIndex].consents, 'name')
        : []
    const isUnderwritingCompleted = existingConsents
      ? !!(
          existingConsents.FAST_TRACKING_MEDICAL_REQTS &&
          existingConsents.FAST_TRACKING_FOLLOWUP_INFO
        )
      : false
    const lifeInsuranceComplete = !!(
      (underwritingMethod === MYLINK || underwritingMethod === TELE) &&
      coverInfo &&
      isUnderwritingCompleted
    )
    const {
      applicationReviewPageTitle,
      applicationReviewSubTitle,
      applicationReviewPageBackLink,
      underwritingReviewErrorModalDescription,
      underwritingReviewErrorModalTitle,
    } = fields
    const {
      applicationReviewAdministrationHeader,
      policySectionHeaderText,
      policySectionSubHeaderText,
    } = reduceAuthorableFields(fields)
    return (
      <Fragment>
        <WithLoader isLoading={isFetchingData} overlay>
          <Header
            backClickHandler={this.backClickHandler}
            heading={applicationReviewPageTitle}
            subHeading={applicationReviewSubTitle}
            showBackIcon
            iconText={applicationReviewPageBackLink}
          />
          <PageWrap>
            <MainWrapper>
              <ClientDetails
                masterData={masterData}
                fields={fields}
                lifeInsuredDetails={lifeInsuredDetails}
                fromApplicationReview
                memberMandatories={memberMandatories}
              />
            </MainWrapper>
            <SectionHeader
              heading={policySectionHeaderText}
              subHeading={policySectionSubHeaderText}
            />
            <PolicyDetailsComponent
              clientId={clientId}
              fields={reduceAuthorableFields(fields)}
              fromApplicationReview
            />
            {!lifeInsuranceComplete && (
              <UnderWriting
                fields={{
                  ...fields,
                  underwriterReviewErrorModalTitle: underwritingReviewErrorModalTitle,
                  underwriterReviewErrorModalDescription: underwritingReviewErrorModalDescription,
                }}
                fromApplicationReview
              />
            )}
            <DocumentUpload fields={fields} quoteData={quoteData} />
            <SectionHeader heading={applicationReviewAdministrationHeader} />
            <Container>
              <RenumerationOptions fields={fields} />
              <SecondaryEmailNotifier fields={fields} advisor={advisor} />
            </Container>
            {this.renderReviewStickyCTAs()}
            {this.renderRetryModal()}
          </PageWrap>
        </WithLoader>
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({
  advisor,
  masterList,
  createQuote,
  clientId,
  fileUploadInfo,
  ure,
}) => {
  // TODO: Revisit this for the quote specific identifier
  let targetedQuoteIndex = createQuote.quotes.findIndex(quote => quote.type === 'DRAFT_APPLN')
  targetedQuoteIndex = targetedQuoteIndex === -1 ? createQuote.activeIndex : targetedQuoteIndex
  const relationships = get(
    createQuote,
    `quotes[${targetedQuoteIndex}].policyStructure[0].relationships`,
    []
  )
  const lifeInsuredDetails = relationships.filter(
    relationship => relationship.role.indexOf(POLICY_RELATIONSHIPS_LIFEASSURED) > -1
  )
  return {
    lifeInsuredDetails: lifeInsuredDetails.length ? lifeInsuredDetails[0] : null,
    advisor,
    quoteData: createQuote,
    clientId,
    fileUploadInfo,
    ure,
    masterData: get(masterList, 'data', []),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationReview)
