// @flow
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Image } from '@mlcl-digital/mlcl-design'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

// components.
import Slider from 'react-slick'
import Modal from '../Modal'
import Heading from '../../atoms/Heading'
import { IconPeople80 } from '../../atoms/Icons'
import Button from '../../atoms/Button'
// constants
import { CAROUSEL_SETTINGS } from '../../../constants/carousel'
// styles.
import styles from './welcomeJourneySlider.styles'
import './carousel.css'
// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import { getAdviserNo } from '../../../utils/cookieUtils'

const StyledImage = styled(Image)(styles.image)
const StyledHeading = styled(Heading)(styles.heading)
const Description = styled('p')(styles.description)
const StyledHeader = styled('div')(styles.header)
const NextBtnWrapper = styled('div')(styles.nextBtnWrapper)

type WelcomeJourneySliderProps = {
  // redux action to update user profile
  actions: {
    updateUserProfile: Function,
    reAuthorizeUser: Function,
  },
  // sitecore fields
  fields: Object<Object>,
  // Redux authentication
  authentication: {
    // loggedin user
    userId: String,
    // Loading state
    isLoading: Boolean,
  },
  activeSupportStaff: object,
}
class WelcomeJourneySlider extends PureComponent<WelcomeJourneySliderProps> {
  constructor(props: Object) {
    super(props)
    this.state = {
      activeSlide: 0,
    }
  }

  handleAfterChange = current => this.setState({ activeSlide: current + 1 })

  handleLastSlideCallback = () => {
    const {
      actions: { updateUserProfile, reAuthorizeUser },
      authentication: { userId, isLoading },
    } = this.props
    const callback = err => {
      if (!err) {
        reAuthorizeUser()
      }
    }
    // resetting flag to hide journey on next time
    // passing callback only for support staff to update token with latest info
    if (!isLoading) {
      updateUserProfile(
        {
          hasCompletedWelcomeJourney: true,
          hasSeenDigiCommsNotification: true,
        },
        userId,
        getAdviserNo() ? callback : null
      )
    }
  }

  next = () => {
    // increasing index to match count
    const { activeSlide } = this.state
    const { innerSlider } = this.slider
    if (innerSlider && innerSlider.state && innerSlider.state.slideCount === activeSlide) {
      this.slider.slickNext()
      this.handleLastSlideCallback()
    }

    this.slider.slickNext()
  }

  renderHeader = fetchSlideContentList => {
    const headerIcon = get(fetchSlideContentList, 'fields.slider-header-icon.value', '')
    const renderHeader = headerIcon.src ? (
      <StyledImage
        src={headerIcon.src}
        alt={headerIcon.alt}
        width={headerIcon.width || '80px'}
        height={headerIcon.height || '80px'}
      />
    ) : (
      <IconPeople80 />
    )
    return <StyledHeader>{renderHeader}</StyledHeader>
  }

  renderSlides = () => {
    const {
      authentication: { isLoading },
      fields,
      activeSupportStaff,
    } = this.props
    const contentList = get(fields, 'slider-content-list', [])
    // show the number zero content list only to support staff not for adviser
    const filteredContentList = isEmpty(activeSupportStaff)
      ? contentList.filter(
          listItem => listItem.fields['slider-text'].value !== 'Before we get started'
        )
      : contentList

    return filteredContentList.length
      ? filteredContentList.map((item, index) => {
          const headingText = get(item, 'fields.slider-text.value', '')
          const headingDesc = get(item, 'fields.slider-description', '')
          const ctaLabel = get(item, 'fields.slider-cta-button-text.value', '')
          const itemKey = index

          return (
            <div key={itemKey}>
              {this.renderHeader(item)}
              <StyledHeading size="2">{headingText}</StyledHeading>
              <Description>{renderTextField(headingDesc, true)}</Description>
              <NextBtnWrapper>
                <Button type="secondary" onClick={this.next} isLoading={isLoading}>
                  {ctaLabel}
                </Button>
              </NextBtnWrapper>
            </div>
          )
        })
      : null
  }

  render() {
    const { settings } = this.props
    const updatedSettings = {
      ...settings,
      ...CAROUSEL_SETTINGS,
      afterChange: this.handleAfterChange,
      dotsClass: 'slick-dots slick-dots-disabled',
      accessibility: false,
      swipe: false,
    }

    return (
      <Modal showCloseButton={false} isOpen>
        <Slider ref={c => (this.slider = c)} {...updatedSettings}>
          {this.renderSlides()}
        </Slider>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  const { supportStaff: { activeSupportStaff } = {} } = state
  return {
    activeSupportStaff,
  }
}

export default connect(mapStateToProps)(WelcomeJourneySlider)
