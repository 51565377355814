import { space, colours, fontSize, fontWeights, mediaQueries } from '../../../styles'

export default {
  main: {
    marginTop: space(10, true),
    marginBottom: space(4, true),
  },
  title: {
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
    margin: 0,
    display: 'flex',
    alignItems: 'center',

    [mediaQueries.md]: {
      fontSize: fontSize.md,
    },
  },
  consent({ disableScrollContent }) {
    let dynamicAttr = {
      maxHeight: space(60, true),
    }
    if (disableScrollContent) {
      dynamicAttr = { maxHeight: 'auto' }
    }
    return {
      color: colours.black,
      margin: `${space(4, true)} 0 0`,
      padding: `${space(4, true)} 0`,
      borderColor: colours.lightGrey,
      borderStyle: 'solid',
      borderWidth: '1px 0 0 0',
      overflowY: 'auto',

      '& h3': {
        fontSize: fontSize.sm,
        fontWeight: fontWeights.bold,
        marginBottom: space(3, true),
      },
      '& p': {
        marginBottom: space(3, true),
      },
      '& ul': {
        marginBottom: space(3, true),
      },
      ...dynamicAttr,
    }
  },
  continueSection: {
    borderTop: `1px solid ${colours.lightGrey}`,
    paddingTop: space(4, true),
  },
  errorMessage: {
    color: colours.red,

    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: space(1) / 4,
      fontWeight: fontWeights.bold,
    },
  },
}
