// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'

// redux.
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from '../../../actions'

// atoms
import Button from '../../atoms/Button'
import { IconLock32 } from '../../atoms/Icons'

// utils
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { isBrowser } from '../../../utils/browserUtils'

// constant
import { NAVIGATION_ONLY_LOGO } from '../../../constants/navigation'

// styles
import styles from './inactiveAdviser.styles'

type InactiveAdviserProps = {
  // Sitecore authorable fields.
  fields: Object,
  // Redux actions available to the component.
  actions: Object,
  // site config object
  config: Object,
}

const Background = styled('div')(styles.background)
const Wrapper = styled('section')(styles.wrapper)
const MessageWrapper = styled('div')(styles.messageWrapper)
const Description = styled('p')(styles.description)
const Heading = styled('p')(styles.heading)
const LockedIcon = styled(IconLock32)(styles.lockedIcon)

export class InactiveAdviser extends Component<InactiveAdviserProps> {
  componentDidMount() {
    const { actions } = this.props
    const { changeNavigationType } = actions
    changeNavigationType(NAVIGATION_ONLY_LOGO)
  }

  handleClick = () => {
    const { config } = this.props
    if (isBrowser()) {
      window.location = config.MLCL_WEBSITE_URL
    }
  }

  render() {
    const { fields } = this.props
    const { inactiveAdviserTitle, inactiveAdviserMessage, inactiveAdviserButtonLabel } =
      reduceAuthorableFields(fields)
    return (
      <Background>
        <Wrapper>
          <MessageWrapper>
            <LockedIcon />
            <Heading>{inactiveAdviserTitle}</Heading>
            <Description>{inactiveAdviserMessage}</Description>
            <Button type="primary" onClick={this.handleClick}>
              {inactiveAdviserButtonLabel}
            </Button>
          </MessageWrapper>
        </Wrapper>
      </Background>
    )
  }
}

export const mapStateToProps = ({ config }) => ({
  config,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(InactiveAdviser)
