// @flow
import React from 'react'
import AltsDeclarationV2Organism from '../../../organisms/Alteration/AltsDeclarationV2'

type AltsDeclarationV2Props = {
  fields: Object,
  // Params
  params: Object,
}

const AltsDeclarationV2 = ({ fields, params }: AltsDeclarationV2Props) => (
  <AltsDeclarationV2Organism fields={fields} params={params} />
)

export default AltsDeclarationV2
