/* eslint-disable no-unused-vars */
import { colours, containerUtils, fontFamily, fontSize, mediaQueries, space } from '../../../styles'

const styles = {
  container: theme => ({
    ...containerUtils.innerPaddingHorizontal,
    fontFamily: fontFamily.charlie,
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    wordBreak: 'keep-all',
    '&:hover': {
      backgroundColor: colours.offWhite,
      color: 'currentColor',
    },
  }),
  link: theme => ({
    display: 'block',
    textDecoration: 'none',
    color: 'currentColor',
    paddingTop: space(2),
    paddingBottom: space(2),
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,

    [mediaQueries.md]: {
      fontSize: fontSize.xs,
    },
  }),
  spinner: {
    minHeight: 0,
  },
}

export default styles
