// @flow
import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button, Modal } from '@mlcl-digital/mlcl-design'

// actions
import { actionCreators } from '../../../actions'

// styles
import styles from './taskUploadConfirmationModal.styles'

// utils
import { renderTextField, reduceAuthorableFields } from '../../../utils/sitecoreUtils'

// styled components
const ModalFooter = styled('div')(styles.modalFooter)
const ModalDescription = styled('div')(styles.modalDescription)

type TaskUploadConfirmationModalPropTypes = {
  fields: Object,
}

const TaskUploadConfirmationModal = ({ fields }: TaskUploadConfirmationModalPropTypes) => {
  const dispatch = useDispatch()
  const { isTaskUploadConfirmationModal } = useSelector(state => state.fileUploadInfo)
  const { ModalTitle, ModalCTA, ModalDesc } = reduceAuthorableFields(fields)

  const handleTaskUploadConfirmationModalClose = useCallback(() => {
    dispatch(actionCreators.showTaskUploadConfirmationModal(false))
  }, [])

  return (
    <Modal
      isOpen={isTaskUploadConfirmationModal}
      title={ModalTitle}
      onClose={handleTaskUploadConfirmationModalClose}
      footer={
        <ModalFooter>
          <Button variant="secondary" size="small" onClick={handleTaskUploadConfirmationModalClose}>
            {renderTextField(ModalCTA)}
          </Button>
        </ModalFooter>
      }
    >
      <ModalDescription>{renderTextField(ModalDesc, true)}</ModalDescription>
    </Modal>
  )
}

export default TaskUploadConfirmationModal
