/* eslint-disable jsx-a11y/aria-role */
// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Select, Input, Icons } from '@mlcl-digital/mlcl-design'
import get from 'lodash/get'

// styles
import styles from '../../adviserRegistration.styles'

// constants
import {
  AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_TITLE,
  AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_FIRST_NAME,
  AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_LAST_NAME,
  AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_ASIC_REP_NO,
} from '../../../../../constants/adviserRegistration'

type AuthorizedRepProps = {
  representative: {
    representativeTitle: string,
    replresentativeFirstName: string,
    representativeLastName: string,
    representativeAsicRepNo: string,
  },
  index: number,
  repId: string,
  /** Form schema containing validation rules and error messages */
  schema: Object,
  fields: { [string]: string },
  handleAuthorizedRepsChange: Function,
  handleRemoveRepresentative: Function,
  isFirstElement: Boolean,
}

export const Fieldset = styled('div')(({ withoutFlex }) => styles.fieldset(withoutFlex))
export const SelectTitle = styled(Select)(({ withoutMargin }) => styles.sectionXsm(withoutMargin))
export const FirstName = styled(Input)(({ withoutMargin }) => styles.sectionXl(withoutMargin))

const InputFullWidth = styled(Input)(styles.fullWidth)
const AuthorizedRepresentativeWrapper = styled('div')(styles.authorizedRepresentativeWrapper)
const { IconClose16 } = Icons
const RepresentativeItemDeleteIcon = styled(IconClose16)(styles.representativeDeleteItem)
const RepresentativeItemDeleteCTA = styled('div')(styles.representativeDeleteItemCTA)

const AuthorizedRep = ({
  representative,
  index,
  repId,
  schema,
  fields,
  handleAuthorizedRepsChange,
  handleRemoveRepresentative,
  isFirstElement,
}: AuthorizedRepProps) => {
  const {
    representativeTitle,
    representativeFirstName,
    representativeLastName,
    representativeAsicRepNo,
  } = representative

  const onChange = event => handleAuthorizedRepsChange(event, index)

  return (
    <Fragment>
      {!isFirstElement && <hr />}
      <RepresentativeItemDeleteCTA>
        <RepresentativeItemDeleteIcon
          role="button"
          onClick={() => handleRemoveRepresentative(index)}
        />
      </RepresentativeItemDeleteCTA>
      <AuthorizedRepresentativeWrapper>
        <Fieldset id={repId} role="fieldset">
          <SelectTitle
            withoutMargin
            label={fields.AuthorisedRepresentativesSectionTitle.value} // sitecore field
            placeholder={fields.adviserRegistrationTitlePlaceholder.value}
            name={AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_TITLE} // schema field
            id={repId}
            changeHandler={onChange}
            options={schema.representativeTitle.options}
            value={representativeTitle.value}
            error={representativeTitle.error.error}
            caption={get(representativeTitle.error, 'errorMsg', '')}
          />
          <FirstName
            label={fields.AuthorisedRepresentativesSectionFirstname.value}
            placeholder={fields.adviserRegistrationFirstNamePlaceholder.value}
            htmlFor={AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_FIRST_NAME}
            name={AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_FIRST_NAME}
            value={representativeFirstName.value}
            changeHandler={onChange}
            error={representativeFirstName.error.error}
            caption={get(representativeFirstName.error, 'errorMsg', '')}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <InputFullWidth
            label={fields.AuthorisedRepresentativesSectionLastname.value}
            placeholder={fields.adviserRegistrationLastNamePlaceholder.value}
            htmlFor={AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_LAST_NAME}
            name={AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_LAST_NAME}
            value={representativeLastName.value}
            changeHandler={onChange}
            error={representativeLastName.error.error}
            caption={get(representativeLastName.error, 'errorMsg', '')}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <InputFullWidth
            label={fields.AuthorisedRepresentativesSectionASICRepNo.value}
            placeholder={fields.adviserRegistrationAsicRepNoPlaceholder.value}
            htmlFor={AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_ASIC_REP_NO}
            name={AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_ASIC_REP_NO}
            value={representativeAsicRepNo.value}
            changeHandler={onChange}
            error={representativeAsicRepNo.error.error}
            caption={get(representativeAsicRepNo.error, 'errorMsg', '')}
          />
        </Fieldset>
      </AuthorizedRepresentativeWrapper>
    </Fragment>
  )
}

export default AuthorizedRep
