// @flow
import React, { Fragment, Component } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import { pathOr } from 'lodash/fp'
import get from 'lodash/get'
// redux
import { connect } from 'react-redux'

// icons
import { IconTick16, IconDot16 } from '../../../../atoms/Icons'

import Button from '../../../../atoms/Button'

// styles
import styles from './activity.styles'

// constants
import {
  DATE_FORMAT_HOUR_AFTER_YEAR,
  DATE_FORMAT_DAY_AND_MONTH_AFTER_HOUR,
} from '../../../../../constants/forms'
import {
  TELE,
  MYLINK,
  COMPLETE_MYLINK,
  WIP_MYLINK,
  REGISTER_MY_LINK,
  COMPLETE_APPLICATION,
  COMPLETE_TELE,
} from '../../../../../constants/application'

const ActivityContainer = styled('div')(styles.container)
const ActivityStatusContainer = styled('div')()
const ActivityStatus = styled('div')(styles.activityStatus)
const ActivityHeader = styled('h1')(styles.acticityHeader)
const ListWrapper = styled('ul')(styles.listWrapper)
const ListContent = styled('div')(styles.listContent)
const List = styled('li')(styles.list)
const ListIcon = styled('span')(styles.listIcon)
const StatusContent = styled('div')(styles.statusContent)
const StatusName = styled('div')(styles.statusName)
const StatusDecription = styled('div')(styles.statusDecription)
const IconDotGrey = styled(IconDot16)(styles.iconDotGrey)

type ActivityPropTypes = {
  // Sitecore fields
  fields: Object,
  // Redux actions
  assignBackToAdviser: Function,
  // quote collection data
  quoteData: {
    quoteCollectionId: String,
    activeIndex: string,
    quotes: Array<Object>,
    action: string,
    isTeleFromAdviser: Boolean,
  },
  // Master List
  masterList: Object,
}

export class Activity extends Component<ActivityPropTypes> {
  formatDateforActive = date =>
    date !== '' ? moment(date).format(DATE_FORMAT_HOUR_AFTER_YEAR) : ''

  formatDateforComplete = date =>
    date !== '' ? moment(date).format(DATE_FORMAT_DAY_AND_MONTH_AFTER_HOUR) : ''

  getStatusData = (code, type) => {
    const { masterList } = this.props
    let matchStatus
    const applicationStatus = pathOr({}, 'data.applicationStatus', masterList)
    if (applicationStatus && applicationStatus.length) {
      // eslint-disable-next-line array-callback-return
      applicationStatus.map(application => {
        if (application.code === code && application.type === type) {
          matchStatus = application
        }
      })
    }

    return matchStatus
  }

  renderStatusContent = (statusName, statusDescription, calculatedBookedDateTime) => (
    <StatusContent>
      <StatusName>{statusName}</StatusName>
      <StatusDecription>{statusDescription}</StatusDecription>
      {calculatedBookedDateTime ? (
        <StatusDecription>{calculatedBookedDateTime}</StatusDecription>
      ) : null}
    </StatusContent>
  )

  getActivityInfo = (action: string, isTeleFromAdviser: Boolean = false) => {
    const { fields } = this.props
    const {
      applicationStatusCompleted,
      applicationStatusSentEmail,
      applicationStatusTeleSelected,
      appStatusTeleSelectedByAdviser,
      applicationStatusOnlineSelected,
      applicationStatusCustomerCompletes,
    } = fields
    const completeApplicationStatus = [applicationStatusCompleted]
    const emailSendStatus = [applicationStatusSentEmail]
    const teleStatus = []
    const selectedOnlineStatus = [applicationStatusOnlineSelected, applicationStatusSentEmail]
    const onlineStatus = [
      applicationStatusCustomerCompletes,
      applicationStatusOnlineSelected,
      applicationStatusSentEmail,
    ]
    if (isTeleFromAdviser) {
      teleStatus.push(appStatusTeleSelectedByAdviser)
    } else {
      teleStatus.push(applicationStatusTeleSelected, applicationStatusSentEmail)
    }

    switch (action) {
      case COMPLETE_APPLICATION:
        return completeApplicationStatus
      case REGISTER_MY_LINK:
        return emailSendStatus
      case COMPLETE_TELE:
      case TELE:
        return teleStatus
      case WIP_MYLINK:
        return selectedOnlineStatus
      case COMPLETE_MYLINK:
        return onlineStatus
      default:
        return null
    }
  }

  applicationActivity = (statusName: Array<string>) => {
    if (!statusName && !statusName.length) {
      return null
    }
    return (
      statusName &&
      statusName.map(value => (
        <List>
          <ListContent>
            <ListIcon>
              <IconTick16 style={styles.tick} />
            </ListIcon>
            <StatusContent>
              <StatusName>{value}</StatusName>
            </StatusContent>
          </ListContent>
        </List>
      ))
    )
  }

  completedWorkType = (statusHistory, type) => {
    const completeStatus = this.getStatusData(statusHistory.oldStatus, type)
    const completeWorkTypeDate =
      completeStatus && statusHistory.oldStatusDateTime
        ? this.formatDateforComplete(statusHistory.oldStatusDateTime)
        : ''

    return (
      completeStatus && (
        <List>
          <ListContent>
            <ListIcon>
              <IconTick16 />
            </ListIcon>
            {this.renderStatusContent(completeStatus.displayName, completeWorkTypeDate)}
          </ListContent>
        </List>
      )
    )
  }

  showTeleActivity = () => {
    const { quoteData } = this.props
    const statusHistoryRoot = get(quoteData, 'workTypeHistory', [])

    if (statusHistoryRoot.length) {
      return [...statusHistoryRoot].reverse().map(historyItem => {
        const { type, status, statusHistory } = historyItem
        if (status) {
          const hasDone = status.toLowerCase() === 'done'
          const displayStatus = this.getStatusData(status, type)
          return (
            <Fragment>
              <List>
                <ListContent>
                  <ListIcon active={!hasDone}>
                    {hasDone ? <IconTick16 /> : <IconDotGrey />}
                  </ListIcon>
                  {this.renderStatusContent(displayStatus ? displayStatus.displayName : status)}
                </ListContent>
              </List>
              {statusHistory && statusHistory.length
                ? [...statusHistory]
                    .reverse()
                    .map(subStatusHistory => this.completedWorkType(subStatusHistory, type))
                : null}
            </Fragment>
          )
        }
        return null
      })
    }

    return null
  }

  showClientUREStatus = () => {
    const {
      assignBackToAdviser,
      quoteData: { action },
    } = this.props
    const actionMap = {
      [MYLINK]: 'Assigned to client',
      [WIP_MYLINK]: 'Assigned to client',
      [TELE]: 'Assigned to Tele-Underwriter',
      [REGISTER_MY_LINK]: 'Assigned to client',
    }
    return (
      <Fragment>
        {actionMap[action] && (
          <ActivityStatusContainer>
            <ActivityStatus>
              Status: <span>{actionMap[action]}</span>
            </ActivityStatus>
            <Button onClick={assignBackToAdviser} type="secondary">
              Assign back to me
            </Button>
          </ActivityStatusContainer>
        )}
      </Fragment>
    )
  }

  render() {
    const { quoteData, fields } = this.props
    const { applicationStatusHeading } = fields

    const { action } = quoteData
    const activityInfo = this.getActivityInfo(action, true) || []
    return (
      <Fragment>
        <ActivityContainer>
          <ListWrapper>
            <List heading>
              <ActivityHeader>{applicationStatusHeading}</ActivityHeader>
              {this.showClientUREStatus()}
            </List>

            <Fragment>
              {this.showTeleActivity()}
              {this.applicationActivity(activityInfo)}
            </Fragment>
          </ListWrapper>
        </ActivityContainer>
      </Fragment>
    )
  }
}
export const mapStateToProps = ({ createQuote, masterList, applicationSummary }, ownProps) => ({
  quoteData: ownProps.fromApplicationSummary ? applicationSummary : createQuote,
  masterList,
})

export default withRouter(connect(mapStateToProps, null)(Activity))
