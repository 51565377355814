// @flow
import React from 'react'
import { useSelector } from 'react-redux'

// components.
import WelcomePage from '../../../organisms/WelcomePage'
import LoginForm from '../../../organisms/LoginForm'

// utils
import { isFeatureEnabled } from '../../../../utils/featureToggling'

type LoginProps = {
  // Redux actions available to the component.
  actions: { [string]: Function },
  // Sitecore fields.
  fields: { [string]: string },
}

// TODO:: move the ForgotUsername into its own organism
// use the 2 Tickets  RET-24515, RET-24522 to do this change.
// remove the Login Username and password forms as well as part of this.
const ForgotUsername = (props: LoginProps) => {
  const { fields, actions } = props
  const features = useSelector(state => state.config.FEATURES)

  return isFeatureEnabled('SignInRedirect', features) ? (
    <WelcomePage
      fields={fields}
      logoHorizontal="/images/logo-desktop-black.svg"
      logoVertical="/images/logo-mobile-black.svg"
      isForgotUserNamePage
    />
  ) : (
    <LoginForm
      actions={actions}
      fields={fields}
      logoHorizontal="/images/logo-desktop-black.svg"
      logoVertical="/images/logo-mobile-black.svg"
    />
  )
}

export default ForgotUsername
