import { Variables } from '@mlcl-digital/mlcl-design'

import { containerUtils, mediaQueries } from '../../../../styles'

const { space, colours } = Variables

export default {
  wrap: {
    ...containerUtils.pageWrapper,
    ...containerUtils.innerPaddingHorizontal,
    backgroundColor: colours.tertiarySix,
    minHeight: space(54, true),
  },
  containerWrap: {
    padding: `${space(9, true)} ${space(2, true)}`,
  },
  heading: {
    marginBottom: space(10, true),
  },
  fixedHeightFrame: {
    border: `1px solid ${colours.tertiaryThree}`,
    marginTop: space(4, true),

    maxHeight: space(60, true),
    overflowY: 'auto',
  },
  declarationText: {
    padding: `${space(5, true)}`,
    marginBottom: 0,
  },
  aCTAContainer: {
    marginTop: space(8, true),
    position: 'relative',
  },
  aCTACheckbox: {
    display: 'block',
    [mediaQueries.md]: {
      display: 'inline-flex',
    },
  },
  aCTAButton: {
    marginBottom: space(4, true),
    marginTop: space(4, true),
    position: 'absolute',
    right: 0,
    width: '100%',
    [mediaQueries.md]: {
      display: 'inline-flex',
      marginBottom: 0,
      marginTop: 0,
      width: 'auto',
    },
  },
}
