// @flow
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Loader } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import get from 'lodash/get'

// hooks
import useEffectSkipMount from '../../../../../hooks/useEffectSkipMount'

// selectors
import {
  getAlterationsModalMessages,
  getInvalidAltsQuoteModalContent,
} from '../../../../../selectors/masterData'
import {
  getEscalationDate,
  getWithInPeriodEffectiveDate,
} from '../../../../../selectors/alterations'
import { getAlterations, getCreateQuote } from '../../../../../selectors/common.selectors'

// utils
import { getAlterationModalMsg } from '../../../../../utils/masterDataUtils'
import history from '../../../../../utils/browserHistory'
import { renderTextField } from '../../../../../utils/sitecoreUtils'

// actions
import { actionCreators } from '../../../../../actions'

// constants
import { ALTERATION_TYPES, VALIDATION_OUTCOME_FAILURE } from '../../../../../constants/alterations'
import {
  DECREASE_COVER_LANDING_PAGE,
  DECREASE_ALTS_QUOTE_ROUTE,
} from '../../../../../constants/routes'

// styles
import styles from './decreaseReEntry.styles'

type DecreaseReEntryProps = {
  setIsReEntryAPIsInProgress: Function,
  quoteCollectionId: string,
  fields: Object,
}

// styled components
const ModalFooter = styled('div')(styles.modalFooter)
const GoBackButton = styled(Button)(styles.goBackButton)

const DecreaseReEntry = ({
  quoteCollectionId,
  fields,
  setIsReEntryAPIsInProgress,
}: DecreaseReEntryProps) => {
  const dispatch = useDispatch()
  // Used to know api calls process is at what step
  const apiStep = useRef('')
  // to know if future dated policy details
  const futureDatedPolicy = useRef(false)
  const alterationsModalMessages = useSelector(getAlterationsModalMessages)
  const alterations = useSelector(getAlterations)
  // gets the escalation due date
  const formattedEscalationDate = useSelector(getEscalationDate('YYYY-MM-DD'))
  const [loaderContent, setLoaderContent] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isStartAgainModalOpen, setIsStartAgainModalOpen] = useState(false)
  const createQuote = useSelector(getCreateQuote)
  const invalidAltsQuoteModalContent = useSelector(getInvalidAltsQuoteModalContent)
  const withInPeriodEffectiveDate = useSelector(getWithInPeriodEffectiveDate)
  const { isFetchingData, fetchQuoteError, quoteCustomerNo } = createQuote
  const {
    isFetchingPolicies,
    isPoliciesFetchError,
    policies = [],
    isFetchingRules,
    rulesError,
    rules,
    isFetchingCalculateQuote,
    altsCalculateQuoteError,
    quoteValidation,
    isFetchingValidation,
    quoteValidationError,
    productRulesAPICount,
    productRulesFailedAPIs,
  } = alterations
  const makeApiCall = useCallback(() => {
    switch (apiStep.current) {
      case 'altsGetQuoteCollectionAPIMessage': {
        dispatch(actionCreators.getQuoteForManual(quoteCollectionId))
        setLoaderContent(
          getAlterationModalMsg(alterationsModalMessages, 'altsGetQuoteCollectionAPIMessage')
        )
        break
      }
      case 'altsGetPolicyDetailsAPIMessage': {
        dispatch(actionCreators.getAlterationPolicies(quoteCustomerNo))
        setLoaderContent(
          getAlterationModalMsg(alterationsModalMessages, 'altsGetPolicyDetailsAPIMessage')
        )
        break
      }
      case 'altsCheckEligibilityRulesMessage': {
        // make rules API call
        dispatch(actionCreators.getAlterationRules())
        setLoaderContent(
          getAlterationModalMsg(alterationsModalMessages, 'altsCheckEligibilityRulesMessage')
        )
        break
      }
      case 'altsFutureDatedGetPolicyDetailsAPIMessage': {
        dispatch(actionCreators.getAlterationPolicies(quoteCustomerNo, withInPeriodEffectiveDate))
        setLoaderContent(
          getAlterationModalMsg(
            alterationsModalMessages,
            'altsFutureDatedGetPolicyDetailsAPIMessage'
          )
        )
        break
      }
      case 'quoteValidation': {
        dispatch(actionCreators.quoteValidation())
        setLoaderContent(
          getAlterationModalMsg(alterationsModalMessages, 'altsBANCSValidationMessage')
        )
        break
      }
      case 'altsBANCSValidationMessage': {
        dispatch(actionCreators.calculateAlteredPoliciesInQuote())
        setLoaderContent(
          getAlterationModalMsg(alterationsModalMessages, 'altsBANCSValidationMessage')
        )
        break
      }
      default: {
        break
      }
    }
  }, [quoteCustomerNo, policies, formattedEscalationDate])
  useEffect(() => {
    // resetting alts product rules
    dispatch(actionCreators.resetAltsProductRules())

    // make get quote collection api call on mount
    apiStep.current = 'altsGetQuoteCollectionAPIMessage'
    makeApiCall()
  }, [])
  useEffectSkipMount(() => {
    if (!isFetchingData) {
      // when get quote api call ends
      if (fetchQuoteError) {
        // when get quote API error, show retry modal
        setIsModalOpen(true)
      } else {
        // when get quote API success, make get policy details api call
        apiStep.current = 'altsGetPolicyDetailsAPIMessage'
        makeApiCall()
      }
    }
  }, [isFetchingData])
  useEffectSkipMount(() => {
    if (!isFetchingPolicies) {
      if (isPoliciesFetchError) {
        // when error in fetching policies, show retry modal
        setIsModalOpen(true)
      } else if (futureDatedPolicy.current) {
        // if success of fetching future dated policy details,
        // refresh quote with latest policy details
        dispatch(actionCreators.refreshAltsQuote())
        // validation api call
        apiStep.current = 'quoteValidation'
        makeApiCall()
      } else {
        // if success of fetching policies

        // make product rules api call
        const uniqueProductIds = Array.from(
          new Set(policies.map(policy => policy.policy.productId))
        )
        uniqueProductIds.map(productId => dispatch(actionCreators.getProductRules({ productId })))

        // make rules api call
        apiStep.current = 'altsCheckEligibilityRulesMessage'
        makeApiCall()
      }
    }
  }, [isFetchingPolicies])
  useEffectSkipMount(() => {
    if (!isFetchingRules) {
      if (rulesError) {
        // if error in fetching rules, show retry modal
        setIsModalOpen(true)
      } else if (get(rules, 'businessData.assesment.decrease_EligibleForAdviser', 'N') === 'N') {
        // if not eligible for decrease, show invalid quote modal
        dispatch(
          actionCreators.setModalDescription({
            modalType: 'altsErrorModal',
            description: 'decreaseCoverReEntry',
          })
        )
        dispatch(actionCreators.showModal('altsErrorModal'))
      } else if (withInPeriodEffectiveDate) {
        // if within anniversary period, fetch future dated policy details
        futureDatedPolicy.current = true
        apiStep.current = 'altsFutureDatedGetPolicyDetailsAPIMessage'
        makeApiCall()
      } else {
        // on success of rules API, update quote with latest policy details
        dispatch(actionCreators.refreshAltsQuote())
        // validation api call
        apiStep.current = 'quoteValidation'
        makeApiCall()
      }
    }
  }, [isFetchingRules])
  useEffectSkipMount(() => {
    if (!isFetchingValidation) {
      // when validate quote request ends
      if (quoteValidationError) {
        // in case of error from api, show retry modal
        setIsModalOpen(true)
      } else if (get(quoteValidation, 'outcome') === VALIDATION_OUTCOME_FAILURE) {
        // when outcome of API is failure, show start again modal
        setIsStartAgainModalOpen(true)
      } else {
        // calculate quote api call
        apiStep.current = 'altsBANCSValidationMessage'
        makeApiCall()
      }
    }
  }, [isFetchingValidation, quoteValidationError, quoteValidation])
  useEffectSkipMount(() => {
    if (!isFetchingCalculateQuote) {
      // when calculate quote api request ends
      if (get(altsCalculateQuoteError, 'errorCode') === 400) {
        // don't want to show calculate error modal because will be showing start again modal
        dispatch(actionCreators.setIsAltsCalculateErrorModal(false))
        // if error from bancs(bad request)
        setIsStartAgainModalOpen(true)
      } else if (altsCalculateQuoteError) {
        // failure due to other reasons, open retry modal
        setIsModalOpen(true)
      } else if (productRulesAPICount === 0 && productRulesFailedAPIs.length === 0) {
        // set re-entry process to false and quote page will be loaded
        setIsReEntryAPIsInProgress(false)
        // removing query param to avoid api calls when page refreshed
        history.replace(DECREASE_ALTS_QUOTE_ROUTE)
      } else {
        // setting this value to know api calls required in series are done,
        // product rules API(parallel calls) is pending
        apiStep.current = 'altsPremiumCalculationDone'
      }
    }
  }, [isFetchingCalculateQuote])
  useEffectSkipMount(() => {
    // if product rules API success after alteration price API
    if (
      productRulesAPICount === 0 &&
      productRulesFailedAPIs.length === 0 &&
      apiStep.current === 'altsPremiumCalculationDone'
    ) {
      // set re-entry process to false and quote page will be loaded
      setIsReEntryAPIsInProgress(false)
      // removing query param to avoid api calls when page refreshed
      history.replace(DECREASE_ALTS_QUOTE_ROUTE)
    }
  }, [productRulesAPICount, productRulesFailedAPIs, apiStep.current])
  const handleGoBack = useCallback(() => {
    setIsModalOpen(false)
    history.goBack()
  }, [])
  const handleTryAgain = useCallback(() => {
    setIsModalOpen(false)
    if (productRulesFailedAPIs.length) {
      // API calls for failed product rules API
      productRulesFailedAPIs.forEach(productId =>
        dispatch(actionCreators.getProductRules({ productId }))
      )
      // removing failed API from list after APIs calls are done
      dispatch(actionCreators.resetAltsProductRulesFailed())
    }

    makeApiCall()
  }, [productRulesFailedAPIs])
  const handleStartAgain = useCallback(() => {
    setIsStartAgainModalOpen(false)
    // delete quote
    dispatch(actionCreators.removeQuote({ quoteId: quoteCollectionId }))
    // create fresh quote
    dispatch(actionCreators.createAltsQuote({ alterationType: ALTERATION_TYPES.DECREASE_RISK }))
    // set re-entry process to false and quote page will be loaded
    setIsReEntryAPIsInProgress(false)
    dispatch(actionCreators.setAlterationTypeSelectedByUser(ALTERATION_TYPES.DECREASE_RISK))
    // removing query param to avoid api calls when page refreshed
    history.replace(DECREASE_COVER_LANDING_PAGE)
  }, [])
  return (
    <>
      <Loader type="tab" loaderContent={loaderContent} />
      <Modal
        isOpen={isModalOpen || !!productRulesFailedAPIs.length}
        title={fields.Header}
        onClose={handleGoBack}
        footer={
          <ModalFooter>
            <GoBackButton variant="tertiary" size="small" onClick={handleGoBack}>
              {renderTextField(fields.GoBackCTA)}
            </GoBackButton>
            <Button variant="secondary" size="small" onClick={handleTryAgain}>
              {renderTextField(fields.TryAgainCTA)}
            </Button>
          </ModalFooter>
        }
      >
        <div>{renderTextField(fields.Description)}</div>
      </Modal>
      <Modal
        isOpen={isStartAgainModalOpen}
        title={invalidAltsQuoteModalContent.title}
        showCloseButton
        onClose={handleGoBack}
        footer={
          <ModalFooter>
            <GoBackButton variant="tertiary" size="small" onClick={handleGoBack}>
              {renderTextField(fields.GoBackCTA)}
            </GoBackButton>
            <Button variant="secondary" size="small" onClick={handleStartAgain}>
              {renderTextField(fields.StartAgainModalButton)}
            </Button>
          </ModalFooter>
        }
      >
        <div>{invalidAltsQuoteModalContent.message}</div>
      </Modal>
    </>
  )
}

export default DecreaseReEntry
