// @flow
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Button as ButtonComponent } from '@mlcl-digital/mlcl-design'
import BurgerMenu from '@mlcl-digital/mlcl-design/lib/base/BurgerMenu'
import { get } from 'lodash'
import { createEvent } from '../../../../../utils/telemetry'

// actions
import { actionCreators } from '../../../../../actions'

// components
import NavItem from '../../../../atoms/NavItem'
import Logo from '../../../../atoms/Logo'

// styles
import styles from './decreaseNavigation.styles'

// constants
import {
  SAVE_QUOTE_ACTION_TYPE,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
} from '../../../../../constants/alterations'

// selectors
import { makeAltsLifeInsuredNameAndPartyNo } from '../../../../../selectors/common.selectors'
import { getAltsProductRules, getIsPageActionsDisabled } from '../../../../../selectors/alterations'
import { getIsAnyPolicyAltered } from '../../../../../selectors/createQuote'

// utils.
import history from '../../../../../utils/browserHistory'
import { renderTextField } from '../../../../../utils/sitecoreUtils'

const Header = styled('header')(styles.header)
const LogoContainer = styled('div')(styles.logoContainer)
const Nav = styled('nav')(styles.nav)
const List = styled('ul')(styles.list)
const Item = styled('li')(styles.item)
const ItemLink = styled(NavItem)(styles.itemLink)
const Button = styled(ItemLink.withComponent(ButtonComponent))(styles.button)

type DecreaseNavigationPropTypes = {
  fields: Object<Object>,
}

const exitText = 'Exit to dashboard'

const DecreaseNavigation = ({ fields }: DecreaseNavigationPropTypes) => {
  const { primary } = fields
  const { logoHorizontal, logoVertical } = fields
  const dispatch = useDispatch()
  const isAnyPolicyAltered = useSelector(getIsAnyPolicyAltered)
  const { firstName, lastName } = useSelector(makeAltsLifeInsuredNameAndPartyNo)
  const quoteCollectionName = `${firstName} ${lastName}`
  const productRules = useSelector(getAltsProductRules)
  const [menuOpen, setMenuOpen] = useState(false)
  const isPageActionsDisabled = useSelector(getIsPageActionsDisabled)

  const handleSaveClick = (type = '') => {
    if (isAnyPolicyAltered) {
      dispatch(
        actionCreators.saveQuote(
          err => {
            if (err) {
              actionCreators.showSaveQuoteErrorModal(true)
            }
          },
          type,
          quoteCollectionName,
          firstName,
          lastName,
          productRules
        )
      )
    }
  }

  const handleExitClick = () => {
    if (!isAnyPolicyAltered) {
      const tagEvent = createEvent({
        GA: {
          category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
          action: 'Exit - decrease cover quote page',
        },
        Splunk: {
          attributes: {
            'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Exit - decrease cover quote page`,
          },
        },
      })
      tagEvent.end()
      history.push('/dashboard')
    } else {
      dispatch(actionCreators.showSaveAndExitModal(true))
    }
  }

  const handleOnClickMenu = () => {
    setMenuOpen(prevState => !prevState)
  }

  return (
    <Header>
      <LogoContainer>
        <Logo
          horizontalSrc={get(logoHorizontal, 'value', '')}
          verticalSrc={get(logoVertical, 'value', '')}
          alt="MLC"
        />
        <BurgerMenu isOpen={menuOpen} onClick={handleOnClickMenu} />
      </LogoContainer>

      <Nav isOpen={menuOpen}>
        <List>
          <ItemLink
            clickDisabled={!isAnyPolicyAltered || isPageActionsDisabled}
            onClick={() => handleSaveClick(SAVE_QUOTE_ACTION_TYPE.SAVE_CLICK)}
          >
            {renderTextField(get(primary[0], 'fields.text', ''))}
          </ItemLink>
          <Item>
            <Button exitButton disabled={isPageActionsDisabled} onClick={handleExitClick}>
              {exitText}
            </Button>
          </Item>
        </List>
      </Nav>
    </Header>
  )
}
export default DecreaseNavigation
