import { space, colours, fontWeights, fontSize, fontFamily } from '../../../../../styles'

const styles = {
  pannelBtnWrapper: {
    textAlign: 'center',
    margin: `${space(4, true)} 0`,

    '& button': {
      width: '90%',
    },
  },

  preAssesmentLoadingWrapper: {
    display: 'block',
    background: 'none',
    borderTop: `1px solid ${colours.darkestGrey}`,
    borderBottom: `1px solid ${colours.darkestGrey}`,
    width: '100%',
    textAlign: 'left',
    padding: `${space(3, true)} ${space(4, true)}}`,
    height: 'auto',
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.sourceSans,
  },

  quoteButton: () => ({
    '&:hover': {
      background: colours.lightGreen,
    },
  }),
}

export default styles
