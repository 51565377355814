import { colours, space } from '../../../../styles'

const styles = {
  wrapper: {
    color: colours.black,
  },
  criteriaList: {
    listStyle: 'none',
    textAlign: 'left',
    width: `calc(100% - ${space(1, true)})`,
    maxWidth: '300px',
    margin: `0 auto ${space(1, true)}`,
  },
  crossIcon: {
    color: colours.red,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      marginRight: space(0.5, true),
    },
  },
}

export default styles
