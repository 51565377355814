// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'

// components.
import Button from '../../../../atoms/Button'
import { IconClose16, IconArrowLeft16 } from '../../../../atoms/Icons'

// styles.
import styles from './header.styles'
import { themeConsumer, getThemeProp } from '../../../../../styles/ThemeContext'
import { getClassnameProp } from '../../../../../utils/stylesUtils'

type HeaderProps = {
  // Contents of sidebarHeader.
  children: Node,
  // header change with secondary layout
  secondaryHeader?: boolean,
  // A function to be fired when sidebar is closed.
  closeHandler?: Function,
  // A function to be fired when nav item is clicked.
  navHandler?: Function,
}

const Head = styled('div')(({ theme }) => styles.base(theme))
const CloseButton = styled(Button)(styles.close)
const HeaderContent = styled('div')(({ secondaryHeader }) => styles.headerContent(secondaryHeader))

const Header = (props: HeaderProps) => {
  const {
    closeHandler,
    navHandler,
    children,
    secondaryHeader,
    headerDataLocator,
    forcedClose,
    forcedBackNav,
  } = props
  const handleClose = () => {
    closeHandler()
  }

  const handleNav = () => {
    navHandler()
  }

  return (
    <Head {...getThemeProp(props)} {...getClassnameProp(props)}>
      <HeaderContent data-locator={headerDataLocator} secondaryHeader={secondaryHeader}>
        {children}
      </HeaderContent>
      {closeHandler && (
        <CloseButton
          data-testid="closeButton"
          onClick={
            (!forcedClose && secondaryHeader && navHandler) || forcedBackNav
              ? handleNav
              : handleClose
          }
          type="icon"
        >
          {(!forcedClose && secondaryHeader && navHandler) || forcedBackNav ? (
            <IconArrowLeft16 />
          ) : (
            <IconClose16 />
          )}
        </CloseButton>
      )}
    </Head>
  )
}

Header.defaultProps = {
  closeHandler: null,
  navHandler: null,
  secondaryHeader: false,
}

export default themeConsumer(Header, 'sidebar')
