import { getBancsCustomerNumber } from '../../utils/cookieUtils'
import { SITECORE_API } from '../types/sitecoreApi'
import { SITECORE_API_BASE } from '../../constants/apis'
import { CONTACT_MLC_SEND_EMAIL, CONTACT_MLC_SEND_EMAIL_INIT } from '../types/contactMLC'

export const contactMLC = data => ({
  type: SITECORE_API,
  name: CONTACT_MLC_SEND_EMAIL,
  verb: 'POST',
  data: {
    ...data,
    customerId: getBancsCustomerNumber(),
  },
  route: `${SITECORE_API_BASE}/contactbyemail`,
})

export const initailalizeContactMLC = () => ({
  type: CONTACT_MLC_SEND_EMAIL_INIT,
})
