// @flow
import React, { Fragment } from 'react'

import Header from '../../../molecules/PageHeader'

type PageContentHeaderPropTypes = {
  // page heading
  heading: {
    value: String,
  },
  // page sub heading
  subHeading: {
    value: String,
  },
}
const PageContentHeader = ({ heading, subHeading }: PageContentHeaderPropTypes) => (
  <Fragment>
    <Header heading={heading} subHeading={subHeading} type="customer" />
  </Fragment>
)

export default PageContentHeader
