// @flow
import {
  TRANSFORM_THIRD_PARTY_QUOTE_INIT,
  TRANSFORM_THIRD_PARTY_QUOTE_SUCCESS,
  TRANSFORM_THIRD_PARTY_QUOTE_ERROR,
  TRANSFORM_THIRD_PARTY_QUOTE_FAILURE,
} from '../actions/types/thirdParty'

const initialState = {
  isFetching: false,
  isDataAvailable: false,
  error: false,
}

const thirdParty = (state = initialState, action) => {
  switch (action.type) {
    case TRANSFORM_THIRD_PARTY_QUOTE_INIT: {
      return {
        ...initialState,
        isFetching: true,
      }
    }

    case TRANSFORM_THIRD_PARTY_QUOTE_SUCCESS: {
      const { isDataAvailable } = action.payload
      return {
        ...state,
        isDataAvailable: isDataAvailable || true,
        isFetching: false,
        error: false,
      }
    }

    case TRANSFORM_THIRD_PARTY_QUOTE_ERROR:
    case TRANSFORM_THIRD_PARTY_QUOTE_FAILURE: {
      const { isDataAvailable, error } = action.payload
      return {
        ...state,
        isFetching: false,
        isDataAvailable: isDataAvailable || false,
        error,
      }
    }
    default:
      return state
  }
}

export default thirdParty
