import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours } = Variables

const styles = {
  backButton: {
    padding: 0,
    marginBottom: space(3),
  },
  leftChevronIcon: {
    color: colours.tertiaryOne,
  },
}

export default styles
