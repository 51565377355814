import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import Table from '@mlcl-digital/mlcl-design/lib/base/Table'
import FilterBar from '@mlcl-digital/mlcl-design/lib/base/FilterBar'
import { ErrorState, Chip } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import moment from 'moment'

// Types
import { ClientPolicy } from '../../../../../types/ClientPolicies'
import { SitecoreField } from '../../../../../types/sitecore'

// Utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../../utils/telemetry'

// selectors
import {
  getPolicyActivities,
  PolicyActivityDataType,
} from '../../../../../selectors/clientPolicies'

// constants
import {
  POLICY_ACTIVITY_ALL,
  POLICY_ACTIVITY_OPEN,
  POLICY_ACTIVITY_CLOSED,
} from '../../../../../constants/policies'

import styles from './activities.styles'

const FilterBarContainer = styled('div')(styles.filterContainer)
const StateContainer = styled('div')(styles.stateContainer)

type Fields = {
  SearchActivityLabel: SitecoreField
  ColumnHeadingDate: SitecoreField
  ColumnHeadingDescription: SitecoreField
  ColumnHeadingStatus: SitecoreField
  NoPolicyActivityMessage: SitecoreField
  PolicyActivityAll: SitecoreField
  PolicyActivityOpen: SitecoreField
  PolicyActivityClosed: SitecoreField
}
type props = {
  policyData: ClientPolicy
  fields: Fields
}

const columns = ({
  ColumnHeadingDate = { value: '' },
  ColumnHeadingDescription = { value: '' },
  ColumnHeadingStatus = { value: '' },
  PolicyActivityOpen = { value: '' },
  PolicyActivityClosed = { value: '' },
}: Fields) => [
  {
    Header: ColumnHeadingDate.value,
    accessor: 'creationDate',
    Cell: ({ value }: { value: string }) => moment(value).format('DD MMM YYYY'),
  },
  {
    Header: ColumnHeadingDescription.value,
    accessor: 'requestType',
    disableSortBy: true,
  },
  {
    Header: ColumnHeadingStatus.value,
    accessor: 'status',
    Cell: ({ value }: { value: string }) => (
      <Chip variant={value === POLICY_ACTIVITY_CLOSED ? 'closed' : 'infoLight'}>
        {renderTextField(
          value === POLICY_ACTIVITY_CLOSED ? PolicyActivityClosed : PolicyActivityOpen
        )}
      </Chip>
    ),
  },
]

const Activities = ({ policyData, fields }: props) => {
  const {
    policy: { serviceHistory },
  } = policyData

  const [recordsToShow, setShowingRecords] = useState<PolicyActivityDataType>([])

  const [search, setSearch] = useState('')
  const [dateRange, setDateRange] = useState<{ to: Date | null; from: Date | null }>({
    to: null,
    from: null,
  })
  const [selection, setSelection] = useState('')

  const mapRecordsToState = useSelector(state => getPolicyActivities(state, serviceHistory))

  useEffect(() => {
    setShowingRecords(mapRecordsToState)
  }, [mapRecordsToState])

  useEffect(() => {
    let filteredRecords = mapRecordsToState
    if (selection) {
      if (selection !== POLICY_ACTIVITY_ALL) {
        filteredRecords = filteredRecords.filter(record => record.status === selection)
      }
    }
    if (search) {
      filteredRecords = filteredRecords.filter(record =>
        record.requestType.toLowerCase().includes(search.toLowerCase())
      )
    }
    if (dateRange.to || dateRange.from) {
      if (dateRange.to && dateRange.from) {
        filteredRecords = filteredRecords.filter(
          item =>
            moment(item.creationDate).isBetween(dateRange.from, dateRange.to) ||
            moment(item.creationDate).isSame(dateRange.from) ||
            moment(item.creationDate).isSame(dateRange.to)
        )
      } else if (dateRange.from) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.creationDate).isSameOrAfter(dateRange.from)
        )
      } else if (dateRange.to) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.creationDate).isSameOrBefore(dateRange.to)
        )
      }
    }
    if (search || dateRange.to || dateRange.from || selection) {
      const event = createEvent({
        GA: {
          category: 'Filter activities',
          action: 'View',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Filter activities',
          },
        },
      })
      event.end()
    }
    setShowingRecords(filteredRecords)
  }, [search, dateRange, selection])

  useEffect(() => {
    const event = createEvent({
      GA: {
        category: 'View activities',
        action: 'select',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'View activities',
        },
      },
    })
    event.end()
  }, [])

  const COLUMNS = columns(fields)

  return (
    <>
      {mapRecordsToState.length === 0 ? (
        <StateContainer>
          <ErrorState
            emptyState
            showBackground={false}
            message={renderTextField(fields.NoPolicyActivityMessage)}
          />
        </StateContainer>
      ) : (
        <>
          <FilterBarContainer>
            <FilterBar
              searchPlaceholder={get(fields, 'SearchActivityLabel.value', '') as string}
              onSearch={e => setSearch(e.value)}
              onDateRange={e => setDateRange(e)}
              selectOptions={[
                {
                  label: get(fields, 'PolicyActivityAll.value') as string,
                  value: POLICY_ACTIVITY_ALL,
                },
                {
                  label: get(fields, 'PolicyActivityOpen.value') as string,
                  value: POLICY_ACTIVITY_OPEN,
                },
                {
                  label: get(fields, 'PolicyActivityClosed.value') as string,
                  value: POLICY_ACTIVITY_CLOSED,
                },
              ]}
              onSelection={e => setSelection(e)}
              showClearAll
            />
          </FilterBarContainer>
          <Table
            pageSize={20}
            data={recordsToShow}
            columns={COLUMNS}
            styleOverrides={{
              cell: styles.cell,
              footer: styles.tableFooter,
              headerCell: styles.cell,
            }}
          />
        </>
      )}
    </>
  )
}

export default Activities
