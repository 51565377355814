import { space, colours, fontWeights, fontSize, mediaQueries } from '../../../../../styles'
import { responsiveCategoryTabStyle, responsiveButtonStyle } from '../../styles'

const centerAlign = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const styles = {
  categoryTabWrapper: {
    display: 'grid',
    justifyContent: 'flex-start',
    textAlign: 'center',
    position: 'relative',
    [mediaQueries.sm]: {
      marginTop: space(8),
    },
    [mediaQueries.lg]: {
      marginTop: space(10),
    },
    ...responsiveCategoryTabStyle,
  },
  categoryTab: isActive => ({
    background: isActive ? colours.white : colours.mediumGreen,
    cursor: 'pointer',
    position: 'relative',
    ...responsiveButtonStyle,
  }),
  categoryTabText: isActive => ({
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
    padding: `${space(2, true)} ${space(1, true)}`,
    color: isActive ? colours.mediumGreen : colours.white,
  }),
  moreButton: {
    background: colours.mediumGreen,
    padding: 0,
    flexDirection: 'column',
    border: `1px solid ${colours.mediumGreen}`,
    position: 'relative',
    cursor: 'pointer',
    ...centerAlign,
    ...responsiveButtonStyle,
    '&:focus:not(:disabled)': {
      outline: '1px',
      borderColor: 'transparent',
    },
  },
  moreText: {
    fontSize: space(2.5),
    color: colours.white,
    fontWeight: fontWeights.semiBold,
    margin: 0,
    padding: `${space(2, true)} ${space(1, true)}`,
  },
  icon: isActive => ({
    color: !isActive ? colours.white : colours.mediumGreen,
    position: 'absolute',
    top: '40%',
    right: '10%',
  }),
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  options: isMobileView => ({
    position: 'absolute',
    zIndex: 1,
    top: '64px',
    padding: 0,
    background: colours.mediumGreen,
    cursor: 'pointer',
    textAlign: 'center',
    width: '100%',
    ...responsiveButtonStyle,
    maxHeight: space(33),
    overflowY: isMobileView ? 'scroll' : 'auto',
  }),
  optionText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
    fontSize: fontSize.sm,
    padding: `${space(2, true)} ${space(1, true)}`,
    color: colours.white,
    fontWeight: fontWeights.semiBold,
    borderBottom: `1px solid ${colours.lightGrey}`,
  },
}

export default styles
