// @flow
import { toast } from 'react-toastify'
import {
  DATA_FEED_REGISTRATION_INIT,
  DATA_FEED_REGISTRATION_SUCCESS,
  DATA_FEED_REGISTRATION_ERROR,
  DATA_FEED_REGISTRATION_FAILURE,
  DATA_FEED_REGISTRATION_TOAST_SUCCESS,
  DATA_FEED_REGISTRATION_TOAST_ERROR,
  DATA_FEED_REGISTRATION_TOAST_ID,
  DATA_FEED_CANCELLATION_INIT,
  DATA_FEED_CANCELLATION_SUCCESS,
  DATA_FEED_CANCELLATION_ERROR,
  DATA_FEED_CANCELLATION_FAILURE,
  DATA_FEED_TERMS_AND_CONDITIONS_INIT,
  DATA_FEED_TERMS_AND_CONDITIONS_SUCCESS,
  DATA_FEED_TERMS_AND_CONDITIONS_ERROR,
  DATA_FEED_TERMS_AND_CONDITIONS_FAILURE,
  DATA_FEED_CANCELLATION_TOAST_SUCCESS,
  DATA_FEED_CANCELLATION_TOAST_ERROR,
  DATA_FEED_CANCELLATION_TOAST_ID,
} from '../actions/types/dataFeedRegistration'

export const initialState = {
  isLoading: false,
  isFetchingTandC: false,
  dataFeed: {},
}

const dataFeedRegistration = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case DATA_FEED_REGISTRATION_INIT: {
      return {
        ...state,
        dataFeed: {
          ...state.dataFeed,
        },
        isLoading: true,
        isFetchingTandC: false,
      }
    }

    case DATA_FEED_REGISTRATION_SUCCESS: {
      toast(DATA_FEED_REGISTRATION_TOAST_SUCCESS, {
        toastId: DATA_FEED_REGISTRATION_TOAST_ID,
        type: toast.TYPE.INFO,
      })
      return {
        ...state,
        dataFeed: action.payload,
        isLoading: false,
        isFetchingTandC: false,
      }
    }

    case DATA_FEED_REGISTRATION_ERROR:
    case DATA_FEED_REGISTRATION_FAILURE: {
      toast(DATA_FEED_REGISTRATION_TOAST_ERROR, {
        toastId: DATA_FEED_REGISTRATION_TOAST_ID,
        type: toast.TYPE.ERROR,
      })
      return {
        ...state,
        isLoading: false,
        isFetchingTandC: false,
        dataFeed: {},
      }
    }

    case DATA_FEED_CANCELLATION_INIT: {
      return {
        ...state,
        dataFeed: {
          ...state.dataFeed,
        },
        isFetchingTandC: false,
        isLoading: true,
      }
    }

    case DATA_FEED_CANCELLATION_SUCCESS: {
      toast(DATA_FEED_CANCELLATION_TOAST_SUCCESS, {
        toastId: DATA_FEED_CANCELLATION_TOAST_ID,
        type: toast.TYPE.INFO,
      })
      return {
        ...state,
        dataFeed: action.payload,
        isLoading: false,
        isFetchingTandC: false,
      }
    }

    case DATA_FEED_CANCELLATION_ERROR:
    case DATA_FEED_CANCELLATION_FAILURE:
    case DATA_FEED_TERMS_AND_CONDITIONS_ERROR:
    case DATA_FEED_TERMS_AND_CONDITIONS_FAILURE: {
      toast(DATA_FEED_CANCELLATION_TOAST_ERROR, {
        toastId: DATA_FEED_CANCELLATION_TOAST_ID,
        type: toast.TYPE.ERROR,
      })
      return {
        ...state,
        isLoading: false,
        isFetchingTandC: false,
        dataFeed: {},
      }
    }

    case DATA_FEED_TERMS_AND_CONDITIONS_INIT: {
      return {
        ...state,
        dataFeed: {
          ...state.dataFeed,
        },
        isFetchingTandC: true,
        isLoading: false,
      }
    }

    case DATA_FEED_TERMS_AND_CONDITIONS_SUCCESS: {
      return {
        ...state,
        dataFeed: {
          ...state.dataFeed,
        },
        isFetchingTandC: false,
        isLoading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default dataFeedRegistration
