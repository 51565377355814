// @flow
import React, { Fragment, Component, type Node } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import moment from 'moment'
import get from 'lodash/get'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import Card from '../../../../atoms/Card'
import Heading from '../../../../atoms/Heading'
import Tag from '../../../../atoms/Tag'

// utils.
import {
  getPolicyOwnerName,
  getPolicyCommencementDate,
  checkCoversBenefits,
} from '../../../../../utils/quoteUtils'
import { policyCompletionCheck } from '../../../../../utils/extendedQuoteUtils'
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'

// constants.

import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_RELATIONSHIPS_OWNER,
  SUPER_OWNER_INTERNAL,
  SUPER_OWNER_INTERNAL_TRUSTEE_NAME,
  SUPER_OWNER_IOOF,
  SUPER_OWNER_IOOF_TRUSTEE_NAME,
  SUPER_OWNER_EXTERNAL,
  WRAPPER_NAVIGATOR_ID,
  PAYMENT_HISTORY_DATE_FORMAT,
  NOT_BEFORE,
  ASAP,
  POLICY_RELATIONSHIPS_PAYER,
  POLICY_RELATIONSHIPS_SMSF,
} from '../../../../../constants/policies'
import { FORM_ID } from '../../../AddNulis/AddNulis.schema'

import { FORM_ID as SMSF_DETAILS_FORM_ID } from '../../../Smsf/components/SmsfDetails/smsfDetails.schema'
import { FORM_ID as SMSF_TRUSTEE_FORM_ID } from '../../../Smsf/components/SmsfTrustee/smsfTrustee.schema'
import { FORM_ID as ADD_SMSF_TRUSTEE_FORM_ID } from '../../../Smsf/components/AddTrustee/addTrustee.schema'

import { FORM_ID as ADD_DIRECTOR_FORM_ID } from '../../../AddPayers/components/AddDirector/addDirector.schema'

// styles.
import styles from './policySetupPolicy.styles'

// icons.
import { IconAdd32, IconEdit32, IconTick32 } from '../../../../atoms/Icons'
import DateSettingAction from '../DateSettingActions'
import { PREMIUM_PAYMENT_METHOD_READABLE_LABEL } from '../../../../../constants/customer'
import { ELIGIBLE_MLC_ACCOUNT } from '../../../../../constants/customerPaymentDetails'

// styled components.
const PolicyCard = styled(Card)(styles.card)
const PolicyName = styled(Heading)(styles.name)
const InsideSuperTag = styled(Tag)(({ colour }) => styles.tag(colour))
const Option = styled('div')(styles.option, ({ isDisabled }) => isDisabled && styles.optionDisabled)
const Label = styled('div')(styles.label, ({ isDisabled }) => isDisabled && styles.optionDisabled)
const Value = styled('div')(styles.value)
const AddIcon = styled(IconAdd32)(
  styles.icon,
  ({ active }) => active && styles.iconActive,
  ({ isDisabled }) => isDisabled && styles.optionDisabled
)
const EditIcon = styled(IconEdit32)(styles.icon, ({ active }) => active && styles.iconActive)
const Checked = styled(IconTick32)(styles.status, styles.checked)
const Unchecked = styled(IconTick32)(styles.status, styles.unchecked)
const LabelContainer = styled('div')(styles.labelContainer)

type PolicySetupPolicyProps = {
  // actions creators for policySetup
  actions: Object,
  // policy to display.
  policy: Object,
  // policy has owner or not
  hasOwner: boolean,
  // sitecore fields.
  fields: Object,
  // sidebar object
  sidebar: Object,
}

type PolicySetupPolicyState = {
  // indicates whether policy owner popover menu is active.
  isPopoverOpen: boolean,
}

class PolicySetupPolicy extends Component<PolicySetupPolicyProps, PolicySetupPolicyState> {
  constructor(props: PolicySetupPolicyProps) {
    super(props)
    this.state = {
      isPopoverOpen: false,
      isOpenCalendar: false,
      policyInstanceNo: 0,
      isInforceDateASAP: false,
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Inforce date',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Inforce date - View',
        },
      },
    })
    tagEvent.end()
  }

  componentDidMount() {
    const {
      policy,
      createQuote,
      actions: { addOwnerForSuperWrapper },
    } = this.props
    const { productId, policyInstanceNo } = policy
    const isInsideSuper = productId === POLICY_PRODUCT_CODE_SUPER
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}
    const activePolicy = policyStructure
      ? policyStructure.find(policyObject => policyObject.policyInstanceNo === policyInstanceNo)
      : ''
    const { fundPaymentMethod, superFundName } = activePolicy
    if (
      isInsideSuper &&
      ((superFundName === SUPER_OWNER_INTERNAL && fundPaymentMethod === WRAPPER_NAVIGATOR_ID) ||
        superFundName === SUPER_OWNER_IOOF)
    ) {
      addOwnerForSuperWrapper(policyInstanceNo)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actions: { deleteForms, resetAbnLookup },
      sidebar: { open },
      abnLookup: { error, isLoading },
    } = this.props

    const {
      sidebar: { open: prevIsopen },
    } = prevProps

    if (open !== prevIsopen && !open) {
      deleteForms([FORM_ID])
      if (error || isLoading) {
        resetAbnLookup()
      }
    }
  }

  openCalendar = () => {
    this.setState(prevState => ({
      isOpenCalendar: !prevState.isOpenCalendar,
    }))
  }

  updateCommencementDate = (date = null, isInforceDateASAP) => {
    const {
      actions: { updatePolicyCommencementDate },
    } = this.props
    const { policyInstanceNo } = this.state
    if (isInforceDateASAP === undefined) {
      this.setState({ isInforceDateASAP: true })
    } else {
      this.setState({ isInforceDateASAP: false })
    }
    updatePolicyCommencementDate(
      date
        ? moment(date).format(PAYMENT_HISTORY_DATE_FORMAT)
        : moment().format(PAYMENT_HISTORY_DATE_FORMAT),
      policyInstanceNo
    )
  }

  dateChange = date => {
    this.updateCommencementDate(date, false)
    this.setState(prevState => ({
      isOpenCalendar: !prevState.isOpenCalendar,
    }))
    const tagEvent = createEvent({
      GA: {
        category: 'Inforce date',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Inforce date - Edit',
        },
      },
    })
    tagEvent.end()
  }

  addPolicyOwner = (field, value, iconProps, isOwner, activePolicy = {}) => {
    const {
      fields: { inforceDateEditLabel },
      fields,
      policy,
    } = this.props
    const { policyOwnerEditLabel, policySetUpSMSF } = reduceAuthorableFields(fields)
    const { isOpenCalendar } = this.state
    const { fundPaymentMethod, superFundName } = activePolicy
    const items = [
      {
        ...ASAP,
        onClick: this.updateCommencementDate,
      },
      {
        ...NOT_BEFORE,
        onClick: this.openCalendar,
      },
    ]

    if (isOwner && superFundName === SUPER_OWNER_INTERNAL && field.value === policyOwnerEditLabel) {
      return (
        <Fragment>
          <Value>{SUPER_OWNER_INTERNAL_TRUSTEE_NAME}</Value>
          {fundPaymentMethod !== WRAPPER_NAVIGATOR_ID &&
            (policy.contributionType ? <EditIcon {...iconProps} /> : <AddIcon {...iconProps} />)}
        </Fragment>
      )
    }
    if (isOwner && superFundName === SUPER_OWNER_IOOF) {
      return (
        <Fragment>
          <Value>{SUPER_OWNER_IOOF_TRUSTEE_NAME}</Value>
        </Fragment>
      )
    }
    if (isOwner && superFundName === SUPER_OWNER_EXTERNAL) {
      const isSMSFEntity = policy.relationships.find(relationship =>
        relationship.role.includes(POLICY_RELATIONSHIPS_SMSF)
      )
      return (
        <Fragment>
          <Value>{policySetUpSMSF}</Value>
          {isSMSFEntity ? <EditIcon {...iconProps} /> : <AddIcon {...iconProps} />}
        </Fragment>
      )
    }

    return (
      <Fragment>
        {value && <Value>{value}</Value>}
        {(value &&
          (field === inforceDateEditLabel ? (
            <DateSettingAction
              value={value}
              iconProps={iconProps}
              popoverItems={items}
              isOpenCalendar={isOpenCalendar}
              onChange={this.dateChange}
            />
          ) : (
            <EditIcon {...iconProps} />
          ))) || <AddIcon {...iconProps} />}{' '}
      </Fragment>
    )
  }

  renderOption = ({
    field,
    value,
    onClick,
    isOwner = false,
    activePolicy,
    isDisabled = false,
    isCompleted,
    addLeftMargin,
  }: Object): Node => {
    const { isPopoverOpen } = this.state
    const optionProps: Object = { role: 'button', isDisabled }

    if (onClick && !isDisabled) {
      optionProps.onClick = onClick
    }
    const iconProps: Object = { isDisabled }
    if (isOwner && isPopoverOpen) {
      iconProps.active = true
    }
    return (
      <Option {...optionProps}>
        {typeof isCompleted === 'boolean' && (
          <>{isCompleted ? <Checked policyStep /> : <Unchecked policyStep />}</>
        )}
        <LabelContainer leftMargin={addLeftMargin}>
          <Label {...optionProps}>
            <Text field={field} />
          </Label>
          <Fragment>{this.addPolicyOwner(field, value, iconProps, isOwner, activePolicy)}</Fragment>
        </LabelContainer>
      </Option>
    )
  }

  opensideBarForOwner = (policyInstanceNo, productId, isInsideSuper) => {
    const {
      actions: { openSidebar, deleteForms },
      createQuote,
    } = this.props

    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}
    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : {}
    const { fundPaymentMethod, superFundName } = activePolicy

    if (isInsideSuper) {
      if (superFundName === SUPER_OWNER_INTERNAL && fundPaymentMethod !== WRAPPER_NAVIGATOR_ID) {
        openSidebar('addNulis', 0, { policyInstanceNo, productId })
      } else if (superFundName === SUPER_OWNER_EXTERNAL) {
        deleteForms([
          SMSF_DETAILS_FORM_ID,
          SMSF_TRUSTEE_FORM_ID,
          ADD_SMSF_TRUSTEE_FORM_ID,
          ADD_DIRECTOR_FORM_ID,
        ])
        openSidebar('smsf', 0, { policyInstanceNo, productId })
      }
    } else {
      openSidebar('addPolicyOwner', 0, { policyInstanceNo, productId })
    }
  }

  setPolicyInstanceNo = policyInstanceNo => {
    this.setState({
      policyInstanceNo,
    })
    const tagEvent = createEvent({
      GA: {
        category: 'Inforce date',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Inforce date - Edit',
        },
      },
    })
    tagEvent.end()
  }

  render() {
    const {
      hasOwner,
      policy,
      fields: {
        policyOwnerAddLabel,
        policyOwnerEditLabel,
        paymentDetailsAddLabel,
        inforceDateEditLabel,
        beneficiariesAddLabel,
        policySetUpInsideSuperLabel,
        policySetUpOutsideSuperLabel,
        policySetUpNumberOfBeneficaries,
      },
      createQuote,
      actions: { openSidebar },
    } = this.props
    const { isInforceDateASAP } = this.state
    const { productName, productId, policyInstanceNo, superFundName, fundPaymentMethod, covers } =
      policy
    const isInsideSuper = productId === POLICY_PRODUCT_CODE_SUPER
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}
    // @FIXME: Isn't activePolicy supplied via the policy prop?
    const activePolicy = policyStructure
      ? policyStructure.find(policyObject => policyObject.policyInstanceNo === policyInstanceNo)
      : ''

    const eligibleCovers = checkCoversBenefits(covers)

    const isOwnedBySMSF = superFundName === SUPER_OWNER_EXTERNAL

    let paymentMethod = ''
    const collectionMethod = get(policy, 'paymentInstruction.collectionMethod', '')

    if (activePolicy) {
      const payerIndex =
        activePolicy.relationships &&
        activePolicy.relationships.findIndex(relation =>
          relation.role.find(role => role.includes(POLICY_RELATIONSHIPS_PAYER))
        )

      if (payerIndex !== -1) {
        paymentMethod =
          (isInsideSuper && collectionMethod === ELIGIBLE_MLC_ACCOUNT
            ? PREMIUM_PAYMENT_METHOD_READABLE_LABEL[fundPaymentMethod]
            : PREMIUM_PAYMENT_METHOD_READABLE_LABEL[collectionMethod]) || collectionMethod
      }
    }

    const isOwnedByMLCSuper = superFundName === SUPER_OWNER_INTERNAL
    const isOwnedByIOOF = superFundName === SUPER_OWNER_IOOF
    const isWrapPaymentMethod = fundPaymentMethod === WRAPPER_NAVIGATOR_ID

    const isBeneficaryVisible = !(
      !eligibleCovers ||
      (isInsideSuper &&
        (isOwnedBySMSF || isOwnedByIOOF || (isOwnedByMLCSuper && isWrapPaymentMethod)))
    )

    const valueOfsections = {
      owner: isInsideSuper ? superFundName : getPolicyOwnerName(policy, POLICY_RELATIONSHIPS_OWNER),
      payer: paymentMethod,
      commenceDate: getPolicyCommencementDate(policy),
      beneficiary:
        policy.beneficiaries && !!policy.beneficiaries.length
          ? `${policy.beneficiaries.length} ${policySetUpNumberOfBeneficaries.value}`
          : '',
    }

    const isSMSFEntity = activePolicy.relationships.find(relationship =>
      relationship.role.includes(POLICY_RELATIONSHIPS_SMSF)
    )
    const completionStatus = policyCompletionCheck(policy)
    return (
      <PolicyCard>
        <PolicyName size="6">
          {productName}
          <InsideSuperTag colour={isInsideSuper}>
            {isInsideSuper ? policySetUpInsideSuperLabel.value : policySetUpOutsideSuperLabel.value}
          </InsideSuperTag>
          {completionStatus.every(status => status) ? <Checked /> : <Unchecked />}
        </PolicyName>
        {this.renderOption({
          field: hasOwner || isInsideSuper ? policyOwnerEditLabel : policyOwnerAddLabel,
          value: valueOfsections.owner,
          onClick: () => this.opensideBarForOwner(policyInstanceNo, productId, isInsideSuper),
          isOwner: isInsideSuper,
          showIcon: !(isOwnedByMLCSuper && isWrapPaymentMethod),
          activePolicy,
          isCompleted: completionStatus[0],
        })}
        {this.renderOption({
          field: paymentDetailsAddLabel,
          value: valueOfsections.payer,
          onClick: () => openSidebar('addPayer', 0, { policyInstanceNo, productId }),
          isDisabled: isOwnedBySMSF && !isSMSFEntity,
          isCompleted: completionStatus[1],
        })}
        {this.renderOption({
          field: inforceDateEditLabel,
          value: isInforceDateASAP ? 'ASAP' : valueOfsections.commenceDate,
          onClick: () => this.setPolicyInstanceNo(policyInstanceNo),
          isCompleted: completionStatus[2],
        })}
        {isBeneficaryVisible &&
          this.renderOption({
            field: beneficiariesAddLabel,
            onClick: () => {
              openSidebar('addBeneficiary', 0, { policyInstanceNo, productId })
            },
            value: valueOfsections.beneficiary,
            addLeftMargin: true,
          })}
      </PolicyCard>
    )
  }
}
export const mapStateToProps = ({ createQuote, sidebar, abnLookup }) => ({
  createQuote,
  sidebar,
  abnLookup,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PolicySetupPolicy)
