import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space, mediaQueriesMax } = Variables as VariablesType

export default {
  tableFooter: {
    backgroundColor: colours.tertiaryFive,
    [mediaQueriesMax.sm]: {
      justifyContent: 'center',
    },
    display: 'flex',
  },
  filterContainer: {
    paddingLeft: space(2),
    paddingRight: space(2),
    marginTop: space(2.5),
    ' .mlc__value-container': {
      height: '34px',
    },
  },
  cell: {
    '&:nth-child(2)': {
      flex: 2,
    },
  },
  stateContainer: {
    marginTop: space(4),
  },
}
