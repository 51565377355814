import { space, colours } from '../../../../../styles'

export default {
  navWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colours.black,
    backgroundColor: colours.offWhite,
    padding: '3px',
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 4,
  },
  change: {
    marginLeft: space(1),
    border: 0,
    background: 'none',
    textDecoration: 'underline',
    color: colours.black,
  },
}
