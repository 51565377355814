// @flow
import React from 'react'
import styled from '@emotion/styled'

// components.
import styles from './addNewItem.styles'
import { IconAddCircle16 } from '../../atoms/Icons'
import Button from '../../atoms/Button'

// styles.

type AddNewItemProps = {
  // Add label
  displayLabel: string,
  // Function to fire when clicked
  clickHandler: Function,
  // Is padding needed or not
  isPaddingNeeded: Boolean,
  // is button disable
  isDisable?: Boolean,
}

const Wrap = styled('div')(styles.wrap)
const Btn = styled(Button)(({ isPaddingNeeded, isDisable }) =>
  styles.button(isPaddingNeeded, isDisable)
)
const Icon = styled(IconAddCircle16)(({ isDisable }) => styles.icon(isDisable))

const AddNewItem = ({
  displayLabel,
  clickHandler,
  isPaddingNeeded,
  isDisable,
}: AddNewItemProps) => (
  <Wrap>
    <Btn
      type="tertiary"
      onClick={clickHandler}
      isPaddingNeeded={isPaddingNeeded}
      isDisable={isDisable}
    >
      <Icon isDisable={isDisable} />
      {displayLabel}
    </Btn>
  </Wrap>
)

AddNewItem.defaultProps = {
  isDisable: false,
}

export default AddNewItem
