import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Heading } from '@mlcl-digital/mlcl-design'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'
import {
  isPartyTypeOrg,
  getEmail,
  getFullName,
  getPhoneNumberWithCode,
} from '../../../../utils/contactUtils'

// types
import { TrusteePropTypes } from '../../../../types/components/PersonalAndContactDetails'

// components
import Director from './Director'

// styles
import styles from '../personalAndContactDetails.styles'

const EmailAddress = styled(LabelValuePair)(styles.emailAddress)

const Trustee = (props: TrusteePropTypes) => {
  const { fields, data } = props
  const abn = get(data, 'relatedParty.abnNumber', '') as string
  const isPartyTypeBusiness = isPartyTypeOrg(data?.relatedParty?.partyType)
  let name
  if (isPartyTypeBusiness) {
    name = get(data, 'relatedParty.businessName', '') as string
  } else {
    name = getFullName(data?.relatedParty)
  }
  const phoneNumber = getPhoneNumberWithCode(data?.relatedParty?.contactMethods?.phones)
  const emailAddress = getEmail(data?.relatedParty?.contactMethods?.emails || [])
  const directors = data?.level2
  return (
    <>
      <GridContainer>
        <LabelValuePair
          label={renderTextField(isPartyTypeBusiness ? fields.CompanyName : fields.FullName)}
          value={name}
        />
        {abn && <LabelValuePair label={renderTextField(fields.AbnNumber)} value={abn} />}
        <EmailAddress label={renderTextField(fields.EmailAddress)} value={emailAddress} />
        <LabelValuePair label={renderTextField(fields.PhoneNumber)} value={`+${phoneNumber}`} />
      </GridContainer>
      {directors?.map((director, index: number) => (
        <>
          <Heading variant="h4" size="small">
            {`${get(fields, 'DirectorDetailsTitle.value', '') as string} ${
              directors.length > 1 ? index + 1 : ''
            }`}
          </Heading>
          <Director {...props} data={director} />
        </>
      ))}
    </>
  )
}

export default Trustee
