// @flow
import { NEW_REPORT_HEADER, PREMIUM_STYLE_STEPPED, NEW_STEPPED_LABEL } from '../constants/benefit'
// check if a string value exists and that it is not an empty string.
export const maxBenefitItem = (data: Array<Object>) =>
  Math.max(...data.reduce((acc, item) => [...acc, item.benefits.length], []))

// it's generate Benefit
const benefitHeader = (maxItem: number, fields: Object) => {
  const BenefitData = []
  for (let index = 0; index < maxItem; index += 1) {
    const BenefitItem = [
      {
        label: fields.clientReportCsvHeaderBenefitItemName,
        key: `benefitItem_${index}.typeofInsurance`,
      },
      {
        label: fields.clientReportCsvHeaderBenefitStatus,
        key: `benefitItem_${index}.benefitStatus`,
      },
      {
        label: fields.clientReportCsvHeaderBenefitItemCommencementDate,
        key: `benefitItem_${index}.benefitDateofEntry`,
      },
      {
        label: fields.clientReportCsvHeaderBenefitItemExpiryDate,
        key: `benefitItem_${index}.benefitExpiryDate`,
      },
      {
        label: NEW_REPORT_HEADER,
        key: `benefitItem_${index}.benefitPremiumStructure`,
      },
      {
        label: fields.clientReportCsvHeaderBenefitItemSumInsured,
        key: `benefitItem_${index}.benefitsumAssured`,
      },
      {
        label: fields.clientReportCsvHeaderBenefitItemPremium,
        key: `benefitItem_${index}.benefitPremium`,
      },

      {
        label: fields.clientReportCsvHeaderBenefitItemWaitingPeriod,
        key: `benefitItem_${index}.waitingPeriod`,
      },
      {
        label: fields.clientReportCsvHeaderBenefitItemBenefitPeriod,
        key: `benefitItem_${index}.benefitPeriod`,
      },
      {
        label: fields.clientReportCsvHeaderBenefitItemConnectedPolicy,
        key: `benefitItem_${index}.connectedPolicy`,
      },
    ]
    BenefitData.push(...BenefitItem)
  }
  return BenefitData
}

// it's generate Header for client report CSV
export const getClientReportHeader = (maxItem: number, fields: Object) => {
  if (maxItem) {
    const header = [
      { label: fields.clientReportCsvHeaderPolicyNo, key: 'policyNo' },
      { label: fields.clientReportCsvHeaderPolicyDateofEntry, key: 'policyDateofEntry' },
      { label: fields.clientReportCsvHeaderPolicyTotalPremium, key: 'policyTotalPremium' },
      { label: fields.clientReportCsvHeaderPolicyPaymentFrequency, key: 'policyPaymentFrequency' },
      { label: fields.clientReportCsvHeaderPolicyStatus, key: 'policyStatus' },
      { label: fields.clientReportCsvHeaderProductName, key: 'insuranceType' },
      { label: fields.clientReportCsvHeaderPolicyOwnerTitle, key: 'policyOwner.title' },
      { label: fields.clientReportCsvHeaderPolicyOwnerFirstName, key: 'policyOwner.firstName' },
      { label: fields.clientReportCsvHeaderPolicyOwnerMiddleName, key: 'policyOwner.middleName' },
      { label: fields.clientReportCsvHeaderPolicyOwnerSurName, key: 'policyOwner.surName' },
      {
        label: fields.clientReportCsvHeaderPolicyOwnerCustomerNumber,
        key: 'policyOwner.bancsExternalCustomerNumber',
      },
      { label: fields.clientReportCsvHeaderPolicyOwnerAddressLine, key: 'policyOwner.addressLine' },
      { label: fields.clientReportCsvHeaderPolicyOwnerSuburb, key: 'policyOwner.suburb' },
      { label: fields.clientReportCsvHeaderPolicyOwnerState, key: 'policyOwner.state' },
      { label: fields.clientReportCsvHeaderPolicyOwnerPostCode, key: 'policyOwner.postCode' },
      { label: fields.clientReportCsvHeaderPolicyOwnerPhoneNumber, key: 'policyOwner.phoneNumber' },
      { label: fields.clientReportCsvHeaderPolicyOwnerEmail, key: 'policyOwner.email' },
      { label: fields.clientReportCsvHeaderPolicyOwnerIsPrimary, key: 'policyOwner.isPrimary' },
      { label: fields.clientReportCsvHeaderLifeInsuredTitle, key: 'lifeInsured.title' },
      { label: fields.clientReportCsvHeaderLifeInsuredFirstName, key: 'lifeInsured.firstName' },
      { label: fields.clientReportCsvHeaderLifeInsuredSurName, key: 'lifeInsured.surName' },
      { label: fields.clientReportCsvHeaderLifeInsuredAddressLine, key: 'lifeInsured.addressLine' },
      { label: fields.clientReportCsvHeaderLifeInsuredSuburb, key: 'lifeInsured.suburb' },
      { label: fields.clientReportCsvHeaderLifeInsuredState, key: 'lifeInsured.state' },
      { label: fields.clientReportCsvHeaderLifeInsuredPostCode, key: 'lifeInsured.postCode' },
      { label: fields.clientReportCsvHeaderLifeInsuredPhoneNumber, key: 'lifeInsured.phoneNumber' },
      { label: fields.clientReportCsvHeaderLifeInsuredEmail, key: 'lifeInsured.email' },
      { label: fields.clientReportCsvHeaderLifeInsuredDateOfBirth, key: 'lifeInsured.dateOfBirth' },
      { label: fields.clientReportCsvHeaderLifeInsuredGender, key: 'lifeInsured.gender' },
      {
        label: fields.clientReportCsvHeaderLifeInsuredSmokingStatus,
        key: 'lifeInsured.smokingStatus',
      },
      {
        label: fields.clientReportCsvHeaderPolicyOwnerSpecialRiskStatus,
        key: 'lifeInsured.specialRisk',
      },
      ...benefitHeader(maxItem, fields),
    ]
    return header
  }
  return []
}

// it's re-structured the reporting data
export const getFormatClientReportData = (data: Array<Object>, isCaliRelabel: boolean = false) => {
  if (data && data.length) {
    return data.map(element => {
      const benefitItem = element.benefits.reduce((acc, item, index) => {
        if (
          isCaliRelabel &&
          element?.insuranceType?.includes('MLC Insurance') &&
          item?.benefitPremiumStructure === PREMIUM_STYLE_STEPPED
        ) {
          item.benefitPremiumStructure = NEW_STEPPED_LABEL
        }
        return {
          ...acc,
          [`benefitItem_${index}`]: { ...item },
        }
      }, {})
      return {
        ...element,
        ...benefitItem,
      }
    })
  }
  return []
}
