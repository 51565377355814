import { space, colours, fontWeights } from '../../../../../styles'

const styles = {
  textBox: {
    fontSize: space(2),
    color: colours.darkGrey,
    fontWeight: fontWeights.semiBold,
    margin: 0,
    flex: '1',
    paddingRight: space(2),
  },
  button: {
    padding: space(2),
    flex: `0 0 ${space(17, true)}`,
  },
  ctaButton: {
    justifyContent: 'flex-end',
    padding: `${space(2, true)} ${space(3, true)}`,
    lineHeight: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    background: colours.lightestGreen,
    alignItems: 'center',
    padding: space(4),
    justifyContent: 'space-between',
    marginBottom: space(4),
    border: `1px solid ${colours.lighterGreen}`,
  },
}
export default styles
