// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { space } = Variables

const styles = {
  modalFooter: {
    textAlign: 'right',
  },
  cancelButton: {
    marginRight: space(1),
  },
}

export default styles
