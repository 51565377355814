// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { components } from 'react-select'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import { Loader, Notification, Modal, Button } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../../utils/telemetry'

// components.
import Heading from '../../../../atoms/Heading'
import Dropzone from '../Dropzone'
import FileUploadingProgress from '../FileUploadingProgress'
import FileDownload, { IconDocument } from '../FileDownload'
import Select from '../../../../atoms/Select'

// utils.
import {
  checkMimeType,
  convertByteFileSizeToKB,
  checkFileValidationError,
  generateFileKey,
  checkIsUploadProgress,
} from '../../../../../utils/fileUpload'

// styles.
import styles from './fileUpload.styles'
import { NULL_BYTE, INVALID_FILE_NAME } from '../../../../../constants/documentTypes'

const SubHeading = styled(Heading)(styles.subHeading)
const ConfirmBtn = styled(Button)(styles.button)
const NotificationWrapper = styled(Notification)(styles.notification)
const DropDownValueContainer = styled.div(styles.dropDownValueContainer)
const Icon = styled(IconDocument)(styles.iconDocument)
const Textarea = styled('textarea')(({ value }) => styles.textarea(value))
const TextareaWrap = styled('div')(styles.textareaWrap)

type FileUploadProps = {
  /** Redux actions */
  actions: {
    // create work item action
    createWorkItem: Function,
  },
  /** file redux state */
  fileUploadInfo: {
    workItemInfo: Array<Object>,
    files: Array<Object>,
    fileListUploaded: boolean,
  },
  /** dropzone componenet meta fields */
  dropzoneMeta?: {
    maxFileNumber: Number,
    maxFileSize: Number, // in KB
  },
  /** Form download meta labels */
  formDownloadMeta: {
    docSubTypeCode: string,
  },
  /** Form uploading progress component meta */
  fileUploadMeta: {
    maxFileSizeError: string,
    wrongFileType: string,
  },
  /** File upload modal component meta */
  modalMeta: {
    modalHeading: string,
    modalSubHeading: string,
    modalConfirmButton: string,
  },
  /** request data to be called with upload document api */
  uploadDocumentRequest: Object<Object>,
  /** request data to be called with createWorkItem api */
  createWorkItemRequest: Object<Object>,
  /** flag to trigger work item on component mount */
  workItemRequired?: Boolean,
  // is create work item needs to be called on component mount
  isCreateWorkItemDisabled?: Boolean,
  // flag to determin whether show notification for task upload
  isTaskUpload?: Boolean,
  /** dropdown data for doc type */
  dropDownData?: Array,
  // action to change dropdown state
  dropDownChange: Function,
  // dropdown data
  dropDown: Object,
  // flag to trigger file upload save action
  saveFileUpload?: Boolean,
  // uploading document data
  document: Object,
  // is free text box needed or not
  isFreeTextBox?: Boolean,
  // free text api call loading props
  isFreeTextFetching?: Boolean,
  // free text box placeholder
  freeTextRequirementPlaceholder?: string,
  // handle free text response
  handleFreeTextSubmit?: Function,
  // handle the change of file type while uploading
  handleChangeFileType?: Function,
  // agreementReqId to be removed once upload is successful
  agreementReqId?: string,
  // only show the download option if document is available
  showOnlyDownloadOption?: Boolean,
  // is multiple file upload is required or not
  disableMultipleFilesUpload?: Boolean,
  // analytics data
  analytics?: Object,
  // analytics events data for download button click.
  analyticsForDownload?: Object,
}

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <Icon />
    <DropDownValueContainer>{children}</DropDownValueContainer>
  </components.ValueContainer>
)
const DragAndDropWrapper = styled('span')({ display: 'none' })
export class File extends Component<FileUploadProps> {
  constructor(props) {
    super(props)
    this.state = {
      showNoFilesUploadedError: false,
      freeTextValue: '',
      showNoSelectedWorkSubTypeCode: false,
    }
    this.fileInputRef = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    const {
      actions: { resetFilesData },
      fileUploadInfo,
      fileUploadMeta: { createWorkItemSuccess },
      isTaskUpload,
    } = this.props
    if (nextProps.fileUploadInfo.fileListUploaded && !fileUploadInfo.fileListUploaded) {
      resetFilesData()
      if (!isTaskUpload) toast.success(createWorkItemSuccess)
    }
  }

  closeModal = () => {
    const {
      actions: { toggleFileUploadModal, resetFilesData },
    } = this.props
    resetFilesData()
    toggleFileUploadModal(false)
    this.setState({ freeTextValue: '' })
  }

  handleModalConfirm = () => {
    const {
      fileUploadInfo: { files },
      actions: { uploadFileWithWorkItem },
      uploadDocumentRequest,
      createWorkItemRequest,
      saveFileUpload,
      document,
      handleFreeTextSubmit,
      agreementReqId,
      isFreeTextBox,
      dropDownData,
      workItemRequired,
      isCreateWorkItemDisabled,
      analytics,
      isTaskUpload,
    } = this.props
    const { freeTextValue } = this.state
    const { docSubTypeCode } = uploadDocumentRequest

    // Analytics
    if (analytics) {
      const event = createEvent({
        GA: analytics,
        Splunk: {
          attributes: {
            'workflow.name': 'handle file upload modal confirm',
          },
        },
      })
      event.end()
    }

    if (dropDownData && !docSubTypeCode) {
      this.setState({ showNoSelectedWorkSubTypeCode: true })
      return
    }
    if (freeTextValue !== '' && handleFreeTextSubmit !== null) {
      handleFreeTextSubmit(freeTextValue, () => {
        this.setState({ freeTextValue: '' })
      })
    }

    if (freeTextValue !== '' || files.length) {
      const uploadedDocument = Array.isArray(document)
        ? document.find(doc => doc.documentCode === uploadDocumentRequest.docSubTypeCode)
        : document
      // upload files in parllel after creating work item
      uploadFileWithWorkItem({
        files,
        createWorkItemRequest,
        agreementReqId,
        uploadDocumentRequest,
        saveFileUpload,
        uploadedDocument,
        workItemRequired,
        isCreateWorkItemDisabled,
        isTaskUpload,
      })
    } else if (!isFreeTextBox) {
      // skip atleast one file needed validation if free text is available
      this.setState({ showNoFilesUploadedError: true })
    }
  }

  handleFileDrop = files => {
    const {
      actions: { browseFilesToUpload },
    } = this.props

    this.setState({ showNoFilesUploadedError: false })
    browseFilesToUpload(files)
  }

  handleFileRemove = fileId => {
    const {
      actions: { removeFile },
      fileUploadInfo: { files },
    } = this.props
    if (fileId !== '') removeFile(files, fileId)
  }

  validateFiles = list => {
    const {
      dropzoneMeta: { maxFileNumber, maxFileSize },
      fileUploadMeta: { maxFileSizeError, wrongFileType, wrongFileNameError = INVALID_FILE_NAME },
    } = this.props
    let result = { hasValidationError: false }

    if (list.length > maxFileNumber) return []
    let fileSizeInKB = 0
    const data = Object.values(list).map(file => {
      const fileId = generateFileKey(file)
      result = { ...result, file, fileId }
      fileSizeInKB = convertByteFileSizeToKB(file.size)

      if (file.name.includes(NULL_BYTE)) {
        result = {
          ...result,
          hasValidationError: true,
          fileValidationErrorLabel: wrongFileNameError,
        }
      }
      if (fileSizeInKB > maxFileSize) {
        result = {
          ...result,
          hasValidationError: true,
          fileValidationErrorLabel: maxFileSizeError,
        }
      }

      if (checkMimeType(file)) {
        result = { ...result, hasValidationError: true, fileValidationErrorLabel: wrongFileType }
      }

      // eslint-disable-next-line consistent-return
      return result
    })

    // eslint-disable-next-line consistent-return
    return data
  }

  renderFileList = () => {
    const {
      fileUploadInfo: { files },
      fileUploadMeta,
    } = this.props
    return files.map(fileData => {
      const fileKey = generateFileKey(fileData.file)
      return (
        <FileUploadingProgress
          key={fileKey}
          handleFileRemove={this.handleFileRemove}
          fileUploadMeta={fileUploadMeta}
          fileData={fileData}
        />
      )
    })
  }

  // @FIXME: need to determine if this return type is correct here
  // eslint-disable-next-line consistent-return
  onDragOver = evt => {
    evt.preventDefault()
    const { fileUploadInfo } = this.props
    const isFileUploading = checkIsUploadProgress(fileUploadInfo.files)
    if (isFileUploading.length) return false
  }

  // @FIXME: need to determine if this return type is correct here
  // eslint-disable-next-line consistent-return
  onDrop = event => {
    event.preventDefault()

    const { files } = event.dataTransfer
    const { fileUploadInfo } = this.props
    const isFileUploading = checkIsUploadProgress(fileUploadInfo.files)
    if (isFileUploading.length) return false

    // eslint-disable-next-line no-unused-expressions
    Object.keys(files).length && this.handleFileDrop(this.validateFiles(files))
  }

  handleDropDownChange = (data): void => {
    const { dropDownChange } = this.props
    this.setState({ showNoSelectedWorkSubTypeCode: false })
    dropDownChange(data)
  }

  handleChange = event => {
    const { value } = event.target
    this.setState({ freeTextValue: value })
  }

  render() {
    const {
      formDownloadMeta,
      fileUploadInfo: { files, disableConfirmButton, isModalOpen, fileUploadError, workItemError },
      fileUploadMeta: { createWorkItemError, noFileUploadedError, noSelectedDocSubtypeCode },
      modalMeta: { modalHeading, modalSubHeading, modalConfirmButton },
      dropzoneMeta: { maxFileNumber },
      dropDownData,
      uploadDocumentRequest,
      createWorkItemRequest: { workTypeCode } = {},
      dropDown,
      isFreeTextBox,
      freeTextRequirementPlaceholder,
      handleChangeFileType,
      showOnlyDownloadOption,
      isFreeTextFetching,
      disableMultipleFilesUpload,
      analyticsForDownload,
    } = this.props

    const { showNoFilesUploadedError, freeTextValue, showNoSelectedWorkSubTypeCode } = this.state
    const isUploadingMultipleFiles = maxFileNumber > 1
    const hasValidationErrors = checkFileValidationError(files)
    const checkIsFilesUploading = checkIsUploadProgress(files)
    const analyticsEditPaymentDownload = analyticsForDownload || {
      category: 'Customer selects edit payment details Download',
      action: 'Select',
    }
    let isDisableMultipleFiles = false
    if (disableMultipleFilesUpload && files.length === 1) {
      isDisableMultipleFiles = true
    }
    const isDocumentLinkAvailable = get(formDownloadMeta, 'documentPath.value', '')
    const formDownloadMetaList =
      formDownloadMeta instanceof Array ? formDownloadMeta : [formDownloadMeta]
    return (
      <DragAndDropWrapper onDragOver={this.onDragOver} onDrop={this.onDrop}>
        <Modal
          isCloseDisabled={checkIsFilesUploading.length && !fileUploadError}
          isOpen={isModalOpen}
          onClose={this.closeModal}
          title={modalHeading}
          footer={
            <ConfirmBtn
              disabled={
                (workTypeCode && workItemError) ||
                disableConfirmButton ||
                hasValidationErrors.length
              }
              type="secondary"
              onClick={this.handleModalConfirm}
            >
              {isFreeTextFetching ? (
                <Loader spinnerSize={25} type="noHeight" />
              ) : (
                modalConfirmButton
              )}
            </ConfirmBtn>
          }
        >
          <SubHeading size="6">{modalSubHeading}</SubHeading>
          {!showOnlyDownloadOption && (
            <Fragment>
              {dropDownData ? (
                <Select
                  noOfDropdownOptionToBeDisplayed={3}
                  placeholder={dropDown.placeHolder}
                  value={uploadDocumentRequest.docSubTypeCode}
                  name="docType"
                  id="docType"
                  changeHandler={this.handleDropDownChange}
                  options={dropDownData}
                  customComponents={{ ValueContainer }}
                  error={showNoSelectedWorkSubTypeCode}
                  caption={showNoSelectedWorkSubTypeCode && noSelectedDocSubtypeCode}
                />
              ) : (
                formDownloadMetaList.map(meta => (
                  <FileDownload
                    key={meta.documentName}
                    formDownloadMeta={meta}
                    showDownload
                    handleChangeFileType={handleChangeFileType}
                    analytics={analyticsEditPaymentDownload}
                  />
                ))
              )}
            </Fragment>
          )}
          {showOnlyDownloadOption && isDocumentLinkAvailable !== '' && (
            <FileDownload
              formDownloadMeta={formDownloadMeta}
              showDownload
              handleChangeFileType={handleChangeFileType}
              analytics={analyticsEditPaymentDownload}
            />
          )}
          <TextareaWrap>
            {isFreeTextBox && (
              <Textarea
                data-testId="freeText"
                value={freeTextValue}
                name="freeText"
                placeholder={freeTextRequirementPlaceholder}
                onChange={this.handleChange}
              />
            )}
          </TextareaWrap>
          <Dropzone
            isFileUploading={checkIsFilesUploading.length}
            hasMultipleFiles={isUploadingMultipleFiles}
            handleFileDrop={this.handleFileDrop}
            validateFiles={this.validateFiles}
            isDisableMultipleFiles={isDisableMultipleFiles}
          />
          {files && files.length ? this.renderFileList() : null}
          {showNoFilesUploadedError && !files.length ? (
            <NotificationWrapper variant="error">{noFileUploadedError}</NotificationWrapper>
          ) : null}
          {(workTypeCode && workItemError) || fileUploadError ? (
            <NotificationWrapper variant="error">{createWorkItemError}</NotificationWrapper>
          ) : null}
        </Modal>
      </DragAndDropWrapper>
    )
  }
}

File.defaultProps = {
  dropzoneMeta: {
    maxFileNumber: 10,
    maxFileSize: 20480, // in KB
  },
  workItemRequired: true,
  dropDownData: null,
  isCreateWorkItemDisabled: false,
  isTaskUpload: false,
  saveFileUpload: false,
  isFreeTextBox: false,
  isFreeTextFetching: false,
  handleFreeTextSubmit: null,
  handleChangeFileType: null,
  freeTextRequirementPlaceholder: '',
  agreementReqId: null,
  showOnlyDownloadOption: false,
  disableMultipleFilesUpload: false,
  analytics: null,
  analyticsForDownload: null,
}

export default withRouter(File)
