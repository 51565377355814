// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../../../../types/ComponentLibrary'

const { colours, space, fontSize, mediaQueries, fontWeights } = Variables as VariablesType

const styles = {
  supportStaffContainer: {
    width: '100%',
    background: colours.tertiarySix,
    padding: `${space(4, true)}`,
  },
  supporStaffFormWrapper: {
    marginTop: space(3),
    [mediaQueries.md]: {
      maxWidth: space(50),
    },
  },
  declarationDescriptions: {
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
  },
  footer: {
    padding: `${space(15, true)} 0 ${space(7, true)} 0`,
  },
  fieldset: withoutFlex => ({
    padding: `0  0 ${space(1, true)}`,
    display: `${withoutFlex ? 'block' : 'flex'}`,
    width: '100%',
    border: 'none',
    position: 'relative',
    margin: 0,
    justifyContent: 'space-between',
  }),
  allowableFeatures: {
    paddingBottom: space(3),
    borderBottom: `1px solid ${colours.tertiaryFive}`,
    marginBottom: space(3),
  },
  sectionXsm: (withoutMargin, transparent?) => ({
    width: `calc(30% - ${withoutMargin ? '0px' : space(3, true)})`,
    [transparent && 'input']: {
      color: colours.tertiaryOne,
      fontSize: 'inherit',
    },
  }),
  sectionXl: withoutMargin => ({
    width: `calc(70% - ${withoutMargin ? '0px' : space(3, true)})`,
  }),
  sectionMd: withoutMargin => ({
    width: `calc(50% - ${withoutMargin ? '0px' : space(3, true)})`,
  }),
  fullWidth: {
    flex: '0 0 100%',
  },
  sectionLg: {
    width: '60%',
  },
  sectionSm: {
    width: `calc(40% - ${space(3, true)})`,
  },
  button: {
    color: 'inherit',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
  },
  modalButton: {
    marginTop: space(4),
  },
  noResults: {
    padding: space(2),
  },
  registrationDescription: { color: colours.tertiaryOne },
  declarationCheckbox: { '> label': { '> div': { fontSize: fontSize.xsm } } },
  supportStaffRegisterDeclarationGroupWrapper: {
    marginTop: space(3),
    width: '100%',
  },
  supportStaffRegisterWrapper: {
    width: '100%',
    paddingLeft: space(12),
    '& label': {
      marginBottom: space(1),
    },
    flexBasis: 'calc(85% - 9px)',
  },
  nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: fontSize.xs,
    padding: '17px 17px 22px 0',
    height: 'auto',
    color: colours.mlcAquaAccessible,
    svg: {
      marginRight: space(0.5),
    },
    '&:hover': {
      color: colours.mlcAquaAccessible,
      textDecoration: 'none',
    },
    textDecoration: 'underline',
  },
  submitBtn: {
    fontSize: '18px',
  },
}

export default styles
