import { space, colours, fontSize, fontWeights, fontFamily } from '../../../../../../styles'

const styles = {
  collectionLabel: {
    fontSize: fontSize.xxs,
    fontWeight: fontWeights.semiBold,
    color: colours.white,
  },

  customerInfoLabel: {
    lineHeight: 1,
    display: 'block',
  },

  customerInfoValue: {
    fontSize: fontSize.sm,
    color: colours.white,
    fontWeight: fontWeights.normal,
    fontFamily: fontFamily.charlie,
    textAlign: 'center',
  },

  customerInfoRoot: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${colours.darkestGrey} `,
    background: colours.darkestGreen,
  },

  customerInfoItem: {
    textAlign: 'center',
    flexGrow: 1,
    paddingTop: space(1, true),
    paddingBottom: space(2, true),
    borderRight: `1px solid ${colours.darkestGrey} `,
  },
}

export default styles
