import { fontSize, space } from '../../../../../styles'

const styles = {
  base: theme => ({
    position: 'relative',
    padding: space(3),
    paddingRight: space(7),
    height: space(12, true),
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.border,
    color: theme.text,
    background: theme.bg,
  }),
  nav: {
    marginBottom: space(3),
    display: 'block',
    position: 'absolute',
    fontSize: fontSize.xxs,
    color: 'inherit',
    padding: 0,
    top: '0%',
    height: 'auto',
    svg: {
      marginRight: 5,
      color: 'inherit',
    },
  },
  headerContent: secondaryHeader => ({
    paddingTop: space(0),
    h2: {
      fontSize: secondaryHeader ? fontSize.xs : fontSize.sm,
    },
  }),
  close: {
    position: 'absolute',
    top: 'calc(50% - 22px)',
    right: 16,
    color: 'inherit',
    svg: {
      color: 'inherit',
    },
  },
}

export default styles
