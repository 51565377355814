import { required, maxLength } from '../../../utils/formUtils'

export const FORM_ID = 'CONTACT_MLC'
const schema = (fields, maxlength = 500) => ({
  category: {
    condition: required,
    errorMsg: fields.ContactMLCCategoryRequiredErrorMsg,
  },
  commentsOrQuestions: {
    condition: [
      {
        condition: required,
        errorMsg: fields.ContactMLCCommentsOrQuestionsErrorMsg,
      },
      {
        condition: maxLength(maxlength),
        errorMsg: fields.ContactMLCCommentsOrQuestionsMaxlengthErrorMsg + maxlength,
      },
    ],
  },
})

export default schema
