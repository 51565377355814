// @flow
import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import { useSelector, connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { DoughnutChart, Button, Variables, Icons } from '@mlcl-digital/mlcl-design'
import { object, shape, string, func } from 'prop-types'
import { createEvent } from '../../../../utils/telemetry'

// Actions
import { actionCreators } from '../../../../actions'

// Utilities
import {
  renderTextField,
  reduceAuthorableFields,
  replaceLabelPlaceholders,
} from '../../../../utils/sitecoreUtils'
import history from '../../../../utils/browserHistory'

// Constants
import { ALTERATION_TYPES, REJECTCPI_WINDOW_IN_DAYS } from '../../../../constants/alterations'
import { NAVIGATION_DECREASE, FOOTER_DECREASE } from '../../../../constants/navigation'

// Selectors
import { getIsAdviserPortal } from '../../../../selectors/common.selectors'
import {
  getEligiblePolicies,
  makeRejectCPIRemainingDays,
  getBancsCustomerNoOfLAFromAlterations,
  getAlterationType,
  getAlterationTypeSelectedByUser,
  getEscalationDate,
  getEscalationRejectionDeadlineDate,
  getIsFetchingPolicies,
  getIsPolicyAPIDoneForPolicyWithinAnivPeriod,
  getWithInPeriodEffectiveDate,
} from '../../../../selectors/alterations'
import {
  getAlterationPolicies,
  setIsPolicyAPIDoneForPolicyWithinAnivPeriod,
} from '../../../../actions/creators/alterations'

// Styling
import styles from './styles'
import { colours as themeColors } from '../../../../styles'
import { DASHBOARD_ROUTE } from '../../../../constants/routes'

// Components
import WithLoader from '../../../molecules/WithLoader'

const { colours } = Variables

const PageContainer = styled('div')(styles.pageContainer)
const RowOneContainer = styled('div')(styles.rowOneContainer)
const LeftContainer = styled('div')(styles.leftContainer)
const StyledLeftContent = styled('div')(styles.leftContent)
const RightContainer = styled('div')(styles.rightContainer)
const RightChildContainer = styled('div')(styles.rightChildContainer)
const LeftChildContainer = styled('div')(styles.leftChildContainer)
const RowTwoContainer = styled('div')(styles.rowTwoContainer)
const RowTwoContainerRight = styled('div')(styles.rowTwoContainerRight)
const StyledRowTwoLeftContent = styled('div')(styles.rowTwoLeftContent)
const StyledRowTwoRightContent = styled('div')(styles.rowTwoRightContent)
const RowTwoCTAWrapper = styled.div(styles.rowTwoCTAWrapper)
const StyledButton = styled(Button)(styles.styledButton)
const StyledText = styled('div')(styles.styledText)
const ListIcon = styled('span')(styles.listIcon)

const constructChartData = (
  daysLeft: number,
  totalDaysInWindow: number = REJECTCPI_WINDOW_IN_DAYS
) => {
  const daysLeftObj = { name: 'noOfDaysLeft', value: daysLeft }
  const daysPassedObj = { name: 'daysAlreadyPassed', value: totalDaysInWindow - daysLeft }
  let colorDaysLeft = colours.functionalInfoDark
  let colorDaysPassed = colours.functionalInfo
  if (daysLeft > 0 && daysLeft <= 5) {
    colorDaysLeft = colours.functionalDangerDark
    colorDaysPassed = colours.functionalDanger
  }
  if (daysLeft >= 6 && daysLeft <= 20) {
    colorDaysLeft = colours.functionalWarningDark
    colorDaysPassed = colours.functionalWarning
  }
  return [
    { ...daysLeftObj, color: colorDaysLeft },
    { ...daysPassedObj, colour: colorDaysPassed },
  ]
}

export const DOUGHNUT_CONTAINER_TEST_ID = 'doughnut-chart-container'

const redirectToDashboard = () => history.push(DASHBOARD_ROUTE)

export const AltsLandingPage = ({ fields, params, actions }) => {
  const isAdviserPortal = useSelector(getIsAdviserPortal)
  const eligiblePolicies = useSelector(getEligiblePolicies(params.AlterationType))
  const bancsCustomerNoOfLA = useSelector(getBancsCustomerNoOfLAFromAlterations)
  const alterationType = useSelector(getAlterationType())
  const alterationTypeSelectedByUser = useSelector(getAlterationTypeSelectedByUser)
  const isPolicyAPIDoneForPolicyWithinAnivPeriod = useSelector(
    getIsPolicyAPIDoneForPolicyWithinAnivPeriod
  )
  const dispatch = useDispatch()

  // These params are only needed in decrease cover
  // Loader when fetching policy detials within aniv period
  const isLoadingPoliciesDetail = useSelector(getIsFetchingPolicies)
  // gets the escalation due date
  const formattedEscalationDate = useSelector(getEscalationDate('YYYY-MM-DD'))
  const formattedDeadLineDate = useSelector(
    getEscalationRejectionDeadlineDate(isAdviserPortal, 'MMMM DD, YYYY')
  )
  const withInPeriodEffectiveDate = useSelector(getWithInPeriodEffectiveDate)

  const { IconInfo32 } = Icons
  const isMounted = useRef(false)

  const {
    setAlterationTypeSelectedByUser,
    setAlterationTypeForAlterationsRoute,
    changeNavigationType,
    changeFooterType,
    createAltsQuote,
  } = actions

  useEffect(() => {
    if (params.AlterationType !== alterationTypeSelectedByUser) {
      redirectToDashboard()
    }
  }, [alterationTypeSelectedByUser])

  useEffect(() => {
    if (alterationTypeSelectedByUser === ALTERATION_TYPES.DECREASE_RISK) {
      changeNavigationType(NAVIGATION_DECREASE)
      changeFooterType(FOOTER_DECREASE)
    }
  }, [alterationTypeSelectedByUser])

  useEffect(() => {
    // Setting Quote Entity when eligiblePolicies are updated after get policies api call
    // success for decrease cover when within policy aniversery period
    // Or for other case when API Call is not required
    if (
      isMounted.current ||
      !(withInPeriodEffectiveDate && params.AlterationType === ALTERATION_TYPES.DECREASE_RISK)
    ) {
      createAltsQuote({
        alterationType: params.AlterationType,
      })
    } else {
      isMounted.current = true
    }
  }, [eligiblePolicies])

  useEffect(() => {
    // Get policy details and the "effectiveDate" passed in the request to BaNCS
    // Only in decrease cover
    if (
      withInPeriodEffectiveDate &&
      params.AlterationType === ALTERATION_TYPES.DECREASE_RISK &&
      !isPolicyAPIDoneForPolicyWithinAnivPeriod
    ) {
      dispatch(getAlterationPolicies(bancsCustomerNoOfLA, withInPeriodEffectiveDate))
      dispatch(setIsPolicyAPIDoneForPolicyWithinAnivPeriod())
    }
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: `${alterationType} landing page`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - landing page`,
        },
      },
    })
    tagEvent.end()
    // Clear the flag on unmount to avoid accessing the link directly
    return () => {
      setAlterationTypeSelectedByUser('')
    }
  }, [])

  let chartData
  let noOfDaysLeftToRejectCPI = 0
  if (params.AlterationType === ALTERATION_TYPES.REJECT_CPI) {
    noOfDaysLeftToRejectCPI = useSelector(makeRejectCPIRemainingDays(isAdviserPortal))
    chartData = constructChartData(noOfDaysLeftToRejectCPI)
  }

  const handleClick = redirectURL => {
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: `Enter ${alterationType} alteration`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Enter ${alterationType} alteration`,
        },
      },
    })
    tagEvent.end()
    history.replace(redirectURL)
  }

  const { Desc, Checklist, DaysText, AppDesc, StartAppBtn, LoaderMessage, Note } =
    reduceAuthorableFields(fields)
  const escalationDatesObj = {
    AnniversaryDate: moment(formattedEscalationDate).format('MMMM DD, YYYY'),
    CutOffDate: formattedDeadLineDate,
  }
  const NoteDates = replaceLabelPlaceholders(Note, escalationDatesObj)

  return (
    <WithLoader
      loaderProps={{ loaderContent: renderTextField(LoaderMessage, true) }}
      isLoading={isLoadingPoliciesDetail}
      childrenBackgroundColor={themeColors.white}
      overlay
    >
      <PageContainer>
        <RowOneContainer>
          <LeftContainer>
            <StyledLeftContent>{renderTextField(Desc, true)}</StyledLeftContent>
          </LeftContainer>
          <RightContainer>
            <LeftChildContainer>
              {renderTextField(AppDesc && AppDesc.replace('###', noOfDaysLeftToRejectCPI), true)}
            </LeftChildContainer>
            {params.AlterationType === ALTERATION_TYPES.REJECT_CPI && (
              <RightChildContainer data-testid={DOUGHNUT_CONTAINER_TEST_ID}>
                <DoughnutChart
                  cx="50%"
                  cy="50%"
                  data={chartData}
                  dataKey="value"
                  endAngle={450}
                  height={160}
                  innerRadius="58%"
                  isAnimationActive
                  labelAtCenter={noOfDaysLeftToRejectCPI}
                  labelAtCenterUnit={renderTextField(DaysText)}
                  labelColorCode={chartData[0].color}
                  minAngle={0}
                  nameKey="name"
                  outerRadius="100%"
                  paddingAngle={0}
                  showLabelAtCenter
                  startAngle={90}
                  width={160}
                />
              </RightChildContainer>
            )}
          </RightContainer>
        </RowOneContainer>

        <RowTwoContainer>
          <LeftContainer>
            <StyledRowTwoLeftContent>
              {renderTextField(Checklist, true)}
              <RowTwoCTAWrapper>
                <StyledButton
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    setAlterationTypeForAlterationsRoute(params.AlterationType)
                    handleClick(StartAppBtn.href)
                  }}
                >
                  {StartAppBtn.text}
                </StyledButton>
              </RowTwoCTAWrapper>
            </StyledRowTwoLeftContent>
          </LeftContainer>
          {params.AlterationType === ALTERATION_TYPES.REJECT_CPI && (
            <RowTwoContainerRight>
              <StyledRowTwoRightContent>
                <StyledText>
                  <ListIcon>
                    <IconInfo32 />
                  </ListIcon>
                  {renderTextField(NoteDates, true)}
                </StyledText>
              </StyledRowTwoRightContent>
            </RowTwoContainerRight>
          )}
        </RowTwoContainer>
      </PageContainer>
    </WithLoader>
  )
}

AltsLandingPage.defaultProps = {
  params: {},
  actions: {},
}

// todo: DEFINE THE PROPTYPES
AltsLandingPage.propTypes = {
  // fields from Sitecore
  fields: shape({
    Desc: object,
    Checklist: object,
    DaysText: object,
    AppDesc: object,
    StartAppBtn: object,
    LoaderMessage: object,
  }).isRequired,
  // params from Sitecore
  params: shape({
    AlterationType: string,
  }),
  // actions
  actions: shape({
    createAltsQuote: func,
  }),
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(AltsLandingPage)
