// @flow
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import moment from 'moment'
import { Tooltip, Chip, Variables, DownloadCSV, Loader, Button } from '@mlcl-digital/mlcl-design'
import TableV2 from '@mlcl-digital/mlcl-design/lib/base/Table'
import WithLoader from '../../../../molecules/WithLoader'
import EmailClientErrorModal from '../../../EmailClientErrorModal'
// utils
import history from '../../../../../utils/browserHistory'
import {
  sortCurrency,
  sortDate,
  sortByKey,
  sortByKeyOfFirst,
} from '../../../../../utils/sortingUtils'
import { sortLifeAssuredByPolicyOwner } from '../../../../../utils/policyUtils'
import { dollarAmountWithCommasAndTwoDecimal } from '../../../../../utils/formUtils'
import { createEvent } from '../../../../../utils/telemetry'
import { getPreferredItemBreakdown } from '../../../../../utils/contactUtils'
import { allOccuranceReplace } from '../../../../../utils/commonUtils'

// constants
import { POLICY_ANNIVERSARY_ID } from '../../../../../constants/dashboard'
import { POLICY_MEMBER_IDENTIFIER_PARTY } from '../../../../../constants/policies'
import {
  BANCS_CUSTOMER_NUMBER_NULIS,
  BANCS_CUSTOMER_NUMBER_IOOF,
} from '../../../../../constants/bancs'

const LifeInsuredCell = styled('div')({
  '& >div': {
    margin: Variables.space(1),
  },
})

const EmailClientBtn = styled(Button)({
  '& >a': {
    color: 'inherit',
    textDecoration: 'inherit',
  },
})

const StyledDownloadCSV = styled(DownloadCSV)({
  color: Variables.colours.mlcAquaAccessible,
  borderColor: Variables.colours.mlcAquaAccessible,
  '&:hover, &:focus': {
    color: Variables.colours.tertiarySix,
    backgroundColor: Variables.colours.mlcAquaAccessible,
  },
})

const TableInformation = styled('div')({
  background: Variables.colours.overlayWhite,
  padding: `${Variables.space(2.5)}px ${Variables.space(2)}px`,
  '& + div': {
    th: {
      textAlign: 'left',
    },
  },
})

const Spinner = styled(Loader)(({ width, height }) => ({
  flexDirection: 'row',
  ...(width && { width: Variables.space(width) }),
  ...(height && { height: Variables.space(height) }),
  boxSizing: 'border-box',
  minHeight: 0,
  '& >div': {
    marginTop: 0,
    marginLeft: Variables.space(1),
  },
}))

const hoverStyles = data =>
  data.length > 0
    ? {
        tr: {
          cursor: 'pointer',
          '&:hover': {
            background: Variables.colours.tertiaryThree,
          },
        },
      }
    : {}

const renderOtherLifeInsured = (first, others) =>
  Boolean(others.length) && (
    <>
      <Chip data-tip data-for={`tooltip-${first}`} variant="info">
        +{others.length}
      </Chip>
      <Tooltip id={`tooltip-${first}`}>
        {others.map(item => (
          <div key={item}>{item}</div>
        ))}
      </Tooltip>
    </>
  )

export class RetentionTable extends PureComponent {
  static formatResults(data) {
    return data.map(
      ({
        policyNo,
        anniversaryDate,
        paymentFrequency,
        scheduledLapseDate,
        premium,
        newPremium,
        policyOwner,
        lapseDate,
        cancellationDate,
        paymentOutstandingAmount,
        lifeAssured,
        bancsPolicyNo,
      }) => ({
        policyNo,
        anniversaryDate,
        paymentFrequency,
        scheduledLapseDate,
        lapseDate,
        cancellationDate,
        policyOwner: policyOwner.fullName,
        premium: dollarAmountWithCommasAndTwoDecimal(premium),
        newPremium,
        paymentOutstandingAmount: dollarAmountWithCommasAndTwoDecimal(paymentOutstandingAmount),
        lifeInsured: [...lifeAssured]
          .sort(item => sortLifeAssuredByPolicyOwner(policyOwner, item.bancsCustomerNo))
          .map(({ firstName, lastName }) => `${firstName} ${lastName}`),
        bancsPolicyNo,
      })
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      pageIndex: 0,
      isDownloadListLoading: false,
      isNewPremiumLoading: false,
      csvData: [],
      showEmailClientErrorModal: false,
      preferredEmail: '',
      emailClientContent: '',
    }
    this.rowClick = this.rowClick.bind(this)
    this.onPageChangeHandler = this.onPageChangeHandler.bind(this)
    this.csvLinkRef = React.createRef()
    this.emailClientRef = React.createRef()
    this.pageChangeHanlderRef = React.createRef(0)
    this.clientId = ''
    this.policyId = ''
  }

  async onPageChangeHandler(tableState, pageInfo) {
    const {
      policies,
      getAllPoliciesNewPremium,
      canPolicyNewPremiumFetching,
      isRentionNewPremiumEnabled,
      activeTab,
    } = this.props
    const { isNewPremiumLoading } = this.state
    const { pageIndex, pageSize } = tableState
    // this might look weird just to differentiate the case when old design,
    // will remove after go live
    if (activeTab === 2 || activeTab === POLICY_ANNIVERSARY_ID) {
      this.pageChangeHanlderRef.current += 1
    }
    this.setState({ pageIndex: tableState.pageIndex })
    const startIndex = pageIndex * pageSize
    const endIndex = (pageIndex + 1) * pageSize
    const requestedPolicies = pageInfo?.map(i =>
      policies?.find(p => p?.bancsPolicyNo === i?.original?.bancsPolicyNo)
    )
    const isAnniPage = activeTab === 2 || activeTab === POLICY_ANNIVERSARY_ID
    if (
      pageInfo.length > 0 &&
      canPolicyNewPremiumFetching &&
      !isNewPremiumLoading &&
      requestedPolicies.every(p => p.newPremium === undefined) &&
      // first time page info is not sorted yet, only fetch when rerender
      this.pageChangeHanlderRef.current > 1 &&
      isAnniPage &&
      isRentionNewPremiumEnabled
    ) {
      this.setState({ isNewPremiumLoading: true })
      await getAllPoliciesNewPremium(requestedPolicies, startIndex, endIndex)
      this.setState({ isNewPremiumLoading: false })
    }
  }

  COLUMNS = fields => {
    const { isRentionNewPremiumEnabled, activeTab, policies } = this.props

    return [
      {
        Header: fields.policyOwnerTitle,
        id: 'policyOwner',
        accessor: 'policyOwner',
        sortType: sortByKey('policyOwner'),
      },
      {
        Header: fields.lifeInsuredTitle,
        id: 'lifeInsured',
        accessor: 'lifeInsured',
        Cell: ({ value: lifeInsured }: Object) => {
          const [first, ...others] = lifeInsured
          return (
            <LifeInsuredCell key={`${first}`}>
              {first}
              {renderOtherLifeInsured(first, others)}
            </LifeInsuredCell>
          )
        },
        sortType: sortByKeyOfFirst('lifeInsured'),
      },
      {
        Header: fields.policyNoTitle,
        id: 'policyNo',
        accessor: 'policyNo',
        sortType: sortByKey('policyNo'),
      },
      {
        Header: fields.premiumTitle,
        id: 'premium',
        accessor: 'premium',
        sortType: sortCurrency('premium', 'policyOwner'),
      },
      ...(isRentionNewPremiumEnabled
        ? [
            {
              Header: fields.newPremiumTitle,
              id: 'newPremium',
              accessor: 'newPremium',
              columnTooltipText: fields.policyAnniversaryNewPremiumTooltip,
              Cell: ({ value: newPremium, row: { index }, page }: Object) =>
                newPremium === undefined && index === page[0].index ? (
                  <Spinner spinnerSize={25} loaderContent="Loading..." />
                ) : (
                  <div>
                    {newPremium === undefined
                      ? null
                      : dollarAmountWithCommasAndTwoDecimal(newPremium, true)}
                  </div>
                ),
            },
          ]
        : []),
      {
        Header: fields.paymentOutstandingAmountTitle,
        id: 'paymentOutstandingAmount',
        accessor: 'paymentOutstandingAmount',
        sortType: sortCurrency('paymentOutstandingAmount'),
      },
      {
        Header: fields.anniversaryDateTitle,
        headerStyle: { position: 'relative' },
        id: 'anniversaryDate',
        accessor: 'anniversaryDate',
        sortType: sortDate('anniversaryDate', 'policyOwner'),
      },
      {
        Header: fields.paymentFrequencyTitle,
        id: 'paymentFrequency',
        accessor: 'paymentFrequency',
        disableSortBy: true,
      },
      {
        Header: fields.scheduledLapseDateTitle,
        headerStyle: { position: 'relative' },
        id: 'scheduledLapseDate',
        accessor: 'scheduledLapseDate',
        sortType: sortDate('scheduledLapseDate', 'policyOwner'),
      },
      {
        Header: fields.cancellationDateTitle,
        id: 'cancellationDate',
        accessor: 'cancellationDate',
        sortType: sortDate('cancellationDate', 'policyOwner'),
      },
      {
        Header: fields.lapseDateTitle,
        id: 'lapseDate',
        accessor: 'lapseDate',
        Cell: ({ value }: Object) => (
          <div>
            {value}
            {moment(value, 'DD/MM/YYYY').isAfter(moment().subtract(31, 'days')) && (
              <Chip variant="important">{fields.chipText}</Chip>
            )}
          </div>
        ),
        sortType: sortDate('lapseDate', 'policyOwner'),
      },
      {
        Header: '',
        id: 'emailClient',
        accessor: 'emailClient',
        Cell: ({
          row: {
            original: {
              scheduledLapseDate,
              bancsPolicyNo,
              policyOwner,
              policyNo,
              lapseDate,
              paymentOutstandingAmount,
            },
          },
        }) => {
          const { preferredEmail, emailClientContent } = this.state
          const matchedPolicy = policies?.find(policy => policy?.bancsPolicyNo === bancsPolicyNo)
          const isOwnerNULISorIOOF = [
            BANCS_CUSTOMER_NUMBER_NULIS,
            BANCS_CUSTOMER_NUMBER_IOOF,
          ].includes(matchedPolicy?.policyOwner?.bancsCustomerNo)
          const clientBancsCustNo = !isOwnerNULISorIOOF
            ? matchedPolicy?.policyOwner?.bancsCustomerNo
            : matchedPolicy?.lifeAssured?.[0]?.bancsCustomerNo
          const scemailTemplate =
            activeTab === 0 ? fields.policyLapsedEmailContent : fields.missedPaymentEmailContent
          const emailContent = allOccuranceReplace(scemailTemplate, {
            '{0}': isOwnerNULISorIOOF
              ? `${matchedPolicy?.lifeAssured?.[0]?.firstName} ${matchedPolicy?.lifeAssured?.[0]?.lastName}`
              : policyOwner,
            '{1}': policyNo,
            '{2}': paymentOutstandingAmount,
            '{3}':
              activeTab !== 1
                ? moment(lapseDate, 'DD/MM/YYYY').add(30, 'days').format('Do MMMM YYYY')
                : moment(scheduledLapseDate, 'DD/MM/YYYY').format('Do MMMM YYYY'),
          })
          const showBtnConditionMap = {
            0: moment(lapseDate, 'DD/MM/YYYY').isAfter(moment().subtract(31, 'days')),
            1: true,
          }
          return (
            showBtnConditionMap[activeTab] && (
              <div>
                <EmailClientBtn
                  variant="secondaryWithTheme"
                  size="small"
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    this.handleEmailClientClick(clientBancsCustNo, bancsPolicyNo, emailContent, e)
                  }
                >
                  <a
                    ref={this.emailClientRef}
                    href={`mailto:${preferredEmail}?body=${emailClientContent}`}
                  >
                    {fields.emailClientCTA}
                  </a>
                </EmailClientBtn>
              </div>
            )
          )
        },
      },
    ]
  }

  handleDownloadListClick = async (policies, done, csvDataResults) => {
    const { getAllPoliciesNewPremium } = this.props
    const { csvData } = this.state
    if (csvData.length) {
      done(true)
    } else {
      done(false)
      this.setState({ isDownloadListLoading: true })
      const dataResults = await getAllPoliciesNewPremium(policies)
      this.setState({ isDownloadListLoading: false })
      this.setState({
        csvData: csvDataResults.map(policy => {
          const policyDataInResults = dataResults?.find(
            result => result?.bancsPolicyNo === policy?.bancsPolicyNo
          )
          return {
            ...policy,
            newPremium: policyDataInResults?.newPremium
              ? `$${policyDataInResults.newPremium}`
              : '---',
          }
        }),
      })
      this.csvLinkRef.current.link.click()
    }
  }

  handleEmailClientClick = async (clientBancsCustNo, bancsPolicyNo, emailContent, e) => {
    const { activeTab, getClientDetails } = this.props
    const { preferredEmail } = this.state
    e.stopPropagation()
    if (!preferredEmail) {
      e.preventDefault()
    } else {
      return
    }
    this.clientId = clientBancsCustNo
    this.policyId = bancsPolicyNo

    const eventInfoMappingArr = [
      {
        category: 'Retention email client Lapsed policies',
        action: 'AP retention page - lapsed policy email',
      },
      {
        category: 'Retention email client Missed payment',
        action: 'AP retention page - missed payment email',
      },
    ]
    const event = createEvent({
      GA: eventInfoMappingArr[activeTab],
      Splunk: {
        attributes: {
          'workflow.name': eventInfoMappingArr[activeTab].category,
        },
      },
    })
    event.end()

    if (!preferredEmail) {
      await getClientDetails(clientBancsCustNo, dataReceived => {
        const clientEmailsArr = dataReceived?.businessData?.member?.contactMethods?.emails ?? []
        this.setState(
          {
            preferredEmail: getPreferredItemBreakdown(clientEmailsArr, null)?.email,
            emailClientContent: allOccuranceReplace(emailContent, {
              '{4}': dataReceived?.businessData?.member?.identifiers?.find(
                identifier => identifier?.type === POLICY_MEMBER_IDENTIFIER_PARTY
              )?.value,
            }),
          },
          () => {
            if (clientEmailsArr.length > 0) {
              this.emailClientRef.current.click()
            }
            this.setState({ preferredEmail: '' })
          }
        )
        if (clientEmailsArr.length === 0) {
          this.setState({ showEmailClientErrorModal: true })
        }
      })
    }
  }

  rowClick(rowInfo) {
    const { rowClickHandler } = this.props
    if (rowInfo.original && rowInfo.original.policyNo) {
      rowClickHandler(rowInfo.original.policyNo)
    }
  }

  render() {
    const {
      policies,
      downloadTitle,
      fields,
      columns,
      csvFileName,
      isRentionNewPremiumEnabled,
      activeTab,
      policiesNumber,
    } = this.props
    const { pageIndex, isDownloadListLoading, csvData, showEmailClientErrorModal } = this.state

    if (!policies) {
      return null
    }
    const {
      retentionDashboardNoDataMessage,
      dashboardPolicyAnniversaryInfo,
      dashboardRiskOfLapseInfo,
      recentlyLapseInfo,
      recentlyCancelledInfo,
    } = fields
    const mappedColumns = this.COLUMNS(fields).filter(item => columns.includes(item.id))
    const results = RetentionTable.formatResults(policies)
    const infoArray = [
      recentlyLapseInfo,
      dashboardRiskOfLapseInfo,
      dashboardPolicyAnniversaryInfo,
      recentlyCancelledInfo,
    ]

    const TableComponent = TableV2
    const defaultSortedMappingArr = [
      { id: 'lapseDate', desc: true },
      { id: 'scheduledLapseDate', desc: true },
      { id: 'anniversaryDate', desc: true },
      { id: 'cancellationDate', desc: true },
    ]
    const isFetchDownloadList =
      (isRentionNewPremiumEnabled && activeTab === POLICY_ANNIVERSARY_ID) ||
      (isRentionNewPremiumEnabled && activeTab === 2)

    return (
      <>
        <TableInformation>
          {infoArray[typeof activeTab === 'number' ? activeTab : 0].replace('##', policiesNumber)}
        </TableInformation>
        <TableComponent
          classes="table-row"
          data={results}
          columns={mappedColumns}
          clickHandler={this.rowClick}
          activeTab={activeTab}
          onPageChange={this.onPageChangeHandler}
          currentIndex={pageIndex}
          defaultSorted={[defaultSortedMappingArr[activeTab]]}
          noDataCopy={retentionDashboardNoDataMessage}
          pageSize={20}
          styleOverrides={{
            tableBody: hoverStyles(results),
            headerCell: {
              minWidth: 'none',
            },
            cell: {
              minWidth: 'none',
            },
          }}
          footer={
            Boolean(results.length) && (
              <WithLoader
                isLoading={isDownloadListLoading}
                loaderProps={{ type: 'noHeight', spinnerSize: 20 }}
              >
                <StyledDownloadCSV
                  ref={this.csvLinkRef}
                  filename={csvFileName}
                  headers={mappedColumns.map(item => ({
                    label: item.Header,
                    key: item.id,
                  }))}
                  asyncOnClick={isRentionNewPremiumEnabled ? true : undefined}
                  onClick={
                    isFetchDownloadList
                      ? (_, done) => this.handleDownloadListClick(policies, done, results)
                      : undefined
                  }
                  data={isFetchDownloadList ? csvData : results}
                >
                  {downloadTitle}
                </StyledDownloadCSV>
              </WithLoader>
            )
          }
        />
        <EmailClientErrorModal
          fields={fields}
          isOpen={showEmailClientErrorModal}
          onClose={() => this.setState({ showEmailClientErrorModal: false })}
          handleCheckButtonClick={() => {
            const event = createEvent({
              GA: {
                category: 'Retention email no address - check client details button',
                action: 'select',
              },
              Splunk: {
                attributes: {
                  'workflow.name': 'Retention email no address - check client details button',
                },
              },
            })
            event.end()
            const path = `/clients/details?clientId=${this.clientId}&policyId=${this.policyId}`
            history.push(path, { fromEmailClient: true })
          }}
        />
      </>
    )
  }
}

RetentionTable.defaultProps = {
  policies: [],
  isRentionNewPremiumEnabled: false,
  activeTab: 0,
  policiesNumber: 0,
}
RetentionTable.propTypes = {
  downloadTitle: PropTypes.string.isRequired,
  csvFileName: PropTypes.string.isRequired,
  fields: PropTypes.objectOf(PropTypes.string).isRequired,
  policies: PropTypes.arrayOf(PropTypes.object),
  canPolicyNewPremiumFetching: PropTypes.bool.isRequired,

  rowClickHandler: PropTypes.func.isRequired,
  getAllPoliciesNewPremium: PropTypes.func.isRequired,
  getClientDetails: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRentionNewPremiumEnabled: PropTypes.bool,
  activeTab: PropTypes.number,
  policiesNumber: PropTypes.number,
}

export default RetentionTable
