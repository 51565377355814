import React from 'react'
import { Chip, Table } from '@mlcl-digital/mlcl-design'
import moment from 'moment'
import PropTypes from 'prop-types'
import { CLIENT_SIDE_DATE_FORMAT, ALLOWED_DATE_FORMAT } from '../../../utils/quoteUtils'
import styles from './ActiveServiceRequestTable.styles'
import { sortDate } from '../../../utils/sortingUtils'

const ActiveServiceRequestTable = ({ openRequests, fields }) => {
  const {
    policyServiceRequestDateCreatedText,
    policyServiceRequestNameText,
    policyServiceRequestPolicyText,
  } = fields

  const columns = [
    {
      Header: '',
      accessor: 'status',
      disableSortBy: true,
    },
    {
      Header: policyServiceRequestNameText,
      accessor: 'serviceRequestName',
    },
    {
      Header: policyServiceRequestPolicyText,
      accessor: 'policy',
    },
    {
      Header: policyServiceRequestDateCreatedText,
      accessor: 'dateCreated',
      sortType: sortDate('dateCreated', 'serviceRequestName'),
    },
  ]
  const data = openRequests.map(request => ({
    status: (
      <Chip variant={request.status.toLowerCase() === 'closed' ? 'closed' : 'default'}>
        {request.status.toLowerCase()}
      </Chip>
    ),
    serviceRequestName: request.requestType,
    policy: request.policyNumber,
    dateCreated: moment(request.createdDate, ALLOWED_DATE_FORMAT, true).format(
      CLIENT_SIDE_DATE_FORMAT
    ),
  }))

  return (
    <Table
      data-testid="policy-details-overview-active-service-request"
      columns={columns}
      data={data}
      defaultSorted={[
        {
          id: 'dateCreated',
          desc: false,
        },
      ]}
      styleOverrides={{ tableEle: styles }}
    />
  )
}

ActiveServiceRequestTable.defaultProps = {
  openRequests: [],
}

ActiveServiceRequestTable.propTypes = {
  openRequests: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.shape({
    policyServiceRequestDateCreatedText: PropTypes.string,
    policyServiceRequestNameText: PropTypes.string,
    policyServiceRequestPolicyText: PropTypes.string,
  }).isRequired,
}

export default ActiveServiceRequestTable
