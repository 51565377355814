import { Variables } from '@mlcl-digital/mlcl-design'
import { colours, space, mediaQueries } from '../../../styles'

const { colours: designColours } = Variables

const styles = {
  wrapper: (isCustomer, closeButtonDisabled) => ({
    position: 'fixed',
    display: 'flex',
    zIndex: 3,
    padding: closeButtonDisabled
      ? `${space(1.25, true)} ${space(1, true)} ${space(1.25, true)} ${space(3, true)}`
      : `${space(1, true)} ${space(1, true)} ${space(1, true)} ${space(3, true)}`,
    background: designColours.functionalWarningLight,
    width: '100%',
    [mediaQueries.md]: {
      padding: closeButtonDisabled
        ? `${space(1.25, true)} ${space(3, true)}`
        : `${space(1, true)} ${space(3, true)}`,
      // different margin top in tablet view for
      // avoiding main nav covers notification bar
      marginTop: isCustomer ? space(3, true) : 0,
      marginBottom: isCustomer ? `-${space(3, true)}` : 0,
    },
    [mediaQueries.tb]: {
      margin: 0,
    },
    '& + header': {
      marginTop: space(7.5, true),
    },
  }),
  infoIconContainer: {
    padding: `${space(1, true)} 0`,
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'calc(100% - 16px - 40px)',
    [mediaQueries.md]: {
      width: 'calc(100% - 16px - 24px)',
    },
  },
  message: {
    display: 'inline-block',
    margin: `0 0 0 ${space(1, true)}`,
    color: designColours.functionalWarningDark,
    padding: `${space(1, true)} 0`,
    [mediaQueries.md]: {
      margin: `0 0 0 ${space(2, true)}`,
    },
  },
  link: {
    color: designColours.functionalWarningDark,
    textDecoration: 'none',
    marginLeft: space(1, true),
    verticalAlign: 'text-bottom',
    [mediaQueries.md]: {
      marginLeft: space(2, true),
    },
  },
  iconInfo: {
    color: designColours.functionalWarning,
  },
  closeIcon: {
    color: colours.black,
  },
}

export default styles
