import { space, colours, fontSize, fontFamily } from '../../../../../../../styles'

const styles = {
  quoteName: {
    color: colours.white,
  },
  heading: {
    color: colours.white,
    fontFamily: fontFamily.charlie,
    fontSize: fontSize.md,
    marginBottom: space(1),
    position: 'relative',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

export default styles
