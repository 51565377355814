import { space, mediaQueries, colours, fontSize } from '../../../../../../styles'

const styles = {
  line: {
    display: 'flex',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 1,
    borderColor: colours.lightestGrey,
    lineHeight: space(0.6),
  },
  lineBoxHidden: {
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
    },
  },
  activeBox: {
    marginLeft: space(2),
    marginTop: space(2),
    fontSize: space(2),
    height: space(3),
    paddingLeft: space(3),
    paddingRight: space(3),
    paddingTop: 2,
    paddingBottom: 2,
    display: 'inline',
    [mediaQueries.md]: {
      marginRight: space(7),
    },
  },
  textBox: {
    flexGrow: 1,
    color: colours.darkestGrey,
    whiteSpace: 'nowrap',
    width: space(17),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [mediaQueries.md]: {
      overflow: 'visible',
      width: 'auto',
    },
  },
  button: {
    height: 'auto',
    textAlign: 'center',
    fontSize: fontSize.xs,
    background: 'none',
    padding: 0,
    display: 'block',
  },
  smallButton: {
    paddingRight: space(1),
    textAlign: 'right',
    color: colours.darkestGrey,
  },
}
export default styles
