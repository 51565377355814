// @flow
import React from 'react'
import styled from '@emotion/styled'
import { PopOverMenu, Variables } from '@mlcl-digital/mlcl-design'

// components.
import ButtonComponent from '../../../../../../../atoms/Button'

// hooks.
import useComponentVisible from '../../../../../../../../hooks/useComponentVisible'

// styles.
import styles from './quoteSettingActions.styles'

const { space } = Variables

const OptionsButton = styled(ButtonComponent)(
  styles.optionsButton,
  ({ isActive }) => isActive && styles.optionsButtonActive
)

const Container = styled('div')(styles.container)
const PopoverMenuContainer = styled(PopOverMenu)(styles.popoverMenu)

type QuoteSettingActionsProps = {
  quoteNameSettingActionsText: String,
  // settings list items
  popoverItems: Array<Object>,
}

const QuoteSettingActions = ({
  quoteNameSettingActionsText,
  popoverItems,
}: QuoteSettingActionsProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const handleButtonClick = () => setIsComponentVisible(true)
  return (
    <Container>
      <OptionsButton
        type="icon"
        data-testid="quoteSettingButton"
        data-locator="Quote_setting_button"
        onClick={handleButtonClick}
        isActive={isComponentVisible}
      >
        {quoteNameSettingActionsText}
      </OptionsButton>
      <div ref={ref}>
        <PopoverMenuContainer
          isOpen={isComponentVisible}
          items={popoverItems}
          top={space(4, true)}
          right={space(-16, true)}
        />
      </div>
    </Container>
  )
}

export default QuoteSettingActions
