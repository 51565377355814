import {
  space,
  colours,
  shadows,
  fontWeights,
  fontSize,
  fontFamily,
  QUOTE_SUMMARY_POLICY_CARD_DEFAULT_WIDTH,
} from '../../../../../../styles'

const styles = {
  quoteSummaryPolicyRoot: {
    background: colours.white,
    boxShadow: shadows.low,
    marginBottom: space(5, true),
    maxWidth: QUOTE_SUMMARY_POLICY_CARD_DEFAULT_WIDTH,
  },

  quoteSummaryPolicyHeader: {
    padding: `${space(3, true)} 0`,
    margin: `0 ${space(3, true)}`,
    borderBottom: `1px solid ${colours.lightestGrey}`,
    display: 'flex',
    justifyContent: 'space-between',
  },

  quoteSummaryPolicyName: {
    color: colours.darkestGrey,
    margin: 0,
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
    fontWeight: fontWeights.normal,
  },

  quoteSummaryPolicyFreqLabel: {
    color: colours.darkestGrey,
    marginRight: space(2, true),
  },

  quoteSummaryPolicyFreqValue: {
    color: colours.mediumDarkGrey,
    fontWeight: fontWeights.semiBold,
  },

  quoteSummaryPolicyTableHeadWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${space(3, true)} ${space(4, true)}`,
  },

  quoteSummaryPolicyTableHead: {
    opacity: 1,
    color: colours.darkestGrey,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
    fontFamily: fontFamily.sourceSans,
    margin: 0,
    '&:nth-child(1)': {
      width: '35%',
    },
    '&:nth-child(2)': {
      width: '25%',
    },
    '&:nth-child(3)': {
      width: '25%',
    },
    '&:nth-child(4)': {
      width: '15%',
      textAlign: 'right',
    },
  },
}

export default styles
