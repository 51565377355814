// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input, Button, Select, Checkbox, Modal, Loader } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../utils/telemetry'
import { actionCreators } from '../../../../actions'
// Components
import AddressLookUp from '../../AddressLookUp'
import InputPhone from '../../InputPhone'

// Selectors
import {
  getContactDetailsForm,
  getClientContactDetailsFormattedForForm,
  formatContactDetailsAsMemberDetailsRequestPayload,
  formatMemberDetailsForAdviserRequestPayload,
  getContactDetailsHasUpdateError,
} from '../../../../selectors/personalDetails'

// schema.
import SCHEMA, { FORM_ID } from './contactDetailsForm.schema'

// styles
import styles from './contactDetailsForm.styles'
import {
  TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
  TOAST_ID_UPDATE_CONTACT_ERROR,
  TOAST_ID_UPDATE_CONTACT_SUCCESS,
} from '../../../../constants/toast'

import { DASHBOARD_ROUTE } from '../../../../constants/routes'
import {
  PREFERRED_METHOD_OF_COMMS,
  PREFERRED_METHOD_OF_COMMS_EVENT_MAP,
} from '../../../../constants/forms'

import phoneNumberField from '../../../../constants/customerPersonalDetails'

// utils
import {
  errorCheck,
  generateFieldsFromData,
  getValue,
  validateAll,
} from '../../../../utils/formUtils'
import { normalizeContactDetails } from '../../../../utils/contactUtils'
import history from '../../../../utils/browserHistory'
import { reduceAuthorableFields, renderTextField } from '../../../../utils/sitecoreUtils'
import {
  POSTAL_ADDRESS_FIELDS,
  RESIDENTIAL_ADDRESS_FIELDS,
} from '../../../organisms/Alteration/AltsContactDetails/components/ContactDetailsForm'

const Form = styled('form')(styles)
const FormInput = styled(Input)(styles.input)
const FormInputPhone = styled(InputPhone)(styles.inputPhone)
const SelectComponent = styled(Select)(styles.title)
const ButtonsContainer = styled('div')(styles.buttonsContainer)
const FormAddressLookup = styled(AddressLookUp)(styles.input)
const Spinner = styled(Loader)(styles.spinner)
const PostalAddressCheckbox = styled(Checkbox)(styles.postalAddressCheckbox)

type ContactDetailsFormProps = {
  // An object containing action creator functions.
  actions: Object,
  // Sitecore authorable fields.
  fields: Object,
  // Form object in store
  form: Object,
  // A boolean value for manually seeting up the address
  isManualResidential: boolean,
  // method to close the edit contact details form
  closeContactDetailsForm: Function,
  // welcome journey flag
  hasCompletedWelcomeJourney: Boolean,
  // member details formatted to initialise form with
  contactDetailsFormattedForForm: Object,
  // member details updated a formatted to be sent in request payload
  memberDetailsRequestPayload: Object,
  // A boolean value for manually setting up the postal address
  isManualPostal: boolean,
  isPartyTypeOrg: boolean,
  isAdvisor: boolean,
  clientId: string,
  formattedRequestPayLoad: Object,
  hasUpdateError: boolean,
}

let isPOManual = false
export class ContactDetailsForm extends Component<
  ContactDetailsFormProps,
  ContactDetailsFormState
> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  componentDidMount() {
    const {
      actions: { formInit },
      isManualResidential,
      fields,
      contactDetailsFormattedForForm,
      isManualPostal,
      isPartyTypeOrg,
    } = this.props
    const hidePostal =
      !isPartyTypeOrg && get(contactDetailsFormattedForForm, 'showPostalAddressCheckbox', true)
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal },
      fields
    )

    formInit(FORM_ID, schema, contactDetailsFormattedForForm)
  }

  addressChange = ({ name, data, value }) => {
    const {
      actions: { formUpdateField, formUpdate, formValidate },
      isManualResidential,
      fields,
      isManualPostal,
      form,
      isPartyTypeOrg,
    } = this.props
    const hidePostal =
      !isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal },
      fields
    )
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
    if (name === 'residentialAddress') {
      formUpdateField(FORM_ID, 'hasAddressChanged', {
        error: false,
        value: 'YES',
      })
    } else {
      formUpdateField(FORM_ID, 'hasSecondaryAddressChanged', {
        error: false,
        value: 'YES',
      })
    }
  }

  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      isManualResidential,
      fields,
      isManualPostal,
      form,
      isPartyTypeOrg,
    } = this.props

    const hidePostal =
      !isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))

    if (name === 'postalAddressPONo') {
      isPOManual = Boolean(value)
    }
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, hidePostal, isManualPostal, isPOManual },
      fields
    )

    const data = {
      error: errorCheck(
        value,
        schema[name].condition,
        null,
        name === phoneNumberField ? form.fields : {}
      ),
      value,
    }

    formUpdateField(FORM_ID, name, data)

    if (name === 'postalAddressPONo' && form.isDirty) {
      const postalAddressStreetErrorUpdate = {
        error: errorCheck(
          getValue(get(form, 'fields.postalAddressStreet', '')),
          schema.postalAddressStreet.condition
        ),
      }
      formUpdateField(FORM_ID, 'postalAddressStreet', postalAddressStreetErrorUpdate)
    }
    formValidate(FORM_ID, schema)
    if (RESIDENTIAL_ADDRESS_FIELDS.includes(name)) {
      formUpdateField(FORM_ID, 'hasAddressChanged', {
        error: false,
        value: 'YES',
      })
    } else if (POSTAL_ADDRESS_FIELDS.includes(name)) {
      formUpdateField(FORM_ID, 'hasSecondaryAddressChanged', {
        error: false,
        value: 'YES',
      })
    }

    if (name === PREFERRED_METHOD_OF_COMMS) {
      const event = createEvent({
        GA: {
          category: 'Contact details edit modal',
          action: PREFERRED_METHOD_OF_COMMS_EVENT_MAP[value.value],
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Contact details edit modal',
            action: PREFERRED_METHOD_OF_COMMS_EVENT_MAP[value.value],
          },
        },
      })
      event.end()
    }
  }

  addressToggleHandler = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props
    if (isManual) {
      formResetField(FORM_ID, RESIDENTIAL_ADDRESS_FIELDS)
    }
  }

  togglePostalAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      form,
      fields,
      isManualPostal,
      isManualResidential,
      isPartyTypeOrg,
    } = this.props
    const hidePostal =
      !isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))

    const schema = SCHEMA(fields, isManualResidential, isManualPostal, hidePostal)
    const data = {
      error: errorCheck(value, schema[name] && schema[name].condition),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressToggleHandlerPostal = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props
    // We clear address fields when entering manual mode
    if (isManual) {
      formResetField(FORM_ID, POSTAL_ADDRESS_FIELDS)
    } else {
      formResetField(FORM_ID, ['postalAddressPONo'])
    }
  }

  updateMemberDetails = () => {
    const {
      actions: { updateClientDetails, updateContactDetails },
      closeContactDetailsForm,
      hasCompletedWelcomeJourney,
      memberDetailsRequestPayload,
      isAdvisor,
      clientId,
      formattedRequestPayLoad,
    } = this.props

    const updateDetailsPayload = {
      data: formattedRequestPayLoad,
      clientId,
    }
    this.setState({ isLoading: true })
    if (!isAdvisor) {
      updateContactDetails(normalizeContactDetails(memberDetailsRequestPayload), (data, err) => {
        this.setState({ isLoading: false })
        if (err) {
          toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
            toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
            type: toast.TYPE.ERROR,
          })
        } else {
          toast(TOAST_ID_UPDATE_CONTACT_SUCCESS, {
            toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
            type: toast.TYPE.SUCCESS,
          })
          closeContactDetailsForm()
          if (!hasCompletedWelcomeJourney) {
            history.push(DASHBOARD_ROUTE)
          }
        }
      })
    } else {
      updateClientDetails(updateDetailsPayload, (data, err) => {
        this.setState({ isLoading: false })
        if (err) {
          toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
            toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
            type: toast.TYPE.ERROR,
          })
        } else {
          toast(TOAST_ID_UPDATE_CONTACT_SUCCESS, {
            toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
            type: toast.TYPE.SUCCESS,
          })
          closeContactDetailsForm()
        }
      })
    }
  }

  onSubmit = () => {
    const {
      actions: { formSubmit, formSubmitComplete, formUpdateValidations },
      isManualResidential,
      fields,
      form,
      hasCompletedWelcomeJourney,
      isManualPostal,
      isAdvisor,
      isPartyTypeOrg,
    } = this.props

    const hidePostal =
      !isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))

    isPOManual = Boolean(getValue(get(form, 'fields.postalAddressPONo', '')))

    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal, isPOManual },
      fields
    )

    // Analytics
    const event = createEvent({
      GA: {
        category: 'Contact details edit modal',
        action: 'CP - confirm contact details',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Contact details edit modal - Confirm',
        },
      },
    })
    event.end()

    formUpdateValidations(FORM_ID, schema)
    if (validateAll(schema, form.fields)) {
      if (form.isDirty) {
        formSubmit(FORM_ID, schema, () => {
          this.updateMemberDetails()
        })
        formSubmitComplete(FORM_ID)
        const customerEvent = createEvent({
          GA: {
            category: 'Editing contact details - client info tab',
            action: 'Edit',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Editing contact details - client info tab',
            },
          },
        })
        !isAdvisor && customerEvent.end()
      } else if (!isAdvisor && !hasCompletedWelcomeJourney) {
        history.push(DASHBOARD_ROUTE)
      }
    }
  }

  onCancel = () => {
    const { closeContactDetailsForm } = this.props
    closeContactDetailsForm()
    this.setState({ isLoading: false })
  }

  // Close the Modal
  onModalClose = () => {
    const {
      closeContactDetailsForm,
      isAdvisor,
      actions: {
        resetCustomerContactDetailsHasUpdateError,
        resetClientContactDetailsHasUpdateError,
      },
    } = this.props

    if (isAdvisor) {
      resetClientContactDetailsHasUpdateError()
    } else {
      resetCustomerContactDetailsHasUpdateError()
    }

    closeContactDetailsForm()
    this.setState({ isLoading: false })
  }

  // Submitting form again
  onTryAgain = () => {
    const {
      actions: {
        resetCustomerContactDetailsHasUpdateError,
        resetClientContactDetailsHasUpdateError,
      },
      isAdvisor,
    } = this.props
    this.setState({ isLoading: false })
    if (isAdvisor) {
      resetClientContactDetailsHasUpdateError()
    } else {
      resetCustomerContactDetailsHasUpdateError()
    }
    this.updateMemberDetails()
  }

  /** Handler for rendering a Retry Modal */
  renderRetryModal() {
    const { hasUpdateError, fields } = this.props
    const { ContactDetailsModalCTA, ContactDetailsModalDesc, ContactDetailsModalTitle } =
      reduceAuthorableFields(fields)
    return (
      <Modal isOpen={hasUpdateError} onClose={this.onModalClose} title={ContactDetailsModalTitle}>
        <p>{renderTextField(ContactDetailsModalDesc)}</p>
        <Button type="secondary" onClick={this.onTryAgain}>
          {ContactDetailsModalCTA}
        </Button>
      </Modal>
    )
  }

  render() {
    const {
      fields,
      form,
      isManualResidential,
      hasCompletedWelcomeJourney,
      isManualPostal,
      isPartyTypeOrg,
    } = this.props
    if (!form) return null
    const {
      ContactDetailsFormResidentialAddressFieldLabel,
      ContactDetailsFormResidentialAddressFieldPlaceholder,
      ContactDetailsFormResidentialAddressStreetFieldLabel,
      ContactDetailsFormResidentialAddressStreetFieldPlaceholder,
      ContactDetailsFormResidentialAddressHouseNoFieldLabel,
      ContactDetailsFormResidentialAddressHouseNoFieldPlaceholder,
      ContactDetailsFormPostalAddressPONoFieldPlaceholder,
      ContactDetailsFormPostalAddressHouseNoFieldPlaceholder,
      ContactDetailsFormResidentialAddressLocalityFieldLabel,
      ContactDetailsFormResidentialAddressLocalityFieldPlaceholder,
      ContactDetailsFormResidentialAddressStateFieldLabel,
      ContactDetailsFormResidentialAddressStateFieldPlaceholder,
      ContactDetailsFormResidentialAddressCountryFieldLabel,
      ContactDetailsFormResidentialAddressCountryFieldPlaceholder,
      ContactDetailsFormResidentialAddressPostCodeFieldLabel,
      ContactDetailsFormResidentialAddressPostCodeFieldPlaceholder,
      ContactDetailsFormResidentialAddressToggleToAutoText,
      ContactDetailsEmailAddress,
      ContactDetailsPhone,
      ContactDetailsPhoneCode,
      ContactDetailsPreferredMethodOfComms,
      ContactDetailsUseAsPostal,
      ContactDetailsPostalAddressLabel,
      ContactDetailsPostalAddressPlaceholder,
      ContactDetailsCantFindAddressLabel,
    } = reduceAuthorableFields(fields)
    const {
      phones,
      phoneCode,
      emails,
      residentialAddress,
      residentialAddressHouseNo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry,
      residentialAddressPostCode,
      preferredMethodOfComms,
      showPostalAddressCheckbox,
      postalAddress,
      postalAddressStreet,
      postalAddressHouseNo,
      postalAddressPONo,
      postalAddressLocality,
      postalAddressState,
      postalAddressCountry,
      postalAddressPostCode,
    } = form.fields

    // If partyType is ORG, show postal address and hide home address.
    const hidePostal =
      !isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))

    // If partyType is ORG, send hidePostal as false to make home address optional.
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal },
      fields
    )

    const postalAddressInvalid = postalAddressCountry?.error.error

    const selectPhoneCode = {
      label: ContactDetailsPhoneCode,
      value: phoneCode.value,
      name: 'phoneCode',
      id: 'phoneCode',
      changeHandler: this.handleChange,
    }
    const inputPhoneNumber = {
      htmlFor: 'phones',
      name: 'phones',
      label: ContactDetailsPhone,
      placeholder: ContactDetailsPhone,
      value: phones.value,
      changeHandler: this.handleChange,
      type: 'text',
      error: phones.error.error,
      caption: phones.error.error && phones.error.errorMsg.value,
    }
    const { isLoading } = this.state
    return (
      <Fragment>
        <Form id={FORM_ID} aria-labelledby="application-overview">
          <FormInputPhone selectPhoneCode={selectPhoneCode} inputPhoneNumber={inputPhoneNumber} />
          <FormInput
            htmlFor="emails"
            name="emails"
            label={ContactDetailsEmailAddress}
            changeHandler={this.handleChange}
            placeholder={ContactDetailsEmailAddress}
            value={emails && emails.value}
            error={emails.error.error}
            caption={emails.error.error && emails.error.errorMsg.value}
          />
          <Fragment>
            {!isPartyTypeOrg && (
              <FormAddressLookup
                toggleHandler={this.addressToggleHandler}
                name="residentialAddress"
                auto={{
                  label: ContactDetailsFormResidentialAddressFieldLabel,
                  placeholder: ContactDetailsFormResidentialAddressFieldPlaceholder,
                  toggleLabel: ContactDetailsCantFindAddressLabel,
                  value: residentialAddress.value,
                  selectChangeHandler: this.addressChange,
                  addressName: 'residentialAddress',
                  addressError: residentialAddress.error.error,
                  caption:
                    residentialAddress.error.error && residentialAddress.error.errorMsg.value,
                  addressErrorMessage:
                    residentialAddress.error.error && residentialAddress.error.errorMsg.value,
                }}
                manual={{
                  streetLabel: ContactDetailsFormResidentialAddressStreetFieldLabel,
                  streetPlaceholder: ContactDetailsFormResidentialAddressStreetFieldPlaceholder,
                  streetValue: residentialAddressStreet.value,
                  streetRequiredErrorMessage: get(
                    residentialAddressStreet,
                    'error.errorMsg.value',
                    ''
                  ),
                  streetError: residentialAddressStreet.error.error,
                  caption:
                    residentialAddressStreet.error.error &&
                    residentialAddressStreet.error.errorMsg.value,
                  streetName: 'residentialAddressStreet',
                  houseNoLabel: ContactDetailsFormResidentialAddressHouseNoFieldLabel,
                  houseNoPlaceholder: ContactDetailsFormResidentialAddressHouseNoFieldPlaceholder,
                  houseNoValue: residentialAddressHouseNo.value,
                  houseNoError: residentialAddressHouseNo.error.error,
                  houseNoRequiredErrorMessage: get(
                    residentialAddressHouseNo,
                    'error.errorMsg.value',
                    ''
                  ),
                  houseNoName: 'residentialAddressHouseNo',
                  localityLabel: ContactDetailsFormResidentialAddressLocalityFieldLabel,
                  localityPlaceholder: ContactDetailsFormResidentialAddressLocalityFieldPlaceholder,
                  localityLabelValue: residentialAddressLocality.value,
                  localityError: residentialAddressLocality.error.error,
                  localityRequiredErrorMessage: get(
                    residentialAddressLocality,
                    'error.errorMsg.value',
                    ''
                  ),
                  localityName: 'residentialAddressLocality',
                  stateLabel: ContactDetailsFormResidentialAddressStateFieldLabel,
                  statePlaceholder: ContactDetailsFormResidentialAddressStateFieldPlaceholder,
                  stateOptions: schema.residentialAddressState.options,
                  stateValue: residentialAddressState.value,
                  stateError: residentialAddressState.error.error,
                  stateRequiredErrorMessage: get(
                    residentialAddressState,
                    'error.errorMsg.value',
                    ''
                  ),
                  stateName: 'residentialAddressState',
                  countryLabel: ContactDetailsFormResidentialAddressCountryFieldLabel,
                  countryPlaceholder: ContactDetailsFormResidentialAddressCountryFieldPlaceholder,
                  countryValue: residentialAddressCountry.value,
                  countryError: residentialAddressCountry.error.error,
                  countryRequiredErrorMessage: get(
                    residentialAddressCountry,
                    'error.errorMsg.value',
                    ''
                  ),
                  countryName: 'residentialAddressCountry',
                  postCodeLabel: ContactDetailsFormResidentialAddressPostCodeFieldLabel,
                  postCodePlaceholder: ContactDetailsFormResidentialAddressPostCodeFieldPlaceholder,
                  postCodeValue: residentialAddressPostCode.value,
                  postCodeError: residentialAddressPostCode.error.error,
                  postCodeRequiredErrorMessage: get(
                    residentialAddressPostCode,
                    'error.errorMsg.value',
                    ''
                  ),
                  postCodeName: 'residentialAddressPostCode',
                  manualToggleLabel: ContactDetailsFormResidentialAddressToggleToAutoText,
                  inputEntryHandler: this.handleChange,
                  postalType: 'residentialAddressPostalType',
                  postalNumber: 'residentialAddressPostalNumber',
                }}
              />
            )}

            {!isPartyTypeOrg && (
              <PostalAddressCheckbox
                text={ContactDetailsUseAsPostal}
                name="showPostalAddressCheckbox"
                htmlFor="showPostalAddressCheckbox"
                onChangeHandler={this.togglePostalAddress}
                checked={showPostalAddressCheckbox.value}
              />
            )}
          </Fragment>
          {(!showPostalAddressCheckbox.value || isPartyTypeOrg) && (
            <FormAddressLookup
              toggleHandler={this.addressToggleHandlerPostal}
              name="postalAddress"
              auto={{
                label: ContactDetailsPostalAddressLabel,
                placeholder: ContactDetailsPostalAddressPlaceholder,
                toggleLabel: ContactDetailsCantFindAddressLabel,
                value: postalAddress.value,
                selectChangeHandler: this.addressChange,
                addressError: postalAddress.error.error || postalAddressInvalid,
                addressName: 'postalAddress',
                caption: postalAddress.error.error && postalAddress.error.errorMsg.value,
                addressErrorMessage:
                  postalAddress.error.error && postalAddress.error.errorMsg.value,
              }}
              manual={{
                streetLabel: ContactDetailsFormResidentialAddressStreetFieldLabel,
                streetPlaceholder: ContactDetailsFormResidentialAddressStreetFieldPlaceholder,
                streetValue: postalAddressStreet.value,
                streetRequiredErrorMessage: get(postalAddressStreet, 'error.errorMsg.value', ''),
                streetError: postalAddressStreet.error.error,
                caption: get(postalAddressStreet, 'error.errorMsg.value', ''),
                streetName: 'postalAddressStreet',
                houseNoLabel: ContactDetailsFormResidentialAddressHouseNoFieldLabel,
                houseNoPlaceholder: ContactDetailsFormPostalAddressHouseNoFieldPlaceholder,
                houseNoValue: postalAddressHouseNo.value,
                houseNoError: postalAddressHouseNo.error.error,
                houseNoRequiredErrorMessage: get(postalAddressHouseNo, 'error.errorMsg.value', ''),
                houseNoName: 'postalAddressHouseNo',
                postOfficeNoPlaceholder: ContactDetailsFormPostalAddressPONoFieldPlaceholder,
                postOfficeNoValue: postalAddressPONo.value,
                postOfficeNoError: postalAddressPONo.error.error,
                postOfficeNoRequiredErrorMessage: get(
                  postalAddressPONo,
                  'error.errorMsg.value',
                  ''
                ),
                postOfficeNoName: 'postalAddressPONo',
                localityLabel: ContactDetailsFormResidentialAddressLocalityFieldLabel,
                localityPlaceholder: ContactDetailsFormResidentialAddressLocalityFieldPlaceholder,
                localityLabelValue: postalAddressLocality.value,
                localityError: postalAddressLocality.error.error,
                localityRequiredErrorMessage: get(
                  postalAddressLocality,
                  'error.errorMsg.value',
                  ''
                ),
                localityName: 'postalAddressLocality',
                stateLabel: ContactDetailsFormResidentialAddressStateFieldLabel,
                statePlaceholder: ContactDetailsFormResidentialAddressStateFieldPlaceholder,
                stateOptions: schema.postalAddressState.options,
                stateValue: postalAddressState.value,
                stateError: postalAddressState.error.error,
                stateRequiredErrorMessage: get(postalAddressState, 'error.errorMsg.value', ''),
                stateName: 'postalAddressState',
                countryLabel: ContactDetailsFormResidentialAddressCountryFieldLabel,
                countryPlaceholder: ContactDetailsFormResidentialAddressCountryFieldPlaceholder,
                countryValue: postalAddressCountry.value,
                countryError: postalAddressCountry.error.error,
                countryRequiredErrorMessage: get(postalAddressCountry, 'error.errorMsg.value', ''),
                countryName: 'postalAddressCountry',
                postCodeLabel: ContactDetailsFormResidentialAddressPostCodeFieldLabel,
                postCodePlaceholder: ContactDetailsFormResidentialAddressPostCodeFieldPlaceholder,
                postCodeValue: postalAddressPostCode.value,
                postCodeError: postalAddressPostCode.error.error,
                postCodeRequiredErrorMessage: get(
                  postalAddressPostCode,
                  'error.errorMsg.value',
                  ''
                ),
                postCodeName: 'postalAddressPostCode',
                manualToggleLabel: ContactDetailsFormResidentialAddressToggleToAutoText,
                inputEntryHandler: this.handleChange,
                postalType: 'postalAddressPostalType',
                postalNumber: 'postalAddressPostalNumber',
              }}
            />
          )}
          {ContactDetailsPreferredMethodOfComms && (
            <SelectComponent
              label={ContactDetailsPreferredMethodOfComms}
              placeholder="None"
              value={preferredMethodOfComms.value}
              name={PREFERRED_METHOD_OF_COMMS}
              id={PREFERRED_METHOD_OF_COMMS}
              changeHandler={this.handleChange}
              options={schema.preferredMethodOfComms.options}
              error={preferredMethodOfComms.error.error}
              caption={
                preferredMethodOfComms.error.error && preferredMethodOfComms.error.errorMsg.value
              }
            />
          )}
          <ButtonsContainer>
            <Button variant="secondary" size="small" onClick={this.onSubmit}>
              {isLoading ? <Spinner spinnerSize={Number(20)} borderSize={Number(2)} /> : 'Confirm'}
            </Button>
            {hasCompletedWelcomeJourney && (
              <Button variant="tertiary" size="small" onClick={this.onCancel}>
                Cancel
              </Button>
            )}
          </ButtonsContainer>
        </Form>
        {this.renderRetryModal()}
      </Fragment>
    )
  }
}

export const mapStateToProps = state => ({
  contactDetailsFormattedForForm: getClientContactDetailsFormattedForForm(state),
  memberDetailsRequestPayload: formatContactDetailsAsMemberDetailsRequestPayload(state),
  form: getContactDetailsForm(state),
  formattedRequestPayLoad: formatMemberDetailsForAdviserRequestPayload(state),
  hasUpdateError: getContactDetailsHasUpdateError(state),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsForm)
