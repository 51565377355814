// @flow
import React from 'react'
import FullUreEngine from '../../../organisms/FullUreEngine'

type EngineProps = {
  fields: Object<Object>,
}

const Engine = ({ fields }: EngineProps) => <FullUreEngine fields={fields} />

export default Engine
