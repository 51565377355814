// @flow
import React, { PureComponent, Fragment } from 'react'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { SectionHeader } from '@mlcl-digital/mlcl-design'

// styles
import styles from './directorDetails.styles'

// utils
import { fetchDirectorSecretaryDetails } from '../../../../../utils/clientDetailsUtils'
import {
  getFullAddressWithState,
  getEmail,
  getPhoneNumber,
  getNonPreferredLatestItemBreakdown,
  formatPhoneNumberWithSpaces,
} from '../../../../../utils/contactUtils'

const Wrap = styled('div')(styles.wrap)
const DirectorDetailsCard = styled('div')(styles.directorDetailsCard)
const DirectorDetailsHeader = styled('div')(styles.directorDetailsHeader)
const DirectorName = styled('div')(styles.directorName)
const Role = styled('div')(styles.role)
const DirectorDetailsContainer = styled('div')(styles.directorDetailsContainer)
const Wrapper = styled('div')(styles.wrapper)
const KeyValueContainer = styled('div')(styles.keyValueContainer)
const Key = styled('div')(styles.key)
const Value = styled('div')(styles.value)

type DirectorDetailsComponentProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // clientDetails object
  clientDetails: Object,
}

export class DirectorDetailsComponent extends PureComponent<DirectorDetailsComponentProps> {
  renderDirectorSecretaryCard = dirOrTrustee => {
    const {
      fields: {
        adviserPortalClientDetailsSecretaryHeading,
        adviserPortalClientDetailsDirectorHeading,
        adviserPortalClientDetailsTrusteeHeading,
        adviserPortalClientDetailsDirectorDetailsPhoneHeading,
        adviserPortalClientDetailsDirectorDetailsSecondaryPhoneHeading,
        adviserPortalClientDetailsDirectorDetailsEmailHeading,
        adviserPortalClientDetailsDirectorDetailsAddressHeading,
      },
    } = this.props

    const isTrusteeAnIndividual =
      dirOrTrustee && dirOrTrustee.level2 !== undefined && dirOrTrustee.level2.length === 0

    const isSecretary = get(dirOrTrustee, 'isSecretary', false)
    const name = `${get(dirOrTrustee, 'relatedParty.firstName', '')} ${get(
      dirOrTrustee,
      'relatedParty.lastName',
      ''
    )}`
    const address = getFullAddressWithState(
      get(dirOrTrustee, 'relatedParty.contactMethods.addresses', [])
    )
    const email = getEmail(get(dirOrTrustee, 'relatedParty.contactMethods.emails', []))
    const phone = getPhoneNumber(get(dirOrTrustee, 'relatedParty.contactMethods.phones', []))

    const secondaryPhone = getNonPreferredLatestItemBreakdown(
      get(dirOrTrustee, 'relatedParty.contactMethods.phones', [])
    )

    return (
      <DirectorDetailsCard key={name}>
        <DirectorDetailsHeader>
          <DirectorName>{name}</DirectorName>
          <Role>
            {(isTrusteeAnIndividual && adviserPortalClientDetailsTrusteeHeading) ||
              (isSecretary
                ? adviserPortalClientDetailsSecretaryHeading
                : adviserPortalClientDetailsDirectorHeading)}
          </Role>
        </DirectorDetailsHeader>
        <DirectorDetailsContainer>
          <Wrapper>
            <KeyValueContainer>
              <Key>{adviserPortalClientDetailsDirectorDetailsPhoneHeading}</Key>
              <Value>{phone && formatPhoneNumberWithSpaces(phone)}</Value>
            </KeyValueContainer>
            <KeyValueContainer>
              <Key>{adviserPortalClientDetailsDirectorDetailsSecondaryPhoneHeading}</Key>
              <Value>
                {secondaryPhone &&
                  secondaryPhone.number &&
                  formatPhoneNumberWithSpaces(secondaryPhone.number)}
              </Value>
            </KeyValueContainer>
            <KeyValueContainer>
              <Key>{adviserPortalClientDetailsDirectorDetailsEmailHeading}</Key>
              <Value>{email}</Value>
            </KeyValueContainer>
            <KeyValueContainer>
              <Key>{adviserPortalClientDetailsDirectorDetailsAddressHeading}</Key>
              <Value>{address}</Value>
            </KeyValueContainer>
          </Wrapper>
        </DirectorDetailsContainer>
      </DirectorDetailsCard>
    )
  }

  renderDirectorSecretaryDetails = () => {
    const {
      fields: {
        adviserPortalClientDetailsDirectorDetailsHeading,
        adviserPortalClientDetailsDirectorDetailsSubHeading,
      },
      clientDetails,
    } = this.props
    const memberEntity = fetchDirectorSecretaryDetails(clientDetails)

    return memberEntity && memberEntity.length ? (
      <Fragment>
        <SectionHeader
          heading={adviserPortalClientDetailsDirectorDetailsHeading}
          subHeading={adviserPortalClientDetailsDirectorDetailsSubHeading}
        />
        <Wrap>
          {memberEntity.map(dirOrTrustee => this.renderDirectorSecretaryCard(dirOrTrustee))}
        </Wrap>
      </Fragment>
    ) : null
  }

  render() {
    return <Fragment>{this.renderDirectorSecretaryDetails()}</Fragment>
  }
}

export default DirectorDetailsComponent
