import { Variables } from '@mlcl-digital/mlcl-design'
import { mediaQueriesMax } from '../../../styles'

const { space, colours, fontSize, shadows, fontWeights } = Variables

const styles = {
  topBackground: {
    backgroundColor: colours.mlcAquaDark,
    color: colours.tertiarySix,
  },
  navSection: {
    h1: {
      color: colours.tertiarySix,
    },
    padding: `${space(8, true)} 0 ${space(19, true)}`,
  },
  headerDescription: {
    color: colours.tertiarySix,
  },
  nav: {
    display: 'block',
    marginBottom: space(2),
    paddingLeft: 0,
    height: 'auto',
    fontSize: fontSize.xs,
    color: colours.tertiarySix,
    textDecoration: 'none',
    svg: {
      marginRight: space(1),
      marginTop: space(-0.25, true),
      color: colours.tertiarySix,
    },
    '&:hover': {
      color: colours.mlcAquaCustomer,
      svg: {
        color: colours.mlcAquaCustomer,
      },
    },
  },
  wrap: {
    width: '100%',
    background: colours.tertiarySix,
    marginTop: space(-8),
    marginBottom: space(15),
    boxShadow: shadows.mid,
  },
  details: {
    padding: `${space(5, true)} ${space(7, true)}`,
  },
  inputField: {
    maxWidth: space(50),
    marginBottom: space(3),
  },
  formContainer: {
    borderTop: `1px solid ${colours.lightGrey}`,
    padding: `${space(3, true)} 0 ${space(2, true)}`,
    margin: `${space(3, true)} 0 ${space(5, true)}`,
    borderBottom: `1px solid ${colours.lightGrey}`,
  },
  infocontainer: {
    display: 'flex',
    flexFlow: 'column',
    alignContent: 'start',
    paddingBottom: space(2, true),
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: space(2),
  },
  container: {
    display: 'flex',
    flexFlow: 'row',
    alignContent: 'start',
    paddingBottom: space(2, true),
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: space(2),
  },
  infoBox: {
    color: colours.mlcAquaAccessible,
    paddingLeft: space(4, true),
    [mediaQueriesMax.md]: { paddingLeft: space(2, true) },
  },
  infoBoxHeader: { fontWeight: fontWeights.semiBold, fontSize: fontSize.xsm },
  infoWrap: {
    background: colours.functionalInfoLight,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colours.lightGrey,
    boxShadow: shadows.low,
    maxWidth: `${space(48, true)}`,
  },
  infoContentWrap: { padding: space(1), color: colours.functionalInfoDark },
  infoIcon: {
    color: colours.functionalInfoDark,
    fontSize: fontSize.smd,
    width: '25px',
    float: 'left',
    marginTop: space(1),
    paddingTop: space(1),
    [mediaQueriesMax.md]: {
      marginBottom: '-50px',
      marginLeft: '-15px',
    },
  },
  infoBoxContent: { fontSize: fontSize.xsm },

  formContent: {
    marginBottom: space(3),
  },
}
export default styles
