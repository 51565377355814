// @flow
import React from 'react'
import { objectOf, object } from 'prop-types'
// components.
import ContactMLCComponent from '../../../organisms/ContactMLC'
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'

const ContactMLC = ({ fields }) => <ContactMLCComponent fields={reduceAuthorableFields(fields)} />

ContactMLC.propTypes = {
  fields: objectOf(object).isRequired,
}
export default ContactMLC
