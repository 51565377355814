import { Variables } from '@mlcl-digital/mlcl-design'

import { VariablesType } from '../../../../../types/ComponentLibrary'

const { space } = Variables as VariablesType

const styles = {
  pageWrap: {
    marginTop: space(7.5),
  },
}

export default styles
