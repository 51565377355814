import { colours, QUOTE_LEFT_SIDEBAR_WIDTH, space } from '../../../../../../styles'

const styles = {
  panelRoot: {
    position: 'fixed',
    height: '100%',
    width: QUOTE_LEFT_SIDEBAR_WIDTH,
    background: colours.darkestGreen,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: space(10),
  },
}

export default styles
