// @flow
import React from 'react'
// components.
import Registration from '../../../organisms/AdviserRegistration'

type AdviserRegistrationProps = {
  // Sitecore fields.
  fields: Object<Object>,
}

const AdviserRegistration = ({ fields }: AdviserRegistrationProps) => (
  <Registration fields={fields} />
)

export default AdviserRegistration
