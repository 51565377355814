// @flow
import React, { PureComponent, Fragment } from 'react'
import get from 'lodash/get'
import queryString from 'query-string'
import styled from '@emotion/styled'

// utils

import history from '../../../../../utils/browserHistory'
import { getFullAddressWithState, getEmail } from '../../../../../utils/contactUtils'

// styles
import styles from './contactDetails.styles'

const ContactDetailsCard = styled('div')(styles.contactDetailsCard)
const ContactDetailsHeader = styled('div')(styles.contactDetailsHeader)
export const EditDetails = styled('span')(styles.editDetails)
const ContactDetailsContainer = styled('div')(styles.contactDetailsContainer)
const Wrapper = styled('div')(styles.wrapper)
const KeyValueContainer = styled('div')(styles.keyValueContainer)
const Key = styled('div')(styles.key)
const Value = styled('div')(styles.value)

type ContactDetailsComponentProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  // setActiveTab function
  setActiveTab: Function,
  // clientDetails object
  clientDetails: Object,
  isSMSF: Boolean,
}

export class ContactDetailsComponent extends PureComponent<ContactDetailsComponentProps> {
  editContactDetails = () => {
    const { setActiveTab } = this.props
    const {
      location: { search: searchParams },
    } = history
    const { clientId } = queryString.parse(searchParams)
    // FIXME: Path is still missing query strings
    const path = `/clients/details?clientId=${clientId}#Client%20info`
    setActiveTab('Client info')
    history.push(path)
  }

  renderClientDetails = () => {
    const {
      fields: {
        adviserPortalClientDetailsContactDetailsCompanyDetailsHeading,
        adviserPortalClientDetailsContactDetailsSMSFFundDetailsHeading,
        adviserPortalClientDetailsContactDetailsCompanyDetailsSuperannuationFundHeading,
        adviserPortalClientDetailsContactDetailsCompanyDetailsEditHeading,
        adviserPortalClientDetailsContactDetailsCompanyDetailsCompanyNameHeading,
        adviserPortalClientDetailsContactDetailsCompanyDetailsABNHeading,
        adviserPortalClientDetailsContactDetailsCompanyDetailsAddressHeading,
        adviserPortalClientDetailsContactDetailsCompanyDetailsEmailHeading,
      },
      clientDetails,
      isSMSF,
    } = this.props
    const abn = get(clientDetails, 'relatedParty.abnNumber', '')
    const businessName = get(clientDetails, 'relatedParty.businessName', '')
    const address = getFullAddressWithState(
      get(clientDetails, 'relatedParty.contactMethods.addresses', [])
    )
    const email = getEmail(get(clientDetails, 'relatedParty.contactMethods.emails', []))

    return (
      <ContactDetailsCard>
        <ContactDetailsHeader>
          {isSMSF
            ? adviserPortalClientDetailsContactDetailsSMSFFundDetailsHeading
            : adviserPortalClientDetailsContactDetailsCompanyDetailsHeading}
          <EditDetails onClick={this.editContactDetails}>
            {adviserPortalClientDetailsContactDetailsCompanyDetailsEditHeading}
          </EditDetails>
        </ContactDetailsHeader>
        <ContactDetailsContainer>
          <Wrapper>
            <KeyValueContainer>
              <Key>
                {isSMSF
                  ? adviserPortalClientDetailsContactDetailsCompanyDetailsSuperannuationFundHeading
                  : adviserPortalClientDetailsContactDetailsCompanyDetailsCompanyNameHeading}
              </Key>
              <Value>{businessName}</Value>
            </KeyValueContainer>
            <KeyValueContainer>
              <Key>{adviserPortalClientDetailsContactDetailsCompanyDetailsABNHeading}</Key>
              <Value>{abn}</Value>
            </KeyValueContainer>
            <KeyValueContainer>
              <Key>{adviserPortalClientDetailsContactDetailsCompanyDetailsAddressHeading}</Key>
              <Value>{address}</Value>
            </KeyValueContainer>
          </Wrapper>
          <Wrapper>
            <KeyValueContainer>
              <Key>{adviserPortalClientDetailsContactDetailsCompanyDetailsEmailHeading}</Key>
              <Value>{email}</Value>
            </KeyValueContainer>
          </Wrapper>
        </ContactDetailsContainer>
      </ContactDetailsCard>
    )
  }

  render() {
    return <Fragment>{this.renderClientDetails()}</Fragment>
  }
}

export default ContactDetailsComponent
