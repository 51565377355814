// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// components.
import QuoteSummaryHeader from './Header'
import QuoteSummaryPolicy from './Policy'

// styles.
import styles from './policyDetail.styles'

type QuoteSummaryPolicyDetailProps = {
  // Sitecore fields
  fields: Object,
  // Current Quote Information
  quoteData: Object,
  // Product Rules Object
  productRules: Object,
}

const QuoteSummaryRoot = styled('section')(styles.quoteSummaryRoot)

/**
 * QuoteSummaryPolicyDetail
 * This component will render the setion containing the Quote Summary Header
 * and Premium Information(s)
 */
export const QuoteSummaryPolicyDetail = ({
  fields,
  quoteData,
  productRules,
}: QuoteSummaryPolicyDetailProps) => {
  const policyStructure = quoteData !== undefined ? quoteData.policyStructure : []
  return (
    <Fragment>
      <QuoteSummaryRoot>
        <QuoteSummaryHeader fields={fields} />
        {policyStructure && policyStructure !== undefined && policyStructure.length !== 0
          ? policyStructure.map((policy, policyIndex) => (
              // @FIXME: need to determine better key
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={policyIndex}>
                <QuoteSummaryPolicy
                  options={get(quoteData, 'option', {})}
                  fields={fields}
                  appliedPolicy={policy}
                  policyStructure={policyStructure}
                  productRules={productRules}
                />
              </Fragment>
            ))
          : null}
      </QuoteSummaryRoot>
    </Fragment>
  )
}

export const mapDispatchToProp = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ productRules: { data } }) => ({
  productRules: data,
})

export default connect(mapStateToProps, mapDispatchToProp)(QuoteSummaryPolicyDetail)
