// @flow
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { createEvent } from '../../../utils/telemetry'

import PageHeader from '../../molecules/PageHeader'
import { getTheme, getIsAdviserPortal, getCreateQuote } from '../../../selectors/common.selectors'
import {
  makeSubHeading,
  makeLifeInsuredForAlterationsFlow,
  getIsSMSF,
} from '../../../selectors/alterations'
import history from '../../../utils/browserHistory'
import {
  ALTERATION_TYPES,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
} from '../../../constants/alterations'

type HeaderProps = {
  // sitecore labels
  fields: Object,
}
const Header = ({ fields }: HeaderProps) => {
  const siteCoreCustomerNoText = get(fields, 'content.value', '')
  const alterationType = get(useSelector(getCreateQuote), 'alterationType', '')
  const bancsCustomerNoLA = get(
    useSelector(makeLifeInsuredForAlterationsFlow),
    'bancsCustomerNo',
    ''
  )
  const isSMSF = useSelector(getIsSMSF)
  const isAdviserPortal = useSelector(getIsAdviserPortal)
  const backClickHandler = useCallback(() => {
    let url = get(fields, 'CTA.value.href')
    const analyticsCategory = get(fields, 'AnalyticsCategory.value')
    /**
     * Creating url for client details when navigating from listing page.
     * For other case, since client details is already visited, data is already
     * present in redux store
     */
    if (isAdviserPortal && bancsCustomerNoLA) {
      url = `${url}?clientId=${bancsCustomerNoLA}&isSMSF=${isSMSF}`
    }
    if (analyticsCategory) {
      const tagEvent = createEvent({
        GA: {
          category: analyticsCategory,
          action: get(fields, 'AnalyticsAction.value'),
        },
        Splunk: {
          attributes: {
            'workflow.name': `${analyticsCategory} - ${get(fields, 'AnalyticsAction.value')}`,
          },
        },
      })
      tagEvent.end()
    }
    history.push(url)
    if (alterationType === ALTERATION_TYPES.DECREASE_RISK) {
      const tagEvent = createEvent({
        GA: {
          category: 'Decrease cover',
          action: 'Exit - Landing Page',
        },
        Splunk: {
          attributes: {
            'workflow.name': "'Decrease cover' - Exit - Landing Page",
          },
        },
      })
      tagEvent.end()
      if (history.location.pathname === '/DecreaseCover/Alterations') {
        const tagEventTwo = createEvent({
          GA: {
            category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
            action: 'Exit - decrease cover quote page',
          },
          Splunk: {
            attributes: {
              'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Exit - decrease cover quote page`,
            },
          },
        })
        tagEventTwo.end()
      }
    }
  }, [bancsCustomerNoLA])
  return (
    <PageHeader
      theme={useSelector(getTheme)}
      backClickHandler={backClickHandler}
      heading={fields.heading}
      subHeading={useSelector(makeSubHeading(siteCoreCustomerNoText))}
      showBackIcon={get(fields, 'CTA.value.text')}
      iconText={get(fields, 'CTA.value.text', '')}
    />
  )
}

export default Header
