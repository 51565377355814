// @flow
import React, { PureComponent } from 'react'
import type { Node } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'

// components.
import {
  Card as LoginWrapperCard,
  Button,
  Heading,
  Notification,
  Loader,
  Icons,
} from '@mlcl-digital/mlcl-design'
import Logo from '../../atoms/Logo' // FIXME: Check Logo in Component Lib
import ReCaptcha from '../../atoms/ReCaptcha'
import ThirdPartyLoading from '../ThirdPartyLoading'
// helpers.
import {
  getIsLoginError,
  getThirdPartyQuoteProgressMsg,
  getShowThirdPartyLoading,
} from '../../../utils/loginPageUtils'
import { errorCheck } from '../../../utils/formUtils'
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
// constants
import { ADVISOR_PORTAL } from '../../../constants/site'
// styles.
import styles from './loginWrapper.styles'
import { USERNAME_FIELD_KEY } from '../../organisms/LoginForm/Schema/login.schema'

const { IconChevronLeft16 } = Icons

const NavButton = styled(Button)(styles.nav)
const HeaderOuterContent = styled('div')(styles.headerOuterContent)
const Form = styled('form')(styles.form)
// FIXME: To be replaced with Component Lib Link
const LoginLinkWrapper = styled('div')(styles.loginWrapper)
const FormSubmitButton = styled(Button)(styles.formSubmitButton)
const Spinner = styled(Loader)(styles.spinner)
const DescriptionWrapper = styled('div')(styles.descriptionWrapper)

type LoginWrapperProps = {
  // components list to render
  components: Array<{
    // heading
    heading: String,
    // show/hide logo
    hasLogo: Boolean,
    // description component
    Description: Node,
    // component to be rendered
    inputComponent: Node,
    // show/hide back button
    hasBackButton: Boolean,
    // back button label
    backButtonLabel: Boolean,
    // form field key
    formFieldKey: String,
    // form schema
    schema: Object<Object>,
    // form submit button label
    submitButtonLabel: String,
    // form submit button handler
    submitHandler: Function,
  }>,
  // rendered form id
  formId: String,
  // redux form
  form: {
    fields: Object<Object>,
  },
  // Fetching props
  thirdParty: Object,
  productRules: Object,
  masterList: Object,
  // okta state
  okta: Object<Object>,
  config: Object,
  authentication: Object,
  // Calculated
  thirdPartyQuoteProgressMsg: Object | string,
}

type LoginWrapperState = {
  // Array of object, each object containing component and data for current screen
  components: Object,
}

export class LoginWrapper extends PureComponent<LoginWrapperProps, LoginWrapperState> {
  constructor(props) {
    super(props)

    this.state = {
      components: props.components,
      formSchema: props.formSchema,
    }

    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.submit = this.submit.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.components) !== JSON.stringify(state.components) ||
      JSON.stringify(props.formSchema) !== JSON.stringify(state.formSchema)
    ) {
      return {
        components: props.components,
        formSchema: props.formSchema,
      }
    }

    return null
  }

  componentDidMount() {
    const {
      actions: { resetCreateQuote, resetAdvisorSupportStaff, resetOktaAuthorizationError },
      config,
    } = this.props
    const isAdviserPortal = config && config.SITE === ADVISOR_PORTAL
    // reset create quote
    resetCreateQuote()
    resetOktaAuthorizationError()
    if (isAdviserPortal) {
      resetAdvisorSupportStaff()
    }
  }

  getIsError() {
    const { form, fields, okta, authentication } = this.props
    const { components } = this.state
    return getIsLoginError({
      form,
      fields,
      okta,
      authentication,
      components,
    })
  }

  getShowThirdPartyLoading() {
    const { location, thirdPartyQuoteProgressMsg } = this.props
    return getShowThirdPartyLoading({
      location,
      thirdPartyQuoteProgressMsg,
    })
  }

  goBack() {
    const {
      components: { handleBackButton },
    } = this.props
    handleBackButton()
  }

  handleNext() {
    const {
      components: { handleNextButtonClick },
    } = this.props
    handleNextButtonClick()
  }

  handleInputBlur(event, name) {
    const { value } = event.target
    const {
      form,
      formId,
      actions: { formUpdateField, formValidate, oktaResetUserAccountStatus },
      authentication: { isLocked, isValidIdentity },
    } = this.props
    const { formSchema } = this.state

    const field = {
      error: errorCheck(value, formSchema[name].condition, formSchema[name].errorMsg, form),
      value,
    }

    formUpdateField(formId, name, field)
    formValidate(formId, formSchema)
    // reset user account state
    if (isLocked || isValidIdentity) oktaResetUserAccountStatus()
  }

  handleFieldChange({ value, name }) {
    const {
      form,
      formId,
      actions: {
        formUpdateField,
        oktaResetErrorCode,
        oktaResetIsError,
        resetOktaAuthorizationError,
      },
      authentication: { errorCode, isError },
      okta,
    } = this.props
    // reset api error codes
    if (errorCode) oktaResetErrorCode()
    // reset isError
    if (isError) oktaResetIsError()
    // reset okta error code
    if (okta.errorCode) resetOktaAuthorizationError()
    const { formSchema } = this.state
    let error = {}
    if (name === USERNAME_FIELD_KEY) {
      error = errorCheck(value, formSchema[name].condition, formSchema[name].errorMsg, form)
    }
    formUpdateField(formId, name, {
      error,
      value,
    })
  }

  async submit(event, execute) {
    const {
      actions: { formSubmit, setForgotUserNameReCaptchaToken },
      formId,
    } = this.props
    const { formSchema } = this.state
    const {
      components: { handleNextButtonClick },
    } = this.state
    event.preventDefault()
    if (execute) {
      const recaptchaToken = await execute()
      setForgotUserNameReCaptchaToken(recaptchaToken)
    }
    formSubmit(formId, formSchema, handleNextButtonClick)
  }

  renderSubmitButton = execute => {
    const {
      authentication: { isLoading },
      isFetchingAdviserDetails,
    } = this.props
    const showLoader = isLoading || isFetchingAdviserDetails
    const { components } = this.state
    const { submitButtonLabel } = components
    return (
      <FormSubmitButton
        btnActionType="submit"
        disabled={showLoader}
        variant="primary"
        data-testid="submitBtn"
        onClick={e => this.submit(e, execute)}
      >
        {showLoader ? (
          <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
        ) : (
          submitButtonLabel
        )}
      </FormSubmitButton>
    )
  }

  renderCaptcha = () => (
    <ReCaptcha action="test">{({ execute }) => this.renderSubmitButton(execute)}</ReCaptcha>
  )

  renderForm = () => {
    const { form, formId } = this.props
    const { components } = this.state

    if (!form || !components) return null

    const { inputComponent: RenderInputComponent, hasRecaptcha } = components

    // To show tooltip of other app
    return (
      <Form onSubmit={this.submit} id={formId} aria-labelledby={`${formId}-label`}>
        <RenderInputComponent
          {...this.props}
          handleChange={this.handleFieldChange}
          formFields={form.fields}
          handleBlur={this.handleInputBlur}
        />
        {hasRecaptcha ? this.renderCaptcha() : this.renderSubmitButton()}
      </Form>
    )
  }

  renderFooter() {
    const {
      fields: { loginLinkTooltip, loginLabel },
      config: { SITE, MLCL_CUSTOMER_APP_PATH, MLCL_ADVISOR_APP_PATH },
    } = this.props
    const appLoginPath = SITE === ADVISOR_PORTAL ? MLCL_CUSTOMER_APP_PATH : MLCL_ADVISOR_APP_PATH

    return (
      <LoginLinkWrapper>
        <Text field={loginLinkTooltip} />
        <a href={appLoginPath} target="__blank">
          <Text field={loginLabel} />
        </a>
      </LoginLinkWrapper>
    )
  }

  renderHeader() {
    const {
      logoHorizontal,
      authentication: { isLoading },
      logoVertical,
      fields,
    } = this.props
    const { value: logoAltText } = fields
    const { components } = this.state
    const { hasBackButton, backButtonLabel } = components

    return (
      <>
        <HeaderOuterContent>
          {hasBackButton && (
            <NavButton
              disabled={isLoading}
              data-testid="backButton"
              onClick={this.goBack}
              variant="tertiary"
            >
              <IconChevronLeft16 />
              {backButtonLabel}
            </NavButton>
          )}
        </HeaderOuterContent>
        <Logo
          href="/"
          horizontalSrc={logoHorizontal}
          verticalSrc={logoVertical}
          alt={logoAltText}
        />
        <HeaderOuterContent />
      </>
    )
  }

  renderBody() {
    const {
      form,
      authentication: { isLoading, isCodeResent },
      fields,
    } = this.props
    const { components } = this.state
    if (!form || !components) return null
    const { heading, descriptionComponent } = components
    const RenderDescription = descriptionComponent
    const { smsCodeResent } = reduceAuthorableFields(fields)

    const renderError = this.getIsError()

    return (
      <div>
        {renderError ? (
          <Notification variant="error">{renderTextField(renderError, true)}</Notification>
        ) : null}
        {!isLoading && isCodeResent && !renderError ? (
          <Notification variant="success">{smsCodeResent}</Notification>
        ) : null}
        {
          <>
            {heading && (
              <Heading variant="h2">
                <RichText field={{ value: heading }} />
              </Heading>
            )}
            <DescriptionWrapper>
              <RenderDescription {...this.props} />
            </DescriptionWrapper>
          </>
        }
        {this.renderForm()}
      </div>
    )
  }

  renderThirdPartyLoading() {
    const { logoHorizontal, logoVertical, fields, thirdPartyQuoteProgressMsg } = this.props
    const { value: logoAltText } = fields
    return (
      <ThirdPartyLoading
        logoHorizontal={logoHorizontal}
        logoVertical={logoVertical}
        logoAltText={logoAltText}
        message={thirdPartyQuoteProgressMsg}
      />
    )
  }

  render() {
    return !this.getIsError() && this.getShowThirdPartyLoading() ? (
      this.renderThirdPartyLoading()
    ) : (
      <LoginWrapperCard
        styleOverrides={{
          base: styles.base,
          header: styles.header,
          footer: styles.footer,
          body: styles.body,
        }}
        header={this.renderHeader()}
        footer={this.renderFooter()}
      >
        {this.renderBody()}
      </LoginWrapperCard>
    )
  }
}

const mapStateToProps = (
  { thirdParty, productRules, masterList, okta, config, authentication },
  { fields }
) => ({
  thirdParty,
  productRules,
  masterList,
  okta,
  config,
  authentication,
  thirdPartyQuoteProgressMsg: getThirdPartyQuoteProgressMsg({
    thirdParty,
    productRules,
    masterList,
    fields,
  }),
})

export default connect(mapStateToProps)(LoginWrapper)
