// @flow
import React from 'react'
import styled from '@emotion/styled'
import { getRelativeTimeDifference } from '../../../../../utils/quoteUtils'
import {
  APPLICATION_OVERVIEW_GENDER_STATUS_LONG,
  APPLICATION_OVERVIEW_GENDER_STATUS_SHORT,
  APPLICATION_OVERVIEW_SMOKER_STATUS_LONG,
  APPLICATION_OVERVIEW_SMOKER_STATUS_SHORT,
} from '../../../../../constants/application'

import Heading from '../../../../atoms/Heading'
import styles from './details.styles'

type DetailsProps = {
  title: string,
  fields: Object<Object>,
  mandatories: Object<Object>,
}

const getGender = (fields, gender) => {
  const genderObj = {
    M: fields.applicationMandatoriesMaleText,
    F: fields.applicationMandatoriesFemaleText,
  }
  if (
    gender.toLowerCase() === APPLICATION_OVERVIEW_GENDER_STATUS_LONG.toLowerCase() ||
    gender.toLowerCase() === APPLICATION_OVERVIEW_GENDER_STATUS_SHORT.toLowerCase()
  ) {
    return genderObj.M
  }
  return genderObj.F
}

const getSmokerStatus = (fields, isSmoker) => {
  const statusObj = {
    Y: fields.applicationMandatoriesSmokerText,
    N: fields.applicationMandatoriesNonSmokerText,
  }
  if (
    isSmoker.toLowerCase() === APPLICATION_OVERVIEW_SMOKER_STATUS_LONG.toLowerCase() ||
    isSmoker.toLowerCase() === APPLICATION_OVERVIEW_SMOKER_STATUS_SHORT.toLowerCase()
  ) {
    return statusObj.Y
  }
  return statusObj.N
}

export const Wrapper = styled('div')(styles.wrapper)
export const Title = styled('p')(styles.title)
export const Name = styled(Heading)(styles.name)
export const InlineDetails = styled('p')(styles.inlineDetails)

const InsuredDetails = ({ fields, mandatories, title }: DetailsProps) => {
  const { quoteCollectionName, dateOfBirth, gender, isSmoker, occupation } = mandatories
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Name size={1}>{quoteCollectionName}</Name>
      <InlineDetails>{`${fields.applicationMandatoriesAgePrefix} ${getRelativeTimeDifference(
        dateOfBirth
      )} | ${getGender(fields, gender)} | ${getSmokerStatus(
        fields,
        isSmoker
      )} | ${occupation}`}</InlineDetails>
    </Wrapper>
  )
}

export default InsuredDetails
