// @flow
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../../../actions'
import { errorCheck } from '../../../../../../../../utils/formUtils'
import { renderTextField } from '../../../../../../../../utils/sitecoreUtils'

// components
import Button from '../../../../../../../atoms/Button'
import Input from '../../../../../../../atoms/Input'
import getEditQuoteNameSchema, { FORM_ID } from './editQuoteName.schema'

type EditQuoteNameProps = {
  collection: Object,
  activeIndex: Number,
  value: String,
  handleUpdate: Function,
  actions: {
    /** Action creator */
    formInit: Function,
    /** Action creator */
    formSubmit: Function,
    /** Action creator */
    formUpdate: Function,
    /** Action creator */
    formUpdateField: Function,
    /** Action creator */
    formValidate: Function,
  },
  form: Object,
  fields: {
    QuoteEntityQuoteNameDuplicationErrorMsg: String,
    QuoteEntityQuoteNameInvalidErrorMsg: String,
    quoteEntityRenameButtonLabel: String,
  },
}

class EditQuoteName extends Component<EditQuoteNameProps> {
  constructor(props) {
    super(props)
    const {
      actions: { formInit },
      value,
      collection,
      activeIndex,
      fields,
    } = props

    // Generate form schema from fields and store in state
    this.state = {
      schema: getEditQuoteNameSchema(fields, collection, activeIndex),
    }

    // Always reset the form on mounting (open)
    const { schema } = this.state
    formInit(FORM_ID, schema, {
      quoteName: value,
    })
  }

  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { schema } = this.state
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  handleSubmit = event => {
    event.preventDefault()
    const {
      actions: { formSubmit },
      handleUpdate,
    } = this.props
    const { schema } = this.state
    formSubmit(FORM_ID, schema, (data, form) => {
      if (form.isValid) {
        handleUpdate(data.quoteName)
      }
    })
  }

  render() {
    const {
      form,
      fields: { quoteEntityRenameButtonLabel },
    } = this.props
    if (!form) return null
    const { schema } = this.state
    const {
      fields: { quoteName },
    } = form

    return (
      <Fragment>
        <Input
          htmlFor="quoteName"
          name="quoteName"
          label="Quote Name"
          changeHandler={this.handleChange}
          error={quoteName.error.error}
          caption={quoteName.error.error ? quoteName.error.errorMsg : schema.quoteName.tooltip}
          value={quoteName.value}
          data-locator="saveQuoteName_modal_quoteName"
        />
        <Button
          type="secondary"
          onClick={this.handleSubmit}
          data-locator="saveQuoteName_modal_confirm"
          data-testid="rename-button"
        >
          {renderTextField(quoteEntityRenameButtonLabel)}
        </Button>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms }) => ({
  form: forms[FORM_ID],
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditQuoteName)
